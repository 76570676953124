import { Chip } from '@mui/material';
import { PublicFaq } from 'nrosh-common/Api/UnauthenticatedFaqsApi';
import '@/Pages/Content/Faqs/FaqsPage.scss';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Accordion from '@/Components/Accordion/Accordion';
import ContentDisplay from '@/Components/ContentDisplay/ContentDisplay';
import { FilterDropdown } from '@/Components/Filters/FilterDropdown';
import FilterHeading from '@/Components/Filters/FilterHeading';
import { FilterSearchBox } from '@/Components/Filters/FilterSearchBox';
import { SearchableDropdown, SearchableDropdownOption } from '@/Components/SearchableDropdown/SearchableDropdown';
import { htmlTagsRegex } from '@/Helpers/Validation';

const questionOrAnswerContainsSearchTerm = (faq: PublicFaq, searchTerm: string): boolean => {
  const normalisedSearchTerm = searchTerm.toLowerCase().trim();

  return faq.title.concat(faq.content.replace(htmlTagsRegex, '')).toLowerCase().includes(normalisedSearchTerm);
};

const FaqHeader = ({ faq }: { faq: PublicFaq }): JSX.Element => (
  <div className="d-flex flex-wrap align-items-center gap-3">
    {faq.title}
    {faq.tags.map((t) => (
      <Chip key={t.id} label={t.name} />
    ))}
  </div>
);

type FaqsAccordionProps = {
  faqs: PublicFaq[];
  tagOptions: SearchableDropdownOption<number>[];
};

const applyFilters = (faqs: PublicFaq[], faqSearchString: string, tagIds: number[]): PublicFaq[] => {
  const normalisedSearchString = faqSearchString.trim().toLowerCase();
  let filteredFaqs = faqs.filter((faq) => questionOrAnswerContainsSearchTerm(faq, normalisedSearchString));
  filteredFaqs =
    tagIds.length === 0
      ? filteredFaqs
      : filteredFaqs.filter((d) => tagIds.some((id) => d.tags.some((t) => t.id === id)));
  return filteredFaqs;
};

const Heading = (faq: PublicFaq): JSX.Element => <FaqHeader faq={faq} />;

const Body = (faq: PublicFaq): JSX.Element => {
  const { content } = faq;
  return <ContentDisplay htmlContent={content} />;
};

const FaqsAccordion = ({ faqs, tagOptions }: FaqsAccordionProps): JSX.Element => {
  const [faqSearchString, setFaqSearchString] = useState<string>('');
  const [tagIds, setTagIds] = useState<number[]>([]);
  const [filteredFaqs, setFilteredFaqs] = useState(faqs);

  useEffect(() => {
    setFilteredFaqs(applyFilters(faqs, faqSearchString, tagIds));
  }, [faqs, tagIds, faqSearchString]);

  return (
    <div>
      <FilterDropdown resultCount={filteredFaqs.length} heading="Filter FAQs">
        <div className="row mb-2">
          <Form.Group className="col">
            <Form.Label className="fw-normal mb-0">
              <FilterHeading heading="Search for FAQ Text" />
            </Form.Label>
            <FilterSearchBox
              id="faqSearchBox"
              searchString={faqSearchString}
              setSearchString={setFaqSearchString}
              placeholderText="Search for FAQ text"
            />
          </Form.Group>
          <div className="col">
            <FilterHeading heading="Filter by Tag" />
            <SearchableDropdown
              options={tagOptions}
              currentSelection={tagIds}
              placeholderText="Choose Tags"
              onChange={(v) => setTagIds(v)}
              allowMultiSelect
            />
          </div>
        </div>
      </FilterDropdown>
      <Accordion
        data={filteredFaqs}
        id={(faq: PublicFaq) => faq.id?.toString()}
        heading={Heading}
        body={Body}
        paginated
        message="No questions matching the search criteria."
      />
    </div>
  );
};

export default FaqsAccordion;
