import { ProviderContact } from 'nrosh-common/Api/ProvidersApi';
import { SurveyChecks } from 'nrosh-common/Api/SurveysApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { Stack } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import { LoadingSpinner } from '@/Components/Loading/LoadingSpinner';
import SubmissionPageHeader from '@/Components/PageHeader/SubmissionPageHeader';
import { MyProviderApi, ProvidersApi, SubmissionsApi } from '@/Helpers/Apis';
import { surveyDisplayName } from '@/Helpers/SurveyHelper';
import useSubmissionDetail from '@/Hooks/useSubmissionDetail';
import { providerPages, publicPages, submissionPages } from '@/Pages/Home/SitePages';
import ConfirmOrganisationalContactsForms from '@/Pages/Submissions/Confirmation/ConfirmOrganisationalContactsForms';
import { SubmissionConfirmation } from '@/Pages/Submissions/Confirmation/SubmissionConfirmation';

const ConfirmOrganisationalContactsPage = ({ isAdmin = false }: { isAdmin?: boolean }): JSX.Element => {
  const location = useLocation();
  const submissionConfirmationState = location.state as SubmissionConfirmation;
  const { submissionId } = useParams();
  const [data] = useSubmissionDetail(submissionId!, isAdmin);
  const [checks] = useEndpoint<SurveyChecks>(SubmissionsApi.getEndOfSurveyChecks, submissionId);
  const [contacts] = useEndpoint<ProviderContact[]>(
    isAdmin ? ProvidersApi.getOrganisationalContacts : MyProviderApi.getProviderContacts,
    submissionId,
  );

  if (!checks || !contacts || (isAdmin && !data)) {
    return <LoadingSpinner />;
  }

  return (
    <Stack gap={5}>
      <SubmissionPageHeader
        heading="Organisational contact details"
        isRshUser={isAdmin}
        surveyDisplayName={surveyDisplayName(checks.surveyName, checks.timePeriodName)}
        providerName={data?.provider.name ?? ''}
        additionalRshCrumbs={[
          {
            name: 'Organisational contact details',
            path: submissionPages.AdminConfirmOrganisationalContacts.path,
          },
        ]}
        additionalProviderCrumbs={[
          {
            name: 'Organisational contact details',
            path: providerPages.ConfirmOrganisationalContacts.path,
          },
        ]}
      />
      <div>
        <p>Please check the following details we hold for your organisation and update where necessary.</p>
        <p>
          For each contact below, please confirm you have checked these and that they are correct at the point of survey
          submission.
        </p>
        <p>
          The regulator will use this information to contact your organisation and for the purposes set out in the&nbsp;
          <Link to={publicPages.PublicPrivacyNotice.path}>privacy notice</Link> and with reference to our&nbsp;
          <Link to={publicPages.PublicTermsAndConditions.path}>terms and conditions</Link>.
        </p>
      </div>
      <ConfirmOrganisationalContactsForms
        contactsToConfirm={checks.organisationalDetailsToConfirm}
        contacts={contacts}
        submissionConfirmationState={submissionConfirmationState}
        isAdmin={isAdmin}
      />
    </Stack>
  );
};

export default ConfirmOrganisationalContactsPage;
