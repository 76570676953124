import { SubmissionPartValidationComment, ValidationIssues, ValidationUpdate } from 'nrosh-common/Api/SubmissionsApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { useCallback, useState } from 'react';
import { DimensionValue, SpreadsheetValues } from '@/Components/Spreadsheet/SpreadsheetTypes';
import { SubmissionsApi } from '@/Helpers/Apis';
import {
  DimensionalMemberUpdate,
  FactUpdate,
  SubscriptionStartedData,
  ValidationApprovalUpdate,
} from '@/Pages/Submissions/SubmissionPartContext/useFactHub';

type SubmissionPartState = {
  data: SpreadsheetValues | null;
  dimensionValues: DimensionValue[] | null;
  validationIssues: ValidationIssues[] | null;
  validationStatuses: ValidationUpdate[] | null;
  validationComments: SubmissionPartValidationComment[] | null;
  setInitialData: (initialData: SubscriptionStartedData) => void;
  updateFact: (factUpdate: FactUpdate) => void;
  updateDimensionalMember: (dimensionalMemberUpdate: DimensionalMemberUpdate) => void;
  swapDimensionMemberInDataPoints: (
    oldDimensionMemberId: number,
    newDimensionMemberId: number | null,
    dataPointsToUpdate: string[],
  ) => void;
  updateValidation: (validationUpdate: ValidationUpdate) => void;
  updateValidationApproval: (validationApprovalUpdate: ValidationApprovalUpdate) => void;
  updateComment: (commentUpdate: SubmissionPartValidationComment) => void;
  deleteComment: (validationId: string) => void;
};

const updateSpreadsheetValuesForDataPoint = (
  values: SpreadsheetValues,
  dataPointId: string,
  newValue: string,
  dimension1MemberId: number | null,
  dimension2MemberId: number | null,
): SpreadsheetValues => ({
  ...values,
  [dataPointId]: [
    ...(values[dataPointId] || []).filter(
      (data) => !(dimension1MemberId === data.dimension1MemberId && dimension2MemberId === data.dimension2MemberId),
    ),
    {
      dimension1MemberId: dimension1MemberId ?? null,
      dimension2MemberId: dimension2MemberId ?? null,
      value: newValue,
    },
  ],
});

// When a user selects a different dimensional member in a dimension cell, we need to update
// all of the corresponding facts so that they now also correspond to the new dimensional member.
const updateDataForDimensionalRegion = (
  data: SpreadsheetValues,
  dataPointsToUpdate: string[],
  prevDimensionMemberId: number,
  newDimensionMemberId: number | null,
): SpreadsheetValues => {
  const newData: SpreadsheetValues = {};

  dataPointsToUpdate.forEach((dataPointId) => {
    const existingValues = data[dataPointId] || [];
    // Note: It's not possible for both of these arrays to be non-empty
    const valuesMatchingDimension1Member = existingValues.filter((d) => d.dimension1MemberId === prevDimensionMemberId);
    const valuesMatchingDimension2Member = existingValues.filter((d) => d.dimension2MemberId === prevDimensionMemberId);

    const otherValues = existingValues.filter(
      (d) => d.dimension1MemberId !== prevDimensionMemberId && d.dimension2MemberId !== prevDimensionMemberId,
    );

    if (!newDimensionMemberId) {
      newData[dataPointId] = otherValues;
    } else {
      const updatedDimension1Values = valuesMatchingDimension1Member.map((value) => ({
        ...value,
        dimension1MemberId: newDimensionMemberId,
      }));
      const updatedDimension2Values = valuesMatchingDimension2Member.map((value) => ({
        ...value,
        dimension2MemberId: newDimensionMemberId,
      }));
      newData[dataPointId] = otherValues.concat(updatedDimension1Values).concat(updatedDimension2Values);
    }
  });

  return {
    ...data,
    ...newData,
  };
};

const mapStringToNumberOrNull = (inputString: string | undefined): number | null =>
  inputString ? Number(inputString) : null;

const useSubmissionPartState = (submissionPartId: string): SubmissionPartState => {
  const [validationIssues, setValidationIssues] = useEndpoint<ValidationIssues[]>(
    SubmissionsApi.getSubmissionPartValidationIssues,
    submissionPartId,
  );
  const [validationStatuses, setValidationStatuses] = useState<ValidationUpdate[] | null>(null);
  const [validationComments, setValidationComments] = useState<SubmissionPartValidationComment[] | null>(null);
  const [data, setData] = useState<SpreadsheetValues | null>(null);
  const [dimensionValues, setDimensionValues] = useState<DimensionValue[] | null>(null);

  const setInitialData = ({
    data: initialData,
    validations,
    comments,
    dimensionData,
  }: SubscriptionStartedData): void => {
    const deserializedData: SpreadsheetValues = {};
    Object.keys(initialData).forEach((dataPointId) => {
      const serializedValues = initialData[dataPointId];
      const deserializedValues = serializedValues.map((sv) => {
        const parts = sv.split(',');
        return {
          dimension1MemberId: mapStringToNumberOrNull(parts.shift()),
          dimension2MemberId: mapStringToNumberOrNull(parts.shift()),
          value: parts.join(','), // If the value contains a comma then we need to re-join
        };
      });
      deserializedData[dataPointId] = deserializedValues;
    });
    setData(deserializedData);
    setDimensionValues(dimensionData);
    setValidationStatuses(validations);
    setValidationComments(comments);
  };

  // Note: We're assuming in all of the functions below that setInitialData has already been called.
  // This is ensured by the SubmissionPartLiveDataContextProvider which displays a loading message until
  // all the relevant initialisation has occurred.
  const updateFact = useCallback(
    ({ dataPointId, newValue, dimension1MemberId, dimension2MemberId }: FactUpdate) => {
      setData((prevData) =>
        updateSpreadsheetValuesForDataPoint(prevData!, dataPointId, newValue, dimension1MemberId, dimension2MemberId),
      );
    },
    [setData, updateSpreadsheetValuesForDataPoint],
  );

  const updateDimensionalMember = useCallback(
    ({ dimensionalRegionId, index, dimensionalMemberId }: DimensionalMemberUpdate) => {
      setDimensionValues((prevDimensionValues) => {
        const existingDimensionId = prevDimensionValues!.find(
          (v) => v.regionId === dimensionalRegionId && v.index === index,
        )?.dimensionMemberId;

        if (existingDimensionId === dimensionalMemberId) {
          return prevDimensionValues;
        }

        const filteredValues = prevDimensionValues!.filter(
          (v) =>
            !((v.index === index || v.dimensionMemberId === dimensionalMemberId) && v.regionId === dimensionalRegionId),
        );
        return dimensionalMemberId
          ? filteredValues.concat([{ regionId: dimensionalRegionId, index, dimensionMemberId: dimensionalMemberId }])
          : filteredValues;
      });
    },
    [setDimensionValues],
  );

  const swapDimensionMemberInDataPoints = useCallback(
    (oldDimensionMemberId: number, newDimensionMemberId: number | null, dataPointsToUpdate: string[]) => {
      setData((prevData) =>
        updateDataForDimensionalRegion(prevData!, dataPointsToUpdate, oldDimensionMemberId, newDimensionMemberId),
      );
    },
    [setData, updateDataForDimensionalRegion],
  );

  const updateValidation = (validationUpdate: ValidationUpdate): void => {
    setValidationStatuses((prevValidations) =>
      prevValidations!.filter((v) => v.validationRef !== validationUpdate.validationRef).concat(validationUpdate),
    );
  };

  const updateValidationApproval = ({ validationId, isApproved }: ValidationApprovalUpdate): void => {
    setValidationIssues((prevValidationIssues) =>
      prevValidationIssues!.some((issue) => issue.validationId === validationId)
        ? prevValidationIssues!.map((validationIssue) =>
            validationIssue.validationId === validationId
              ? { ...validationIssue, isValidationApproved: isApproved }
              : validationIssue,
          )
        : prevValidationIssues!.concat({ validationId, isValidationApproved: isApproved }),
    );
  };

  const updateComment = (commentUpdate: SubmissionPartValidationComment): void => {
    setValidationComments((prevComments) =>
      prevComments!.filter((c) => c.validationId !== commentUpdate.validationId).concat(commentUpdate),
    );
  };

  const deleteComment = (validationId: string): void => {
    setValidationComments((prevComments) => prevComments!.filter((c) => c.validationId !== validationId));
  };

  return {
    data,
    dimensionValues,
    validationIssues,
    validationStatuses,
    validationComments,
    setInitialData,
    updateFact,
    updateDimensionalMember,
    swapDimensionMemberInDataPoints,
    updateValidation,
    updateValidationApproval,
    updateComment,
    deleteComment,
  };
};

export default useSubmissionPartState;
