import ApiClient, { APIHelpers, APIResult, handleAPIRequest } from './ApiClient';
import { Role } from './Enums';

export enum UserType {
  Admin = 'Admin',
  User = 'User',
}

export enum UserStatus {
  AwaitingActivation = 'AwaitingActivation',
  AwaitingReactivation = 'AwaitingReactivation',
  Active = 'Active',
  Locked = 'Locked',
  Disabled = 'Disabled',
  Deleted = 'Deleted',
  AwaitingEmailConfirmation = 'AwaitingEmailConfirmation',
}

export type UserProfile = {
  id: number;
  name: string;
  type: UserProfileType;
  roles: Role[];
  surveys: number[];
  assignedUsers: number;
};

type UserProfileSummary = {
  id: number;
  name: string;
  type: UserProfileType;
};

export type User = {
  id: number;
  userName: string;
  emailAddress: string;
  name: string;
  profile: UserProfileSummary | null;
  status: UserStatus;
  preferredFormOfAddress?: string;
  providerId?: number;
  lastSuccessfulLogin: string | null;
};

export type UserUpdateResponse = {
  successful: boolean;
  error: string | null;
};

export type ProviderUsers = {
  id: number | null;
  providerNumber: string;
  name: string;
  users: User[];
};

export type UsersResponse = {
  rshUsers: User[];
  providers: ProviderUsers[];
};

export type AddUserRequest = {
  userName: string;
  emailAddress: string;
  name: string;
  preferredFormOfAddress?: string;
  profileId: number;
  providerId?: number;
};

export type UpdateUserRequest = {
  name: string;
  preferredFormOfAddress?: string;
  profileId: number;
  providerId?: number;
};

export enum UserProfileType {
  DcsProfile = 'DcsProfile',
  ProviderProfile = 'ProviderProfile',
}

export type UserProfileRequest = {
  name: string;
  type: UserProfileType;
  roles: Role[];
  surveys: number[];
};

export type UpdateUserEmailRequest = {
  emailAddress: string;
  password: string;
};

const UsersApiRoot = 'Users';
const UsersApiPaths = {
  USER: (userId: number) => `${UsersApiRoot}/${userId}`,
  ENABLE_USER: (userId: number) => `${UsersApiRoot}/${userId}/Enable`,
  DISABLE_USER: (userId: number) => `${UsersApiRoot}/${userId}/Disable`,
  UPDATE_USER_EMAIL: (userId: number) => `${UsersApiRoot}/${userId}/UpdateEmail`,
  SEND_PASSWORD_RESET_EMAIL: (userId: number) => `${UsersApiRoot}/${userId}/SendResetPasswordEmail`,
  RESEND_ACTIVATION_EMAIL: (userId: number) => `${UsersApiRoot}/${userId}/ResendActivationEmail`,

  USERS: UsersApiRoot,
  DOWNLOAD_USERS: `${UsersApiRoot}/Download`,
  GET_PROVIDER_USERS: `${UsersApiRoot}/Provider`,
  GET_PROVIDER_PROFILES: `${UsersApiRoot}/Provider/Profiles`,
  GET_USERS_FOR_PROVIDER: (providerId: number) => `${UsersApiRoot}/Provider/${providerId}`,
  ENABLE_USERS: `${UsersApiRoot}/BulkEnable`,
  DISABLE_USERS: `${UsersApiRoot}/BulkDisable`,
  DELETE_USERS: `${UsersApiRoot}/BulkDelete`,
  PROFILES: `${UsersApiRoot}/Profiles`,
  PROFILE: (profileId: number) => `${UsersApiRoot}/Profiles/${profileId}`,
};

const usersApi = (apiClient: ApiClient) => ({
  getUser: (userId: number) => {
    return handleAPIRequest(apiClient.get(UsersApiPaths.USER(userId)), APIHelpers.json<User>, APIHelpers.standardError);
  },

  updateUser: (userId: number, request: UpdateUserRequest) => {
    return handleAPIRequest(
      apiClient.patch(UsersApiPaths.USER(userId), request),
      APIHelpers.json<APIResult>,
      APIHelpers.standardError<APIResult>,
    );
  },

  deleteUser: (userId: number) => {
    return handleAPIRequest(
      apiClient.deleteAction(UsersApiPaths.USER(userId)),
      APIHelpers.json<APIResult>,
      APIHelpers.standardError<APIResult>,
    );
  },

  enableUser: (userId: number) => {
    return handleAPIRequest(
      apiClient.patch(UsersApiPaths.ENABLE_USER(userId), null),
      APIHelpers.json<APIResult>,
      APIHelpers.standardError<APIResult>,
    );
  },

  disableUser: (userId: number) => {
    return handleAPIRequest(
      apiClient.patch(UsersApiPaths.DISABLE_USER(userId), null),
      APIHelpers.json<APIResult>,
      APIHelpers.standardError<APIResult>,
    );
  },

  updateUserEmail: (userId: number, request: UpdateUserEmailRequest) => {
    return handleAPIRequest(
      apiClient.patch(UsersApiPaths.UPDATE_USER_EMAIL(userId), request),
      APIHelpers.json<APIResult>,
      APIHelpers.standardError<APIResult>,
    );
  },

  sendPasswordResetEmail: (userId: number) => {
    return handleAPIRequest(
      apiClient.post(UsersApiPaths.SEND_PASSWORD_RESET_EMAIL(userId), null),
      APIHelpers.json<APIResult>,
      APIHelpers.standardError<APIResult>,
    );
  },

  resendActivationEmail: (userId: number) => {
    return handleAPIRequest(
      apiClient.post(UsersApiPaths.RESEND_ACTIVATION_EMAIL(userId), null),
      APIHelpers.json<APIResult>,
      APIHelpers.standardError<APIResult>,
    );
  },

  getUsers: () => {
    return handleAPIRequest(
      apiClient.get(UsersApiPaths.USERS),
      APIHelpers.json<UsersResponse>,
      APIHelpers.standardError,
    );
  },

  addUser: (request: AddUserRequest) => {
    return handleAPIRequest(
      apiClient.post(UsersApiPaths.USERS, request),
      APIHelpers.json<APIResult>,
      APIHelpers.standardError<APIResult>,
    );
  },

  downloadUsers: () => {
    return apiClient.getFile(UsersApiPaths.DOWNLOAD_USERS);
  },

  getProviderUsers: () => {
    return handleAPIRequest(
      apiClient.get(UsersApiPaths.GET_PROVIDER_USERS),
      APIHelpers.json<ProviderUsers>,
      APIHelpers.standardError,
    );
  },

  getProviderProfiles: () => {
    return handleAPIRequest(
      apiClient.get(UsersApiPaths.GET_PROVIDER_PROFILES),
      APIHelpers.json<UserProfile[]>,
      APIHelpers.standardError,
    );
  },

  getUsersForProvider: (providerId: number) => {
    return handleAPIRequest(
      apiClient.get(UsersApiPaths.GET_USERS_FOR_PROVIDER(providerId)),
      APIHelpers.json<ProviderUsers>,
      APIHelpers.standardError,
    );
  },

  enableUsers: (userIds: number[]) => {
    return handleAPIRequest(
      apiClient.patch(UsersApiPaths.ENABLE_USERS, userIds),
      APIHelpers.json<APIResult>,
      APIHelpers.standardError<APIResult>,
    );
  },

  disableUsers: (userIds: number[]) => {
    return handleAPIRequest(
      apiClient.patch(UsersApiPaths.DISABLE_USERS, userIds),
      APIHelpers.json<APIResult>,
      APIHelpers.standardError<APIResult>,
    );
  },

  deleteUsers: (userIds: number[]) => {
    return handleAPIRequest(
      apiClient.post(UsersApiPaths.DELETE_USERS, userIds),
      APIHelpers.json<APIResult>,
      APIHelpers.standardError<APIResult>,
    );
  },

  createProfile: (request: UserProfileRequest) => {
    return handleAPIRequest(
      apiClient.post(UsersApiPaths.PROFILES, request),
      APIHelpers.json<APIResult>,
      APIHelpers.standardError<APIResult>,
    );
  },

  getProfiles: () => {
    return handleAPIRequest(
      apiClient.get(UsersApiPaths.PROFILES),
      APIHelpers.json<UserProfile[]>,
      APIHelpers.standardError,
    );
  },

  getProfile: (profileId: number) => {
    return handleAPIRequest(
      apiClient.get(UsersApiPaths.PROFILE(profileId)),
      APIHelpers.json<UserProfile>,
      APIHelpers.standardError,
    );
  },

  updateProfile: (profileId: number, request: UserProfileRequest) => {
    return handleAPIRequest(
      apiClient.patch(UsersApiPaths.PROFILE(profileId), request),
      APIHelpers.json<APIResult>,
      APIHelpers.standardError<APIResult>,
    );
  },

  deleteProfile: (profileId: number) => {
    return handleAPIRequest(
      apiClient.deleteAction(UsersApiPaths.PROFILE(profileId)),
      APIHelpers.json<APIResult>,
      APIHelpers.standardError<APIResult>,
    );
  },
});

export default usersApi;
