const Download = (): JSX.Element => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.2082 17.0834C15.5533 17.0833 15.8333 17.3631 15.8333 17.7083C15.8333 18.0534 15.5537 18.3333 15.2085 18.3334L4.37518 18.3366C4.03 18.3367 3.75 18.0569 3.75 17.7118C3.75 17.3666 4.02964 17.0867 4.37482 17.0866L15.2082 17.0834ZM9.70686 1.6766L9.79167 1.6709C10.1081 1.6709 10.3696 1.90603 10.411 2.21109L10.4167 2.2959L10.4158 13.7001L13.5173 10.5998C13.7393 10.3779 14.0865 10.3578 14.3311 10.5394L14.4012 10.5999C14.6231 10.8218 14.6432 11.169 14.4617 11.4137L14.4011 11.4838L10.2364 15.6476C10.0146 15.8694 9.66766 15.8896 9.423 15.7083L9.3529 15.6479L5.18336 11.484C4.93912 11.2401 4.93885 10.8444 5.18276 10.6001C5.40449 10.3781 5.7517 10.3577 5.9965 10.5391L6.06664 10.5995L9.16583 13.6942L9.16667 2.2959C9.16667 1.97949 9.40179 1.71799 9.70686 1.6766L9.79167 1.6709L9.70686 1.6766Z"
      fill="currentColor"
    />
  </svg>
);

export default Download;
