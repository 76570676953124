import { Form } from 'react-bootstrap';
import '@/Components/Filters/Filters.scss';
import { FilterDropdown } from '@/Components/Filters/FilterDropdown';
import { AccessibleFieldSet } from '@/Components/Form/AccessibleFieldSet';

export enum FilterType {
  Search,
  Checkbox,
  Dropdown,
}

type BaseFilter = {
  type: FilterType;
  id: string;
};

export type SearchFilterInfo = BaseFilter & {
  type: FilterType.Search;
  text: string;
  placeholder?: string;
  ariaLabelledBy?: string;
};

export type CheckboxFilterInfo = BaseFilter & {
  type: FilterType.Checkbox;
  text: string;
  options: {
    value: string;
    text: string;
    isUnselectedByDefault?: boolean;
  }[];
};

export type DropdownFilterInfo = BaseFilter & {
  type: FilterType.Dropdown;
  text: string;
  placeholder?: string;
  options: {
    value: string;
    text: string;
  }[];
};

type FilterInfo = SearchFilterInfo | CheckboxFilterInfo | DropdownFilterInfo;

export type SelectedSearchFilterInfo = BaseFilter & {
  type: FilterType.Search;
  searchTerm: string;
};

export type SelectedCheckboxFilterInfo = BaseFilter & {
  type: FilterType.Checkbox;
  options: Record<string, boolean>;
};

export type SelectedDropdownFilterInfo = BaseFilter & {
  type: FilterType.Dropdown;
  option: string;
};

export type SelectedFilterInfo = SelectedSearchFilterInfo | SelectedCheckboxFilterInfo | SelectedDropdownFilterInfo;

const getDefaultSelectedOptions = (filterInfo: CheckboxFilterInfo): Record<string, boolean> => {
  const options: Record<string, boolean> = {};
  filterInfo.options.forEach((o) => {
    options[o.value] = !o.isUnselectedByDefault;
  });
  return options;
};

export const getDefaultSelectedFilterInfo = (filterInfo: FilterInfo): SelectedFilterInfo => {
  switch (filterInfo.type) {
    case FilterType.Search:
      return { ...filterInfo, searchTerm: '' };
    case FilterType.Checkbox:
      return { ...filterInfo, options: getDefaultSelectedOptions(filterInfo) };
    case FilterType.Dropdown:
      return { ...filterInfo, option: '' };
    default:
      return filterInfo;
  }
};

type SelectedFilterComponentProps = {
  selectedFilter: SelectedFilterInfo;
  filterInfo: FilterInfo;
  setSelectedFilter: (filter: SelectedFilterInfo) => void;
};

const SelectedFilterComponent = (props: SelectedFilterComponentProps): JSX.Element | null => {
  const { selectedFilter, filterInfo, setSelectedFilter } = props;

  if (selectedFilter.type === FilterType.Search) {
    return (
      <Form.Control
        value={selectedFilter.searchTerm}
        onChange={(e) => setSelectedFilter({ ...selectedFilter, searchTerm: e.target.value })}
        className="searchInput"
        placeholder={(filterInfo as SearchFilterInfo).placeholder}
        aria-labelledby={(filterInfo as SearchFilterInfo).ariaLabelledBy}
      />
    );
  }

  if (selectedFilter.type === FilterType.Checkbox) {
    return (
      <div className="d-flex flex-row flex-wrap">
        {(filterInfo as CheckboxFilterInfo).options.map((option) => (
          <div className="mb-2 d-flex align-items-center" key={option.value}>
            <input
              type="checkbox"
              className="me-3"
              id={`${selectedFilter.id}-${option.value}`}
              name={`${selectedFilter.id}_checkbox`}
              value={option.value}
              checked={!!selectedFilter.options[option.value]}
              onChange={(e) =>
                setSelectedFilter({
                  ...selectedFilter,
                  options: { ...selectedFilter.options, [option.value]: e.target.checked },
                })
              }
            />
            <label className="me-6 ms-2" htmlFor={`${selectedFilter.id}-${option.value}`}>
              {option.text}
            </label>
          </div>
        ))}
      </div>
    );
  }

  if (selectedFilter.type === FilterType.Dropdown) {
    return (
      <Form.Select
        value={selectedFilter.option}
        as="select"
        onChange={(e) => setSelectedFilter({ ...selectedFilter, option: e.target.value })}
        className="selectInput"
      >
        <option value="" hidden>
          {(filterInfo as DropdownFilterInfo).placeholder || 'Select option'}
        </option>
        {(filterInfo as DropdownFilterInfo).options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        ))}
      </Form.Select>
    );
  }

  return null;
};

export type FiltersComponentProps = {
  heading: string;
  count: number;
  filters: FilterInfo[];
  selectedFilter: SelectedFilterInfo | null;
  setSelectedFilter: (filter: SelectedFilterInfo) => void;
};

const FiltersComponent = (props: FiltersComponentProps): JSX.Element => {
  const { heading, count, filters, selectedFilter, setSelectedFilter } = props;

  const currentSelectedFilterInfo = filters.find((filter) => filter.id === selectedFilter?.id);

  const numberOfAppliedFilters = (): number | undefined => {
    if (selectedFilter?.type === FilterType.Checkbox) {
      const { options } = currentSelectedFilterInfo as CheckboxFilterInfo;
      const checkedOptions = options.filter((option) => !!selectedFilter.options[option.value]);
      return checkedOptions.length;
    }
    return undefined;
  };

  return (
    <FilterDropdown resultCount={count} heading={heading} numberOfAppliedFilters={numberOfAppliedFilters()}>
      <>
        <AccessibleFieldSet legend="Filter by:" legendClassName="mb-2">
          <div className="d-flex flex-wrap mt-2">
            {filters.map((filter) => (
              <div className="d-flex align-items-center mb-2" key={filter.id}>
                <input
                  type="radio"
                  id={filter.id}
                  name="filter_type"
                  checked={currentSelectedFilterInfo?.id === filter.id}
                  value={filter.id}
                  onChange={() => setSelectedFilter(getDefaultSelectedFilterInfo(filter))}
                />
                <label className="me-6 ms-2" htmlFor={filter.id}>
                  {filter.text}
                </label>
              </div>
            ))}
          </div>
        </AccessibleFieldSet>
        {currentSelectedFilterInfo && (
          <div className="selectedFilterContainer mt-2">
            <SelectedFilterComponent
              selectedFilter={selectedFilter!}
              filterInfo={currentSelectedFilterInfo}
              setSelectedFilter={setSelectedFilter}
            />
          </div>
        )}
        <button
          className="clearFiltersButton mt-3 p-0"
          type="button"
          onClick={() => {
            if (currentSelectedFilterInfo) {
              setSelectedFilter(getDefaultSelectedFilterInfo(currentSelectedFilterInfo));
            }
          }}
        >
          Reset to default
        </button>
      </>
    </FilterDropdown>
  );
};

export default FiltersComponent;
