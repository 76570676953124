import { EmailRecipientResponse, EmailRecipientsFilter, ScheduledEmailRequest } from 'nrosh-common/Api/EmailsApi';
import { ProviderSize, ProviderType, ReportingStatus, SubmissionStatus } from 'nrosh-common/Api/Enums';
import '@/Pages/Emails/EmailStyles.scss';
import { ProviderSummary } from 'nrosh-common/Api/ProvidersApi';
import { ContactType, Surveys } from 'nrosh-common/Api/SurveysApi';
import { UserProfile } from 'nrosh-common/Api/UsersApi';
import { FormEvent, useMemo, useReducer, useState } from 'react';
import { Alert, Form, InputGroup, ListGroup, Tab } from 'react-bootstrap';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import { AccessibleFeedback } from '@/Components/Form/AccessibleFeedback';
import { AccessibleFieldSet } from '@/Components/Form/AccessibleFieldSet';
import LinkButton from '@/Components/Links/LinkButton';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { EmailsApi } from '@/Helpers/Apis';
import { FilterEntry, getSelectedValues, useFilterPattern } from '@/Helpers/FilterHelpers';
import { getValidityProps } from '@/Helpers/Forms';
import { SelectionType, mapSelectionToRequestString } from '@/Pages/Emails/EmailUtilities';
import { EmailRecipientsView } from '@/Pages/Emails/Manual/EmailRecipientsView';
import { ExcludedProvidersTable, ProviderTableRow } from '@/Pages/Emails/Manual/ExcludedProvidersTable';
import { MultiSelectFormWrapper } from '@/Pages/Emails/MultiSelectFormWrapper';
import { adminPages } from '@/Pages/Home/SitePages';

type SurveyInstanceFilterEntry = {
  surveyId: number;
  timePeriodId: number;
  displayName: string;
  isSelected: boolean;
};

type FilterMultiSelectionState<T> = {
  selectionType: SelectionType;
  pattern: T;
};

type EmailRecipientsInitialState = {
  surveyInstances: FilterMultiSelectionState<SurveyInstanceFilterEntry[]>;
  submissionStatuses: FilterMultiSelectionState<FilterEntry<SubmissionStatus>[]>;
  providerSizes: FilterMultiSelectionState<FilterEntry<ProviderSize>[]>;
  reportingStatuses: FilterMultiSelectionState<FilterEntry<ReportingStatus>[]>;
  providerTypes: FilterMultiSelectionState<FilterEntry<ProviderType>[]>;
  organisationalContacts: FilterMultiSelectionState<FilterEntry<ContactType>[]>;
  userProfiles: FilterMultiSelectionState<FilterEntry<number>[]>;
  excludedProviders: ProviderTableRow[];
};

type EmailRecipientsFormProps = {
  emailId: string;
  setDirty: () => void;
  setPristine: () => void;
  initialState: EmailRecipientsInitialState;
  surveyList: Surveys;
  profileList: UserProfile[];
  providerList: ProviderSummary[];
};

const getProviderSelectionString = (providers: ProviderTableRow[]): string => {
  const selectedProviders = providers.filter((p) => p.isSelected);

  if (selectedProviders.length === 0) {
    return 'None Selected';
  }

  if (selectedProviders.length <= 3) {
    return selectedProviders.map((p) => p.providerNumber).join(', ');
  }

  return `${selectedProviders
    .slice(0, 3)
    .map((p) => p.providerNumber)
    .join(', ')}... (${selectedProviders.length} total)`;
};

export const EmailRecipientsForm = ({
  emailId,
  setDirty,
  setPristine,
  initialState,
  surveyList,
  profileList,
  providerList,
}: EmailRecipientsFormProps): JSX.Element => {
  const [recipientsFilter, setRecipientsFilter] = useState<EmailRecipientsFilter>();
  const [emailRecipients, setEmailRecipients] = useState<EmailRecipientResponse[] | undefined>();
  const location = useLocation();

  // Survey Instances
  const [surveyInstanceSelectionPattern, setSurveyInstanceSelectionPattern] = useState<SurveyInstanceFilterEntry[]>(
    initialState.surveyInstances.pattern,
  );

  const [surveyInstanceSelectionType, setSurveyInstanceSelectionType] = useState<SelectionType>(
    initialState.surveyInstances.selectionType,
  );

  const setSurveyInstanceSelected = (surveyId: number, timePeriodId: number, isSelected: boolean): void => {
    setDirty();
    setSurveyInstanceSelectionPattern((prevOpenSurveyInstances) =>
      prevOpenSurveyInstances.map((si) =>
        si.surveyId === surveyId && si.timePeriodId === timePeriodId ? { ...si, isSelected } : si,
      ),
    );
  };

  // Submission Statuses
  const [submissionStatusSelectionPattern, setSubmissionStatusSelected] = useFilterPattern(
    initialState.submissionStatuses.pattern,
    setDirty,
  );
  const [submissionStatusSelectionType, setSubmissionStatusSelectionType] = useState<SelectionType>(
    initialState.submissionStatuses.selectionType,
  );

  // Provider Size
  const [providerSizeSelectionPattern, setProviderSizeSelected] = useFilterPattern(
    initialState.providerSizes.pattern,
    setDirty,
  );
  const [providerSizeSelectionType, setProviderSizeSelectionType] = useState<SelectionType>(
    initialState.providerSizes.selectionType,
  );

  // Provider Reporting Status
  const [reportingStatusSelectionPattern, setReportingStatusSelected] = useFilterPattern(
    initialState.reportingStatuses.pattern,
    setDirty,
  );
  const [reportingStatusSelectionType, setReportingStatusSelectionType] = useState<SelectionType>(
    initialState.reportingStatuses.selectionType,
  );

  // Provider Type
  const [providerTypeSelectionPattern, setProviderTypeSelected] = useFilterPattern(
    initialState.providerTypes.pattern,
    setDirty,
  );
  const [providerTypeSelectionType, setProviderTypeSelectionType] = useState<SelectionType>(
    initialState.providerTypes.selectionType,
  );

  // Excluded Providers
  const [exclusionsString, setExclusionsString] = useState<string>(
    getProviderSelectionString(initialState.excludedProviders),
  );
  const [excludedProvidersTableData, setExcludedProvidersTableData] = useState<ProviderTableRow[]>(
    initialState.excludedProviders,
  );
  const [isExclusionsExpanded, toggleExclusionsExpanded] = useReducer((value) => !value, false);

  // Organisational Contacts
  const [organisationalContactSelectionPattern, setOrganisationalContactSelected] = useFilterPattern(
    initialState.organisationalContacts.pattern,
    setDirty,
  );
  const [organisationalContactsSelectionType, setOrganisationalContactsSelectionType] = useState<SelectionType>(
    initialState.organisationalContacts.selectionType,
  );

  // User Profiles
  const [userProfilesSelectionPattern, setUserProfileSelected] = useFilterPattern(initialState.userProfiles.pattern);
  const [userProfilesSelectionType, setUserProfilesSelectionType] = useState<SelectionType>(
    initialState.userProfiles.selectionType,
  );

  const [validated, setValidated] = useState<boolean>(false);

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const areRecipientsInvalid = useMemo(() => {
    const areSomeUserProfilesSelected = userProfilesSelectionPattern.some((up) => up.isSelected);
    const areSomeOrganisationalContactsSelected = organisationalContactSelectionPattern.some((oc) => oc.isSelected);

    if (userProfilesSelectionType === SelectionType.All || organisationalContactsSelectionType === SelectionType.All) {
      return false;
    }
    if (
      userProfilesSelectionType === SelectionType.None &&
      organisationalContactsSelectionType === SelectionType.None
    ) {
      return true;
    }
    if (userProfilesSelectionType === SelectionType.None) {
      return !areSomeOrganisationalContactsSelected;
    }
    if (organisationalContactsSelectionType === SelectionType.None) {
      return !areSomeUserProfilesSelected;
    }

    return !areSomeOrganisationalContactsSelected && !areSomeUserProfilesSelected;
  }, [
    userProfilesSelectionPattern,
    organisationalContactSelectionPattern,
    userProfilesSelectionType,
    organisationalContactsSelectionType,
  ]);

  const navigate = useNavigate();

  const updateExcludedProviders = (newExcludedProviders: ProviderTableRow[]): void => {
    setExcludedProvidersTableData(newExcludedProviders);
    setExclusionsString(getProviderSelectionString(newExcludedProviders));
  };

  const composePath = generatePath(adminPages.AdminViewOrEditEmail.path, { emailId });

  const getRecipientsFilter = (): {
    excludedProviderIds: null | string;
    reportingStatuses: null | string;
    providerTypes: null | string;
    providerContactsContactTypes: string | null;
    surveyInstanceIds: null | string;
    userProfiles: string | null;
    providerSizes: null | string;
    submissionStatuses: null | string;
  } => {
    const surveyInstanceIds = surveyInstanceSelectionPattern
      .filter((si) => si.isSelected)
      .map((si) => `${si.surveyId}:${si.timePeriodId}`)
      .join(',');

    const submissionStatuses = getSelectedValues(submissionStatusSelectionPattern).join(',');

    const providerTypes = getSelectedValues(providerTypeSelectionPattern).join(',');

    const providerSizes = getSelectedValues(providerSizeSelectionPattern).join(',');

    const reportingStatuses = getSelectedValues(reportingStatusSelectionPattern).join(',');

    const excludedProviderIds = excludedProvidersTableData
      .filter((p) => p.isSelected)
      .map((p) => p.id)
      .join(',');

    const providerContactsContactTypes = getSelectedValues(organisationalContactSelectionPattern).join(',');

    const userProfiles = getSelectedValues(userProfilesSelectionPattern).join(',');

    return {
      surveyInstanceIds:
        surveyInstanceIds === '' || surveyInstanceSelectionType === SelectionType.All ? null : surveyInstanceIds,
      submissionStatuses:
        submissionStatuses === '' || submissionStatusSelectionType === SelectionType.All ? null : submissionStatuses,
      providerSizes: providerSizes === '' || providerSizeSelectionType === SelectionType.All ? null : providerSizes,
      providerTypes: providerTypes === '' || providerTypeSelectionType === SelectionType.All ? null : providerTypes,
      reportingStatuses:
        reportingStatuses === '' || reportingStatusSelectionType === SelectionType.All ? null : reportingStatuses,
      excludedProviderIds: excludedProviderIds === '' ? null : excludedProviderIds,
      providerContactsContactTypes: mapSelectionToRequestString(
        organisationalContactsSelectionType,
        providerContactsContactTypes,
      ),
      userProfiles: mapSelectionToRequestString(userProfilesSelectionType, userProfiles),
    };
  };

  const updateRecipients = async (): Promise<void> => {
    setIsSaving(true);
    setPristine();

    const emailPatchRequest: ScheduledEmailRequest = {
      recipientsFilter: getRecipientsFilter(),
    };

    const response = await EmailsApi.patchScheduledEmail(emailId, emailPatchRequest).raw;

    if (response.ok) {
      setErrorMessage(undefined);
      setIsSaving(false);
      navigate(composePath);
      return;
    }
    setErrorMessage(response.value.message);
    setIsSaving(false);
  };

  const submitForm = (e: FormEvent<HTMLFormElement> | undefined = undefined): void => {
    e?.preventDefault();

    if (!areRecipientsInvalid) {
      setValidated(false);
      updateRecipients().catch(() => {});
    } else {
      e?.stopPropagation();
      setValidated(true);
    }
  };

  const updateRecipientsForPreview = async (): Promise<void> => {
    const updatedRecipientsFilter = getRecipientsFilter();
    setRecipientsFilter(updatedRecipientsFilter);
    await EmailsApi.getFilteredEmailRecipients(updatedRecipientsFilter).withHandlers(
      (recipients) => setEmailRecipients(recipients),
      ({ message }) => setErrorMessage(message),
    );
  };

  const setRecipientsNavLink = '#setRecipients';
  const previewRecipientsNavLink = '#previewRecipients';

  return (
    <>
      <Tab.Container
        defaultActiveKey={location.hash || setRecipientsNavLink}
        onSelect={(activeKey) => activeKey === previewRecipientsNavLink && updateRecipientsForPreview()}
      >
        <ListGroup horizontal className="mb-3 w-50">
          <ListGroup.Item bsPrefix="listGroupItem submissions" action href={setRecipientsNavLink} as="button">
            Set
          </ListGroup.Item>
          <ListGroup.Item bsPrefix="listGroupItem submissions" action href={previewRecipientsNavLink} as="button">
            Preview
          </ListGroup.Item>
        </ListGroup>
        <Tab.Content>
          <Tab.Pane eventKey={setRecipientsNavLink}>
            <Form onSubmit={submitForm} className="mb-3 recipientsForm">
              <AccessibleFieldSet legend="Survey Instance" legendClassName="mb-2">
                <MultiSelectFormWrapper
                  controlId="survey-instance-filter"
                  selectionType={surveyInstanceSelectionType}
                  setSelectionType={setSurveyInstanceSelectionType}
                  isInvalid={false}
                  allowNone={false}
                  setDirty={setDirty}
                >
                  <Form.Group className="mb-3" controlId="survey-instance">
                    {surveyInstanceSelectionPattern.map((si) => (
                      <Form.Check
                        key={`survey-instance-${si.surveyId}-${si.timePeriodId}`}
                        inline
                        label={si.displayName}
                        name="survey-instance"
                        type="checkbox"
                        id={`survey-instance-${si.surveyId}-${si.timePeriodId}`}
                        checked={si.isSelected}
                        onChange={(e) => setSurveyInstanceSelected(si.surveyId, si.timePeriodId, e.target.checked)}
                      />
                    ))}
                  </Form.Group>
                </MultiSelectFormWrapper>
              </AccessibleFieldSet>

              <AccessibleFieldSet legend="Submission Status" legendClassName="mb-2">
                <MultiSelectFormWrapper
                  controlId="submission-status-filter"
                  selectionType={submissionStatusSelectionType}
                  setSelectionType={setSubmissionStatusSelectionType}
                  isInvalid={false}
                  allowNone={false}
                  setDirty={setDirty}
                >
                  <Form.Group className="mb-3" controlId="submission-status">
                    {submissionStatusSelectionPattern.map((ss) => (
                      <Form.Check
                        key={`submission-status-${ss.value}`}
                        inline
                        label={ss.displayName}
                        name="submission-status"
                        type="checkbox"
                        id={`submission-status-${ss.value}`}
                        checked={ss.isSelected}
                        onChange={(e) => setSubmissionStatusSelected(ss.value, e.target.checked)}
                      />
                    ))}
                  </Form.Group>
                </MultiSelectFormWrapper>
              </AccessibleFieldSet>

              <AccessibleFieldSet legend="Provider Size" legendClassName="mb-2">
                <MultiSelectFormWrapper
                  controlId="provider-size-filter"
                  selectionType={providerSizeSelectionType}
                  setSelectionType={setProviderSizeSelectionType}
                  isInvalid={false}
                  allowNone={false}
                  setDirty={setDirty}
                >
                  <Form.Group className="mb-3" controlId="provider-size">
                    {providerSizeSelectionPattern.map((ps) => (
                      <Form.Check
                        key={`provider-size-${ps.value}`}
                        inline
                        label={ps.displayName}
                        name="provider-size"
                        type="checkbox"
                        id={`provider-size-${ps.value}`}
                        checked={ps.isSelected}
                        onChange={(e) => setProviderSizeSelected(ps.value, e.target.checked)}
                      />
                    ))}
                  </Form.Group>
                </MultiSelectFormWrapper>
              </AccessibleFieldSet>

              <AccessibleFieldSet legend="Reporting Status" legendClassName="mb-2">
                <MultiSelectFormWrapper
                  controlId="provider-reporting-status-filter"
                  selectionType={reportingStatusSelectionType}
                  setSelectionType={setReportingStatusSelectionType}
                  isInvalid={false}
                  allowNone={false}
                  setDirty={setDirty}
                >
                  <Form.Group className="mb-3" controlId="provider-reporting-status">
                    {reportingStatusSelectionPattern.map((rs) => (
                      <Form.Check
                        key={`provider-reporting-status-${rs.value}`}
                        inline
                        label={rs.displayName}
                        name="provider-reporting-status"
                        type="checkbox"
                        id={`provider-reporting-status-${rs.value}`}
                        checked={rs.isSelected}
                        onChange={(e) => setReportingStatusSelected(rs.value, e.target.checked)}
                      />
                    ))}
                  </Form.Group>
                </MultiSelectFormWrapper>
              </AccessibleFieldSet>

              <AccessibleFieldSet legend="Provider Type" legendClassName="mb-2">
                <MultiSelectFormWrapper
                  controlId="provider-type-filter"
                  selectionType={providerTypeSelectionType}
                  setSelectionType={setProviderTypeSelectionType}
                  isInvalid={false}
                  allowNone={false}
                  setDirty={setDirty}
                >
                  <Form.Group className="mb-3" controlId="provider-type">
                    {providerTypeSelectionPattern.map((pt) => (
                      <Form.Check
                        key={`provider-type-${pt.value}`}
                        inline
                        label={pt.displayName}
                        name="provider-type"
                        type="checkbox"
                        id={`provider-type-${pt.value}`}
                        checked={pt.isSelected}
                        onChange={(e) => setProviderTypeSelected(pt.value, e.target.checked)}
                      />
                    ))}
                  </Form.Group>
                </MultiSelectFormWrapper>
              </AccessibleFieldSet>

              <AccessibleFieldSet legend="Excluded Providers" legendClassName="mb-2">
                <Form.Group className="mb-3">
                  <InputGroup>
                    <Form.Control aria-label="Excluded Provider Summary" disabled value={exclusionsString} />
                    <PrimaryButton onClick={toggleExclusionsExpanded}>
                      {isExclusionsExpanded ? 'Hide Excluded Providers' : 'Show Excluded Providers'}
                    </PrimaryButton>
                  </InputGroup>
                  {isExclusionsExpanded && (
                    <div className="toggleContainer">
                      <ExcludedProvidersTable
                        providerList={excludedProvidersTableData}
                        onUpdateProviders={updateExcludedProviders}
                        setDirty={setDirty}
                      />
                    </div>
                  )}
                </Form.Group>
              </AccessibleFieldSet>

              <AccessibleFieldSet legend="Organisational Contacts" legendClassName="mb-2">
                <MultiSelectFormWrapper
                  controlId="organisational-contacts-filter"
                  selectionType={organisationalContactsSelectionType}
                  setSelectionType={setOrganisationalContactsSelectionType}
                  isInvalid={validated && areRecipientsInvalid}
                  allowNone
                  setDirty={setDirty}
                >
                  <Form.Group className="mb-3" controlId="organisational-contacts">
                    {organisationalContactSelectionPattern.map((oc) => (
                      <Form.Check
                        key={`organisational-contacts-${oc.value}`}
                        inline
                        label={oc.displayName}
                        name="organisational-contacts"
                        type="checkbox"
                        id={`organisational-contacts-${oc.value}`}
                        checked={oc.isSelected}
                        onChange={(e) => setOrganisationalContactSelected(oc.value, e.target.checked)}
                        {...getValidityProps(validated && areRecipientsInvalid, 'profilesFeedback')}
                      />
                    ))}
                  </Form.Group>
                </MultiSelectFormWrapper>
              </AccessibleFieldSet>

              <AccessibleFieldSet legend="User Profiles" legendClassName="mb-2">
                <MultiSelectFormWrapper
                  controlId="user-profile-filter"
                  selectionType={userProfilesSelectionType}
                  setSelectionType={setUserProfilesSelectionType}
                  isInvalid={validated && areRecipientsInvalid}
                  allowNone
                  setDirty={setDirty}
                >
                  <Form.Group className="mb-3" controlId="user-profile">
                    {userProfilesSelectionPattern.map((p) => (
                      <Form.Check
                        key={`profile-${p.value}`}
                        inline
                        label={p.displayName}
                        name="user-profile"
                        type="checkbox"
                        id={`user-profile-${p.value}`}
                        checked={p.isSelected}
                        onChange={(e) => setUserProfileSelected(p.value, e.target.checked)}
                        {...getValidityProps(validated && areRecipientsInvalid, 'profilesFeedback')}
                      />
                    ))}
                  </Form.Group>
                </MultiSelectFormWrapper>
              </AccessibleFieldSet>
              <AccessibleFeedback displayFeedback={validated && areRecipientsInvalid} id="profilesFeedback">
                You must choose at least one user role and/or at least one organisational contact
              </AccessibleFeedback>
            </Form>

            <div className="d-flex gap-2">
              <LinkButton to={composePath} variant="outline-primary" disabled={isSaving}>
                Cancel
              </LinkButton>
              {!isSaving ? (
                <PrimaryButton type="submit" onClick={() => submitForm()} disabled={errorMessage !== undefined}>
                  Save and Return
                </PrimaryButton>
              ) : (
                <LoadingButton message="Save and Return" />
              )}
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey={previewRecipientsNavLink}>
            <EmailRecipientsView
              emailRecipients={emailRecipients}
              recipientsFilter={recipientsFilter}
              surveyList={surveyList}
              profileList={profileList}
              providerList={providerList}
              isPreview
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
      {errorMessage && (
        <Alert className="mt-3" variant="danger">
          {errorMessage}
        </Alert>
      )}
    </>
  );
};
