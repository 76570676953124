import ApiClient, { APIHelpers, handleAPIRequest } from './ApiClient';
import { Tag } from './CommonTypes';

export type FaqRequest = {
  id?: string;
  title: string;
  content: string;
  publishDate?: Date | null;
  priority?: number;
  tagIds: number[];
};

export type FaqPriority = {
  id: string;
  priority: number;
};

type FaqsPriorityRequest = {
  faqsPriorityList: FaqPriority[];
};

export type Faq = {
  id: string;
  title: string;
  content: string;
  publishDateTime: string;
  priority: number;
  updatedPriority?: boolean;
  tags: Tag[];
};

const FaqsApiRoot = 'Faqs';
const FaqsApiPaths = {
  FAQS: FaqsApiRoot,
  FAQ: (faqId: string) => `${FaqsApiRoot}/${faqId}`,
  CREATE_FAQ: `${FaqsApiRoot}/create`,
};

const faqsApi = (apiClient: ApiClient) => ({
  getFaqs: () => {
    return handleAPIRequest(apiClient.get(FaqsApiPaths.FAQS), APIHelpers.json<Faq[]>, APIHelpers.standardError);
  },
  editFaqsPriority: (faqsPriorityRequest: FaqsPriorityRequest) => {
    return handleAPIRequest(
      apiClient.patch(FaqsApiPaths.FAQS, faqsPriorityRequest),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  getFaq: (faqId: string) => {
    return handleAPIRequest(apiClient.get(FaqsApiPaths.FAQ(faqId)), APIHelpers.json<Faq>, APIHelpers.standardError);
  },
  
  editFaq: (faqId: string, faqRequest: FaqRequest) => {
    return handleAPIRequest(
      apiClient.patch(FaqsApiPaths.FAQ(faqId), faqRequest),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },
  
  deleteFaq: (faqId: string) => {
    return handleAPIRequest(apiClient.deleteAction(FaqsApiPaths.FAQ(faqId)), APIHelpers.none, APIHelpers.standardError);
  },
  
  createFaq: (faqRequest: FaqRequest) => {
    return handleAPIRequest(
      apiClient.post(FaqsApiPaths.CREATE_FAQ, faqRequest),
      APIHelpers.json<{ id: number }>,
      APIHelpers.standardError,
    );
  },
});

export default faqsApi;
