import { SurveyStatus } from 'nrosh-common/Api/SubmissionsApi';
import { SurveyFrequency, SurveyInstance, TimePeriodWithStringDates } from 'nrosh-common/Api/SurveysApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { FormEvent, useEffect, useState } from 'react';
import '@/Components/SurveyForm/SurveyForm.scss';
import { Form, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useErrorReporting } from '@/Components/Errors/DCSErrorBoundary';
import { AccessibleFeedback } from '@/Components/Form/AccessibleFeedback';
import { UtcDatePicker } from '@/Components/Form/FormDatePicker';
import LinkButton from '@/Components/Links/LinkButton';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import SurveyFormButton from '@/Components/SurveyForm/SurveyFormButton';
import Description from '@/Components/SurveyForm/SurveyFormDescription';
import TooltippedInfoIcon from '@/Components/Tooltips/TooltippedInfoIcon';
import { SurveysApi } from '@/Helpers/Apis';
import { getValidityProps } from '@/Helpers/Forms';
import { containsANonWhitespaceCharacterRegex } from '@/Helpers/Validation';
import useUnsavedChangesWarning from '@/Hooks/useUnsavedChangesWarning';

type Props = {
  initialValues: SurveyInstance;
  onSave: (
    surveyData: SurveyInstance,
    notify: boolean,
  ) => Promise<{ success: boolean; redirectPath?: string; errorMessage?: string }>;
  pathOnCancel: string;
  isCreate: boolean;
};

const datesAreEqual = (a: Date | null, b: Date | null): boolean => !!a && !!b && a.getTime() === b.getTime();

const SurveyForm: (props: Props) => JSX.Element = ({ initialValues, onSave, pathOnCancel, isCreate }) => {
  const navigate = useNavigate();
  const [timePeriods] = useEndpoint<TimePeriodWithStringDates[]>(SurveysApi.getTimePeriods);
  const [surveyData, setSurveyData] = useState(initialValues);
  const [validated, setValidated] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [setDirty, setPristine] = useUnsavedChangesWarning();
  const [raiseError, clearError] = useErrorReporting();
  const [isNotifying, setIsNotifying] = useState<boolean>(false);
  const isSurveyClosed = surveyData.surveyStatus === SurveyStatus.Closed;

  useEffect(() => {
    setSurveyData(initialValues);
  }, [initialValues]);

  if (!timePeriods) {
    return <LoadingMessage />;
  }

  const availableTimePeriods = timePeriods.filter((tp) => tp.timePeriodType === (surveyData.frequency as string));

  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    const form = event.currentTarget;

    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      event.preventDefault();
      setSaving(true);
      const { success, redirectPath, errorMessage } = await onSave(surveyData, isCreate ? false : isNotifying);

      if (success) {
        setPristine();
        setValidated(false);
        clearError();
      } else {
        raiseError(errorMessage);
      }

      if (redirectPath) {
        navigate(redirectPath, { state: { shouldShowSuccessAlert: true } });
      } else {
        setSaving(false);
      }
    }
  };

  const updateSurveyData = (update: Partial<SurveyInstance>): void => {
    setSurveyData((prevData) => ({ ...prevData, ...update }));
    setDirty();
  };

  const isNameInvalid = !containsANonWhitespaceCharacterRegex.test(surveyData.name) || surveyData.name === '';

  const deadlineHasChanged = !datesAreEqual(
    surveyData.defaultSubmissionDeadline,
    initialValues.defaultSubmissionDeadline,
  );
  const showNotifyCheckbox = !isCreate && surveyData.isAssigned && deadlineHasChanged;

  const allRequiredFieldsHaveValues = !!(
    surveyData.name &&
    surveyData.defaultSubmissionDeadline &&
    surveyData.frequency &&
    surveyData.timePeriodId
  );

  return (
    <Form noValidate onSubmit={onSubmit} className="surveyForm d-grid gap-5">
      <Stack gap={4}>
        <Form.Group controlId="surveyName">
          <Form.Label>Survey name</Form.Label>
          <Form.Control
            required
            type="text"
            name="text"
            onChange={(e) => updateSurveyData({ name: e.target.value })}
            value={surveyData.name}
            pattern={containsANonWhitespaceCharacterRegex.source}
            readOnly={isSurveyClosed}
            {...getValidityProps(validated && isNameInvalid, 'survey-name-feedback')}
          />
          <AccessibleFeedback id="survey-name-feedback" displayFeedback={validated && isNameInvalid}>
            Please enter a survey name
          </AccessibleFeedback>
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="defaultSubmissionDeadline">Submission deadline</Form.Label>
          <UtcDatePicker
            value={surveyData.defaultSubmissionDeadline}
            onChange={(newDate) => updateSurveyData({ defaultSubmissionDeadline: newDate })}
            inputId="defaultSubmissionDeadline"
            required
            disabled={isSurveyClosed}
            {...getValidityProps(validated && !surveyData.defaultSubmissionDeadline)}
          />
          <AccessibleFeedback
            id="submission-deadline-feedback"
            displayFeedback={validated && !surveyData.defaultSubmissionDeadline}
          >
            Please choose a default submission deadline
          </AccessibleFeedback>
        </Form.Group>
        <Description surveyData={surveyData} updateSurveyData={updateSurveyData} />
        <Form.Group>
          <Form.Label className="mb-1" htmlFor="frequency">
            Frequency
          </Form.Label>
          <TooltippedInfoIcon message="This is the frequency at which the survey will be asked" />
          <p className="formInfo mb-2 mt-0">
            {isCreate
              ? 'You will not be able to change this once the survey is created.'
              : 'You cannot change this field as the survey has already been created.'}
          </p>
          <Form.Select
            id="frequency"
            disabled={!isCreate}
            value={surveyData.frequency || ''}
            onChange={(e) => updateSurveyData({ frequency: e.target.value as SurveyFrequency })}
          >
            <option key="none" value="">
              Please select
            </option>
            {Object.values(SurveyFrequency).map((frequency) => (
              <option value={frequency} key={frequency}>
                {frequency}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        {availableTimePeriods.length > 0 && (
          <Form.Group>
            <Form.Label className="mb-1" htmlFor="initialTimePeriod">
              Initial time period
            </Form.Label>
            <TooltippedInfoIcon message="This is when the survey will start" />
            <p className="formInfo mb-2 mt-0">
              {isCreate
                ? 'You will not be able to change this once the survey is created.'
                : 'You cannot change this field as the survey has already been created.'}
            </p>
            <Form.Select
              id="initialTimePeriod"
              disabled={!isCreate}
              value={surveyData.timePeriodId || ''}
              onChange={(e) => updateSurveyData({ timePeriodId: e.target.value ? parseInt(e.target.value, 10) : null })}
              required
              {...getValidityProps(validated && surveyData.timePeriodId === null, 'initial-time-period-feedback')}
            >
              <option key="none" value="">
                Please select
              </option>
              {availableTimePeriods.map((timePeriod) => (
                <option key={timePeriod.id} value={timePeriod.id.toString()}>
                  {timePeriod.name}
                </option>
              ))}
            </Form.Select>
            <AccessibleFeedback
              id="initial-time-period-feedback"
              displayFeedback={validated && surveyData.timePeriodId === null}
            >
              Please select a time period
            </AccessibleFeedback>
          </Form.Group>
        )}
      </Stack>

      {!isSurveyClosed && (
        <>
          {showNotifyCheckbox && (
            <Form.Check
              className="mb-3"
              type="checkbox"
              id="notify-toggle"
              label="Notify provider users"
              onChange={(e) => setIsNotifying(e.target.checked)}
              checked={isNotifying}
            />
          )}
          <div className="d-flex gap-3">
            <LinkButton to={pathOnCancel} variant="outline-primary" disabled={isSaving}>
              Cancel
            </LinkButton>
            <SurveyFormButton
              text={isCreate ? 'Create new survey' : 'Save changes'}
              isSaving={isSaving}
              disabled={!allRequiredFieldsHaveValues}
            />
          </div>
        </>
      )}
    </Form>
  );
};

export default SurveyForm;
