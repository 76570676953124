import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { useRef } from 'react';
import { IconContext } from 'react-icons';
import { FiTrash2 } from 'react-icons/fi';
import MultiFileUploadArea from '@/Components/FileUpload/MultiFileUploadArea';
import '@/Components/FileUpload/UploadedFileManager.scss';

export type FileItem = {
  fileName: string;
  fileObject: File | null;
};

export type UploadedFileManagerProps = {
  files: FileItem[];
  setFiles: ReactStateSetter<FileItem[]>;
  setDirty?: () => void;
  isInvalid?: boolean;
};

type UploadedFileProps = {
  fileName: string;
  onDelete: () => void;
};

const UploadedFile = ({ fileName, onDelete }: UploadedFileProps): JSX.Element => {
  const { current: fiTrash2IconContext } = useRef({ size: '1.25rem' });

  return (
    <div className="fileItemContainer">
      <span>{fileName}</span>
      <button type="button" aria-label={`Delete ${fileName}`} className="iconButton" onClick={onDelete}>
        <IconContext.Provider value={fiTrash2IconContext}>
          <FiTrash2 />
        </IconContext.Provider>
      </button>
    </div>
  );
};

export const MultipleFileUploadManager = ({
  files,
  setFiles,
  setDirty,
  isInvalid = false,
}: UploadedFileManagerProps): JSX.Element => {
  const fileUploadHandler = async (fileList: File[]): Promise<void> => {
    if (setDirty) {
      setDirty();
    }
    const newFileNames = fileList.map((f) => f.name);
    const existingFileNames = files.map((fi) => fi.fileName);

    const newFileItems = files.map((fi) => {
      if (newFileNames.includes(fi.fileName)) {
        return { ...fi, fileObject: fileList.find((f) => f.name === fi.fileName)! };
      }
      return fi;
    });

    fileList.forEach((file) => {
      if (!existingFileNames.includes(file.name)) {
        newFileItems.push({ fileName: file.name, fileObject: file });
      }
    });

    setFiles(newFileItems);
  };

  const fileRemovalHandler = async (fileName: string): Promise<void> => {
    if (setDirty) {
      setDirty();
    }
    setFiles((oldFiles) => oldFiles.filter((fi) => fi.fileName !== fileName));
  };

  return (
    <>
      <MultiFileUploadArea isInvalid={isInvalid} onChange={fileUploadHandler} />
      <div className="uploadedFiles">
        <ul>
          {files.map((a) => (
            <li key={`file-${a.fileName}`}>
              <UploadedFile fileName={a.fileName} onDelete={() => fileRemovalHandler(a.fileName)} />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
