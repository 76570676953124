import { UserStatus } from 'nrosh-common/Api/UsersApi';

export const UserStatusNameMapping: Record<UserStatus, string> = {
  AwaitingActivation: 'Awaiting Activation',
  AwaitingReactivation: 'Awaiting Reactivation',
  Active: 'Active',
  Locked: 'Locked',
  Disabled: 'Disabled',
  Deleted: 'Deleted',
  AwaitingEmailConfirmation: 'Awaiting Email Confirmation',
};
