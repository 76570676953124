import { StandardErrorResponse } from 'nrosh-common/Api/ApiClient';
import { SubmissionDetail, mapSubmissionDetailResponse } from 'nrosh-common/Api/SubmissionsApi';
import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { useEndpointConditionallyWithProcessing } from 'nrosh-common/Hooks/useEndpoint';
import { SubmissionsApi } from '@/Helpers/Apis';

const useSubmissionDetail = (
  submissionId: number | string,
  condition: boolean = true,
): [
  SubmissionDetail | null,
  ReactStateSetter<SubmissionDetail | null>,
  StandardErrorResponse<string> | null,
  boolean,
] =>
  useEndpointConditionallyWithProcessing(
    SubmissionsApi.getSubmission,
    condition,
    mapSubmissionDetailResponse,
    submissionId,
  );

export default useSubmissionDetail;
