import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { Col, Form, Row } from 'react-bootstrap';
import DateTimePicker from '@/Pages/Content/DateTimePicker';

type ContentDateTimePickerProps = {
  publishAtSpecificDateTime: boolean;
  setPublishAtSpecificDateTime: ReactStateSetter<boolean>;
  dateTime: Date | null;
  setDateTime: ReactStateSetter<Date | null>;
  setDirty: () => void;
};

const ContentDateTimePicker = ({
  publishAtSpecificDateTime,
  setPublishAtSpecificDateTime,
  dateTime,
  setDateTime,
  setDirty,
}: ContentDateTimePickerProps): JSX.Element => {
  const handlePublishAtSpecificDate = (checked: boolean): void => {
    if (!checked) {
      setDateTime(null);
      setDirty();
    }
    setPublishAtSpecificDateTime(checked);
  };

  return (
    <Row className="mb-4">
      <Col sm="auto" className="d-flex align-self-center">
        <Form.Check
          type="checkbox"
          label="Publish at specific time and date"
          onChange={(e) => handlePublishAtSpecificDate(e.target.checked)}
          checked={publishAtSpecificDateTime}
        />
      </Col>
      <DateTimePicker
        dateTime={dateTime}
        setDateTime={setDateTime}
        dateDisabled={!publishAtSpecificDateTime}
        timeDisabled={!publishAtSpecificDateTime || !dateTime}
        allowPastDate={false}
      />
    </Row>
  );
};

export default ContentDateTimePicker;
