import { ContactType } from 'nrosh-common/Api/SurveysApi';
import { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { AccessibleFeedback } from '@/Components/Form/AccessibleFeedback';
import { createContext } from '@/Helpers/Context';
import { debounceOverFirstArg } from '@/Helpers/Debounce';
import '@/Pages/Submissions/Confirmation/ConfirmOrganisationalContactsPage.scss';
import { getValidityProps } from '@/Helpers/Forms';
import { alphanumericOrSpaceRegex, emailRegex, textIsForbidden } from '@/Helpers/Validation';
import {
  OrganisationalContactForm,
  OrganisationalContactFormStringField,
} from '@/Pages/Submissions/Confirmation/ConfirmOrganisationalContactsTypes';
import { useOrganisationalContactsDispatch } from '@/Pages/Submissions/Confirmation/OrganisationalContactsReducer';
import SubmissionConfirmationCheckbox from '@/Pages/Submissions/Confirmation/SubmissionConfirmationCheckbox';

const ContactTypeContext = createContext<ContactType>();

type ConfirmOrganisationalContactInputFormGroupProps = {
  label: string;
  field: OrganisationalContactFormStringField;
  value: string;
  minLength?: number;
  maxLength?: number;
  pattern?: string;
  required?: boolean;
  invalidMessage?: string;
  validated: boolean;
};

const ConfirmOrganisationalContactInputFormGroup = ({
  label,
  field,
  value,
  minLength,
  maxLength,
  pattern,
  required = false,
  invalidMessage,
  validated,
}: ConfirmOrganisationalContactInputFormGroupProps): JSX.Element => {
  const dispatch = useOrganisationalContactsDispatch();
  const contactType = useContext(ContactTypeContext);

  const [inputValue, setInputValue] = useState(value);
  const [showValidation, setShowValidation] = useState(false);

  const onInputInvalid = (): void => {
    setShowValidation(true);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setInputValue(e.target.value);
    setShowValidation(textIsForbidden(e.target.value));
  };

  // Memoise to avoid creating a new debounced function per render cycle (defeating the purpose of the debounce)
  const debouncedDispatch = useMemo(
    () =>
      debounceOverFirstArg((v) =>
        dispatch({ type: 'update-string-field', payload: { contactType: contactType!, field, value: v } }),
      ),
    [dispatch],
  );

  useEffect(() => {
    debouncedDispatch(inputValue);
  }, [inputValue]);

  return (
    <Form.Group className="mb-3 w-100" controlId={`${field}-${contactType}`}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        value={inputValue}
        onChange={onChange}
        required={required}
        minLength={minLength}
        pattern={pattern}
        maxLength={maxLength ?? 256}
        onInvalid={() => onInputInvalid()}
        {...getValidityProps(validated && showValidation, `${field}-${contactType}-feedback`)}
      />
      <AccessibleFeedback displayFeedback={validated && showValidation} id={`${field}-${contactType}-feedback`}>
        {invalidMessage}
      </AccessibleFeedback>
    </Form.Group>
  );
};

type ConfirmOrganisationalContactFormProps = {
  contactType: ContactType;
  contact: OrganisationalContactForm;
  validated: boolean;
};

const ConfirmOrganisationalContactForm = ({
  contactType,
  contact,
  validated,
}: ConfirmOrganisationalContactFormProps): JSX.Element => {
  const dispatch = useOrganisationalContactsDispatch();

  return (
    <div className="mb-6 w-25 d-flex flex-column organisationalContactFormContainer">
      <h4>{contact.formTitle}</h4>
      <ContactTypeContext.Provider value={contactType}>
        <ConfirmOrganisationalContactInputFormGroup
          label="Name"
          field="name"
          value={contact.name}
          required
          validated={validated}
          invalidMessage="Please enter a name"
        />
        <ConfirmOrganisationalContactInputFormGroup
          label="Job title"
          field="jobTitle"
          value={contact.jobTitle}
          required
          validated={validated}
          invalidMessage="Please enter a job title"
        />
        <ConfirmOrganisationalContactInputFormGroup
          label="Email"
          field="email"
          value={contact.email}
          required
          minLength={6}
          pattern={emailRegex.source}
          invalidMessage="Please enter a valid email"
          validated={validated}
        />
        <ConfirmOrganisationalContactInputFormGroup
          label="Phone"
          field="phone"
          value={contact.phone}
          required
          minLength={10}
          validated={validated}
          invalidMessage="Please enter a valid phone number"
        />
        <ConfirmOrganisationalContactInputFormGroup
          label="Address 1"
          field="address1"
          value={contact.address1}
          required
          validated={validated}
          invalidMessage="Please enter an address"
        />
        <ConfirmOrganisationalContactInputFormGroup
          label="Address 2"
          field="address2"
          value={contact.address2}
          validated={validated}
        />
        <ConfirmOrganisationalContactInputFormGroup
          label="Address 3"
          field="address3"
          value={contact.address3}
          validated={validated}
        />
        <ConfirmOrganisationalContactInputFormGroup
          label="Address 4"
          field="address4"
          value={contact.address4}
          validated={validated}
        />
        <ConfirmOrganisationalContactInputFormGroup
          label="Postcode"
          field="postcode"
          value={contact.postcode}
          required
          minLength={5}
          maxLength={8}
          pattern={alphanumericOrSpaceRegex.source}
          invalidMessage="Please enter a valid postcode"
          validated={validated}
        />
        <SubmissionConfirmationCheckbox
          checked={contact.confirmed}
          id={`confirm-checkbox-${contactType}`}
          label="I confirm this contact is correct"
          onChange={(e) =>
            dispatch({
              type: 'update-boolean-field',
              payload: { contactType, field: 'confirmed', value: e.target.checked },
            })
          }
          required
          validated={validated}
        />
      </ContactTypeContext.Provider>
    </div>
  );
};

export default ConfirmOrganisationalContactForm;
