import { UnhandledIntermediateFetchResult } from 'nrosh-common/Api/ApiClient';
import { Tag } from 'nrosh-common/Api/CommonTypes';
import { PublicDocumentMetadata } from 'nrosh-common/Api/PublicDocumentsApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { FormEvent, useReducer, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import FileUploadArea from '@/Components/FileUpload/FileUploadArea';
import { AccessibleFeedback } from '@/Components/Form/AccessibleFeedback';
import LinkButton from '@/Components/Links/LinkButton';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import TagCheckboxes from '@/Components/Tags/TagCheckboxes';
import { TagsApi } from '@/Helpers/Apis';
import { getValidityProps } from '@/Helpers/Forms';
import useUnsavedChangesWarning from '@/Hooks/useUnsavedChangesWarning';
import { adminPages } from '@/Pages/Home/SitePages';
import '@/Pages/Content/PublicDocuments/PublicDocumentForm.scss';

type PublicDocumentFormProps = {
  document?: PublicDocumentMetadata;
  submitButtonMessage: string;
  submitAction: (form: FormData) => UnhandledIntermediateFetchResult<null, string>;
};

const PublicDocumentForm = ({ document, submitButtonMessage, submitAction }: PublicDocumentFormProps): JSX.Element => {
  const [allTags] = useEndpoint<Tag[]>(TagsApi.getContentTags);

  const [error, setError] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [setDirty, setPristine, isDirty] = useUnsavedChangesWarning();
  const [validated, setValidated] = useState<boolean>(false);

  const [title, setTitle] = useState<string | null>(document?.title ?? null);
  const [file, setFile] = useState<File | null>(null);
  const [fileWasChanged, markFileAsChanged] = useReducer(() => true, false);
  const [selectedTagIds, setSelectedTagIds] = useState<number[]>(document?.tags.map((t) => t.id) ?? []);

  const navigate = useNavigate();

  if (!allTags) {
    return <LoadingMessage />;
  }

  const isFileInvalid = !file && (!document || fileWasChanged);

  const onSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (!e.currentTarget.checkValidity() || isFileInvalid) {
      setValidated(true);
      return;
    }

    setPristine();
    setIsSaving(true);

    const formData = new FormData();
    if (file) {
      formData.append(file.name, file, file.name);
    }
    formData.append('fileWasChanged', fileWasChanged.toString());
    selectedTagIds.forEach((id) => formData.append('tagIds', id.toString()));
    if (title) formData.append('title', title);

    const response = await submitAction(formData).raw;

    if (response.ok) {
      navigate(adminPages.AdminPublicDocuments.path);
      return;
    }
    setError(response.value.message);

    setIsSaving(false);
  };

  const initialFileData = document && { name: document.fileName, sizeBytes: document.fileSizeBytes };

  return (
    <Form className="w-25 publicDocumentForm" noValidate onSubmit={onSubmit}>
      <Form.Group controlId="upload" className="mb-3">
        <Form.Label>Select file</Form.Label>
        <FileUploadArea
          aria-label="file"
          value={file}
          initialFileData={initialFileData}
          allowZeroSizeFiles={false}
          onChange={(newFile) => {
            setFile(newFile);
            markFileAsChanged();
            setDirty();
          }}
          {...getValidityProps(validated && isFileInvalid, 'file-feedback')}
        />
        <AccessibleFeedback displayFeedback={validated && isFileInvalid} id="file-feedback">
          Please select a file
        </AccessibleFeedback>
      </Form.Group>
      <Form.Group controlId="title" className="mb-3">
        <Form.Label>Title</Form.Label>
        <Form.Control
          name="title"
          aria-label="title"
          value={title ?? ''}
          required
          onChange={(e) => {
            setTitle(e.target.value);
            setDirty();
          }}
          {...getValidityProps(validated && !title, 'title-feedback')}
        />
        <AccessibleFeedback displayFeedback={validated && !title} id="title-feedback">
          Please provide a title
        </AccessibleFeedback>
      </Form.Group>
      <div className="mb-3 d-flex flex-column gap-2">
        <span>Tags</span>
        <TagCheckboxes
          allTags={allTags}
          selectedTagIds={selectedTagIds}
          setSelectedTagIds={setSelectedTagIds}
          onChange={setDirty}
        />
      </div>
      <div className="d-flex gap-2">
        <LinkButton to={adminPages.AdminPublicDocuments.path} variant="outline-primary" disabled={isSaving}>
          Cancel
        </LinkButton>
        {isSaving ? (
          <LoadingButton message={submitButtonMessage} />
        ) : (
          <PrimaryButton type="submit" disabled={!isDirty}>
            {submitButtonMessage}
          </PrimaryButton>
        )}
      </div>
      {error && (
        <Alert variant="danger" className="mt-3" dismissible onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
    </Form>
  );
};

export default PublicDocumentForm;
