import { PersonalDetails } from 'nrosh-common/Api/AccountApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { Stack } from 'react-bootstrap';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import { AccountApi } from '@/Helpers/Apis';
import PersonalDetailsForm from '@/Pages/Users/PersonalDetailsForm';

const PersonalDetailsPage = (): JSX.Element => {
  const [personalDetails] = useEndpoint<PersonalDetails>(AccountApi.getPersonalDetails);

  if (!personalDetails) {
    return <LoadingMessage />;
  }

  return (
    <Stack gap={5} className="mt-6">
      <h1>Personal details</h1>
      <PersonalDetailsForm personalDetails={personalDetails} />
    </Stack>
  );
};

export default PersonalDetailsPage;
