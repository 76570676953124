import { saveAs } from 'file-saver';
import { ProviderRole, RshRole } from 'nrosh-common/Api/Enums';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import { useContext, useState } from 'react';
import { Stack } from 'react-bootstrap';
import { generatePath, useParams } from 'react-router-dom';
import { TertiaryButton } from '@/Components/Buttons/DCSButton';
import { TertiaryLinkButton } from '@/Components/Buttons/TertiaryLinkButton';
import { DCSErrorContextOutlet, useErrorReporting } from '@/Components/Errors/DCSErrorBoundary';
import ClipboardTask from '@/Components/Icons/ClipboardTask';
import Download from '@/Components/Icons/Download';
import { SubmissionsApi } from '@/Helpers/Apis';
import { providerPages, submissionPages } from '@/Pages/Home/SitePages';

export type ValidationsSectionProps = {
  surveyName: string;
  timePeriodName: string;
  isAdmin: boolean;
};

const ValidationsSection = ({ surveyName, timePeriodName, isAdmin }: ValidationsSectionProps): JSX.Element => {
  const { submissionId, surveyId, timePeriodId } = useParams();
  const auth = useContext(AuthContext);
  const [isDownloading, setDownloading] = useState<boolean>(false);
  const [raiseError, clearError] = useErrorReporting();

  const downloadValidations = async (): Promise<void> => {
    setDownloading(true);
    try {
      const validations = await SubmissionsApi.downloadSubmissionValidationIssues(submissionId!, false);
      saveAs(URL.createObjectURL(validations), `${surveyName}_${timePeriodName}-Validations.csv`.replaceAll(' ', '_'));
      clearError();
    } catch {
      raiseError();
    }
    setDownloading(false);
  };

  return (
    <Stack gap={4}>
      <h2>Validations</h2>
      <DCSErrorContextOutlet />
      <Stack direction="horizontal" gap={3}>
        {auth.hasOneOfRoles(RshRole.ViewSurveyData, ProviderRole.ViewSurveyData) && (
          <TertiaryButton icon={<Download />} onClick={downloadValidations} disabled={isDownloading}>
            Export all failing validations
          </TertiaryButton>
        )}
        {auth.hasOneOfRoles(RshRole.ViewSurveyData, ProviderRole.ViewSurveyData) && (
          <TertiaryLinkButton
            icon={<ClipboardTask />}
            to={
              isAdmin
                ? generatePath(submissionPages.AdminReviewCrossPartValidations.path, {
                    surveyId,
                    timePeriodId,
                    submissionId,
                  })
                : generatePath(providerPages.ReviewCrossPartValidations.path, { submissionId })
            }
            text="Review cross-part validations"
          />
        )}
      </Stack>
    </Stack>
  );
};

export default ValidationsSection;
