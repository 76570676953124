import { Checkbox } from '@mui/material';
import { Tag } from 'nrosh-common/Api/CommonTypes';
import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { ChangeEvent } from 'react';

type TagCheckboxesProps = {
  allTags: Tag[];
  selectedTagIds: number[];
  setSelectedTagIds: ReactStateSetter<number[]>;
  onChange?: () => void;
};

const TagCheckboxes = ({ allTags, selectedTagIds, setSelectedTagIds, onChange }: TagCheckboxesProps): JSX.Element => (
  <div className="d-flex flex-wrap">
    {allTags.map((tag) => (
      <div key={tag.id}>
        <Checkbox
          id={`tag-${tag.id}`}
          color="nrosh"
          checked={selectedTagIds.includes(tag.id)}
          disableRipple
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setSelectedTagIds((prev) => {
              if (event.target.checked) {
                return [...prev, tag.id];
              }

              return prev.filter((id) => id !== tag.id);
            });

            if (onChange !== undefined) {
              onChange();
            }
          }}
        />
        <label htmlFor={`tag-${tag.id}`}>{tag.name}</label>
      </div>
    ))}
  </div>
);

export default TagCheckboxes;
