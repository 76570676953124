import { DataDictionaryError, DataDictionaryErrorName } from 'nrosh-common/Api/SurveyPartsApi';

const warningMessage = (error: DataDictionaryError): string => {
  switch (error.errorName) {
    case DataDictionaryErrorName.InvalidDataPointIdPrefix:
      return `${error.dataPointId}: This data point could not be uploaded because the ID does not have the prefix "DP_"`;

    case DataDictionaryErrorName.InvalidDataPointId:
      return `${error.dataPointId}: This data point could not be uploaded because the ID contained characters other than alphanumeric and '_'`;

    case DataDictionaryErrorName.DuplicateDataPointIdsInDataDictionary:
      return `${error.dataPointId}: This data point ID was identified as a duplicate in the upload file. You may wish to check that the uploaded definition is correct.`;

    case DataDictionaryErrorName.DuplicateDataPointIdsInSurvey:
      return `${error.dataPointId}: This data point could not be uploaded because it already appears in the data dictionary of another part of the survey.`;

    case DataDictionaryErrorName.InvalidDataPointType:
      return `${error.dataPointId}: This data point could not be uploaded because the Type is invalid. Type must be "Text", "Number", "Date" or "List:[name of list]" where [name of list] is the name of a list that will be created in the survey template file (for example, List:YesOrNo). The list name must be alphanumeric with no spaces.`;

    case DataDictionaryErrorName.InvalidChangedThisVersion:
      return `${error.dataPointId}: This data point could not be uploaded because the value in the "Changed This Version" column is invalid. Please enter YES, NO, or leave the field blank (defaults to NO)`;

    case DataDictionaryErrorName.InvalidAllowNull:
      return `${error.dataPointId}: This data point could not be uploaded because the value in the "Allow Null" column is invalid. Please enter YES, NO, or leave the field blank (defaults to YES)`;

    case DataDictionaryErrorName.DataPointTypeAndDecimalPlacesMismatch:
      return `${error.dataPointId}: This data point could not be uploaded because the number of Decimal Places is invalid. If data point type is Number, this field must contain a positive integer greater than or equal to zero (or left blank, which defaults to 0). If data point type is Text, this field must be left blank.`;

    case DataDictionaryErrorName.InvalidValueRestriction:
      return `${error.dataPointId}: This data point could not be uploaded because the value in the "Value Restriction" column is invalid. If data point type is Number, this field must be blank or contain one of the following values: Positive Locked, Positive Unlocked, Negative Locked, Negative Unlocked. If data point type is Text, this field must be blank.`;

    case DataDictionaryErrorName.InvalidQuestionReference:
      return `${error.dataPointId}: This data point could not be uploaded because the Question Reference is invalid. This field must contain an alphanumeric value (may include underscore) and be no more than 30 characters long.`;

    case DataDictionaryErrorName.InvalidShortName:
      return `${error.dataPointId}: This data point could not be uploaded because the Short Name is invalid. This field must contain a value that is no more than 50 characters long.`;

    case DataDictionaryErrorName.InvalidDimensions:
      return `${error.dataPointId}: This data point could not be uploaded because the Dimension columns are invalid. If this is a dimensional data point, the Dimension column field(s) must contain an existing Dimension Id, otherwise these fields should be left blank.`;

    case DataDictionaryErrorName.RepeatedDimension:
      return `${error.dataPointId}: This data point could not be uploaded because it has the same value for Dimension1 and Dimension2. 2D data points must have two distinct dimensions.`;

    case DataDictionaryErrorName.Dimension1NotSpecified:
      return `${error.dataPointId}: This data point could not be uploaded because it has a value specified for Dimension2, but Dimension1 is blank. 2D data points must have two distinct dimensions and 1D data points must have a value specified for Dimension1 only.`;

    case DataDictionaryErrorName.DimensionInUse:
      return `${error.dataPointId}: This data point could not be uploaded because the Dimension has changed and the data point is already in use in this or another instance of this survey. To resolve this issue: locate the instance(s) of the survey where the data point is in use, unassign the survey from all providers, then remove the data point from the survey template file and re-upload.`;

    case DataDictionaryErrorName.DataPointInUse:
      return `${error.dataPointId}: This data point could not be uploaded because the Type has changed and the data point is already in use in this or another instance of this survey. To resolve this issue: locate the instance(s) of the survey where the data point is in use, unassign the survey from all providers, then remove the data point from the survey template file and re-upload.`;

    default:
      return `${error.dataPointId}: This data point could not be uploaded.`;
  }
};

export default warningMessage;
