import { Dispatch, SetStateAction } from 'react';
import { Col, Row } from 'react-bootstrap';
import DateTimePicker from '@/Pages/Content/DateTimePicker';

export type FilterDateRangePickerProps = {
  fromDateTime: Date | null;
  setFromDateTime: Dispatch<SetStateAction<Date | null>>;
  toDateTime: Date | null;
  setToDateTime: Dispatch<SetStateAction<Date | null>>;
  showTime?: boolean;
};

const FilterDateRangePicker = (props: FilterDateRangePickerProps): JSX.Element => {
  const { fromDateTime, setFromDateTime, toDateTime, setToDateTime, showTime = true } = props;

  return (
    <Row>
      <Col sm="auto" className="align-self-center">
        <div>From:</div>
        <DateTimePicker
          ariaLabel="Upload Date Minimum"
          dateTime={fromDateTime}
          setDateTime={setFromDateTime}
          dateDisabled={false}
          timeDisabled={!fromDateTime}
          allowPastDate
          showTime={showTime}
        />
      </Col>
      <Col sm="auto" className="align-self-center">
        <div>To:</div>
        <DateTimePicker
          ariaLabel="Upload Date Maximum"
          dateTime={toDateTime}
          setDateTime={setToDateTime}
          dateDisabled={false}
          timeDisabled={!toDateTime}
          allowPastDate
          showTime={showTime}
        />
      </Col>
    </Row>
  );
};

export default FilterDateRangePicker;
