import { LoadingSpinner } from '@/Components/Loading/LoadingSpinner';

export const LoadingMessage = (): JSX.Element => (
  <div className="text-center m-6">
    <LoadingSpinner />
    <div>
      <span aria-hidden="true">Loading...</span>
    </div>
  </div>
);
