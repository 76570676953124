export const joinWithSpecialLastJoin = (array: string[], primarySeparator: string, finalSeparator: string) =>
  array.slice(0, -1).join(primarySeparator) + finalSeparator + array.slice(-1);

/**
 * Mimics C#'s nameof for variables, with the caveat that the operand must be wrapped in an object first
 * @param o An object containing the variable in question, e.g. `{foo}`: note this is equivalent to `{foo: foo}`
 * @returns The variable's name, with type narrowed to the string literal: e.g. `nameof({foo}): "foo"`
 */
// Take the only key of the passed object and safely cast that back to the generic string literal type inferred from the
// object itself
export const nameof = <TLiteral extends string>(o: Record<TLiteral, unknown>): TLiteral =>
  Object.keys(o)[0] as TLiteral;
