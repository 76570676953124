import { CellStyle } from './Enums';
import ApiClient, { APIHelpers, handleAPIRequest } from './ApiClient';
import { SurveyStatus } from './SubmissionsApi';

export type SubmitSurveyPartResponse = {
  errors: SurveyPartError[];
};

export enum SurveyPartErrorSeverity {
  Warning = 'Warning',
  Error = 'Error',
}

export enum SurveyPartErrorName {
  DataPointHasStaticValue = 'DataPointHasStaticValue',
  DataPointWithoutFormulaIsNotDataInput = 'DataPointWithoutFormulaIsNotDataInput',
  TotalCellHasNoFormula = 'TotalCellHasNoFormula',
  TotalCellHasNoDataPoint = 'TotalCellHasNoDataPoint',
  DataInputCellHasNoDataPoint = 'DataInputCellHasNoDataPoint',
  FormulaHasIncorrectCellStyle = 'FormulaHasIncorrectCellStyle',
  FormulaHasNoDataPoint = 'FormulaHasNoDataPoint',
  DimensionsMissingForDimensionalDataPoint = 'DimensionsMissingForDimensionalDataPoint',
  DimensionCellHasInvalidContent = 'DimensionCellHasInvalidContent',
  DimensionCellHasDuplicateContent = 'DimensionCellHasDuplicateContent',
  VariableDimensionCellIsNotDataInput = 'VariableDimensionCellIsNotDataInput',
  OneDimensionalDataPointUsedOverTwoDimensions = 'OneDimensionalDataPointUsedOverTwoDimensions',
  DimensionalDataPointNotConnectedCellBlock = 'DimensionalDataPointNotConnectedCellBlock',
  DimensionNotSingleWidthCellBlock = 'DimensionNotSingleWidthCellBlock',
  DimensionalDataPointDoesNotAlignWithDimension = 'DimensionalDataPointDoesNotAlignWithDimension',
  DimensionalDataPointInMultipleRegions = 'DimensionalDataPointInMultipleRegions',
  DimensionHasNoUsedDataPoints = 'DimensionHasNoUsedDataPoints',
  InvalidFormula = 'InvalidFormula',
  ValidationTypeNotValid = 'ValidationTypeNotValid',
  DuplicateValidationReference = 'DuplicateValidationReference',
  ValidationReferenceFormat = 'ValidationReferenceFormat',
  ValidationReferenceInUse = 'ValidationReferenceInUse',
  DimensionalValueValidationRefersToNonExistentMember = 'DimensionalValueValidationRefersToNonExistentMember',
  ShortTextFormat = 'ShortTextFormat',
  ListTypeIsNotSpecified = 'ListTypeIsNotSpecified',
  ListTypeIsSpecifiedMoreThanOnce = 'ListTypeIsSpecifiedMoreThanOnce',
  CellHasUnrecognisedStyle = 'CellHasUnrecognisedStyle',
  SurveyIsClosed = 'SurveyIsClosed',
  SurveyTabContainsWorkbookScopedNamedRanges = 'SurveyTabContainsWorkbookScopedNamedRanges',
  MultipleSlicersOnTab = 'MultipleSlicersOnTab',
  DimensionRegionRemoved = 'DimensionRegionRemoved',
  CalculatedDataPointUsedInMultipleTabs = 'CalculatedDataPointUsedInMultipleTabs',
}

export type SurveyPartError = {
  rowNumber: number | null;
  columnNumber: number | null;
  sheetName: string;
  listName: string | null;
  dataPointId: string | null;
  dimensionId: string | null;
  severity: SurveyPartErrorSeverity;
  errorName: SurveyPartErrorName;
  badlyScopedNames: string[] | null;
};

export type DataPoint = {
  id: string;
  section: string;
  description: string;
  dimension1?: string;
  dimension2?: string;
  type: string;
  inUse: boolean;
  helpTextField: string | null;
  allowNull: boolean;
  // This isn't null here - every data point _must_ have a questionReference
  questionReference: string;
  changedThisVersion: boolean;
  decimalPlaces: number;
  valueRestriction: string;
  dateOfLastChange: string;
  shortName: string;
};

export type DataPointResponse = DataPoint & {
  inUse: boolean;
};

export type DataDictionaryResponse = {
  surveyName: string;
  surveyPartName: string;
  dataPoints: DataPoint[];
  surveyId: number;
};

export type SurveyCell = {
  row: number;
  column: number;
  address: string;
  style: CellStyle | null;
  content: string | null;
  formula: string | null;
  dataPointId: string | null;
  dimensionId: string | null;
};

type RowHeight = {
  rowNumber: number;
  height: number;
};

type ColumnWidth = {
  columnNumber: number;
  width: number;
};

type FreezePaneSpan = {
  columns: number;
  rows: number;
};

export type SurveyTab = {
  index: number;
  title: string;
  cells: SurveyCell[];
  rowHeights: RowHeight[];
  columnWidths: ColumnWidth[];
  minRowNumber: number;
  minColNumber: number;
  freezePaneSpan: FreezePaneSpan;
};

export type SurveyPartValidation = {
  validationId: string;
  validationFormula: string;
  validationType: string;
  description: string | null;
  helpText: string | null;
  category: string | null;
  row: number;
};

export type SurveyList = {
  name: string;
  row: number;
  column: number;
  items: string[];
};

export type SurveyPart = {
  surveyTabs: SurveyTab[];
  surveyValidations: SurveyPartValidation[];
  surveyLists: SurveyList[];
};

export type SurveyPartResponse = {
  surveyPartId: number;
  surveyPartName: string;
  surveyId: number;
};

export type EditSurveyPartRequest = {
  name: string;
};

export enum DataDictionaryErrorName {
  InvalidDataPointIdPrefix = 'InvalidDataPointIdPrefix',
  InvalidDataPointId = 'InvalidDataPointId',
  DuplicateDataPointIdsInDataDictionary = 'DuplicateDataPointIdsInDataDictionary',
  DuplicateDataPointIdsInSurvey = 'DuplicateDataPointIdsInSurvey',
  InvalidDataPointType = 'InvalidDataPointType',
  InvalidChangedThisVersion = 'InvalidChangedThisVersion',
  InvalidAllowNull = 'InvalidAllowNull',
  DataPointTypeAndDecimalPlacesMismatch = 'DataPointTypeAndDecimalPlacesMismatch',
  InvalidValueRestriction = 'InvalidValueRestriction',
  InvalidQuestionReference = 'InvalidQuestionReference',
  InvalidShortName = 'InvalidShortName',
  InvalidDimensions = 'InvalidDimensions',
  RepeatedDimension = 'RepeatedDimension',
  Dimension1NotSpecified = 'Dimension1NotSpecified',
  DimensionInUse = 'DimensionInUse',
  DataPointInUse = 'DataPointInUse',
}

export type DataDictionaryError = {
  dataPointId: string;
  errorName: DataDictionaryErrorName;
};

export type DataDictionaryErrorResponse = {
  dataDictionaryErrors: DataDictionaryError[];
  isMissingDataPointIds: boolean;
};

export type ImportTemplateVersionHistory = {
  surveyPartName: string;
  version: number;
  uploadDate: string;
  surveyPartId: number;
  timePeriodId: number;
};

const formatAsString = (value: boolean | string | number | null) => {
  return value === null || value.toString().trim() === '' ? null : value.toString().trim();
};

const SurveyPartsApiRoot = 'SurveyParts';
export const SurveyPartsApiPaths = {
  ALL_SURVEY_PARTS: SurveyPartsApiRoot,

  DATA_DICTIONARY: (surveyPartId: string) => `${SurveyPartsApiRoot}/${surveyPartId}/DataDictionary`,
  DOWNLOAD_DATA_DICTIONARY: (surveyPartId: string) => `${SurveyPartsApiRoot}/${surveyPartId}/DataDictionary/Download`,
  DATA_POINT: (partId: string, dataPointId: string) =>
    `${SurveyPartsApiRoot}/${partId}/DataDictionary/DataPoints/${dataPointId}`,
  DELETE_UNUSED_DATA_POINTS: (partId: string) => `${SurveyPartsApiRoot}/${partId}/DataDictionary/DeleteUnused`,
  UPDATE_DATA_DICTIONARY: (surveyPartId: string) => `${SurveyPartsApiRoot}/${surveyPartId}/UpdateDataDictionary`,

  SURVEY_PART: (surveyPartId: string, timePeriodId: string) =>
    `${SurveyPartsApiRoot}/${surveyPartId}/TimePeriods/${timePeriodId}`,
  SURVEY_PART_IMPORT_TEMPLATE: (surveyPartId: number, timePeriodId: number) =>
    `${SurveyPartsApiRoot}/${surveyPartId}/TimePeriods/${timePeriodId}/Template`,
  SURVEY_PART_IMPORT_TEMPLATE_VERSION: (surveyPartId: string, timePeriodId: string, version: string) =>
    `${SurveyPartsApiRoot}/${surveyPartId}/TimePeriods/${timePeriodId}/Template/${version}`,
  SURVEY_STATUS: (surveyPartId: number, timePeriodId: number) =>
    `${SurveyPartsApiRoot}/${surveyPartId}/TimePeriods/${timePeriodId}/surveyStatus`,
  SURVEY_PART_TEMPLATE_VERSION_HISTORY: (surveyPartId: number, timePeriodId: number) =>
    `${SurveyPartsApiRoot}/${surveyPartId}/TimePeriods/${timePeriodId}/surveyPartTemplateVersionHistory`,
};

const surveyPartsApi = (apiClient: ApiClient) => ({
  getAllSurveyParts: () => {
    return handleAPIRequest(
      apiClient.get(SurveyPartsApiPaths.ALL_SURVEY_PARTS),
      APIHelpers.json<SurveyPartResponse[]>,
      APIHelpers.standardError,
    );
  },

  getDataDictionary: (surveyPartId: string) => {
    return handleAPIRequest(
      apiClient.get(SurveyPartsApiPaths.DATA_DICTIONARY(surveyPartId)),
      APIHelpers.json<DataDictionaryResponse>,
      APIHelpers.standardError,
    );
  },

  uploadDataDictionary: (formData: FormData, surveyPartId: string) => {
    return handleAPIRequest(
      apiClient.patchFile(SurveyPartsApiPaths.DATA_DICTIONARY(surveyPartId), formData),
      APIHelpers.json<DataDictionaryErrorResponse>,
      APIHelpers.standardError,
    );
  },

  downloadDataDictionary: (surveyPartId: string) => {
    return apiClient.getFile(SurveyPartsApiPaths.DOWNLOAD_DATA_DICTIONARY(surveyPartId));
  },

  deleteDataPoint: (partId: string, dataPointId: string) => {
    return handleAPIRequest(
      apiClient.deleteAction(SurveyPartsApiPaths.DATA_POINT(partId, dataPointId)),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  deleteUnusedDataPoints: (partId: string) => {
    return handleAPIRequest(
      apiClient.put(SurveyPartsApiPaths.DELETE_UNUSED_DATA_POINTS(partId), null),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  updateDataDictionary: (dataPoints: DataPoint[], surveyPartId: string) => {
    const updateDataDictionaryRequest = dataPoints.map((dp) => ({
      ...dp,
      decimalPlaces: formatAsString(dp.decimalPlaces),
      valueRestriction: formatAsString(dp.valueRestriction),
    }));
    return handleAPIRequest(
      apiClient.patch(SurveyPartsApiPaths.UPDATE_DATA_DICTIONARY(surveyPartId), updateDataDictionaryRequest),
      APIHelpers.json<DataDictionaryErrorResponse>,
      APIHelpers.standardError,
    );
  },

  deletePartFromSurveyInstance: (surveyPartId: string, timePeriodId: string) => {
    return handleAPIRequest(
      apiClient.deleteAction(SurveyPartsApiPaths.SURVEY_PART(surveyPartId, timePeriodId)),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  editSurveyPart: (surveyPartId: string, timePeriodId: string, request: EditSurveyPartRequest) => {
    return handleAPIRequest(
      apiClient.patch(SurveyPartsApiPaths.SURVEY_PART(surveyPartId, timePeriodId), request),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  getSurveyPartImportTemplate: (surveyPartId: number, timePeriodId: number) => {
    return apiClient.getFileWithName(
      SurveyPartsApiPaths.SURVEY_PART_IMPORT_TEMPLATE(surveyPartId, timePeriodId),
      `Template_${surveyPartId}_${timePeriodId}.xlsm`,
    );
  },

  getSurveyPartImportTemplateVersion: (surveyPartId: string, timePeriodId: string, version: string) => {
    return apiClient.getFileWithName(
      SurveyPartsApiPaths.SURVEY_PART_IMPORT_TEMPLATE_VERSION(surveyPartId, timePeriodId, version),
      `Template_${surveyPartId}_${timePeriodId}.xlsm`,
    );
  },

  uploadSurveyPartTemplate: (
    partId: number,
    timePeriodId: number,
    file: Blob,
    partRequest: SurveyPart,
    ignoreWarnings: boolean,
  ) => {
    const formData = new FormData();
    formData.append('File', file);
    formData.append('PartRequest', JSON.stringify(partRequest));
    return handleAPIRequest(
      apiClient.putFormData(SurveyPartsApiPaths.SURVEY_PART_IMPORT_TEMPLATE(partId, timePeriodId), formData, {
        ignoreWarnings,
      }),
      APIHelpers.json<SubmitSurveyPartResponse>,
      APIHelpers.standardError<SubmitSurveyPartResponse>,
    );
  },

  getSurveyStatus: (surveyPartId: number, timePeriodId: number) => {
    return handleAPIRequest(
      apiClient.get(SurveyPartsApiPaths.SURVEY_STATUS(surveyPartId, timePeriodId)),
      APIHelpers.json<SurveyStatus>,
      APIHelpers.standardError,
    );
  },

  getSurveyPartTemplateVersionHistory: (surveyPartId: number, timePeriodId: number) => {
    return handleAPIRequest(
      apiClient.get(SurveyPartsApiPaths.SURVEY_PART_TEMPLATE_VERSION_HISTORY(surveyPartId, timePeriodId)),
      APIHelpers.json<ImportTemplateVersionHistory[]>,
      APIHelpers.standardError,
    );
  },
});
export default surveyPartsApi;
