import { ProviderContact } from 'nrosh-common/Api/ProvidersApi';
import { ContactType } from 'nrosh-common/Api/SurveysApi';
import { FormEvent, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { MyProviderApi, ProvidersApi } from '@/Helpers/Apis';
import { textIsForbidden } from '@/Helpers/Validation';
import ConfirmOrganisationalContactForm from '@/Pages/Submissions/Confirmation/ConfirmOrganisationalContactForm';
import {
  ConfirmSubmissionPage,
  useNextAndPreviousConfirmPath,
} from '@/Pages/Submissions/Confirmation/ConfirmSubmissionRouteHelpers';
import {
  OrganisationalContactsDispatchContextProvider,
  useOrganisationalContactsReducer,
} from '@/Pages/Submissions/Confirmation/OrganisationalContactsReducer';
import '@/Pages/Submissions/Confirmation/ConfirmOrganisationalContactsPage.scss';
import SubmissionBackButton from '@/Pages/Submissions/Confirmation/SubmissionBackButton';
import { SubmissionConfirmation } from '@/Pages/Submissions/Confirmation/SubmissionConfirmation';
import SubmissionConfirmationCheckbox from '@/Pages/Submissions/Confirmation/SubmissionConfirmationCheckbox';

const ConfirmButton = ({ isSubmitting }: { isSubmitting: boolean }): JSX.Element => {
  const buttonText = 'Confirm';

  return !isSubmitting ? (
    <PrimaryButton type="submit">{buttonText}</PrimaryButton>
  ) : (
    <LoadingButton message={buttonText} />
  );
};

type ConfirmOrganisationalContactsFormsProps = {
  contactsToConfirm: ContactType[];
  contacts: ProviderContact[];
  submissionConfirmationState: SubmissionConfirmation;
  isAdmin: boolean;
};

const ConfirmOrganisationalContactsForms = ({
  contactsToConfirm,
  contacts,
  submissionConfirmationState,
  isAdmin,
}: ConfirmOrganisationalContactsFormsProps): JSX.Element => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [state, dispatch] = useOrganisationalContactsReducer(contactsToConfirm, contacts);
  const navigate = useNavigate();
  const { submissionId } = useParams();
  const [error, setError] = useState<string | null>(null);
  const [validated, setValidated] = useState(false);

  const contactTypes = Object.keys(state) as ContactType[];

  const { previousPage, nextPage } = useNextAndPreviousConfirmPath(
    ConfirmSubmissionPage.OrganisationalContacts,
    isAdmin,
  );

  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault(); // Stops the page from reloading

    if (!event.currentTarget.checkValidity()) {
      setValidated(true);
      return;
    }

    if (
      Object.values(state).some((ocf) => Object.values(ocf).some((v) => typeof v !== 'boolean' && textIsForbidden(v)))
    ) {
      setValidated(true);
      return;
    }

    setValidated(false);
    setSubmitting(true);
    setError(null);

    const providerContacts: ProviderContact[] = contactsToConfirm.map((contactType) => {
      const contactDetails = state[contactType];

      return {
        type: contactType,
        name: contactDetails.name,
        jobTitle: contactDetails.jobTitle,
        address: {
          addressLine1: contactDetails.address1,
          addressLine2: contactDetails.address2,
          addressLine3: contactDetails.address3,
          addressLine4: contactDetails.address4,
          postcode: contactDetails.postcode,
        },
        phoneNumber: contactDetails.phone,
        emailAddress: contactDetails.email,
      };
    });
    const request = isAdmin
      ? ProvidersApi.confirmOrganisationalContacts(Number(submissionId), providerContacts)
      : MyProviderApi.confirmOrganisationalContacts(Number(submissionId), providerContacts);
    const response = await request.raw;

    if (response.ok) {
      navigate(nextPage, {
        state: {
          detailsCorrect: submissionConfirmationState.detailsCorrect,
          updateRequestSent: submissionConfirmationState.updateRequestSent,
          feesInvoiceEmailConfirmed: submissionConfirmationState.feesInvoiceEmailConfirmed,
          organisationalDetailsConfirmed: true,
        },
      });
    } else {
      setError(response.value.message);
      setSubmitting(false);
    }
  };

  return (
    <Form onSubmit={onSubmit} noValidate>
      <OrganisationalContactsDispatchContextProvider dispatch={dispatch}>
        <div className="d-flex flex-wrap">
          {contactTypes.map((contactType) => (
            <ConfirmOrganisationalContactForm
              key={contactType}
              contactType={contactType}
              contact={state[contactType]}
              validated={validated}
            />
          ))}
        </div>
      </OrganisationalContactsDispatchContextProvider>
      {error && (
        <Alert variant="danger" dismissible onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
      <div className="mb-4 w-50 d-flex confirmAllContainer">
        <p className="me-4 w-50">
          <b>
            I confirm I have checked all contact details provided above and these are correct at the point of survey
            submissions
          </b>
        </p>
        <SubmissionConfirmationCheckbox
          validated={validated}
          checked={Object.values(state).every((c) => c.confirmed)}
          id="confirm-all"
          label="I confirm all contacts above are correct"
          onChange={(e) => dispatch({ type: 'confirm-all', payload: { value: e.target.checked } })}
        />
      </div>
      <div className="d-flex gap-2">
        <SubmissionBackButton previousPage={previousPage} submissionConfirmationState={submissionConfirmationState} />
        <ConfirmButton isSubmitting={isSubmitting} />
      </div>
    </Form>
  );
};

export default ConfirmOrganisationalContactsForms;
