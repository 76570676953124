import { Checkbox } from '@mui/material';
import classNames from 'classnames';
import { saveAs } from 'file-saver';
import { ProviderRole, RshRole } from 'nrosh-common/Api/Enums';
import { ProviderSummary } from 'nrosh-common/Api/ProvidersApi';
import { RegulatoryDocument, RegulatoryDocumentStatus } from 'nrosh-common/Api/RegulatoryDocumentsApi';
import AuthContext, { Auth } from 'nrosh-common/Contexts/AuthContext';
import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { useEndpointConditionally } from 'nrosh-common/Hooks/useEndpoint';
import { ChangeEvent, DispatchWithoutAction, ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { IconContext } from 'react-icons';
import { Link, generatePath } from 'react-router-dom';
import { CellProps, HeaderProps } from 'react-table';
import { useErrorReporting } from '@/Components/Errors/DCSErrorBoundary';
import FileDownloadIconButton from '@/Components/FileDownload/FileDownloadIconButton';
import FilterDateRangePicker from '@/Components/Filters/FilterDateRangePicker';
import { FilterDropdown } from '@/Components/Filters/FilterDropdown';
import FilterHeading from '@/Components/Filters/FilterHeading';
import { FilterSearchBox } from '@/Components/Filters/FilterSearchBox';
import Delete from '@/Components/Icons/Delete';
import Edit from '@/Components/Icons/Edit';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import { useModal } from '@/Components/Modal/ModalProvider';
import { SearchableDropdown, SearchableDropdownOption } from '@/Components/SearchableDropdown/SearchableDropdown';
import Table, { NroshColumn } from '@/Components/Table/Table';
import { ProvidersApi, RegulatoryDocumentsApi } from '@/Helpers/Apis';
import { formatISODateString, getEndOfDay } from '@/Helpers/DateHelpers';
import { caseInsensitiveSort } from '@/Helpers/TableHelper';
import { adminPages, providerPages } from '@/Pages/Home/SitePages';
import '@/Pages/RegulatoryDocuments/RegulatoryDocumentsTable.scss';
import RegulatoryDocumentButtons from '@/Pages/RegulatoryDocuments/RegulatoryDocumentButtons';

const deleteWarning = (
  <div>
    <p>Are you sure you want to delete this document?</p>
    <p>
      <strong> This will be permanent.</strong>
    </p>
  </div>
);

const isReadonly = (document: RegulatoryDocument): boolean =>
  document.status !== RegulatoryDocumentStatus.Pending || document.hasBeenDeleted;

type EditButtonProps = {
  documentId: string;
  isAdmin: boolean;
  disabled: boolean;
};

const EditButton = ({ documentId, isAdmin, disabled }: EditButtonProps): JSX.Element => {
  if (disabled) {
    return (
      <button className="disabledButton" type="button" disabled aria-label="Edit disabled">
        <Edit />
      </button>
    );
  }

  const editPath = isAdmin ? adminPages.EditRegulatoryDocument.path : providerPages.EditRegulatoryDocument.path;

  return (
    <Link to={generatePath(editPath, { documentId })}>
      <Edit />
    </Link>
  );
};

type ActionButtonProps = {
  ariaLabel: string;
  onClick: () => void;
  disabled?: boolean;
  children: ReactNode;
};

const ActionButton = ({ ariaLabel, onClick, disabled = false, children }: ActionButtonProps): JSX.Element => {
  const { current: iconContextValue } = useRef({ size: '1.25rem' });
  return (
    <button
      className={classNames('actionButton iconButton d-flex align-items-center justify-content-center w-100 m-0')}
      type="button"
      aria-label={ariaLabel}
      onClick={onClick}
      disabled={disabled}
    >
      <IconContext.Provider value={iconContextValue}>{children}</IconContext.Provider>
    </button>
  );
};

const getDocumentTagsDisplay = (document: RegulatoryDocument): string =>
  [document.primaryTag?.name, document.secondaryTag?.name].filter((t) => t).join(', ');

const getDeletedStatus = (
  document: RegulatoryDocument,
  notDeletedStatusValue: string | JSX.Element,
): string | JSX.Element => (document.hasBeenDeleted ? `Deleted By ${document.deletedBy}` : notDeletedStatusValue);

const providerTableRowToExportRow = (
  document: RegulatoryDocument,
): {
  'Description': string | null;
  'Date of Upload': string;
  'Scheduled Deletion Date': string;
  'Deleted Status': string;
  'Title': string;
  'Tags': string;
} => ({
  'Title': document.fileName,
  'Description': document.description,
  'Date of Upload': formatISODateString(document.uploadDate),
  'Scheduled Deletion Date': formatISODateString(document.scheduledDeletionDate),
  'Tags': getDocumentTagsDisplay(document),
  'Deleted Status': getDeletedStatus(document, 'Not Deleted') as string,
});

const adminTableRowToExportRow = (
  document: RegulatoryDocument,
): {
  'Provider number': string;
  'Description': string | null;
  'Date of Upload': string;
  'Provider Name': string;
  'Scheduled Deletion Date': string;
  'Deleted Status': string;
  'Title': string;
  'Tags': string;
} => ({
  ...providerTableRowToExportRow(document),
  'Provider Name': document.providerName,
  'Provider number': document.providerNumber,
});

const setSelected =
  (selected: boolean) =>
  (doc: RegulatoryDocument): SelectableRegulatoryDocument => ({ ...doc, selected });

const buildColumns = (
  auth: Auth,
  onDelete: (documentId: number) => Promise<void>,
  triggerDocumentDownload: (documentId: string, fileName: string) => Promise<void>,
  setAllDocuments: ReactStateSetter<SelectableRegulatoryDocument[]>,
): NroshColumn<SelectableRegulatoryDocument>[] => [
  ...[
    {
      accessor: 'selected' as const,
      disableSortBy: true,
      width: 80,
      align: 'center' as const,
      Header: ({ rows }: HeaderProps<SelectableRegulatoryDocument>) => {
        const filteredRows = rows.filter((r) => !r.original.hasBeenDeleted).map((row) => row.original);

        const onClick = (e: ChangeEvent<HTMLInputElement>): void => {
          const updateSelected = setSelected(e.target.checked);
          setAllDocuments((prevDocuments) =>
            prevDocuments.map((prevDoc) =>
              filteredRows.some((r) => r.id === prevDoc.id) ? updateSelected(prevDoc) : prevDoc,
            ),
          );
        };

        return (
          <div>
            <Checkbox
              checked={filteredRows.every((r) => r.selected)}
              indeterminate={filteredRows.some((r) => r.selected)}
              disableRipple
              color="nrosh"
              onChange={onClick}
              inputProps={{ 'aria-label': 'Select/unselect all documents' }}
            />
            <span className="visually-hidden">Select document</span>
          </div>
        );
      },
      Cell: ({ value, row }: CellProps<SelectableRegulatoryDocument, boolean>) => {
        const onClick = (e: ChangeEvent<HTMLInputElement>): void => {
          setAllDocuments((prevDocuments) =>
            prevDocuments.map((doc) => (doc.id === row.original.id ? { ...doc, selected: e.target.checked } : doc)),
          );
        };
        return (
          <Checkbox
            inputProps={{ 'aria-label': `Select/unselect document: ${row.values.fileName}` }}
            checked={value}
            disableRipple
            disabled={row.original.hasBeenDeleted}
            color="nrosh"
            onChange={onClick}
          />
        );
      },
    },
  ],
  ...(auth.hasRole(RshRole.ViewRegDocs)
    ? [
        {
          Header: 'Provider Name',
          accessor: 'providerName' as const,
          sortType: caseInsensitiveSort,
        },
        {
          Header: 'Provider Number',
          accessor: 'providerNumber' as const,
          sortType: caseInsensitiveSort,
        },
      ]
    : []),
  {
    Header: 'File name',
    accessor: 'fileName',
    sortType: caseInsensitiveSort,
  },
  {
    Header: 'Description',
    accessor: 'description',
    sortType: caseInsensitiveSort,
  },
  {
    Header: 'Date of Upload',
    align: 'center',
    accessor: 'uploadDate',
    Cell: ({ row }: CellProps<RegulatoryDocument>) => formatISODateString(row.original.uploadDate),
  },

  {
    Header: 'Scheduled Deletion Date',
    align: 'center' as const,
    accessor: 'scheduledDeletionDate',
    Cell: ({ row }: CellProps<RegulatoryDocument>) => formatISODateString(row.original.scheduledDeletionDate),
  },
  {
    Header: 'Tags',
    accessor: (row) => getDocumentTagsDisplay(row),
  },
  ...(auth.hasOneOfRoles(RshRole.EditRegDocs, ProviderRole.EditRegDocs)
    ? [
        {
          Header: 'Edit',
          align: 'center' as const,
          disableSortBy: true,
          disableResizing: true,
          width: 100,
          Cell: ({ row }: CellProps<RegulatoryDocument>) => (
            <EditButton
              documentId={row.original.id.toString()}
              isAdmin={auth.hasRole(RshRole.EditRegDocs)!}
              disabled={isReadonly(row.original)}
            />
          ),
        },
      ]
    : []),
  {
    Header: 'Export',
    align: 'center' as const,
    disableSortBy: true,
    disableResizing: true,
    width: 100,
    Cell: ({ row }: CellProps<RegulatoryDocument>) => (
      <FileDownloadIconButton
        onDownload={() => triggerDocumentDownload(row.original.id.toString(), row.original.fileName)}
        disabled={row.original.status === RegulatoryDocumentStatus.Transferred || row.original.hasBeenDeleted}
      />
    ),
  },
  ...(auth.hasOneOfRoles(RshRole.EditRegDocs, ProviderRole.EditRegDocs)
    ? [
        {
          Header: 'Delete',
          align: 'center' as const,
          disableSortBy: true,
          disableResizing: true,
          width: 150,
          Cell: ({ row }: CellProps<RegulatoryDocument>) =>
            getDeletedStatus(
              row.original,
              <ActionButton
                ariaLabel="delete"
                onClick={() => onDelete(row.original.id)}
                disabled={isReadonly(row.original)}
              >
                <Delete />
              </ActionButton>,
            ),
        },
      ]
    : []),
];

const applyFilters = (
  documents: SelectableRegulatoryDocument[],
  providerSearchString: string,
  tagIds: number[],
  fromDate: Date | null,
  toDate: Date | null,
): SelectableRegulatoryDocument[] => {
  const normalisedSearchString = providerSearchString.trim().toLowerCase();

  let filteredDocuments =
    normalisedSearchString === ''
      ? documents
      : documents.filter(
          (d) =>
            d.providerName.toLowerCase().includes(normalisedSearchString) ||
            d.providerNumber.toLowerCase().includes(normalisedSearchString),
        );

  filteredDocuments =
    tagIds.length === 0
      ? filteredDocuments
      : filteredDocuments.filter((d) => tagIds.some((id) => [d.primaryTag, d.secondaryTag].some((t) => t?.id === id)));

  filteredDocuments =
    fromDate === null ? filteredDocuments : filteredDocuments.filter((d) => new Date(d.uploadDate) >= fromDate);

  filteredDocuments =
    toDate === null
      ? filteredDocuments
      : filteredDocuments.filter((d) => new Date(d.uploadDate) <= getEndOfDay(toDate)!);

  return filteredDocuments;
};

type RegulatoryDocumentsTableProps = {
  apiDocuments: RegulatoryDocument[];
  tagOptions: SearchableDropdownOption<number>[];
  triggerDataRefresh: DispatchWithoutAction;
};

export type SelectableRegulatoryDocument = RegulatoryDocument & { selected: boolean };

const RegulatoryDocumentsTable = ({
  apiDocuments,
  tagOptions,
  triggerDataRefresh,
}: RegulatoryDocumentsTableProps): JSX.Element => {
  const auth = useContext(AuthContext);
  const { confirm } = useModal();

  const isAdmin = auth.hasRole(RshRole.User)!;
  const [providers] = useEndpointConditionally<ProviderSummary[]>(ProvidersApi.getProviders, isAdmin);

  const [allDocuments, setAllDocuments] = useState(apiDocuments.map(setSelected(false)));

  const [raiseError, clearError] = useErrorReporting();
  const [providerSearchString, setProviderSearchString] = useState<string>('');
  const [tagIds, setTagIds] = useState<number[]>([]);
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [successMessages, setSuccessMessages] = useState<string[]>([]);
  const [updatingDocuments, setUpdatingDocuments] = useState(false);

  useEffect(() => {
    triggerDataRefresh();
    setUpdatingDocuments(true);
  }, [successMessages, triggerDataRefresh]);
  useEffect(() => {
    setAllDocuments((prevDocuments) =>
      prevDocuments
        .filter((pd) => apiDocuments.some((ad) => ad.id === pd.id))
        .map((pd) => ({ ...apiDocuments.find((ad) => ad.id === pd.id)!, selected: pd.selected })),
    );
    setUpdatingDocuments(false);
  }, [apiDocuments]);

  const triggerDocumentDownload = async (documentId: string, fileName: string): Promise<void> => {
    // TODO-198: Replace this with a more best-practices compliant version (use <a href="..." download/> instead of file-saver)
    try {
      const document = await RegulatoryDocumentsApi.download(documentId.toString());
      saveAs(URL.createObjectURL(document), fileName);
    } catch {
      raiseError();
    }
  };

  // It's important to call useMemo here - otherwise, whenever the export button is clicked all of the cells will
  // be unmounted and recreated, and an unmounted component warning appears in the console.
  const columns = useMemo((): NroshColumn<SelectableRegulatoryDocument>[] => {
    const onDelete = async (documentId: number): Promise<void> => {
      if (!(await confirm(deleteWarning))) {
        return;
      }

      const response = await RegulatoryDocumentsApi.delete(documentId.toString()).raw;

      if (response.ok) {
        clearError();
        const message = `${allDocuments.find((d) => d.id === documentId)!.fileName} has been deleted`;
        setAllDocuments((data) => data.map((d) => (d.id === documentId ? { ...d, selected: false } : d)));
        setSuccessMessages((prevMessages) => [...prevMessages, message]);
      } else {
        raiseError(response.value.message);
      }
    };

    return buildColumns(auth, onDelete, triggerDocumentDownload, setAllDocuments);
  }, [auth, allDocuments, confirm]);

  const filteredTableData = useMemo(
    () => applyFilters(allDocuments, providerSearchString, tagIds, fromDate, toDate),
    [allDocuments, providerSearchString, tagIds, fromDate, toDate],
  );

  if (isAdmin && !providers) {
    return <LoadingMessage />;
  }

  const selectedDocuments = allDocuments.filter((d) => d.selected);

  const indexedSuccessMessages = successMessages.map((message, index) => ({
    id: index,
    message,
  }));

  return (
    <div>
      {successMessages.length > 0 &&
        indexedSuccessMessages.map((indexedMessage, index) => (
          <Alert
            variant="success"
            key={indexedMessage.id}
            dismissible
            onClose={() =>
              setSuccessMessages((prevMessages) => prevMessages.slice(0, index).concat(prevMessages.slice(index + 1)))
            }
          >
            {indexedMessage.message}
          </Alert>
        ))}
      <RegulatoryDocumentButtons selectedDocuments={selectedDocuments} setSuccessMessages={setSuccessMessages} />
      <FilterDropdown resultCount={filteredTableData.length} heading="Filter Documents">
        <div className="row mb-2">
          {auth.hasRole(RshRole.ViewRegDocs) && (
            <Form.Group className="col">
              <Form.Label className="fw-normal mb-0">
                <FilterHeading heading="Search for Provider (Name/Number)" />
              </Form.Label>
              <FilterSearchBox
                id="providerSearchBox"
                searchString={providerSearchString}
                setSearchString={setProviderSearchString}
                placeholderText="Enter Provider Name/Number"
              />
            </Form.Group>
          )}
          <div className="col">
            <FilterHeading heading="Filter by Tag" />
            <SearchableDropdown
              options={tagOptions}
              currentSelection={tagIds}
              onChange={(newTagIds) => setTagIds(newTagIds)}
              placeholderText="Choose Tags"
              allowMultiSelect
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <FilterHeading heading="Upload Date Range" />
            <FilterDateRangePicker
              fromDateTime={fromDate}
              setFromDateTime={setFromDate}
              toDateTime={toDate}
              setToDateTime={setToDate}
              showTime={false}
            />
          </div>
        </div>
      </FilterDropdown>
      {updatingDocuments ? (
        <LoadingMessage />
      ) : (
        <Table
          data={filteredTableData}
          columns={columns}
          paginated
          exportable
          exportFileName="Regulatory Documents Metadata.csv"
          tableRowToExportRow={isAdmin ? adminTableRowToExportRow : providerTableRowToExportRow}
          rowHeadingIndex={auth.hasRole(RshRole.ViewRegDocs) ? 2 : 0}
        />
      )}
    </div>
  );
};

export default RegulatoryDocumentsTable;
