import classNames from 'classnames';
import { APIResponse } from 'nrosh-common/Api/ApiClient';
import { RshRole } from 'nrosh-common/Api/Enums';
import { Provider, ProviderOptions, ProviderWithStringDate } from 'nrosh-common/Api/ProvidersApi';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import { FormEvent, useContext, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { Link, generatePath, useParams } from 'react-router-dom';
import { AccessibleFeedback } from '@/Components/Form/AccessibleFeedback';
import { ButtonWithLoadingEffect } from '@/Components/Loading/ButtonWithLoadingEffect';
import { ProvidersApi } from '@/Helpers/Apis';
import { getValidityProps } from '@/Helpers/Forms';
import { emailRegex } from '@/Helpers/Validation';
import useUnsavedChangesWarning from '@/Hooks/useUnsavedChangesWarning';
import { adminPages } from '@/Pages/Home/SitePages';
import '@/Pages/Providers/RegisteredDetailsPage.scss';
import { RegisteredDetailsTable } from '@/Pages/Providers/RegisteredDetailsTable';

const ViewRegisteredDetails = (props: { provider: Provider }): JSX.Element => {
  const { provider } = props;
  const { providerId } = useParams();
  const auth = useContext(AuthContext);

  const [isTestProvider, setIsTestProvider] = useState(provider.isTestProvider);
  const [isDisabled, setIsDisabled] = useState(provider.isDisabled);
  const [feesInvoiceEmail, setFeesInvoiceEmail] = useState(provider.feesInvoiceEmail);

  const [emailInvalid, setEmailInvalid] = useState(false);

  const [error, setError] = useState<string | null>(null);
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [setDirty, setPristine, isDirty] = useUnsavedChangesWarning();

  const handleResponse = async (response: APIResponse<ProviderWithStringDate>): Promise<void> => {
    if (response.ok) {
      const body = response.value;
      setIsDisabled(body.isDisabled);
    } else {
      setError(response.value.message);
    }
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    setError(null);
    event.preventDefault(); // Stops the page from reloading
    event.stopPropagation();

    if (!event.currentTarget.checkValidity()) {
      setValidated(true);
      return;
    }
    setSubmitting(true);
    const providerOptions: ProviderOptions = {
      isTestProvider,
      feesInvoiceEmail,
    };
    const response = await ProvidersApi.updateProvider(provider.id, providerOptions).raw;
    if (response.ok) {
      setPristine();
    }
    await handleResponse(response);

    setSubmitting(false);
  };

  const setDisabled = async (isProviderDisabled: boolean): Promise<void> => {
    setSubmitting(true);
    setError(null);

    const response = await (isProviderDisabled
      ? ProvidersApi.disableProvider(provider.id)
      : ProvidersApi.enableProvider(provider.id)
    ).raw;

    await handleResponse(response);
    setSubmitting(false);
  };

  const canEditProvider = auth.hasRole(RshRole.EditProviders);

  const registeredDetails = {
    providerNumber: provider.providerNumber,
    providerName: provider.name,
    reportingStatus: provider.reportingStatus,
    providerSize: provider.size,
    providerType: provider.type,
    registeredAddress: provider.registeredAddress,
    endOfFinancialYear: provider.endOfFinancialYear,
    registrationStatus: provider.registrationStatus,
    registrationDate: provider.registrationDate,
    deregistrationDate: provider.deregistrationDate,
    profitStatus: provider.profitStatus,
    isTestProvider,
    isDisabled,
    feesInvoiceEmail,
  };

  return (
    <>
      <RegisteredDetailsTable
        registeredDetails={registeredDetails}
        showEditableFields={!canEditProvider}
        showFullDetails
      />
      {auth.hasRole(RshRole.EditProviders) && (
        <>
          <h2>Edit Provider Details</h2>
          <Form noValidate onSubmit={onSubmit} className="registeredDetailsFormContainer">
            {error && (
              <Alert variant="danger" className="w-75">
                {error}
              </Alert>
            )}
            <Form.Group className={classNames('mb-3', 'w-50')} controlId="feesInvoiceEmail">
              <Form.Label>Fees Invoice Email</Form.Label>
              <Form.Control
                value={feesInvoiceEmail ?? ''}
                onChange={(e) => {
                  setDirty();
                  setEmailInvalid(false);
                  setFeesInvoiceEmail(e.target.value);
                }}
                minLength={6}
                maxLength={256}
                pattern={emailRegex.source}
                onInvalid={() => setEmailInvalid(true)}
                {...getValidityProps(validated && emailInvalid, 'fees-invoice-email-feedback')}
              />
              <AccessibleFeedback id="fees-invoice-email-feedback" displayFeedback={validated && emailInvalid}>
                Please provide a valid email address
              </AccessibleFeedback>
            </Form.Group>
            <div className="mt-3 d-flex align-items-center">
              <input
                type="checkbox"
                id="isTestProviderCheckbox"
                className="me-2"
                checked={isTestProvider}
                onChange={(e) => {
                  setIsTestProvider(e.target.checked);
                  setDirty();
                }}
              />
              <label htmlFor="isTestProviderCheckbox">Is test provider</label>
            </div>
            <div className="mt-4 d-flex gap-3 align-items-center">
              <Link to={generatePath(adminPages.AdminViewProvider.path, { providerId })}>Return to Provider</Link>
              <ButtonWithLoadingEffect
                isLoading={isSubmitting}
                loadingMessage="Save"
                buttonProps={{ type: 'submit', disabled: !isDirty }}
              >
                Save
              </ButtonWithLoadingEffect>
              <ButtonWithLoadingEffect
                isLoading={isSubmitting}
                loadingMessage={isDisabled ? 'Enable' : 'Disable'}
                colour="outline-primary"
                buttonProps={{
                  type: 'button',
                  onClick: () => {
                    setDisabled(!isDisabled).catch(() => {});
                  },
                }}
              >
                {isDisabled ? 'Enable' : 'Disable'}
              </ButtonWithLoadingEffect>
            </div>
          </Form>
        </>
      )}
    </>
  );
};

export default ViewRegisteredDetails;
