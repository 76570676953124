import { EditablePage } from 'nrosh-common/Api/EditableContentApi';
import { useState } from 'react';
import { Alert, ListGroup, Tab } from 'react-bootstrap';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import ContentDisplay from '@/Components/ContentDisplay/ContentDisplay';
import ContentEditor from '@/Components/ContentEditor/ContentEditor';
import LinkButton from '@/Components/Links/LinkButton';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { EditableContentApi } from '@/Helpers/Apis';
import useUnsavedChangesWarning from '@/Hooks/useUnsavedChangesWarning';
import { adminPages } from '@/Pages/Home/SitePages';

type Props = {
  editablePage: EditablePage;
};

const contentEditorNavLink = '#contentEditor';
const previewNavLink = '#preview';

const EditContent: (props: Props) => JSX.Element = ({ editablePage }) => {
  const [content, setContent] = useState(editablePage.content);
  const [setDirty, setPristine, isDirty] = useUnsavedChangesWarning();
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const sendEditPageRequest = async (): Promise<void> => {
    setIsSaving(true);

    // The ContentEditor document expects to have at least one block node, which is the paragraph by default.
    // Deleting the content or running the clearContent command will still result in the document having at least one empty paragraph.
    if (!content || content === '<p></p>') {
      setError('The editable content on this page cannot be left blank. Please enter content in the editor.');
      setIsSaving(false);
      return;
    }

    setPristine();
    const editablePageRequest = { content };
    const response = await EditableContentApi.updatePageContent(editablePage.id, editablePageRequest).raw;
    if (response.ok) {
      navigate(generatePath(adminPages.AdminEditableContent.path));
      return;
    }
    const errorMessage = response.value.message;
    setError(errorMessage);
    setDirty();
    setIsSaving(false);
  };

  return (
    <Tab.Container defaultActiveKey={location.hash || contentEditorNavLink}>
      <ListGroup horizontal className="my-3 w-50">
        <ListGroup.Item bsPrefix="listGroupItem submissions" action href={contentEditorNavLink} as="button">
          Edit
        </ListGroup.Item>
        <ListGroup.Item bsPrefix="listGroupItem submissions" action href={previewNavLink} as="button">
          Preview
        </ListGroup.Item>
      </ListGroup>
      <Tab.Content>
        <Tab.Pane eventKey={contentEditorNavLink}>
          <div className="mb-3">
            <ContentEditor content={content} setContent={setContent} setDirty={setDirty} hasH1Tag ariaLabel="Content" />
          </div>
          <LinkButton
            className="me-2"
            to={adminPages.AdminEditableContent.path}
            variant="outline-primary"
            disabled={isSaving}
          >
            Cancel
          </LinkButton>
          {!isSaving ? (
            <PrimaryButton type="button" onClick={() => sendEditPageRequest()} disabled={!isDirty}>
              Save
            </PrimaryButton>
          ) : (
            <LoadingButton message="Saving..." />
          )}
          {error && (
            <Alert className="mt-4" variant="danger" dismissible onClose={() => setError(null)}>
              {error}
            </Alert>
          )}
        </Tab.Pane>
        <Tab.Pane eventKey={previewNavLink}>
          <ContentDisplay htmlContent={content} />
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  );
};

export default EditContent;
