const SubmitSurvey = (): JSX.Element => {
  const fill = '#1EB6C8';
  return (
    <svg id="submit-surveys-icon" xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="30 30 240 240">
      <rect
        fill="none"
        stroke={fill}
        strokeWidth="5.13px"
        strokeMiterlimit="10"
        x="36.33"
        y="108.5"
        width="223.96"
        height="33.68"
        rx="12.31"
        ry="12.31"
      />
      <path
        fill="none"
        stroke={fill}
        strokeMiterlimit="10"
        strokeWidth="4.78px"
        d="m251.32,140.8v113.17c0,6.12-5.33,11.13-11.83,11.13H60.51c-6.51,0-11.83-5.01-11.83-11.13v-112.07"
      />
      <path
        fill="none"
        stroke={fill}
        strokeMiterlimit="10"
        strokeWidth="4.93px"
        d="m48.68,108.79v-53.48c0-6.51,5.33-11.83,11.83-11.83h68.61"
      />
      <path
        fill="none"
        stroke={fill}
        strokeMiterlimit="10"
        strokeWidth="4.93px"
        d="m168.41,43.47h71.09c6.51,0,11.83,5.33,11.83,11.83v54.65"
      />
      <g>
        <path
          fill="none"
          stroke={fill}
          strokeWidth="5px"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m225.59,100.26v-32.13c0-8.77-7.17-15.94-15.94-15.94h-36.42c-.87-10.5-10.93-18.79-23.24-18.79s-22.37,8.29-23.24,18.79h-36.42c-8.77,0-15.94,7.17-15.94,15.94v32.13h151.19Zm-120.18-42.79c0-2.39,1.61-4.42,3.8-5.07l40.07-.04c.24-.02.47-.04.72-.04s.46.02.69.03l39.83-.03c2.32.55,4.06,2.65,4.06,5.14v22.48c0,2.91-2.38,5.28-5.28,5.28h-78.61c-2.9,0-5.28-2.38-5.28-5.28v-22.48Z"
        />
        <path
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4.84px"
          d="m74.41,151.28v76.06c0,8.22,7.17,14.95,15.94,14.95h119.31c8.77,0,15.94-6.73,15.94-14.95v-76.06H74.41Z"
        />
      </g>
      <polyline
        fill="none"
        stroke={fill}
        strokeWidth="5px"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="94.05 174.5 102.12 183.63 120.24 166.07"
      />
      <line
        fill="none"
        stroke={fill}
        strokeWidth="5px"
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="136.41"
        y1="173.25"
        x2="192.99"
        y2="173.25"
      />
      <polyline
        fill="none"
        stroke={fill}
        strokeWidth="5px"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="94.05 123.81 102.12 132.94 120.24 115.37"
      />
      <line
        fill="none"
        stroke={fill}
        strokeWidth="5px"
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="136.41"
        y1="125.38"
        x2="192.99"
        y2="125.38"
      />
      <polyline
        fill="none"
        stroke={fill}
        strokeWidth="5px"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="94.05 204.98 102.12 214.12 120.24 196.55"
      />
      <line
        fill="none"
        stroke={fill}
        strokeWidth="5px"
        strokeLinecap="round"
        strokeLinejoin="round"
        x1="136.41"
        y1="203.74"
        x2="192.99"
        y2="203.74"
      />
    </svg>
  );
};
export default SubmitSurvey;
