import { EmailTemplateResponse, EmailTemplateType } from 'nrosh-common/Api/EmailsApi';
import { Link, generatePath } from 'react-router-dom';
import { CellProps, Row } from 'react-table';
import Edit from '@/Components/Icons/Edit';
import Table, { NroshColumn } from '@/Components/Table/Table';
import {
  emailTemplateTypeNames,
  emailTemplateTypeOptionality,
  emailTemplateTypeRecipients,
} from '@/Pages/Emails/EmailTypes';
import { ScheduledEmailTableRow } from '@/Pages/Emails/Manual/ScheduledEmailTable';
import { adminPages } from '@/Pages/Home/SitePages';

type EmailTemplatesTableProps = {
  emailTemplates: EmailTemplateResponse[];
};

const headers: NroshColumn<EmailTemplateResponse>[] = [
  {
    Header: 'Trigger',
    accessor: 'templateType',
    Cell: ({ value }: CellProps<ScheduledEmailTableRow, EmailTemplateType>) => emailTemplateTypeNames[value],
  },
  {
    Header: 'Optional?',
    align: 'center',
    width: 75,
    Cell: ({ cell: { row } }: { cell: { row: Row<EmailTemplateResponse> } }) =>
      emailTemplateTypeOptionality[row.original.templateType] ? 'Yes' : 'No',
  },
  {
    Header: 'Recipients',
    Cell: ({ cell: { row } }: { cell: { row: Row<EmailTemplateResponse> } }) =>
      emailTemplateTypeRecipients[row.original.templateType],
  },
  {
    Header: 'Edit',
    align: 'center',
    width: 75,
    disableSortBy: true,
    Cell: ({ cell: { row } }: { cell: { row: Row<EmailTemplateResponse> } }) => (
      <Link to={generatePath(adminPages.AdminUpdateEmailTemplate.path, { templateType: row.original.templateType })}>
        <Edit />
      </Link>
    ),
  },
];

const tableRowToExportRow = (
  templateResponse: EmailTemplateResponse,
): { 'Trigger': string; 'Recipients': string; 'Body': string; 'Optional?': string; 'Subject': string } => ({
  'Trigger': emailTemplateTypeNames[templateResponse.templateType],
  'Optional?': emailTemplateTypeOptionality[templateResponse.templateType] ? 'Yes' : 'No',
  'Recipients': emailTemplateTypeRecipients[templateResponse.templateType],
  'Subject': templateResponse.templateSubject,
  'Body': templateResponse.templateText,
});

export const EmailTemplatesTable = ({ emailTemplates }: EmailTemplatesTableProps): JSX.Element => (
  <Table
    data={emailTemplates}
    columns={headers}
    message="No Automated Templates to Display"
    exportable
    tableRowToExportRow={tableRowToExportRow}
    exportFileName="Email Templates"
    rowHeadingIndex={0}
  />
);
