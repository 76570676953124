import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import { LoadingButton } from '@/Components/Loading/LoadingButton';

type ButtonProps = {
  isSaving: boolean;
  disabled: boolean;
  text: string;
};

const SurveyFormButton = ({ isSaving, disabled, text }: ButtonProps): JSX.Element =>
  !isSaving ? (
    <OverlayTrigger
      placement="right"
      show={disabled && undefined}
      overlay={<Tooltip>Fill in all required fields</Tooltip>}
    >
      <div className="d-inline-block">
        <PrimaryButton type="submit" value="save" disabled={disabled}>
          {text}
        </PrimaryButton>
      </div>
    </OverlayTrigger>
  ) : (
    <LoadingButton message="Saving..." />
  );

export default SurveyFormButton;
