import { ProviderSummary } from 'nrosh-common/Api/ProvidersApi';
import { UserProfileType } from 'nrosh-common/Api/UsersApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { Stack } from 'react-bootstrap';
import { generatePath, useParams } from 'react-router-dom';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { LoadingSpinner } from '@/Components/Loading/LoadingSpinner';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { ProvidersApi } from '@/Helpers/Apis';
import { adminPages } from '@/Pages/Home/SitePages';
import UserForm from '@/Pages/Users/UserForm';

export const AdminProviderAddUserPage = (): JSX.Element => {
  const { providerId } = useParams();

  const [providerDetails] = useEndpoint<ProviderSummary>(ProvidersApi.getProvider, providerId);

  if (!providerDetails) {
    return <LoadingSpinner />;
  }

  return (
    <Stack gap={5}>
      <PageHeader
        heading="Add user"
        crumbsType={RootPathType.AdminProviders}
        crumbs={[
          {
            name: 'Organisational information',
            path: generatePath(adminPages.AdminViewProvider.path, { providerId: providerDetails.id.toString() }),
          },
          {
            name: providerDetails.name,
            path: generatePath(adminPages.AdminProviderUsers.path, { providerId: providerDetails.id.toString() }),
          },
          {
            name: 'Add user',
            path: adminPages.AdminAddUser.path,
          },
        ]}
      />
      <UserForm
        providerId={parseInt(providerId!, 10)}
        isAdmin
        userProfileType={UserProfileType.ProviderProfile}
        providerDetails={providerDetails}
        cancelPath
      />
    </Stack>
  );
};
