import { SurveyInstance } from 'nrosh-common/Api/SurveysApi';
import { Form } from 'react-bootstrap';

type DescriptionProps = {
  updateSurveyData: (update: Partial<SurveyInstance>) => void;
  surveyData: SurveyInstance;
};

const Description = ({ updateSurveyData, surveyData }: DescriptionProps): JSX.Element => (
  <Form.Group>
    <Form.Label>Description (optional)</Form.Label>
    <Form.Control
      onChange={(e) => {
        updateSurveyData({ description: e.target.value });
      }}
      value={surveyData.description || ''}
    />
  </Form.Group>
);

export default Description;
