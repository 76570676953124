import { ProviderRole, RshRole } from 'nrosh-common/Api/Enums';

export const RshRoleDescriptionMapping: Record<RshRole, string> = {
  [RshRole.User]: 'RSH user',
  [RshRole.ViewSurveys]: 'View surveys',
  [RshRole.AssignSurveys]: 'Assign surveys',
  [RshRole.DesignSurveys]: 'Design surveys',
  [RshRole.OpenCloseSurveys]: 'Open and close surveys',
  [RshRole.DeleteSurveys]: 'Delete surveys',
  [RshRole.UploadRegDocs]: 'Upload regulatory documents',
  [RshRole.EditRegDocs]: 'Edit and delete regulatory documents',
  [RshRole.ViewRegDocs]: 'View regulatory documents',
  [RshRole.TransferRegDocsToD365]: 'Transfer regulatory documents to D365',
  [RshRole.ViewSurveyData]: 'View survey data',
  [RshRole.EditSurveyData]: 'Edit survey data',
  [RshRole.SubmitSurvey]: 'Submit survey',
  [RshRole.UnsubmitSurvey]: 'Unsubmit surveys',
  [RshRole.SignOffSurvey]: 'Sign-off surveys',
  [RshRole.UnsignOffSurvey]: 'Un-sign-off surveys',
  [RshRole.ApproveValidations]: 'Approve validations',
  [RshRole.ViewProviders]: 'View providers',
  [RshRole.EditProviders]: 'Edit providers',
  [RshRole.ViewUsers]: 'View users',
  [RshRole.EditUsers]: 'Edit users',
  [RshRole.ViewSentEmails]: 'View sent emails',
  [RshRole.SendEmails]: 'Send emails',
  [RshRole.ManageContent]: 'Manage site content',
  [RshRole.ViewAudit]: 'View audit trail',
  [RshRole.ManageUserRoles]: 'Manage user profiles',
};

export const ProviderRoleDescriptionMapping: Record<ProviderRole, string> = {
  [ProviderRole.User]: 'Provider user',
  [ProviderRole.UploadRegDocs]: 'Upload regulatory documents',
  [ProviderRole.EditRegDocs]: 'Edit and delete regulatory documents',
  [ProviderRole.ViewRegDocs]: 'View regulatory documents',
  [ProviderRole.ViewSurveyData]: 'View survey data',
  [ProviderRole.EditSurveyData]: 'Edit survey data',
  [ProviderRole.SubmitSurvey]: 'Submit survey',
  [ProviderRole.ViewProvider]: 'View provider',
  [ProviderRole.EditProvider]: 'Edit provider',
  [ProviderRole.ViewUsers]: 'View users',
  [ProviderRole.EditUsers]: 'Edit users',
};
