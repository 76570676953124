import React, { ForwardRefExoticComponent, MouseEventHandler, ReactElement, RefAttributes } from 'react';
import { Dropdown } from 'react-bootstrap';

export type IconToggleProps = {
  icon: ReactElement;
};
type IconButtonProps = {
  children?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};
type IconButtonType = ForwardRefExoticComponent<IconButtonProps & RefAttributes<HTMLButtonElement>>;
const makeIconButton = (icon: ReactElement): IconButtonType =>
  React.forwardRef<HTMLButtonElement>(({ onClick }: IconButtonProps, ref) => (
    <button className="iconButton" type="button" aria-label="View history" ref={ref} onClick={onClick}>
      {icon}
    </button>
  ));
const IconToggle = ({ icon }: IconToggleProps): JSX.Element => <Dropdown.Toggle as={makeIconButton(icon)} />;

export default IconToggle;
