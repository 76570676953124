import classNames from 'classnames';
import { MouseEvent, useRef, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { Accept, useDropzone } from 'react-dropzone';
import { IconContext } from 'react-icons';
import { FiFile } from 'react-icons/fi';
import {
  FileData,
  NoFileDisplay,
  buildErrorMessage,
  defaultAcceptedFileTypes,
} from '@/Components/FileUpload/FileUploadHelpers';
import Delete from '@/Components/Icons/Delete';
import { formatBytes } from '@/Helpers/File';
import '@/Components/FileUpload/FileUploadArea.scss';

type UploadedFileDisplayProps = {
  fileName: string;
  fileSize: string;
  onChange: (file: File | null) => void;
};

const UploadedFileDisplay = ({ fileName, fileSize, onChange }: UploadedFileDisplayProps): JSX.Element => {
  const onDelete = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    onChange(null);
  };
  const { current: fiFileIconContext } = useRef({ size: '1.25rem' });

  return (
    <div className="d-flex align-items-center justify-content-center h-100 gap-6">
      <div className="d-flex align-items-center gap-1">
        <IconContext.Provider value={fiFileIconContext}>
          <FiFile />
        </IconContext.Provider>
        <span className="fileNameText">{fileName}</span>
      </div>
      <span className="fileSizeText">{fileSize}</span>
      <button className="bg-transparent border-0 deleteFileButton" type="button" aria-label="Delete" onClick={onDelete}>
        <div className="d-flex align-items-center gap-1">
          <Delete />
          Delete
        </div>
      </button>
    </div>
  );
};

/**
 * When populating the field with an existing file, instead of downloading the file and passing it in as the value, we
 * only need to fetch the file name and size so they can be used for the display. If the uploaded file is updated then
 * the new file's name and size will override those of the old file.
 */
type FileUploadAreaProps = {
  className?: string;
  value: File | null;
  initialFileData?: FileData;
  onChange: (file: File | null) => void;
  disabled?: boolean;
  accept?: Accept;
  allowZeroSizeFiles?: boolean;
  isInvalid?: boolean;
};

const FileUploadArea = ({
  className,
  value,
  initialFileData,
  onChange,
  disabled = false,
  accept = defaultAcceptedFileTypes,
  allowZeroSizeFiles = true,
  isInvalid = false,
}: FileUploadAreaProps): JSX.Element => {
  const [hasValue, setHasValue] = useState(!!(value || initialFileData));
  const [error, setError] = useState<string | null>(null);

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: (acceptedFiles: File[]) => {
      onChange(acceptedFiles[0]);
      setHasValue(true);
    },
    onDropRejected: (fileRejections) => setError(buildErrorMessage(fileRejections, accept)),
    disabled,
    maxFiles: 1,
    minSize: allowZeroSizeFiles ? 0 : 1,
    noClick: hasValue,
    accept,
  });

  const containerClass = classNames(
    'fileUploadArea',
    disabled && 'fileUploadDisabled',
    hasValue && 'fileUploadWithValue',
    isInvalid && 'fileUploadInvalid',
    className,
  );

  return (
    <>
      <div className={containerClass} {...getRootProps()}>
        <input {...getInputProps()} aria-label="Upload Files" />
        {hasValue ? (
          <UploadedFileDisplay
            fileName={(value?.name ?? initialFileData?.name)!}
            fileSize={formatBytes((value?.size ?? initialFileData?.sizeBytes)!)}
            onChange={(file) => {
              onChange(file);
              setHasValue(!!file);
            }}
          />
        ) : (
          <NoFileDisplay disabled={disabled} displayText="Drag and drop your document" />
        )}
      </div>
      {error && (
        <Alert variant="danger" className="mt-3" dismissible onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
    </>
  );
};

export default FileUploadArea;
