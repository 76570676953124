import useFocusOnPathChange from '@/Pages/Home/Navigation/useFocusOnPathChange';

// When navigating to a new page using react router, by default the focus remains on the link
// that has just been clicked. The purpose of this component is to steal the focus to the top
// of the page to best imitate how a real browser behaves on navigation.
const StealFocus = (): JSX.Element => {
  const focusRef = useFocusOnPathChange();
  // See https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex
  // tabIndex -1 allows the element to be focused, but keeps it out of the tab order.
  return <div aria-hidden="true" ref={focusRef} tabIndex={-1} />;
};

export default StealFocus;
