import { useEffect } from 'react';
import { useScreenReaderMessageContext } from '@/Pages/Home/Navigation/ScreenReaderMessagesContext';

type AccessiblePageProps = {
  title: string;
  children: JSX.Element;
};

const AccessiblePage = ({ title, children }: AccessiblePageProps): JSX.Element => {
  const { setMessage } = useScreenReaderMessageContext();
  useEffect(() => {
    document.title = title;
    setMessage(`Navigated to ${title} page`);
  }, [title, setMessage]);
  return children;
};

export default AccessiblePage;
