import { BulkImportWarnings } from 'nrosh-common/Api/SubmissionsApi';
import { useState } from 'react';
import { Alert, Stack } from 'react-bootstrap';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { DCSErrorContextOutlet, useErrorReporting } from '@/Components/Errors/DCSErrorBoundary';
import FileUploadButton from '@/Components/FileUpload/FileUploadButton';
import LinkButton from '@/Components/Links/LinkButton';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import { useModal } from '@/Components/Modal/ModalProvider';
import SubmissionPageHeader from '@/Components/PageHeader/SubmissionPageHeader';
import { SubmissionsApi, generateResponseErrorMessage } from '@/Helpers/Apis';
import { providerDisplayName } from '@/Helpers/ProviderHelper';
import { surveyDisplayName } from '@/Helpers/SurveyHelper';
import useSubmissionDetail from '@/Hooks/useSubmissionDetail';
import { providerPages, submissionPages } from '@/Pages/Home/SitePages';
import { BulkUploadWarnings, bulkImportHasErrors } from '@/Pages/Submissions/BulkUploadWarnings';

type BulkUploadOptionsPageProps = {
  isAdmin: boolean;
};

export const BulkImportOptionsPage = (props: BulkUploadOptionsPageProps): JSX.Element => {
  const { submissionId, surveyId, timePeriodId } = useParams();
  const { isAdmin } = props;
  const [data] = useSubmissionDetail(submissionId!);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [bulkImportResponse, setBulkImportResponse] = useState<BulkImportWarnings | null>();
  const [raiseError, clearError] = useErrorReporting();
  const { confirm } = useModal();
  if (!data) {
    return <LoadingMessage />;
  }

  const submissionPath = isAdmin
    ? generatePath(submissionPages.SubmissionView.path, {
        surveyId,
        timePeriodId,
        submissionId,
      })
    : generatePath(providerPages.SubmissionsDetail.path, { submissionId });

  const uploadChangeHandler = async (formData: FormData): Promise<boolean> => {
    if (
      await confirm(
        'This action will overwrite all existing data for this submission. Are you sure you wish to continue with the import?',
      )
    ) {
      try {
        clearError();
        setBulkImportResponse(null);
        const response = SubmissionsApi.uploadSubmissionBulkImportFile(formData, submissionId!);
        return await response.withHandlers<boolean>(
          async (value) => {
            setBulkImportResponse(value.bulkImportWarnings);
            return !bulkImportHasErrors(value.bulkImportWarnings!);
          },
          async (err) => {
            const detail = generateResponseErrorMessage(err, (res) => res.error, 'Unknown error');
            raiseError(`Upload Failed: ${detail}`);
            return false;
          },
        );
      } catch (e) {
        // We log the error here in case the frontend throws while fetching
        console.error(e);
        raiseError();
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <Stack gap={5}>
      <SubmissionPageHeader
        noErrorOutlet
        heading="Bulk upload"
        subheading={`${surveyDisplayName(data.surveyName, data.timePeriodName)} \u2014 
        ${providerDisplayName(data.provider.name, data.provider.providerNumber)}`}
        isRshUser={isAdmin}
        surveyDisplayName={surveyDisplayName(data.surveyName, data.timePeriodName)}
        providerName={data.provider.name}
        additionalRshCrumbs={[
          {
            name: 'Bulk upload',
            path: submissionPages.AdminBulkImportOptions.path,
          },
        ]}
        additionalProviderCrumbs={[
          {
            name: 'Bulk upload',
            path: providerPages.ProviderBulkImportOptions.path,
          },
        ]}
      />
      <Alert variant="primary">
        Bulk upload will overwrite all existing data. Please ensure the data you are uploading is correct before
        continuing.
      </Alert>
      <div className="d-flex gap-2">
        <FileUploadButton
          onFileChange={(formData) => uploadChangeHandler(formData)}
          onSuccess={() => navigate(submissionPath)}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          fileTypes="text/csv"
          formDataFileName="bulkImportFile"
        />
        <LinkButton to={submissionPath} variant="outline-primary" disabled={isLoading}>
          Cancel
        </LinkButton>
      </div>
      <DCSErrorContextOutlet />
      {bulkImportResponse && <BulkUploadWarnings bulkImportResponse={bulkImportResponse} />}
    </Stack>
  );
};
