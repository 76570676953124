import { DimensionalMember } from 'nrosh-common/Api/SubmissionsApi';
import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { ChangeEvent, useCallback, useMemo, useRef } from 'react';
import { IconContext } from 'react-icons';
import { FiTrash2 } from 'react-icons/fi';
import { useModal } from '@/Components/Modal/ModalProvider';
import { BaseCellProps, baseCellProps as getBaseCellProps } from '@/Components/Spreadsheet/Cells/CellHelpers';
import TextCell from '@/Components/Spreadsheet/Cells/TextCell';
import { SpreadsheetStaticState } from '@/Components/Spreadsheet/Spreadsheet';
import { SlicerCellStyle } from '@/Components/Spreadsheet/SpreadsheetTypes';
import { useSubmissionPartData } from '@/Pages/Submissions/SubmissionPartContext/SubmissionPartContext';

type SlicerCellProps = BaseCellProps & {
  cellStyle: SlicerCellStyle;
  setActiveCell: ReactStateSetter<string | null>;
  selectedSlicerValues: DimensionalMember[];
  unselectedSlicerValues: DimensionalMember[];
  currentMemberId: number;
  setCurrentMemberId: (dimensionMemberId: number) => void;
  removeSelectedSlicerValue: () => void;
  readonly: boolean;
};

const SlicerCellDisplay = (props: SlicerCellProps): JSX.Element => {
  const {
    cellStyle,
    setActiveCell,
    selectedSlicerValues,
    unselectedSlicerValues,
    currentMemberId,
    setCurrentMemberId,
    removeSelectedSlicerValue,
    readonly,
  } = props;
  const { dimensionId } = cellStyle;
  const selectId = `data-select-${dimensionId}-${cellStyle.column}-${cellStyle.row}`;

  const onSlicerValueChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    const selectedValue = event.target.value;
    setCurrentMemberId(Number(selectedValue));
  };
  const { confirm } = useModal();
  const onSlicerValueRemove = async (): Promise<void> => {
    if (
      await confirm(
        'Are you sure you want to remove this value? All the data that you have entered corresponding to this value will be lost.',
      )
    ) {
      removeSelectedSlicerValue();
    }
  };

  const { current: fiTrash2IconContext } = useRef({ size: '1.25rem' });

  return (
    <TextCell {...getBaseCellProps(props)}>
      <label htmlFor={selectId}>
        Choose dimension member:
        <select
          onFocus={() => setActiveCell(null)}
          id={selectId}
          value={currentMemberId}
          onChange={onSlicerValueChange}
        >
          {selectedSlicerValues.length === 0 && <option value="0">Select...</option>}
          <optgroup label="Selected options">
            {selectedSlicerValues.map((dm) => (
              <option key={dm.id} value={dm.id}>
                {dm.value}
              </option>
            ))}
          </optgroup>
          {unselectedSlicerValues.length && !readonly && (
            <optgroup label="Add...">
              {unselectedSlicerValues.map((dm) => (
                <option key={dm.id} value={dm.id}>
                  {dm.value}
                </option>
              ))}
            </optgroup>
          )}
        </select>
      </label>
      {currentMemberId > 0 && !readonly && (
        <button
          className="iconButton d-flex align-items-center h-100"
          type="button"
          aria-label="Remove"
          onClick={onSlicerValueRemove}
        >
          <IconContext.Provider value={fiTrash2IconContext}>
            <FiTrash2 />
          </IconContext.Provider>
        </button>
      )}
    </TextCell>
  );
};

const SlicerCell = (props: {
  baseCellProps: BaseCellProps;
  cellStyle: SlicerCellStyle;
  staticState: SpreadsheetStaticState;
}): JSX.Element => {
  const { baseCellProps, cellStyle, staticState } = props;
  const { setSelectedSlicerValues } = staticState;
  // We have to use the `useSubmissionPartData` hook here because rendering the slicer cell depends on
  // which dimension values have been added to the region. This means that the slicer cell will re-render
  // frequently (including times when it doesn't really need to) but this is ok because there can only
  // ever be one slicer cell on a tab.
  const { readonly, dimensionMembers, dimensionValues, updateDimensionValue, selectedSlicerValues, setActiveCell } =
    useSubmissionPartData();

  const regionMembers = useMemo(
    () => dimensionValues.filter((d) => d.regionId === cellStyle.regionId).sort((a, b) => a.index - b.index),
    [dimensionValues, cellStyle],
  );

  const currentMemberId = selectedSlicerValues[cellStyle.regionId] ?? 0;

  const setCurrentMemberId = useCallback(
    (dimensionMemberId: number) => {
      if (regionMembers.every((rm) => rm.dimensionMemberId !== dimensionMemberId)) {
        const newIndex = regionMembers.length > 0 ? Math.max(...regionMembers.map((rm) => rm.index)) + 1 : 0;
        updateDimensionValue(dimensionMemberId, cellStyle.regionId, newIndex);
      }
      setSelectedSlicerValues((prevSelectedSlicerValues) => ({
        ...prevSelectedSlicerValues,
        [cellStyle.regionId]: dimensionMemberId,
      }));
    },
    [regionMembers, updateDimensionValue, setSelectedSlicerValues, cellStyle.regionId],
  );

  const selectedSlicerValuesForRegion = useMemo(
    () => regionMembers.map((rm) => dimensionMembers.find((dm) => dm.id === rm.dimensionMemberId)!),
    [regionMembers, dimensionMembers],
  );

  const unselectedSlicerValuesForRegion = useMemo(
    () =>
      dimensionMembers.filter(
        (dm) =>
          dm.dimensionId === cellStyle.dimensionId &&
          !dm.isArchived &&
          !regionMembers.some((rm) => dm.id === rm.dimensionMemberId),
      ),
    [dimensionMembers, regionMembers],
  );

  const removeSelectedSlicerValue = useCallback(() => {
    const currentIndex =
      regionMembers.find((rm) => rm.dimensionMemberId === selectedSlicerValues[cellStyle.regionId])?.index ?? 0;
    updateDimensionValue(null, cellStyle.regionId, currentIndex);
    setSelectedSlicerValues((prevSelectedSlicerValues) => ({
      ...prevSelectedSlicerValues,
      // The selected options are ordered by index so this will get the member with the lowest remaining index.
      // If there are no longer any selected options, then set the member ID to zero.
      [cellStyle.regionId]: selectedSlicerValuesForRegion.filter((opt) => opt.id !== currentMemberId)[0]?.id ?? 0,
    }));
  }, [
    regionMembers,
    updateDimensionValue,
    setSelectedSlicerValues,
    selectedSlicerValues,
    cellStyle.regionId,
    currentMemberId,
  ]);

  return (
    <SlicerCellDisplay
      {...baseCellProps}
      cellStyle={cellStyle}
      setActiveCell={setActiveCell}
      currentMemberId={currentMemberId}
      selectedSlicerValues={selectedSlicerValuesForRegion}
      unselectedSlicerValues={unselectedSlicerValuesForRegion}
      setCurrentMemberId={setCurrentMemberId}
      removeSelectedSlicerValue={removeSelectedSlicerValue}
      readonly={readonly}
    />
  );
};

export default SlicerCell;
