import { RelatedArticle } from 'nrosh-common/Api/UnauthenticatedNewsApi';
import { Link, generatePath } from 'react-router-dom';
import { publicPages } from '@/Pages/Home/SitePages';
import '@/Pages/Content/News/RelatedArticles.scss';

const RelatedArticles = ({ articles }: { articles: RelatedArticle[] }): JSX.Element => (
  <div className="relatedArticlesContainer">
    <h2>Related Articles</h2>
    <ul>
      {articles.map((article) => (
        <li key={article.id}>
          <Link to={generatePath(publicPages.PublicArticle.path, { articleId: article.id })}>{article.name}</Link>
        </li>
      ))}
    </ul>
  </div>
);

export default RelatedArticles;
