import ApiClient, { APIHelpers, handleAPIRequest } from './ApiClient';
import { PageTitle } from './Enums';

export type EditablePage = {
  id: string;
  title: PageTitle;
  content: string;
};

export type EditablePageRequest = {
  content: string;
};

const EditableContentApiRoot = 'EditablePages';
const EditableContentApiPaths = {
  EDITABLE_PAGES: `${EditableContentApiRoot}/`,
  EDITABLE_PAGE: (pageId: string) => `${EditableContentApiRoot}/${pageId}`,
};

const editableContentApi = (apiClient: ApiClient) => ({
  getEditablePages: () => {
    return handleAPIRequest(
      apiClient.get(EditableContentApiPaths.EDITABLE_PAGES),
      APIHelpers.json<EditablePage[]>,
      APIHelpers.standardError,
    );
  },

  getEditablePage: (pageId: string) => {
    return handleAPIRequest(
      apiClient.get(EditableContentApiPaths.EDITABLE_PAGE(pageId)),
      APIHelpers.json<EditablePage>,
      APIHelpers.standardError,
    );
  },

  updatePageContent: (pageId: string, editPageRequest: EditablePageRequest) => {
    return handleAPIRequest(
      apiClient.patch(EditableContentApiPaths.EDITABLE_PAGE(pageId), editPageRequest),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },
});

export default editableContentApi;
