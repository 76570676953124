import saveAs from 'file-saver';
import { SubmissionStatus } from 'nrosh-common/Api/Enums';
import { ProviderSummary } from 'nrosh-common/Api/ProvidersApi';
import { SurveyStatus } from 'nrosh-common/Api/SubmissionsApi';
import { SubmissionBySurveyId, Survey } from 'nrosh-common/Api/SurveysApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { useEffect, useState } from 'react';
import { Form, Stack } from 'react-bootstrap';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { PrimaryButton, TertiaryButton } from '@/Components/Buttons/DCSButton';
import { TertiaryLinkButton } from '@/Components/Buttons/TertiaryLinkButton';
import { useErrorReporting } from '@/Components/Errors/DCSErrorBoundary';
import AssignProvider from '@/Components/Icons/AssignProvider';
import ChevronLeft from '@/Components/Icons/ChevronLeft';
import Download from '@/Components/Icons/Download';
import LinkButton from '@/Components/Links/LinkButton';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import { useModal } from '@/Components/Modal/ModalProvider';
import PageHeader from '@/Components/PageHeader/PageHeader';
import ToastWithIcon, { ToastType } from '@/Components/ToastWithIcon/ToastWithIcon';
import { ProvidersApi, SurveysApi } from '@/Helpers/Apis';
import { surveyDisplayName } from '@/Helpers/SurveyHelper';
import useUnsavedChangesWarning from '@/Hooks/useUnsavedChangesWarning';
import { surveyPages } from '@/Pages/Home/SitePages';
import AssignSurveyTable from '@/Pages/Surveys/AssignSurveyTable';

export type ProviderWithSubmissionStatus = ProviderSummary & {
  surveyAssigned: boolean;
  status?: SubmissionStatus;
  submissionId?: number;
};

const calculateInitialTableData = (
  timePeriod: string,
  submissions: SubmissionBySurveyId[],
  providersList: ProviderSummary[],
): ProviderWithSubmissionStatus[] => {
  const submissionsInTimePeriod = submissions.filter(
    (submission) => submission.timePeriodId === parseInt(timePeriod, 10),
  );

  return providersList.map((provider) => {
    const foundSubmission = submissionsInTimePeriod.find(
      (submission) => submission.providerNumber === provider.providerNumber,
    );
    return {
      ...provider,
      surveyAssigned: !!foundSubmission,
      status: foundSubmission?.status,
      submissionId: foundSubmission?.submissionId,
    };
  });
};

const AssignSurveyPage = (): JSX.Element => {
  const { surveyId, timePeriodId } = useParams();
  const [dataLastUploadedAt, setDataLastuploadedAt] = useState<Date | null>(null);
  const [surveyData] = useEndpoint<Survey>(SurveysApi.getSurvey, surveyId, timePeriodId);
  const [providersList] = useEndpoint<ProviderSummary[]>(ProvidersApi.getProviders, true);
  const [submissions] = useEndpoint<SubmissionBySurveyId[]>(
    SurveysApi.getSubmissionsBySurveyId,
    surveyId,
    dataLastUploadedAt,
  );
  // No point calculating the initial table data here, as we know it will be empty until the time periods have been fetched.
  const [tableData, setTableData] = useState<ProviderWithSubmissionStatus[]>([]);
  const [initialProviderIds, setInitialProviderIds] = useState<number[]>([]);
  const [isSaving, setSaving] = useState(false);
  const [setDirty, setPristine, isDirty] = useUnsavedChangesWarning();
  const [isNotifying, setIsNotifying] = useState<boolean>(false);
  const [raiseError] = useErrorReporting();
  const navigate = useNavigate();
  const { confirm } = useModal();

  useEffect(() => {
    if (submissions && providersList) {
      const newTableData = calculateInitialTableData(timePeriodId!, submissions, providersList);
      setTableData(newTableData);
      setInitialProviderIds(newTableData.filter((provider) => provider.surveyAssigned).map((p) => p.id));
    }
  }, [timePeriodId, submissions, providersList]);

  if (!providersList || !submissions || !surveyData) {
    return <LoadingMessage />;
  }

  const surveyInstance = surveyData.instances.find((i) => i.timePeriodId === Number(timePeriodId));
  const { timePeriodName, surveyStatus } = surveyInstance!;
  const isSurveyClosed = surveyStatus === SurveyStatus.Closed;
  const isSurveyInDevelopment = surveyStatus === SurveyStatus.InDevelopment;
  const surveyViewPath = generatePath(surveyPages.SurveyView.path, { surveyId, timePeriodId });

  const downloadAssignedProviders = async (): Promise<void> => {
    try {
      const users = await SurveysApi.downloadAssignedProviders(surveyId!, timePeriodId!);
      saveAs(
        URL.createObjectURL(users),
        `${surveyData.surveyName}_${timePeriodName}-AssignedProviders.csv`.replaceAll(' ', '_'),
      );
    } catch {
      raiseError();
    }
  };

  const onSave = async (notify: boolean): Promise<void> => {
    setSaving(true);
    const assignedProviderIds = new Set(tableData.filter((provider) => provider.surveyAssigned).map((p) => p.id));

    const removedProviderIds = new Set(initialProviderIds.filter((id) => !assignedProviderIds.has(id)));
    const providersToWarnAboutUnassignedSurvey = tableData.filter(
      (p) => p.status && p.status !== SubmissionStatus.NotStarted && removedProviderIds.has(p.id),
    );

    let modalContent;
    if (providersToWarnAboutUnassignedSurvey.length === 1) {
      modalContent = (
        <div>
          <p>
            Provider <strong> {providersToWarnAboutUnassignedSurvey[0].name} </strong> may have started filling in the
            survey.
          </p>
          <p>
            <strong>
              Any data that has been entered by this provider will be permanently lost if they are unassigned.
            </strong>
          </p>
          <p>Do you wish to continue?</p>
        </div>
      );
    } else if (providersToWarnAboutUnassignedSurvey.length <= 10) {
      modalContent = (
        <div>
          <p>
            The following providers may have started filling in the survey:{' '}
            <strong>{providersToWarnAboutUnassignedSurvey.map((p) => p.name).join(', ')}</strong>.
          </p>
          <p>
            <strong>
              Any data that has been entered by these providers will be permanently lost if they are unassigned.
            </strong>
          </p>
          <p>Do you wish to continue?</p>
        </div>
      );
    } else {
      modalContent = (
        <div>
          <p>
            <strong>{providersToWarnAboutUnassignedSurvey.length}</strong> providers may have started filling in the
            survey.
          </p>
          <p>
            <strong>
              Any data that has been entered by these providers will be permanently lost if they are unassigned.
            </strong>
          </p>
          <p>Do you wish to continue?</p>
        </div>
      );
    }

    let proceed;
    if (providersToWarnAboutUnassignedSurvey.length === 0) {
      proceed = true;
    } else {
      proceed = await confirm(modalContent);
    }

    if (proceed) {
      await SurveysApi.assignSurvey(surveyId!, timePeriodId!, [...assignedProviderIds], notify).withHandlers(
        async () => {
          setDataLastuploadedAt(new Date());
          setPristine();
          setInitialProviderIds([...assignedProviderIds]);
        },
        async ({ message }) => raiseError(message),
      );
      setSaving(false);
    } else {
      setSaving(false);
    }
  };

  return (
    <Stack gap={5}>
      <Stack gap={4}>
        <Stack gap={5}>
          <PageHeader
            heading="Assign survey"
            subheading={surveyDisplayName(surveyData.surveyName, timePeriodName)}
            crumbsType={RootPathType.AdminSurveys}
            crumbs={[
              {
                name: surveyDisplayName(surveyData.surveyName, timePeriodName),
                path: surveyPages.SurveyView.path,
              },
              {
                name: 'Assign survey',
                path: surveyPages.SurveyAssign.path,
              },
            ]}
          />
          {isSurveyInDevelopment && (
            <ToastWithIcon
              text="This survey is in development. Assigned Providers will not see this survey in their dashboard until it is open."
              type={ToastType.Info}
            />
          )}
          <p>Select the providers you want to assign to the survey from the list below.</p>
          <AssignSurveyTable
            submissions={submissions}
            providersList={providersList}
            timePeriod={timePeriodId!}
            tableData={tableData}
            setTableData={setTableData}
            setDirty={setDirty}
            // key is being used to clear the global filter when the time period changes.
            key={timePeriodId}
            isSurveyClosed={isSurveyClosed}
          />
        </Stack>
        <div className="d-flex gap-3">
          {!isSurveyClosed && (
            <TertiaryButton
              icon={<AssignProvider />}
              onClick={() => navigate(generatePath(surveyPages.SurveyAssignUpload.path, { surveyId, timePeriodId }))}
            >
              Upload bulk assign file
            </TertiaryButton>
          )}
          <TertiaryButton icon={<Download />} onClick={downloadAssignedProviders}>
            Download assigned providers
          </TertiaryButton>
        </div>
      </Stack>
      {!isSurveyClosed && (
        <>
          <Form.Check
            type="checkbox"
            label="Notify assigned provider users"
            id="notify-toggle"
            className="mt-2 mb-3"
            onChange={(e) => setIsNotifying(e.target.checked)}
            checked={isNotifying}
          />
          <div className="d-flex gap-3">
            <LinkButton to={surveyViewPath} variant="outline-primary" disabled={isSaving}>
              Cancel
            </LinkButton>
            {!isSaving ? (
              <PrimaryButton
                className="addSaveButton"
                type="button"
                onClick={() => onSave(isNotifying)}
                disabled={!isDirty}
              >
                Save and assign
              </PrimaryButton>
            ) : (
              <LoadingButton message="Saving..." />
            )}
          </div>
          <TertiaryLinkButton to={surveyViewPath} icon={<ChevronLeft />} text="Return to survey overview" />
        </>
      )}
    </Stack>
  );
};
export default AssignSurveyPage;
