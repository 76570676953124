import { Stack } from 'react-bootstrap';
import { Breadcrumbs, Crumb, RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { DCSErrorContextOutlet } from '@/Components/Errors/DCSErrorBoundary';

type PageHeaderProps = {
  heading: string;
  subheading?: string;
  crumbs?: Crumb[];
  crumbsType: RootPathType;
  noErrorOutlet?: boolean;
};

export const PageHeader = ({
  heading,
  subheading,
  crumbs,
  crumbsType,
  noErrorOutlet,
}: PageHeaderProps): JSX.Element => (
  <Stack gap={5}>
    <Breadcrumbs type={crumbsType} crumbs={crumbs} />
    <Stack gap={3}>
      <h1>{heading}</h1>
      {subheading && <h2>{subheading}</h2>}
    </Stack>
    {!noErrorOutlet && <DCSErrorContextOutlet />}
  </Stack>
);

export default PageHeader;
