const bytesPerKilobyte = 1024;
const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export const formatBytes = (bytes: number): string => {
  if (!bytes) return '0 Bytes';

  const index = Math.floor(Math.log(bytes) / Math.log(bytesPerKilobyte));

  return `${parseFloat((bytes / bytesPerKilobyte ** index).toFixed(1))} ${sizes[index]}`;
};
