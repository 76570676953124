import { UnhandledIntermediateFetchResult } from 'nrosh-common/Api/ApiClient';
import { Tag } from 'nrosh-common/Api/CommonTypes';
import { RshRole } from 'nrosh-common/Api/Enums';
import { ProviderSummary } from 'nrosh-common/Api/ProvidersApi';
import { DocumentType, RegulatoryDocumentMetadata } from 'nrosh-common/Api/RegulatoryDocumentsApi';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import useEndpoint, { useEndpointConditionally } from 'nrosh-common/Hooks/useEndpoint';
import { FormEvent, useContext, useReducer, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import { DCSErrorContextOutlet, useErrorReporting } from '@/Components/Errors/DCSErrorBoundary';
import FileUploadArea from '@/Components/FileUpload/FileUploadArea';
import { FileItem, MultipleFileUploadManager } from '@/Components/FileUpload/MultipleFileUploadManager';
import { AccessibleFeedback } from '@/Components/Form/AccessibleFeedback';
import { UtcDatePicker } from '@/Components/Form/FormDatePicker';
import LinkButton from '@/Components/Links/LinkButton';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import { ProvidersApi, RegulatoryDocumentsApi, TagsApi } from '@/Helpers/Apis';
import { getValidityProps } from '@/Helpers/Forms';
import useUnsavedChangesWarning from '@/Hooks/useUnsavedChangesWarning';
import { adminPages, providerPages } from '@/Pages/Home/SitePages';
import '@/Pages/RegulatoryDocuments/RegulatoryDocumentForm.scss';

const createAction = (
  isAdmin: boolean,
  providerId: number | null,
  formData: FormData,
): UnhandledIntermediateFetchResult<unknown, string> => {
  if (isAdmin) {
    return RegulatoryDocumentsApi.uploadForProvider(providerId!.toString(), formData);
  }

  return RegulatoryDocumentsApi.upload(formData);
};

type RegulatoryDocumentFormProps = {
  initialDocument?: RegulatoryDocumentMetadata;
};

const RegulatoryDocumentForm = ({ initialDocument }: RegulatoryDocumentFormProps): JSX.Element => {
  const { documentId } = useParams();
  const auth = useContext(AuthContext);

  const isCreate = !initialDocument;

  const isAdmin = auth.hasRole(RshRole.User)!;
  const [providers] = useEndpointConditionally<ProviderSummary[]>(ProvidersApi.getProviders, isAdmin);
  const [allTags] = useEndpoint<Tag[]>(TagsApi.getDocumentTags);
  const [documentTypes] = useEndpoint<DocumentType[]>(RegulatoryDocumentsApi.getDocumentTypes);

  const [raiseError, clearError] = useErrorReporting();
  const [isSaving, setIsSaving] = useState(false);
  const [setDirty, setPristine, isDirty] = useUnsavedChangesWarning();
  const [validated, setValidated] = useState<boolean>(false);
  const [filesHaveBeenUploaded, setFilesHaveBeenUploaded] = useState(false);
  const [showSuccessfulUploadMessage, setShowSuccessfulUploadMessage] = useState(false);

  const [providerId, setProviderId] = useState<number | null>(initialDocument?.providerId ?? null);
  const [description, setDescription] = useState<string | null>(initialDocument?.description ?? null);
  const [files, setFiles] = useState<FileItem[]>(
    isCreate ? [] : [{ fileName: initialDocument.fileName, fileObject: null }],
  );
  const [fileWasChanged, markFileAsChanged] = useReducer(() => true, false);
  const [documentType] = useState<string | null>('OtherRegulatoryDocument');
  const [primaryTagId, setPrimaryTagId] = useState<number | null>(initialDocument?.primaryTag?.id ?? null);
  const [secondaryTagId, setSecondaryTagId] = useState<number | null>(initialDocument?.secondaryTag?.id ?? null);
  const [scheduledDeletionDate, setScheduledDeletionDate] = useState(initialDocument?.scheduledDeletionDate ?? null);

  const navigate = useNavigate();

  if ((isAdmin && !providers) || !allTags || !documentTypes) {
    return <LoadingMessage />;
  }

  const submitButtonMessage = isCreate ? 'Upload' : 'Save';

  const returnPath = isAdmin ? adminPages.RegulatoryDocuments.path : providerPages.RegulatoryDocuments.path;

  const initialFileData = initialDocument && {
    name: initialDocument.fileName,
    sizeBytes: initialDocument.fileSizeBytes,
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (!e.currentTarget.checkValidity() || files.length === 0) {
      setValidated(true);
      return;
    }

    clearError();
    setPristine();
    setIsSaving(true);

    const formData = new FormData();

    const filesToUpload = files
      .filter((fileItem) => fileItem.fileObject !== null)
      .map((fileItem) => fileItem.fileObject!);
    filesToUpload.forEach((file) => formData.append(file.name, file, file.name));

    formData.append('fileWasChanged', fileWasChanged.toString());
    if (documentType) formData.append('documentType', documentType);
    if (primaryTagId) formData.append('primaryTagId', primaryTagId.toString());
    if (secondaryTagId) formData.append('secondaryTagId', secondaryTagId.toString());
    if (description) formData.append('description', description);
    if (scheduledDeletionDate && isAdmin) formData.append('scheduledDeletionDate', scheduledDeletionDate.toISOString());

    const request = isCreate
      ? createAction(isAdmin, providerId, formData)
      : RegulatoryDocumentsApi.update(documentId!.toString(), formData);
    const response = await request.raw;

    if (response.ok) {
      if (isCreate) {
        setFilesHaveBeenUploaded(true);
        setShowSuccessfulUploadMessage(true);
      } else {
        navigate(returnPath);
        return;
      }
    } else if (response.value.status === 400) {
      raiseError(response.value.message);
    } else {
      raiseError();
    }

    setIsSaving(false);
  };

  return (
    <Form noValidate className="w-25 regulatoryDocumentForm" onSubmit={onSubmit}>
      <Form.Group className="mb-3" controlId="upload">
        <Form.Label>Select {isCreate ? 'files' : 'file'}</Form.Label>
        {isCreate ? (
          <MultipleFileUploadManager
            aria-label="files"
            files={files}
            setFiles={setFiles}
            setDirty={setDirty}
            {...getValidityProps(validated && files.length === 0, 'file-feedback')}
          />
        ) : (
          <FileUploadArea
            aria-label="file"
            value={files[0]?.fileObject}
            initialFileData={initialFileData}
            allowZeroSizeFiles={false}
            onChange={(file) => {
              setFiles(file ? [{ fileName: file.name, fileObject: file }] : []);
              markFileAsChanged();
              setDirty();
            }}
            {...getValidityProps(validated && files.length === 0, 'file-feedback')}
          />
        )}
        <AccessibleFeedback displayFeedback={validated && files.length === 0} id="file-feedback">
          Please select {isCreate ? 'at least one' : 'a'} file
        </AccessibleFeedback>
      </Form.Group>
      {isAdmin && isCreate && providers && (
        <Form.Group className="mb-3" controlId="select-provider">
          <Form.Label>Provider</Form.Label>
          <Form.Select
            value={providerId ?? undefined}
            onChange={(e) => {
              setProviderId(e.target.value ? Number(e.target.value) : null);
              setDirty();
            }}
            aria-label="select-provider"
            required
            {...getValidityProps(validated && providerId === null, 'provider-feedback')}
          >
            <option value="" hidden>
              Select provider
            </option>
            {providers.map((p) => (
              <option key={p.id} value={p.id}>
                {p.name}
              </option>
            ))}
          </Form.Select>
          <AccessibleFeedback displayFeedback={validated && providerId === null} id="provider-feedback">
            Please select a provider
          </AccessibleFeedback>
        </Form.Group>
      )}
      <Form.Group controlId="description" className="mb-3">
        <Form.Label>Description</Form.Label>
        <Form.Control
          name="description"
          aria-label="description"
          value={description ?? undefined}
          onChange={(e) => {
            setDescription(e.target.value);
            setDirty();
          }}
        />
      </Form.Group>
      {(isAdmin || scheduledDeletionDate !== null) && (
        <Form.Group className="mb-3">
          <Form.Label htmlFor="scheduledDeletionDate">Scheduled deletion date</Form.Label>
          <UtcDatePicker
            value={scheduledDeletionDate}
            onChange={(newDate) => {
              setScheduledDeletionDate(newDate);
              setDirty();
            }}
            inputId="scheduledDeletionDate"
            min={new Date()}
            disabled={!isAdmin}
          />
        </Form.Group>
      )}
      {documentType && (
        <>
          <Form.Group className="mb-3" controlId="select-primary-tag">
            <Form.Label>Primary Tag</Form.Label>
            <Form.Select
              value={primaryTagId ?? undefined}
              onChange={(e) => {
                setPrimaryTagId(e.target.value ? Number(e.target.value) : null);
                if (!e.target.value) {
                  setSecondaryTagId(null);
                }
                setDirty();
              }}
              aria-label="select-primary-tag"
            >
              <option value="" hidden>
                Select primary tag
              </option>
              {allTags
                .filter((t) => t.documentType === documentType)
                .map((t) => (
                  <option key={t.id} value={t.id}>
                    {t.name}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
          {primaryTagId && (
            <Form.Group className="mb-3" controlId="select-secondary-type">
              <Form.Label>Secondary Tag</Form.Label>
              <Form.Select
                value={secondaryTagId ?? undefined}
                onChange={(e) => {
                  setSecondaryTagId(e.target.value ? Number(e.target.value) : null);
                  setDirty();
                }}
                aria-label="select-secondary-tag"
              >
                <option value="" hidden>
                  Select secondary tag
                </option>
                {allTags
                  .filter((t) => t.documentType === documentType)
                  .map((t) => (
                    <option key={t.id} value={t.id}>
                      {t.name}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
          )}
        </>
      )}
      {!filesHaveBeenUploaded ? (
        <div className="d-flex gap-2 mb-3">
          <LinkButton to={returnPath} variant="outline-primary" disabled={isSaving}>
            Cancel
          </LinkButton>
          {isSaving ? (
            <LoadingButton message={submitButtonMessage} />
          ) : (
            <PrimaryButton type="submit" disabled={!isDirty}>
              {submitButtonMessage}
            </PrimaryButton>
          )}
        </div>
      ) : (
        <div className="d-flex gap-2 mb-3">
          <LinkButton to={returnPath} variant="outline-primary">
            Return to Regulatory Documents
          </LinkButton>
        </div>
      )}
      <DCSErrorContextOutlet />
      {showSuccessfulUploadMessage && (
        <Alert variant="success" dismissible onClose={() => setShowSuccessfulUploadMessage(false)}>
          All documents have been successfully uploaded
        </Alert>
      )}
    </Form>
  );
};

export default RegulatoryDocumentForm;
