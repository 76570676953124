import { DateTimeSelectorProps } from '@/Components/DateTime/DateTimeSelectorProps';
import { LocalDatePicker } from '@/Components/Form/FormDatePicker';

const DateSelector = ({
  dateTime,
  setDateTime,
  disabled = false,
  allowPast = false,
  isInvalid = false,
  ariaLabel,
}: DateTimeSelectorProps): JSX.Element => {
  const handleDateChange = (newDate: Date | null): void => {
    if (setDateTime) {
      if (newDate) {
        setDateTime((prevDateTime) => {
          if (prevDateTime) {
            // Override the date of the previous date time, but leave the time unchanged.
            return new Date(prevDateTime.setFullYear(newDate.getFullYear(), newDate.getMonth(), newDate.getDate()));
          }
          return newDate;
        });
      } else {
        setDateTime(null);
      }
    }
  };

  return (
    <LocalDatePicker
      value={dateTime}
      onChange={handleDateChange}
      disabled={disabled}
      isInvalid={isInvalid}
      min={!allowPast ? new Date() : undefined}
      ariaLabel={ariaLabel}
    />
  );
};

export default DateSelector;
