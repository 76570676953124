import ApiClient, { APIHelpers, handleAPIRequest } from './ApiClient';

export type ContentChunk = {
  content: string;
};

const PublicPagesApiRoot = 'ContentChunk';
export const PublicPagesApiPaths = {
  CONTENT_CHUNK: (pageTitle: string) => `${PublicPagesApiRoot}/${pageTitle}`,
};

const publicPagesApi = (apiClient: ApiClient) => ({
  getContentChunk: (pageTitle: string) => {
    return handleAPIRequest(
      apiClient.get(PublicPagesApiPaths.CONTENT_CHUNK(pageTitle)),
      APIHelpers.json<ContentChunk>,
      APIHelpers.standardError,
    );
  },
});

export default publicPagesApi;
