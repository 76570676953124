import { saveAs } from 'file-saver';

const escapeAndQuoteValue = (value: string): `"${string}"` => `"${value.replaceAll('"', '""')}"`;
const arrayToCsvString = (rowValues: string[]): string => rowValues.map(escapeAndQuoteValue).join(',');

const buildCsv = (headers: string[], rows: { [key: string]: string | number | null | undefined }[]): string => {
  const orderedRows = rows.map((row) => headers.map((columnName) => row[columnName]?.toString() ?? ''));
  const data = [headers, ...orderedRows];
  return data.map(arrayToCsvString).join('\r\n');
};

export const exportTableData = <T extends object>(
  tableData: T[],
  tableRowToExportRow: (dataRow: T) => { [key: string]: string | number | null | undefined },
  fileName?: string,
): void => {
  if (tableData.length === 0) return;

  const dataForExport = tableData.map(tableRowToExportRow);
  const headerRow = Object.keys(dataForExport[0]);

  const exportString = buildCsv(headerRow, dataForExport);

  const blob = new Blob([exportString], { type: 'text/csv;charset=utf-8' });
  saveAs(blob, fileName ?? 'Export.csv');
};
