import { useEffect, useState } from 'react';
import { PaginationProps } from '@/Components/Pagination/Pagination';

const MAX_PAGE_SIZE = 10;

type Pagination<T> = {
  page: Array<T>;
} & PaginationProps;

const usePagination = <T>(data: Array<T>): Pagination<T> => {
  const [allData, setAllData] = useState(data);
  const [page, setPage] = useState(data.slice(0, MAX_PAGE_SIZE));
  const [pageIndex, setPageIndex] = useState(0);
  const numberOfPages = Math.ceil(data.length / MAX_PAGE_SIZE);

  useEffect(() => {
    const sliceStart = pageIndex * MAX_PAGE_SIZE;
    setPage(allData.slice(sliceStart, sliceStart + MAX_PAGE_SIZE));
  }, [allData, pageIndex]);

  useEffect(() => {
    setAllData(data);
    setPage(data.slice(0, MAX_PAGE_SIZE));
    setPageIndex(0);
  }, [data]);

  return {
    page,
    pageOptions: new Array(numberOfPages).map((_, index) => index),
    pageIndex,
    gotoPage: (index) => {
      setPageIndex(index);
    },
    nextPage: () => {
      setPageIndex(pageIndex + 1);
    },
    canNextPage: pageIndex + 1 < numberOfPages,
    previousPage: () => {
      setPageIndex(pageIndex - 1);
    },
    canPreviousPage: pageIndex > 0,
  };
};

export default usePagination;
