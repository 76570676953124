import { ContactType } from 'nrosh-common/Api/SurveysApi';
import { ChangeEvent, useState } from 'react';
import { Form } from 'react-bootstrap';
import { ContactTypeTitleMapping } from '@/Components/EndOfSurveyChecks/ContactTypeTitleMapping';
import '@/Pages/Providers/ContactForm.scss';
import { AccessibleFeedback } from '@/Components/Form/AccessibleFeedback';
import { getValidityProps } from '@/Helpers/Forms';
import { alphanumericOrSpaceRegex, emailRegex, textIsForbidden } from '@/Helpers/Validation';
import { ProviderContactsField } from '@/Pages/Providers/ProviderContactsForm';

type ProviderContactFormProps = {
  contactType: ContactType;
  fieldValues: Record<ProviderContactsField, string>;
  updateFieldValue: (field: ProviderContactsField, newValue: string) => void;
  readonly: boolean;
  validated: boolean;
  isAdmin: boolean;
};

const ContactForm = (props: ProviderContactFormProps): JSX.Element => {
  const { contactType, fieldValues, updateFieldValue, readonly, validated, isAdmin } = props;

  const [fieldValidities, setFieldValidities] = useState<Record<ProviderContactsField, boolean>>({
    [ProviderContactsField.Name]: true,
    [ProviderContactsField.JobTitle]: true,
    [ProviderContactsField.AddressLine1]: true,
    [ProviderContactsField.AddressLine2]: true,
    [ProviderContactsField.AddressLine3]: true,
    [ProviderContactsField.AddressLine4]: true,
    [ProviderContactsField.Postcode]: true,
    [ProviderContactsField.PhoneNumber]: true,
    [ProviderContactsField.EmailAddress]: true,
  });

  const updateFieldValidity = (field: ProviderContactsField, newValue: boolean): void =>
    setFieldValidities((prev) => ({ ...prev, [field]: newValue }));

  const onFieldChange = (field: ProviderContactsField) => (event: ChangeEvent<HTMLInputElement>) => {
    updateFieldValue(field, event.target.value);
    updateFieldValidity(field, !textIsForbidden(event.target.value));
  };

  const onFieldInvalid = (field: ProviderContactsField) => (_: ChangeEvent<HTMLInputElement>) =>
    updateFieldValidity(field, false);

  return (
    <div className="w-25 mb-4 providerContactFormContainer">
      <h3>{ContactTypeTitleMapping[contactType]}</h3>
      <Form.Group className="mb-3" controlId={`name-${contactType}`}>
        <Form.Label>Name</Form.Label>
        <Form.Control
          value={fieldValues[ProviderContactsField.Name]}
          onChange={onFieldChange(ProviderContactsField.Name)}
          disabled={readonly}
          required={!isAdmin}
          maxLength={256}
          onInvalid={onFieldInvalid(ProviderContactsField.Name)}
          {...getValidityProps(
            validated && !fieldValidities[ProviderContactsField.Name],
            `${contactType}-name-feedback`,
          )}
        />
        <AccessibleFeedback
          displayFeedback={validated && !fieldValidities[ProviderContactsField.Name]}
          id={`${contactType}-name-feedback`}
        >
          Please provide a name
        </AccessibleFeedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId={`jobTitle-${contactType}`}>
        <Form.Label>Job Title</Form.Label>
        <Form.Control
          value={fieldValues[ProviderContactsField.JobTitle]}
          onChange={onFieldChange(ProviderContactsField.JobTitle)}
          disabled={readonly}
          required={!isAdmin}
          maxLength={256}
          onInvalid={onFieldInvalid(ProviderContactsField.JobTitle)}
          {...getValidityProps(
            validated && !fieldValidities[ProviderContactsField.JobTitle],
            `${contactType}-job-title-feedback`,
          )}
        />
        <AccessibleFeedback
          displayFeedback={validated && !fieldValidities[ProviderContactsField.JobTitle]}
          id={`${contactType}-job-title-feedback`}
        >
          Please provide a job title
        </AccessibleFeedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId={`email-${contactType}`}>
        <Form.Label>Email</Form.Label>
        <Form.Control
          value={fieldValues[ProviderContactsField.EmailAddress]}
          onChange={onFieldChange(ProviderContactsField.EmailAddress)}
          disabled={readonly}
          required={!isAdmin}
          minLength={6}
          maxLength={256}
          pattern={emailRegex.source}
          onInvalid={onFieldInvalid(ProviderContactsField.EmailAddress)}
          {...getValidityProps(
            validated && !fieldValidities[ProviderContactsField.EmailAddress],
            `${contactType}-email-feedback`,
          )}
        />
        <AccessibleFeedback
          displayFeedback={validated && !fieldValidities[ProviderContactsField.EmailAddress]}
          id={`${contactType}-email-feedback`}
        >
          Please provide a valid email address
        </AccessibleFeedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId={`phoneNumber-${contactType}`}>
        <Form.Label>Phone</Form.Label>
        <Form.Control
          value={fieldValues[ProviderContactsField.PhoneNumber]}
          onChange={onFieldChange(ProviderContactsField.PhoneNumber)}
          disabled={readonly}
          required={!isAdmin}
          minLength={10}
          maxLength={256}
          onInvalid={onFieldInvalid(ProviderContactsField.PhoneNumber)}
          {...getValidityProps(
            validated && !fieldValidities[ProviderContactsField.PhoneNumber],
            `${contactType}-phone-feedback`,
          )}
        />
        <AccessibleFeedback
          displayFeedback={validated && !fieldValidities[ProviderContactsField.PhoneNumber]}
          id={`${contactType}-phone-feedback`}
        >
          Please provide a valid phone number (at least 10 digits)
        </AccessibleFeedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId={`addressLine1-${contactType}`}>
        <Form.Label>Address Line 1</Form.Label>
        <Form.Control
          value={fieldValues[ProviderContactsField.AddressLine1]}
          onChange={onFieldChange(ProviderContactsField.AddressLine1)}
          disabled={readonly}
          required={!isAdmin}
          maxLength={256}
          onInvalid={onFieldInvalid(ProviderContactsField.AddressLine1)}
          {...getValidityProps(
            validated && !fieldValidities[ProviderContactsField.AddressLine1],
            `${contactType}-address-line-1-feedback`,
          )}
        />
        <AccessibleFeedback
          displayFeedback={validated && !fieldValidities[ProviderContactsField.AddressLine1]}
          id={`${contactType}-address-line-1-feedback`}
        >
          Please provide at least the first line of the address
        </AccessibleFeedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId={`addressLine2-${contactType}`}>
        <Form.Label>Address Line 2</Form.Label>
        <Form.Control
          value={fieldValues[ProviderContactsField.AddressLine2]}
          onChange={onFieldChange(ProviderContactsField.AddressLine2)}
          disabled={readonly}
          maxLength={256}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId={`addressLine3-${contactType}`}>
        <Form.Label>Address Line 3</Form.Label>
        <Form.Control
          value={fieldValues[ProviderContactsField.AddressLine3]}
          onChange={onFieldChange(ProviderContactsField.AddressLine3)}
          disabled={readonly}
          maxLength={256}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId={`addressLine4-${contactType}`}>
        <Form.Label>Address Line 4</Form.Label>
        <Form.Control
          value={fieldValues[ProviderContactsField.AddressLine4]}
          onChange={onFieldChange(ProviderContactsField.AddressLine4)}
          disabled={readonly}
          maxLength={256}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId={`postcode-${contactType}`}>
        <Form.Label>Postcode</Form.Label>
        <Form.Control
          value={fieldValues[ProviderContactsField.Postcode]}
          onChange={onFieldChange(ProviderContactsField.Postcode)}
          disabled={readonly}
          className="postCodeInput"
          required={!isAdmin}
          pattern={alphanumericOrSpaceRegex.source}
          minLength={5}
          maxLength={8}
          onInvalid={onFieldInvalid(ProviderContactsField.Postcode)}
          {...getValidityProps(
            validated && !fieldValidities[ProviderContactsField.Postcode],
            `${contactType}-postcode-feedback`,
          )}
        />
        <AccessibleFeedback
          displayFeedback={validated && !fieldValidities[ProviderContactsField.Postcode]}
          id={`${contactType}-postcode-feedback`}
        >
          Please provide a valid postcode
        </AccessibleFeedback>
      </Form.Group>
    </div>
  );
};

export default ContactForm;
