import { SurveyStatus } from 'nrosh-common/Api/SubmissionsApi';
import { EditSurveyChecksRequest, SurveyChecks } from 'nrosh-common/Api/SurveysApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import PageHeadingWithSurveyStatusBadge from '@/Components/Badges/PageHeadingWithSurveyStatusBadge';
import { Breadcrumbs, RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import EndOfSurveyChecksForm from '@/Components/EndOfSurveyChecks/EndOfSurveyChecksForm';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import { SurveysApi } from '@/Helpers/Apis';
import { surveyDisplayName } from '@/Helpers/SurveyHelper';
import { surveyPages } from '@/Pages/Home/SitePages';
import '@/Pages/Surveys/EndOfSurveyChecksPage.scss';

const EndOfSurveyChecksPage = (): JSX.Element => {
  const { surveyId, timePeriodId } = useParams();
  const [checks] = useEndpoint<SurveyChecks>(SurveysApi.getEndOfSurveyChecks, surveyId, timePeriodId);

  const onSave = async (
    surveyChecks: EditSurveyChecksRequest,
  ): Promise<{ success: boolean; errorMessage?: string }> => {
    const response = await SurveysApi.editEndOfSurveyChecks(surveyChecks, surveyId!, timePeriodId!).raw;
    if (response.ok) {
      return { success: true };
    }
    const errorMessage = response.value.message;
    return { success: false, errorMessage };
  };

  if (!checks) {
    return <LoadingMessage />;
  }

  const isSurveyClosed = checks.surveyStatus === SurveyStatus.Closed;

  return (
    <Stack gap={5}>
      <Stack gap={5}>
        <Breadcrumbs
          crumbs={[
            {
              name: surveyDisplayName(checks.surveyName, checks.timePeriodName),
              path: surveyPages.SurveyView.path,
            },
            {
              name: 'View survey',
              path: surveyPages.SurveyView.path,
            },
            {
              name: `${isSurveyClosed ? 'View' : 'Edit'} survey declarations`,
              path: surveyPages.SurveyChecks.path,
            },
          ]}
          type={RootPathType.AdminSurveys}
        />
        <PageHeadingWithSurveyStatusBadge
          title={surveyDisplayName(checks.surveyName, checks.timePeriodName)}
          status={checks.surveyStatus}
        />
      </Stack>
      <EndOfSurveyChecksForm checks={checks} onSave={onSave} surveyStatus={checks.surveyStatus} />
    </Stack>
  );
};

export default EndOfSurveyChecksPage;
