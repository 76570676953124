import { Info } from '@/Components/Icons/Info';
import TooltipOnHover from '@/Components/Tooltips/TooltipOnHover';

type Props = {
  message: string;
  placement?: 'left' | 'right' | 'top' | 'bottom';
};

const TooltippedInfoIcon = ({ message, placement = 'right' }: Props): JSX.Element => (
  <TooltipOnHover placement={placement} text={message}>
    <button className="iconButton tooltipButton" type="button" aria-label="Info">
      <Info />
    </button>
  </TooltipOnHover>
);

export default TooltippedInfoIcon;
