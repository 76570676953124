import { SurveyStatus } from 'nrosh-common/Api/SubmissionsApi';
import { SurveyInstance } from 'nrosh-common/Api/SurveysApi';
import { Dispatch, SetStateAction, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import LinkButton from '@/Components/Links/LinkButton';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { surveyPages } from '@/Pages/Home/SitePages';
import useManageSurveyState from '@/Pages/Surveys/SurveyViewPage/useManageSurveyState';

enum SurveyState {
  NotConfigured = 'NotConfigured',
  ConfiguredNoProviders = 'ConfiguredNoProviders',
  NotOpenProviders = 'NotOpenProviders',
  Open = 'Open',
  Closed = 'Closed',
}

export type SurveyManagementProps = {
  survey: SurveyInstance;
  setError: Dispatch<SetStateAction<string | null>>;
  setSurveyStatus: Dispatch<SetStateAction<SurveyStatus | undefined>>;
  surveyStatus: SurveyStatus;
};

type SurveyManagementTextProps = {
  surveyState: SurveyState;
};

type SurveyStatusButtonProps = {
  surveyState: SurveyState;
  setError: Dispatch<SetStateAction<string | null>>;
  setSurveyStatus: Dispatch<SetStateAction<SurveyStatus | undefined>>;
  surveyId: string;
  timePeriodId: string;
};

type ButtonProps = {
  buttonText: string;
  loadingText: string;
  onClick: () => Promise<void>;
};

const SurveyManagementText = ({ surveyState }: SurveyManagementTextProps): JSX.Element => {
  switch (surveyState) {
    case SurveyState.NotConfigured:
      return (
        <p>
          This survey is <span className="boldText">not configured</span>. Please click Configure Survey to add required
          information.
        </p>
      );
    case SurveyState.ConfiguredNoProviders:
      return (
        <p>
          This survey is <span className="boldText">now configured</span>. Please assign at least one Provider in order
          to open this survey.
        </p>
      );
    case SurveyState.NotOpenProviders:
      return <p>You can now open the survey. This will allow assigned providers to start submitting their answers.</p>;
    case SurveyState.Open:
      return (
        <p>
          You can close the survey at any point. Assigned providers will no longer be able to edit answers or submit the
          survey.
        </p>
      );
    default:
      return <p>If you reopen the survey, assigned providers will be able to edit answers and submit the survey.</p>;
  }
};

const SurveyStatusButton = ({
  surveyState,
  surveyId,
  timePeriodId,
  setError,
  setSurveyStatus,
}: SurveyStatusButtonProps): JSX.Element | null => {
  const [isLoading, setIsLoading] = useState(false);
  const { openSurvey, closeSurvey, reopenSurvey } = useManageSurveyState({
    surveyId,
    timePeriodId,
    setError,
    setSurveyStatus,
    setIsLoading,
  });

  const getButtonProps = (): ButtonProps | null => {
    switch (surveyState) {
      case SurveyState.NotOpenProviders:
        return {
          buttonText: 'Open survey',
          loadingText: 'Opening...',
          onClick: openSurvey,
        };
      case SurveyState.Open:
        return {
          buttonText: 'Close survey',
          loadingText: 'Closing...',
          onClick: closeSurvey,
        };
      case SurveyState.Closed:
        return {
          buttonText: 'Reopen survey',
          loadingText: 'Reopening...',
          onClick: reopenSurvey,
        };
      default:
        return null;
    }
  };

  const buttonProps = getButtonProps();

  return (
    buttonProps &&
    (!isLoading ? (
      <PrimaryButton onClick={buttonProps.onClick} className="me-3">
        {buttonProps.buttonText}
      </PrimaryButton>
    ) : (
      <LoadingButton message={buttonProps.loadingText} />
    ))
  );
};

export const SurveyManagement = ({
  survey,
  setError,
  surveyStatus,
  setSurveyStatus,
}: SurveyManagementProps): JSX.Element => {
  const { isSurveyInstanceConfigured, isAssigned, surveyId, timePeriodId } = survey;

  const getSurveyState = (): SurveyState => {
    if (!isSurveyInstanceConfigured) {
      return SurveyState.NotConfigured;
    }
    if (isSurveyInstanceConfigured && !isAssigned) {
      return SurveyState.ConfiguredNoProviders;
    }
    if (surveyStatus === SurveyStatus.InDevelopment && isAssigned) {
      return SurveyState.NotOpenProviders;
    }
    if (surveyStatus === SurveyStatus.Open) {
      return SurveyState.Open;
    }
    return SurveyState.Closed;
  };

  return (
    <div className="surveyViewSection">
      <h2>Manage survey</h2>
      <div className="pt-3">
        <div className="mb-3">
          <SurveyManagementText surveyState={getSurveyState()} />
        </div>
        <SurveyStatusButton
          surveyState={getSurveyState()}
          surveyId={surveyId!.toString()}
          timePeriodId={timePeriodId!.toString()}
          setError={setError}
          setSurveyStatus={setSurveyStatus}
        />
        <LinkButton
          to={generatePath(surveyPages.SurveyConfigure.path, {
            surveyId: surveyId?.toString(),
            timePeriodId: timePeriodId?.toString(),
          })}
          variant={getSurveyState() === SurveyState.NotConfigured ? 'primary' : 'outline-primary'}
        >
          Configure survey
        </LinkButton>
      </div>
    </div>
  );
};
