import { SubmissionValidationIssues } from 'nrosh-common/Api/SubmissionsApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { SubmissionBreadcrumbs } from '@/Components/Breadcrumbs/SubmissionBreadcrumbs';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import { SubmissionsApi } from '@/Helpers/Apis';
import { surveyDisplayName } from '@/Helpers/SurveyHelper';
import useSubmissionDetail from '@/Hooks/useSubmissionDetail';
import { providerPages, submissionPages } from '@/Pages/Home/SitePages';
import { mapSubmissionValidationsToViewModels } from '@/Pages/Submissions/SubmissionHelpers';
import SubmissionValidationsView from '@/Pages/Submissions/SubmissionValidationsView';

const ValidationIssuesPage = ({ isAdmin = false }: { isAdmin?: boolean }): JSX.Element => {
  const { submissionId } = useParams();
  const [submissionValidations] = useEndpoint<SubmissionValidationIssues>(
    SubmissionsApi.validateSubmissionAndGetValidationIssues,
    submissionId,
  );
  const [data] = useSubmissionDetail(submissionId!);

  if (!submissionValidations || !data) {
    return <LoadingMessage />;
  }

  const failingValidationIssues = mapSubmissionValidationsToViewModels(submissionValidations).filter(
    (v) => !v.isPassing && !v.isApproved,
  );

  return (
    <Stack gap={5}>
      <SubmissionBreadcrumbs
        isRshUser={isAdmin}
        surveyDisplayName={surveyDisplayName(data.surveyName, data.timePeriodName)}
        providerName={data.provider.name}
        additionalRshCrumbs={[
          {
            name: 'Review validations',
            path: submissionPages.AdminValidationIssues.path,
          },
        ]}
        additionalProviderCrumbs={[
          {
            name: 'Review validations',
            path: providerPages.ValidationIssues.path,
          },
        ]}
      />
      <SubmissionValidationsView
        validations={failingValidationIssues}
        submissionData={data}
        onFailuresAlertText="There are hard validation issues that need to be resolved before you can continue."
        exportButtonText="Export"
        commentsAreReadonly
        isAdmin={isAdmin}
        canSubmit
      />
    </Stack>
  );
};

export default ValidationIssuesPage;
