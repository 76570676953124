import accountApi from 'nrosh-common/Api/AccountApi';
import ApiClient, { StandardErrorResponse } from 'nrosh-common/Api/ApiClient';
import auditLogsApi from 'nrosh-common/Api/AuditLogsApi';
import editableContentApi from 'nrosh-common/Api/EditableContentApi';
import emailsApi from 'nrosh-common/Api/EmailsApi';
import faqsApi from 'nrosh-common/Api/FaqsApi';
import myProviderApi from 'nrosh-common/Api/MyProviderApi';
import newsApi from 'nrosh-common/Api/NewsApi';
import providersApi from 'nrosh-common/Api/ProvidersApi';
import publicDocumentsApi from 'nrosh-common/Api/PublicDocumentsApi';
import publicPagesApi from 'nrosh-common/Api/PublicPagesApi';
import regulatoryDocumentsApi from 'nrosh-common/Api/RegulatoryDocumentsApi';
import submissionsApi from 'nrosh-common/Api/SubmissionsApi';
import surveyPartsApi from 'nrosh-common/Api/SurveyPartsApi';
import surveysApi from 'nrosh-common/Api/SurveysApi';
import tagsApi from 'nrosh-common/Api/TagsApi';
import unauthenticatedFaqsApi from 'nrosh-common/Api/UnauthenticatedFaqsApi';
import unauthenticatedNewsApi from 'nrosh-common/Api/UnauthenticatedNewsApi';
import unauthenticatedPublicDocumentsApi from 'nrosh-common/Api/UnauthenticatedPublicDocumentsApi';
import usersApi from 'nrosh-common/Api/UsersApi';
import { webApiBaseUrl } from '@/Config';

/**
 * Placeholder function introduced in DCS-379 to allow for location of yet unhandled errors.
 * Will be removed in DCS-378
 * @param err API error
 */
export const placeHolderAPIErrorHandler = async (err: unknown): Promise<void> => {
  console.error('API error', err);
};

export const generateResponseErrorMessage = <T>(
  { message }: StandardErrorResponse<T>,
  detailProcess: (detail: T) => string | undefined | null,
  fallback: string = 'Unknown error',
): string => {
  if (typeof message !== 'string') {
    return detailProcess(message) || fallback;
  }
  return message || fallback;
};

export const apiClient = new ApiClient(webApiBaseUrl);
export const AccountApi = accountApi(apiClient);
export const SurveysApi = surveysApi(apiClient);
export const SurveyPartsApi = surveyPartsApi(apiClient);
export const MyProviderApi = myProviderApi(apiClient);
export const ProvidersApi = providersApi(apiClient);
export const SubmissionsApi = submissionsApi(apiClient);
export const UsersApi = usersApi(apiClient);
export const NewsApi = newsApi(apiClient);
export const UnauthenticatedNewsApi = unauthenticatedNewsApi(apiClient);
export const AuditLogsApi = auditLogsApi(apiClient);
export const FaqsApi = faqsApi(apiClient);
export const UnauthenticatedFaqsApi = unauthenticatedFaqsApi(apiClient);
export const EmailsApi = emailsApi(apiClient);
export const EditableContentApi = editableContentApi(apiClient);
export const PublicDocumentsApi = publicDocumentsApi(apiClient);
export const PublicPagesApi = publicPagesApi(apiClient);
export const RegulatoryDocumentsApi = regulatoryDocumentsApi(apiClient);
export const TagsApi = tagsApi(apiClient);
export const UnauthenticatedPublicDocumentsApi = unauthenticatedPublicDocumentsApi(apiClient);
