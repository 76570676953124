import { Checkbox, FormControlLabel } from '@mui/material';
import { ProviderSummary } from 'nrosh-common/Api/ProvidersApi';
import React, { ChangeEvent } from 'react';
import { CellProps, Column } from 'react-table';
import { useTablePageResetOverride } from '@/Components/Table/PageSkipUtilities';
import Table, { CheckboxValues } from '@/Components/Table/Table';
import { providerSizeNames, providerTypeNames, reportingStatusNames } from '@/Pages/Providers/ProviderEnums';

export type ProviderTableRow = ProviderSummary & { isSelected: boolean };

type Props = {
  providerList: ProviderTableRow[];
  onUpdateProviders: (newData: ProviderTableRow[]) => void;
  setDirty: () => void;
};

const tableRowToExportRow = (
  provider: ProviderTableRow,
): {
  'Provider Size': string;
  'Provider Name': string;
  'Provider Reporting Status': string;
  'Provider Number': string;
  'Is Excluded': string;
  'Provider Type': string;
} => ({
  'Provider Number': provider.providerNumber,
  'Provider Name': provider.name,
  'Provider Type': providerTypeNames[provider.type],
  'Provider Size': providerSizeNames[provider.size],
  'Provider Reporting Status': reportingStatusNames[provider.reportingStatus],
  'Is Excluded': provider.isSelected ? 'Yes' : 'No',
});

export const ExcludedProvidersTable = ({ providerList, onUpdateProviders, setDirty }: Props): JSX.Element => {
  const [skipPageResetRef, , disableReset] = useTablePageResetOverride();
  const calculateAllChecked = (): CheckboxValues => {
    if (providerList.length === 0) {
      return CheckboxValues.Unchecked;
    }
    if (providerList.every((provider) => provider.isSelected)) {
      return CheckboxValues.Checked;
    }
    if (providerList.some((provider) => provider.isSelected)) {
      return CheckboxValues.Indeterminate;
    }
    return CheckboxValues.Unchecked;
  };

  const selectAllChangeHandler = (isChecked: boolean): void => {
    disableReset();
    setDirty();
    onUpdateProviders(providerList.map((p) => ({ ...p, isSelected: isChecked })));
  };

  const selectionChangeHandler = (isChecked: boolean, providerId: number): void => {
    disableReset();
    setDirty();
    onUpdateProviders(
      providerList.map((p) =>
        p.id === providerId
          ? {
              ...p,
              isSelected: isChecked,
            }
          : p,
      ),
    );
  };

  const renderHeader = (): JSX.Element => {
    const onClick = (e: ChangeEvent<HTMLInputElement>): void => {
      e.preventDefault();
      selectAllChangeHandler(e.target.checked);
    };

    const allChecked = calculateAllChecked();

    return (
      <div className="rpNumberContainer">
        <FormControlLabel
          label=""
          style={{ padding: 0, margin: 0 }}
          control={
            <Checkbox
              checked={allChecked === CheckboxValues.Checked}
              indeterminate={allChecked === CheckboxValues.Indeterminate}
              disableRipple
              color="nrosh"
              onChange={onClick}
            />
          }
        />
        <div className="rpNumber">Provider Number</div>
      </div>
    );
  };

  const renderCell = ({ value, row }: CellProps<ProviderTableRow, boolean>): JSX.Element => (
    <div className="rpNumberContainer">
      <Checkbox
        inputProps={{ 'aria-label': `Mark provider ${row.values.name} as excluded` }}
        checked={value}
        disableRipple
        color="nrosh"
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          selectionChangeHandler(event.target.checked, row.original.id)
        }
      />
      <div className="rpNumber">{row.values.providerNumber}</div>
    </div>
  );

  const headers: Column<ProviderTableRow>[] = [
    {
      accessor: 'isSelected',
      Header: renderHeader,
      Cell: renderCell,
    },
    {
      Header: 'Provider Number',
      accessor: 'providerNumber',
    },
    {
      Header: 'Provider Name',
      accessor: 'name',
    },
  ];

  return (
    <Table
      data={providerList}
      columns={headers}
      searchable
      paginated
      hiddenColumns={['providerNumber']}
      className="w-100"
      exportable
      exportFileName="Excluded Providers.csv"
      tableRowToExportRow={tableRowToExportRow}
      skipPageReset={skipPageResetRef.current}
      disableSkipPageReset={disableReset}
      rowHeadingIndex={1}
    />
  );
};
