import { saveAs } from 'file-saver';
import { GetPublicDocumentResponse, PublicDocument } from 'nrosh-common/Api/PublicDocumentsApi';
import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { ReactNode, useMemo, useRef } from 'react';
import { IconContext } from 'react-icons';
import { BiAddToQueue } from 'react-icons/bi';
import { Link, generatePath } from 'react-router-dom';
import { CellProps, ColumnInstance, Row } from 'react-table';
import { useErrorReporting } from '@/Components/Errors/DCSErrorBoundary';
import FileDownloadIconButton from '@/Components/FileDownload/FileDownloadIconButton';
import Delete from '@/Components/Icons/Delete';
import Edit from '@/Components/Icons/Edit';
import LinkButton from '@/Components/Links/LinkButton';
import { ConfirmModalProps, useModal } from '@/Components/Modal/ModalProvider';
import EditableCell from '@/Components/Table/EditableCell';
import Table, { NroshColumn } from '@/Components/Table/Table';
import { PublicDocumentsApi } from '@/Helpers/Apis';
import { formatISODateString } from '@/Helpers/DateHelpers';
import { formatBytes } from '@/Helpers/File';
import { caseInsensitiveSort } from '@/Helpers/TableHelper';
import { adminPages } from '@/Pages/Home/SitePages';

const deleteWarning = (
  <div>
    <p>Are you sure you want to delete this document?</p>
    <p>
      <strong> This will be permanent.</strong>
    </p>
  </div>
);

type PublicDocumentsTableProps = {
  documentsResponse: GetPublicDocumentResponse;
  setDirty: () => void;
  setDocumentsResponse: ReactStateSetter<GetPublicDocumentResponse | null>;
  setDocumentsLastUpdatedAt: ReactStateSetter<Date | null>;
};

const tableRowToExportRow = (
  document: PublicDocument,
): {
  'Priority': number | undefined;
  'Title': string;
  'File Size': string;
  'File Type': string;
  'Date Published': string;
  'Tags': string;
} => ({
  'Priority': document.priority,
  'Title': document.title,
  'File Size': formatBytes(document.fileSizeBytes),
  'File Type': document.fileExtension,
  'Date Published': formatISODateString(document.uploadDate),
  'Tags': document.tags.map((t) => t.name).join(', '),
});

const PriorityCell = (
  value: number,
  row: Row<PublicDocument & { validated: boolean }>,
  column: ColumnInstance<PublicDocument & { validated: boolean }>,
  setDirty: () => void,
  updateData: (rowIndex: number, columnId: string, value: string) => void,
): JSX.Element => {
  const { id, original } = row;
  return (
    <EditableCell
      value={value !== null ? value : ''}
      row={row}
      column={column}
      updateData={updateData}
      setDirty={setDirty}
      ariaLabel="Priority number"
      invalidValueFeedback="Please enter a number"
      inputType="number"
      required={false}
      validated={original.validated}
      feedbackId={`priority-feedback-${id}`}
    />
  );
};

const EditCell = (row: Row<PublicDocument>): JSX.Element => {
  const { original } = row;
  return (
    <Link
      to={generatePath(adminPages.AdminEditPublicDocument.path, {
        documentId: original.id.toString(),
      })}
    >
      <Edit />
    </Link>
  );
};

const ExportCell = (row: Row<PublicDocument>): JSX.Element => {
  const { original } = row;
  const [raiseError] = useErrorReporting();
  const triggerDocumentDownload = async (documentId: string, fileName: string): Promise<void> => {
    // TODO-198: Replace this with a more best-practices compliant version (use <a href="..." download/> instead of file-saver)
    try {
      const document = await PublicDocumentsApi.download(documentId.toString());
      saveAs(URL.createObjectURL(document), fileName);
    } catch {
      raiseError();
    }
  };

  return (
    <FileDownloadIconButton onDownload={() => triggerDocumentDownload(original.id.toString(), original.fileName)} />
  );
};

const DeleteCell = (
  row: Row<PublicDocument>,
  setDocumentsResponse: ReactStateSetter<GetPublicDocumentResponse | null>,
  setDocumentsLastUpdatedAt: ReactStateSetter<Date | null>,
  confirm: (body: ReactNode, props?: ConfirmModalProps | undefined) => Promise<boolean>,
): JSX.Element => {
  const { original } = row;
  const [raiseError, clearError] = useErrorReporting();
  const onDelete = async (documentId: number): Promise<void> => {
    if (!(await confirm(deleteWarning))) {
      return;
    }

    const response = await PublicDocumentsApi.delete(documentId.toString()).raw;

    if (response.ok) {
      clearError();
      setDocumentsResponse((newDocumentsResponse) => ({
        documents: newDocumentsResponse!.documents.filter((d) => d.id !== documentId),
      }));
      setDocumentsLastUpdatedAt(new Date());
    } else {
      raiseError(response.value.message);
    }
  };

  return (
    <button className="iconButton" type="button" aria-label="Delete" onClick={() => onDelete(original.id)}>
      <Delete />
    </button>
  );
};

const ManagePublicDocumentsTable = ({
  documentsResponse,
  setDirty,
  setDocumentsResponse,
  setDocumentsLastUpdatedAt,
}: PublicDocumentsTableProps): JSX.Element => {
  const { confirm } = useModal();

  // It's important to call useMemo here - otherwise, whenever the export button is clicked all of the cells will
  // be unmounted and recreated, and an unmounted component warning appears in the console.
  const headers: NroshColumn<PublicDocument>[] = useMemo(() => {
    const updateData = (rowIndex: number, columnId: string, value: string): void => {
      setDocumentsResponse((prevData) => {
        const newData = { documents: [...prevData!.documents] };
        newData.documents[rowIndex] = {
          ...prevData!.documents[rowIndex],
          [columnId]: value !== '' ? value : null,
          updatedPriority: true,
        };

        return newData;
      });
    };

    return [
      {
        Header: 'Priority',
        accessor: 'priority',
        Cell: ({ value, row, column }: CellProps<PublicDocument & { validated: boolean }, number>) =>
          PriorityCell(value, row, column, setDirty, updateData),
        disableSortBy: true,
      },
      {
        Header: 'Title',
        accessor: 'title',
        sortType: caseInsensitiveSort,
        minWidth: 325,
      },
      {
        Header: 'File Size',
        accessor: (row) => formatBytes(row.fileSizeBytes),
      },
      {
        Header: 'File Type',
        accessor: 'fileExtension',
      },
      {
        Header: 'Date Published',
        align: 'center',
        accessor: 'uploadDate',
        Cell: ({ row }: CellProps<PublicDocument>) => formatISODateString(row.original.uploadDate),
      },
      {
        Header: 'Tags',
        disableSortBy: true,
        Cell: ({ row }: CellProps<PublicDocument>) => row.original.tags.map((t) => t.name).join(', '),
      },
      {
        Header: 'Edit',
        align: 'center',
        disableSortBy: true,
        maxWidth: 75,
        Cell: ({ row }: CellProps<PublicDocument>) => EditCell(row),
      },
      {
        Header: 'Export',
        align: 'center',
        disableSortBy: true,
        maxWidth: 75,
        Cell: ({ row }: CellProps<PublicDocument>) => ExportCell(row),
      },
      {
        Header: 'Delete',
        align: 'center',
        disableSortBy: true,
        maxWidth: 75,
        Cell: ({ row }: CellProps<PublicDocument>) =>
          DeleteCell(row, setDocumentsResponse, setDocumentsLastUpdatedAt, confirm),
      },
    ];
  }, [confirm, setDirty, setDocumentsLastUpdatedAt, setDocumentsResponse]);

  const { current: iconContextValue } = useRef({ size: '1.25rem', color: 'white' });

  return (
    <>
      <LinkButton to={generatePath(adminPages.AdminUploadPublicDocument.path)} className="mb-3">
        <div className="d-flex align-items-center justify-content-center gap-2">
          Upload New Document
          <IconContext.Provider value={iconContextValue}>
            <BiAddToQueue />
          </IconContext.Provider>
        </div>
      </LinkButton>
      <Table
        data={documentsResponse.documents}
        columns={headers}
        paginated
        exportable
        exportFileName="Public Documents Metadata.csv"
        tableRowToExportRow={tableRowToExportRow}
        rowHeadingIndex={1}
        autoResetPage={false}
      />
    </>
  );
};

export default ManagePublicDocumentsTable;
