import { ValidationType } from 'nrosh-common/Api/Enums';
import { SurveyCrossPartValidation } from 'nrosh-common/Api/SurveysApi';
import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { FormEvent, ReactNode, useMemo, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { generatePath, useParams } from 'react-router-dom';
import { CellProps, Row as ReactTableRow } from 'react-table';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import Delete from '@/Components/Icons/Delete';
import LinkButton from '@/Components/Links/LinkButton';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { ConfirmModalProps, useModal } from '@/Components/Modal/ModalProvider';
import EditableCell from '@/Components/Table/EditableCell';
import Table, { NroshColumn } from '@/Components/Table/Table';
import { SurveysApi } from '@/Helpers/Apis';
import { addValidatedPropertyToArray } from '@/Helpers/Forms';
import useUnsavedChangesWarning from '@/Hooks/useUnsavedChangesWarning';
import { surveyPages } from '@/Pages/Home/SitePages';

type SurveyCrossPartValidationsTableProps = {
  initialData: SurveyCrossPartValidation[];
  isSurveyClosed: boolean;
  surveyInstanceName: string;
};

type SurveyCrossPartValidationRow = SurveyCrossPartValidation & { validated: boolean };

type SurveyCrossPartValidationTableRow = {
  'ID': string;
  'Type': ValidationType;
  'Formula': string;
  'Short Text': string;
  'Description': string;
};

const buildHeaders = (
  setValidations: ReactStateSetter<SurveyCrossPartValidation[]>,
  setDirty: () => void,
  isSurveyClosed: boolean,
  confirm: (body: ReactNode, props?: ConfirmModalProps) => Promise<boolean>,
): NroshColumn<SurveyCrossPartValidationRow>[] => {
  const updateData = (rowIndex: number, columnId: string, value: string): void => {
    setValidations((prevData) => {
      const newData = [...prevData];
      newData[rowIndex] = {
        ...prevData[rowIndex],
        [columnId]: value,
      };
      return newData;
    });
  };

  const onDelete = async (row: ReactTableRow<SurveyCrossPartValidationRow>): Promise<void> => {
    const warningMessage = 'Are you sure you want to delete this cross-part validation?';
    if (!(await confirm(warningMessage))) {
      return;
    }

    setValidations((prevData) => prevData.filter((_, index) => index !== row.index));
    setDirty();
  };

  return [
    {
      Header: 'ID',
      accessor: 'validationId',
      Cell: ({ value, row, column }: CellProps<SurveyCrossPartValidationRow, string>) => (
        <EditableCell
          value={value}
          row={row}
          column={column}
          updateData={updateData}
          setDirty={setDirty}
          ariaLabel="id"
          readOnly={isSurveyClosed}
          feedbackId={`validation-id-feedback-${row.id}`}
          validated={row.original.validated}
          invalidValueFeedback="Please provide a validation ID"
        />
      ),
    },
    {
      Header: 'Type',
      accessor: 'type',
      Cell: ({ value, row: { index } }: CellProps<SurveyCrossPartValidationRow, string>) => (
        <Form.Select
          aria-label="type-dropdown"
          value={value}
          onChange={(e) => {
            setDirty();
            updateData(index, 'type', e.target.value);
          }}
          disabled={isSurveyClosed}
        >
          <option value={ValidationType.Hard}>{ValidationType.Hard}</option>
          <option value={ValidationType.Soft}>{ValidationType.Soft}</option>
        </Form.Select>
      ),
    },
    {
      Header: 'Formula',
      accessor: 'validationFormula',
      minWidth: 200,
      Cell: ({ value, row, column }: CellProps<SurveyCrossPartValidationRow, string>) => (
        <EditableCell
          value={value}
          row={row}
          column={column}
          updateData={updateData}
          setDirty={setDirty}
          ariaLabel="formula"
          readOnly={isSurveyClosed}
          feedbackId={`formula-feedback-${row.id}`}
          validated={row.original.validated}
          invalidValueFeedback="Please provide a formula"
        />
      ),
    },
    {
      Header: 'Short Text',
      accessor: 'shortText',
      Cell: ({ value, row, column }: CellProps<SurveyCrossPartValidationRow, string>) => (
        <EditableCell
          value={value}
          row={row}
          column={column}
          updateData={updateData}
          setDirty={setDirty}
          ariaLabel="short-text"
          readOnly={isSurveyClosed}
          feedbackId={`short-text-feedback-${row.id}`}
          validated={row.original.validated}
          invalidValueFeedback="Please provide short text"
        />
      ),
    },
    {
      Header: 'Description',
      accessor: 'description',
      minWidth: 200,
      Cell: ({ value, row, column }: CellProps<SurveyCrossPartValidationRow, string>) => (
        <EditableCell
          value={value}
          row={row}
          column={column}
          updateData={updateData}
          setDirty={setDirty}
          ariaLabel="description"
          readOnly={isSurveyClosed}
          feedbackId={`description-feedback-${row.id}`}
          validated={row.original.validated}
          invalidValueFeedback="Please provide a description"
        />
      ),
    },
    ...(!isSurveyClosed
      ? [
          {
            Header: 'Delete',
            align: 'center' as const,
            maxWidth: 100,
            disableSortBy: true,
            disableResizing: true,
            Cell: ({ row }: CellProps<SurveyCrossPartValidationRow>) => (
              <button className="iconButton" type="button" aria-label="Delete" onClick={() => onDelete(row)}>
                <Delete />
              </button>
            ),
          },
        ]
      : []),
  ];
};

const tableRowToExportRow = (validation: SurveyCrossPartValidationRow): SurveyCrossPartValidationTableRow => ({
  'ID': validation.validationId,
  'Type': validation.type,
  'Formula': ` ${validation.validationFormula}`,
  'Short Text': validation.shortText,
  'Description': validation.description,
});

const SurveyCrossPartValidationsTable = ({
  initialData,
  isSurveyClosed,
  surveyInstanceName,
}: SurveyCrossPartValidationsTableProps): JSX.Element => {
  const { surveyId, timePeriodId } = useParams();

  const [validations, setValidations] = useState(initialData);
  const [isSaving, setSaving] = useState(false);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [setDirty, setPristine, isDirty] = useUnsavedChangesWarning();
  const { confirm } = useModal();

  // It's important to call useMemo here - otherwise, whenever the columns are recalculated all of the cells will
  // be unmounted and recreated. This means that the editable cell component will lose focus on each keystroke.
  const headers = useMemo(
    () => buildHeaders(setValidations, setDirty, isSurveyClosed, confirm),
    [setValidations, setDirty, isSurveyClosed, confirm],
  );

  const tableData = useMemo(() => addValidatedPropertyToArray(validations, validated), [validations, validated]);

  const addNewRow = (): void => {
    const newRow: SurveyCrossPartValidation = {
      validationId: '',
      validationFormula: '',
      description: '',
      type: ValidationType.Hard,
      shortText: '',
    };

    setValidations((prevData) => [...prevData, newRow]);
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    const validationIds = validations.map((v) => v.validationId);
    if (new Set(validationIds).size !== validationIds.length) {
      setError('Cross-part validation IDs must be unique');
      return;
    }

    if (!event.currentTarget.checkValidity()) {
      event.stopPropagation();
      setValidated(true);
    } else {
      setSaving(true);
      const response = await SurveysApi.saveCrossPartValidations(surveyId!, timePeriodId!, { validations }).raw;

      if (response.ok) {
        setPristine();
        setValidated(false);
        setError(null);
      } else {
        const { message } = response.value;
        setError(message);
      }

      setSaving(false);
    }
  };

  const editSurveyPath = generatePath(surveyPages.SurveyEdit.path, { surveyId, timePeriodId });

  return (
    <Form onSubmit={onSubmit} noValidate>
      {error && (
        <Alert variant="danger" dismissible onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
      <Table
        data={tableData}
        columns={headers}
        message={
          isSurveyClosed
            ? 'There are no cross-part validations for this survey'
            : "To add a cross-part validation click 'Add New Validation'."
        }
        exportable
        exportFileName={`${surveyInstanceName} - Cross Part Validations.csv`}
        tableRowToExportRow={tableRowToExportRow}
      />
      {!isSurveyClosed && (
        <div className="d-flex gap-3 mt-3">
          <LinkButton to={editSurveyPath} variant="outline-primary" disabled={isSaving}>
            Cancel
          </LinkButton>
          <PrimaryButton type="button" onClick={addNewRow} disabled={isSaving}>
            Add New Validation
          </PrimaryButton>
          {!isSaving ? (
            <PrimaryButton type="submit" disabled={!isDirty}>
              Save
            </PrimaryButton>
          ) : (
            <LoadingButton message="Saving..." />
          )}
        </div>
      )}
    </Form>
  );
};

export default SurveyCrossPartValidationsTable;
