import { ProviderRole, RshRole } from 'nrosh-common/Api/Enums';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import { useContext } from 'react';
import { Params, generatePath, useParams } from 'react-router-dom';
import { Breadcrumb } from '@/Components/Breadcrumbs/Breadcrumb';
import { adminPages, providerPages, publicPages, surveyPages } from '@/Pages/Home/SitePages';

export enum RootPathType {
  AdminSurveys = 'AdminSurveys',
  ProviderSurveys = 'ProviderSurveys',
  AdminUsers = 'AdminUsers',
  ProviderUsers = 'ProviderUsers',
  AdminProviders = 'AdminProviders',
  PublicNews = 'PublicNews',
  MyProvider = 'MyProvider',
  PublicFaqs = 'PublicFaqs',
  AdminRegulatoryDocuments = 'AdminRegulatoryDocuments',
  ProviderRegulatoryDocuments = 'ProviderRegulatoryDocuments',
  PublicDocuments = 'PublicDocuments',
  AdminContent = 'AdminContent',
  AdminAudits = 'AdminAudits',
  AdminEmails = 'AdminEmails',
}

// TODO-381: Remove state from Crumb
export type Crumb = { name: string; path: string };

type Props = {
  crumbs?: Crumb[];
  type: RootPathType;
};

const getCrumbs = (params: Params<string>, crumbs: Crumb[]): Crumb[] =>
  crumbs.map((crumb) => ({
    name: crumb.name,
    path: generatePath(crumb.path, params),
  }));

export const Breadcrumbs = (props: Props): JSX.Element => {
  const { crumbs, type: rootPathType } = props;
  const params = useParams() as { params: string };
  const auth = useContext(AuthContext);

  const mappedCrumbs = getCrumbs(params, crumbs ?? []);

  switch (rootPathType) {
    case RootPathType.AdminSurveys:
      mappedCrumbs.unshift({ name: 'All surveys', path: surveyPages.Surveys.path });
      break;
    case RootPathType.ProviderSurveys:
      mappedCrumbs.unshift({ name: 'My surveys', path: providerPages.SubmissionsList.path });
      break;
    case RootPathType.AdminUsers:
      mappedCrumbs.unshift({
        name: auth.hasRole(RshRole.EditUsers) ? 'User management' : 'Users',
        path: adminPages.AdminUsers.path,
      });
      break;
    case RootPathType.ProviderUsers:
      mappedCrumbs.unshift({
        name: auth.hasRole(ProviderRole.EditUsers) ? 'User management' : 'Users',
        path: providerPages.UserManagement.path,
      });
      break;
    case RootPathType.AdminProviders:
      mappedCrumbs.unshift({
        name: 'Providers',
        path: adminPages.AdminProviders.path,
      });
      break;
    case RootPathType.PublicNews:
      mappedCrumbs.unshift({ name: 'News', path: publicPages.PublicNews.path });
      break;
    case RootPathType.MyProvider:
      mappedCrumbs.unshift({ name: 'My provider', path: providerPages.ProviderDetails.path });
      break;
    case RootPathType.PublicFaqs:
      mappedCrumbs.unshift({ name: 'FAQs', path: publicPages.PublicFaqs.path });
      break;
    case RootPathType.AdminContent:
      mappedCrumbs.unshift({ name: 'Content management', path: adminPages.AdminContent.path });
      break;
    case RootPathType.AdminRegulatoryDocuments:
      mappedCrumbs.unshift({ name: 'Regulatory documents', path: adminPages.RegulatoryDocuments.path });
      break;
    case RootPathType.ProviderRegulatoryDocuments:
      mappedCrumbs.unshift({ name: 'Regulatory documents', path: providerPages.RegulatoryDocuments.path });
      break;
    case RootPathType.PublicDocuments:
      mappedCrumbs.unshift({ name: 'Documents', path: publicPages.PublicDocuments.path });
      break;
    case RootPathType.AdminAudits:
      mappedCrumbs.unshift({ name: 'Audits', path: adminPages.AdminAudits.path });
      break;
    case RootPathType.AdminEmails:
      mappedCrumbs.unshift({ name: 'Emails', path: adminPages.AdminEmails.path });
      break;
    default:
  }

  return (
    <div className="d-flex pt-2 flex-wrap">
      {mappedCrumbs.map((crumb, index) => (
        <Breadcrumb key={crumb.name} isLastCrumb={index === mappedCrumbs.length - 1} crumb={crumb} />
      ))}
    </div>
  );
};
