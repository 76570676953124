import { Article } from 'nrosh-common/Api/NewsApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { useRef, useState } from 'react';
import { Stack } from 'react-bootstrap';
import { IconContext } from 'react-icons';
import { BiAddToQueue } from 'react-icons/bi';
import { Link, generatePath } from 'react-router-dom';
import { CellProps, Row } from 'react-table';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import Delete from '@/Components/Icons/Delete';
import Edit from '@/Components/Icons/Edit';
import LinkButton from '@/Components/Links/LinkButton';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import { LoadingSpinner } from '@/Components/Loading/LoadingSpinner';
import { useModal } from '@/Components/Modal/ModalProvider';
import PageHeader from '@/Components/PageHeader/PageHeader';
import Table, { NroshColumn } from '@/Components/Table/Table';
import { NewsApi, placeHolderAPIErrorHandler } from '@/Helpers/Apis';
import { formatISODateString } from '@/Helpers/DateHelpers';
import { caseInsensitiveSort } from '@/Helpers/TableHelper';
import { PublishStatus, checkItemCanBeDeleted, getPublishStatus } from '@/Pages/Content/ContentHelpers';
import { adminPages } from '@/Pages/Home/SitePages';
import '@/Pages/Content/Content.scss';

const tableRowToExportRow = (
  article: Article,
): {
  'Title': string;
  'Tags': string;
  'Publish Date': string;
  'Status': PublishStatus;
  'Content': string;
} => ({
  'Title': article.name,
  'Tags': article.tags.map((t) => t.name).join(', '),
  'Publish Date': formatISODateString(article.publishDateTime),
  'Status': getPublishStatus(article.publishDateTime),
  'Content': article.content,
});

const ManageNewsPage = (): JSX.Element => {
  const [articleDeletedAt, setArticleDeletedAt] = useState<Date | null>(null);
  const [articles] = useEndpoint<Article[]>(NewsApi.getNewsArticles, articleDeletedAt);
  const [deleteinProgress, setDeleteInProgress] = useState<Set<string>>(new Set());
  const { confirm } = useModal();

  const deleteNewsArticle = async (articleId: string, publishDateTime: string): Promise<void> => {
    if (!(await checkItemCanBeDeleted(publishDateTime, 'Article', confirm))) {
      return;
    }
    setDeleteInProgress((prevDeleteInProgress) => new Set(prevDeleteInProgress).add(articleId));
    await NewsApi.deleteNewsArticle(articleId).justErrors(placeHolderAPIErrorHandler);
    setDeleteInProgress((prevDeleteInProgress) => {
      const newSet = new Set(prevDeleteInProgress);
      newSet.delete(articleId);
      return newSet;
    });
    setArticleDeletedAt(new Date());
  };

  const editCell = (value: string): JSX.Element => (
    <Link
      className="d-flex align-items-center editLink justify-content-center"
      to={generatePath(adminPages.AdminNewsEdit.path, { articleId: value })}
    >
      <Edit />
    </Link>
  );

  const deleteCell = (row: Row<Article>): JSX.Element =>
    deleteinProgress.has(row.original.id) ? (
      <div className="spinner-container">
        <LoadingSpinner smallSpinner />
      </div>
    ) : (
      <button
        aria-label="Delete"
        className="iconButton"
        type="button"
        onClick={async () => {
          await deleteNewsArticle(row.original.id, row.original.publishDateTime);
        }}
      >
        <Delete />
      </button>
    );

  const headers: NroshColumn<Article>[] = [
    {
      Header: 'Title',
      accessor: 'name',
      sortType: caseInsensitiveSort,
    },
    {
      Header: 'Tags',
      width: 100,
      accessor: (row) => row.tags.map((t) => t.name).join(', '),
    },
    {
      Header: 'Publish Date',
      accessor: 'publishDateTime',
      align: 'center',
      width: 100,
      Cell: ({ value }) => formatISODateString(value),
    },
    {
      Header: 'Status',
      width: 100,
      accessor: (row: Article) => getPublishStatus(row.publishDateTime),
      Cell: ({ row }: CellProps<Article>) => getPublishStatus(row.original.publishDateTime),
    },
    {
      Header: 'Edit',
      accessor: 'id',
      align: 'center',
      width: 100,
      disableSortBy: true,
      disableResizing: true,
      Cell: ({ value }) => editCell(value),
    },
    {
      Header: 'Delete',
      align: 'center',
      width: 100,
      disableSortBy: true,
      disableResizing: true,
      Cell: ({ row }: CellProps<Article>) => deleteCell(row),
    },
  ];

  const { current: iconContextValue } = useRef({ size: '1.25rem', color: 'white' });

  if (!articles) {
    return <LoadingMessage />;
  }

  return (
    <Stack gap={5}>
      <PageHeader
        heading="Manage news"
        crumbsType={RootPathType.AdminContent}
        crumbs={[
          {
            name: 'Manage news',
            path: adminPages.AdminNews.path,
          },
        ]}
      />
      <LinkButton to={adminPages.AdminNewsCreate.path} className="mb-3">
        <div className="d-flex align-items-center">
          Add New Article
          <div className="ms-3 d-flex align-items-center">
            <IconContext.Provider value={iconContextValue}>
              <BiAddToQueue />
            </IconContext.Provider>
          </div>
        </div>
      </LinkButton>
      <Table
        data={articles}
        columns={headers}
        paginated
        searchable
        exportable
        exportFileName="News Articles.csv"
        tableRowToExportRow={tableRowToExportRow}
        rowHeadingIndex={0}
      />
    </Stack>
  );
};

export default ManageNewsPage;
