import { RshRole } from 'nrosh-common/Api/Enums';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ErrorMessage } from '@/Components/ErrorMessage/ErrorMessage';
import { createContext } from '@/Helpers/Context';

const defaultErrorMessage = console.error;
type ErrorReporter = [(message?: string) => void, () => void];
type ErrorReportingContextType = {
  reportError: (msg?: string) => void;
  clearError: () => void;
  internalError: string | undefined | null;
  active: React.MutableRefObject<boolean>;
};
const ErrorReporting = createContext<ErrorReportingContextType>({
  reportError: (msg?: string) => defaultErrorMessage(new Error(msg)),
  clearError: () => {},
  internalError: null,
  active: { current: false },
});

export const useErrorReporting = (): ErrorReporter => {
  const { reportError, clearError } = useContext(ErrorReporting);
  useEffect(() => () => clearError(), [clearError]);
  return [reportError, clearError];
};

export const DCSErrorContextOutlet = (): JSX.Element | null => {
  const { active, clearError, internalError } = useContext(ErrorReporting);
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (active.current) {
      console.warn('It looks like there are currently 2 components acting as outlets for the error message.');
    }
    active.current = true;
    return () => {
      active.current = false;
    };
  }, [active]);

  return (
    <ErrorMessage
      isAdmin={auth.hasRole(RshRole.User)}
      error={{ message: internalError ?? null, status: internalError !== null }}
      clearError={clearError}
    />
  );
};

export const DCSErrorBoundary = ({ children }: { children: ReactNode }): JSX.Element => {
  const [error, setError] = useState<string | undefined | null>(null);
  const clearError = useCallback(() => setError(null), [setError]);
  const active = useRef(false);

  const reportError = useMemo(
    () =>
      (message?: string): void =>
        active.current ? setError(message) : defaultErrorMessage(new Error(message)),
    [active],
  );
  const result = useMemo<ErrorReportingContextType>(() => {
    const internalError = error;
    return { reportError, clearError, internalError, active };
  }, [reportError, clearError, error]);
  return <ErrorReporting.Provider value={result}>{children}</ErrorReporting.Provider>;
};
