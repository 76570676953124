import { ProviderRole, SubmissionStatus } from 'nrosh-common/Api/Enums';
import { Submission, SurveyStatus } from 'nrosh-common/Api/SubmissionsApi';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { useContext, useState } from 'react';
import { Form, ListGroup, Stack, Tab } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { SubmissionsApi } from '@/Helpers/Apis';
import { formatISODateString } from '@/Helpers/DateHelpers';
import { providerDisplayName } from '@/Helpers/ProviderHelper';
import { hasBeenSubmitted } from '@/Pages/Submissions/SubmissionHelpers';
import SubmissionsPageAlert from '@/Pages/Submissions/SubmissionsPageAlert';
import SubmissionsPageCard from '@/Pages/Submissions/SubmissionsPageCard';
import '@/Pages/Submissions/SubmissionsPage.scss';
import '@/Styles/BootstrapOverrides/ListGroup.scss';

export type SubmissionCard = {
  id: number;
  surveyName: string;
  timePeriodName: string;
  hard: number;
  soft: number;
  status: SubmissionStatus;
  daysRemaining: number;
  surveyStatus: SurveyStatus;
  submissionDeadline: string;
};

const openSurveyNavLink = '#openSurvey';
const closedSurveyNavLink = '#closedSurvey';

const getDaysRemaining = (submissionDate: Date): number => {
  const timeRemaining = submissionDate.getTime() - new Date().getTime();
  return Math.ceil(timeRemaining / (1000 * 3600 * 24));
};

const SubmissionsAlert = ({ submissions }: { submissions: SubmissionCard[] }): JSX.Element | null => {
  const submissionsDueInFiveDays = submissions.some(
    (submission) =>
      submission.daysRemaining <= 5 && submission.daysRemaining > 2 && !hasBeenSubmitted(submission.status),
  );
  const submissionsDueInTwoDays = submissions.some(
    (submission) =>
      submission.daysRemaining <= 2 && submission.daysRemaining >= 0 && !hasBeenSubmitted(submission.status),
  );
  const submissionsOverdue = submissions.some(
    (submission) => submission.daysRemaining < 0 && !hasBeenSubmitted(submission.status),
  );
  const deadlineApproaching = 'Survey submission deadline approaching.';
  const deadlinePassed = 'Survey submission deadline passed.';

  if (submissionsOverdue) {
    return <SubmissionsPageAlert message={deadlinePassed} className="redAlert" />;
  }
  if (submissionsDueInTwoDays) {
    return <SubmissionsPageAlert message={deadlineApproaching} className="redAlert" />;
  }
  if (submissionsDueInFiveDays) {
    return <SubmissionsPageAlert message={deadlineApproaching} className="orangeAlert" />;
  }

  return null;
};

const SubmissionsPage = (): JSX.Element => {
  const AllYears = 0;
  const auth = useContext(AuthContext);
  const [year, setYear] = useState<number>(AllYears);
  const location = useLocation();

  const [data] = useEndpoint<Submission[]>(SubmissionsApi.getSubmissions);
  if (!auth.hasRole(ProviderRole.ViewSurveyData)) {
    return <h1>Welcome to DCS</h1>;
  }

  if (!data || !auth.user) {
    return <LoadingMessage />;
  }

  const years = [
    ...new Set(data.filter((d) => d.surveyStatus === SurveyStatus.Closed).map((d) => d.surveyYear)),
  ].sort();

  const submissionData = data.map((submission) => ({
    id: submission.id,
    surveyName: submission.surveyName,
    timePeriodName: submission.timePeriodName,
    surveyYear: submission.surveyYear,
    hard: submission.hardValidationIssues,
    soft: submission.softValidationIssues,
    status: submission.status,
    daysRemaining: getDaysRemaining(new Date(submission.deadline)),
    surveyStatus: submission.surveyStatus,
    submissionDeadline: formatISODateString(submission.deadline),
  }));

  const activeSubmissionData = submissionData.filter((s) => s.surveyStatus !== SurveyStatus.Closed);
  const closedSubmissionData = submissionData.filter(
    (s) => s.surveyStatus === SurveyStatus.Closed && (year === s.surveyYear || year === AllYears),
  );

  return (
    <Stack gap={5}>
      <PageHeader
        heading="My surveys"
        subheading={`${providerDisplayName(auth.user.providerName!, auth.user.providerNumber!)}`}
        crumbsType={RootPathType.ProviderSurveys}
      />
      <p>
        This page shows the overall status of data entry, validation, submission and sign-off for each of the surveys
        which your provider has to complete, and the time remaining to complete it.
      </p>
      <Tab.Container defaultActiveKey={location.hash || openSurveyNavLink}>
        <ListGroup horizontal className="w-50">
          <ListGroup.Item bsPrefix="listGroupItem submissions" action href={openSurveyNavLink} as="button">
            Open Surveys
          </ListGroup.Item>
          <ListGroup.Item bsPrefix="listGroupItem submissions" action href={closedSurveyNavLink} as="button">
            Closed Surveys
          </ListGroup.Item>
        </ListGroup>
        <Tab.Content>
          <Tab.Pane eventKey={openSurveyNavLink}>
            <SubmissionsAlert submissions={activeSubmissionData} />
            <SubmissionsPageCard submissionData={activeSubmissionData} />
          </Tab.Pane>
          <Tab.Pane eventKey={closedSurveyNavLink}>
            <Form.Label htmlFor="year">Select year:</Form.Label>
            <Form.Select
              id="year"
              className="w-auto mb-3"
              value={year ?? ''}
              onChange={(e) => setYear(Number(e.target.value))}
            >
              <option key="none" value={AllYears}>
                All years
              </option>
              {years.map((yearOption) => (
                <option key={yearOption} value={yearOption}>
                  {yearOption}
                </option>
              ))}
            </Form.Select>
            <SubmissionsPageCard submissionData={closedSubmissionData} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Stack>
  );
};

export default SubmissionsPage;
