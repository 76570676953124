import { RshRole } from 'nrosh-common/Api/Enums';
import { SubmissionStatusDict, SurveyStatus } from 'nrosh-common/Api/SubmissionsApi';
import { SubmissionBySurveyId } from 'nrosh-common/Api/SurveysApi';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import { useContext } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import { TertiaryLinkButton } from '@/Components/Buttons/TertiaryLinkButton';
import Edit from '@/Components/Icons/Edit';
import LinkButton from '@/Components/Links/LinkButton';
import Table from '@/Components/Table/Table';
import { submissionPages, surveyPages } from '@/Pages/Home/SitePages';

type SurveyAssignedProvidersProps = {
  surveyId: string;
  timePeriodId: string;
  submissionsData: SubmissionBySurveyId[];
  surveyStatus: SurveyStatus;
  isSurveyInstanceConfigured: boolean;
};

type SurveyAssignedProvidersTableRow = {
  'Provider Number': string;
  'Provider Name': string;
  'Status': string;
};

const tableRowToExportRow = (submission: SubmissionBySurveyId): SurveyAssignedProvidersTableRow => ({
  'Provider Number': submission.providerNumber,
  'Provider Name': submission.providerName,
  'Status': SubmissionStatusDict[submission.status],
});

const SurveyAssignedProviders = ({
  surveyId,
  timePeriodId,
  submissionsData,
  surveyStatus,
  isSurveyInstanceConfigured,
}: SurveyAssignedProvidersProps): JSX.Element | null => {
  const auth = useContext(AuthContext);

  const providerNumberColumnCell = (value: number, rowValues: SubmissionBySurveyId): JSX.Element => (
    <Link
      to={generatePath(submissionPages.SubmissionView.path, {
        surveyId,
        submissionId: rowValues.submissionId.toString(),
        timePeriodId,
      })}
    >
      {value}
    </Link>
  );

  const headers: Column<SubmissionBySurveyId>[] = [
    {
      Header: 'Provider Number',
      accessor: 'providerNumber',
      Cell: ({ value, row: { original: rowValues } }: CellProps<SubmissionBySurveyId, number>) =>
        auth.hasRole(RshRole.AssignSurveys) ||
        (auth.hasRole(RshRole.ViewSurveyData) && auth.userCanAccessSurvey(Number(surveyId)))
          ? providerNumberColumnCell(value, rowValues)
          : value,
      width: 50,
    },
    {
      Header: 'Name',
      accessor: 'providerName',
      width: 150,
    },
    {
      Header: 'Status',
      accessor: (row: SubmissionBySurveyId) => SubmissionStatusDict[row.status],
    },
  ];

  if (submissionsData.length > 0) {
    return (
      <>
        <div className="d-flex flex-row gap-2 align-items-center mb-3">
          <h2>Assigned providers</h2>
          {surveyStatus !== SurveyStatus.Closed && (
            <TertiaryLinkButton
              icon={<Edit />}
              text="Edit assigned providers"
              to={generatePath(surveyPages.SurveyAssign.path, { surveyId, timePeriodId })}
            />
          )}
        </div>
        <p className="mb-2">Search providers</p>
        <Table
          data={submissionsData}
          columns={headers}
          searchable
          paginated
          placeholderSearchText=""
          exportable
          exportFileName="Assigned Providers.csv"
          tableRowToExportRow={tableRowToExportRow}
          exportButtonText="Download assigned providers"
          rowHeadingIndex={1}
        />
      </>
    );
  }

  if (auth.hasRole(RshRole.AssignSurveys)) {
    return (
      <div>
        <h2>Assigned Providers</h2>
        <div className="mt-3">
          {isSurveyInstanceConfigured ? (
            <>
              <p>You have not assigned any Providers to this survey.</p>
              <LinkButton
                to={generatePath(surveyPages.SurveyAssign.path, { surveyId, timePeriodId })}
                variant="primary"
              >
                Assign providers
              </LinkButton>
            </>
          ) : (
            <p>You must first fully configure the survey before you can assign Providers.</p>
          )}
        </div>
      </div>
    );
  }

  return null;
};

export default SurveyAssignedProviders;
