import saveAs from 'file-saver';
import { DetailedAuditChangeType, DetailedAuditTrailLog } from 'nrosh-common/Api/AuditLogsApi';
import { CellProps } from 'react-table';
import { useErrorReporting } from '@/Components/Errors/DCSErrorBoundary';
import FileDownloadIconButton from '@/Components/FileDownload/FileDownloadIconButton';
import Table, { NroshColumn } from '@/Components/Table/Table';
import { AuditLogsApi } from '@/Helpers/Apis';
import { TimeResolution, formatISODateString } from '@/Helpers/DateHelpers';
import { DetailedAuditChangeTypeMapping } from '@/Pages/AuditLogs/DetailedAuditTrailLogsPage';

type DetailedAuditTrailLogsTableProps = {
  data: DetailedAuditTrailLog[];
};

const DetailedAuditTrailLogsTable = (props: DetailedAuditTrailLogsTableProps): JSX.Element => {
  const { data } = props;
  const [reportError] = useErrorReporting();

  const triggerFileDownload = async (auditLogId: number, fileName: string): Promise<void> => {
    try {
      const auditLogFile = await AuditLogsApi.downloadDetailedAuditTrailLogFile(auditLogId);
      saveAs(URL.createObjectURL(auditLogFile), fileName);
    } catch {
      reportError();
    }
  };

  const generateAuditLogFileName = (auditLog: DetailedAuditTrailLog, fileName: string): string => {
    const fileExtension = fileName.split('.').pop();
    const descriptiveFileName = ''
      .concat(auditLog.surveyName ? `${auditLog.surveyName}_` : '')
      .concat(auditLog.surveyPartName ? `${auditLog.surveyPartName}_` : '')
      .concat(auditLog.timePeriodName ? `${auditLog.timePeriodName}_` : '')
      .concat(auditLog.dateOfChange ? new Date(auditLog.dateOfChange).toISOString() : '')
      .concat(`.${fileExtension}`);

    return descriptiveFileName.trim().replace(/\s+/g, '_');
  };

  const renderCell = (cellProps: CellProps<DetailedAuditTrailLog, string>): JSX.Element | string | number => {
    const { value, row } = cellProps;

    return row.original.changeType === DetailedAuditChangeType.FileUploaded && value !== null ? (
      <FileDownloadIconButton
        onDownload={() => triggerFileDownload(row.original.auditLogId, generateAuditLogFileName(row.original, value))}
      />
    ) : (
      value
    );
  };

  const headers: NroshColumn<DetailedAuditTrailLog>[] = [
    {
      Header: 'Date of Change',
      accessor: 'dateOfChange',
      Cell: ({ value }) => formatISODateString(value, TimeResolution.HundredthSecond),
      width: 200,
      disableSortBy: true,
    },
    {
      Header: 'Provider Number',
      accessor: 'providerNumber',
      width: 100,
      disableSortBy: true,
    },
    {
      Header: 'Provider Name',
      accessor: 'providerName',
      disableSortBy: true,
    },
    {
      Header: 'Survey',
      accessor: 'surveyName',
      disableSortBy: true,
    },
    {
      Header: 'Time Period',
      accessor: 'timePeriodName',
      disableSortBy: true,
    },
    {
      Header: 'Survey Part',
      accessor: 'surveyPartName',
      disableSortBy: true,
    },
    {
      Header: 'Change by User',
      accessor: 'changedByUserName',
      disableSortBy: true,
    },
    {
      Header: 'Change Type',
      accessor: 'changeType',
      Cell: ({ value }) => value && DetailedAuditChangeTypeMapping[value as DetailedAuditChangeType],
      width: 200,
      disableResizing: true,
      disableSortBy: true,
    },
    {
      Header: 'Field',
      accessor: 'field',
      disableSortBy: true,
    },
    {
      Header: 'Old Value',
      accessor: 'oldValue',
      disableSortBy: true,
    },
    {
      Header: 'New Value',
      accessor: 'newValue',
      disableSortBy: true,
      align: 'center',
      Cell: renderCell,
    },
    {
      Header: 'Dimension 1',
      accessor: 'dimension1',
      disableSortBy: true,
    },
    {
      Header: 'Dimension 2',
      accessor: 'dimension2',
      disableSortBy: true,
    },
    {
      Header: 'Reason',
      accessor: 'reason',
      disableSortBy: true,
    },
  ];

  return (
    <div className="overflow-auto">
      <Table
        data={data}
        columns={headers}
        paginated
        // An alternative export button is provided
        exportable={false}
      />
    </div>
  );
};

export default DetailedAuditTrailLogsTable;
