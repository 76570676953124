import { ProviderRole, Role, RshRole } from 'nrosh-common/Api/Enums';
import AuditsLandingPage from '@/Pages/AuditLogs/AuditsLandingPage';
import DetailedAuditTrailLogsPage from '@/Pages/AuditLogs/DetailedAuditTrailLogsPage';
import UserAuditLogsPage from '@/Pages/AuditLogs/UserAuditLogsPage';
import ContentManagementLandingPage from '@/Pages/Content/ContentManagementLandingPage';
import FaqsPage from '@/Pages/Content/Faqs/FaqsPage';
import EditableContentPage from '@/Pages/Content/ManageEditableContent/EditableContentPage';
import ManageEditableContentPage from '@/Pages/Content/ManageEditableContent/ManageEditableContentPage';
import CreateFaqPage from '@/Pages/Content/ManageFaqs/CreateFaqPage';
import EditFaqPage from '@/Pages/Content/ManageFaqs/EditFaqPage';
import ManageFaqsPage from '@/Pages/Content/ManageFaqs/ManageFaqsPage';
import CreateNewsArticlePage from '@/Pages/Content/ManageNews/CreateNewsArticlePage';
import EditNewsArticlePage from '@/Pages/Content/ManageNews/EditNewsArticlePage';
import ManageNewsPage from '@/Pages/Content/ManageNews/ManageNewsPage';
import ArticlePage from '@/Pages/Content/News/ArticlePage';
import NewsPage from '@/Pages/Content/News/NewsPage';
import EditPublicDocumentPage from '@/Pages/Content/PublicDocuments/EditPublicDocumentPage';
import ManagePublicDocumentsPage from '@/Pages/Content/PublicDocuments/ManagePublicDocumentsPage';
import PublicDocumentsPage from '@/Pages/Content/PublicDocuments/PublicDocumentsPage';
import UploadPublicDocumentPage from '@/Pages/Content/PublicDocuments/UploadPublicDocumentPage';
import { AutomatedEmailsPage } from '@/Pages/Emails/Automated/AutomatedEmailsPage';
import { UpdateEmailTemplatePage } from '@/Pages/Emails/Automated/UpdateEmailTemplatePage';
import { EditFooterPage } from '@/Pages/Emails/EditFooterPage';
import EmailsLandingPage from '@/Pages/Emails/EmailsLandingPage';
import { EmailRecipientsPage } from '@/Pages/Emails/Manual/EmailRecipientsPage';
import { ScheduledEmailPage } from '@/Pages/Emails/Manual/ScheduledEmailPage';
import { ViewOrEditEmailPage } from '@/Pages/Emails/Manual/ViewOrEditEmailPage';
import { EditSurveyReminderPage } from '@/Pages/Emails/SurveyReminders/EditSurveyReminderPage';
import { ManageSurveyRemindersPage } from '@/Pages/Emails/SurveyReminders/ManageSurveyRemindersPage';
import HomePage from '@/Pages/Home/HomePage';
import LoginPage from '@/Pages/Home/LoginPage';
import ForgotPasswordPage from '@/Pages/Home/PasswordChange/ForgotPasswordPage';
import PasswordResetPage from '@/Pages/Home/PasswordChange/PasswordResetPage';
import { ActivateAccountPage, ConfirmEmailAddressPage } from '@/Pages/Home/PasswordChange/VerifyEmailPages';
import AddProviderPage from '@/Pages/Providers/AddProviderPage';
import MyProviderDetailsPage from '@/Pages/Providers/MyProviderDetailsPage';
import OrganisationalInformationPage from '@/Pages/Providers/OrganisationalInformationPage';
import ProviderContactsPage from '@/Pages/Providers/ProviderContactsPage';
import ProviderManagementPage from '@/Pages/Providers/ProviderManagementPage';
import ProvidersLandingPage from '@/Pages/Providers/ProvidersLandingPage';
import RegisteredDetailsPage from '@/Pages/Providers/RegisteredDetailsPage';
import { ViewProviderGroupPage } from '@/Pages/Providers/ViewProviderGroupPage';
import AboutPage from '@/Pages/PublicPages/AboutPage';
import AccessibilityStatementPage from '@/Pages/PublicPages/AccessibilityStatementPage';
import ContactUsPage from '@/Pages/PublicPages/ContactUsPage';
import PrivacyNoticePage from '@/Pages/PublicPages/PrivacyNoticePage';
import TermsAndConditionsPage from '@/Pages/PublicPages/TermsAndConditionsPage';
import EditRegulatoryDocumentPage from '@/Pages/RegulatoryDocuments/EditRegulatoryDocumentPage';
import RegulatoryDocumentsPage from '@/Pages/RegulatoryDocuments/RegulatoryDocumentsPage';
import UploadRegulatoryDocumentPage from '@/Pages/RegulatoryDocuments/UploadRegulatoryDocumentPage';
import { BulkImportOptionsPage } from '@/Pages/Submissions/BulkImportOptionsPage';
import ConfirmDeclarationsPage from '@/Pages/Submissions/Confirmation/ConfirmDeclarationsPage';
import ConfirmFeesInvoicePage from '@/Pages/Submissions/Confirmation/ConfirmFeesInvoicePage';
import ConfirmOrganisationalContactsPage from '@/Pages/Submissions/Confirmation/ConfirmOrganisationalContactsPage';
import ConfirmRegisteredDetailsPage from '@/Pages/Submissions/Confirmation/ConfirmRegisteredDetailsPage';
import ConfirmSubmissionContactPage from '@/Pages/Submissions/Confirmation/ConfirmSubmissionContactPage';
import EditSupportingDocumentPage from '@/Pages/Submissions/Documents/EditSupportingDocumentPage';
import UploadSupportingDocumentPage from '@/Pages/Submissions/Documents/UploadSupportingDocumentPage';
import { PartImportOptionsPage } from '@/Pages/Submissions/PartImportOptionsPage';
import ReviewCrossPartValidationsPage from '@/Pages/Submissions/ReviewCrossPartValidations';
import SignOffPage from '@/Pages/Submissions/SignOffPage';
import SubmissionPartPage from '@/Pages/Submissions/SubmissionPartPage';
import SubmissionsDetailPage from '@/Pages/Submissions/SubmissionsDetailPage';
import SubmissionsPage from '@/Pages/Submissions/SubmissionsPage';
import UnsubmitPage from '@/Pages/Submissions/UnsubmitPage';
import ValidationIssuesPage from '@/Pages/Submissions/ValidationIssuesPage';
import AssignSurveyPage from '@/Pages/Surveys/AssignSurveyPage';
import AssignSurveyUploadPage from '@/Pages/Surveys/AssignSurveyUploadPage';
import CloneExistingSurveyPage from '@/Pages/Surveys/CloneExistingSurveyPage';
import ConfigureSurveyPage from '@/Pages/Surveys/ConfigureSurveyPage';
import CreateSurveyPage from '@/Pages/Surveys/CreateSurveyPage';
import DataDictionaryPage from '@/Pages/Surveys/DataDictionaryPage';
import EditSurveyPage from '@/Pages/Surveys/EditSurveyPage';
import EndOfSurveyChecksPage from '@/Pages/Surveys/EndOfSurveyChecksPage';
import SurveyCrossPartValidationsPage from '@/Pages/Surveys/SurveyCrossPartValidationsPage';
import SurveyDashboardPage from '@/Pages/Surveys/SurveyDashboardPage';
import SurveyPartTemplateVersionHistoryPage from '@/Pages/Surveys/SurveyPartTemplateVersionHistoryPage';
import SurveyViewPage from '@/Pages/Surveys/SurveyViewPage/SurveyViewPage';
import { AdminProviderAddUserPage } from '@/Pages/Users/AdminProviderAddUserPage';
import { AdminProviderEditUserPage } from '@/Pages/Users/AdminProviderEditUserPage';
import { AdminProviderUserManagementPage } from '@/Pages/Users/AdminProviderUserManagementPage';
import { AdminUserManagementPage } from '@/Pages/Users/AdminUserManagementPage';
import { EditPersonalEmailPage } from '@/Pages/Users/EditPersonalEmailPage';
import { EditUserEmailPage } from '@/Pages/Users/EditUserEmailPage';
import LoginAndSecurityPage from '@/Pages/Users/LoginAndSecurityPage';
import ManageProfilesPage from '@/Pages/Users/ManageProfilesPage';
import PersonalDetailsPage from '@/Pages/Users/PersonalDetailsPage';
import ProviderUserManagementPage from '@/Pages/Users/ProviderUserManagementPage';
import { AddDcsUserPage, AddUserPage, EditUserPage } from '@/Pages/Users/UserFormPages';
import { AddUserProfilePage, EditUserProfilePage } from '@/Pages/Users/UserProfileFormPages';

export type Page = {
  key: string;
  path: string;
  title: string;
  render: () => JSX.Element;
  roles?: Role[];
};

export const surveyPages: { [id: string]: Page } = {
  Surveys: {
    key: 'admin-surveys-list',
    path: '/surveys',
    title: 'Survey Dashboard',
    render: () => <SurveyDashboardPage />,
    roles: [RshRole.User],
  },
  SurveyCreate: {
    key: 'admin-surveys-create',
    path: '/surveys/create',
    title: 'Create Survey',
    render: () => <CreateSurveyPage />,
    roles: [RshRole.DesignSurveys],
  },
  SurveyClone: {
    key: 'admin-surveys-clone',
    path: '/surveys/clone',
    title: 'Clone Existing Survey',
    render: () => <CloneExistingSurveyPage />,
    roles: [RshRole.DesignSurveys],
  },
  SurveyView: {
    key: 'admin-surveys-view',
    path: '/surveys/:surveyId/timePeriods/:timePeriodId',
    title: 'View Survey',
    render: () => <SurveyViewPage />,
    roles: [RshRole.ViewSurveys],
  },
  SurveyEdit: {
    key: 'admin-surveys-edit',
    path: '/surveys/:surveyId/timePeriods/:timePeriodId/edit',
    title: 'Manage Survey',
    render: () => <EditSurveyPage />,
    roles: [RshRole.DesignSurveys],
  },
  SurveyConfigure: {
    key: 'admin-surveys-configure',
    path: '/surveys/:surveyId/timePeriods/:timePeriodId/configure',
    title: 'Configure Survey',
    render: () => <ConfigureSurveyPage />,
    roles: [RshRole.DesignSurveys],
  },
  SurveyPartEdit: {
    key: 'admin-surveys-data-dictionary',
    path: '/surveys/:surveyId/timePeriods/:timePeriodId/configure/surveypart/:surveyPartId/dataDictionary',
    title: 'Manage Data Dictionary',
    render: () => <DataDictionaryPage />,
    roles: [RshRole.DesignSurveys],
  },
  SurveyPartImportTemplateHistory: {
    key: 'admin-surveys-template-history',
    path: '/surveys/:surveyId/timePeriods/:timePeriodId/configure/surveypart/:surveyPartId/ImportTemplateHistory',
    title: 'View Template History',
    render: () => <SurveyPartTemplateVersionHistoryPage />,
    roles: [RshRole.DesignSurveys],
  },
  SurveyAssign: {
    key: 'admin-surveys-assign',
    path: '/surveys/:surveyId/timePeriods/:timePeriodId/assign',
    title: 'Assign Survey',
    render: () => <AssignSurveyPage />,
    roles: [RshRole.AssignSurveys],
  },
  SurveyAssignUpload: {
    key: 'admin-surveys-assign-upload',
    path: '/surveys/:surveyId/timePeriods/:timePeriodId/assign/upload',
    title: 'Bulk Assign Survey',
    render: () => <AssignSurveyUploadPage />,
    roles: [RshRole.AssignSurveys],
  },
  SurveyChecks: {
    key: 'admin-surveys-checks',
    path: '/surveys/:surveyId/timePeriods/:timePeriodId/checks',
    title: 'Manage End of Survey Checks',
    render: () => <EndOfSurveyChecksPage />,
    roles: [RshRole.DesignSurveys],
  },
  SurveyCrossPartValidations: {
    key: 'admin-survey-cross-part-validations',
    path: '/surveys/:surveyId/timePeriods/:timePeriodId/configure/crossPartValidations',
    title: 'Manage Cross-Part Validations',
    render: () => <SurveyCrossPartValidationsPage />,
    roles: [RshRole.DesignSurveys],
  },
  SurveyConfigureReminders: {
    key: 'admin-survey-configure-reminders',
    path: '/surveys/:surveyId/timePeriods/:timePeriodId/reminders',
    title: 'Configure Survey Reminders',
    render: () => <EditSurveyReminderPage isSurveyFlow />,
    roles: [RshRole.DesignSurveys],
  },
};

export const submissionPages: { [id: string]: Page } = {
  SubmissionView: {
    key: 'admin-submission-view',
    path: '/surveys/:surveyId/timePeriods/:timePeriodId/submissions/:submissionId',
    title: 'View Submission',
    render: () => <SubmissionsDetailPage isAdmin />,
    roles: [RshRole.ViewSurveyData, RshRole.AssignSurveys],
  },
  AdminSubmissionPartEdit: {
    key: 'admin-submissions-edit',
    path: '/surveys/:surveyId/timePeriods/:timePeriodId/submissions/:submissionId/submissionParts/:submissionPartId/edit',
    title: 'Edit Submission Data',
    render: () => <SubmissionPartPage isAdmin />,
    roles: [RshRole.ViewSurveyData],
  },
  AdminPartImportOptions: {
    key: 'import-options',
    path: '/surveys/:surveyId/timePeriods/:timePeriodId/submissions/:submissionId/submissionParts/:submissionPartId/import',
    title: 'Import Submission Part',
    render: () => <PartImportOptionsPage isAdmin />,
    roles: [RshRole.EditSurveyData],
  },
  AdminBulkImportOptions: {
    key: 'bulk-import-options',
    path: '/surveys/:surveyId/timePeriods/:timePeriodId/submissions/:submissionId/bulkImport',
    title: 'Bulk Import Submission Data',
    render: () => <BulkImportOptionsPage isAdmin />,
    roles: [RshRole.EditSurveyData],
  },
  AdminReviewCrossPartValidations: {
    key: 'admin-review-cross-part-validations',
    path: '/surveys/:surveyId/timePeriods/:timePeriodId/submissions/:submissionId/review-cross-part-validations',
    title: 'Review Cross-Part Validations',
    render: () => <ReviewCrossPartValidationsPage isAdmin />,
    roles: [RshRole.ViewSurveyData],
  },
  AdminValidationIssues: {
    key: 'admin-validation-issues',
    path: '/surveys/:surveyId/timePeriods/:timePeriodId/submissions/:submissionId/validationIssues',
    title: 'Review Validation Issues',
    render: () => <ValidationIssuesPage isAdmin />,
    roles: [RshRole.SubmitSurvey],
  },
  AdminConfirmSubmissionContact: {
    key: 'admin-confirm-submission-contact',
    path: '/surveys/:surveyId/timePeriods/:timePeriodId/submissions/:submissionId/confirm-survey-contact',
    title: 'Confirm Submission Contact',
    render: () => <ConfirmSubmissionContactPage isAdmin />,
    roles: [RshRole.SubmitSurvey],
  },
  AdminConfirmRegisteredDetails: {
    key: 'admin-confirm-registered-details',
    path: '/surveys/:surveyId/timePeriods/:timePeriodId/submissions/:submissionId/confirm-registered-details',
    title: 'Confirm Registered Details',
    render: () => <ConfirmRegisteredDetailsPage isAdmin />,
    roles: [RshRole.SubmitSurvey],
  },
  AdminConfirmFeesInvoiceEmail: {
    key: 'admin-confirm-fees-invoice-email',
    path: '/surveys/:surveyId/timePeriods/:timePeriodId/submission/:submissionId/confirm-fees-invoice-email',
    title: 'Confirm Fees Invoice Email',
    render: () => <ConfirmFeesInvoicePage isAdmin />,
    roles: [RshRole.SubmitSurvey],
  },
  AdminConfirmOrganisationalContacts: {
    key: 'admin-confirm-organisational-contacts',
    path: '/surveys/:surveyId/timePeriods/:timePeriodId/submissions/:submissionId/confirm-organisational-contact',
    title: 'Confirm Organisational Contacts',
    render: () => <ConfirmOrganisationalContactsPage isAdmin />,
    roles: [RshRole.SubmitSurvey],
  },
  AdminConfirmDeclarations: {
    key: 'admin-confirm-delcarations',
    path: '/surveys/:surveyId/timePeriods/:timePeriodId/submissions/:submissionId/confirm-declarations',
    title: 'Confirm Submission Declarations',
    render: () => <ConfirmDeclarationsPage isAdmin />,
    roles: [RshRole.SubmitSurvey],
  },
  AdminUnsubmitPage: {
    key: 'admin-unsubmit',
    path: '/surveys/:surveyId/timePeriods/:timePeriodId/submissions/:submissionId/unsubmit',
    title: 'Unsubmit Submission',
    render: () => <UnsubmitPage />,
    roles: [RshRole.UnsubmitSurvey],
  },
  AdminSignOffPage: {
    key: 'admin-sign-off',
    path: '/surveys/:surveyId/timePeriods/:timePeriodId/submissions/:submissionId/signOff',
    title: 'Sign-off Submission',
    render: () => <SignOffPage />,
    roles: [RshRole.SignOffSurvey],
  },
  UploadSupportingDocument: {
    key: 'admin-upload-supporting-document',
    path: '/surveys/:surveyId/timePeriods/:timePeriodId/submissions/:submissionId/documents',
    title: 'Upload Supporting Documents',
    render: () => <UploadSupportingDocumentPage />,
    roles: [RshRole.EditSurveyData],
  },
  EditSupportingDocument: {
    key: 'admin-edit-supporting-document',
    path: '/surveys/:surveyId/timePeriods/:timePeriodId/submissions/:submissionId/documents/:documentId',
    title: 'Edit Supporting Document',
    render: () => <EditSupportingDocumentPage />,
    roles: [RshRole.EditSurveyData],
  },
};

export const adminPages: { [id: string]: Page } = {
  AdminProviders: {
    key: 'admin-providers',
    path: '/admin/providers',
    title: 'View Providers',
    render: () => <ProvidersLandingPage />,
    roles: [RshRole.ViewProviders],
  },
  AdminManageProviders: {
    key: 'admin-providers',
    path: '/admin/providers/manage',
    title: 'View Providers',
    render: () => <ProviderManagementPage />,
    roles: [RshRole.ViewProviders],
  },
  AdminProviderGroups: {
    key: 'admin-provider-groups',
    path: '/admin/providers/groups',
    title: 'View Provider Groups',
    render: () => <ViewProviderGroupPage />,
    roles: [RshRole.ViewProviders],
  },
  AdminAddProvider: {
    key: 'admin-add-provider',
    path: '/admin/providers/add',
    title: 'Add Provider',
    render: () => <AddProviderPage />,
    roles: [RshRole.EditProviders],
  },
  AdminViewProvider: {
    key: 'admin-view-providers',
    path: '/admin/providers/:providerId',
    title: 'View Provider',
    render: () => <OrganisationalInformationPage />,
    roles: [RshRole.ViewProviders],
  },
  AdminRegisteredDetails: {
    key: 'admin-registered-details',
    path: '/admin/providers/:providerId/registeredDetails',
    title: 'View Registered Details',
    render: () => <RegisteredDetailsPage />,
    roles: [RshRole.ViewProviders],
  },
  AdminProviderContacts: {
    key: 'admin-provider-contacts',
    path: '/admin/providers/:providerId/contacts',
    title: 'Manage Provider Contacts',
    render: () => <ProviderContactsPage />,
    roles: [RshRole.ViewProviders],
  },
  AdminUsers: {
    key: 'admin-users',
    path: '/admin/users',
    title: 'View Users',
    render: () => <AdminUserManagementPage />,
    roles: [RshRole.ViewUsers],
  },
  AdminProviderUsers: {
    key: 'admin-provider-users',
    path: '/admin/providers/:providerId/users',
    title: 'Manage Provider Users',
    render: () => <AdminProviderUserManagementPage />,
    roles: [RshRole.ViewUsers],
  },
  AdminAddUser: {
    key: 'admin-add-users',
    path: '/admin/users/add/:providerId',
    title: 'Add User',
    render: () => <AddUserPage isAdmin />,
    roles: [RshRole.EditUsers],
  },
  AdminProviderAddUser: {
    key: 'admin-add-users',
    path: '/admin/provider/users/add/:providerId',
    title: 'Add User',
    render: () => <AdminProviderAddUserPage />,
    roles: [RshRole.EditUsers],
  },
  AdminAddDcsUser: {
    key: 'admin-add-dcs-users',
    path: '/admin/users/add/',
    title: 'Add DCS User',
    render: () => <AddDcsUserPage isAdmin />,
    roles: [RshRole.EditUsers],
  },
  AdminEditUser: {
    key: 'admin-edit-users',
    path: '/admin/users/edit/:userId',
    title: 'Edit User',
    render: () => <EditUserPage isAdmin />,
    roles: [RshRole.EditUsers],
  },
  AdminEditUserEmail: {
    key: 'admin-edit-user-email',
    path: '/admin/userManagement/editEmail/:userId',
    title: 'Edit User Email Address',
    render: () => <EditUserEmailPage isAdmin />,
    roles: [RshRole.EditUsers],
  },
  AdminProviderEditUser: {
    key: 'admin-provider-edit-users',
    path: '/admin/provider/users/edit/:userId',
    title: 'Edit User',
    render: () => <AdminProviderEditUserPage />,
    roles: [RshRole.EditUsers],
  },
  AdminManageUserProfiles: {
    key: 'admin-user-profiles',
    path: '/admin/users/profiles',
    title: 'Manage User Profiles',
    render: () => <ManageProfilesPage />,
    roles: [RshRole.ManageUserRoles],
  },
  AdminAddUserProfile: {
    key: 'admin-add-profile',
    path: '/admin/users/profiles/add',
    title: 'Add User Profile',
    render: () => <AddUserProfilePage />,
    roles: [RshRole.ManageUserRoles],
  },
  AdminEditUserProfile: {
    key: 'admin-edit-profile',
    path: '/admin/users/profiles/edit/:profileId',
    title: 'Edit User Profile',
    render: () => <EditUserProfilePage />,
    roles: [RshRole.ManageUserRoles],
  },
  AdminContent: {
    key: 'admin-content',
    path: '/admin/content',
    title: 'Content Management',
    render: () => <ContentManagementLandingPage />,
    roles: [RshRole.ManageContent],
  },
  AdminNews: {
    key: 'admin-news',
    path: '/admin/content/news',
    title: 'Manage News Articles',
    render: () => <ManageNewsPage />,
    roles: [RshRole.ManageContent],
  },
  AdminNewsCreate: {
    key: 'admin-news-add',
    path: '/admin/content/news/newsArticles/create',
    title: 'Add News Article',
    render: () => <CreateNewsArticlePage />,
    roles: [RshRole.ManageContent],
  },
  AdminNewsEdit: {
    key: 'admin-news-edit',
    path: '/admin/content/news/newsArticles/:articleId/edit',
    title: 'Edit News Article',
    render: () => <EditNewsArticlePage />,
    roles: [RshRole.ManageContent],
  },
  AdminFaqs: {
    key: 'admin-faqs',
    path: '/admin/content/faqs',
    title: 'Manage FAQs',
    render: () => <ManageFaqsPage />,
    roles: [RshRole.ManageContent],
  },
  AdminFaqsCreate: {
    key: 'admin-faqs-add',
    path: '/admin/content/faqs/create',
    title: 'Add FAQ',
    render: () => <CreateFaqPage />,
    roles: [RshRole.ManageContent],
  },
  AdminFaqsEdit: {
    key: 'admin-faqs-edit',
    path: '/admin/content/faqs/:faqId/edit',
    title: 'Edit FAQ',
    render: () => <EditFaqPage />,
    roles: [RshRole.ManageContent],
  },
  AdminEmails: {
    key: 'admin-emails',
    path: '/admin/emails',
    title: 'Emails',
    render: () => <EmailsLandingPage />,
    roles: [RshRole.ViewSentEmails, RshRole.ViewSurveys],
  },
  AdminManualEmails: {
    key: 'admin-emails-manual',
    path: '/admin/emails/manual',
    title: 'Manage Manual Emails',
    render: () => <ScheduledEmailPage />,
    roles: [RshRole.ViewSentEmails],
  },
  AdminViewOrEditEmail: {
    key: 'admin-emails-manual-view-or-edit',
    path: '/admin/emails/manual/:emailId/',
    title: 'Manage Manual Email',
    render: () => <ViewOrEditEmailPage />,
    roles: [RshRole.ViewSentEmails],
  },
  AdminEmailSetRecipients: {
    key: 'admin-emails-manual-set-recipients',
    path: '/admin/emails/manual/:emailId/recipients',
    title: 'Choose Recipients',
    render: () => <EmailRecipientsPage />,
    roles: [RshRole.SendEmails],
  },
  AdminEmailFooter: {
    key: 'admin-email-footer',
    path: '/admin/emails/footer',
    title: 'Manage Email Footer',
    render: () => <EditFooterPage />,
    roles: [RshRole.SendEmails],
  },
  AdminAutomatedEmails: {
    key: 'admin-emails-automated',
    path: '/admin/emails/automated',
    title: 'Manage Automated Emails',
    render: () => <AutomatedEmailsPage />,
    roles: [RshRole.SendEmails],
  },
  AdminUpdateEmailTemplate: {
    key: 'admin-emails-automated-template-update',
    path: '/admin/emails/automated/:templateType',
    title: 'Manage Email Template',
    render: () => <UpdateEmailTemplatePage />,
    roles: [RshRole.SendEmails],
  },
  AdminManageSurveyReminderEmails: {
    key: 'admin-emails-reminders',
    path: '/admin/emails/reminders',
    title: 'Manage Survey Reminders',
    render: () => <ManageSurveyRemindersPage />,
    roles: [RshRole.ViewSurveys],
  },
  AdminEditSurveyReminderEmail: {
    key: 'admin-emails-reminders-edit',
    path: '/admin/emails/reminders/surveys/:surveyId/timePeriods/:timePeriodId',
    title: 'Manage Survey Reminder',
    render: () => <EditSurveyReminderPage />,
    roles: [RshRole.ViewSurveys],
  },
  AdminAudits: {
    key: 'admin-audits',
    path: '/admin/audits',
    title: 'Audit Logs',
    render: () => <AuditsLandingPage />,
    roles: [RshRole.ViewAudit],
  },
  AdminUserAudit: {
    key: 'admin-audits-user',
    path: '/admin/audit/user',
    title: 'User Audit Logs',
    render: () => <UserAuditLogsPage />,
    roles: [RshRole.ViewAudit],
  },
  AdminDetailedAuditTrail: {
    key: 'admin-detailed-audit-trail',
    path: '/admin/audit/detailedAuditTrail',
    title: 'Survey Audit Logs',
    render: () => <DetailedAuditTrailLogsPage />,
    roles: [RshRole.ViewAudit],
  },
  AdminEditableContent: {
    key: 'admin-editable-content',
    path: '/admin/content/editableContent',
    title: 'Manage Site Content',
    render: () => <ManageEditableContentPage />,
    roles: [RshRole.ManageContent],
  },
  AdminEditPage: {
    key: 'admin-edit-page',
    path: '/admin/content/editableContent/:pageId',
    title: 'Manage Page Content',
    render: () => <EditableContentPage />,
    roles: [RshRole.ManageContent],
  },
  AdminPublicDocuments: {
    key: 'admin-public-documents',
    path: '/admin/public-documents',
    title: 'Manage Public Documents',
    render: () => <ManagePublicDocumentsPage />,
    roles: [RshRole.ManageContent],
  },
  AdminUploadPublicDocument: {
    key: 'admin-upload-public-document',
    path: '/admin/public-documents/upload',
    title: 'Upload Public Document',
    render: () => <UploadPublicDocumentPage />,
    roles: [RshRole.ManageContent],
  },
  AdminEditPublicDocument: {
    key: 'admin-edit-public-document',
    path: '/admin/public-documents/:documentId',
    title: 'Edit Public Document',
    render: () => <EditPublicDocumentPage />,
    roles: [RshRole.ManageContent],
  },
  RegulatoryDocuments: {
    key: 'admin-regulatory-documents',
    path: '/admin/documents',
    title: 'Manage Regulatory Documents',
    render: () => <RegulatoryDocumentsPage isAdmin />,
    roles: [RshRole.ViewRegDocs],
  },
  UploadRegulatoryDocument: {
    key: 'admin-upload-regulatory-document',
    path: '/admin/documents/upload',
    title: 'Upload Regulatory Documents',
    render: () => <UploadRegulatoryDocumentPage />,
    roles: [RshRole.UploadRegDocs],
  },
  EditRegulatoryDocument: {
    key: 'admin-edit-regulatory-document',
    path: '/admin/documents/:documentId',
    title: 'Edit Regulatory Document',
    render: () => <EditRegulatoryDocumentPage />,
    roles: [RshRole.EditRegDocs],
  },
};

export const providerPages: { [id: string]: Page } = {
  SubmissionsList: {
    key: 'submissions-list',
    path: '/my-dcs/submissions',
    title: 'My Surveys',
    render: () => <SubmissionsPage />,
    roles: [ProviderRole.User],
  },
  ProviderSubmissionPartEdit: {
    key: 'provider-submissions-edit',
    path: '/my-dcs/submissions/:submissionId/submissionParts/:submissionPartId/edit',
    title: 'Edit Survey Data',
    render: () => <SubmissionPartPage isAdmin={false} />,
    roles: [ProviderRole.ViewSurveyData],
  },
  SubmissionsDetail: {
    key: 'submissions-detail',
    path: '/my-dcs/submissions/:submissionId',
    title: 'View Survey',
    render: () => <SubmissionsDetailPage isAdmin={false} />,
    roles: [ProviderRole.ViewSurveyData],
  },
  UploadSupportingDocument: {
    key: 'upload-supporting-document',
    path: '/my-dcs/submissions/:submissionId/documents',
    title: 'Upload Supporting Documents',
    render: () => <UploadSupportingDocumentPage />,
    roles: [ProviderRole.EditSurveyData],
  },
  EditSupportingDocument: {
    key: 'edit-supporting-document',
    path: '/my-dcs/submissions/:submissionId/documents/:documentId',
    title: 'Edit Supporting Document',
    render: () => <EditSupportingDocumentPage />,
    roles: [ProviderRole.EditSurveyData],
  },
  ProviderPartImportOptions: {
    key: 'import-options',
    path: '/my-dcs/submissions/:submissionId/submissionParts/:submissionPartId/import',
    title: 'Import Survey Part',
    render: () => <PartImportOptionsPage isAdmin={false} />,
    roles: [ProviderRole.EditSurveyData],
  },
  ProviderBulkImportOptions: {
    key: 'bulk-import-options',
    path: '/my-dcs/submissions/:submissionId/bulkImport',
    title: 'Bulk Import Survey Data',
    render: () => <BulkImportOptionsPage isAdmin={false} />,
    roles: [ProviderRole.EditSurveyData],
  },
  ValidationIssues: {
    key: 'validation-issues',
    path: '/my-dcs/submissions/:submissionId/validationIssues',
    title: 'View Validation Issues',
    render: () => <ValidationIssuesPage />,
    roles: [ProviderRole.SubmitSurvey],
  },
  ProviderDetails: {
    key: 'my-provider-details',
    path: '/my-dcs/provider',
    title: 'Manage Provider Details',
    render: () => <MyProviderDetailsPage />,
    roles: [ProviderRole.ViewProvider],
  },
  UserManagement: {
    key: 'user-management',
    path: '/my-dcs/userManagement',
    title: 'Manage Users',
    render: () => <ProviderUserManagementPage />,
    roles: [ProviderRole.ViewUsers],
  },
  AddUser: {
    key: 'user-management',
    path: '/my-dcs/userManagement/add',
    title: 'Add User',
    render: () => <AddUserPage isAdmin={false} />,
    roles: [ProviderRole.EditUsers],
  },
  EditUser: {
    key: 'user-management',
    path: '/my-dcs/userManagement/edit/:userId',
    title: 'Edit User',
    render: () => <EditUserPage isAdmin={false} />,
    roles: [ProviderRole.EditUsers],
  },
  EditUserEmail: {
    key: 'edit-user-email',
    path: '/my-dcs/userManagement/editEmail/:userId',
    title: 'Edit User Email Address',
    render: () => <EditUserEmailPage isAdmin={false} />,
    roles: [ProviderRole.EditUsers],
  },
  ConfirmSubmissionContact: {
    key: 'confirm-submission-contact',
    path: '/my-dcs/submissions/:submissionId/confirm-survey-contact',
    title: 'Confirm Survey Contact',
    render: () => <ConfirmSubmissionContactPage />,
    roles: [ProviderRole.SubmitSurvey],
  },
  ConfirmRegisteredDetails: {
    key: 'confirm-registered-details',
    path: '/my-dcs/submissions/:submissionId/confirm-registered-details',
    title: 'Confirm Registered Details',
    render: () => <ConfirmRegisteredDetailsPage />,
    roles: [ProviderRole.SubmitSurvey],
  },
  ConfirmFeesInvoiceEmail: {
    key: 'confirm-fees-invoice-email',
    path: '/my-dcs/submissions/:submissionId/confirm-fees-invoice-email',
    title: 'Confirm Fees Invoice Email',
    render: () => <ConfirmFeesInvoicePage />,
    roles: [ProviderRole.SubmitSurvey],
  },
  ConfirmOrganisationalContacts: {
    key: 'confirm-organisational-contact',
    path: '/my-dcs/submissions/:submissionId/confirm-organisational-contact',
    title: 'Confirm Organisational Contacts',
    render: () => <ConfirmOrganisationalContactsPage />,
    roles: [ProviderRole.SubmitSurvey],
  },
  ConfirmDeclarations: {
    key: 'confirm-declarations',
    path: '/my-dcs/submissions/:submissionId/confirm-declarations',
    title: 'Confirm Declarations',
    render: () => <ConfirmDeclarationsPage />,
    roles: [ProviderRole.SubmitSurvey],
  },
  ReviewCrossPartValidations: {
    key: 'review-cross-part-validations',
    path: '/my-dcs/submissions/:submissionId/review-cross-part-validations',
    title: 'Review Cross-Part Validations',
    render: () => <ReviewCrossPartValidationsPage isAdmin={false} />,
    roles: [ProviderRole.ViewSurveyData],
  },
  RegulatoryDocuments: {
    key: 'provider-regulatory-documents',
    path: '/my-dcs/documents',
    title: 'Manage Regulatory Documents',
    render: () => <RegulatoryDocumentsPage />,
    roles: [ProviderRole.ViewRegDocs],
  },
  UploadRegulatoryDocument: {
    key: 'provider-upload-regulatory-document',
    path: '/my-dcs/documents/upload',
    title: 'Upload Regulatory Documents',
    render: () => <UploadRegulatoryDocumentPage />,
    roles: [ProviderRole.UploadRegDocs],
  },
  EditRegulatoryDocument: {
    key: 'provider-edit-regulatory-document',
    path: '/my-dcs/documents/:documentId',
    title: 'Edit Regulatory Document',
    render: () => <EditRegulatoryDocumentPage />,
    roles: [ProviderRole.EditRegDocs],
  },
};

export const publicPages: { [id: string]: Page } = {
  PublicHome: {
    key: 'home',
    path: '/',
    title: 'DCS',
    render: () => <HomePage />,
  },
  PublicNews: {
    key: 'news',
    path: '/news',
    title: 'News',
    render: () => <NewsPage />,
  },
  PublicArticle: {
    key: 'article',
    path: '/news/:articleId',
    title: 'View News Article',
    render: () => <ArticlePage />,
  },
  PublicDocuments: {
    key: 'documents',
    // Path should match the expected path in the API for documents (EmailTemplatingService.cs)
    path: '/documents',
    title: 'Documents',
    render: () => <PublicDocumentsPage />,
  },
  PublicFaqs: {
    key: 'faqs',
    path: '/faq',
    title: 'FAQs',
    render: () => <FaqsPage />,
  },
  PublicContactUs: {
    key: 'contact-us',
    path: '/contact-us',
    title: 'Contact Us',
    render: () => <ContactUsPage />,
  },
  PublicAbout: {
    key: 'about',
    path: '/about',
    title: 'About DCS',
    render: () => <AboutPage />,
  },
  PublicPrivacyNotice: {
    key: 'privacy-notice',
    path: '/privacy-notice',
    title: 'Privacy Notice',
    render: () => <PrivacyNoticePage />,
  },
  PublicAccessibilityStatement: {
    key: 'accessibility-statement',
    path: '/accessibility-statement',
    title: 'Accessibility Statement',
    render: () => <AccessibilityStatementPage />,
  },
  PublicTermsAndConditions: {
    key: 'terms-and-conditions',
    path: '/terms-and-conditions',
    title: 'Terms and Conditions',
    render: () => <TermsAndConditionsPage />,
  },
};

export const loginPages: { [id: string]: Page } = {
  PublicLogin: {
    key: 'login',
    path: '/login',
    title: 'Login to DCS',
    render: () => <LoginPage />,
  },
  PublicForgotPassword: {
    key: 'forgot-password',
    path: '/forgot-password',
    title: 'Forgot Password',
    render: () => <ForgotPasswordPage />,
  },
  PublicPasswordReset: {
    key: 'password-reset',
    // Path should match the expected path in the API for password reset emails (AutomatedEmailService.cs)
    path: '/password-reset',
    title: 'Reset Password',
    render: () => <PasswordResetPage />,
  },
  PublicActivation: {
    key: 'activate-account',
    // Path should match the expected path in the API for account activation emails (AutomatedEmailService.cs)
    path: '/activate',
    title: 'Activate Account',
    render: () => <ActivateAccountPage />,
  },
  PublicConfirmEmail: {
    key: 'confirm-email',
    // Path should match the expected path in the API for account activation emails (AutomatedEmailService.cs)
    path: '/confirm-email',
    title: 'Confirm Email Address',
    render: () => <ConfirmEmailAddressPage />,
  },
};

export const userPages: { [id: string]: Page } = {
  UserPersonalDetails: {
    key: 'personal-details',
    path: '/user/personal-details',
    title: 'Personal Details',
    render: () => <PersonalDetailsPage />,
    roles: [ProviderRole.User, RshRole.User],
  },
  UserLoginSecurity: {
    key: 'login-security',
    path: '/user/login-security',
    title: 'Login and Security',
    render: () => <LoginAndSecurityPage />,
    roles: [ProviderRole.User, RshRole.User],
  },
  EditPersonalEmail: {
    key: 'edit-personal-email',
    path: '/user/personal-details/editEmail/:userId',
    title: 'Edit Email Address',
    render: () => <EditPersonalEmailPage />,
    roles: [ProviderRole.EditUsers, RshRole.User],
  },
};
