/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

type FunctionType = (key: string, ...values: any[]) => void;

export const debounceOverFirstArg = (func: FunctionType, timeout = 300): FunctionType => {
  const timers: { [key: string]: NodeJS.Timeout } = {};

  return (key: string, ...values: any[]) => {
    const existingTimer = timers[key];
    if (existingTimer) {
      clearTimeout(existingTimer);
    }
    timers[key] = setTimeout(() => {
      func(key, ...values);
    }, timeout);
  };
};
