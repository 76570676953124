import { SurveyStatus } from 'nrosh-common/Api/SubmissionsApi';
import { SurveyInstance } from 'nrosh-common/Api/SurveysApi';
import { Stack } from 'react-bootstrap';
import { generatePath } from 'react-router-dom';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import PageHeader from '@/Components/PageHeader/PageHeader';
import SurveyForm from '@/Components/SurveyForm/SurveyForm';
import { SurveysApi } from '@/Helpers/Apis';
import { surveyPages } from '@/Pages/Home/SitePages';
import '@/Styles/BootstrapOverrides/ListGroup.scss';

const CreateSurveyPage = (): JSX.Element => {
  const initialValues: SurveyInstance = {
    timePeriodId: null,
    name: '',
    timePeriodName: '',
    frequency: null,
    parts: [],
    defaultSubmissionDeadline: null,
    crossPartValidations: [],
    surveyStatus: SurveyStatus.InDevelopment,
    isSurveyInstanceConfigured: false,
    isAssigned: false,
    isDeletable: true,
    description: '',
  };

  const createNewSurvey = async (
    surveyData: SurveyInstance,
  ): Promise<{ success: boolean; redirectPath?: string; errorMessage?: string }> => {
    const survey = {
      name: surveyData.name.trim(),
      initialTimePeriodId: surveyData.timePeriodId!,
      partNames: surveyData.parts.map((part) => part.name.trim()),
      frequency: surveyData.frequency!,
      description: surveyData.description ? surveyData.description.trim() : null,
      // We should have passed validation before this is called, so ok to assume deadline is set
      defaultSubmissionDeadline: surveyData.defaultSubmissionDeadline!,
    };
    const response = await SurveysApi.createSurvey(survey).raw;
    if (response.ok) {
      const surveyInstanceId = response.value;
      const redirectPath = generatePath(surveyPages.SurveyView.path, {
        surveyId: surveyInstanceId.surveyId.toString(),
        timePeriodId: surveyInstanceId.timePeriodId.toString(),
      });
      return { success: true, redirectPath };
    }
    const errorMessage = response.value.message;
    return { success: false, errorMessage };
  };

  return (
    <Stack gap={5}>
      <PageHeader
        heading="Create new survey"
        crumbsType={RootPathType.AdminSurveys}
        crumbs={[{ name: 'Create new survey', path: surveyPages.SurveyCreate.path }]}
      />
      <SurveyForm
        initialValues={initialValues}
        onSave={createNewSurvey}
        pathOnCancel={surveyPages.Surveys.path}
        isCreate
      />
    </Stack>
  );
};

export default CreateSurveyPage;
