import { PersonalDetails } from 'nrosh-common/Api/AccountApi';
import { APIResult, FailureResponse } from 'nrosh-common/Api/ApiClient';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import { FormEvent, useContext, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { generatePath } from 'react-router-dom';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import { AccessibleFeedback } from '@/Components/Form/AccessibleFeedback';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { AccountApi, generateResponseErrorMessage } from '@/Helpers/Apis';
import { getValidityProps } from '@/Helpers/Forms';
import useUnsavedChangesWarning from '@/Hooks/useUnsavedChangesWarning';
import { userPages } from '@/Pages/Home/SitePages';
import { UserEmailFormControl } from '@/Pages/Users/UserEmailFormControl';

type PersonalDetailsFormProps = {
  personalDetails: PersonalDetails;
};

enum PersonalDetailsField {
  Name,
  PreferredName,
  Email,
}

const PersonalDetailsForm = ({ personalDetails }: PersonalDetailsFormProps): JSX.Element => {
  const [fieldValues, setFieldValues] = useState<Record<PersonalDetailsField, string>>({
    [PersonalDetailsField.Name]: personalDetails.name,
    [PersonalDetailsField.PreferredName]: personalDetails.preferredFormOfAddress ?? '',
    [PersonalDetailsField.Email]: personalDetails.emailAddress,
  });
  const [fieldValidities, setFieldValidities] = useState<Record<PersonalDetailsField, boolean>>({
    [PersonalDetailsField.Name]: true,
    [PersonalDetailsField.PreferredName]: true,
    [PersonalDetailsField.Email]: true,
  });

  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [validated, setValidated] = useState<boolean>(false);
  const [setDirty, setPristine, isDirty] = useUnsavedChangesWarning();
  const auth = useContext(AuthContext);

  const onFieldInvalid = (field: PersonalDetailsField): void => {
    setFieldValidities((oldFieldValidity) => ({ ...oldFieldValidity, [field]: false }));
  };

  const onFieldChange = (field: PersonalDetailsField, newValue: string): void => {
    setFieldValues((oldFieldValues) => ({ ...oldFieldValues, [field]: newValue }));
    setFieldValidities((oldFieldValidities) => ({ ...oldFieldValidities, [field]: true }));
    setDirty();
    setValidated(false);
    setError(null);
  };

  // TODO-595: This is duplicated
  const handleError = async (response: FailureResponse<APIResult>): Promise<void> => {
    const unknownErrorMessage = 'An unexpected problem has occured.';
    try {
      const body = response.value;
      const errorMessage = generateResponseErrorMessage(body, (err) => err.error, unknownErrorMessage);
      setError(errorMessage);
    } catch {
      setError(unknownErrorMessage);
    }
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    setError(null);
    event.preventDefault(); // Stops the page from reloading

    if (!event.currentTarget.checkValidity()) {
      setValidated(true);
      return;
    }

    setSubmitting(true);

    const response = await AccountApi.updatePersonalDetails({
      emailAddress: fieldValues[PersonalDetailsField.Email],
      name: fieldValues[PersonalDetailsField.Name],
      preferredFormOfAddress: fieldValues[PersonalDetailsField.PreferredName],
    }).raw;

    if (response.ok) {
      setPristine();
      auth.setCurrentUser({
        isLoggedIn: true,
        user: {
          ...auth.user!,
          displayName: fieldValues[PersonalDetailsField.PreferredName] || fieldValues[PersonalDetailsField.Name],
        },
      });
    } else {
      await handleError(response);
    }

    setSubmitting(false);
  };

  return (
    <Form noValidate onSubmit={onSubmit}>
      {error && (
        <Alert variant="danger" className="w-75">
          {error}
        </Alert>
      )}
      <Form.Group className="mb-3 w-25 userFormGroup" controlId="userName">
        <Form.Label>Username</Form.Label>
        <Form.Control value={personalDetails.userName} disabled />
      </Form.Group>
      <Form.Group className="mb-3 w-25 userFormGroup" controlId="name">
        <Form.Label>Name</Form.Label>
        <Form.Control
          value={fieldValues[PersonalDetailsField.Name]}
          onChange={(e) => onFieldChange(PersonalDetailsField.Name, e.target.value)}
          required
          maxLength={256}
          onInvalid={() => onFieldInvalid(PersonalDetailsField.Name)}
          {...getValidityProps(validated && !fieldValidities[PersonalDetailsField.Name], 'name-feedback')}
        />
        <AccessibleFeedback
          displayFeedback={validated && !fieldValidities[PersonalDetailsField.Name]}
          id="name-feedback"
        >
          A name is required
        </AccessibleFeedback>
      </Form.Group>
      <Form.Group className="mb-3 w-25 userFormGroup" controlId="preferredAddress">
        <Form.Label>Preferred Name</Form.Label>
        <Form.Control
          value={fieldValues[PersonalDetailsField.PreferredName]}
          onChange={(e) => onFieldChange(PersonalDetailsField.PreferredName, e.target.value)}
          maxLength={256}
          onInvalid={() => onFieldInvalid(PersonalDetailsField.PreferredName)}
          {...getValidityProps(
            validated && !fieldValidities[PersonalDetailsField.PreferredName],
            'preferred-name-feedback',
          )}
        />
        <AccessibleFeedback
          displayFeedback={validated && !fieldValidities[PersonalDetailsField.PreferredName]}
          id="preferred-name-feedback"
        >
          A preferred name can have at most 256 characters
        </AccessibleFeedback>
      </Form.Group>
      <UserEmailFormControl
        email={fieldValues[PersonalDetailsField.Email]}
        setEmail={(newEmail) => onFieldChange(PersonalDetailsField.Email, newEmail)}
        editLinkPath={generatePath(userPages.EditPersonalEmail.path, { userId: personalDetails.userId })}
        isReadonly
        validated={validated}
        isValid={fieldValidities[PersonalDetailsField.Email]}
      />
      <div className="mt-4">
        {!isSubmitting ? (
          <PrimaryButton type="submit" disabled={!isDirty}>
            Update
          </PrimaryButton>
        ) : (
          <LoadingButton message="Update" />
        )}
      </div>
    </Form>
  );
};

export default PersonalDetailsForm;
