import { nameof } from 'nrosh-common/Helpers/StringHelpers';
import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { ReactNode, useContext, useMemo, useState } from 'react';
import { DimensionValue } from '@/Components/Spreadsheet/SpreadsheetTypes';
import { createContext } from '@/Helpers/Context';
import { useSubmissionPartLiveData } from '@/Pages/Submissions/SubmissionPartContext/SubmissionPartLiveDataContext';

export type SlicerValuesData = {
  selectedSlicerValues: Record<number, number>;
  setSelectedSlicerValues: ReactStateSetter<Record<number, number>>;
};

const SlicerValuesContext = createContext<SlicerValuesData>();

const memberIdWithMinimalIndex = (dimensionValues: DimensionValue[]): number =>
  dimensionValues.length > 0 ? dimensionValues.sort((a, b) => a.index - b.index)[0].dimensionMemberId : 0;

export const SlicerValuesContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const { dimensionRegions, dimensionValues } = useSubmissionPartLiveData();

  const slicerRegions = dimensionRegions.filter((dr) => dr.isSlicer);
  const [selectedSlicerValues, setSelectedSlicerValues] = useState<Record<number, number>>(
    Object.assign(
      {},
      ...slicerRegions.map((sr) => ({
        [sr.id]: memberIdWithMinimalIndex(dimensionValues.filter((dv) => dv.regionId === sr.id)),
      })),
    ) as Record<number, number>,
  );

  const value = useMemo(() => ({ selectedSlicerValues, setSelectedSlicerValues }), [selectedSlicerValues]);

  return <SlicerValuesContext.Provider value={value}>{children}</SlicerValuesContext.Provider>;
};

export const useSlicerValuesContext = (): SlicerValuesData => {
  const context = useContext(SlicerValuesContext);
  if (!context) {
    throw new Error(
      `${nameof({ useSlicerValuesContext })} must be used within ${nameof({ SlicerValuesContextProvider })}`,
    );
  }

  return context;
};
