import { UserProfile } from 'nrosh-common/Api/UsersApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { UsersApi } from '@/Helpers/Apis';
import { adminPages } from '@/Pages/Home/SitePages';
import UserProfileForm from '@/Pages/Users/UserProfileForm';

export const AddUserProfilePage = (): JSX.Element => (
  <Stack gap={5}>
    <PageHeader
      heading="Add user profile"
      crumbsType={RootPathType.AdminUsers}
      crumbs={[
        { name: 'Manage user profiles', path: adminPages.AdminManageUserProfiles.path },
        { name: 'Add user profile', path: adminPages.AdminAddUserProfile.path },
      ]}
    />
    <UserProfileForm />
  </Stack>
);

export const EditUserProfilePage = (): JSX.Element => {
  const { profileId } = useParams();

  const [profile] = useEndpoint<UserProfile>(UsersApi.getProfile, profileId);

  if (!profile) {
    return <LoadingMessage />;
  }

  return (
    <Stack gap={5}>
      <PageHeader
        heading="Edit user profile"
        crumbsType={RootPathType.AdminUsers}
        crumbs={[
          { name: 'Manage user profiles', path: adminPages.AdminManageUserProfiles.path },
          { name: 'Edit user profile', path: adminPages.AdminEditUserProfile.path },
        ]}
      />
      <UserProfileForm profile={profile} />
    </Stack>
  );
};
