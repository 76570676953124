import { PageTitle } from 'nrosh-common/Api/Enums';
import { ContentChunk } from 'nrosh-common/Api/PublicPagesApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import ContentDisplay from '@/Components/ContentDisplay/ContentDisplay';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import { PublicPagesApi } from '@/Helpers/Apis';

const ContactDetails = (): JSX.Element => {
  const [contentChunk] = useEndpoint<ContentChunk>(PublicPagesApi.getContentChunk, PageTitle.ContactUs);

  if (!contentChunk) {
    return <LoadingMessage />;
  }
  return (
    <div className="flex-column">
      <ContentDisplay htmlContent={contentChunk.content} />
    </div>
  );
};

export default ContactDetails;
