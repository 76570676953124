import { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import { AccountApi, placeHolderAPIErrorHandler } from '@/Helpers/Apis';
import useRedirectWhenLoggedIn from '@/Hooks/useRedirectWhenLoggedIn';
import ChangePasswordForm, { ChangePasswordType } from '@/Pages/Home/PasswordChange/ChangePasswordForm';
import { useTokenAndUsername } from '@/Pages/Home/PasswordChange/VerifyEmailPages';

const PasswordResetPage = (): JSX.Element => {
  const { token, username } = useTokenAndUsername();
  useRedirectWhenLoggedIn();

  const [isTokenValid, setIsTokenValid] = useState<boolean>();

  useEffect(() => {
    if (!token || !username) {
      return;
    }

    AccountApi.verifyPasswordResetToken({ token, username })
      .throwOnErrors()
      .then(() => {
        setIsTokenValid(true);
      })
      .catch((err) => {
        setIsTokenValid(false);
        placeHolderAPIErrorHandler(err).catch(() => {});
      });
  }, [token, username]);

  if (isTokenValid == null) {
    return <LoadingMessage />;
  }

  return (
    <div className="d-flex flex-column">
      <h1 className="mx-auto pt-2 pb-3">Reset Password</h1>
      {!isTokenValid && (
        <Alert variant="danger" className="text-center">
          Your reset password link has expired.
        </Alert>
      )}
      {isTokenValid && (
        <>
          <p className="pt-0 text-center">Please enter your new password</p>
          <ChangePasswordForm
            token={token!}
            username={username!}
            changePasswordType={ChangePasswordType.ResetPassword}
            isLoginLayout
          />
        </>
      )}
    </div>
  );
};

export default PasswordResetPage;
