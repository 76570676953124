import { Survey, SurveyInstanceId, Surveys, TimePeriodWithStringDates } from 'nrosh-common/Api/SurveysApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { ChangeEvent, FormEvent, useState } from 'react';
import { Form, Stack } from 'react-bootstrap';
import { generatePath, useNavigate } from 'react-router-dom';
import { UtcDatePicker } from '@/Components/Form/FormDatePicker';
import LinkButton from '@/Components/Links/LinkButton';
import { LoadingSpinner } from '@/Components/Loading/LoadingSpinner';
import SurveyFormButton from '@/Components/SurveyForm/SurveyFormButton';
import TooltippedInfoIcon from '@/Components/Tooltips/TooltippedInfoIcon';
import { SurveysApi } from '@/Helpers/Apis';
import { surveyPages } from '@/Pages/Home/SitePages';

const getAvailableTimePeriodsForSurvey = (
  survey: Survey,
  timePeriods: TimePeriodWithStringDates[],
): TimePeriodWithStringDates[] => {
  const timePeriodIdsInUse = survey.instances.map((i) => i.timePeriodId);
  return timePeriods
    .filter((tp) => tp.timePeriodType === (survey.frequency as string))
    .filter((tp) => !timePeriodIdsInUse.includes(tp.id));
};

const getTimePeriodsInUseForSurvey = (survey: Survey): { id: number; name: string }[] =>
  survey.instances.map((i) => ({ id: i.timePeriodId, name: i.timePeriodName }));

const CloneSurveyForm = (): JSX.Element => {
  const navigate = useNavigate();
  const [surveysResponse] = useEndpoint<Surveys>(SurveysApi.getSurveys);
  const [timePeriods] = useEndpoint<TimePeriodWithStringDates[]>(SurveysApi.getTimePeriods);

  const [surveyId, setSurveyId] = useState('');
  const [timePeriodId, setTimePeriodId] = useState('');
  const [copyFromTimePeriodId, setCopyFromTimePeriodId] = useState('');
  const [defaultSubmissionDeadline, setDefaultSubmissionDeadline] = useState<Date | null>(null);
  const [isSaving, setSaving] = useState(false);

  if (!surveysResponse || !timePeriods) {
    return <LoadingSpinner />;
  }

  const { surveys } = surveysResponse;
  const selectedSurvey = surveys.find((s) => s.surveyId.toString() === surveyId);
  const availableTimePeriods = selectedSurvey ? getAvailableTimePeriodsForSurvey(selectedSurvey, timePeriods) : [];
  const timePeriodsInUse = selectedSurvey ? getTimePeriodsInUseForSurvey(selectedSurvey) : [];

  const allRequiredFieldsHaveValues = !!(surveyId && timePeriodId && copyFromTimePeriodId && defaultSubmissionDeadline);

  const onSurveySelect = (e: ChangeEvent<HTMLSelectElement>): void => {
    setSurveyId(e.target.value);
    setTimePeriodId('');
    setCopyFromTimePeriodId('');
    setDefaultSubmissionDeadline(null);
  };

  const onTimePeriodSelect = (e: ChangeEvent<HTMLSelectElement>): void => {
    setTimePeriodId(e.target.value);
  };

  const onCopyFromTimePeriodSelect = (e: ChangeEvent<HTMLSelectElement>): void => {
    setCopyFromTimePeriodId(e.target.value);
  };

  const onDefaultSubmissionDeadlineChange = (newDate: Date | null): void => {
    setDefaultSubmissionDeadline(newDate);
  };

  const createNewSurveyInstance = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    if (!event.currentTarget.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      setSaving(true);
      const request = {
        timePeriodId: parseInt(timePeriodId, 10),
        copyFromTimePeriodId: parseInt(copyFromTimePeriodId, 10),
        defaultSubmissionDeadline: defaultSubmissionDeadline!, // This date is required, so must exist if the validity check has passed.
      };
      const response = await SurveysApi.createSurveyInstance(surveyId, request).raw;
      if (response.ok) {
        const surveyInstanceId: SurveyInstanceId = response.value;
        navigate(
          generatePath(surveyPages.SurveyView.path, {
            surveyId: surveyInstanceId.surveyId.toString(),
            timePeriodId: surveyInstanceId.timePeriodId.toString(),
          }),
          { state: { shouldShowSuccessAlert: true } },
        );
      } else {
        // TODO-377: Look into using actual error data
        setSaving(false);
      }
    }
  };

  return (
    <Form noValidate onSubmit={createNewSurveyInstance} className="surveyForm d-grid gap-5">
      <Stack gap={4}>
        <Form.Group>
          <Form.Label htmlFor="selectSurvey">Select a survey to clone</Form.Label>
          <Form.Select id="selectSurvey" value={surveyId} onChange={onSurveySelect}>
            <option key="none" value="">
              Please select
            </option>
            {surveys.map((survey) => (
              <option key={survey.surveyId} value={survey.surveyId.toString()}>
                {survey.surveyName}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        {surveyId && (
          <>
            <Form.Group>
              <Form.Label htmlFor="selectTimePeriod">Time period</Form.Label>
              <TooltippedInfoIcon message="Select your time period" />
              <Form.Select id="selectTimePeriod" value={timePeriodId} onChange={onTimePeriodSelect}>
                <option key="none" value="">
                  Please select
                </option>
                {availableTimePeriods.map((timePeriod) => (
                  <option key={timePeriod.id} value={timePeriod.id.toString()}>
                    {timePeriod.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="selectCopyPeriod">Copy survey data from</Form.Label>
              <TooltippedInfoIcon message="Select survey to copy data from" />
              <Form.Select id="selectCopyPeriod" value={copyFromTimePeriodId} onChange={onCopyFromTimePeriodSelect}>
                <option key="none" value="">
                  Please select
                </option>
                {timePeriodsInUse.map((timePeriod) => (
                  <option key={timePeriod.id} value={timePeriod.id.toString()}>
                    {timePeriod.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="defaultSubmissionDeadline">Submission deadline</Form.Label>
              <UtcDatePicker
                value={defaultSubmissionDeadline}
                onChange={onDefaultSubmissionDeadlineChange}
                inputId="defaultSubmissionDeadline"
              />
            </Form.Group>
          </>
        )}
      </Stack>
      <div className="d-flex gap-3">
        <LinkButton to={surveyPages.Surveys.path} variant="outline-primary" disabled={isSaving}>
          Cancel
        </LinkButton>
        <SurveyFormButton isSaving={isSaving} disabled={!allRequiredFieldsHaveValues} text="Clone survey" />
      </div>
    </Form>
  );
};

export default CloneSurveyForm;
