import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';
import { Variant } from 'react-bootstrap/esm/types';
import '@/Components/Buttons/DCSButton.scss';

interface BootstrapButtonWithIcon extends React.ComponentProps<typeof BootstrapButton> {
  icon?: ReactNode;
}

export interface DCSButtonProps extends BootstrapButtonWithIcon {
  colour?: Variant;
}

const DCSButton = ({ icon, children, ...bootstrapButtonProps }: BootstrapButtonWithIcon): JSX.Element => (
  <BootstrapButton {...bootstrapButtonProps}>
    <div className="d-flex gap-1 align-items-center">
      {icon && <div className="d-flex">{icon}</div>}
      {children}
    </div>
  </BootstrapButton>
);

export const PrimaryButton = (props: DCSButtonProps): JSX.Element => {
  const { colour, className } = props;
  return <DCSButton {...props} variant={colour || 'primary'} className={classNames('primaryButton', className)} />;
};

export const SecondaryButton = (props: DCSButtonProps): JSX.Element => {
  const { colour, className } = props;
  return (
    <DCSButton
      {...props}
      variant={`outline-${colour || 'primary'}`}
      className={classNames('secondaryButton', className)}
    />
  );
};

export const TertiaryButton = (props: DCSButtonProps): JSX.Element => {
  const { colour, className } = props;
  return (
    <DCSButton
      {...props}
      variant={`subtle-${colour || 'primary'}`}
      className={classNames('tertiaryButton', className)}
    />
  );
};
