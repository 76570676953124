import { createContext } from 'react';
import { CurrentUser, User } from '../Api/AccountApi';
import { Role, UserRole } from '../Api/Enums';
import { ReactStateSetter } from '../Helpers/TypeHelpers';

export const userHasRole = (user: User | null, role: Role) => !!user && user.roles.includes(role);

export class Auth {
  user: User | null;
  setCurrentUser: ReactStateSetter<CurrentUser>;

  constructor(currentUser: CurrentUser, setCurrentUser: ReactStateSetter<CurrentUser>) {
    this.user = currentUser.user && {
      ...currentUser.user,
      roles: [...currentUser.user.roles, ...(currentUser.isLoggedIn ? [UserRole.LoggedIn] : [])],
    };
    this.setCurrentUser = setCurrentUser;
  }

  hasRole = (role: Role) => userHasRole(this.user, role);
  hasOneOfRoles = (...roles: Role[]) => roles.some((r) => userHasRole(this.user, r));
  userCanAccessSurvey = (surveyId: number) =>
    !!this.user && (this.user.surveys.length === 0 || this.user.surveys.includes(surveyId));
}

const AuthContext = createContext<Auth>(new Auth({ isLoggedIn: false, user: null }, () => null));

export default AuthContext;
