import { IconProps } from '@/Components/Icons/Icons';
import '@/Components/Icons/Icons.scss';

export const Check = ({ size = 30, className = 'checkIconGreen', ariaLabel }: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="currentColor"
    className={className}
    viewBox="0 0 16 16"
    aria-label={ariaLabel}
  >
    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
  </svg>
);

export const CheckNrosh = ({ size = 30, ariaLabel }: IconProps): JSX.Element => (
  <Check size={size} className="checkIconNrosh" ariaLabel={ariaLabel} />
);
