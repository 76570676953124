import { RshRole } from 'nrosh-common/Api/Enums';
import {
  RegulatoryDocumentMetadata,
  RegulatoryDocumentMetadataWithStringDate,
  mapRegulatoryDocumentResponse,
} from 'nrosh-common/Api/RegulatoryDocumentsApi';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import { useEndpointConditionallyWithProcessing } from 'nrosh-common/Hooks/useEndpoint';
import { useContext } from 'react';
import { Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { RegulatoryDocumentsApi } from '@/Helpers/Apis';
import { providerDisplayName } from '@/Helpers/ProviderHelper';
import { adminPages, providerPages } from '@/Pages/Home/SitePages';
import RegulatoryDocumentForm from '@/Pages/RegulatoryDocuments/RegulatoryDocumentForm';

const EditRegulatoryDocumentPage = (): JSX.Element => {
  const { documentId } = useParams();
  const [document] = useEndpointConditionallyWithProcessing<
    RegulatoryDocumentMetadataWithStringDate,
    RegulatoryDocumentMetadata
  >(RegulatoryDocumentsApi.getMetadata, true, mapRegulatoryDocumentResponse, documentId);
  const auth = useContext(AuthContext);

  if (!document) {
    return <LoadingMessage />;
  }

  const isAdmin = auth.hasRole(RshRole.User)!;
  const currentPath = isAdmin ? adminPages.EditRegulatoryDocument.path : providerPages.EditRegulatoryDocument.path;
  const breadcrumbsRootPathType = isAdmin
    ? RootPathType.AdminRegulatoryDocuments
    : RootPathType.ProviderRegulatoryDocuments;

  return (
    <Stack gap={5}>
      <PageHeader
        heading="Edit regulatory document"
        subheading={`${providerDisplayName(document.providerName, document.providerNumber)}`}
        crumbsType={breadcrumbsRootPathType}
        crumbs={[
          {
            name: 'Edit regulatory document',
            path: currentPath,
          },
        ]}
      />
      <RegulatoryDocumentForm initialDocument={document} />
    </Stack>
  );
};

export default EditRegulatoryDocumentPage;
