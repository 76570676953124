import { EmailTemplateResponse } from 'nrosh-common/Api/EmailsApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import React from 'react';
import { Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { EmailsApi } from '@/Helpers/Apis';
import { UpdateEmailTemplateForm } from '@/Pages/Emails/Automated/UpdateEmailTemplateForm';
import { emailTemplateTypeNames } from '@/Pages/Emails/EmailTypes';
import { adminPages } from '@/Pages/Home/SitePages';

export const UpdateEmailTemplatePage = (): JSX.Element => {
  const { templateType } = useParams();
  const [emailTemplate] = useEndpoint<EmailTemplateResponse>(EmailsApi.getEmailTemplate, templateType!);

  if (!emailTemplate) {
    return <LoadingMessage />;
  }

  return (
    <Stack gap={5}>
      <PageHeader
        heading={emailTemplateTypeNames[emailTemplate.templateType]}
        crumbsType={RootPathType.AdminEmails}
        crumbs={[
          {
            name: 'Automated emails',
            path: adminPages.AdminAutomatedEmails.path,
          },
          { name: emailTemplateTypeNames[emailTemplate.templateType], path: adminPages.AdminUpdateEmailTemplate.path },
        ]}
      />
      <UpdateEmailTemplateForm
        templateType={emailTemplate.templateType}
        initialSubject={emailTemplate.templateSubject}
        initialBody={emailTemplate.templateText}
      />
    </Stack>
  );
};
