import { AccountChangeType, UserAuditLog, UserAuditLogsResponse } from 'nrosh-common/Api/AuditLogsApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { Stack } from 'react-bootstrap';
import { Column } from 'react-table';
import UserAuditLogsForm from '@/Components/AuditLogs/UserAuditLogsForm';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { AuditLogsApi } from '@/Helpers/Apis';
import '@/Pages/AuditLogs/UserAuditLogsPage.scss';
import { TimeResolution, formatISODateString } from '@/Helpers/DateHelpers';
import { adminPages } from '@/Pages/Home/SitePages';

export const AccountChangeTypeMapping: Record<AccountChangeType, string> = {
  AccountCreated: 'Account Created',
  AccountDisabled: 'Account Disabled',
  AccountDeletedManual: 'Account Deleted: Manual',
  AccountDeletedInactivity: 'Account Deleted: Inactivity',
  AccountLockedInactivity: 'Account Locked: Inactivity',
  AccountLockedFailedLogin: 'Account Locked: Failed Login',
  AccountUnlocked: 'Account Unlocked',
};

const UserAuditLogsPage = (): JSX.Element => {
  const [userAuditLogsResponse] = useEndpoint<UserAuditLogsResponse>(AuditLogsApi.getUserAuditLogs);

  const headers: Column<UserAuditLog>[] = [
    {
      Header: 'Date of Change',
      accessor: 'dateOfChange',
      Cell: ({ value }) => formatISODateString(value, TimeResolution.HundredthSecond),
      width: 200,
      disableSortBy: true,
    },
    {
      Header: 'User Name',
      accessor: 'userName',
      disableSortBy: true,
    },
    {
      Header: 'Provider Number',
      accessor: 'providerNumber',
      disableSortBy: true,
    },
    {
      Header: 'Provider Name',
      accessor: 'providerName',
      disableSortBy: true,
    },
    {
      Header: 'Change Type',
      accessor: 'changeType',
      Cell: ({ value }) => value && AccountChangeTypeMapping[value as AccountChangeType],
      width: 250,
      disableResizing: true,
      disableSortBy: true,
    },
    {
      Header: 'Change By',
      accessor: 'changedByUserName',
      disableSortBy: true,
    },
  ];

  if (!userAuditLogsResponse) {
    return <LoadingMessage />;
  }

  return (
    <Stack gap={5}>
      <PageHeader
        heading="User audit"
        crumbsType={RootPathType.AdminAudits}
        crumbs={[
          {
            name: 'User audit',
            path: adminPages.AdminUserAudit.path,
          },
        ]}
      />
      <UserAuditLogsForm userAuditLogsResponse={userAuditLogsResponse} headers={headers} />
    </Stack>
  );
};

export default UserAuditLogsPage;
