import Spinner from 'react-bootstrap/Spinner';

type Props = {
  smallSpinner?: boolean;
};

export const LoadingSpinner = (props: Props): JSX.Element => {
  const { smallSpinner } = props;
  return (
    <Spinner animation="border" role="status" size={smallSpinner ? 'sm' : undefined}>
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
};
