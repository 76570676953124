import { Stack } from 'react-bootstrap';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { PublicDocumentsApi } from '@/Helpers/Apis';
import PublicDocumentForm from '@/Pages/Content/PublicDocuments/PublicDocumentForm';
import { adminPages } from '@/Pages/Home/SitePages';

const UploadPublicDocumentPage = (): JSX.Element => (
  <Stack gap={5}>
    <PageHeader
      heading="Upload document"
      crumbsType={RootPathType.AdminContent}
      crumbs={[
        {
          name: 'Manage documents',
          path: adminPages.AdminPublicDocuments.path,
        },
        {
          name: 'Upload document',
          path: adminPages.AdminUploadPublicDocument.path,
        },
      ]}
    />
    <PublicDocumentForm submitButtonMessage="Upload" submitAction={(formData) => PublicDocumentsApi.upload(formData)} />
  </Stack>
);

export default UploadPublicDocumentPage;
