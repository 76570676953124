import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import { StepIconProps } from '@mui/material/StepIcon';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import '@/Components/ProgressStepper/ProgressStepper.scss';
import { SubmissionStatus } from 'nrosh-common/Api/Enums';
import { Check } from '@/Components/Icons/CheckIcon';
import { Circle } from '@/Components/Icons/Circle';

type Props = {
  submissionStatus: SubmissionStatus;
};

const stepNumbers = {
  [SubmissionStatus.NotStarted]: 1,
  [SubmissionStatus.InProgress]: 2,
  [SubmissionStatus.Unsubmitted]: 2,
  [SubmissionStatus.Submitted]: 3,
  [SubmissionStatus.SignedOff]: 4,
};

const StepIcon = (props: StepIconProps): JSX.Element => {
  const { completed, className } = props;
  return completed ? (
    <Check ariaLabel="Checked" className={className} />
  ) : (
    <Circle className="circleIcon" ariaLabel="Unchecked" />
  );
};

const ProgressStepper: (props: Props) => JSX.Element = ({ submissionStatus }) => {
  const steps = [
    { label: 'Not Started', className: 'checkIconGreen' },
    submissionStatus === SubmissionStatus.Unsubmitted
      ? { label: 'Unsubmitted', className: 'checkIconYellow' }
      : { label: 'Started', className: 'checkIconGreen' },
    { label: 'Submitted', className: 'checkIconGreen' },
    { label: 'Signed-off', className: 'checkIconGreen' },
  ];

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={stepNumbers[submissionStatus]} alternativeLabel>
        {steps.map(({ label, className }) => (
          <Step key={label}>
            <StepLabel StepIconComponent={StepIcon} StepIconProps={{ className }}>
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default ProgressStepper;
