import { Cell, HeaderGroup, Meta, TableCellProps, TableHeaderProps } from 'react-table';
import { Alignment, NroshColumn } from '@/Components/Table/Table';

const alignmentToJustifyContent = (align: Alignment): string => {
  switch (align) {
    case 'center':
      return 'center';
    case 'right':
      return 'flex-end';
    case 'left':
    default:
      return 'flex-start';
  }
};

const generateHeaderStyles = (align: Alignment): Partial<TableHeaderProps> => ({
  style: {
    justifyContent: alignmentToJustifyContent(align),
  },
});

const generateCellStyles = (align: Alignment): Partial<TableCellProps> => ({
  style: {
    textAlign: align,
  },
});

export const combinedHeaderProps = <TData extends object>(
  props: Partial<TableHeaderProps>,
  meta: Meta<TData, { column: HeaderGroup<TData> }>,
): Partial<TableHeaderProps>[] => [
  props,
  generateHeaderStyles((meta.column as NroshColumn<TData>).align ?? 'left'),
  meta.column.getSortByToggleProps(),
];

export const combinedCellProps = <TData extends object>(
  props: Partial<TableCellProps>,
  meta: Meta<TData, { cell: Cell<TData> }>,
): Partial<TableCellProps>[] => [props, generateCellStyles((meta.cell.column as NroshColumn<TData>).align ?? 'left')];
