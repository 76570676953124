import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { loginPages } from '@/Pages/Home/SitePages';

type Props = {
  className?: string;
};

const LoginLink = ({ className }: Props): JSX.Element => (
  <Nav.Link className={className} as={Link} to={loginPages.PublicLogin.path}>
    Log In
  </Nav.Link>
);

export default LoginLink;
