import { useEffect, useState } from 'react';
import { ReactStateSetter } from '../Helpers/TypeHelpers';
import { StandardErrorResponse, UnhandledIntermediateFetchResult } from '../Api/ApiClient';

type UseEndpointOutput<TOut extends {}, TError> = [
  TOut | null,
  ReactStateSetter<TOut | null>,
  StandardErrorResponse<TError> | null,
  boolean,
];
export const useEndpointConditionallyWithProcessing = <TResponse, TOut extends {}, TError = string>(
  endpoint: (...endpointArgs: any) => UnhandledIntermediateFetchResult<TResponse, TError>,
  condition: boolean,
  map: (response: TResponse) => TOut,
  ...hookArgs: any[]
): UseEndpointOutput<TOut, TError> => {
  const [data, setData] = useState<TOut | null>(null);
  const [error, setError] = useState<StandardErrorResponse<TError> | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      let responseData = await endpoint(...hookArgs).raw;
      if (responseData.status === 'succeeded') {
        const value = await responseData.value;
        const initialData = map(value);
        setData(initialData);
        setError(null);
      } else {
        const error = responseData.value;
        setError(error);

        setData(null);
      }
      setLoading(false);
    };
    if (condition) {
      fetchData().catch();
    }
  }, [endpoint, condition, map, ...hookArgs]);
  return [data, setData, error, loading];
};

const identityMap = <T>(_: T) => _;

export const useEndpointConditionally = <T extends {}, TError = string>(
  endpoint: (...endpointArgs: any) => UnhandledIntermediateFetchResult<T, TError>,
  condition: boolean,
  ...hookArgs: any[]
): UseEndpointOutput<T, TError> =>
  useEndpointConditionallyWithProcessing<T, T, TError>(endpoint, condition, identityMap, ...hookArgs);

const useEndpoint = <T extends {}, TError = string>(
  endpoint: (...endpointArgs: any) => UnhandledIntermediateFetchResult<T, TError>,
  ...hookArgs: any[]
): UseEndpointOutput<T, TError> => useEndpointConditionally<T, TError>(endpoint, true, ...hookArgs);

export default useEndpoint;
