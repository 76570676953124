import { Checkbox } from '@mui/material';
import { RshRole, ValidationType } from 'nrosh-common/Api/Enums';
import { SurveyStatus } from 'nrosh-common/Api/SubmissionsApi';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import { useContext } from 'react';
import { useValidationModel } from '@/Components/Validations/ValidationAccordionItem';
import { useValidationsAccordionContext } from '@/Components/Validations/Validations';

export const ValidationApprovalToggleRow = (): JSX.Element | null => {
  const { validationId, isApproved, isPassing, type: validationType } = useValidationModel();
  const { updateValidationApprovalStatus, surveyStatus } = useValidationsAccordionContext();
  const auth = useContext(AuthContext);

  const approvalIsEditable =
    validationType === ValidationType.Hard &&
    auth.hasRole(RshRole.ApproveValidations) &&
    updateValidationApprovalStatus !== undefined;

  const validationIsFailingAndApproved = isApproved && !isPassing;

  if (approvalIsEditable) {
    return (
      <tr>
        <th id="checkboxLabel">Allow Validation to Fail</th>
        <td>
          <Checkbox
            aria-labelledby="checkboxLabel"
            color="nrosh"
            checked={isApproved}
            onChange={(event) => {
              const { checked } = event.target;
              updateValidationApprovalStatus(validationId, checked);
            }}
            disabled={surveyStatus === SurveyStatus.Closed}
          />
        </td>
      </tr>
    );
  }

  if (validationIsFailingAndApproved) {
    return (
      <tr>
        <th>
          <span>Validation Status</span>
        </th>
        <td>
          <span>Accepted by DCS</span>
        </td>
      </tr>
    );
  }

  return null;
};
