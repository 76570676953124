import { RshRole } from 'nrosh-common/Api/Enums';
import { Stack } from 'react-bootstrap';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import LandingPage from '@/Components/LandingPage/LandingPage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { adminPages } from '@/Pages/Home/SitePages';

const EmailsLandingPage = (): JSX.Element => (
  <Stack gap={5}>
    <PageHeader heading="Emails" crumbsType={RootPathType.AdminEmails} />
    <LandingPage
      description="Manage automated and manually-sent emails"
      pages={[
        { name: 'Manual emails', path: adminPages.AdminManualEmails.path, roles: [RshRole.ViewSentEmails] },
        {
          name: 'Manage automated emails',
          path: adminPages.AdminAutomatedEmails.path,
          roles: [RshRole.SendEmails],
        },
        {
          name: 'Manage survey reminders',
          path: adminPages.AdminManageSurveyReminderEmails.path,
          roles: [RshRole.ViewSurveys],
        },
        { name: 'Edit email footer', path: adminPages.AdminEmailFooter.path, roles: [RshRole.SendEmails] },
      ]}
    />
  </Stack>
);

export default EmailsLandingPage;
