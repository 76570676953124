import { ScheduledManualEmailSummaryResponse } from 'nrosh-common/Api/EmailsApi';
import { RshRole } from 'nrosh-common/Api/Enums';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { useContext, useState } from 'react';
import { Stack } from 'react-bootstrap';
import { generatePath, useNavigate } from 'react-router-dom';
import '@/Pages/Emails/Manual/ScheduledEmailPage.scss';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { LoadingSpinner } from '@/Components/Loading/LoadingSpinner';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { EmailsApi, placeHolderAPIErrorHandler } from '@/Helpers/Apis';
import { ScheduledEmailTable } from '@/Pages/Emails/Manual/ScheduledEmailTable';
import { adminPages } from '@/Pages/Home/SitePages';

export const ScheduledEmailPage = (): JSX.Element => {
  const [scheduledEmails] = useEndpoint<ScheduledManualEmailSummaryResponse[]>(EmailsApi.getScheduledManualEmails);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const auth = useContext(AuthContext);

  if (!scheduledEmails) {
    return <LoadingSpinner />;
  }

  const createEmail = async (): Promise<void> => {
    setIsLoading(true);
    return EmailsApi.createScheduledEmail().withHandlers((id) => {
      const emailId = id.toString();
      setIsLoading(false);
      return navigate(generatePath(adminPages.AdminViewOrEditEmail.path, { emailId }));
    }, placeHolderAPIErrorHandler);
  };

  const canSendEmails = auth.hasRole(RshRole.SendEmails);

  return (
    <Stack gap={5}>
      <PageHeader
        heading="Manual emails"
        crumbsType={RootPathType.AdminEmails}
        crumbs={[
          {
            name: 'Manual emails',
            path: adminPages.AdminManualEmails.path,
          },
        ]}
      />
      {canSendEmails &&
        (isLoading ? (
          <LoadingButton className="compose-email-button" message="Loading..." />
        ) : (
          <PrimaryButton className="compose-email-button" onClick={createEmail}>
            Compose Email
          </PrimaryButton>
        ))}
      <ScheduledEmailTable scheduledManualEmails={scheduledEmails} canEdit={canSendEmails ?? false} />
    </Stack>
  );
};
