import { EditorEvents } from '@tiptap/core';
import Placeholder from '@tiptap/extension-placeholder';
import { EditorContent, HTMLContent, useEditor } from '@tiptap/react';
import { contentEditorExtensions } from '@/Components/ContentEditor/ContentEditorExtensions';
import MenuBar from '@/Components/ContentEditor/MenuBar';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import '@/Components/ContentEditor/ContentEditor.scss';
import { TemplateField, TemplateFieldGroup } from '@/Pages/Emails/EmailTemplates';

export type ContentEditorProps = {
  content: HTMLContent;
  setContent: (content: HTMLContent) => void;
  setDirty: () => void;
  placeholderText?: string;
  isInvalid?: boolean;
  hasH1Tag?: boolean;
  templateFieldGroups?: TemplateFieldGroup[];
  requiredTemplateFields?: TemplateField[];
  ariaLabel: string;
};

const ContentEditor = (props: ContentEditorProps): JSX.Element => {
  const {
    content,
    setContent,
    setDirty,
    placeholderText,
    isInvalid,
    hasH1Tag,
    templateFieldGroups,
    requiredTemplateFields,
    ariaLabel,
  } = props;

  const onUpdate = ({ editor }: EditorEvents['update']): void => {
    setContent(editor.getHTML());
    setDirty();
  };

  const editor = useEditor({
    editorProps: { attributes: { class: 'contentTable' } },
    extensions: [...contentEditorExtensions, Placeholder.configure({ placeholder: placeholderText })],
    content,
    onUpdate,
  });

  if (!editor) {
    return <LoadingMessage />;
  }

  return (
    <div className={isInvalid ? 'invalidEditor' : 'editor'} aria-invalid={isInvalid}>
      <MenuBar
        editor={editor}
        hasH1Tag={hasH1Tag}
        templateFieldGroups={templateFieldGroups}
        requiredTemplateFields={requiredTemplateFields}
      />
      <EditorContent editor={editor} aria-label={ariaLabel} />
    </div>
  );
};

export default ContentEditor;
