import { ProviderRole, RshRole, SubmissionStatus } from 'nrosh-common/Api/Enums';
import { SubmissionDetail, SurveyStatus } from 'nrosh-common/Api/SubmissionsApi';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import { useContext } from 'react';
import { Stack } from 'react-bootstrap';
import { createSearchParams, generatePath, useParams } from 'react-router-dom';
import '@/Pages/Submissions/SubmissionsDetailPage.scss';
import PageHeadingWithSurveyStatusBadge from '@/Components/Badges/PageHeadingWithSurveyStatusBadge';
import { SubmissionBreadcrumbs } from '@/Components/Breadcrumbs/SubmissionBreadcrumbs';
import { TertiaryLinkButton } from '@/Components/Buttons/TertiaryLinkButton';
import { DCSErrorBoundary, DCSErrorContextOutlet } from '@/Components/Errors/DCSErrorBoundary';
import FormLineBreak from '@/Components/Form/FromLineBreak';
import DocumentPerson from '@/Components/Icons/DocumentPerson';
import History from '@/Components/Icons/History';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import ToastWithIcon, { ToastType } from '@/Components/ToastWithIcon/ToastWithIcon';
import { surveyDisplayName } from '@/Helpers/SurveyHelper';
import useSubmissionDetail from '@/Hooks/useSubmissionDetail';
import { adminPages, providerPages, submissionPages } from '@/Pages/Home/SitePages';
import SupportingDocumentsSection from '@/Pages/Submissions/Documents/SupportingDocumentsSection';
import SubmissionDescription from '@/Pages/Submissions/SubmissionDescription';
import { canBeSubmitted, submissionIsReadonly } from '@/Pages/Submissions/SubmissionHelpers';
import SubmissionsDetailPartCard from '@/Pages/Submissions/SubmissionsDetailPartCard';
import ValidationsSection from '@/Pages/Submissions/ValidationsSection';

const UploadBulkImportFileButton = ({ isAdmin }: { isAdmin: boolean }): JSX.Element => {
  const { submissionId, surveyId, timePeriodId } = useParams();

  const uploadBulkImportFilePath = isAdmin
    ? generatePath(submissionPages.AdminBulkImportOptions.path, { surveyId, submissionId, timePeriodId })
    : generatePath(providerPages.ProviderBulkImportOptions.path, { submissionId });

  return <TertiaryLinkButton icon={<DocumentPerson />} to={uploadBulkImportFilePath} text="Upload bulk import file" />;
};

const SubmissionsDetailPageHeader = (props: {
  isAdmin: boolean;
  data: SubmissionDetail;
  surveyId: string;
  timePeriodId: string;
  noErrorOutlet?: boolean;
}): JSX.Element => {
  const { isAdmin, data, surveyId, timePeriodId, noErrorOutlet } = props;

  return (
    <Stack gap={5}>
      <SubmissionBreadcrumbs
        isRshUser={isAdmin}
        surveyDisplayName={surveyDisplayName(data.surveyName, data.timePeriodName)}
        providerName={data.provider.name}
        additionalRshCrumbs={[]}
        additionalProviderCrumbs={[]}
      />
      {isAdmin ? (
        <div className="d-flex justify-content-between">
          <div className="d-grid gap-3">
            <h1>{data.provider.name}</h1>
            <h2>
              {data.surveyName} &#8212; {data.timePeriodName}
            </h2>
          </div>
          <TertiaryLinkButton
            icon={<History />}
            text="View audit trail"
            to={{
              pathname: adminPages.AdminDetailedAuditTrail.path,
              search: createSearchParams({
                providerId: data.provider.id.toString(),
                surveyId,
                timePeriodId,
              }).toString(),
            }}
          />
        </div>
      ) : (
        <PageHeadingWithSurveyStatusBadge
          title={surveyDisplayName(data.surveyName, data.timePeriodName)}
          status={data.surveyStatus}
        />
      )}
      {!noErrorOutlet && <DCSErrorContextOutlet />}
    </Stack>
  );
};

const SubmissionsDetailPage = ({ isAdmin }: { isAdmin: boolean }): JSX.Element => {
  const { submissionId, surveyId, timePeriodId } = useParams();
  const [data, setData] = useSubmissionDetail(submissionId!);
  const auth = useContext(AuthContext);

  const readonly =
    !data ||
    submissionIsReadonly(data.status, isAdmin, data.surveyStatus) ||
    !auth.hasOneOfRoles(RshRole.EditSurveyData, ProviderRole.EditSurveyData);

  if (!data) {
    return <LoadingMessage />;
  }

  const surveyIsEditable =
    data.status === SubmissionStatus.NotStarted || canBeSubmitted(data.status, data.surveyStatus);

  const getToastText = (surveyStatus: SurveyStatus, submissionStatus: SubmissionStatus): string | null => {
    if (surveyStatus === SurveyStatus.Closed) {
      return 'This survey is closed. You can no longer submit the survey or edit the answers.';
    }
    if (submissionStatus === SubmissionStatus.Submitted) {
      return isAdmin
        ? 'The Provider has submitted the survey. You can now sign off or reject the submission.'
        : 'Survey successfully submitted. Please wait for sign off.';
    }
    if (submissionStatus === SubmissionStatus.SignedOff) {
      return isAdmin ? 'This survey has been signed off and cannot be edited.' : 'Survey has been signed off.';
    }
    if (data.deadline < new Date() && !isAdmin) {
      return 'This survey is overdue. If it is still open, you may be able to submit the survey.';
    }
    return null;
  };

  const getToastType = (surveyStatus: SurveyStatus, submissionStatus: SubmissionStatus): ToastType => {
    if (isAdmin || surveyStatus === SurveyStatus.Closed) {
      return ToastType.Info;
    }

    if (submissionStatus === SubmissionStatus.Submitted || submissionStatus === SubmissionStatus.SignedOff) {
      return ToastType.Success;
    }

    if (data.deadline < new Date()) {
      return ToastType.Warning;
    }

    return ToastType.Info;
  };

  const toastText = getToastText(data.surveyStatus, data.status);

  return (
    <Stack gap={5} className="submissionsContainer m-0 p-0">
      <SubmissionsDetailPageHeader
        isAdmin={isAdmin}
        data={data}
        surveyId={surveyId!}
        timePeriodId={timePeriodId!}
        noErrorOutlet
      />
      {toastText && <ToastWithIcon type={getToastType(data.surveyStatus, data.status)} text={toastText} />}
      {data.description && <p className="description p-0 m-0">{data.description}</p>}
      <SubmissionDescription
        initialDeadline={data.deadline}
        submissionStatus={data.status}
        mostRecentSubmission={data.lastSubmissionDate}
        signOffDate={data.signOffDate}
        isAdmin={isAdmin}
        surveyStatus={data.surveyStatus}
        hasHardValidationIssues={data.parts.some(({ hardValidationIssues }) => hardValidationIssues)}
        setSubmissionStatus={(status) => setData((s) => ({ ...s, status }) as SubmissionDetail)}
      />
      {(auth.hasRole(ProviderRole.ViewSurveyData) ||
        (auth.hasRole(RshRole.ViewSurveyData) && auth.userCanAccessSurvey(Number(surveyId)))) && (
        <Stack gap={4}>
          <Stack gap={4}>
            <DCSErrorContextOutlet />
            <Stack gap={3}>
              <h2>Survey parts</h2>
              {!readonly && (
                <div>There cannot be hard validation issues in any of the parts in order to submit this survey.</div>
              )}
            </Stack>
            {data.parts.map((part) => (
              <SubmissionsDetailPartCard
                partData={part}
                isAdmin={isAdmin}
                readonly={readonly}
                submissionId={submissionId}
                surveyId={surveyId}
                key={`${surveyId}-${submissionId}-${timePeriodId}-${part.id}`}
                timePeriodId={timePeriodId}
              />
            ))}
          </Stack>
          {!readonly && surveyIsEditable && auth.hasOneOfRoles(RshRole.EditSurveyData, ProviderRole.EditSurveyData) && (
            <Stack gap={3}>
              <p className="p-0 m-0">
                To upload data for all parts at once use the &#39;Upload bulk import file&#39; button below.
              </p>
              <UploadBulkImportFileButton isAdmin={isAdmin} />
            </Stack>
          )}
          <FormLineBreak />
          {submissionId && (
            <Stack gap={5}>
              <DCSErrorBoundary>
                <ValidationsSection
                  surveyName={data.surveyName}
                  timePeriodName={data.timePeriodName}
                  isAdmin={isAdmin}
                />
              </DCSErrorBoundary>
              <FormLineBreak />
            </Stack>
          )}
          <div>
            <DCSErrorBoundary>
              <SupportingDocumentsSection
                isRshUser={isAdmin}
                documents={data.supportingDocuments}
                readonly={data.status === SubmissionStatus.SignedOff || data.surveyStatus === SurveyStatus.Closed}
              />
            </DCSErrorBoundary>
          </div>
        </Stack>
      )}
    </Stack>
  );
};

export default SubmissionsDetailPage;
