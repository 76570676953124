import { User } from 'nrosh-common/Api/AccountApi';
import { ProviderRole, RshRole } from 'nrosh-common/Api/Enums';
import AuthContext, { userHasRole } from 'nrosh-common/Contexts/AuthContext';
import { ChangeEvent, FormEvent, useContext, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import { AccessibleFeedback } from '@/Components/Form/AccessibleFeedback';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { AccountApi, generateResponseErrorMessage } from '@/Helpers/Apis';
import { getValidityProps } from '@/Helpers/Forms';
import { loginPages, providerPages, publicPages, surveyPages } from '@/Pages/Home/SitePages';
import '@/Pages/Home/LoginForm.scss';

export const splashPage = (user: User | null): string => {
  if (userHasRole(user, RshRole.User)) {
    return surveyPages.Surveys.path;
  }
  if (userHasRole(user, ProviderRole.User)) {
    return providerPages.SubmissionsList.path;
  }
  return publicPages.PublicHome.path;
};

const LoginForm = (): JSX.Element | null => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [validated, setValidated] = useState<boolean>(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const onUserNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setUserName(e.target.value);
    setError(null);
  };

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setPassword(e.target.value);
    setError(null);
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    setError(null);
    event.preventDefault(); // Stops the page from reloading
    if (userName === '' || password === '') {
      setValidated(true);
      return;
    }

    setSubmitting(true);
    event.preventDefault(); // Stops the page from reloading
    const response = await AccountApi.login({ userName, password }).raw;
    setSubmitting(false);
    if (response.ok) {
      const userBody = response.value.user;
      auth.setCurrentUser({ isLoggedIn: true, user: userBody });
      navigate(splashPage(userBody));
    } else {
      const errorResponse = response.value;
      setError(generateResponseErrorMessage(errorResponse, (d) => d.error ?? null));
    }
  };

  if (auth.user) return null;

  return (
    <Form onSubmit={onSubmit} className="d-flex flex-column loginForm">
      {error && <Alert variant="danger">{error}</Alert>}
      <Form.Group className="mb-3" controlId="userName">
        <Form.Label>Username</Form.Label>
        <Form.Control
          value={userName}
          onChange={onUserNameChange}
          {...getValidityProps(validated && userName === '', 'username-feedback')}
        />
        <AccessibleFeedback id="username-feedback" displayFeedback={validated && userName === ''}>
          You must provide a username
        </AccessibleFeedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="password">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          value={password}
          onChange={onPasswordChange}
          {...getValidityProps(validated && password === '', 'password-feedback')}
        />
        <AccessibleFeedback id="password-feedback" displayFeedback={validated && password === ''}>
          You must provide a password
        </AccessibleFeedback>
      </Form.Group>
      <div className="ms-auto d-flex flex-column align-items-end">
        <Link className="d-block mb-3 forgotPasswordLink" to={loginPages.PublicForgotPassword.path}>
          Forgot password?
        </Link>
        {!isSubmitting ? (
          <PrimaryButton type="submit" data-testid="submit-button">
            Log In
          </PrimaryButton>
        ) : (
          <LoadingButton message="Log In" className="me-0" data-testid="loading-button" />
        )}
      </div>
    </Form>
  );
};

export default LoginForm;
