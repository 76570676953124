import { EmailTemplateResponse, EmailTemplateType } from 'nrosh-common/Api/EmailsApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { Stack } from 'react-bootstrap';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { EmailsApi } from '@/Helpers/Apis';
import { EmailTemplatesTable } from '@/Pages/Emails/Automated/EmailTemplatesTable';
import '@/Pages/Emails/EmailStyles.scss';
import { emailTemplateTypeNames } from '@/Pages/Emails/EmailTypes';
import { adminPages } from '@/Pages/Home/SitePages';

export const AutomatedEmailsPage = (): JSX.Element => {
  const [emailTemplates] = useEndpoint<EmailTemplateResponse[]>(EmailsApi.getEmailTemplates);

  if (!emailTemplates) {
    return <LoadingMessage />;
  }

  const filteredTemplates = emailTemplates
    .filter((et) => et.templateType !== EmailTemplateType.Footer)
    .sort((et1, et2) =>
      emailTemplateTypeNames[et1.templateType].localeCompare(emailTemplateTypeNames[et2.templateType]),
    );

  return (
    <Stack gap={5}>
      <PageHeader
        heading="Automated emails"
        crumbsType={RootPathType.AdminEmails}
        crumbs={[
          {
            name: 'Automated emails',
            path: adminPages.AdminAutomatedEmails.path,
          },
        ]}
      />
      <Stack gap={4}>
        <h2>Configurable templates</h2>
        <EmailTemplatesTable emailTemplates={filteredTemplates} />
      </Stack>
    </Stack>
  );
};
