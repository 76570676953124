import { Role } from 'nrosh-common/Api/Enums';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { publicPages } from '@/Pages/Home/SitePages';

type RequireRoleProps = { children: JSX.Element; requireOneOfRoles: Role[] };

const RequireRole = (props: RequireRoleProps): JSX.Element => {
  const auth = useContext(AuthContext);
  const { requireOneOfRoles, children } = props;
  return auth.hasOneOfRoles(...requireOneOfRoles) ? children : <Navigate to={publicPages.PublicHome.path} replace />;
};

export default RequireRole;
