import { SubmissionPartValidation } from 'nrosh-common/Api/SubmissionsApi';
import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import React from 'react';
import { Form } from 'react-bootstrap';
import ValidationIssues from '@/Components/Validations/ValidationIssues';

const ValidationHeadlines = (props: {
  validations: SubmissionPartValidation[];
  showValidations: boolean;
  setShowValidations: ReactStateSetter<boolean>;
}): JSX.Element => {
  const { validations, showValidations, setShowValidations } = props;
  const validationsLabel = showValidations ? 'Hide validations' : 'Show validations';

  return (
    <div className="d-flex pe-5 justify-content-between">
      <ValidationIssues validations={validations} />
      <Form.Check
        className="d-flex align-items-center gap-2"
        type="switch"
        id="show-validations"
        label={validationsLabel}
        checked={showValidations}
        onChange={(e) => setShowValidations(e.target.checked)}
      />
    </div>
  );
};

export default ValidationHeadlines;
