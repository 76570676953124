import { DataDictionaryErrorResponse, DataDictionaryResponse, DataPoint } from 'nrosh-common/Api/SurveyPartsApi';
import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { useEffect, useMemo, useState } from 'react';
import { Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CellProps } from 'react-table';
import { PrimaryButton, SecondaryButton } from '@/Components/Buttons/DCSButton';
import {
  DeleteDataDictionaryButton,
  DownloadDataDictionaryButton,
  UploadDataDictionaryButton,
} from '@/Components/DataDictionaryUpload/DataDictionaryButtons';
import { useErrorReporting } from '@/Components/Errors/DCSErrorBoundary';
import Delete from '@/Components/Icons/Delete';
import Edit from '@/Components/Icons/Edit';
import { LoadingSpinner } from '@/Components/Loading/LoadingSpinner';
import EditableCell from '@/Components/Table/EditableCell';
import Table, { NroshColumn } from '@/Components/Table/Table';
import { SurveyPartsApi } from '@/Helpers/Apis';
import '@/Pages/Surveys/DataDictionaryPage.scss';
import '@/Components/Icons/Icons.scss';

export type DataDictionaryTableProps = {
  initialData: DataPoint[];
  surveyPartId: string | undefined;
  setDataLastUploadedAt: ReactStateSetter<Date | null>;
  setDataDictionaryErrors: ReactStateSetter<DataDictionaryErrorResponse | undefined>;
  setShow: ReactStateSetter<{ [p: string]: boolean }>;
  setShowToast: ReactStateSetter<boolean>;
  setToastText: ReactStateSetter<string>;
  isSurveyClosed: boolean;
};

const buildHeaders = (
  setData: ReactStateSetter<DataPoint[]>,
  surveyPartId: string | undefined,
  setDataPointDeletionsInProgress: ReactStateSetter<Set<string>>,
  dataPointDeletionsInProgress: Set<string>,
  isEditing: boolean,
): NroshColumn<DataPoint>[] => {
  const updateData = (rowIndex: number, columnId: string, value: string): void => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[rowIndex] = {
        ...prevData[rowIndex],
        [columnId]: value,
      };
      return newData;
    });
  };

  const deleteIndividual = async (dataPointId: string): Promise<void> => {
    setDataPointDeletionsInProgress((prevDeletionsInProgress) => new Set(prevDeletionsInProgress).add(dataPointId));
    const response = await SurveyPartsApi.deleteDataPoint(surveyPartId!, dataPointId).raw;
    if (response.ok) {
      setData((prevData) => prevData.filter((dp) => dp.id !== dataPointId));
    }

    setDataPointDeletionsInProgress((prevDeletionsInProgress) => {
      const newSet = new Set(prevDeletionsInProgress);
      newSet.delete(dataPointId);
      return newSet;
    });
  };

  const renderCell = (props: CellProps<DataDictionaryResponse, string>): JSX.Element | string | number => {
    const { cell, row, column } = props;

    if (isEditing) {
      return (
        <EditableCell value={cell.value} row={row} column={column} updateData={updateData} ariaLabel={column.id} />
      );
    }

    if (cell.value !== null) {
      return cell.value.toString();
    }

    return '';
  };

  return [
    {
      Header: 'ID',
      accessor: 'id',
      width: 90,
      Cell: renderCell,
    },
    {
      Header: 'Section',
      accessor: 'section',
      Cell: renderCell,
    },
    {
      Header: 'Question Reference',
      accessor: 'questionReference',
      Cell: renderCell,
    },
    {
      Header: 'Short Name',
      accessor: 'shortName',
      Cell: renderCell,
    },
    {
      Header: 'Type',
      accessor: 'type',
      width: 85,
      Cell: renderCell,
    },
    {
      Header: 'Description',
      accessor: 'description',
      width: 400,
      Cell: renderCell,
    },
    {
      Header: 'Help Text',
      accessor: 'helpTextField',
      width: 200,
      Cell: renderCell,
    },
    {
      Header: 'Dimension 1',
      accessor: 'dimension1',
      Cell: renderCell,
    },
    {
      Header: 'Dimension 2',
      accessor: 'dimension2',
      Cell: renderCell,
    },
    {
      Header: 'Decimal Places',
      accessor: 'decimalPlaces',
      width: 75,
      Cell: renderCell,
    },
    {
      Header: 'Value Restriction',
      accessor: 'valueRestriction',
      Cell: renderCell,
    },
    {
      Header: 'Allow Null',
      accessor: 'allowNull',
      width: 100,
      Cell: renderCell,
    },
    {
      Header: 'Date Of Last Change',
      accessor: 'dateOfLastChange',
      Cell: renderCell,
    },
    {
      Header: 'Changed This Version',
      accessor: 'changedThisVersion',
      width: 100,
      Cell: renderCell,
    },
    {
      Header: 'Delete',
      align: 'center',
      width: 65,
      disableResizing: true,
      accessor: 'inUse',
      Cell: ({ cell: { value, row } }: CellProps<DataDictionaryResponse, string>) =>
        value ? (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                This data point cannot currently be deleted because it is assigned to a cell in this or another instance
                of this survey
              </Tooltip>
            }
          >
            <div data-toggle="tooltip" title="Disabled button tooltip">
              <button className="iconButton tooltipped-disabled-icon-button" type="button" aria-label="Delete" disabled>
                <Delete />
              </button>
            </div>
          </OverlayTrigger>
        ) : (
          <button
            className="iconButton"
            type="button"
            aria-label="Delete"
            onClick={async () => {
              await deleteIndividual(row.values.id as string);
            }}
            disabled={dataPointDeletionsInProgress.has(row.values.id as string)}
          >
            {dataPointDeletionsInProgress.has(row.values.id as string) ? (
              <div className="spinner-container">
                <LoadingSpinner smallSpinner />
              </div>
            ) : (
              <Delete />
            )}
          </button>
        ),
    },
  ];
};

const DataDictionaryTable = ({
  initialData,
  surveyPartId,
  setDataLastUploadedAt,
  setDataDictionaryErrors,
  setShow,
  setShowToast,
  setToastText,
  isSurveyClosed,
}: DataDictionaryTableProps): JSX.Element => {
  const [data, setData] = useState(initialData);
  const [isEditing, setIsEditing] = useState(false);

  const [dataPointDeletionsInProgress, setDataPointDeletionsInProgress] = useState<Set<string>>(new Set());
  const headers = useMemo(
    () => buildHeaders(setData, surveyPartId, setDataPointDeletionsInProgress, dataPointDeletionsInProgress, isEditing),
    [setData, isEditing, surveyPartId, dataPointDeletionsInProgress],
  );

  const [raiseError] = useErrorReporting();

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  const saveDataDictionary = async (): Promise<void> => {
    const response = await SurveyPartsApi.updateDataDictionary(data, surveyPartId!).raw;
    if (response.ok) {
      const dataDictionaryResponse = response.value;
      setDataDictionaryErrors(dataDictionaryResponse);

      if (dataDictionaryResponse.dataDictionaryErrors.length === 0) {
        setIsEditing(false);
      }

      dataDictionaryResponse.dataDictionaryErrors.map((e, i): void =>
        setShow((prev) => ({
          ...prev,
          [`item: ${i}`]: true,
        })),
      );
      setDataLastUploadedAt(new Date());
      setShowToast(dataDictionaryResponse.dataDictionaryErrors.length === 0);
      setToastText('Changes to data dictionary saved successfully');
    } else {
      raiseError(response.value.message);
    }
  };

  const onCancel = (): void => {
    setIsEditing(false);
    setData(initialData);
  };

  const onSuccessfulBulkDelete = (): void => {
    setData(data.filter((dp) => dp.inUse));
  };

  return (
    <Container className="p-0 m-0" fluid>
      {!isSurveyClosed && (
        <SecondaryButton
          id="editButton"
          className="mb-3"
          type="button"
          disabled={isEditing}
          onClick={() => {
            setIsEditing(true);
            setShowToast(false);
          }}
        >
          <div className="d-flex align-items-center justify-content-center gap-2">
            Edit Data
            <Edit />
          </div>
        </SecondaryButton>
      )}
      <Table
        className="data-dictionary-table"
        data={data}
        columns={headers}
        paginated
        // An alternative export button is provided
        exportable={false}
        rowHeadingIndex={1}
        autoResetPage={false}
      />
      {!isEditing ? (
        <>
          <DownloadDataDictionaryButton
            text="Export data dictionary"
            surveyPartId={surveyPartId!}
            Button={SecondaryButton}
          />
          {!isSurveyClosed && (
            <>
              <UploadDataDictionaryButton
                surveyPartId={surveyPartId!}
                setDataLastUploadedAt={setDataLastUploadedAt}
                setDataDictionaryErrors={setDataDictionaryErrors}
                setShow={setShow}
                setShowToast={setShowToast}
                setToastText={setToastText}
                Button={SecondaryButton}
              />
              <DeleteDataDictionaryButton
                surveyPartId={surveyPartId!}
                onSuccessfulBulkDelete={onSuccessfulBulkDelete}
                numUnusedDataPoints={data.filter((dp) => !dp.inUse).length}
                setShowToast={setShowToast}
                setToastText={setToastText}
              />
            </>
          )}
        </>
      ) : (
        <div className="d-flex gap-2">
          <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
          <PrimaryButton type="button" onClick={saveDataDictionary}>
            Save
          </PrimaryButton>
        </div>
      )}
    </Container>
  );
};

export default DataDictionaryTable;
