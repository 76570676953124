import {
  EmailFileObjectType,
  EmailRecipientResponse,
  ScheduledEmailRequest,
  ScheduledEmailResponse,
  ScheduledEmailStatus,
} from 'nrosh-common/Api/EmailsApi';
import { RshRole } from 'nrosh-common/Api/Enums';
import { ProviderSummary } from 'nrosh-common/Api/ProvidersApi';
import { Surveys } from 'nrosh-common/Api/SurveysApi';
import { UserProfile } from 'nrosh-common/Api/UsersApi';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import useEndpoint, { useEndpointConditionally } from 'nrosh-common/Hooks/useEndpoint';
import { useContext, useState } from 'react';
import { Alert, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import ContentDisplay from '@/Components/ContentDisplay/ContentDisplay';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import { useModal } from '@/Components/Modal/ModalProvider';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { EmailsApi, ProvidersApi, SurveysApi, UsersApi } from '@/Helpers/Apis';
import '@/Pages/Emails/EmailStyles.scss';
import { EmailRecipientsView } from '@/Pages/Emails/Manual/EmailRecipientsView';
import { adminPages } from '@/Pages/Home/SitePages';

type ViewEmailPageProps = {
  emailId: string;
  emailData: ScheduledEmailResponse;
};

export const ViewEmailPage = ({ emailId, emailData }: ViewEmailPageProps): JSX.Element => {
  const [surveyList] = useEndpoint<Surveys>(SurveysApi.getSurveys);
  const [profileList] = useEndpoint<UserProfile[]>(UsersApi.getProviderProfiles);
  const [providerList] = useEndpoint<ProviderSummary[]>(ProvidersApi.getProviders);

  const [isCancellingAndSaving, setIsCancellingAndSaving] = useState<boolean>();
  const [isCancellingAndDeleting, setIsCancellingAndDeleting] = useState<boolean>();

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  const { confirm } = useModal();

  const hasSendStarted =
    emailData.status !== ScheduledEmailStatus.Draft && emailData.status !== ScheduledEmailStatus.Queued;
  const hasEmailSent = emailData.status === ScheduledEmailStatus.Sent;
  const cancelActionInProgress = isCancellingAndSaving || isCancellingAndDeleting;

  const [pendingEmailRecipients] = useEndpointConditionally<EmailRecipientResponse[]>(
    EmailsApi.getFilteredEmailRecipients,
    !hasSendStarted,
    emailData.recipientsFilter,
  );

  if (!surveyList || !profileList || !providerList) {
    return <LoadingMessage />;
  }

  const cancelSendAndSave = async (): Promise<void> => {
    setIsCancellingAndSaving(true);

    const emailPatchRequest: ScheduledEmailRequest = {
      status: ScheduledEmailStatus.Draft,
    };
    const response = await EmailsApi.patchScheduledEmail(emailId, emailPatchRequest).raw;

    if (response.ok) {
      setErrorMessage(undefined);
      setIsCancellingAndSaving(false);
      navigate(0);
      return;
    }
    setErrorMessage(response.value.message);
    setIsCancellingAndSaving(false);
  };

  const cancelSendAndDelete = async (): Promise<void> => {
    if (
      !(await confirm(
        <div>
          <p>Are you sure you want to delete this email?</p>
          <p>
            <strong> This action is irreversible, and all data will be lost.</strong>
          </p>
        </div>,
      ))
    ) {
      return;
    }

    setIsCancellingAndDeleting(true);

    const response = await EmailsApi.deleteScheduledEmail(emailId).raw;
    if (response.ok) {
      setErrorMessage(undefined);
      setIsCancellingAndDeleting(false);
      navigate(adminPages.AdminManualEmails.path);
      return;
    }
    setErrorMessage(response.value.message);
    setIsCancellingAndDeleting(false);
  };

  const emailAttachments = emailData.emailObjects.filter(
    (eo) => eo.emailFileObjectType === EmailFileObjectType.Attachment,
  );

  // TODO-109: Replace the attachments file upload control with a custom file upload component
  return (
    <Stack gap={5}>
      <PageHeader
        heading={emailData.subject ?? '(no subject)'}
        crumbsType={RootPathType.AdminEmails}
        crumbs={[
          {
            name: 'Manual emails',
            path: adminPages.AdminManualEmails.path,
          },
          { name: emailData.subject ?? 'Untitled Email', path: adminPages.AdminViewOrEditEmail.path },
        ]}
      />
      <p>
        {`${hasSendStarted && hasEmailSent ? 'Sent' : 'Sending'} at ${new Date(
          Date.parse(emailData.sendTime),
        ).toLocaleString()}`}
      </p>
      <Stack gap={3}>
        <h2>Recipients</h2>
        <EmailRecipientsView
          recipientsFilter={emailData.recipientsFilter}
          emailRecipients={hasSendStarted ? emailData.recipients : pendingEmailRecipients}
          surveyList={surveyList}
          profileList={profileList}
          providerList={providerList}
          hasEmailSent={hasEmailSent}
        />
      </Stack>
      <Stack gap={3}>
        <h2>Content</h2>
        <div className="borderedContainer">
          <ContentDisplay htmlContent={emailData.htmlBody ?? ''} />
        </div>
        {emailAttachments.length > 0 && (
          <>
            <h2>Attachments</h2>
            <ul>
              {emailAttachments.map((eo) => (
                <li key={`att-${eo.fileName}`}>
                  <a href={EmailsApi.generateEmailObjectPath(eo.id.toString())} download>
                    {eo.fileName}
                  </a>
                </li>
              ))}
            </ul>
          </>
        )}
        {auth.hasRole(RshRole.SendEmails) && !hasSendStarted && (
          <>
            {!isCancellingAndSaving ? (
              <PrimaryButton
                type="button"
                colour="outline-primary"
                className="me-3"
                onClick={cancelSendAndSave}
                disabled={!!errorMessage || cancelActionInProgress}
              >
                Cancel Send and Save as Draft
              </PrimaryButton>
            ) : (
              <LoadingButton message="Cancel Send and Save as Draft" colour="outline-primary" />
            )}
            {!isCancellingAndDeleting ? (
              <PrimaryButton
                type="button"
                colour="danger"
                className="me-3"
                onClick={cancelSendAndDelete}
                disabled={!!errorMessage || cancelActionInProgress}
              >
                Cancel Send and Delete
              </PrimaryButton>
            ) : (
              <LoadingButton message="Cancel Send and Delete" colour="danger" />
            )}
          </>
        )}
      </Stack>
      {errorMessage && (
        <Alert className="mt-3" variant="danger">
          {errorMessage}
        </Alert>
      )}
    </Stack>
  );
};
