import { Stack } from 'react-bootstrap';
import { Crumb } from '@/Components/Breadcrumbs/Breadcrumbs';
import { SubmissionBreadcrumbs } from '@/Components/Breadcrumbs/SubmissionBreadcrumbs';
import { DCSErrorContextOutlet } from '@/Components/Errors/DCSErrorBoundary';

type SubmissionPageHeaderProps = {
  heading: string;
  subheading?: string;
  isRshUser: boolean;
  surveyDisplayName: string;
  providerName: string;
  additionalRshCrumbs: Crumb[];
  additionalProviderCrumbs: Crumb[];
  noErrorOutlet?: boolean;
};

export const SubmissionPageHeader = ({
  heading,
  subheading,
  isRshUser,
  surveyDisplayName,
  providerName,
  additionalRshCrumbs,
  additionalProviderCrumbs,
  noErrorOutlet,
}: SubmissionPageHeaderProps): JSX.Element => (
  <Stack gap={5}>
    <SubmissionBreadcrumbs
      isRshUser={isRshUser}
      surveyDisplayName={surveyDisplayName}
      providerName={providerName}
      additionalRshCrumbs={additionalRshCrumbs}
      additionalProviderCrumbs={additionalProviderCrumbs}
    />
    <Stack gap={3}>
      <h1>{heading}</h1>
      {subheading && <h2>{subheading}</h2>}
    </Stack>
    {!noErrorOutlet && <DCSErrorContextOutlet />}
  </Stack>
);

export default SubmissionPageHeader;
