import ApiClient, { APIHelpers, handleAPIRequest } from './ApiClient';
import { ProfitStatus, ProviderSize, ProviderType, RegistrationStatus, ReportingStatus } from './Enums';
import { ContactType } from './SurveysApi';
import { Submission } from './SubmissionsApi';
import { DynamicsEntity } from './CommonTypes';

export type ProviderSummary = {
  id: number;
  providerNumber: string;
  name: string;
  type: ProviderType;
  size: ProviderSize;
  reportingStatus: ReportingStatus;
};

// We need this type because JSON dates are just strings, and so this is the response given by the API.
export type ProviderWithStringDate = Omit<
  Provider,
  'endOfFinancialYear' | 'registrationDate' | 'deregistrationDate'
> & {
  endOfFinancialYear: string | null;
  registrationDate: string | null;
  deregistrationDate: string | null;
};

export type Provider = ProviderSummary & {
  reportingStatus: ReportingStatus;
  size: ProviderSize;

  registeredAddress: Address;

  endOfFinancialYear: Date | null;
  registrationStatus: RegistrationStatus;
  registrationDate: Date | null;
  deregistrationDate: Date | null;
  profitStatus: ProfitStatus;
  feesInvoiceEmail: string | null;

  isDisabled: boolean;
  isTestProvider: boolean;
  isSyncedWithDynamics: boolean;
};

export const mapProviderResponse = (response: ProviderWithStringDate): Provider => {
  return {
    ...response,
    endOfFinancialYear: response.endOfFinancialYear ? new Date(response.endOfFinancialYear) : null,
    registrationDate: response.registrationDate ? new Date(response.registrationDate) : null,
    deregistrationDate: response.deregistrationDate ? new Date(response.deregistrationDate) : null,
  };
};

export type ProviderOptions = {
  isTestProvider: boolean;
  feesInvoiceEmail: string | null;
};

export type Address = {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  addressLine5?: string;
  postcode: string;
};

export type ProviderContact = {
  type: ContactType;
  name: string;
  jobTitle: string;
  address: Address;
  phoneNumber: string;
  emailAddress: string;
};

// We need this type because JSON dates are just strings, and so this is the response given by the API.
export type RegisteredDetailsWithStringDate = Omit<
  RegisteredDetails,
  'endOfFinancialYear' | 'registrationDate' | 'deregistrationDate'
> & {
  endOfFinancialYear: string | null;
  registrationDate: string | null;
  deregistrationDate: string | null;
};

export interface RegisteredDetails {
  providerNumber: string;
  providerName: string;
  reportingStatus: ReportingStatus;
  providerSize: ProviderSize;
  providerType: ProviderType;
  registeredAddress: Address;
  endOfFinancialYear: Date | null;
  registrationStatus: RegistrationStatus;
  registrationDate: Date | null;
  deregistrationDate: Date | null;
  profitStatus: ProfitStatus;
  isTestProvider: boolean;
  isDisabled: boolean;
}

export const mapRegisteredDetailsResponse = (response: RegisteredDetailsWithStringDate): RegisteredDetails => {
  return {
    ...response,
    endOfFinancialYear: response.endOfFinancialYear ? new Date(response.endOfFinancialYear) : null,
    registrationDate: response.registrationDate ? new Date(response.registrationDate) : null,
    deregistrationDate: response.deregistrationDate ? new Date(response.deregistrationDate) : null,
  };
};

export type FeesInvoiceRequest = {
  feesInvoiceEmail: string | null;
};

const ProvidersApiRoot = 'Providers';
const ProvidersApiPaths = {
  PROVIDERS: ProvidersApiRoot,
  PROVIDER: (providerId: number) => `${ProvidersApiRoot}/${providerId}`,
  PROVIDER_SUBMISSIONS: (providerId: number) => `${ProvidersApiRoot}/${providerId}/Submissions`,
  PROVIDER_DYNAMICS_ENTITIES: (providerId: number) => `${ProvidersApiRoot}/${providerId}/DynamicsEntities`,
  PROVIDER_CONTACTS: (providerId: number) => `${ProvidersApiRoot}/${providerId}/Contacts`,
  ENABLE_PROVIDER: (providerId: number) => `${ProvidersApiRoot}/${providerId}/Enable`,
  DISABLE_PROVIDER: (providerId: number) => `${ProvidersApiRoot}/${providerId}/Disable`,
  EDIT_PROVIDER: (providerId: number) => `${ProvidersApiRoot}/${providerId}/Details`,
  EDIT_PROVIDER_FEES_INVOICE_EMAIL: (providerId: number) => `${ProvidersApiRoot}/${providerId}/FeesInvoiceEmail`,

  DOWNLOAD_PROVIDER_CONTACTS: `${ProvidersApiRoot}/Contacts/Download`,
  DOWNLOAD_PROVIDER_GROUP: `${ProvidersApiRoot}/Groups`,
  PROVIDER_FROM_SUBMISSION: (submissionId: number) => `${ProvidersApiRoot}/Submission/${submissionId}/Provider`,
  REGISTERED_DETAILS: (submissionId: number) => `${ProvidersApiRoot}/Submission/${submissionId}/RegisteredDetails`,
  ORGANISATIONAL_CONTACTS: (submissionId: number) => `${ProvidersApiRoot}/Submission/${submissionId}/Contacts`,
};

const providersApi = (apiClient: ApiClient) => ({
  getProviders: (excludeDisabled?: boolean) => {
    return handleAPIRequest(
      apiClient.get(ProvidersApiPaths.PROVIDERS, {
        excludeDisabled: !!excludeDisabled,
      }),
      APIHelpers.json<ProviderSummary[]>,
      APIHelpers.standardError,
    );
  },

  addProvider: (provider: Provider) => {
    return handleAPIRequest(
      apiClient.post(ProvidersApiPaths.PROVIDERS, provider),
      APIHelpers.json<ProviderWithStringDate>,
      APIHelpers.standardError,
    );
  },

  getProvider: (providerId: number) => {
    return handleAPIRequest(
      apiClient.get(ProvidersApiPaths.PROVIDER(providerId)),
      APIHelpers.json<ProviderWithStringDate>,
      APIHelpers.standardError,
    );
  },

  updateProvider: (providerId: number, providerOptions: ProviderOptions) => {
    return handleAPIRequest(
      apiClient.patch(ProvidersApiPaths.PROVIDER(providerId), providerOptions),
      APIHelpers.json<ProviderWithStringDate>,
      APIHelpers.standardError,
    );
  },

  getProviderSubmissions: (providerId: number) => {
    return handleAPIRequest(
      apiClient.get(ProvidersApiPaths.PROVIDER_SUBMISSIONS(providerId)),
      APIHelpers.json<Submission[]>,
      APIHelpers.standardError,
    );
  },

  getProviderDynamicsEntities: (providerId: number) => {
    return handleAPIRequest(
      apiClient.get(ProvidersApiPaths.PROVIDER_DYNAMICS_ENTITIES(providerId)),
      APIHelpers.json<DynamicsEntity[]>,
      APIHelpers.standardError,
    );
  },

  getProviderContacts: (providerId: number) => {
    return handleAPIRequest(
      apiClient.get(ProvidersApiPaths.PROVIDER_CONTACTS(providerId)),
      APIHelpers.json<ProviderContact[]>,
      APIHelpers.standardError,
    );
  },

  updateProviderContacts: (providerId: number, contacts: ProviderContact[]) => {
    return handleAPIRequest(
      apiClient.patch(ProvidersApiPaths.PROVIDER_CONTACTS(providerId), contacts),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  enableProvider: (providerId: number) => {
    return handleAPIRequest(
      apiClient.patch(ProvidersApiPaths.ENABLE_PROVIDER(providerId), null),
      APIHelpers.json<ProviderWithStringDate>,
      APIHelpers.standardError,
    );
  },

  disableProvider: (providerId: number) => {
    return handleAPIRequest(
      apiClient.patch(ProvidersApiPaths.DISABLE_PROVIDER(providerId), null),
      APIHelpers.json<ProviderWithStringDate>,
      APIHelpers.standardError,
    );
  },

  editProvider: (providerId: number, provider: Provider) => {
    return handleAPIRequest(
      apiClient.patch(ProvidersApiPaths.EDIT_PROVIDER(providerId), provider),
      APIHelpers.json<ProviderWithStringDate>,
      APIHelpers.standardError,
    );
  },

  editProviderFeesInvoiceEmail: (providerId: number, provider: FeesInvoiceRequest) => {
    return handleAPIRequest(
      apiClient.patch(ProvidersApiPaths.EDIT_PROVIDER_FEES_INVOICE_EMAIL(providerId), provider),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  downloadProviderContacts: () => {
    return apiClient.getFile(ProvidersApiPaths.DOWNLOAD_PROVIDER_CONTACTS);
  },

  downloadProviderGroup: (
    providerSizes: ProviderSize[] | null,
    reportingStatuses: ReportingStatus[] | null,
    providerTypes: ProviderType[] | null,
  ) => {
    return apiClient.getFileWithName(ProvidersApiPaths.DOWNLOAD_PROVIDER_GROUP, 'ProviderGroup.csv', {
      providerSize: providerSizes,
      providerType: providerTypes,
      reportingStatus: reportingStatuses,
    });
  },

  getProviderFromSubmission: (submissionId: number) => {
    return handleAPIRequest(
      apiClient.get(ProvidersApiPaths.PROVIDER_FROM_SUBMISSION(submissionId)),
      APIHelpers.json<ProviderWithStringDate>,
      APIHelpers.standardError,
    );
  },

  getRegisteredDetails: (submissionId: number) => {
    return handleAPIRequest(
      apiClient.get(ProvidersApiPaths.REGISTERED_DETAILS(submissionId)),
      APIHelpers.json<RegisteredDetailsWithStringDate>,
      APIHelpers.standardError,
    );
  },

  getOrganisationalContacts: (submissionId: number) => {
    return handleAPIRequest(
      apiClient.get(ProvidersApiPaths.ORGANISATIONAL_CONTACTS(submissionId)),
      APIHelpers.json<ProviderContact[]>,
      APIHelpers.standardError,
    );
  },

  confirmOrganisationalContacts: (submissionId: number, contacts: ProviderContact[]) => {
    return handleAPIRequest(
      apiClient.put(ProvidersApiPaths.ORGANISATIONAL_CONTACTS(submissionId), contacts),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },
});

export default providersApi;
