import { SurveyStatus } from 'nrosh-common/Api/SubmissionsApi';
import {
  SubmissionBySurveyId,
  SurveyInstance,
  SurveyInstanceWithStringDate,
  mapSurveyInstanceResponse,
} from 'nrosh-common/Api/SurveysApi';
import useEndpoint, { useEndpointConditionallyWithProcessing } from 'nrosh-common/Hooks/useEndpoint';
import { useEffect, useState } from 'react';
import { Alert, Stack } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import PageHeadingWithSurveyStatusBadge from '@/Components/Badges/PageHeadingWithSurveyStatusBadge';
import { Breadcrumbs, RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import { useModal } from '@/Components/Modal/ModalProvider';
import { SurveysApi } from '@/Helpers/Apis';
import '@/Pages/Surveys/SurveyViewPage/SurveyViewPage.scss';
import { surveyDisplayName } from '@/Helpers/SurveyHelper';
import { surveyPages } from '@/Pages/Home/SitePages';
import SurveyAssignedProviders from '@/Pages/Surveys/SurveyViewPage/Components/SurveyAssignedProviders';
import { SurveyInformation } from '@/Pages/Surveys/SurveyViewPage/Components/SurveyInformation';
import { SurveyManagement } from '@/Pages/Surveys/SurveyViewPage/Components/SurveyManagement';
import SurveyOptionalActions from '@/Pages/Surveys/SurveyViewPage/Components/SurveyOptionalActions';

type State = {
  shouldShowSuccessAlert: string;
};

const SurveyViewPage = (): JSX.Element => {
  const { surveyId, timePeriodId } = useParams();
  const [survey] = useEndpointConditionallyWithProcessing<SurveyInstanceWithStringDate, SurveyInstance>(
    SurveysApi.getSurveyInstance,
    true,
    mapSurveyInstanceResponse,
    surveyId,
    timePeriodId,
  );
  const [surveyStatus, setSurveyStatus] = useState<SurveyStatus>();
  const [submissions] = useEndpoint<Array<SubmissionBySurveyId>>(SurveysApi.getSubmissionsBySurveyId, surveyId);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();
  const [showAlert, setShowAlert] = useState(!!(location.state as State));
  const { alert } = useModal();

  useEffect(() => {
    setSurveyStatus(survey?.surveyStatus);
    if (showAlert) {
      alert(
        <p>
          You are now on the <strong> survey overview page</strong>, where you can manage the survey through actions
          like configuring survey parts and assigning it to providers, as well as monitoring assigned providers&#39;
          status once the survey is open.
        </p>,
        'new-survey-modal',
        'Survey successfully created',
        true,
      )
        .then(() => {
          setShowAlert(false);
          window.history.replaceState({}, document.title);
        })
        .catch(() => {});
    }
  }, [survey?.surveyStatus, showAlert]);

  if (!survey || !submissions || !surveyId || !timePeriodId || !surveyStatus) {
    return <LoadingMessage />;
  }

  const submissionsData = submissions.filter((s) => timePeriodId && s.timePeriodId === parseInt(timePeriodId, 10));

  return (
    <Stack gap={5}>
      <Stack gap={5}>
        <Breadcrumbs
          crumbs={[
            {
              name: surveyDisplayName(survey.name, survey.timePeriodName),
              path: surveyPages.SurveyView.path,
            },
          ]}
          type={RootPathType.AdminSurveys}
        />
        <PageHeadingWithSurveyStatusBadge title={survey.name} status={surveyStatus} />
      </Stack>
      {error && (
        <Alert variant="danger" className="w-75">
          {error}
        </Alert>
      )}
      <div>
        <SurveyInformation survey={survey} />
        <SurveyManagement
          survey={survey}
          setError={setError}
          setSurveyStatus={setSurveyStatus}
          surveyStatus={surveyStatus}
        />
        <SurveyOptionalActions
          surveyId={surveyId}
          timePeriodId={timePeriodId}
          surveyStatus={surveyStatus}
          hasProvidersAssigned={survey.isAssigned}
          hasSubmissions={!survey.isDeletable}
          setError={setError}
          setSurveyStatus={setSurveyStatus}
        />
        <SurveyAssignedProviders
          surveyId={surveyId}
          timePeriodId={timePeriodId}
          submissionsData={submissionsData}
          surveyStatus={surveyStatus}
          isSurveyInstanceConfigured={survey.isSurveyInstanceConfigured}
        />
      </div>
    </Stack>
  );
};

export default SurveyViewPage;
