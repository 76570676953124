import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { SubmissionPartValidationComment, ValidationUpdate } from 'nrosh-common/Api/SubmissionsApi';
import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { useEffect, useState } from 'react';
import { DimensionValue, SpreadsheetValuesSerialized } from '@/Components/Spreadsheet/SpreadsheetTypes';
import { webApiBaseUrl } from '@/Config';

export type SubscriptionStartedData = {
  data: SpreadsheetValuesSerialized;
  dimensionData: DimensionValue[];
  validations: ValidationUpdate[];
  comments: SubmissionPartValidationComment[];
};

export type FactUpdate = {
  dataPointId: string;
  newValue: string;
  dimension1MemberId: number | null;
  dimension2MemberId: number | null;
};

export type ValidationApprovalUpdate = {
  validationId: string;
  isApproved: boolean;
};

export type DimensionalMemberUpdate = {
  dimensionalRegionId: number;
  index: number;
  dimensionalMemberId: number | null;
};

class FactHub {
  private readonly connection: HubConnection;

  private readonly submissionPartId: number;

  constructor(connection: HubConnection, submissionPartId: number) {
    this.connection = connection;
    this.submissionPartId = submissionPartId;
  }

  reassignCallback = (
    methodName: string,
    callback: ((update: FactUpdate) => void) | ((update: DimensionalMemberUpdate) => void),
  ): void => {
    this.connection.off(methodName);
    this.connection.on(methodName, callback);
  };

  updateFact = async (
    dataPointId: string,
    value: string,
    dim1MemberId?: number,
    dim2MemberId?: number,
  ): Promise<void> => {
    await this.connection.send('UpdateFact', this.submissionPartId, dataPointId, value, dim1MemberId, dim2MemberId);
  };

  updateComment = async (validationId: string, comment: string): Promise<void> => {
    await this.connection.send('UpdateComment', this.submissionPartId, validationId, comment);
  };

  deleteComment = async (validationId: string): Promise<void> => {
    await this.connection.send('DeleteComment', this.submissionPartId, validationId);
  };

  setUsedDimension = async (regionId: number, index: number, dimensionMemberId: number | null): Promise<void> => {
    await this.connection.send('SetUsedDimension', this.submissionPartId, regionId, index, dimensionMemberId);
  };

  updateValidationApproval = async (validationId: string, isApproved: boolean): Promise<void> => {
    await this.connection.send('UpdateValidationApproval', this.submissionPartId, validationId, isApproved);
  };
}

const useFactHub = (
  submissionPartId: number,
  onSubscriptionStarted: (data: SubscriptionStartedData) => void,
  onFactUpdated: (update: FactUpdate) => void,
  onDimensionalMemberUpdated: (update: DimensionalMemberUpdate) => void,
  onValidationUpdated: (update: ValidationUpdate) => void,
  onValidationApprovalUpdated: (update: ValidationApprovalUpdate) => void,
  onCommentUpdated: (update: SubmissionPartValidationComment) => void,
  onCommentDeleted: (validationId: string) => void,
  importInProgress: boolean,
  setImportInProgress: ReactStateSetter<boolean>,
  enginePendingDeletion: boolean,
  setEnginePendingDeletion: ReactStateSetter<boolean>,
): FactHub | null => {
  const [hub, setHub] = useState<FactHub | null>(null);

  const onSubscriptionStartedMessageReceived = (data: SubscriptionStartedData): void => {
    onSubscriptionStarted(data);
    setImportInProgress(false);
  };

  useEffect(() => {
    const openConnection = async (newConnection: HubConnection): Promise<void> => {
      newConnection.on('SubscriptionStarted', onSubscriptionStartedMessageReceived);
      newConnection.on('FactUpdated', onFactUpdated);
      newConnection.on('DimensionalMemberUpdated', onDimensionalMemberUpdated);
      newConnection.on('ValidationUpdated', onValidationUpdated);
      newConnection.on('CommentUpdated', onCommentUpdated);
      newConnection.on('CommentDeleted', onCommentDeleted);
      newConnection.on('ValidationApprovalUpdated', onValidationApprovalUpdated);
      newConnection.on('ImportStarted', () => setImportInProgress(true));
      newConnection.on('ImportFinished', () => setImportInProgress(false));
      newConnection.on('ImportInProgress', () => setImportInProgress(true));
      newConnection.on('EnginePendingDeletion', () => setEnginePendingDeletion(true));
      await newConnection.start();
      await newConnection.send('SubscribeToSubmission', submissionPartId);
      setHub(new FactHub(newConnection, submissionPartId));
    };
    const hubConnection = new HubConnectionBuilder().withUrl(`${webApiBaseUrl}/factHub`).build();
    openConnection(hubConnection).catch(() => {});
    return () => {
      hubConnection.stop().catch(() => {});
    };
  }, [submissionPartId, importInProgress]);

  useEffect(() => {
    if (hub) {
      hub.reassignCallback('FactUpdated', onFactUpdated);
    }
  }, [onFactUpdated]);

  useEffect(() => {
    if (hub) {
      hub.reassignCallback('DimensionalMemberUpdated', onDimensionalMemberUpdated);
    }
  }, [onDimensionalMemberUpdated]);

  return hub;
};

export default useFactHub;
