import ApiClient, { APIHelpers, handleAPIRequest } from './ApiClient';
import { Tag } from './CommonTypes';

const TagsApiRoot = 'Tags';
const TagsApiPaths = {
  CONTENT_TAGS: `${TagsApiRoot}/Content`,
  DOCUMENT_TAGS: `${TagsApiRoot}/Document`,
  SUPPORTING_DOCUMENT_TAGS: `${TagsApiRoot}/SupportingDocument`,
};

const tagsApi = (apiClient: ApiClient) => ({
  getContentTags: () => {
    return handleAPIRequest(apiClient.get(TagsApiPaths.CONTENT_TAGS), APIHelpers.json<Tag[]>, APIHelpers.standardError);
  },

  getDocumentTags: () => {
    return handleAPIRequest(
      apiClient.get(TagsApiPaths.DOCUMENT_TAGS),
      APIHelpers.json<Tag[]>,
      APIHelpers.standardError,
    );
  },

  getSupportingDocumentTags: () => {
    return handleAPIRequest(
      apiClient.get(TagsApiPaths.SUPPORTING_DOCUMENT_TAGS),
      APIHelpers.json<Tag[]>,
      APIHelpers.standardError,
    );
  },
});

export default tagsApi;
