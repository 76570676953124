import { HTMLContent } from '@tiptap/react';
import { Article, ArticleRequest } from 'nrosh-common/Api/NewsApi';
import { useState } from 'react';
import { ListGroup, Tab } from 'react-bootstrap';
import { generatePath, useLocation } from 'react-router-dom';
import ContentDisplay from '@/Components/ContentDisplay/ContentDisplay';
import ContentForm from '@/Pages/Content/ContentForm';
import { adminPages } from '@/Pages/Home/SitePages';

type Props = {
  article?: Article;
  sendArticleRequest: (
    request: ArticleRequest,
    unpublish?: boolean,
  ) => Promise<{ success: boolean; redirectPath?: string; errorMessage?: string }>;
};

const NewsTabContainer: (props: Props) => JSX.Element = ({ article, sendArticleRequest }) => {
  const [articleName, setArticleName] = useState<string>(article ? article.name : '');
  const [content, setContent] = useState<HTMLContent>(article ? article.content : '');
  const contentEditorNavLink = '#contentEditor';
  const previewNavLink = '#preview';
  const redirectPath = generatePath(adminPages.AdminNews.path);
  const location = useLocation();

  const publishDateTime = article && article.publishDateTime ? new Date(article.publishDateTime) : null;

  return (
    <Tab.Container defaultActiveKey={location.hash || contentEditorNavLink}>
      <ListGroup horizontal className="w-50">
        <ListGroup.Item bsPrefix="listGroupItem submissions" action href={contentEditorNavLink} as="button">
          Edit
        </ListGroup.Item>
        <ListGroup.Item bsPrefix="listGroupItem submissions" action href={previewNavLink} as="button">
          Preview
        </ListGroup.Item>
      </ListGroup>
      <Tab.Content>
        <Tab.Pane eventKey={contentEditorNavLink}>
          <ContentForm
            publishDateTime={publishDateTime}
            content={content}
            setContent={setContent}
            title={articleName}
            setTitle={setArticleName}
            sendApiRequest={sendArticleRequest}
            publishOrCancelPath={redirectPath}
            titleLabel="News Story Title"
            contentLabel="News Story Content"
            titlePlaceholderText="Enter news story title"
            contentPlaceHolderText="Start writing content..."
            contentType="article"
            defaultSelectedTagIds={article?.tags.map((t) => t.id) ?? []}
          />
        </Tab.Pane>
        <Tab.Pane eventKey={previewNavLink}>
          <h2>{articleName}</h2>
          <ContentDisplay htmlContent={content} />
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  );
};

export default NewsTabContainer;
