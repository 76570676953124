import { Alert } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import useRedirectWhenLoggedIn from '@/Hooks/useRedirectWhenLoggedIn';
import LoginForm from '@/Pages/Home/LoginForm';

const LoginPage = (): JSX.Element => {
  useRedirectWhenLoggedIn();
  const location = useLocation();
  const locationState = location.state as { passwordChanged?: boolean; accountActivated?: boolean } | null;

  return (
    <div className="d-flex flex-column">
      <h1 className="mx-auto mt-2 mb-3">Log in to DCS</h1>
      {locationState?.passwordChanged && (
        <Alert variant="success" className="text-center">
          Your password has successfully been changed
        </Alert>
      )}
      {locationState?.accountActivated && (
        <Alert variant="success" className="text-center">
          Your account has successfully been activated
        </Alert>
      )}
      <div>
        <LoginForm />
      </div>
    </div>
  );
};

export default LoginPage;
