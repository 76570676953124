import saveAs from 'file-saver';
import {
  AccountChangeType,
  UserAuditLog,
  UserAuditLogFilters,
  UserAuditLogsRequest,
  UserAuditLogsResponse,
} from 'nrosh-common/Api/AuditLogsApi';
import { ProviderSummary } from 'nrosh-common/Api/ProvidersApi';
import { UsersResponse } from 'nrosh-common/Api/UsersApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { FormEvent, useCallback, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Column } from 'react-table';
import AuditLogsSearchButtons from '@/Components/AuditLogs/AuditLogsSearchButtons';
import ExportAuditLogs from '@/Components/AuditLogs/ExportAuditLogs';
import { useErrorReporting } from '@/Components/Errors/DCSErrorBoundary';
import FilterDateRangePicker from '@/Components/Filters/FilterDateRangePicker';
import { FilterDropdown } from '@/Components/Filters/FilterDropdown';
import FilterHeading from '@/Components/Filters/FilterHeading';
import FilterProviderSelection from '@/Components/Filters/FilterProviderSelection';
import FilterUserSelection from '@/Components/Filters/FilterUserSelection';
import { SearchableDropdown } from '@/Components/SearchableDropdown/SearchableDropdown';
import Table from '@/Components/Table/Table';
import { AuditLogsApi, ProvidersApi, UsersApi } from '@/Helpers/Apis';
import { AccountChangeTypeMapping } from '@/Pages/AuditLogs/UserAuditLogsPage';

type UserAuditLogsFormProps = {
  userAuditLogsResponse: UserAuditLogsResponse;
  headers: Column<UserAuditLog>[];
};

const UserAuditLogsForm = (props: UserAuditLogsFormProps): JSX.Element => {
  const { userAuditLogsResponse, headers } = props;
  const [filters, setFilters] = useState<UserAuditLogFilters>({});
  const [fromDateTime, setFromDateTime] = useState<Date | null>(null);
  const [toDateTime, setToDateTime] = useState<Date | null>(null);
  const [filteredResults, setFilteredResults] = useState<UserAuditLogsResponse>(userAuditLogsResponse);
  const [changedByProviderId, setChangedByProviderId] = useState<number>();
  const [providers] = useEndpoint<ProviderSummary[]>(ProvidersApi.getProviders);
  const [users] = useEndpoint<UsersResponse>(UsersApi.getUsers);
  const [raiseError, clearError] = useErrorReporting();
  const [validated, setValidated] = useState<boolean>(false);

  const accountActionOptions = Object.keys(AccountChangeType)
    .map((p) => p as AccountChangeType)
    .map((act) => ({ value: act, label: AccountChangeTypeMapping[act] }));

  const getUserAuditLogsRequest = (): UserAuditLogsRequest => {
    const searchParams = Object.keys(filters).filter((key) => !!filters[key as keyof UserAuditLogFilters]);
    const request: { [key: string]: number | string | boolean | undefined } = {};
    searchParams.forEach((key) => {
      request[key] = filters[key as keyof UserAuditLogFilters];
    });

    if (filters.providerId === 0) {
      request.filterByRshUsers = true;
    }
    if (fromDateTime) {
      request.fromDate = new Date(fromDateTime).toISOString();
    }
    if (toDateTime) {
      request.toDate = new Date(toDateTime).toISOString();
    }

    return request as UserAuditLogsRequest;
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();
    clearError();
    if (!event.currentTarget.checkValidity()) {
      setValidated(true);
      return;
    }
    clearError();
    const request = getUserAuditLogsRequest();
    const response = await AuditLogsApi.getUserAuditLogs(request).raw;
    if (response.ok) {
      const body = response.value;
      setFilteredResults(body);
    } else {
      raiseError(response.value.message);
    }
  };

  const clearFilters = async (): Promise<void> => {
    setValidated(false);
    const response = await AuditLogsApi.getUserAuditLogs().raw;
    if (response.ok) {
      const body = response.value;
      setFilters({});
      setFromDateTime(null);
      setToDateTime(null);
      setChangedByProviderId(undefined);
      setFilteredResults(body);
      setValidated(false);
    } else {
      raiseError(response.value.message);
    }
  };

  const onDownload = useCallback(async (): Promise<void> => {
    try {
      const request = getUserAuditLogsRequest();
      const blob = await AuditLogsApi.downloadUserAuditLogs(request);
      saveAs(blob, 'userAuditLogs.csv');
    } catch {
      raiseError();
    }
  }, [filteredResults]);

  return (
    <div>
      <FilterDropdown resultCount={filteredResults.numberOfResults}>
        <Form noValidate onSubmit={onSubmit}>
          <div className="row mb-2">
            <FilterHeading heading="User" />
            <div className="col">
              <FilterProviderSelection
                value={filters.providerId}
                onChange={(e) =>
                  setFilters((prevData) => ({
                    ...prevData,
                    providerId: e,
                    userId: undefined,
                  }))
                }
                providers={providers}
                includeRSH
              />
            </div>
            <div className="col">
              {(filters.providerId || filters.providerId === 0) && users && (
                <FilterUserSelection
                  value={filters.userId}
                  onChange={(v) =>
                    setFilters((prevData) => ({
                      ...prevData,
                      userId: v,
                    }))
                  }
                  providerId={filters.providerId}
                  users={users}
                  required={false}
                  includeProviderUsers={filters.providerId !== 0}
                  includeRshUsers={filters.providerId === 0}
                />
              )}
            </div>
          </div>
          <div className="row mb-2">
            <FilterHeading heading="Account Action" />
            <div className="col">
              <SearchableDropdown
                options={accountActionOptions}
                currentSelection={filters.changeType}
                placeholderText="Select account action"
                onChange={(v) => setFilters((prevData) => ({ ...prevData, changeType: v }))}
              />
            </div>
            <div className="col" />
          </div>
          <div className="row mb-2">
            <FilterHeading heading="Change By User" />
            <div className="col">
              <FilterProviderSelection
                value={changedByProviderId}
                onChange={(e) => {
                  setChangedByProviderId(e);
                  setFilters((prevData) => ({
                    ...prevData,
                    changedByUserId: undefined,
                  }));
                }}
                providers={providers}
                includeRSH
              />
            </div>
            <div className="col">
              {(changedByProviderId || changedByProviderId === 0) && users && (
                <FilterUserSelection
                  value={filters.changedByUserId}
                  onChange={(v) =>
                    setFilters((prevData) => ({
                      ...prevData,
                      changedByUserId: v,
                    }))
                  }
                  providerId={changedByProviderId}
                  users={users}
                  required
                  validated={validated}
                  includeRshUsers={changedByProviderId === 0}
                  includeProviderUsers={changedByProviderId !== 0}
                />
              )}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col">
              <FilterHeading heading="Date Range" />
              <FilterDateRangePicker
                fromDateTime={fromDateTime}
                setFromDateTime={setFromDateTime}
                toDateTime={toDateTime}
                setToDateTime={setToDateTime}
              />
            </div>
          </div>
          <AuditLogsSearchButtons clearFilters={clearFilters} />
        </Form>
      </FilterDropdown>
      <ExportAuditLogs
        numberOfResults={filteredResults.numberOfResults}
        numberOfResultsReturned={filteredResults.numberOfResultsReturned}
        onDownload={onDownload}
      />
      <Table
        data={filteredResults.userAuditLogs}
        columns={headers}
        paginated
        // An alternative export button is provided
        exportable={false}
      />
    </div>
  );
};

export default UserAuditLogsForm;
