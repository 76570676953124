import ApiClient, { APIHelpers, handleAPIRequest } from './ApiClient';
import { DynamicsEntity, Tag } from './CommonTypes';

export enum RegulatoryDocumentStatus {
  Pending = 'Pending',
  Accepted = 'Accepted',
  Transferred = 'Transferred',
}

export type DocumentType = {
  documentType: string;
  dynamicsName: string;
  displayName: string;
  isTransferable: boolean;
};

export type RegulatoryDocument = {
  id: number;
  description: string | null;
  fileName: string;
  uploadDate: string;
  scheduledDeletionDate: string | null;
  status: RegulatoryDocumentStatus;
  providerId: number;
  providerName: string;
  providerNumber: string;
  documentType: DocumentType;
  primaryTag: Tag;
  secondaryTag: Tag;
  dynamicsRegardingEntity: DynamicsEntity;
  hasBeenDeleted: boolean;
  deletedBy: string;
};

export type GetRegulatoryDocumentResponse = {
  documents: RegulatoryDocument[];
};

// We need this type because JSON dates are just strings, and so this is the response given by the API.
export type RegulatoryDocumentMetadataWithStringDate = Omit<RegulatoryDocumentMetadata, 'scheduledDeletionDate'> & {
  scheduledDeletionDate: string | null;
};

export type RegulatoryDocumentMetadata = {
  description: string | null;
  scheduledDeletionDate: Date | null;
  documentType: string;
  primaryTag: Tag;
  secondaryTag: Tag;
  dynamicsRegardingEntity: DynamicsEntity;
  providerId: number;
  providerName: string;
  providerNumber: string;
  fileName: string;
  fileSizeBytes: number;
};

export const mapRegulatoryDocumentResponse = (
  response: RegulatoryDocumentMetadataWithStringDate,
): RegulatoryDocumentMetadata => {
  return {
    ...response,
    scheduledDeletionDate: response.scheduledDeletionDate ? new Date(response.scheduledDeletionDate) : null,
  };
};

const RegulatoryDocumentsApiRoot = 'RegulatoryDocuments';
const RegulatoryDocumentsApiPaths = {
  LIST: RegulatoryDocumentsApiRoot,
  METADATA: (documentId: string) => `${RegulatoryDocumentsApiRoot}/${documentId}`,
  DOWNLOAD: (documentId: string) => `${RegulatoryDocumentsApiRoot}/${documentId}/Download`,
  DOWNLOAD_MANY: `${RegulatoryDocumentsApiRoot}/DownloadMany`,
  LIST_FOR_PROVIDER: `${RegulatoryDocumentsApiRoot}/ForProvider`,
  UPLOAD_FOR_PROVIDER: (providerId: string) => `${RegulatoryDocumentsApiRoot}/ForProvider/${providerId}`,
  ASSIGN_TO_ENTITY: `${RegulatoryDocumentsApiRoot}/AssignToEntity`,
  MARK_FOR_TRANSFER: `${RegulatoryDocumentsApiRoot}/MarkForTransfer`,
  DOCUMENT_TYPES: `${RegulatoryDocumentsApiRoot}/DocumentTypes`,
};

const RegulatoryDocumentsApi = (apiClient: ApiClient) => ({
  getList: () => {
    return handleAPIRequest(
      apiClient.get(RegulatoryDocumentsApiPaths.LIST),
      APIHelpers.json<GetRegulatoryDocumentResponse>,
      APIHelpers.standardError,
    );
  },

  upload: (formData: FormData) => {
    return handleAPIRequest(
      apiClient.postFile(RegulatoryDocumentsApiPaths.LIST, formData),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  getMetadata: (documentId: string) => {
    return handleAPIRequest(
      apiClient.get(RegulatoryDocumentsApiPaths.METADATA(documentId)),
      APIHelpers.json<RegulatoryDocumentMetadataWithStringDate>,
      APIHelpers.standardError,
    );
  },

  update: (documentId: string, formData: FormData) => {
    return handleAPIRequest(
      apiClient.patchFile(RegulatoryDocumentsApiPaths.METADATA(documentId), formData),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  delete: (documentId: string) => {
    return handleAPIRequest(
      apiClient.deleteAction(RegulatoryDocumentsApiPaths.METADATA(documentId)),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  download: (documentId: string) => {
    return apiClient.getFile(RegulatoryDocumentsApiPaths.DOWNLOAD(documentId));
  },

  downloadMany: (documentIds: number[]) => {
    return apiClient.getFileWithName(RegulatoryDocumentsApiPaths.DOWNLOAD_MANY, undefined, {
      documentIds,
    });
  },

  getListForProvider: () => {
    return handleAPIRequest(
      apiClient.get(RegulatoryDocumentsApiPaths.LIST_FOR_PROVIDER),
      APIHelpers.json<GetRegulatoryDocumentResponse>,
      APIHelpers.standardError,
    );
  },

  uploadForProvider: (providerId: string, formData: FormData) => {
    return handleAPIRequest(
      apiClient.postFile(RegulatoryDocumentsApiPaths.UPLOAD_FOR_PROVIDER(providerId), formData),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  assignToEntity: (entityId: number, documentIds: number[]) => {
    return handleAPIRequest(
      apiClient.patch(RegulatoryDocumentsApiPaths.ASSIGN_TO_ENTITY, {
        entityId,
        documentIds,
      }),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  markForTransfer: (documentIds: number[]) => {
    return handleAPIRequest(
      apiClient.patch(RegulatoryDocumentsApiPaths.MARK_FOR_TRANSFER, {
        documentIds,
      }),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  getDocumentTypes: () => {
    return handleAPIRequest(
      apiClient.get(RegulatoryDocumentsApiPaths.DOCUMENT_TYPES),
      APIHelpers.json<DocumentType[]>,
      APIHelpers.standardError,
    );
  },
});

export default RegulatoryDocumentsApi;
