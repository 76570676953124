import { Role } from 'nrosh-common/Api/Enums';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import { useContext } from 'react';
import { Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ArrowRight from '@/Components/Icons/ArrowRight';

type LandingPageProps = {
  description?: string;
  pages: { name: string; path: string; roles?: Role[] }[];
};

const LandingPage = (props: LandingPageProps): JSX.Element => {
  const { description, pages } = props;
  const auth = useContext(AuthContext);

  const pagesToDisplay = pages.filter((p) => !p.roles || auth.hasOneOfRoles(...p.roles));

  return (
    <Stack gap={4}>
      <div>{description}</div>
      {pagesToDisplay.map((page) => (
        <div key={page.name}>
          <Link to={page.path}>
            {page.name}
            <span className="ps-1 linkIcon">
              <ArrowRight />
            </span>
          </Link>
        </div>
      ))}
    </Stack>
  );
};

export default LandingPage;
