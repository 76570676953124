import { nameof } from 'nrosh-common/Helpers/StringHelpers';
import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { ReactNode, useContext, useMemo, useState } from 'react';
import { createContext } from '@/Helpers/Context';

export type ActiveCellData = {
  activeCell: string | null;
  setActiveCell: ReactStateSetter<string | null>;
};

const ActiveCellContext = createContext<ActiveCellData>();

export const ActiveCellContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [activeCell, setActiveCell] = useState<string | null>(null);

  const value = useMemo(
    () => ({
      activeCell,
      setActiveCell,
    }),
    [activeCell],
  );

  return <ActiveCellContext.Provider value={value}>{children}</ActiveCellContext.Provider>;
};

export const useActiveCellContext = (): ActiveCellData => {
  const context = useContext(ActiveCellContext);
  if (!context) {
    throw new Error(`${nameof({ useActiveCellContext })} must be used within ${nameof({ ActiveCellContextProvider })}`);
  }

  return context;
};
