import { Alert } from 'react-bootstrap';
import { Link, generatePath } from 'react-router-dom';
import { surveyPages } from '@/Pages/Home/SitePages';

type SubmissionPartAlertProps = {
  isAdmin: boolean;
  surveyId: string;
  timePeriodId: string;
};

const SubmissionPartAlert = (props: SubmissionPartAlertProps): JSX.Element => {
  const { isAdmin, surveyId, timePeriodId } = props;
  return (
    <Alert variant="danger" className="mt-6">
      {isAdmin ? (
        <>
          This submission part cannot be viewed because there is no data in the import file. Please go to the{' '}
          <Link to={generatePath(surveyPages.SurveyEdit.path, { surveyId, timePeriodId })}>Edit Survey</Link> page and
          upload data for this survey part.
        </>
      ) : (
        <>
          There is no data available to view or edit for this survey part. Please email&nbsp;
          <a href="mailto:Team-DCS@softwire.com">Team-DCS@softwire.com</a> for further assistance.
        </>
      )}
    </Alert>
  );
};

export default SubmissionPartAlert;
