import classNames from 'classnames';

type Props = {
  index: number;
  activeIndex: number;
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
};

const PageNumber: (props: Props) => JSX.Element = ({ index, activeIndex, gotoPage }) => {
  const activePage = index === activeIndex;
  const activePageClass = classNames({
    activePage,
    over1000Pages: activePage && activeIndex >= 999,
    upTo1000Pages: activePage && activeIndex >= 199 && activeIndex < 999,
    upTo200Pages: activePage && activeIndex < 199,
  });
  return (
    <button type="button" className={activePageClass} key={index} onClick={() => gotoPage(index)}>
      {index + 1}
    </button>
  );
};

export default PageNumber;
