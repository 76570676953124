import { saveAs } from 'file-saver';
import { DataDictionaryErrorResponse } from 'nrosh-common/Api/SurveyPartsApi';
import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { ChangeEvent, FC, useCallback, useRef, useState } from 'react';
import { DCSButtonProps, PrimaryButton, SecondaryButton } from '@/Components/Buttons/DCSButton';
import { useErrorReporting } from '@/Components/Errors/DCSErrorBoundary';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { useModal } from '@/Components/Modal/ModalProvider';
import { SurveyPartsApi } from '@/Helpers/Apis';
import '@/Components/DataDictionaryUpload/DataDictionaryButtons.css';

export type DeleteDataDictionaryButtonProps = {
  surveyPartId: string;
  onSuccessfulBulkDelete: () => void;
  numUnusedDataPoints: number;
  setShowToast: ReactStateSetter<boolean>;
  setToastText: ReactStateSetter<string>;
};

export type DownloadDataDictionaryButtonProps = {
  surveyPartId: string;
  text?: string;
  colour?: string;
  Button?: FC<DCSButtonProps>;
};

export type UploadDataDictionaryButtonProps = {
  surveyPartId: string;
  setDataLastUploadedAt: ReactStateSetter<Date | null>;
  setDataDictionaryErrors: ReactStateSetter<DataDictionaryErrorResponse | undefined>;
  setShow: ReactStateSetter<{ [name: string]: boolean }>;
  setShowToast: ReactStateSetter<boolean>;
  setToastText: ReactStateSetter<string>;
  colour?: string;
  Button?: FC<DCSButtonProps>;
};

export const DownloadDataDictionaryButton = ({
  surveyPartId,
  text = 'Download data dictionary template',
  colour = 'primary',
  Button = PrimaryButton,
}: DownloadDataDictionaryButtonProps): JSX.Element => {
  const [raiseError] = useErrorReporting();
  const [isDownloading, setDownloading] = useState(false);
  const onDownload = useCallback(async () => {
    setDownloading(true);
    try {
      const blob = await SurveyPartsApi.downloadDataDictionary(surveyPartId);
      saveAs(URL.createObjectURL(blob), 'dataDictionary.xlsx');
    } catch {
      raiseError();
    }
    setDownloading(false);
  }, [surveyPartId]);
  return !isDownloading ? (
    <Button colour={colour} className="dictionary-button" type="button" onClick={onDownload}>
      {text}
    </Button>
  ) : (
    <LoadingButton message={text} />
  );
};

export const UploadDataDictionaryButton = ({
  surveyPartId,
  setDataLastUploadedAt,
  setDataDictionaryErrors,
  setShow,
  setShowToast,
  setToastText,
  colour = 'primary',
  Button = PrimaryButton,
}: UploadDataDictionaryButtonProps): JSX.Element => {
  const [raiseError] = useErrorReporting();
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [isUploading, setUploading] = useState(false);

  const changeHandler = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    setUploading(true);
    const formData = new FormData();
    formData.append('excelFile', event.target.files![0]);

    const response = await SurveyPartsApi.uploadDataDictionary(formData, surveyPartId).raw;
    setUploading(false);
    if (response.ok) {
      const dataDictionaryResponse = response.value;
      setDataDictionaryErrors(dataDictionaryResponse);
      dataDictionaryResponse.dataDictionaryErrors.forEach((e, i) => {
        setShow((prev) => ({ ...prev, [`item: ${i}`]: true }));
      });
      setDataLastUploadedAt(new Date());
      setShowToast(true);
      if (dataDictionaryResponse.dataDictionaryErrors.length === 0) {
        setToastText('Data dictionary has been successfully uploaded and saved.');
      } else {
        setToastText('Data dictionary has been partially uploaded and saved.');
      }
    } else {
      raiseError(response.value.message);
    }
    // Reset the value, so that selecting the same file again is seen as a change
    hiddenFileInput.current!.value = '';
  };
  return (
    <>
      {!isUploading ? (
        <Button
          id="uploadButton"
          colour={colour}
          className="dictionary-button"
          type="button"
          onClick={() => {
            hiddenFileInput.current!.click();
          }}
        >
          Upload Data Dictionary
        </Button>
      ) : (
        <LoadingButton message="Upload Data Dictionary" />
      )}
      <input
        className="hiddenInput"
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ref={hiddenFileInput}
        onChange={changeHandler}
        aria-labelledby="uploadButton"
      />
    </>
  );
};

export const DeleteDataDictionaryButton = ({
  surveyPartId,
  onSuccessfulBulkDelete,
  numUnusedDataPoints,
  setShowToast,
  setToastText,
}: DeleteDataDictionaryButtonProps): JSX.Element => {
  const [isDeleting, setIsDeleting] = useState(false);
  const { confirm } = useModal();

  const deleteUnused = async (): Promise<void> => {
    setShowToast(false);
    if (
      await confirm(
        <div>
          <p>
            This will delete{' '}
            <strong>
              {numUnusedDataPoints} data point{numUnusedDataPoints !== 1 ? 's' : ''}
            </strong>{' '}
            not currently in use by this survey part.<strong> You will not be able to undo this action. </strong>
          </p>
          <p>Do you wish to proceed?</p>
        </div>,
      )
    ) {
      setIsDeleting(true);
      const response = await SurveyPartsApi.deleteUnusedDataPoints(surveyPartId).raw;
      if (response.ok) {
        onSuccessfulBulkDelete();
        setShowToast(true);
        setToastText('Changes to data dictionary saved successfully');
      }
    }
    setIsDeleting(false);
  };

  return !isDeleting ? (
    <SecondaryButton
      className="dictionary-button"
      type="button"
      onClick={() => deleteUnused()}
      disabled={numUnusedDataPoints === 0}
    >
      Delete Unused Data Points
    </SecondaryButton>
  ) : (
    <LoadingButton message="Delete Unused Data Points" />
  );
};
