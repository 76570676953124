import { MutableRefObject, useRef } from 'react';

// If using this hook, all external filtering on a table should specify their page reset behaviour by calling one of
// enableReset or disableReset before altering the table data. A global filter on a table will always reset the page
// when it is changed
export const useTablePageResetOverride = (): [MutableRefObject<boolean>, () => void, () => void] => {
  // Provide a ref which allows us to control the page reset more closely during data changes. This allows us to prevent
  // page number resets when ticking checkboxes but force them when changing the global filter.
  // See https://react-table-v7.tanstack.com/docs/faq#how-do-i-stop-my-table-state-from-automatically-resetting-when-my-data-changes
  // for further details.
  const skipPageResetRef = useRef(false);
  const enableReset = (): void => {
    skipPageResetRef.current = false;
  };
  const disableReset = (): void => {
    skipPageResetRef.current = true;
  };

  return [skipPageResetRef, enableReset, disableReset];
};
