import { Checkbox } from '@mui/material';
import { SurveyStatus } from 'nrosh-common/Api/SubmissionsApi';
import {
  ContactType,
  EditSurveyChecksRequest,
  SurveyChecks,
  SurveyDeclarationResponse as SurveyDeclaration,
} from 'nrosh-common/Api/SurveysApi';
import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { generatePath, useParams } from 'react-router-dom';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import { ContactTypeTitleMapping } from '@/Components/EndOfSurveyChecks/ContactTypeTitleMapping';
import EndOfSurveyCheckbox from '@/Components/EndOfSurveyChecks/EndOfSurveyCheckbox';
import SurveyDeclarationInput from '@/Components/EndOfSurveyChecks/SurveyDeclarationInput';
import { AccessibleFeedback } from '@/Components/Form/AccessibleFeedback';
import LinkButton from '@/Components/Links/LinkButton';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import useUnsavedChangesWarning from '@/Hooks/useUnsavedChangesWarning';
import { surveyPages } from '@/Pages/Home/SitePages';

type Props = {
  checks: SurveyChecks;
  onSave: (checks: EditSurveyChecksRequest) => Promise<{
    success: boolean;
    errorMessage?: string;
  }>;
  surveyStatus: SurveyStatus;
};

const listOfContactTypes = Object.keys(ContactType).map((p) => p as ContactType);

const EndOfSurveyChecksForm = (props: Props): JSX.Element => {
  const { surveyId, timePeriodId } = useParams();
  const { checks, onSave, surveyStatus } = props;
  const initialOrganisationalDetailsToConfirm: { [key in ContactType]?: boolean } = {};
  listOfContactTypes.forEach((key) => {
    initialOrganisationalDetailsToConfirm[key] = checks.organisationalDetailsToConfirm
      ? checks.organisationalDetailsToConfirm.includes(key)
      : false;
  });
  const [confirmRegisteredDetails, setConfirmRegisteredDetails] = useState<boolean>(checks.confirmRegisteredDetails);
  const [confirmFeesInvoiceEmail, setConfirmFeesInvoiceEmail] = useState<boolean>(checks.confirmFeesInvoiceEmail);
  const [confirmOrganisationalDetails, setConfirmOrganisationalDetails] = useState<boolean>(
    checks.organisationalDetailsToConfirm.length > 0,
  );
  const [organisationalDetailsToConfirm, setOrganisationalDetailsToConfirm] = useState<{
    [key in ContactType]?: boolean;
  }>(initialOrganisationalDetailsToConfirm);
  const [surveyDeclarations, setSurveyDeclarations] = useState<SurveyDeclaration[]>(checks.surveyDeclarations || []);
  const [setDirty, setPristine, isDirty] = useUnsavedChangesWarning();
  const [validated, setValidated] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const isSurveyClosed = surveyStatus === SurveyStatus.Closed;

  const organisationalDetailsError =
    confirmOrganisationalDetails && !Object.values(organisationalDetailsToConfirm).includes(true);

  const additionalDeclarationsError = surveyDeclarations.some((sd) => sd.declaration === '');

  const addNewSurveyDeclaration = (): void => {
    const newOrderIndex = surveyDeclarations ? surveyDeclarations.length + 1 : 1;
    const newId = surveyDeclarations.length !== 0 ? Math.max(...surveyDeclarations.map((d) => d.id)) + 1 : 1;

    const newDeclaration: SurveyDeclaration = {
      id: newId,
      orderIndex: newOrderIndex,
      declaration: '',
    };

    setSurveyDeclarations((prevData) =>
      surveyDeclarations == null ? [newDeclaration] : [...prevData, newDeclaration],
    );
    setDirty();
  };

  const deleteHandler = (declaration: SurveyDeclaration): void => {
    setSurveyDeclarations(surveyDeclarations?.filter((sd) => sd !== declaration));
    setDirty();
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    if (organisationalDetailsError || additionalDeclarationsError) {
      setValidated(true);
      return;
    }

    setSaving(true);
    const { success, errorMessage } = await onSave({
      confirmRegisteredDetails,
      confirmFeesInvoiceEmail,
      organisationalDetailsToConfirm: confirmOrganisationalDetails
        ? listOfContactTypes.filter((contactType) => organisationalDetailsToConfirm[contactType] === true)
        : [],
      surveyDeclarations: surveyDeclarations.map((sd) => sd.declaration),
    });
    if (success) {
      setPristine();
      setValidated(false);
      setError(null);
    } else {
      setError(errorMessage || null);
    }
    setSaving(false);
  };

  return (
    <Form noValidate onSubmit={onSubmit}>
      {error && (
        <Alert variant="danger" dismissible onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
      <div className="heading">End of Survey Checks</div>
      <EndOfSurveyCheckbox
        checked={confirmRegisteredDetails}
        setDirty={setDirty}
        setState={setConfirmRegisteredDetails}
        label="Confirm registered details"
        disabled={isSurveyClosed}
      />
      <EndOfSurveyCheckbox
        checked={confirmFeesInvoiceEmail}
        setDirty={setDirty}
        setState={setConfirmFeesInvoiceEmail}
        label="Confirm fees invoice email"
        disabled={isSurveyClosed}
      />
      <EndOfSurveyCheckbox
        checked={confirmOrganisationalDetails}
        setDirty={setDirty}
        setState={setConfirmOrganisationalDetails}
        label="Confirm organisational contact(s)"
        disabled={isSurveyClosed}
        describedBy={organisationalDetailsError ? 'organisational-details-feedback' : undefined}
      />
      {confirmOrganisationalDetails &&
        listOfContactTypes.map((contactType) => (
          <div key={contactType} className="checkboxContainer positions">
            <Checkbox
              id={contactType}
              checked={organisationalDetailsToConfirm[contactType]}
              disableRipple
              color="nrosh"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setOrganisationalDetailsToConfirm((prev) => ({
                  ...prev,
                  [contactType]: event.target.checked,
                }));
                setDirty();
              }}
              disabled={isSurveyClosed}
              aria-describedby={organisationalDetailsError ? 'organisational-details-feedback' : undefined}
            />
            <label htmlFor={contactType}>{ContactTypeTitleMapping[contactType]}</label>
          </div>
        ))}
      <AccessibleFeedback
        displayFeedback={validated && organisationalDetailsError}
        id="organisational-details-feedback"
      >
        You must select at least one position
      </AccessibleFeedback>
      <div className="checkboxContainer heading">
        <div>Additional declarations</div>
        {!isSurveyClosed && (
          <PrimaryButton type="button" className="addButton" onClick={() => addNewSurveyDeclaration()}>
            Add New Declaration
          </PrimaryButton>
        )}
      </div>
      {surveyDeclarations && surveyDeclarations.length ? (
        <>
          {surveyDeclarations.map((sd, i) => (
            <div key={sd.id} className="checkboxContainer inputGroup">
              <SurveyDeclarationInput
                surveyDeclarations={surveyDeclarations}
                setSurveyDeclarations={setSurveyDeclarations}
                setDirty={setDirty}
                surveyDeclaration={sd}
                index={i}
                readOnly={isSurveyClosed}
                validated={validated}
                deleteHandler={deleteHandler}
              />
            </div>
          ))}
        </>
      ) : (
        <div className="noDeclarations">
          No additional declarations have been created for this survey.
          {!isSurveyClosed &&
            ` Click "Add New Declaration" to add survey
          declarations.`}
        </div>
      )}

      <div className="pt-3 d-flex gap-3">
        <LinkButton
          to={generatePath(surveyPages.SurveyView.path, { surveyId, timePeriodId })}
          variant="outline-primary"
          disabled={isSaving}
        >
          {isSurveyClosed ? 'Return to Survey' : 'Cancel'}
        </LinkButton>
        {!isSurveyClosed && (
          <div>
            {!isSaving ? (
              <PrimaryButton type="submit" disabled={!isDirty || organisationalDetailsError}>
                Save
              </PrimaryButton>
            ) : (
              <LoadingButton message="Saving..." />
            )}
          </div>
        )}
      </div>
    </Form>
  );
};

export default EndOfSurveyChecksForm;
