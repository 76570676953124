import { DynamicsEntity } from 'nrosh-common/Api/CommonTypes';
import { ProviderRole, RshRole } from 'nrosh-common/Api/Enums';
import AuthContext, { Auth } from 'nrosh-common/Contexts/AuthContext';
import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { useContext, useEffect, useState } from 'react';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import { useErrorReporting } from '@/Components/Errors/DCSErrorBoundary';
import { ExportDocumentsButton } from '@/Components/FileDownload/ExportDocumentsButton';
import DocumentAdd from '@/Components/Icons/DocumentAdd';
import LinkButton from '@/Components/Links/LinkButton';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { ProvidersApi, RegulatoryDocumentsApi, placeHolderAPIErrorHandler } from '@/Helpers/Apis';
import { adminPages, providerPages } from '@/Pages/Home/SitePages';
import { SelectableRegulatoryDocument } from '@/Pages/RegulatoryDocuments/RegulatoryDocumentsTable';

const documentButtonText = (verb: string, selectedDocuments: SelectableRegulatoryDocument[]): string =>
  selectedDocuments.length > 0
    ? `${verb} ${selectedDocuments.length} Document${selectedDocuments.length !== 1 ? 's' : ''}`
    : `${verb} Documents`;

const UploadDocumentButton = ({ auth }: { auth: Auth }): JSX.Element => {
  const path = auth.hasRole(RshRole.UploadRegDocs)
    ? adminPages.UploadRegulatoryDocument.path
    : providerPages.UploadRegulatoryDocument.path;

  return (
    <LinkButton to={path}>
      <div className="d-flex align-items-center justify-content-center gap-2">
        Upload New Documents
        <DocumentAdd />
      </div>
    </LinkButton>
  );
};

type RegulatoryDocumentButtonsProps = {
  selectedDocuments: SelectableRegulatoryDocument[];
  setSuccessMessages: ReactStateSetter<string[]>;
};

const RegulatoryDocumentButtons = ({
  selectedDocuments,
  setSuccessMessages,
}: RegulatoryDocumentButtonsProps): JSX.Element => {
  const auth = useContext(AuthContext);

  const [isChoosingEntity, setIsChoosingEntity] = useState<boolean>(false);
  const [selectedEntityId, setSelectedEntityId] = useState<number | null>(null);

  const [isAssigning, setIsAssigning] = useState<boolean>(false);

  const providerIds = new Set(selectedDocuments.map((d) => d.providerId));
  const providerId = providerIds.size === 1 ? (providerIds.values().next().value as number) : null;
  const documentTypes = new Set(
    selectedDocuments.filter((d) => d.documentType).map((d) => d.documentType.documentType),
  );
  const documentType = documentTypes.size === 1 ? (documentTypes.values().next().value as string) : null;

  const [dynamicsEntities, setDynamicsEntities] = useState<DynamicsEntity[] | null>(null);

  const [raiseError] = useErrorReporting();

  useEffect(() => {
    const fetchEntities = async (id: number): Promise<void> => {
      setDynamicsEntities(null);
      const response = await ProvidersApi.getProviderDynamicsEntities(id).justErrors(placeHolderAPIErrorHandler);
      setDynamicsEntities(response || null);
    };

    setIsChoosingEntity(false);
    if (providerId !== null) {
      fetchEntities(providerId).catch(() => {});
    } else {
      setDynamicsEntities(null);
    }
  }, [providerId]);

  useEffect(() => {
    setIsChoosingEntity(false);
    setSelectedEntityId(null);
  }, [providerId, documentType]);

  const availableEntities =
    dynamicsEntities && documentType ? dynamicsEntities.filter((e) => e.documentType === documentType) : [];

  const nonDeletedDocuments = selectedDocuments.filter((d) => !d.hasBeenDeleted);

  const exportButtonText = documentButtonText('Download', nonDeletedDocuments);
  const assignButtonText = documentButtonText('Assign', nonDeletedDocuments);

  const assignSelectedDocuments = async (): Promise<void> => {
    setIsAssigning(true);
    try {
      await RegulatoryDocumentsApi.assignToEntity(
        selectedEntityId!,
        selectedDocuments.map((d) => d.id),
      ).raw;
      const message =
        selectedDocuments.length > 1
          ? `${selectedDocuments.length} Documents have been assigned to the selected entity.`
          : '1 Document has been assigned to the selected entity.';
      setSuccessMessages((prevMessages) => [...prevMessages, message]);
    } catch {
      raiseError();
    } finally {
      setIsAssigning(false);
      setIsChoosingEntity(false);
    }
  };

  return (
    <div className="d-flex flex-row gap-2 mb-3">
      {!isChoosingEntity ? (
        <>
          {auth.hasOneOfRoles(RshRole.UploadRegDocs, ProviderRole.UploadRegDocs) && (
            <UploadDocumentButton auth={auth} />
          )}
          <ExportDocumentsButton
            numFiles={nonDeletedDocuments.length}
            buttonText={exportButtonText}
            endpointCall={async () => RegulatoryDocumentsApi.downloadMany(nonDeletedDocuments.map((d) => d.id))}
          />
        </>
      ) : (
        <>
          <label htmlFor="dynamics-entity-select">
            Please select the Dynamics entity to assign to:
            <select
              id="dynamics-entity-select"
              value={selectedEntityId ?? ''}
              onChange={(e) => setSelectedEntityId(e.target.value ? Number(e.target.value) : null)}
            >
              <option value="" selected disabled hidden>
                Please select an entity...
              </option>
              {availableEntities.map((e) => (
                <option value={e.id}>{e.name}</option>
              ))}
            </select>
          </label>
          {isAssigning ? (
            <LoadingButton message={assignButtonText} />
          ) : (
            <PrimaryButton disabled={!selectedEntityId} onClick={() => assignSelectedDocuments()}>
              {assignButtonText}
            </PrimaryButton>
          )}
          <PrimaryButton colour="outline-primary" onClick={() => setIsChoosingEntity(false)}>
            Cancel
          </PrimaryButton>
        </>
      )}
    </div>
  );
};

export default RegulatoryDocumentButtons;
