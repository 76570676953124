import { ChangeEventHandler } from 'react';
import { Form } from 'react-bootstrap';

export type SelectEnumValueProps<T extends string | number> = {
  controlId: string;
  className?: string;
  label: string;
  value: T;
  options: Partial<Record<T, string>>;
  onChange: ChangeEventHandler<HTMLSelectElement>;
};

const SelectEnumValue = <T extends string | number>({
  controlId,
  onChange,
  value,
  className,
  options,
  label,
}: SelectEnumValueProps<T>): JSX.Element => {
  const optionKeys = Object.keys(options).map((k) => k as T);
  return (
    <Form.Group controlId={controlId} className={className}>
      <Form.Label>{label}</Form.Label>
      <Form.Select value={value ?? ''} onChange={onChange}>
        {optionKeys.map((optionKey) => (
          <option key={optionKey} value={optionKey}>
            {options[optionKey]}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
};

export default SelectEnumValue;
