import { CellStyle as CellStyleEnum, defaultCellStyle } from 'nrosh-common/Api/Enums';
import { memo } from 'react';
import { styleIsCalculation } from '@/Components/Spreadsheet/Cells/CellStyleHelpers';
import DataInputCell from '@/Components/Spreadsheet/Cells/DataInputCell';
import ListInputCell from '@/Components/Spreadsheet/Cells/ListInputCell';
import SlicerCell from '@/Components/Spreadsheet/Cells/SlicerCell';
import TextCell, { TextContentCell } from '@/Components/Spreadsheet/Cells/TextCell';
import TotalCell from '@/Components/Spreadsheet/Cells/TotalCell';
import VariableDimensionCell from '@/Components/Spreadsheet/Cells/VariableDimensionCell';
import { CellLayout, SpreadsheetStaticState } from '@/Components/Spreadsheet/Spreadsheet';
import { CellStyle, FreezePaneSpan } from '@/Components/Spreadsheet/SpreadsheetTypes';
import { useSubmissionPartStaticData } from '@/Pages/Submissions/SubmissionPartContext/SubmissionPartStaticDataContext';

const spreadsheetCellPadding = 4;

export const commonCellProps = (
  row: number,
  column: number,
  rowHeights: number[],
  columnWidths: number[],
  cellStyle?: CellStyle,
): { key: string; style: CellStyleEnum; height: number; width: number; rowSpan: number; columnSpan: number } => {
  const cellKey = `row-${row}-col-${column}`;
  const height = rowHeights.slice(row, row + (cellStyle?.rowSpan ?? 1)).reduce((a, b) => a + b);
  const width =
    columnWidths.slice(column, column + (cellStyle?.columnSpan ?? 1)).reduce((a, b) => a + b) -
    2 * spreadsheetCellPadding;
  return {
    key: cellKey,
    style: cellStyle?.style || defaultCellStyle,
    height,
    width,
    rowSpan: cellStyle?.rowSpan ?? 1,
    columnSpan: cellStyle?.columnSpan ?? 1,
  };
};

export const getFreezePaneProps = (
  row: number,
  column: number,
  accumulatedRowHeights: number[],
  accumulatedColumnWidths: number[],
  freezePane: FreezePaneSpan,
): { isInFreezePane: boolean; top: number | undefined; left: number | undefined } => {
  if (freezePane.rows === 0 && freezePane.columns === 0) {
    return {
      isInFreezePane: false,
      top: undefined,
      left: undefined,
    };
  }

  return {
    isInFreezePane: row < freezePane.rows || column < freezePane.columns,
    top: row < freezePane.rows ? accumulatedRowHeights[row] : undefined,
    left: column < freezePane.columns ? accumulatedColumnWidths[column] : undefined,
  };
};

const SpreadsheetCell = (props: {
  cellLayout: CellLayout;
  staticState: SpreadsheetStaticState;
  cellKey: string;
  active: boolean;
  cellValue: string;
  dimension1MemberId: number | null;
  dimension2MemberId: number | null;
}): JSX.Element | null => {
  const { cellLayout, staticState, cellKey, active, cellValue, dimension1MemberId, dimension2MemberId } = props;
  const { setActiveCell, updateDataPoint } = staticState;
  const { readonly } = useSubmissionPartStaticData();

  if (!cellLayout) return null;

  const { cellStyle, cellProps } = cellLayout;

  if (!cellStyle) {
    return <TextCell {...cellProps} />;
  }

  if ('content' in cellStyle) {
    const { content } = cellStyle;
    return <TextContentCell {...cellProps} content={content} />;
  }

  if ('dimensionId' in cellStyle) {
    const isSlicer = !('index' in cellStyle);
    if (isSlicer) {
      return <SlicerCell baseCellProps={cellProps} cellStyle={cellStyle} staticState={staticState} />;
    }
    return <VariableDimensionCell {...cellProps} staticState={staticState} cellStyle={cellStyle} />;
  }

  const baseDataCellProps = {
    ...cellProps,
    cellStyle,
    isActive: active,
    cellKey,
    setActiveCell,
    value: cellValue,
    dimension1Id: dimension1MemberId,
    dimension2Id: dimension2MemberId,
  };

  if (styleIsCalculation(cellStyle.style)) {
    return <TotalCell {...baseDataCellProps} />;
  }

  const isReadonly =
    readonly ||
    (cellStyle.dataPoint.dimension1 !== null && dimension1MemberId === null) ||
    (cellStyle.dataPoint.dimension2 !== null && dimension2MemberId === null);

  if (cellStyle.listTypeName) {
    return (
      <ListInputCell
        {...baseDataCellProps}
        updateDataPoint={updateDataPoint}
        listOptions={staticState.listTypes.find((lt) => lt.name === cellStyle.listTypeName!)}
        readonly={isReadonly}
      />
    );
  }

  return <DataInputCell {...baseDataCellProps} updateDataPoint={updateDataPoint} readonly={isReadonly} />;
};

export default memo(SpreadsheetCell);
