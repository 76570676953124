import { Alert } from 'react-bootstrap';

export type ErrorStatus = {
  status: boolean;
  message?: string | null;
};

export type ErrorMessageProps = {
  isAdmin: boolean;
  error: ErrorStatus;
  clearError: () => void;
};

export const rshUnknownErrorMessage = 'There was a problem handling your request.';

export const providerUnknownErrorMessage = `${rshUnknownErrorMessage} If the problem persists, please contact the DCS Enquiries team at Team-DCS@softwire.com`;

export const ErrorMessage = (props: ErrorMessageProps): JSX.Element | null => {
  const { isAdmin, error, clearError } = props;
  const unknownErrorMessage = isAdmin ? rshUnknownErrorMessage : providerUnknownErrorMessage;

  return error.status ? (
    <Alert variant="danger" dismissible onClose={clearError}>
      {error.message ?? unknownErrorMessage}
    </Alert>
  ) : null;
};
