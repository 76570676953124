import { ProviderRole } from 'nrosh-common/Api/Enums';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import { useContext, useState } from 'react';
import { PrimaryButton, TertiaryButton } from '@/Components/Buttons/DCSButton';
import { useValidationModel } from '@/Components/Validations/ValidationAccordionItem';
import ValidationCommentDetail from '@/Components/Validations/ValidationCommentDetail';
import ValidationCommentForm from '@/Components/Validations/ValidationCommentForm';
import { useValidationsAccordionContext } from '@/Components/Validations/Validations';

const ValidationComment = ({ commentOnServer }: { commentOnServer: string | undefined }): JSX.Element | null => {
  const { validationId } = useValidationModel();
  const { commentsAreReadonly, deleteComment } = useValidationsAccordionContext();
  const auth = useContext(AuthContext);

  const commentExists = !!commentOnServer;
  const [editing, setEditing] = useState(!commentExists && auth.hasRole(ProviderRole.EditSurveyData));
  const [comment, setComment] = useState(commentOnServer);

  if (commentsAreReadonly) {
    return <ValidationCommentDetail />;
  }

  const onDelete = (): void => {
    deleteComment(validationId);
    setEditing(true);
    setComment(undefined);
  };

  if (editing) {
    return (
      <ValidationCommentForm
        comment={comment}
        setComment={setComment}
        editingExistingComment={commentExists}
        closeEditor={() => setEditing(false)}
      />
    );
  }

  if (commentExists) {
    return (
      <div>
        <ValidationCommentDetail />
        <div className="buttonContainer gap-1">
          <PrimaryButton size="sm" type="button" onClick={() => setEditing(true)}>
            Edit
          </PrimaryButton>
          {auth.hasRole(ProviderRole.EditSurveyData) && (
            <TertiaryButton size="sm" colour="primary" onClick={onDelete}>
              Delete
            </TertiaryButton>
          )}
        </div>
      </div>
    );
  }

  return null;
};

export default ValidationComment;
