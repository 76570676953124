import { IdType, Row } from 'react-table';

export const caseInsensitiveSort = <TData extends object>(
  rowA: Row<TData>,
  rowB: Row<TData>,
  columnId: IdType<TData>,
): number => {
  const comparisonProperty = columnId as keyof TData;
  const rowAString = (rowA.original[comparisonProperty] ?? '') as string;
  const rowBString = (rowB.original[comparisonProperty] ?? '') as string;

  if (rowAString.toUpperCase() > rowBString.toUpperCase()) {
    return 1;
  }
  if (rowAString.toUpperCase() < rowBString.toUpperCase()) {
    return -1;
  }

  return 0;
};
