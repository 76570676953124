import { SubmissionStatus } from 'nrosh-common/Api/Enums';
import {
  CrossPartValidationIssue,
  SubmissionPartValidation,
  SubmissionPartValidationComment,
  SubmissionValidationIssues,
  SurveyStatus,
  ValidationIssues,
  ValidationUpdate,
} from 'nrosh-common/Api/SubmissionsApi';
import { ValidationModel } from '@/Components/Validations/ValidationTypes';

export const canBeSubmitted = (status: SubmissionStatus, surveyStatus: SurveyStatus): boolean =>
  (status === SubmissionStatus.InProgress || status === SubmissionStatus.Unsubmitted) &&
  surveyStatus !== SurveyStatus.Closed;
export const canBeUnsubmitted = (status: SubmissionStatus, surveyStatus: SurveyStatus): boolean =>
  status === SubmissionStatus.Submitted && surveyStatus !== SurveyStatus.Closed;
export const canBeSignedOff = (status: SubmissionStatus, surveyStatus: SurveyStatus): boolean =>
  status === SubmissionStatus.Submitted && surveyStatus !== SurveyStatus.Closed;
export const canBeUnSignedOff = (status: SubmissionStatus, surveyStatus: SurveyStatus): boolean =>
  status === SubmissionStatus.SignedOff && surveyStatus !== SurveyStatus.Closed;
export const hasBeenSubmitted = (status: SubmissionStatus): boolean =>
  status === SubmissionStatus.Submitted || status === SubmissionStatus.SignedOff;

export const submissionIsReadonly = (status: SubmissionStatus, isAdmin: boolean, surveyStatus: SurveyStatus): boolean =>
  status === SubmissionStatus.SignedOff ||
  (!isAdmin && status === SubmissionStatus.Submitted) ||
  surveyStatus === SurveyStatus.Closed;

export const mergeValidationsWithCurrentStatus = (
  submissionValidations: SubmissionPartValidation[],
  validationStatuses: ValidationUpdate[],
  validationIssues: ValidationIssues[],
  validationComments?: SubmissionPartValidationComment[] | null,
): SubmissionPartValidation[] =>
  submissionValidations.map((v) => {
    const validationComment = validationComments?.find((vc) => vc.validationId === v.validationId);
    const validationStatus = validationStatuses.find((vs) => vs.validationRef === v.validationId);
    return {
      ...v,
      // A ValidationIssue will only be created the first time a validation fails, so if one doesn't exist we assume the
      // validation is passing
      isPassing: validationStatus?.currentStatus ?? true,
      errorDimensions: validationStatus?.errorDimensions ?? [],
      isApproved: validationIssues.find((vi) => vi.validationId === v.validationId)?.isValidationApproved ?? false,
      comment: validationComment?.comment,
      commentDateTime: validationComment?.dateTime,
      commentUserName: validationComment?.userName,
    };
  });

export const mapCrossPartValidationToViewModel = (validation: CrossPartValidationIssue): ValidationModel => ({
  description: validation.description,
  shortText: validation.shortText,
  formula: validation.formula,
  type: validation.type,
  validationId: validation.validationId,
  isPassing: validation.isPassing,
  errorDimensions: [], // Cross part validations are not allowed to be dimensional
  isApproved: validation.isApproved,
  comment: validation.comment ?? undefined,
  commentUserName: validation.commentUserName ?? undefined,
  commentDateTime: validation.commentDateTime ?? undefined,
  isCrossPartValidation: true,
});

export const mapSubmissionValidationsToViewModels = (validations: SubmissionValidationIssues): ValidationModel[] =>
  (
    mergeValidationsWithCurrentStatus(
      validations.submissionValidations,
      validations.validationStatuses,
      validations.validationIssues,
      validations.validationComments,
    ) as ValidationModel[]
  ).concat(validations.crossPartValidations.map(mapCrossPartValidationToViewModel));
