import { Stack } from 'react-bootstrap';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import LandingPage from '@/Components/LandingPage/LandingPage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { adminPages } from '@/Pages/Home/SitePages';

const AuditsLandingPage = (): JSX.Element => (
  <Stack gap={5}>
    <PageHeader heading="Audits" crumbsType={RootPathType.AdminAudits} />
    <LandingPage
      description="View the system audits."
      pages={[
        { name: 'User audit', path: adminPages.AdminUserAudit.path },
        { name: 'Detailed audit trail', path: adminPages.AdminDetailedAuditTrail.path },
      ]}
    />
  </Stack>
);

export default AuditsLandingPage;
