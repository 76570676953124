import { useCallback, useEffect, useMemo, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { TertiaryButton } from '@/Components/Buttons/DCSButton';

type NavigatorProps = {
  count: number;
  index: number;
  next: () => void;
  prev: () => void;
};

const Navigator = ({ count, index, next, prev }: NavigatorProps): JSX.Element | null =>
  count <= 1 ? null : (
    <div className="d-flex align-items-center">
      <TertiaryButton aria-label="Previous error" disabled={index <= 0} type="button" onClick={() => prev()}>
        {'<'}
      </TertiaryButton>
      {`${index + 1}/${count}`}
      <TertiaryButton aria-label="Next error" disabled={index >= count - 1} type="button" onClick={() => next()}>
        {'>'}
      </TertiaryButton>
    </div>
  );

export const NavigableAlertMessageList = ({ messages }: { messages: string[] }): JSX.Element | null => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [visibleMessages, setVisibleMessages] = useState<boolean[]>([]);

  const visibleMessageIndices = useMemo<number[]>(
    () => messages.map((_, index): number => index).filter((_, i) => visibleMessages[i]),
    [messages, visibleMessages],
  );

  const clamp = useCallback(
    (value: number) => Math.max(0, Math.min(visibleMessageIndices.length - 1, value)),
    [visibleMessageIndices],
  );

  const activeMessageIndex = visibleMessageIndices[selectedIndex] || 0;

  const next = (): void => setSelectedIndex((prevSelectedIndex) => clamp(prevSelectedIndex + 1));
  const prev = (): void => setSelectedIndex((prevSelectedIndex) => clamp(prevSelectedIndex - 1));
  const remove = (index: number): void => {
    setVisibleMessages((prevVisibleMessages) => prevVisibleMessages.map((v, i) => v && i !== index));
    prev();
  };

  useEffect(() => {
    setSelectedIndex(0);
    setVisibleMessages(messages.map(() => true));
  }, [messages]);

  return visibleMessageIndices.length === 0 ? null : (
    <div className="d-flex flex-column w-100" role="log">
      <Alert variant="warning" dismissible onClose={() => remove(activeMessageIndex)}>
        {messages[activeMessageIndex]}
      </Alert>
      <div className="align-self-end">
        <Navigator count={visibleMessageIndices.length} index={selectedIndex} next={next} prev={prev} />
      </div>
    </div>
  );
};
