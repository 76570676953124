import { Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

type Props = {
  userPages: {
    name: string;
    path: string;
  }[];
  displayName: string;
  className?: string;
};

const UserDetails = ({ userPages, displayName, className }: Props): JSX.Element => (
  <NavDropdown className={className} title={`Hello, ${displayName}`}>
    {userPages.map((page) => (
      <Nav.Link id="dropdownLink" eventKey={page.path} key={page.path} as={Link} to={page.path}>
        {page.name}
      </Nav.Link>
    ))}
  </NavDropdown>
);

export default UserDetails;
