import ApiClient, { APIHelpers, handleAPIRequest } from './ApiClient';
import { SurveyStatus } from './SubmissionsApi';

type EmailBase = {
  subject?: string;
  htmlBody?: string | null;
  plainTextBody?: string | null;
  status?: ScheduledEmailStatus | null;
  recipientsFilter?: EmailRecipientsFilter | null;
};

export type EmailRecipientsFilter = {
  surveyInstanceIds: string | null;
  submissionStatuses: string | null;
  providerSizes: string | null;
  providerTypes: string | null;
  reportingStatuses: string | null;
  excludedProviderIds: string | null;
  providerContactsContactTypes: string | null;
  userProfiles: string | null;
};

export type ScheduledEmailRequest = EmailBase & {
  sendTime?: Date | null;
  recipients?: EmailRecipient[];
};

export type ScheduledEmailResponse = EmailBase & {
  id: number;
  sendTime: string;
  emailObjects: EmailFileObjectResponse[];
  emailType: EmailType;
  recipients?: EmailRecipientResponse[];
  lastEditedByName?: string;
  lastEditedAt: string;
};

export type ScheduledManualEmailSummaryResponse = {
  id: number;
  subject: string;
  status: ScheduledEmailStatus;
  sendTime: string;
  recipientsFilter?: EmailRecipientsFilter | null;
  numberOfAttachments: number;
  numberOfRecipients: number;
  lastEditedByName: string;
  lastEditedAt: string;
};

export enum EmailType {
  SystemFixed = 'SystemFixed',
  SystemVariable = 'SystemVariable',
  Manual = 'Manual',
}

export enum ScheduledEmailStatus {
  Draft = 'Draft',
  Queued = 'Queued',
  Sending = 'Sending',
  Sent = 'Sent',
  Failed = 'Failed',
  PartiallyFailed = 'Partially Failed',
}

export type EmailRecipient = {
  name: string;
  address: string;
  recipientType: EmailRecipientType;
  userName: string;
  providerName?: string;
  providerNumber?: string;
};

export type EmailRecipientResponse = EmailRecipient & {
  hasSent: boolean;
};

export enum EmailRecipientType {
  To = 'To',
  Cc = 'Cc',
  Bcc = 'Bcc',
}

export enum EmailFileObjectType {
  LinkedResource = 'LinkedResource',
  Attachment = 'Attachment',
}

export type EmailFileObjectResponse = {
  emailFileObjectType: EmailFileObjectType;
  fileName: string;
  id: number;
};

export type EmailFileObjectRequest = {
  type: EmailFileObjectType;
  fileName: string;
  content: Blob;
};

export enum EmailTemplateType {
  NewProviderUser = 'NewProviderUser',
  NewDcsUser = 'NewDcsUser',
  ProviderUserPasswordReset = 'ProviderUserPasswordReset',
  DcsUserPasswordReset = 'DcsUserPasswordReset',
  ProviderUserUsernameReminder = 'ProviderUserUsernameReminder',
  DcsUserUsernameReminder = 'DcsUserUsernameReminder',
  ProviderUserAccountLock = 'ProviderUserAccountLock',
  DcsUserAccountLock = 'DcsUserAccountLock',
  ProviderUserAccountDisabled = 'ProviderUserAccountDisabled',
  DcsUserAccountDisabled = 'DcsUserAccountDisabled',
  ProviderUserAccountReactivated = 'ProviderUserAccountReactivated',
  DcsUserAccountReactivated = 'DcsUserAccountReactivated',
  ValidationAccepted = 'ValidationAccepted',
  SurveySubmitted = 'SurveySubmitted',
  SurveyAssigned = 'SurveyAssigned',
  SurveyDeadlineUpdated = 'SurveyDeadlineUpdated',
  SurveyUnsubmitted = 'SurveyUnsubmitted',
  SurveySignOff = 'SurveySignOff',
  SurveyReminderDefault = 'SurveyReminderDefault',
  Footer = 'Footer',
  ConfirmEmail = 'ConfirmEmail',
}

export type EmailTemplateResponse = {
  templateType: EmailTemplateType;
  templateSubject: string;
  templateText: string;
};

export type EmailTemplateRequest = {
  templateSubject: string | null;
  templateText: string | null;
};

export type SurveyReminderRequest = {
  templateText: string;
  templateSubject: string;
  reminderDays: string | null;
};

export type SurveyReminderResponse = SurveyReminderRequest & {
  surveyId: number;
  timePeriodId: number;
  surveyInstanceName: string;
  surveyStatus: SurveyStatus;
};

const linkedResourcePrefix = 'LINKEDRES_';

const EmailsApiRoot = 'Emails';
const EmailsApiPaths = {
  SCHEDULED_MANUAL_EMAILS: `${EmailsApiRoot}/Manual`,
  CREATE_SCHEDULED_EMAIL: `${EmailsApiRoot}/Scheduled`,
  SCHEDULED_EMAIL: (emailId: string) => `${EmailsApiRoot}/Scheduled/${emailId}`,
  UPDATE_SCHEDULED_ATTACHMENTS: (emailId: string) => `${EmailsApiRoot}/Scheduled/${emailId}/Attachments`,
  EMAIL_TEMPLATES: `${EmailsApiRoot}/Automated/Templates`,
  EMAIL_TEMPLATE: (templateType: string) => `${EmailsApiRoot}/Automated/Templates/${templateType}`,
  SURVEY_REMINDERS: `${EmailsApiRoot}/Reminders`,
  SURVEY_REMINDER: (surveyId: string, timePeriodId: string) =>
    `${EmailsApiRoot}/Reminders/Surveys/${surveyId}/TimePeriods/${timePeriodId}`,
  GENERATE_EMAIL_OBJECT_PATH: (emailObjectId: string) => `${EmailsApiRoot}/Objects/${emailObjectId}`,
  FILTERED_EMAIL_RECIPIENTS: `${EmailsApiRoot}/Recipients`,
};

export const emailsApi = (apiClient: ApiClient) => ({
  getScheduledManualEmails: () => {
    return handleAPIRequest(
      apiClient.get(EmailsApiPaths.SCHEDULED_MANUAL_EMAILS),
      APIHelpers.json<ScheduledManualEmailSummaryResponse[]>,
      APIHelpers.standardError,
    );
  },

  createScheduledEmail: () => {
    return handleAPIRequest(
      apiClient.post(EmailsApiPaths.CREATE_SCHEDULED_EMAIL, {}),
      APIHelpers.json<number>,
      APIHelpers.standardError,
    );
  },

  getScheduledEmail: (emailId: string) => {
    return handleAPIRequest(
      apiClient.get(EmailsApiPaths.SCHEDULED_EMAIL(emailId)),
      APIHelpers.json<ScheduledEmailResponse>,
      APIHelpers.standardError,
    );
  },

  patchScheduledEmail: (emailId: string, scheduledEmail: ScheduledEmailRequest) => {
    return handleAPIRequest(
      apiClient.patch(EmailsApiPaths.SCHEDULED_EMAIL(emailId), scheduledEmail),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  deleteScheduledEmail: (emailId: string) => {
    return handleAPIRequest(
      apiClient.deleteAction(EmailsApiPaths.SCHEDULED_EMAIL(emailId), {}),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  updateScheduledAttachments: (emailId: string, retainedFiles: string[], files: EmailFileObjectRequest[]) => {
    const formData = new FormData();
    for (const file of files) {
      const formDataElementName =
        file.type === EmailFileObjectType.LinkedResource ? linkedResourcePrefix + file.fileName : file.fileName;
      formData.append(formDataElementName, file.content, file.fileName);
    }
    formData.append('AttachmentRequest', JSON.stringify({ fileNamesToRetain: retainedFiles }));
    return handleAPIRequest(
      apiClient.patchFile(EmailsApiPaths.UPDATE_SCHEDULED_ATTACHMENTS(emailId), formData),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  getEmailTemplates: () => {
    return handleAPIRequest(
      apiClient.get(EmailsApiPaths.EMAIL_TEMPLATES),
      APIHelpers.json<EmailTemplateResponse[]>,
      APIHelpers.standardError,
    );
  },

  getEmailTemplate: (templateType: string) => {
    return handleAPIRequest(
      apiClient.get(EmailsApiPaths.EMAIL_TEMPLATE(templateType)),
      APIHelpers.json<EmailTemplateResponse>,
      APIHelpers.standardError,
    );
  },

  patchEmailTemplate: (templateType: string, templateRequest: EmailTemplateRequest) => {
    return handleAPIRequest(
      apiClient.patch(EmailsApiPaths.EMAIL_TEMPLATE(templateType), templateRequest),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  getSurveyReminders: () => {
    return handleAPIRequest(
      apiClient.get(EmailsApiPaths.SURVEY_REMINDERS),
      APIHelpers.json<SurveyReminderResponse[]>,
      APIHelpers.standardError,
    );
  },

  getSurveyReminder: (surveyId: string, timePeriodId: string) => {
    return handleAPIRequest(
      apiClient.get(EmailsApiPaths.SURVEY_REMINDER(surveyId, timePeriodId)),
      APIHelpers.json<SurveyReminderResponse>,
      APIHelpers.standardError,
    );
  },

  patchSurveyReminder: (surveyId: string, timePeriodId: string, templateRequest: SurveyReminderRequest) => {
    return handleAPIRequest(
      apiClient.patch(EmailsApiPaths.SURVEY_REMINDER(surveyId, timePeriodId), templateRequest),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  generateEmailObjectPath: (emailObjectId: string) => {
    return apiClient.generatePath(EmailsApiPaths.GENERATE_EMAIL_OBJECT_PATH(emailObjectId));
  },

  getFilteredEmailRecipients: (emailRecipientsFilterRequest: EmailRecipientsFilter) => {
    return handleAPIRequest(
      apiClient.get(EmailsApiPaths.FILTERED_EMAIL_RECIPIENTS, emailRecipientsFilterRequest),
      APIHelpers.json<EmailRecipientResponse[]>,
      APIHelpers.standardError,
    );
  },
});

export default emailsApi;
