import { IconProps } from '@/Components/Icons/Icons';

export const Circle = ({ ariaLabel, className }: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className={`bi bi-circle ${className}`}
    viewBox="0 0 16 16"
    aria-label={ariaLabel}
  >
    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
  </svg>
);
