import AuthContext from 'nrosh-common/Contexts/AuthContext';
import { useContext } from 'react';
import LoginLink from '@/Components/Navbar/LoginLink';
import SignOutLink from '@/Components/Navbar/SignOutLink';
import UserDetails from '@/Components/Navbar/UserDetails';
import userLogo from '@/user-logo.png';

type Props = {
  userPages: { name: string; path: string }[];
  logout: () => Promise<void>;
};

const UserActions = ({ userPages, logout }: Props): JSX.Element => {
  const auth = useContext(AuthContext);

  return (
    <div className="userActionsContainer d-none d-md-flex">
      <img src={userLogo as string} alt="" width="31" height="31" />
      {auth.user ? (
        <>
          <UserDetails userPages={userPages} displayName={auth.user.displayName} />
          <div className="userActionsDivider">|</div>
          <SignOutLink logout={logout} />
        </>
      ) : (
        <LoginLink />
      )}
    </div>
  );
};

export default UserActions;
