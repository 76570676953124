import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ children }: { children: JSX.Element }): JSX.Element => {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    // See https://stackoverflow.com/questions/59069417/how-to-instantly-scroll-to-position
    const root = document.querySelector(':root')!;
    root.setAttribute('style', 'scroll-behavior: auto;');

    // Timeout ensures styles are applied before scrolling
    setTimeout(() => {
      window.scrollTo(0, 0);

      // Reset to CSS defaults.
      root.removeAttribute('style');
    }, 0);
  }, [pathname]);

  return children;
};

export default ScrollToTop;
