import { useScreenReaderMessageContext } from '@/Pages/Home/Navigation/ScreenReaderMessagesContext';

const ScreenReaderMessage = (): JSX.Element => {
  const { message } = useScreenReaderMessageContext();
  return (
    <div className="visually-hidden" role="status" aria-live="polite" aria-atomic="true">
      {message}
    </div>
  );
};

export default ScreenReaderMessage;
