import { Link } from 'react-router-dom';
import { Crumb } from '@/Components/Breadcrumbs/Breadcrumbs';

type Props = {
  isLastCrumb: boolean;
  crumb: Crumb;
};

export const Breadcrumb = (props: Props): JSX.Element => {
  const { isLastCrumb, crumb } = props;
  return isLastCrumb ? (
    <div>{crumb.name}</div>
  ) : (
    <div className="d-flex">
      <Link to={crumb.path}>{crumb.name}</Link>
      <div className="mx-3">{'>'}</div>
    </div>
  );
};
