import { PublicDocumentMetadata } from 'nrosh-common/Api/PublicDocumentsApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { PublicDocumentsApi } from '@/Helpers/Apis';
import PublicDocumentForm from '@/Pages/Content/PublicDocuments/PublicDocumentForm';
import { adminPages } from '@/Pages/Home/SitePages';

const EditPublicDocumentPage = (): JSX.Element => {
  const { documentId } = useParams();
  const [document] = useEndpoint<PublicDocumentMetadata>(PublicDocumentsApi.getMetadata, documentId);

  if (!document) {
    return <LoadingMessage />;
  }

  return (
    <Stack gap={5}>
      <PageHeader
        heading="Edit document"
        crumbsType={RootPathType.AdminContent}
        crumbs={[
          {
            name: 'Manage documents',
            path: adminPages.AdminPublicDocuments.path,
          },
          {
            name: 'Edit document',
            path: adminPages.AdminEditPublicDocument.path,
          },
        ]}
      />
      <PublicDocumentForm
        document={document}
        submitButtonMessage="Save"
        submitAction={(formData) => PublicDocumentsApi.update(documentId!, formData)}
      />
    </Stack>
  );
};

export default EditPublicDocumentPage;
