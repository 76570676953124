import { ContactType } from 'nrosh-common/Api/SurveysApi';

export const ContactTypeTitleMapping: Record<ContactType, string> = {
  ChiefExecutive: 'Chief Executive (or equivalent position)',
  Chair: 'Chair (or equivalent position)',
  FinanceDirector: 'Finance Director',
  AuditCommitteeChair: 'Audit Committee Chair',
  RegulatoryContact: 'Regulatory Contact',
  FinancialReturnsSubmissionPerson: 'Person responsible for submission of all Financial Returns',
  FinancialSurveyDataEntryContact: 'Financial Survey Data Entry Contact',
  FeesInvoiceContact: 'Fees Invoice Contact',
};
