import { Editor } from '@tiptap/react';
import { useCallback } from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { GrReturn } from 'react-icons/gr';
import {
  RiAlignLeft,
  RiArrowGoBackLine,
  RiArrowGoForwardLine,
  RiBold,
  RiCodeFill,
  RiDoubleQuotesL,
  RiH1,
  RiH2,
  RiH3,
  RiItalic,
  RiLink,
  RiListOrdered,
  RiListUnordered,
  RiMarkPenLine,
  RiStrikethrough,
  RiTable2,
  RiUnderline,
} from 'react-icons/ri';
import { getEditorCommands } from '@/Components/ContentEditor/EditorCommands';
import { TemplateField, TemplateFieldGroup, TemplatesDropdownMenuItems } from '@/Pages/Emails/EmailTemplates';
import '@/Components/ContentEditor/MenuBar.scss';

const HexColors = [
  { hex: '#000000', name: 'Black' },
  { hex: '#c33a32', name: 'Red' },
  { hex: '#639f57', name: 'Green' },
  { hex: '#fcbe37', name: 'Yellow' },
  { hex: '#4097db', name: 'Blue' },
  { hex: '#59468d', name: 'Purple' },
];

const defaultColor = '#000000';

export type MenuBarProps = {
  editor: Editor;
  hasH1Tag?: boolean;
  templateFieldGroups?: TemplateFieldGroup[];
  requiredTemplateFields?: TemplateField[];
};

const MenuBar = (props: MenuBarProps): JSX.Element | null => {
  const { editor, hasH1Tag, templateFieldGroups, requiredTemplateFields = [] } = props;

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href as string;
    const url = window.prompt('URL', previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();

      return;
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
  }, [editor]);

  if (!editor) {
    return null;
  }

  const editorCommands = getEditorCommands(editor);

  return (
    <div className="menuBar">
      <button
        type="button"
        title="Undo"
        aria-label="Undo"
        onClick={editorCommands.undo}
        disabled={!editorCommands.canUndo}
      >
        <RiArrowGoBackLine size="1.25rem" style={{ margin: 'auto' }} />
      </button>
      <button
        type="button"
        title="Redo"
        aria-label="Redo"
        onClick={editorCommands.redo}
        disabled={!editorCommands.canRedo}
      >
        <RiArrowGoForwardLine size="1.25rem" style={{ margin: 'auto' }} />
      </button>
      <Dropdown as={ButtonGroup} size="md" style={{ display: 'flex' }}>
        <button type="button" title="Paragraph Align" aria-label="Paragraph Align" className="alignButton">
          <RiAlignLeft size="1.25rem" />
        </button>
        <Dropdown.Toggle split id="align-dropdown" />
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => editorCommands.setTextAlign('left')}>Left Align</Dropdown.Item>
          <Dropdown.Item onClick={() => editorCommands.setTextAlign('center')}>Center Align</Dropdown.Item>
          <Dropdown.Item onClick={() => editorCommands.setTextAlign('right')}>Right Align</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <input
        className="colorInput"
        title="Color"
        type="color"
        onChange={(event) => editorCommands.setColor(event.target.value)}
        value={(editor.getAttributes('textStyle').color as string) || defaultColor}
      />
      <Dropdown as={ButtonGroup} size="md" style={{ display: 'flex' }}>
        <Dropdown.Toggle split id="color-dropdown" />
        <Dropdown.Menu>
          {HexColors.map((color) => (
            <Dropdown.Item key={color.hex} onClick={() => editorCommands.setColor(color.hex)}>
              <div className="colorContainer">
                <div className={`colorIndicator-${color.name}`} />
                {color.name}
              </div>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <button
        type="button"
        title="Bullet List"
        aria-label="Bullet List"
        onClick={editorCommands.toggleBulletList}
        className={editor.isActive('bulletList') ? 'is-active' : ''}
      >
        <RiListUnordered size="1.25rem" style={{ margin: 'auto' }} />
      </button>
      <button
        type="button"
        title="Numbered List"
        aria-label="Numbered List"
        onClick={editorCommands.toggleOrderedList}
        className={editor.isActive('orderedList') ? 'is-active' : ''}
      >
        <RiListOrdered size="1.25rem" style={{ margin: 'auto' }} />
      </button>
      <button
        type="button"
        title="Link"
        aria-label="Link"
        onClick={setLink}
        className={editor.isActive('link') ? 'is-active' : ''}
      >
        <RiLink size="1.25rem" style={{ margin: 'auto' }} />
      </button>
      <button
        type="button"
        title="Block Quote"
        aria-label="Block Quote"
        onClick={editorCommands.toggleBlockQuote}
        className={editor.isActive('blockquote') ? 'is-active' : ''}
      >
        <RiDoubleQuotesL size="1.25rem" style={{ margin: 'auto' }} />
      </button>
      <button
        type="button"
        title="Bold"
        aria-label="Bold"
        onClick={editorCommands.toggleBold}
        disabled={!editorCommands.canToggleBold}
        className={editor.isActive('bold') ? 'is-active' : ''}
      >
        <RiBold size="1.25rem" style={{ margin: 'auto' }} />
      </button>
      <button
        type="button"
        title="Italic"
        aria-label="Italic"
        onClick={editorCommands.toggleItalic}
        disabled={!editorCommands.canToggleItalic}
        className={editor.isActive('italic') ? 'is-active' : ''}
      >
        <RiItalic size="1.25rem" style={{ margin: 'auto' }} />
      </button>
      <button
        type="button"
        title="Underline"
        aria-label="Underline"
        onClick={editorCommands.toggleUnderline}
        disabled={!editorCommands.canToggleUnderline}
        className={editor.isActive('underline') ? 'is-active' : ''}
      >
        <RiUnderline size="1.25rem" style={{ margin: 'auto' }} />
      </button>
      <button
        type="button"
        title="Strikethrough"
        aria-label="Strikethrough"
        onClick={editorCommands.toggleStrikethrough}
        disabled={!editorCommands.canToggleStrikethrough}
        className={editor.isActive('strike') ? 'is-active' : ''}
      >
        <RiStrikethrough size="1.25rem" style={{ margin: 'auto' }} />
      </button>
      <button
        type="button"
        title="Highlight"
        aria-label="Highlight"
        onClick={editorCommands.toggleHighlight}
        className={editor.isActive('highlight') ? 'is-active' : ''}
      >
        <RiMarkPenLine size="1.25rem" style={{ margin: 'auto' }} />
      </button>

      <Dropdown as={ButtonGroup} size="md" style={{ display: 'flex' }}>
        <button
          type="button"
          className="tableDropdown"
          title="Table"
          aria-label="Table"
          onClick={editorCommands.table.insert}
        >
          <RiTable2 size="1.25rem" style={{ margin: 'auto' }} />
        </button>

        <Dropdown.Toggle split id="table-dropdown" style={{ display: 'flex' }} />

        <Dropdown.Menu>
          <Dropdown.Item
            onClick={editorCommands.table.addColumnBefore}
            disabled={!editorCommands.table.canAddColumnBefore}
          >
            Add column before
          </Dropdown.Item>
          <Dropdown.Item
            onClick={editorCommands.table.addColumnAfter}
            disabled={!editorCommands.table.canAddColumnAfter}
          >
            Add column after
          </Dropdown.Item>
          <Dropdown.Item onClick={editorCommands.table.addRowBefore} disabled={!editorCommands.table.canAddRowBefore}>
            Add row before
          </Dropdown.Item>
          <Dropdown.Item onClick={editorCommands.table.addRowAfter} disabled={!editorCommands.table.canAddRowAfter}>
            Add row after
          </Dropdown.Item>
          <Dropdown.Item onClick={editorCommands.table.deleteColumn} disabled={!editorCommands.table.canDeleteColumn}>
            Delete column
          </Dropdown.Item>
          <Dropdown.Item onClick={editorCommands.table.deleteRow} disabled={!editorCommands.table.canDeleteRow}>
            Delete row
          </Dropdown.Item>
          <Dropdown.Item onClick={editorCommands.table.delete} disabled={!editorCommands.table.canDelete}>
            Delete Table
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {hasH1Tag && (
        <button
          type="button"
          title="Heading One"
          aria-label="Heading One"
          onClick={() => editorCommands.toggleHeading(1)}
          className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
        >
          <RiH1 size="1.25rem" style={{ margin: 'auto' }} />
        </button>
      )}
      <button
        type="button"
        title="Heading Two"
        aria-label="Heading Two"
        onClick={() => editorCommands.toggleHeading(2)}
        className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
      >
        <RiH2 size="1.25rem" style={{ margin: 'auto' }} />
      </button>
      <button
        type="button"
        title="Heading Three"
        aria-label="Heading Three"
        onClick={() => editorCommands.toggleHeading(3)}
        className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
      >
        <RiH3 size="1.25rem" style={{ margin: 'auto' }} />
      </button>
      <button type="button" title="Line Break" aria-label="Line Break" onClick={editorCommands.addHardBreak}>
        <GrReturn size="1.25rem" style={{ margin: 'auto' }} />
      </button>
      {templateFieldGroups && templateFieldGroups.length > 0 && (
        <Dropdown as={ButtonGroup} size="md" style={{ display: 'flex' }}>
          <button type="button" title="Templates" aria-label="Templates" className="templateButton">
            <RiCodeFill size="1.25rem" />
          </button>
          <Dropdown.Toggle split id="template-dropdown" />
          <Dropdown.Menu>
            <TemplatesDropdownMenuItems
              templateGroups={templateFieldGroups}
              onInsert={editorCommands.insertContent}
              requiredTemplateFieldsOptions={requiredTemplateFields}
            />
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
};

export default MenuBar;
