import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { publicPages } from '@/Pages/Home/SitePages';

const PrivacyNoticeFooter = (): JSX.Element => (
  <p className="text-center m-0">
    © {format(new Date(), 'yyyy')} Softwire. All rights reserved.
    <Link className="ms-1" to={publicPages.PublicPrivacyNotice.path}>
      Privacy Notice
    </Link>
  </p>
);

export default PrivacyNoticeFooter;
