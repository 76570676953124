import { DetailedAuditChangeType, DetailedAuditTrailResponse } from 'nrosh-common/Api/AuditLogsApi';
import { ProviderSummary } from 'nrosh-common/Api/ProvidersApi';
import { SurveyPartResponse } from 'nrosh-common/Api/SurveyPartsApi';
import { Surveys } from 'nrosh-common/Api/SurveysApi';
import { UsersResponse } from 'nrosh-common/Api/UsersApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { useMemo } from 'react';
import { Stack } from 'react-bootstrap';
import DetailedAuditTrailLogsForm from '@/Components/AuditLogs/DetailedAuditTrailLogsForm';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { AuditLogsApi, ProvidersApi, SurveyPartsApi, SurveysApi, UsersApi } from '@/Helpers/Apis';
import { adminPages } from '@/Pages/Home/SitePages';

export const DetailedAuditChangeTypeMapping: Record<DetailedAuditChangeType, string> = {
  SurveyAssigned: 'Survey Assigned',
  SurveyUnassigned: 'Survey Unassigned',
  SurveyStatus: 'Survey Status',
  DeadlineChange: 'Deadline Change',
  ValidationTolerance: 'Validation Tolerance',
  ValueChange: 'Value Change',
  DimensionAdded: 'Dimension Added',
  DimensionRemoved: 'Dimension Removed',
  SurveyClosed: 'Survey Closed',
  SurveyReopened: 'Survey Reopened',
  FileUploaded: 'File Uploaded',
  SurveyDeleted: 'Survey Deleted',
  SurveyMarkedAsInDevelopment: 'Survey Marked as In Development',
  SurveyOpened: 'Survey Opened',
};

const DetailedAuditTrailLogsPage = (): JSX.Element => {
  const [detailedAuditTrailResponse] = useEndpoint<DetailedAuditTrailResponse>(AuditLogsApi.getDetailedAuditTrailLogs);
  const [providers] = useEndpoint<ProviderSummary[]>(ProvidersApi.getProviders);
  const [users] = useEndpoint<UsersResponse>(UsersApi.getUsers);
  const [surveysResponse] = useEndpoint<Surveys>(SurveysApi.getSurveys);
  const [surveyParts] = useEndpoint<SurveyPartResponse[]>(SurveyPartsApi.getAllSurveyParts);

  const surveyOptions = useMemo(
    () =>
      surveysResponse?.surveys
        .sort((a, b) => (a.surveyName > b.surveyName ? 1 : -1))
        .map((s) => ({ value: s.surveyId, label: s.surveyName })),
    [surveysResponse],
  );

  if (!detailedAuditTrailResponse || !providers || !users || !surveysResponse || !surveyParts || !surveyOptions) {
    return <LoadingMessage />;
  }

  return (
    <Stack gap={5}>
      <PageHeader
        heading="Detailed audit trail"
        crumbsType={RootPathType.AdminAudits}
        crumbs={[
          {
            name: 'Detailed audit trail',
            path: adminPages.AdminDetailedAuditTrail.path,
          },
        ]}
      />
      <DetailedAuditTrailLogsForm
        detailedAuditTrailResponse={detailedAuditTrailResponse}
        surveysResponse={surveysResponse}
        surveyParts={surveyParts}
        surveyOptions={surveyOptions}
        providers={providers}
        users={users}
      />
    </Stack>
  );
};

export default DetailedAuditTrailLogsPage;
