import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { ReactNode } from 'react';
import { Form } from 'react-bootstrap';
import { SelectionType } from '@/Pages/Emails/EmailUtilities';

type MultiSelectFormWrapperProps = {
  controlId: string;
  selectionType: SelectionType;
  setSelectionType: ReactStateSetter<SelectionType>;
  isInvalid: boolean;
  allowNone?: boolean;
  setDirty?: () => void;
  disabled?: boolean;
  children?: ReactNode;
};

export const MultiSelectFormWrapper = ({
  children,
  controlId,
  selectionType,
  setSelectionType,
  isInvalid,
  allowNone = true,
  setDirty = () => {},
  disabled = false,
}: MultiSelectFormWrapperProps): JSX.Element => (
  <>
    <Form.Group className="mb-3" controlId={controlId}>
      <Form.Check
        inline
        type="radio"
        label="All"
        id={`${controlId}-all`}
        name={controlId}
        isInvalid={isInvalid}
        checked={selectionType === SelectionType.All}
        disabled={disabled}
        onChange={(e) => {
          setDirty();
          if (e.target.checked) {
            setSelectionType(SelectionType.All);
          }
        }}
      />
      <Form.Check
        inline
        type="radio"
        label="Specific"
        id={`${controlId}-specific`}
        name={controlId}
        isInvalid={isInvalid}
        checked={selectionType === SelectionType.Specific}
        disabled={disabled}
        onChange={(e) => {
          setDirty();
          if (e.target.checked) {
            setSelectionType(SelectionType.Specific);
          }
        }}
      />
      {allowNone && (
        <Form.Check
          inline
          type="radio"
          label="None"
          id={`${controlId}-none`}
          name={controlId}
          isInvalid={isInvalid}
          checked={selectionType === SelectionType.None}
          disabled={disabled}
          onChange={(e) => {
            setDirty();
            if (e.target.checked) {
              setSelectionType(SelectionType.None);
            }
          }}
        />
      )}
    </Form.Group>
    {selectionType === SelectionType.Specific && children}
  </>
);
