import { Accept, ErrorCode, FileRejection } from 'react-dropzone';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';

// Should match DefaultAcceptedFileTypes in FileService.cs
export const defaultAcceptedFileTypes = {
  'text/csv': ['.csv'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/pdf': ['.pdf'],
  'application/vnd.ms-powerpoint': ['.ppt'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
  'text/plain': ['.txt'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'application/jpg': ['.jpg'],
  'application/jpeg': ['.jpeg'],
};

type NoFileDisplayProps = {
  disabled: boolean;
  displayText: string;
};

export const NoFileDisplay = ({ disabled, displayText }: NoFileDisplayProps): JSX.Element => (
  <div className="d-flex flex-column align-items-center justify-content-center h-100">
    <span>
      <b>{displayText}</b>
    </span>
    <span className="mb-3">or</span>
    <PrimaryButton type="button" disabled={disabled}>
      Browse Files
    </PrimaryButton>
  </div>
);

export const buildErrorMessage = (fileRejections: FileRejection[], accept: Accept): string => {
  const errorCodes = fileRejections.flatMap((r) => r.errors).map((e) => e.code);

  if (errorCodes.some((c) => c === ErrorCode.TooManyFiles.toString())) {
    return 'Too many files selected. Please select one file.';
  }

  if (errorCodes.some((c) => c === ErrorCode.FileInvalidType.toString())) {
    const acceptedExtensions = Object.values(accept).flat().sort();
    const listFormatter = new Intl.ListFormat('en-GB', { style: 'long', type: 'conjunction' });
    return `Invalid file type. The following file types are accepted: ${listFormatter.format(acceptedExtensions)}.`;
  }

  if (errorCodes.some((c) => c === ErrorCode.FileTooSmall.toString())) {
    return 'The uploaded file was empty. Please select a non-empty file.';
  }

  return 'Error uploading file.';
};

export type FileData = {
  name: string;
  sizeBytes: number;
};
