import { Tag } from 'nrosh-common/Api/CommonTypes';
import { DocumentsResponse } from 'nrosh-common/Api/UnauthenticatedPublicDocumentsApi';
import { distinctBy } from 'nrosh-common/Helpers/ArrayHelpers';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { Stack } from 'react-bootstrap';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { TagsApi, UnauthenticatedPublicDocumentsApi } from '@/Helpers/Apis';
import { compareTagNamesAlphabetically } from '@/Helpers/TagHelper';
import PublicDocumentsTable from '@/Pages/Content/PublicDocuments/PublicDocumentsTable';
import '@/Pages/Content/Content.scss';

const PublicDocumentsPage = (): JSX.Element => {
  const [documentsResponse] = useEndpoint<DocumentsResponse>(UnauthenticatedPublicDocumentsApi.getAll);
  const [tags] = useEndpoint<Tag[]>(TagsApi.getContentTags);

  if (!documentsResponse || !tags) {
    return <LoadingMessage />;
  }

  const tagOptions = distinctBy(documentsResponse.documents.map((d) => d.tags).flat(), (t) => t.id)
    .sort(compareTagNamesAlphabetically)
    .map((t) => ({
      value: t.id,
      label: t.name,
    }));

  return (
    <Stack gap={5}>
      <PageHeader heading="Documents" crumbsType={RootPathType.PublicDocuments} />
      <PublicDocumentsTable publicDocuments={documentsResponse.documents} tagOptions={tagOptions} />
    </Stack>
  );
};

export default PublicDocumentsPage;
