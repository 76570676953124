import { ScheduledEmailResponse } from 'nrosh-common/Api/EmailsApi';
import React, { useRef } from 'react';
import { Stack } from 'react-bootstrap';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import useUnsavedChangesWarning from '@/Hooks/useUnsavedChangesWarning';
import { ComposeEmailForm } from '@/Pages/Emails/Manual/ComposeEmailForm';
import { adminPages } from '@/Pages/Home/SitePages';

type ViewEmailPageProps = {
  emailId: string;
  emailData: ScheduledEmailResponse;
};

export const ComposeEmailPage = ({ emailId, emailData }: ViewEmailPageProps): JSX.Element => {
  const [setDirty, setPristine] = useUnsavedChangesWarning();

  const initialRenderTime = useRef(new Date());

  if (!emailData) {
    return <LoadingMessage />;
  }

  const dateTimeToSend = new Date(Date.parse(emailData.sendTime));
  const isSendDateTimeLaterThanRender = dateTimeToSend > initialRenderTime.current;

  // TODO #438: Replace the attachments file upload control with a custom file upload component
  return (
    <Stack gap={5}>
      <PageHeader
        heading="Compose email"
        crumbsType={RootPathType.AdminEmails}
        crumbs={[
          {
            name: 'Manual emails',
            path: adminPages.AdminManualEmails.path,
          },
          { name: emailData.subject ? emailData.subject : 'Compose email', path: adminPages.AdminViewOrEditEmail.path },
        ]}
      />
      <ComposeEmailForm
        emailId={emailId}
        emailData={emailData}
        setDirty={setDirty}
        setPristine={setPristine}
        initialSubject={emailData.subject ?? ''}
        initialHtmlBody={emailData.htmlBody ?? ''}
        initialUploadedFiles={emailData.emailObjects.map((eo) => ({
          fileName: eo.fileName,
          fileObject: null,
        }))}
        initialSendLater={isSendDateTimeLaterThanRender}
        initialSendDateTime={isSendDateTimeLaterThanRender ? dateTimeToSend : initialRenderTime.current}
        recipientsFilter={emailData.recipientsFilter}
      />
    </Stack>
  );
};
