import { EditablePage } from 'nrosh-common/Api/EditableContentApi';
import { PageTitle } from 'nrosh-common/Api/Enums';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { Stack } from 'react-bootstrap';
import { Link, generatePath } from 'react-router-dom';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import ArrowRight from '@/Components/Icons/ArrowRight';
import Edit from '@/Components/Icons/Edit';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import Table, { NroshColumn } from '@/Components/Table/Table';
import { EditableContentApi } from '@/Helpers/Apis';
import { PageTitleDict } from '@/Pages/Content/ContentHelpers';
import { adminPages, publicPages } from '@/Pages/Home/SitePages';
import '@/Pages/Content/Content.scss';

const ManageEditableContent = (): JSX.Element => {
  const [editablePages] = useEndpoint<EditablePage[]>(EditableContentApi.getEditablePages);

  // This dictionary cannot reside outside of the component due to a circular reference with publicPages.
  const PagePathDict: { [key in PageTitle]: string } = {
    [PageTitle.Home]: publicPages.PublicHome.path,
    [PageTitle.About]: publicPages.PublicAbout.path,
    [PageTitle.PrivacyNotice]: publicPages.PublicPrivacyNotice.path,
    [PageTitle.AccessibilityStatement]: publicPages.PublicAccessibilityStatement.path,
    [PageTitle.TermsAndConditions]: publicPages.PublicTermsAndConditions.path,
    [PageTitle.ContactUs]: publicPages.PublicContactUs.path,
  };

  const pageCell = (value: PageTitle): JSX.Element => (
    <Link className="d-flex align-items-center" to={generatePath(PagePathDict[value])}>
      {PageTitleDict[value]}
      <span className="ps-1 linkIcon">
        <ArrowRight />
      </span>
    </Link>
  );

  const editCell = (value: string): JSX.Element => (
    <Link
      className="d-flex align-items-center editLink justify-content-center"
      to={generatePath(adminPages.AdminEditPage.path, { pageId: value })}
    >
      <Edit />
    </Link>
  );

  const headers: NroshColumn<EditablePage>[] = [
    {
      Header: 'Page',
      accessor: 'title',
      Cell: ({ value }) => pageCell(value),
    },
    {
      Header: 'Edit',
      accessor: 'id',
      align: 'center',
      width: 50,
      Cell: ({ value }) => editCell(value),
      disableSortBy: true,
    },
  ];

  if (!editablePages) {
    return <LoadingMessage />;
  }

  return (
    <Stack gap={5}>
      <PageHeader
        heading="Manage editable content"
        crumbsType={RootPathType.AdminContent}
        crumbs={[
          {
            name: 'Manage editable content',
            path: adminPages.AdminEditableContent.path,
          },
        ]}
      />
      <Table
        data={editablePages}
        columns={headers}
        className="w-75"
        // This is just a table of links
        exportable={false}
        rowHeadingIndex={0}
      />
    </Stack>
  );
};

export default ManageEditableContent;
