import React, { ReactElement } from 'react';
import { Dropdown } from 'react-bootstrap';
import TooltipOnHover from '@/Components/Tooltips/TooltipOnHover';

type DropdownButtonProps = {
  icon: ReactElement;
  text: string;
  tooltipText?: string;
  disabled?: boolean;
  onClick?: (() => Promise<void>) | (() => void);
};

const DropdownButton = ({ icon, text, tooltipText, disabled = false, onClick }: DropdownButtonProps): JSX.Element => {
  const item = (
    <Dropdown.Item
      as="button"
      disabled={disabled}
      onClick={onClick}
      className="d-inline-flex align-items-center rounded fw-bold py-3 px-4 gap-2"
    >
      {icon}
      {text}
    </Dropdown.Item>
  );

  return tooltipText ? (
    <TooltipOnHover text={tooltipText} placement="left">
      {item}
    </TooltipOnHover>
  ) : (
    item
  );
};

export default DropdownButton;
