export enum CellStyle {
  // Data input
  DataCurrency = 'DataCurrency',
  DataDate = 'DataDate',
  DataDecimalOneDp = 'DataDecimalOneDp',
  DataDecimalTwoDp = 'DataDecimalTwoDp',
  DataInteger = 'DataInteger',
  DataText = 'DataText',

  // Calculations
  TotalCurrency = 'TotalCurrency',
  TotalDecimalOneDp = 'TotalDecimalOneDp',
  TotalDecimalTwoDp = 'TotalDecimalTwoDp',
  TotalInteger = 'TotalInteger',
  TotalPercent = 'TotalPercent',

  // Pre-populated
  PrePopulatedCurrency = 'PrePopulatedCurrency',
  PrePopulatedDecimalOneDp = 'PrePopulatedDecimalOneDp',
  PrePopulatedDecimalTwoDp = 'PrePopulatedDecimalTwoDp',
  PrePopulatedInteger = 'PrePopulatedInteger',
  PrePopulatedText = 'PrePopulatedText',

  // Format only styles
  Grey = 'Grey',
  GreyBorder = 'GreyBorder',

  YellowHeaderLarge = 'YellowHeaderLarge',
  YellowHeaderMedium = 'YellowHeaderMedium',
  YellowHeaderSmall = 'YellowHeaderSmall',
  YellowHeaderSmallBorder = 'YellowHeaderSmallBorder',
  YellowSmallRight = 'YellowSmallRight',
  YellowLine = 'YellowLine',
  CalculatedYellowHeader = 'CalculatedYellowHeader',

  PurpleHeaderLarge = 'PurpleHeaderLarge',
  PurpleHeaderMedium = 'PurpleHeaderMedium',
  PurpleHeaderSmall = 'PurpleHeaderSmall',
  PurpleHeaderSmallBorder = 'PurpleHeaderSmallBorder',
  PurpleSmallRight = 'PurpleSmallRight',
  PurpleLine = 'PurpleLine',

  TableHeaderCentre = 'TableHeaderCentre',
  TableHeaderLeft = 'TableHeaderLeft',
  TableHeaderLine = 'TableHeaderLine',

  TextLarge = 'TextLarge',
  TextLargeBold = 'TextLargeBold',
  TextMedium = 'TextMedium',
  TextMediumBold = 'TextMediumBold',
  TextSmall = 'TextSmall',
  TextSmallWarning = 'TextSmallWarning',
  TextSmallBold = 'TextSmallBold',
  TextSmallBoldWarning = 'TextSmallBoldWarning',
  TextSmallBoldRight = 'TextSmallBoldRight',
  TextSmallRight = 'TextSmallRight',
  TextSmallWarningRight = 'TextSmallWarningRight',
  TextSmallBoldWarningRight = 'TextSmallBoldWarningRight',

  TextQuestionNumber = 'TextQuestionNumber',
  TextQuestionNumberCentre = 'TextQuestionNumberCentre',

  Hidden = 'Hidden',

  // Represents a style which is neither "Normal" nor one of the NROSH styles
  UnrecognisedStyle = 'UnrecognisedStyle',
}

export const defaultCellStyle = CellStyle.TextSmall;

export enum SubmissionStatus {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Submitted = 'Submitted',
  Unsubmitted = 'Unsubmitted',
  SignedOff = 'SignedOff',
}

export enum ValidationType {
  Hard = 'Hard',
  Soft = 'Soft',
}

export enum ValidationStatus {
  Pass = 'Pass',
  Fail = 'Fail',
  Approved = 'Approved',
}

export type Role = UserRole | RshRole | ProviderRole;

export enum UserRole {
  LoggedIn = 'LoggedIn',
}

export enum RshRole {
  User = 'RshUser',
  ViewSurveys = 'RshViewSurveys',
  DesignSurveys = 'RshDesignSurveys',
  AssignSurveys = 'RshAssignSurveys',
  OpenCloseSurveys = 'RshOpenCloseSurveys',
  DeleteSurveys = 'RshDeleteSurveys',
  ViewRegDocs = 'RshViewRegDocs',
  EditRegDocs = 'RshEditRegDocs',
  UploadRegDocs = 'RshUploadRegDocs',
  TransferRegDocsToD365 = 'RshTransferRegDocsToD365',
  ViewSurveyData = 'RshViewSurveyData',
  EditSurveyData = 'RshEditSurveyData',
  SubmitSurvey = 'RshSubmitSurvey',
  UnsubmitSurvey = 'RshUnsubmitSurvey',
  SignOffSurvey = 'RshSignOffSurvey',
  UnsignOffSurvey = 'RshUnsignOffSurvey',
  ApproveValidations = 'RshApproveValidations',
  ViewProviders = 'RshViewProviders',
  EditProviders = 'RshEditProviders',
  ViewUsers = 'RshViewUsers',
  EditUsers = 'RshEditUsers',
  ViewSentEmails = 'RshViewSentEmails',
  SendEmails = 'RshSendEmails',
  ManageContent = 'RshManageContent',
  ViewAudit = 'RshViewAudit',
  ManageUserRoles = 'RshManageUserRoles',
}

export enum ProviderRole {
  User = 'ProviderUser',
  ViewRegDocs = 'ProviderViewRegDocs',
  EditRegDocs = 'ProviderEditRegDocs',
  UploadRegDocs = 'ProviderUploadRegDocs',
  ViewSurveyData = 'ProviderViewSurveyData',
  EditSurveyData = 'ProviderEditSurveyData',
  SubmitSurvey = 'ProviderSubmitSurvey',
  ViewProvider = 'ProviderViewProvider',
  EditProvider = 'ProviderEditProvider',
  ViewUsers = 'ProviderViewUsers',
  EditUsers = 'ProviderEditUsers',
}

export enum PageTitle {
  Home = 'Home',
  About = 'About',
  PrivacyNotice = 'PrivacyNotice',
  AccessibilityStatement = 'AccessibilityStatement',
  TermsAndConditions = 'TermsAndConditions',
  ContactUs = 'ContactUs',
}

export enum ProviderType {
  Company = 'Company',
  Other = 'Other',
}

export enum ProviderSize {
  NotSpecified = 'NotSpecified',
  Small = 'Small',
  Large = 'Large',
}

export enum RegistrationStatus {
  NotSpecified = 'NotSpecified',
  Registered = 'Registered',
  NonRegistered = 'NonRegistered',
  Other = 'Other',
}

export enum ReportingStatus {
  NotSpecified = 'NotSpecified',
  Mainstream = 'Mainstream',
  MainstreamGroupOnly = 'MainstreamGroupOnly',
  Small = 'Small',
}

export enum ProfitStatus {
  NotSpecified = 'NotSpecified',
  Profit = 'Profit',
  LocalAuthority = 'LocalAuthority',
  NonProfit = 'NonProfit',
}
