const AccessReports = (): JSX.Element => {
  const fill = '#FFAF00';
  return (
    <svg id="access-reports-icon" xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="53 40 194 194">
      <g>
        <path
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5px"
          d="m199.02,114.47c-4.09,0-7.41-3.32-7.41-7.41v-25.65c0-.65-.53-1.18-1.18-1.18h-66.84c-8.53,0-15.45,6.92-15.45,15.45v116.57c0,8.53,6.92,15.45,15.45,15.45h86.5c8.53,0,15.45-6.92,15.45-15.45v-96.6c0-.65-.53-1.18-1.18-1.18h-25.33Z"
        />
        <line
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5px"
          x1="191.62"
          y1="80.23"
          x2="225.54"
          y2="114.47"
        />
        <line
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5px"
          x1="127.47"
          y1="139.99"
          x2="206.22"
          y2="139.99"
        />
        <line
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5px"
          x1="127.47"
          y1="157.82"
          x2="206.22"
          y2="157.82"
        />
        <line
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5px"
          x1="127.47"
          y1="175.65"
          x2="206.22"
          y2="175.65"
        />
        <line
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5px"
          x1="127.47"
          y1="193.48"
          x2="206.22"
          y2="193.48"
        />
      </g>
      <path
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5px"
        d="m99.02,211.94c-4.8-.14-8.69-4.11-8.69-8.94V73.42c0-4.92,4.03-8.95,8.95-8.95h99.27c4.92,0,8.95,4.03,8.95,8.95v12.5"
      />
      <path
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5px"
        d="m83.15,192.02c-4.8-.14-8.69-4.11-8.69-8.94V53.5c0-4.92,4.03-8.95,8.95-8.95h99.27c4.92,0,8.95,4.03,8.95,8.95v3.78"
      />
    </svg>
  );
};
export default AccessReports;
