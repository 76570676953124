import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import '@/Components/Table/GlobalFilter.scss';

type GlobalFilterProps = {
  filter: string;
  setFilter: ReactStateSetter<string>;
  placeholderText?: string;
};

const GlobalFilter: (props: GlobalFilterProps) => JSX.Element = ({ filter, setFilter, placeholderText }) => (
  <div className="searchBar">
    <input
      value={filter || ''}
      onChange={(e) => setFilter(e.target.value)}
      placeholder={placeholderText === undefined ? 'Search' : placeholderText}
    />
  </div>
);

export default GlobalFilter;
