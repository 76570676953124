import { isValid } from 'date-fns';
import saveAs from 'file-saver';
import {
  DetailedAuditTrailFilters as DetailedAuditTrailRequestFilters,
  DetailedAuditTrailResponse,
} from 'nrosh-common/Api/AuditLogsApi';
import { ProviderSummary } from 'nrosh-common/Api/ProvidersApi';
import { SurveyPartResponse } from 'nrosh-common/Api/SurveyPartsApi';
import { Surveys } from 'nrosh-common/Api/SurveysApi';
import { UsersResponse } from 'nrosh-common/Api/UsersApi';
import React, { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import AuditLogsSearchButtons from '@/Components/AuditLogs/AuditLogsSearchButtons';
import DetailedAuditTrailLogsTable from '@/Components/AuditLogs/DetailedAuditTrailLogsTable';
import ExportAuditLogs from '@/Components/AuditLogs/ExportAuditLogs';
import { useErrorReporting } from '@/Components/Errors/DCSErrorBoundary';
import FilterDateRangePicker from '@/Components/Filters/FilterDateRangePicker';
import { FilterDropdown } from '@/Components/Filters/FilterDropdown';
import FilterHeading from '@/Components/Filters/FilterHeading';
import FilterProviderSelection from '@/Components/Filters/FilterProviderSelection';
import FilterUserSelection from '@/Components/Filters/FilterUserSelection';
import { SearchableDropdown, SearchableDropdownOption } from '@/Components/SearchableDropdown/SearchableDropdown';
import { AuditLogsApi } from '@/Helpers/Apis';
import { DetailedAuditChangeTypeMapping } from '@/Pages/AuditLogs/DetailedAuditTrailLogsPage';

type DetailedAuditTrailLogsFormProps = {
  detailedAuditTrailResponse: DetailedAuditTrailResponse;
  providers: ProviderSummary[];
  users: UsersResponse;
  surveysResponse: Surveys;
  surveyParts: SurveyPartResponse[];
  surveyOptions: SearchableDropdownOption<number>[];
};

const DetailedAuditTrailLogsForm = (props: DetailedAuditTrailLogsFormProps): JSX.Element => {
  const { detailedAuditTrailResponse, providers, users, surveysResponse, surveyParts, surveyOptions } = props;
  const [filteredResults, setFilteredResults] = useState<DetailedAuditTrailResponse>(detailedAuditTrailResponse);
  const [raiseError, clearError] = useErrorReporting();
  const [searchParams, setSearchParams] = useSearchParams();
  const [providerId, setProviderId] = useState<number | undefined>();
  const [changedByUserId, setChangedByUserId] = useState<number | undefined>();
  const [changeType, setChangeType] = useState<string | undefined>();
  const [surveyId, setSurveyId] = useState<number | undefined>();
  const [timePeriodId, setTimePeriodId] = useState<number | undefined>();
  const [surveyPartId, setSurveyPartId] = useState<number | undefined>();
  const [fromDateTime, setFromDateTime] = useState<Date | null>(null);
  const [toDateTime, setToDateTime] = useState<Date | null>(null);

  const changeTypeOptions: SearchableDropdownOption<string>[] = Object.entries(DetailedAuditChangeTypeMapping).map(
    ([changeTypeOption, changeTypeString]) => ({
      value: changeTypeOption,
      label: changeTypeString,
    }),
  );

  const getFiltersFromSearchParams = (): DetailedAuditTrailRequestFilters => {
    const filterParams: DetailedAuditTrailRequestFilters = {};
    const fromDateParam = searchParams.get('fromDate');
    const toDateParam = searchParams.get('toDate');
    const providerIdParam = searchParams.get('providerId');
    const changedByUserIdParam = searchParams.get('changedByUserId');
    const changeTypeParam = searchParams.get('changeType');
    const surveyIdParam = searchParams.get('surveyId');
    const timePeriodIdParam = searchParams.get('timePeriodId');
    const surveyPartIdParam = searchParams.get('surveyPartId');

    const isNotNullOrEmpty = (str: string | null): str is string => str !== null && str.trim().length !== 0;

    if (isNotNullOrEmpty(fromDateParam)) {
      filterParams.fromDate = fromDateParam;
    }
    if (isNotNullOrEmpty(toDateParam)) {
      filterParams.toDate = toDateParam;
    }
    if (isNotNullOrEmpty(providerIdParam)) {
      filterParams.providerId = Number(providerIdParam);
    }
    if (isNotNullOrEmpty(changedByUserIdParam)) {
      filterParams.changedByUserId = Number(changedByUserIdParam);
    }
    if (isNotNullOrEmpty(changeTypeParam)) {
      filterParams.changeType = changeTypeParam;
    }
    if (isNotNullOrEmpty(surveyIdParam)) {
      filterParams.surveyId = Number(surveyIdParam);

      if (isNotNullOrEmpty(timePeriodIdParam)) {
        filterParams.timePeriodId = Number(timePeriodIdParam);
      }
      if (isNotNullOrEmpty(surveyPartIdParam)) {
        filterParams.surveyPartId = Number(surveyPartIdParam);
      }
    }

    return filterParams;
  };

  const getFilteredResults = async (request: DetailedAuditTrailRequestFilters): Promise<void> => {
    await AuditLogsApi.getDetailedAuditTrailLogs(request).withHandlers(
      async (body) => setFilteredResults(body),
      async ({ message }) => raiseError(message),
    );
  };

  useEffect(() => {
    const getInitialResults = async (): Promise<void> => {
      clearError();

      const filterParams = getFiltersFromSearchParams();
      await getFilteredResults(filterParams);

      setProviderId(filterParams.providerId);
      setChangedByUserId(filterParams.changedByUserId);
      setChangeType(filterParams.changeType);
      setSurveyId(filterParams.surveyId);
      setTimePeriodId(filterParams.timePeriodId);
      setSurveyPartId(filterParams.surveyPartId);

      if (filterParams.toDate === undefined) {
        setToDateTime(null);
      } else {
        const toDate = new Date(filterParams.toDate);
        setToDateTime(isValid(toDate) ? toDate : null);
      }
      if (filterParams.fromDate === undefined) {
        setFromDateTime(null);
      } else {
        const fromDate = new Date(filterParams.fromDate);
        setFromDateTime(isValid(fromDate) ? fromDate : null);
      }
    };

    getInitialResults().catch(() => {});
  }, [searchParams]);

  const isUserAdmin = (userId: number): boolean => users.rshUsers.map((u) => u.id).includes(userId);

  const updateSearchParams = (): void => {
    const updatedSearchParams: URLSearchParams = new URLSearchParams();

    if (providerId) {
      updatedSearchParams.set('providerId', providerId.toString());
    }
    if (surveyId) {
      updatedSearchParams.set('surveyId', surveyId.toString());
    }
    if (changedByUserId) {
      updatedSearchParams.set('changedByUserId', changedByUserId.toString());
    }
    if (timePeriodId && surveyId) {
      updatedSearchParams.set('timePeriodId', timePeriodId.toString());
    }
    if (surveyPartId && surveyId) {
      updatedSearchParams.set('surveyPartId', surveyPartId.toString());
    }
    if (fromDateTime) {
      updatedSearchParams.set('fromDate', fromDateTime.toISOString());
    }
    if (toDateTime) {
      updatedSearchParams.set('toDate', toDateTime.toISOString());
    }
    if (changeType) {
      updatedSearchParams.set('changeType', changeType);
    }

    setSearchParams(updatedSearchParams);
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();
    clearError();
    updateSearchParams();
  };

  const clearFilters = async (): Promise<void> => {
    setSearchParams({});
  };

  const onDownload = useCallback(async () => {
    try {
      const request = getFiltersFromSearchParams();
      const blob = await AuditLogsApi.downloadDetailedAuditTrail(request);
      saveAs(blob, 'detailedAuditTrail.csv');
    } catch {
      raiseError();
    }
  }, [filteredResults]);

  const filteredSurvey = useMemo(
    () => surveysResponse.surveys.find((s) => s.surveyId === surveyId),
    [surveyId, surveysResponse],
  );

  const timePeriodOptions = useMemo(() => {
    if (surveyId === undefined || filteredSurvey === undefined) return [];

    return filteredSurvey.instances.map((st) => ({ value: st.timePeriodId, label: st.timePeriodName }));
  }, [surveysResponse, surveyId]);

  const surveyPartOptions = useMemo(
    () =>
      surveyParts
        .filter((sp) => sp.surveyId === surveyId)!
        .map((sp) => ({ value: sp.surveyPartId, label: sp.surveyPartName })),
    [surveyParts, surveyId],
  );

  return (
    <div>
      <FilterDropdown resultCount={filteredResults.numberOfResults}>
        <Form onSubmit={onSubmit}>
          <div className="row mb-2">
            <div className="col">
              <FilterHeading heading="Provider" />
              <FilterProviderSelection
                value={providerId}
                onChange={(v) => {
                  if (v !== providerId && changedByUserId !== undefined && !isUserAdmin(changedByUserId)) {
                    setChangedByUserId(undefined);
                  }
                  setProviderId(v);
                }}
                providers={providers}
                includeRSH={false}
              />
            </div>
            <div className="col">
              <FilterHeading heading="Survey" />
              <SearchableDropdown
                options={surveyOptions}
                currentSelection={surveyId}
                placeholderText="Select survey"
                onChange={(v) => {
                  if (v === undefined) {
                    setSurveyPartId(undefined);
                    setTimePeriodId(undefined);
                  }
                  setSurveyId(v);
                }}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col">
              <FilterHeading heading="Change by User" />
              <FilterUserSelection
                value={changedByUserId}
                onChange={setChangedByUserId}
                providerId={providerId}
                users={users}
                required={false}
                includeRshUsers
                includeProviderUsers={providerId !== undefined}
              />
            </div>
            <div className="col">
              {surveyId !== undefined && filteredSurvey !== undefined && (
                <>
                  <FilterHeading heading="Time Period" />
                  <SearchableDropdown
                    options={timePeriodOptions}
                    currentSelection={timePeriodId}
                    placeholderText="Select time period"
                    onChange={setTimePeriodId}
                  />
                </>
              )}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col">
              <FilterHeading heading="Change Type" />
              <SearchableDropdown
                options={changeTypeOptions}
                currentSelection={changeType}
                placeholderText="Select change type"
                onChange={setChangeType}
              />
            </div>
            <div className="col">
              {surveyId !== undefined && filteredSurvey !== undefined && (
                <>
                  <FilterHeading heading="Survey Part" />
                  <SearchableDropdown
                    options={surveyPartOptions}
                    currentSelection={surveyPartId}
                    placeholderText="Select survey part"
                    onChange={setSurveyPartId}
                  />
                </>
              )}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col">
              <FilterHeading heading="Date Range" />
              <FilterDateRangePicker
                fromDateTime={fromDateTime}
                setFromDateTime={setFromDateTime}
                toDateTime={toDateTime}
                setToDateTime={setToDateTime}
              />
            </div>
          </div>
          <AuditLogsSearchButtons clearFilters={clearFilters} />
        </Form>
      </FilterDropdown>
      <ExportAuditLogs
        numberOfResults={filteredResults.numberOfResults}
        numberOfResultsReturned={filteredResults.numberOfResultsReturned}
        onDownload={onDownload}
      />
      <DetailedAuditTrailLogsTable data={filteredResults.detailedAuditTrail} />
    </div>
  );
};

export default DetailedAuditTrailLogsForm;
