import { useState } from 'react';
import { Alert, Stack } from 'react-bootstrap';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { DCSErrorContextOutlet, useErrorReporting } from '@/Components/Errors/DCSErrorBoundary';
import FileUploadButton from '@/Components/FileUpload/FileUploadButton';
import LinkButton from '@/Components/Links/LinkButton';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import { useModal } from '@/Components/Modal/ModalProvider';
import SubmissionPageHeader from '@/Components/PageHeader/SubmissionPageHeader';
import { UploadOptions } from '@/Components/UploadOptions/UploadOptions';
import { SubmissionsApi, generateResponseErrorMessage } from '@/Helpers/Apis';
import { surveyDisplayName } from '@/Helpers/SurveyHelper';
import useSubmissionDetail from '@/Hooks/useSubmissionDetail';
import { providerPages, submissionPages } from '@/Pages/Home/SitePages';

type PartImportOptionsPageProps = {
  isAdmin: boolean;
};

export const PartImportOptionsPage = (props: PartImportOptionsPageProps): JSX.Element => {
  const { submissionId, submissionPartId, surveyId, timePeriodId } = useParams();
  const { isAdmin } = props;
  const [data] = useSubmissionDetail(submissionId!);
  const [isLoading, setIsLoading] = useState(false);
  const [overwrite, setOverwrite] = useState(false);
  const [raiseError, clearError] = useErrorReporting();
  const navigate = useNavigate();
  const { confirm } = useModal();
  const excelFileTypes =
    '.xlsx,.xlsm,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel.sheet.macroEnabled.12';

  const submissionPath = isAdmin
    ? generatePath(submissionPages.SubmissionView.path, {
        surveyId,
        timePeriodId,
        submissionId,
      })
    : generatePath(providerPages.SubmissionsDetail.path, { submissionId });

  const uploadChangeHandler = async (formData: FormData): Promise<boolean> => {
    if (
      !overwrite ||
      (await confirm(
        'Overwriting existing values with blank cells will clear any data in the system for these cells. Are you sure you wish to continue with the import?',
      ))
    ) {
      clearError();
      try {
        const response = await SubmissionsApi.uploadSubmissionPartImportFile(formData, submissionPartId!, overwrite)
          .raw;

        if (!response.ok) {
          const responseBody = response.value;
          raiseError(`Upload Failed: ${generateResponseErrorMessage(responseBody, (err) => err.error)}`);
          return false;
        }

        return true;
      } catch (e) {
        // We log the error here in case the frontend throws while fetching
        console.error(e);
        raiseError();
        return false;
      }
    }
    return false;
  };

  if (!data) {
    return <LoadingMessage />;
  }

  const partName = data.parts.filter((p) => p.id.toString() === submissionPartId)[0].name;

  return (
    <Stack gap={5}>
      <SubmissionPageHeader
        noErrorOutlet
        heading="Import data"
        subheading={`${data.surveyName} \u2014 ${partName} \u2014 ${data.timePeriodName} \u2014 ${data.provider.name}`}
        isRshUser={isAdmin}
        surveyDisplayName={surveyDisplayName(data.surveyName, data.timePeriodName)}
        providerName={data.provider.name}
        additionalRshCrumbs={[
          {
            name: `Import data \u2014 ${partName}`,
            path: submissionPages.AdminPartImportOptions.path,
          },
        ]}
        additionalProviderCrumbs={[
          {
            name: `Import data \u2014 ${partName}`,
            path: providerPages.ProviderPartImportOptions.path,
          },
        ]}
      />
      <Alert variant="primary">
        <p>
          The default option for uploading templates is &quot;Ignore blank cells&quot;. This means that any blank cells
          in the template will be ignored and will not import into the system. Any value in the system for the question
          (cell) left blank will be retained and other questions (cells) in the part will be updated.{' '}
          <strong>We recommend using this option unless you want to delete data from your survey.</strong>
        </p>
        <p>
          If you want to delete data from the survey within the DCS system you can select the &quot;Overwrite existing
          values with blank cells&quot; option. Any data that has already been added for the part will be overwritten by
          the upload and if a question (cell) is left blank in the template the value already existing in the system
          will be removed from the database.
        </p>
        <p>
          <em>
            Please note, there are some exceptions to the &quot;Overwrite&quot; function and you should refer to
            individual survey guidance for more information.
          </em>
        </p>
        <p className="mt-2">
          <strong>
            Imports can take up to five minutes at busy periods. Please do not navigate away from this page while your
            import is in progress. Once your import is complete you will either be returned to the parts list or will
            receive a warning notification on this page. If you are experiencing difficulties with your import please
            contact our enquiries team.
          </strong>
        </p>
      </Alert>
      <Stack gap={3}>
        <UploadOptions setOverwrite={setOverwrite} />
        <div className="d-flex gap-2">
          <FileUploadButton
            onFileChange={(formData) => uploadChangeHandler(formData)}
            onSuccess={() => navigate(submissionPath)}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            fileTypes={excelFileTypes}
            formDataFileName="importTemplate"
          />
          <LinkButton to={submissionPath} variant="outline-primary" disabled={isLoading}>
            Cancel
          </LinkButton>
        </div>
        <DCSErrorContextOutlet />
      </Stack>
    </Stack>
  );
};
