import { GetSubmissionContactResponse, SubmissionContact } from 'nrosh-common/Api/SubmissionsApi';
import { SurveyChecks } from 'nrosh-common/Api/SurveysApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { ChangeEvent, FormEvent, useState } from 'react';
import { Alert, Form, Stack } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import '@/Pages/Submissions/Confirmation/ConfirmSubmissionContactPage.scss';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import { AccessibleFeedback } from '@/Components/Form/AccessibleFeedback';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import SubmissionPageHeader from '@/Components/PageHeader/SubmissionPageHeader';
import { SubmissionsApi } from '@/Helpers/Apis';
import { getValidityProps } from '@/Helpers/Forms';
import { surveyDisplayName } from '@/Helpers/SurveyHelper';
import { emailRegex, textIsForbidden } from '@/Helpers/Validation';
import useSubmissionDetail from '@/Hooks/useSubmissionDetail';
import { providerPages, submissionPages } from '@/Pages/Home/SitePages';
import {
  ConfirmSubmissionPage,
  useNextAndPreviousConfirmPath,
} from '@/Pages/Submissions/Confirmation/ConfirmSubmissionRouteHelpers';
import SubmissionBackButton from '@/Pages/Submissions/Confirmation/SubmissionBackButton';
import { SubmissionConfirmation } from '@/Pages/Submissions/Confirmation/SubmissionConfirmation';

const ConfirmButton = ({ isSubmitting }: { isSubmitting: boolean }): JSX.Element => {
  const buttonText = 'Confirm';

  return !isSubmitting ? (
    <PrimaryButton type="submit">{buttonText}</PrimaryButton>
  ) : (
    <LoadingButton message={buttonText} />
  );
};

type ConfirmSubmissionContactFormProps = {
  submissionId: string;
  contact: SubmissionContact | null;
  checks: SurveyChecks;
  isAdmin: boolean;
};

enum ConfirmSubmissionContactField {
  Name,
  Email,
  Phone,
}

const ConfirmSubmissionContactForm = ({
  submissionId,
  contact,
  checks,
  isAdmin,
}: ConfirmSubmissionContactFormProps): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  const [fieldValues, setFieldValues] = useState<Record<ConfirmSubmissionContactField, string>>({
    [ConfirmSubmissionContactField.Name]: contact?.name ?? '',
    [ConfirmSubmissionContactField.Email]: contact?.email ?? '',
    [ConfirmSubmissionContactField.Phone]: contact?.phone ?? '',
  });
  const [fieldValidities, setFieldValidities] = useState<Record<ConfirmSubmissionContactField, boolean>>({
    [ConfirmSubmissionContactField.Name]: true,
    [ConfirmSubmissionContactField.Email]: true,
    [ConfirmSubmissionContactField.Phone]: true,
  });

  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setSubmitting] = useState(false);

  const [validated, setValidated] = useState(false);

  const submissionConfirmationState = location.state as SubmissionConfirmation;
  const { previousPage, nextPage } = useNextAndPreviousConfirmPath(
    ConfirmSubmissionPage.SubmissionContact,
    isAdmin,
    checks,
  );

  const onFieldInvalid = (field: ConfirmSubmissionContactField): void => {
    setFieldValidities((oldFieldValidity) => ({ ...oldFieldValidity, [field]: false }));
  };

  const onFieldChange = (field: ConfirmSubmissionContactField) => (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValues((oldFieldValues) => ({ ...oldFieldValues, [field]: e.target.value }));
    setFieldValidities((oldFieldValidities) => ({
      ...oldFieldValidities,
      [field]: !textIsForbidden(e.target.value),
    }));
    setError(null);
    setValidated(false);
  };

  if (!checks) {
    return <LoadingMessage />;
  }

  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault(); // Stops the page from reloading
    if (!event.currentTarget.checkValidity()) {
      setValidated(true);
      return;
    }

    if (Object.values(fieldValues).some(textIsForbidden)) {
      setValidated(true);
      return;
    }

    setSubmitting(true);
    setValidated(false);
    const response = await SubmissionsApi.saveContact(submissionId, {
      id: contact?.id ?? null,
      name: fieldValues[ConfirmSubmissionContactField.Name],
      email: fieldValues[ConfirmSubmissionContactField.Email],
      phone: fieldValues[ConfirmSubmissionContactField.Phone],
    }).raw;
    setSubmitting(false);
    if (response.ok) {
      navigate(nextPage, {
        state: {
          detailsCorrect: submissionConfirmationState?.detailsCorrect ?? false,
          updateRequestSent: submissionConfirmationState?.updateRequestSent ?? false,
          feesInvoiceEmailConfirmed: submissionConfirmationState?.feesInvoiceEmailConfirmed ?? false,
          organisationalDetailsConfirmed: submissionConfirmationState?.organisationalDetailsConfirmed ?? false,
        },
      });
    } else {
      setValidated(true);
      setError('Error saving contact');
    }
  };

  return (
    <div>
      <p>Please provide the name and contact details for this survey.</p>
      <Form onSubmit={onSubmit} noValidate>
        <Form.Group className="mb-3 w-25 contactFormGroup" controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control
            value={fieldValues[ConfirmSubmissionContactField.Name]}
            onChange={onFieldChange(ConfirmSubmissionContactField.Name)}
            required
            maxLength={256}
            onInvalid={() => onFieldInvalid(ConfirmSubmissionContactField.Name)}
            {...getValidityProps(validated && !fieldValidities[ConfirmSubmissionContactField.Name], 'nameFeedback')}
          />
          <AccessibleFeedback
            displayFeedback={validated && !fieldValidities[ConfirmSubmissionContactField.Name]}
            id="nameFeedback"
          >
            A name is required
          </AccessibleFeedback>
        </Form.Group>
        <Form.Group className="mb-3 w-25 contactFormGroup" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            value={fieldValues[ConfirmSubmissionContactField.Email]}
            onChange={onFieldChange(ConfirmSubmissionContactField.Email)}
            required
            minLength={6}
            maxLength={256}
            pattern={emailRegex.source}
            onInvalid={() => onFieldInvalid(ConfirmSubmissionContactField.Email)}
            {...getValidityProps(validated && !fieldValidities[ConfirmSubmissionContactField.Email], 'emailFeedback')}
          />
          <AccessibleFeedback
            displayFeedback={validated && !fieldValidities[ConfirmSubmissionContactField.Email]}
            id="emailFeedback"
          >
            A valid email address is required
          </AccessibleFeedback>
        </Form.Group>
        <Form.Group className="mb-3 w-25 contactFormGroup" controlId="phone">
          <Form.Label>Phone</Form.Label>
          <Form.Control
            value={fieldValues[ConfirmSubmissionContactField.Phone]}
            onChange={onFieldChange(ConfirmSubmissionContactField.Phone)}
            required
            minLength={10}
            maxLength={256}
            onInvalid={() => onFieldInvalid(ConfirmSubmissionContactField.Phone)}
            {...getValidityProps(validated && !fieldValidities[ConfirmSubmissionContactField.Phone], 'phoneFeedback')}
          />
          <AccessibleFeedback
            displayFeedback={validated && !fieldValidities[ConfirmSubmissionContactField.Phone]}
            id="phoneFeedback"
          >
            A valid phone number is required
          </AccessibleFeedback>
        </Form.Group>
        <div className="d-flex gap-2">
          <SubmissionBackButton previousPage={previousPage} label="Back to Validations" />
          <ConfirmButton isSubmitting={isSubmitting} />
        </div>
        {error && (
          <Alert variant="danger" className="mt-3">
            {error}
          </Alert>
        )}
      </Form>
    </div>
  );
};

const ConfirmSubmissionContactPage = ({ isAdmin = false }: { isAdmin?: boolean }): JSX.Element => {
  const { submissionId } = useParams();
  const [contactResponse] = useEndpoint<GetSubmissionContactResponse>(SubmissionsApi.getContact, submissionId);

  const [data] = useSubmissionDetail(submissionId!, isAdmin);
  const [checks] = useEndpoint<SurveyChecks>(SubmissionsApi.getEndOfSurveyChecks, submissionId);

  if (!contactResponse || !checks || (isAdmin && !data)) {
    return <LoadingMessage />;
  }

  return (
    <Stack gap={5}>
      <SubmissionPageHeader
        heading="Survey contact details"
        isRshUser={isAdmin}
        surveyDisplayName={surveyDisplayName(checks.surveyName, checks.timePeriodName)}
        providerName={data?.provider.name ?? ''}
        additionalRshCrumbs={[
          {
            name: 'Survey contact details',
            path: submissionPages.AdminConfirmSubmissionContact.path,
          },
        ]}
        additionalProviderCrumbs={[
          {
            name: 'Survey contact details',
            path: providerPages.ConfirmSubmissionContact.path,
          },
        ]}
      />
      <ConfirmSubmissionContactForm
        submissionId={submissionId!}
        contact={contactResponse.submissionContact}
        checks={checks}
        isAdmin={isAdmin}
      />
    </Stack>
  );
};

export default ConfirmSubmissionContactPage;
