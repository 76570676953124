import { defaultCellStyle } from 'nrosh-common/Api/Enums';
import { DimensionalMember, ListType } from 'nrosh-common/Api/SubmissionsApi';
import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { useEffect, useMemo } from 'react';
import '@/Components/Spreadsheet/Spreadsheet.scss';
import { createCellId, parseCellId } from '@/Components/Spreadsheet/CellIdHelpers';
import { BaseCellProps, findDimensionMember } from '@/Components/Spreadsheet/Cells/CellHelpers';
import SpreadsheetCell, { commonCellProps, getFreezePaneProps } from '@/Components/Spreadsheet/Cells/SpreadsheetCell';
import {
  CellStyle,
  ContentCellStyle,
  DataPoint,
  DimensionValue,
  SpreadsheetValues,
  TabLayout,
} from '@/Components/Spreadsheet/SpreadsheetTypes';
import { useSubmissionPartData } from '@/Pages/Submissions/SubmissionPartContext/SubmissionPartContext';

export type SpreadsheetProps = {
  layout: TabLayout;
  isActive: boolean;
  lastScrollAt: Date;
};

export type CellLayout = {
  cellStyle: CellStyle;
  cellProps: BaseCellProps;
} | null;

export type SpreadsheetStaticState = {
  setActiveCell: ReactStateSetter<string | null>;
  setSelectedSlicerValues: ReactStateSetter<Record<number, number>>;
  updateDataPoint: (
    dataPointId: string,
    value: string,
    dimension1MemberId: number | null,
    dimension2MemberId: number | null,
    sendToHub?: boolean,
  ) => void;
  updateDimensionValue: (dimensionMemberId: number | null, regionId: number, index: number) => void;
  listTypes: ListType[];
  dimensionMembers: DimensionalMember[];
};

type CellDynamicState = {
  cellKey: string;
  active: boolean;
  cellValue: string;
  dimension1MemberId: number | null;
  dimension2MemberId: number | null;
};

const sizeScaling = 1.45;

const scrollToElementAndFocus = (elementId: string): void => {
  const element = document.getElementById(elementId);
  element?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });

  const focusableChild = element?.querySelector('input,textarea') as HTMLInputElement | HTMLTextAreaElement;
  focusableChild?.focus();

  // Set the cursor position at the end of any existing value; trying to do this for date inputs blows up (but this is
  // fine - the day section is highlighted and it's more natural to start there)
  if (focusableChild?.type !== 'date') {
    focusableChild?.setSelectionRange(focusableChild?.value.length, focusableChild?.value.length);
  }
};

const getMergedCellsToIgnore = (
  numberOfRows: number,
  numberOfColumns: number,
  cellStyles: CellStyle[],
): boolean[][] => {
  // Tech debt: Switch to a different approach that doesn't use stateful loops
  const mergedCellsToIgnore: boolean[][] = [...Array(numberOfRows).keys()].map(() =>
    [...Array(numberOfColumns).keys()].map(() => false),
  );
  const remainingRowSpans = [...Array(numberOfColumns).keys()].fill(0);

  for (let rowIndex = 0; rowIndex < numberOfRows; rowIndex++) {
    let remainingColumnSpan = 0;
    for (let colIndex = 0; colIndex < numberOfColumns; colIndex++) {
      const cellStyle = cellStyles.find((c) => c.row === rowIndex && c.column === colIndex);

      if (remainingColumnSpan > 0) {
        remainingColumnSpan -= 1;
        mergedCellsToIgnore[rowIndex][colIndex] = true;
      } else if (remainingRowSpans[colIndex] > 0) {
        remainingRowSpans[colIndex] -= 1;
        mergedCellsToIgnore[rowIndex][colIndex] = true;
      }

      if (cellStyle !== undefined) {
        if (cellStyle.rowSpan > 1) {
          for (let i = 0; i < cellStyle.columnSpan; i++) {
            remainingRowSpans[colIndex + i] = cellStyle.rowSpan - 1;
          }
        }
        if (cellStyle.columnSpan > 1) {
          remainingColumnSpan = cellStyle.columnSpan - 1;
        }
      }
    }
  }
  return mergedCellsToIgnore;
};

const renderDataRow = (
  rowIndex: number,
  rowHeights: number[],
  columnWidths: number[],
  cellLayouts: CellLayout[][],
  staticState: SpreadsheetStaticState,
  dynamicState: CellDynamicState[][],
): JSX.Element => (
  <tr key={`row-${rowIndex}`}>
    <td
      aria-label="Cell"
      className="row-size-cell"
      key={`row-${rowIndex}-sizeCell`}
      style={{ height: rowHeights[rowIndex] }}
    />
    {columnWidths.map((_, colIndex) => {
      const cellState = dynamicState[rowIndex][colIndex];
      return (
        <SpreadsheetCell
          key={`${rowIndex.toString()}-${colIndex.toString()}`}
          cellLayout={cellLayouts[rowIndex][colIndex]}
          staticState={staticState}
          cellKey={cellState.cellKey}
          active={cellState.active}
          cellValue={cellState.cellValue}
          dimension1MemberId={cellState.dimension1MemberId}
          dimension2MemberId={cellState.dimension2MemberId}
        />
      );
    })}
  </tr>
);

const renderSizingRow = (columnWidths: number[]): JSX.Element => (
  <tr key="row-sizing">
    <td aria-label="cell" className="row-size-cell column-size-cell" />
    {columnWidths.map((width, index) => (
      <td aria-label="cell" className="column-size-cell" key={`row-sizing-${index.toString()}`} style={{ width }} />
    ))}
  </tr>
);

// Tech-debt: Some (or even all) of this pre-processing should live in the useSubmissionPartLayout hook
const processLayout = (
  layout: TabLayout,
): {
  scaledRowHeights: number[];
  cellLayouts: (CellLayout | null)[][];
  accumulatedColumnWidths: number[];
  tableWidth: number;
  accumulatedRowHeights: number[];
  tableHeight: number;
  mergedCellsToIgnore: boolean[][];
  scaledColumnWidths: number[];
} => {
  const { numberOfRows, numberOfColumns, cellStyles, rowHeights, columnWidths, freezePaneSpan } = layout;
  const scaledRowHeights = rowHeights.map((h) => h * sizeScaling);
  const scaledColumnWidths = columnWidths.map((w) => w * sizeScaling);

  const accumulatedRowHeights = scaledRowHeights.map((_, row) =>
    scaledRowHeights.slice(0, row).reduce((a, b) => a + b, 0),
  );
  const accumulatedColumnWidths = scaledColumnWidths.map((_, column) =>
    scaledColumnWidths.slice(0, column).reduce((a, b) => a + b, 0),
  );

  const tableHeight = scaledRowHeights.reduce((a, b) => a + b);
  const tableWidth = scaledColumnWidths.reduce((a, b) => a + b);

  const mergedCellsToIgnore = getMergedCellsToIgnore(numberOfRows, numberOfColumns, cellStyles);

  const getCellLayout = (row: number, col: number): CellLayout | null => {
    if (mergedCellsToIgnore[row][col]) {
      return null;
    }
    const cellStyle = cellStyles.find((cs) => cs.row === row && cs.column === col);
    // Render unspecified cells as empty text cells
    if (!cellStyle) {
      const emptyCellStyle: ContentCellStyle = {
        row,
        column: col,
        rowSpan: 1,
        columnSpan: 1,
        style: defaultCellStyle,
        dimensionalRegionId: null,
        cellId: `${layout.tabId}-${col}:${row}`,
        content: '',
      };
      return {
        cellStyle: emptyCellStyle,
        cellProps: {
          ...commonCellProps(row, col, scaledRowHeights, scaledColumnWidths, emptyCellStyle),
          ...getFreezePaneProps(row, col, accumulatedRowHeights, accumulatedColumnWidths, freezePaneSpan),
        },
      };
    }
    return {
      cellStyle,
      cellProps: {
        ...commonCellProps(row, col, scaledRowHeights, scaledColumnWidths, cellStyle),
        ...getFreezePaneProps(row, col, accumulatedRowHeights, accumulatedColumnWidths, freezePaneSpan),
      },
    };
  };

  const cellLayouts: (CellLayout | null)[][] = [];
  for (let row = 0; row < numberOfRows; row++) {
    cellLayouts.push(Array.from({ length: numberOfColumns }).fill(null) as (CellLayout | null)[]);
    for (let col = 0; col < numberOfColumns; col++) {
      cellLayouts[row][col] = getCellLayout(row, col);
    }
  }

  return {
    tableHeight,
    scaledRowHeights,
    accumulatedRowHeights,
    tableWidth,
    scaledColumnWidths,
    accumulatedColumnWidths,
    mergedCellsToIgnore,
    cellLayouts,
  };
};

const focusableCellInfo = (
  dataPoint: DataPoint,
  data: SpreadsheetValues,
  activeCell: string | null,
  selectedSlicerValues: Record<number, number>,
  dimensionValues: DimensionValue[],
): CellDynamicState => {
  const dimension1MemberId = findDimensionMember(dataPoint.dimension1, selectedSlicerValues, dimensionValues);
  const dimension2MemberId = findDimensionMember(dataPoint.dimension2, selectedSlicerValues, dimensionValues);

  const cellKey = createCellId(dataPoint.dataPointId, dimension1MemberId, dimension2MemberId);
  const dimensionlessCellKey = createCellId(dataPoint.dataPointId);
  // Checking against the dimensionless cell as well as the fully qualified cell here allows us the ability
  // to activate all of the cells for a particular data point. We want to do this when a dimensional validation
  // is passing.
  const active = dimensionlessCellKey === activeCell || cellKey === activeCell;

  const cellValue =
    data[dataPoint.dataPointId]?.find(
      (v) => v.dimension1MemberId === dimension1MemberId && v.dimension2MemberId === dimension2MemberId,
    )?.value ?? '';

  return {
    cellKey,
    active,
    cellValue,
    dimension1MemberId,
    dimension2MemberId,
  };
};

const getDynamicCellStates = (
  cellLayouts: CellLayout[][],
  data: SpreadsheetValues,
  dimensionValues: DimensionValue[],
  activeCell: string | null,
  selectedSlicerValues: Record<number, number>,
): CellDynamicState[][] =>
  cellLayouts.map((row) =>
    row.map((cell) => {
      if (!cell?.cellStyle) {
        return { cellKey: '', active: false, cellValue: '', dimension1MemberId: null, dimension2MemberId: null };
      }
      const { cellStyle } = cell;
      if ('dataPoint' in cellStyle) {
        return focusableCellInfo(cellStyle.dataPoint, data, activeCell, selectedSlicerValues, dimensionValues);
      }
      if ('content' in cellStyle) {
        return {
          cellKey: '',
          active: false,
          cellValue: cellStyle.content,
          dimension1MemberId: null,
          dimension2MemberId: null,
        };
      }
      // For variable dimension cells and slicer cells we return default values.
      // We could pass the selected value through in the `cellValue` property, but these cells need to access
      // the dimension values from context anyway (so that they know the list of possible options) so it's not
      // possible to properly memo them.
      return { cellKey: '', active: false, cellValue: '', dimension1MemberId: null, dimension2MemberId: null };
    }),
  );

const Spreadsheet = (props: SpreadsheetProps): JSX.Element => {
  const {
    updateDataPoint,
    updateDimensionValue,
    data,
    dimensionValues,
    layouts,
    dimensionMembers,
    selectedSlicerValues,
    setSelectedSlicerValues,
    activeCell,
    setActiveCell,
  } = useSubmissionPartData();
  const { layout, isActive, lastScrollAt } = props;
  const { listTypes } = layouts;

  const { tableHeight, scaledRowHeights, tableWidth, scaledColumnWidths, cellLayouts } = useMemo(
    () => processLayout(layout),
    [layout],
  );

  // The "static state" does not change during the lifetime of the spreadsheet (after the initial processing)
  // It captures the structure of the submission, but not the actual data.
  const staticState = useMemo(
    () => ({
      setActiveCell,
      setSelectedSlicerValues,
      updateDataPoint,
      updateDimensionValue,
      listTypes,
      dimensionMembers,
    }),
    [setActiveCell, setSelectedSlicerValues, updateDataPoint, updateDimensionValue, listTypes, dimensionMembers],
  );

  useEffect(() => {
    if (window.location.hash && isActive) {
      const result = parseCellId(window.location.hash);
      if (result.isValidCellId) {
        scrollToElementAndFocus(result.cellId);
      }
    }
  }, [lastScrollAt]);

  // The "dynamic state" represents everything that each cell needs to render that can change during the
  // lifetime of the submission. This includes the submission data, and whether or not a cell is active.
  // Crucially, this can only consist of simple data types, so that cell rendering can be memoed effectively.
  const dynamicState = getDynamicCellStates(cellLayouts, data, dimensionValues, activeCell, selectedSlicerValues);

  return (
    <div className="spreadsheetWrapper">
      <table className="spreadsheet" style={{ height: tableHeight, width: tableWidth }}>
        <tbody>
          {renderSizingRow(scaledColumnWidths)}
          {scaledRowHeights.map((_, rowIndex) =>
            renderDataRow(rowIndex, scaledRowHeights, scaledColumnWidths, cellLayouts, staticState, dynamicState),
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Spreadsheet;
