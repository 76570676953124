import { DimensionalMember } from 'nrosh-common/Api/SubmissionsApi';
import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { memo, useCallback, useMemo } from 'react';
import { BaseCellProps, baseCellProps as getBaseCellProps } from '@/Components/Spreadsheet/Cells/CellHelpers';
import TextCell from '@/Components/Spreadsheet/Cells/TextCell';
import { SpreadsheetStaticState } from '@/Components/Spreadsheet/Spreadsheet';
import { DimensionInputCellStyle } from '@/Components/Spreadsheet/SpreadsheetTypes';
import { useSubmissionPartData } from '@/Pages/Submissions/SubmissionPartContext/SubmissionPartContext';
import { useSubmissionPartStaticData } from '@/Pages/Submissions/SubmissionPartContext/SubmissionPartStaticDataContext';

export type VariableDimensionCellProps = BaseCellProps & {
  cellStyle: DimensionInputCellStyle;
  setActiveCell: ReactStateSetter<string | null>;
  dimensionValue: number | undefined;
  updateDimensionValue: (value: number | undefined) => void;
  options: DimensionalMember[];
  readonly: boolean;
};

// Memo-ing this component means that it does not re-render unless the list of options has actually changed.
// This means that things like data entry in other cells in the spreadsheet are able to render quickly.
// The same effect would be reached if the component above used a more specific context (as mentioned in the
// comment).
const VariableDimensionCellDisplay = memo((props: VariableDimensionCellProps) => {
  const { cellStyle, setActiveCell, dimensionValue, updateDimensionValue, options, readonly } = props;
  const { dimensionId } = cellStyle;
  // Duplicated with SlicerCell
  const selectId = `data-select-${dimensionId}-${cellStyle.column}-${cellStyle.row}`;

  return (
    <TextCell {...getBaseCellProps(props)}>
      <label htmlFor={selectId}>
        Update dimensional member:
        <select
          onFocus={() => setActiveCell(null)}
          id={selectId}
          value={dimensionValue || ''}
          onChange={(event) => {
            updateDimensionValue(Number(event.target.value) || undefined);
          }}
          disabled={readonly}
        >
          <option aria-label="blank option" value="" />
          {options.map((dm) => (
            <option key={dm.id} value={dm.id}>
              {dm.value}
            </option>
          ))}
        </select>
      </label>
    </TextCell>
  );
});

const VariableDimensionCell = (
  props: BaseCellProps & {
    staticState: SpreadsheetStaticState;
    cellStyle: DimensionInputCellStyle;
  },
): JSX.Element => {
  // Tech debt: We'd like to get rid of the reliance on the `useSubmissionPartData` hook, because it will cause
  // these cells to re-render often. However, this is difficult - all of the dimension values in this cell's
  // region are required to render this cell, because we need to know which dimension members to exclude
  // from the list.
  // TODO-348: It may be that the best we can do is to access the `dimensionValues` separately, so that these
  // cells only re-render when the `dimensionValues` change, instead of every render.
  const { dimensionValues } = useSubmissionPartData();
  const { readonly } = useSubmissionPartStaticData();
  const { cellStyle, staticState, ...baseCellProps } = props;
  const { setActiveCell, updateDimensionValue, dimensionMembers } = staticState;

  const regionMembers = useMemo(
    () => dimensionValues.filter((d) => d.regionId === cellStyle.regionId),
    [dimensionValues, cellStyle.regionId],
  );

  const currentValue = dimensionValues.find((ld) => ld.regionId === cellStyle.regionId && ld.index === cellStyle.index)
    ?.dimensionMemberId;

  const options = useMemo(
    () =>
      dimensionMembers
        .filter((dm) => dm.dimensionId === cellStyle.dimensionId)
        .filter(
          (dm) =>
            dm.id === currentValue ||
            (!dm.isArchived && !regionMembers.map((d) => d.dimensionMemberId).includes(dm.id)),
        ),
    [dimensionMembers, cellStyle, regionMembers],
  );

  const updateVariableDimension = useCallback(
    (value: number | undefined) => updateDimensionValue(value ?? null, cellStyle.regionId, cellStyle.index),
    [updateDimensionValue],
  );

  return (
    <VariableDimensionCellDisplay
      {...baseCellProps}
      cellStyle={cellStyle}
      setActiveCell={setActiveCell}
      dimensionValue={currentValue}
      updateDimensionValue={updateVariableDimension}
      options={options}
      readonly={readonly}
    />
  );
};

export default VariableDimensionCell;
