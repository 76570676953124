import { Checkbox } from '@mui/material';
import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { ChangeEvent } from 'react';

export type EndOfSurveyCheckboxProps = {
  checked: boolean;
  setDirty: () => void;
  setState: ReactStateSetter<boolean>;
  label: string;
  disabled: boolean;
  describedBy?: string;
};

const EndOfSurveyCheckbox = (props: EndOfSurveyCheckboxProps): JSX.Element => {
  const { checked, setState, setDirty, label, disabled, describedBy } = props;
  return (
    <div className="checkboxContainer">
      <Checkbox
        id={label}
        checked={checked}
        disableRipple
        color="nrosh"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setState(e.target.checked);
          setDirty();
        }}
        disabled={disabled}
        aria-describedby={describedBy}
      />
      <label htmlFor={label}>{label}</label>
    </div>
  );
};

export default EndOfSurveyCheckbox;
