import { SurveyReminderResponse } from 'nrosh-common/Api/EmailsApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import React from 'react';
import { Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { EmailsApi } from '@/Helpers/Apis';
import { EditSurveyReminderForm } from '@/Pages/Emails/SurveyReminders/EditSurveyReminderForm';
import { adminPages, surveyPages } from '@/Pages/Home/SitePages';

type EditSurveyReminderPageProps = {
  isSurveyFlow?: boolean;
};

export const EditSurveyReminderPage = ({ isSurveyFlow = false }: EditSurveyReminderPageProps): JSX.Element => {
  const { surveyId, timePeriodId } = useParams();

  const [reminder] = useEndpoint<SurveyReminderResponse>(EmailsApi.getSurveyReminder, surveyId, timePeriodId);

  if (!reminder) {
    return <LoadingMessage />;
  }

  const { surveyInstanceName } = reminder;

  return (
    <Stack gap={5}>
      <PageHeader
        heading={`Reminders for ${surveyInstanceName}`}
        crumbsType={isSurveyFlow ? RootPathType.AdminSurveys : RootPathType.AdminEmails}
        crumbs={
          isSurveyFlow
            ? [
                {
                  name: surveyInstanceName,
                  path: surveyPages.SurveyView.path,
                },
                {
                  name: 'Reminders',
                  path: adminPages.AdminEditSurveyReminderEmail.path,
                },
              ]
            : [
                {
                  name: 'Manage Survey Reminders',
                  path: adminPages.AdminManageSurveyReminderEmails.path,
                },
                {
                  name: surveyInstanceName,
                  path: adminPages.AdminEditSurveyReminderEmail.path,
                },
              ]
        }
      />
      <EditSurveyReminderForm reminder={reminder} isSurveyFlow={isSurveyFlow} />
    </Stack>
  );
};
