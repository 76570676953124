import { ProviderRole, RshRole } from 'nrosh-common/Api/Enums';
import { ProviderContact, ProviderSummary } from 'nrosh-common/Api/ProvidersApi';
import { ContactType } from 'nrosh-common/Api/SurveysApi';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import { FormEvent, useContext, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { generatePath } from 'react-router-dom';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import { ContactTypeTitleMapping } from '@/Components/EndOfSurveyChecks/ContactTypeTitleMapping';
import LinkButton from '@/Components/Links/LinkButton';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { MyProviderApi, ProvidersApi } from '@/Helpers/Apis';
import { textIsForbidden } from '@/Helpers/Validation';
import useUnsavedChangesWarning from '@/Hooks/useUnsavedChangesWarning';
import { adminPages } from '@/Pages/Home/SitePages';
import ContactForm from '@/Pages/Providers/ContactForm';
import '@/Pages/Providers/ProviderContactsForm.scss';

export enum ProviderContactsField {
  Name,
  JobTitle,
  AddressLine1,
  AddressLine2,
  AddressLine3,
  AddressLine4,
  Postcode,
  PhoneNumber,
  EmailAddress,
}

const listOfContactTypes = Object.keys(ContactType).map((p) => p as ContactType);

const getDefaultSelectedContactTypes = (contacts: ProviderContact[]): { [key in ContactType]?: boolean } =>
  Object.fromEntries(contacts.map((contact) => [contact.type, true]));

const mapContactToFieldValuesOrDefault = (
  contact: ProviderContact | undefined,
): Record<ProviderContactsField, string> => ({
  [ProviderContactsField.Name]: contact?.name ?? '',
  [ProviderContactsField.JobTitle]: contact?.jobTitle ?? '',
  [ProviderContactsField.AddressLine1]: contact?.address.addressLine1 ?? '',
  [ProviderContactsField.AddressLine2]: contact?.address.addressLine2 ?? '',
  [ProviderContactsField.AddressLine3]: contact?.address.addressLine3 ?? '',
  [ProviderContactsField.AddressLine4]: contact?.address.addressLine4 ?? '',
  [ProviderContactsField.Postcode]: contact?.address.postcode ?? '',
  [ProviderContactsField.PhoneNumber]: contact?.phoneNumber ?? '',
  [ProviderContactsField.EmailAddress]: contact?.emailAddress ?? '',
});

const getInitialFieldValues = (
  contacts: ProviderContact[],
): Record<ContactType, Record<ProviderContactsField, string>> =>
  Object.fromEntries(
    listOfContactTypes.map((contactType) => [
      contactType as ContactType,
      mapContactToFieldValuesOrDefault(contacts.find((c) => c.type === contactType)),
    ]),
  ) as Record<ContactType, Record<ProviderContactsField, string>>;

const mapFieldValuesToContact = (
  fieldValues: Record<ProviderContactsField, string>,
  contactType: ContactType,
): ProviderContact => ({
  type: contactType,
  name: fieldValues[ProviderContactsField.Name],
  jobTitle: fieldValues[ProviderContactsField.JobTitle],
  address: {
    addressLine1: fieldValues[ProviderContactsField.AddressLine1],
    addressLine2: fieldValues[ProviderContactsField.AddressLine2],
    addressLine3: fieldValues[ProviderContactsField.AddressLine3],
    addressLine4: fieldValues[ProviderContactsField.AddressLine4],
    postcode: fieldValues[ProviderContactsField.Postcode],
  },
  phoneNumber: fieldValues[ProviderContactsField.PhoneNumber],
  emailAddress: fieldValues[ProviderContactsField.EmailAddress],
});

type ProviderContactsFormProps = {
  provider: ProviderSummary;
  contacts: ProviderContact[];
  isAdmin: boolean;
};

const ProviderContactsForm = ({ provider, contacts, isAdmin }: ProviderContactsFormProps): JSX.Element => {
  const [selectedContactTypes, setSelectedContactTypes] = useState(getDefaultSelectedContactTypes(contacts));

  const [fieldValues, setFieldValues] = useState<Record<ContactType, Record<ProviderContactsField, string>>>(
    getInitialFieldValues(contacts),
  );

  const [validated, setValidated] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [setDirty, setPristine, isDirty] = useUnsavedChangesWarning();

  const auth = useContext(AuthContext);

  const updateFieldValue = (contactType: ContactType) => (field: ProviderContactsField, newValue: string) => {
    setDirty();
    setFieldValues((prev) => ({ ...prev, [contactType]: { ...prev[contactType], [field]: newValue } }));
  };

  const formIsInvalid = (): boolean => Object.values(fieldValues).some((fv) => Object.values(fv).some(textIsForbidden));

  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    setError(null);

    event.preventDefault(); // Stops the page from reloading

    // We don't validate the form for admins so that the provider can fill in the details

    if (!event.currentTarget.checkValidity() || formIsInvalid()) {
      setValidated(true);
      return;
    }

    setSubmitting(true);
    setValidated(false);

    const updatedContacts = listOfContactTypes
      .filter((contactType) => selectedContactTypes[contactType])
      .map((contactType) => mapFieldValuesToContact(fieldValues[contactType], contactType));

    const response = isAdmin
      ? ProvidersApi.updateProviderContacts(provider.id, updatedContacts)
      : MyProviderApi.updateProviderContacts(updatedContacts);

    await response.withHandlers(
      () => {
        setFieldValues(getInitialFieldValues(updatedContacts));
        setPristine();
      },
      ({ message }) => {
        setError(message);
      },
    );

    setSubmitting(false);
  };

  const isReadonly = !(isAdmin ? auth.hasRole(RshRole.EditProviders) : auth.hasRole(ProviderRole.EditProvider));
  const canSelectContactTypes = isAdmin && !isReadonly;
  const shouldShowSaveButton = !isReadonly && (isAdmin || contacts.length > 0);

  const adminContactMessage =
    'To add a contact for this provider, tick the box next to the contact you wish to add, then provide their details in the fields below';

  return (
    <div className="providerContactsFormContainer">
      <Form onSubmit={onSubmit} noValidate>
        {error && (
          <Alert variant="danger" className="w-75">
            {error}
          </Alert>
        )}
        {canSelectContactTypes && (
          <>
            <p>{adminContactMessage}</p>
            <div className="mb-4">
              {listOfContactTypes.map((contactType) => (
                <div key={contactType} className="mt-3 d-flex align-items-center">
                  <input
                    type="checkbox"
                    id={`contactTypeSelectedCheckbox-${contactType}`}
                    className="me-3"
                    checked={selectedContactTypes[contactType] ?? false}
                    onChange={(e) => {
                      setDirty();
                      setSelectedContactTypes((prev) => ({ ...prev, [contactType]: e.target.checked }));
                    }}
                    disabled={isReadonly}
                  />
                  <label htmlFor={`contactTypeSelectedCheckbox-${contactType}`}>
                    {ContactTypeTitleMapping[contactType]}
                  </label>
                </div>
              ))}
            </div>
          </>
        )}
        {!canSelectContactTypes && contacts.length === 0 && <div>No contacts for provider</div>}
        <div className="d-flex flex-wrap mb-4">
          {listOfContactTypes.map(
            (contactType) =>
              selectedContactTypes[contactType] && (
                <ContactForm
                  key={contactType}
                  contactType={contactType}
                  fieldValues={fieldValues[contactType]}
                  updateFieldValue={updateFieldValue(contactType)}
                  readonly={isReadonly}
                  validated={validated}
                  isAdmin={isAdmin}
                />
              ),
          )}
        </div>
        <div className="d-flex gap-2">
          {isAdmin && (
            <LinkButton
              to={generatePath(adminPages.AdminViewProvider.path, { providerId: provider.id.toString() })}
              variant="outline-primary"
              disabled={isSubmitting}
            >
              {isReadonly ? 'Return to Provider' : 'Cancel'}
            </LinkButton>
          )}
          {shouldShowSaveButton &&
            (!isSubmitting ? (
              <PrimaryButton type="submit" disabled={!isDirty}>
                Save
              </PrimaryButton>
            ) : (
              <LoadingButton message="Save" />
            ))}
        </div>
      </Form>
    </div>
  );
};

export default ProviderContactsForm;
