import { SurveyStatus } from 'nrosh-common/Api/SubmissionsApi';
import { Dispatch, SetStateAction, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { generatePath } from 'react-router-dom';
import { DeleteButton } from '@/Components/Buttons/DeleteButton';
import { TertiaryLinkButton } from '@/Components/Buttons/TertiaryLinkButton';
import DocumentCheckmark from '@/Components/Icons/DocumentCheckmark';
import MailAlert from '@/Components/Icons/MailAlert';
import { surveyPages } from '@/Pages/Home/SitePages';
import useManageSurveyState from '@/Pages/Surveys/SurveyViewPage/useManageSurveyState';

export type SurveyOptionalActionsProps = {
  surveyId: string;
  timePeriodId: string;
  surveyStatus: SurveyStatus;
  hasProvidersAssigned: boolean;
  hasSubmissions: boolean;
  setError: Dispatch<SetStateAction<string | null>>;
  setSurveyStatus: Dispatch<SetStateAction<SurveyStatus | undefined>>;
};

const SurveyOptionalActions = ({
  surveyId,
  timePeriodId,
  surveyStatus,
  hasProvidersAssigned,
  hasSubmissions,
  setError,
  setSurveyStatus,
}: SurveyOptionalActionsProps): JSX.Element => {
  const [, setIsLoading] = useState(false);
  const { deleteSurvey } = useManageSurveyState({
    surveyId,
    timePeriodId,
    setError,
    setSurveyStatus,
    setIsLoading,
  });

  const canDelete = !hasProvidersAssigned && !hasSubmissions;
  const deleteSurveyButton = <DeleteButton text="Delete survey" disabled={!canDelete} onClick={deleteSurvey} />;

  let tooltipMessage = '';

  if (hasProvidersAssigned) {
    tooltipMessage = 'Cannot delete survey with assigned providers.';
  } else if (hasSubmissions) {
    tooltipMessage =
      'This survey cannot be deleted as it was recently unassigned from one or more providers. Note that it can take up to 24 hours for the system to clean up submission data.';
  }

  const deleteSurveyOverlay = canDelete ? (
    deleteSurveyButton
  ) : (
    <OverlayTrigger placement="right" overlay={<Tooltip> {tooltipMessage}</Tooltip>}>
      {/* This div is required for tooltip to work with custom components. Refer to
                https://github.com/react-bootstrap/react-bootstrap/issues/2208#issuecomment-301737531 */}
      <div>{deleteSurveyButton}</div>
    </OverlayTrigger>
  );

  return (
    <div className="surveyViewSection">
      <h2>Optional actions</h2>
      <div className="d-flex flex-row pt-3 gap-1">
        <TertiaryLinkButton
          icon={<DocumentCheckmark />}
          text="Edit survey declarations"
          to={generatePath(surveyPages.SurveyChecks.path, { surveyId, timePeriodId })}
        />
        <TertiaryLinkButton
          icon={<MailAlert />}
          text="Edit email reminders"
          to={generatePath(surveyPages.SurveyConfigureReminders.path, { surveyId, timePeriodId })}
        />
        {surveyStatus === SurveyStatus.InDevelopment && deleteSurveyOverlay}
      </div>
    </div>
  );
};

export default SurveyOptionalActions;
