import AuthContext from 'nrosh-common/Contexts/AuthContext';
import { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import { AccountApi } from '@/Helpers/Apis';
import useRedirectWhenLoggedIn from '@/Hooks/useRedirectWhenLoggedIn';
import { splashPage } from '@/Pages/Home/LoginForm';
import ChangePasswordForm, { ChangePasswordType } from '@/Pages/Home/PasswordChange/ChangePasswordForm';

export const useTokenAndUsername = (): { token: string | null; username: string | null } => {
  const { search } = useLocation();
  return useMemo(() => {
    const urlSearchParams = new URLSearchParams(search);
    const token = urlSearchParams.get('token');
    const username = urlSearchParams.get('username');
    return { token, username };
  }, [search]);
};

type VerifyEmailConfirmationTokenProps = {
  token: string | null;
  username: string | null;
  linkType: string;
  children: ReactNode;
};

const VerifyEmailConfirmationToken = ({
  token,
  username,
  linkType,
  children,
}: VerifyEmailConfirmationTokenProps): JSX.Element => {
  const [isTokenValid, setIsTokenValid] = useState<boolean | null>(null);

  useEffect(() => {
    const verifyToken = async (): Promise<void> => {
      if (!token || !username) {
        return;
      }
      const response = await AccountApi.verifyEmailConfirmationToken({ token, username }).raw;
      setIsTokenValid(response.ok);
    };
    verifyToken().catch(() => {});
  }, [token, username]);

  if (isTokenValid === null) {
    return <LoadingMessage />;
  }

  return (
    <div className="d-flex flex-column">
      <h1 className="mx-auto pt-2 pb-3">Activate Account</h1>
      {!isTokenValid && (
        <Alert variant="danger" className="text-center">
          Your {linkType} link has expired. Please speak to another registered user at your organisation who can send a
          new link. Alternatively, if you cannot do this, you should contact the DCS Enquiries Team via{' '}
          <a href="mailto:Team-DCS@softwire.com">Team-DCS@softwire.com</a>
        </Alert>
      )}
      {isTokenValid && children}
    </div>
  );
};

export const ActivateAccountPage = (): JSX.Element => {
  const { token, username } = useTokenAndUsername();
  useRedirectWhenLoggedIn();

  return (
    <VerifyEmailConfirmationToken token={token} username={username} linkType="account activation">
      <p className="pt-0 text-center">To activate your account, please enter a new password</p>
      <ChangePasswordForm
        token={token!}
        username={username!}
        changePasswordType={ChangePasswordType.ActivateAccount}
        isLoginLayout
      />
    </VerifyEmailConfirmationToken>
  );
};

export const ConfirmEmailAddressPage = (): JSX.Element => {
  const { token, username } = useTokenAndUsername();
  const { user } = useContext(AuthContext);

  return (
    <VerifyEmailConfirmationToken token={token} username={username} linkType="email address confirmation">
      <p className="pt-0 text-center">
        Your email address has been confirmed. Click <Link to={splashPage(user)}>here</Link> to return to DCS.
      </p>
    </VerifyEmailConfirmationToken>
  );
};
