import { Toast } from 'react-bootstrap';
import '@/Components/ToastWithIcon/ToastWithIcon.scss';
import { Check } from '@/Components/Icons/CheckIcon';
import { InfoLarge } from '@/Components/Icons/Info';
import { WarningLarge } from '@/Components/Icons/Warning';

export enum ToastType {
  Success = 'success-toast',
  Info = 'info-toast',
  Warning = 'warning-toast',
}

type ToastProps = {
  text: string;
  type: ToastType;
};

const ToastWithIcon = ({ text, type }: ToastProps): JSX.Element => (
  <Toast className={`${type} d-flex px-3 align-items-center`}>
    {type === ToastType.Info && <InfoLarge className="infoIconBlue flex-shrink-0" />}
    {type === ToastType.Success && <Check className="checkIconGreenWithBorder flex-shrink-0" />}
    {type === ToastType.Warning && <WarningLarge className="warningIconRed flex-shrink-0" />}
    <Toast.Body className="py-3 pl-2 pr-0">{text}</Toast.Body>
  </Toast>
);

export default ToastWithIcon;
