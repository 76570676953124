import { EmailTemplateResponse, EmailTemplateType } from 'nrosh-common/Api/EmailsApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import React from 'react';
import { Stack } from 'react-bootstrap';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { EmailsApi } from '@/Helpers/Apis';
import { EditFooterForm } from '@/Pages/Emails/EditFooterForm';
import { adminPages } from '@/Pages/Home/SitePages';

export const EditFooterPage = (): JSX.Element => {
  const [emailTemplate] = useEndpoint<EmailTemplateResponse>(EmailsApi.getEmailTemplate, EmailTemplateType.Footer);

  if (!emailTemplate) {
    return <LoadingMessage />;
  }

  return (
    <Stack gap={5}>
      <PageHeader
        heading="Email footer"
        crumbsType={RootPathType.AdminEmails}
        crumbs={[
          {
            name: 'Email footer',
            path: adminPages.AdminEmailFooter.path,
          },
        ]}
      />
      <p>Update the footer applied to all outgoing emails.</p>
      <EditFooterForm initialBody={emailTemplate.templateText} />
    </Stack>
  );
};
