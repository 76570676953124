import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { ChangeEvent, useRef } from 'react';
import '@/Components/FileUpload/FileUploadButton.css';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import { LoadingButton } from '@/Components/Loading/LoadingButton';

type FileUploadButtonProps = {
  onFileChange: (formData: FormData) => Promise<boolean>;
  onSuccess: () => void;
  isLoading: boolean;
  setIsLoading: ReactStateSetter<boolean>;
  fileTypes: string;
  formDataFileName: string;
};

const FileUploadButton = ({
  onFileChange,
  onSuccess,
  isLoading,
  setIsLoading,
  fileTypes,
  formDataFileName,
}: FileUploadButtonProps): JSX.Element => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const changeHandler = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append(formDataFileName, event.target.files![0]);
    setIsLoading(false);
    // Reset the value, so that selecting the same file again is seen as a change
    if (hiddenFileInput.current != null) {
      hiddenFileInput.current.value = '';
    }
    if (await onFileChange(formData)) {
      onSuccess();
    }
  };

  return (
    <div className="buttonContainer">
      {!isLoading ? (
        <PrimaryButton
          className="addSaveButton mb-3"
          type="button"
          onClick={() => {
            if (!isLoading) {
              hiddenFileInput.current!.click();
            }
          }}
        >
          Upload
        </PrimaryButton>
      ) : (
        <LoadingButton message="Uploading..." />
      )}
      <input
        className="hiddenInput"
        type="file"
        accept={fileTypes}
        ref={hiddenFileInput}
        onChange={changeHandler}
        aria-labelledby="uploadButton"
      />
    </div>
  );
};

export default FileUploadButton;
