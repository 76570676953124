import {
  EmailRecipientsFilter,
  ScheduledEmailStatus,
  ScheduledManualEmailSummaryResponse,
} from 'nrosh-common/Api/EmailsApi';
import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { useRef, useState } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { CellProps } from 'react-table';
import Delete from '@/Components/Icons/Delete';
import Edit from '@/Components/Icons/Edit';
import { useModal } from '@/Components/Modal/ModalProvider';
import Table, { NroshColumn } from '@/Components/Table/Table';
import { EmailsApi, placeHolderAPIErrorHandler } from '@/Helpers/Apis';
import { TimeResolution, formatISODateString } from '@/Helpers/DateHelpers';
import { getEmailRecipientMessage } from '@/Pages/Emails/EmailUtilities';
import { adminPages } from '@/Pages/Home/SitePages';

export type ProcessedEmail = {
  subject: string;
  status: ScheduledEmailStatus;
  lastEditedByName: string;
  lastEditedAt: string;
  adjustedSendTime: number;
  sendTimeISO: string;
  numberOfAttachments: number;
  numberOfRecipients: number;
  recipientsFilter: EmailRecipientsFilter | null | undefined;
  id: number;
};

export type ScheduledEmailTableRow = {
  id: number;
  subject: string;
  status: ScheduledEmailStatus;
  lastEditedByName: string;
  lastEditedAtISO: string;
  sendTimeString: string;
  sendTimeISO: string;
  numberOfAttachments: number;
  recipientsString: string;
};

type ScheduledEmailTableProps = {
  scheduledManualEmails: ScheduledManualEmailSummaryResponse[];
  canEdit: boolean;
};

const useHeaders = (
  initialRenderTime: number,
  setTableData: ReactStateSetter<ScheduledEmailTableRow[]>,
  canEdit: boolean,
): NroshColumn<ScheduledEmailTableRow>[] => {
  const { confirm } = useModal();
  const deleteEmail = async (emailId: number): Promise<void> => {
    if (
      await confirm(
        <div>
          <p>Are you sure you want to delete this email?</p>
          <p>
            <strong> This action is irreversible, and all data will be lost.</strong>
          </p>
        </div>,
      )
    ) {
      await EmailsApi.deleteScheduledEmail(emailId.toString()).justErrors(placeHolderAPIErrorHandler);
      setTableData((previousTableData) => previousTableData.filter((se) => se.id !== emailId));
    }
  };

  return [
    {
      Header: 'Subject',
      accessor: 'subject',
      Cell: ({ value }: CellProps<ScheduledEmailTableRow, string>) =>
        value !== '' ? value : <span className="blankSubject">(blank)</span>,
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 100,
    },
    {
      Header: 'Send Time',
      accessor: 'sendTimeISO',
      width: 125,
      Cell: ({ cell: { row } }) => row.original.sendTimeString,
    },
    {
      Header: 'Attachments',
      accessor: 'numberOfAttachments',
      align: 'center',
      width: 100,
    },
    {
      Header: 'Recipients',
      disableSortBy: true,
      accessor: 'recipientsString',
    },
    {
      Header: 'Last Edited By',
      accessor: 'lastEditedByName',
      align: 'center',
    },
    {
      Header: 'Last Edited At',
      accessor: 'lastEditedAtISO',
      width: 125,
      Cell: ({ cell: { row } }) =>
        row.original.lastEditedAtISO ? formatISODateString(row.original.lastEditedAtISO, TimeResolution.Second) : null,
    },
    {
      Header: 'View/Edit',
      align: 'center',
      width: 75,
      disableSortBy: true,
      disableGlobalFilter: true,
      Cell: ({ cell: { row } }: CellProps<ScheduledEmailTableRow>) => (
        <Link
          to={generatePath(adminPages.AdminViewOrEditEmail.path, {
            emailId: row.values.id as string,
          })}
        >
          {row.values.status === ScheduledEmailStatus.Draft && canEdit ? <Edit /> : 'View'}
        </Link>
      ),
    },
    {
      Header: 'Delete',
      align: 'center',
      width: 75,
      disableSortBy: true,
      disableGlobalFilter: true,
      accessor: 'id',
      Cell: ({ value, cell: { row } }: CellProps<ScheduledEmailTableRow, number>) =>
        row.values.status === ScheduledEmailStatus.Draft && (
          <button
            className="iconButton"
            type="button"
            aria-label="Delete"
            onClick={() => {
              deleteEmail(value).catch(() => {});
            }}
          >
            <Delete />
          </button>
        ),
    },
  ];
};

const compareEmailRows = (a: ProcessedEmail, b: ProcessedEmail): number => {
  if (a.status === ScheduledEmailStatus.Draft && b.status !== ScheduledEmailStatus.Draft) return -1;
  if (a.status !== ScheduledEmailStatus.Draft && b.status === ScheduledEmailStatus.Draft) return 1;

  if (a.adjustedSendTime === b.adjustedSendTime) return (a.subject ?? '').localeCompare(b.subject ?? '');

  return b.adjustedSendTime - a.adjustedSendTime;
};

const tableRowToExportRow = (
  scheduledEmail: ScheduledEmailTableRow,
): {
  'Status': ScheduledEmailStatus;
  'Recipients': string;
  'Send Time': string;
  'Attachments': number;
  'Subject': string;
} => ({
  'Subject': scheduledEmail.subject || '(blank)',
  'Status': scheduledEmail.status,
  'Send Time': scheduledEmail.sendTimeString,
  'Attachments': scheduledEmail.numberOfAttachments,
  'Recipients': scheduledEmail.recipientsString,
});

export const ScheduledEmailTable = ({ scheduledManualEmails, canEdit }: ScheduledEmailTableProps): JSX.Element => {
  const initialRenderTime = useRef(Date.now());

  const processEmail = (se: ScheduledManualEmailSummaryResponse): ProcessedEmail => {
    const parsedSendTime = Date.parse(se.sendTime);
    return {
      subject: se.subject ?? '',
      status: se.status,
      lastEditedByName: se.lastEditedByName,
      lastEditedAt: se.lastEditedAt,
      adjustedSendTime:
        parsedSendTime < initialRenderTime.current && se.status === ScheduledEmailStatus.Draft
          ? initialRenderTime.current
          : parsedSendTime,
      sendTimeISO: se.sendTime,
      numberOfAttachments: se.numberOfAttachments,
      numberOfRecipients: se.numberOfRecipients,
      recipientsFilter: se.recipientsFilter,
      id: se.id,
    };
  };

  const prepareEmailTableRow = (pe: ProcessedEmail): ScheduledEmailTableRow => {
    const hasSendingStarted =
      pe.status === ScheduledEmailStatus.Sending ||
      pe.status === ScheduledEmailStatus.Sent ||
      pe.status === ScheduledEmailStatus.Failed ||
      pe.status === ScheduledEmailStatus.PartiallyFailed;

    return {
      id: pe.id,
      subject: pe.subject,
      lastEditedByName: pe.lastEditedByName,
      lastEditedAtISO: pe.lastEditedAt,
      status: pe.status,
      sendTimeString:
        !hasSendingStarted && pe.adjustedSendTime === initialRenderTime.current
          ? 'Immediate'
          : formatISODateString(new Date(pe.adjustedSendTime).toISOString(), TimeResolution.Second),
      sendTimeISO: pe.sendTimeISO,
      numberOfAttachments: pe.numberOfAttachments,
      recipientsString: `${getEmailRecipientMessage(pe.recipientsFilter)}${
        hasSendingStarted ? ` (${pe.numberOfRecipients} recipients)` : ''
      }`,
    };
  };

  const initialTableData = scheduledManualEmails
    .filter((se) => se.status !== ScheduledEmailStatus.Draft || canEdit)
    .map(processEmail)
    .sort(compareEmailRows)
    .map(prepareEmailTableRow);

  const [tableData, setTableData] = useState<ScheduledEmailTableRow[]>(initialTableData);

  return (
    <Table
      data={tableData}
      columns={useHeaders(initialRenderTime.current, setTableData, canEdit)}
      message="No Emails to Display"
      paginated
      searchable
      exportable
      exportFileName="Scheduled Emails.csv"
      tableRowToExportRow={tableRowToExportRow}
      rowHeadingIndex={0}
    />
  );
};
