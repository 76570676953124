import { ProviderRole, RshRole } from 'nrosh-common/Api/Enums';
import { UserProfile, UserProfileType } from 'nrosh-common/Api/UsersApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { useReducer, useRef, useState } from 'react';
import { Alert, Stack } from 'react-bootstrap';
import { IconContext } from 'react-icons';
import { BiAddToQueue } from 'react-icons/bi';
import { Link, generatePath } from 'react-router-dom';
import { Column, Row } from 'react-table';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import FiltersComponent, { FilterType, getDefaultSelectedFilterInfo } from '@/Components/Filters/Filters';
import useFilters, { Filter } from '@/Components/Filters/useFilters';
import Delete from '@/Components/Icons/Delete';
import Edit from '@/Components/Icons/Edit';
import LinkButton from '@/Components/Links/LinkButton';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import { useModal } from '@/Components/Modal/ModalProvider';
import PageHeader from '@/Components/PageHeader/PageHeader';
import Table, { NroshColumn } from '@/Components/Table/Table';
import { UsersApi, generateResponseErrorMessage } from '@/Helpers/Apis';
import { adminPages } from '@/Pages/Home/SitePages';
import '@/Pages/Users/ManageProfilesPage.scss';
import { ProviderRoleDescriptionMapping, RshRoleDescriptionMapping } from '@/Pages/Users/RoleDescriptionMapping';

type UserProfileTableRow = {
  'Name': string;
  'Profile Type': string;
  'Assigned Users': number;
  'Roles': string;
};

const filters: Filter<UserProfile>[] = [
  {
    type: FilterType.Search,
    id: 'name',
    text: 'Name',
    filter: (profile, filter) => profile.name.toLowerCase().includes(filter.toLowerCase().trim()),
    placeholder: 'Enter name',
    ariaLabelledBy: 'name',
  },
  {
    type: FilterType.Checkbox,
    id: 'type',
    text: 'Profile Type',
    filter: (profile, values) => values.includes(profile.type),
    options: [
      {
        value: UserProfileType.DcsProfile,
        text: 'DCS Profile',
      },
      {
        value: UserProfileType.ProviderProfile,
        text: 'Provider Profile',
      },
    ],
  },
];

const getProfileTableColumns = (deleteProfile: (profileId: number) => Promise<void>): NroshColumn<UserProfile>[] => [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Profile Type',
    accessor: 'type',
    Cell: ({ value }) => (value === UserProfileType.DcsProfile ? 'DCS Profile' : 'Provider Profile'),
  },
  {
    Header: 'Assigned Users',
    accessor: 'assignedUsers',
    align: 'center',
  },
  {
    Header: 'Edit',
    width: 120,
    align: 'center',
    Cell: ({ row }: { row: Row<UserProfile> }) => (
      <Link
        to={generatePath(adminPages.AdminEditUserProfile.path, { profileId: row.original.id.toString() })}
        className="d-flex flex-column align-items-center"
      >
        <Edit />
      </Link>
    ),
    disableSortBy: true,
    disableResizing: true,
    getResizerProps: () => {},
  } as NroshColumn<UserProfile>,
  {
    Header: 'Delete',
    width: 120,
    align: 'center',
    Cell: ({ row }: { row: Row<UserProfile> }) =>
      row.original.assignedUsers === 0 && (
        <button
          className="deleteButton d-flex flex-column align-items-center p-0 w-100"
          type="button"
          onClick={() => deleteProfile(row.original.id)}
          aria-label={`Delete ${row.original.name}`}
        >
          <Delete />
        </button>
      ),
    disableSortBy: true,
    disableResizing: true,
    getResizerProps: () => {},
  } as Column<UserProfile>,
];

const tableRowToExportRow = (profile: UserProfile): UserProfileTableRow => {
  const isDcsProfile = profile.type === UserProfileType.DcsProfile;
  const roles = isDcsProfile
    ? profile.roles.map((r) => r as RshRole).filter((r) => r !== RshRole.User)
    : profile.roles.map((r) => r as ProviderRole).filter((r) => r !== ProviderRole.User);
  return {
    'Name': profile.name,
    'Profile Type': isDcsProfile ? 'DCS Profile' : 'Provider Profile',
    'Assigned Users': profile.assignedUsers,
    'Roles': roles
      .map((r) =>
        isDcsProfile ? RshRoleDescriptionMapping[r as RshRole] : ProviderRoleDescriptionMapping[r as ProviderRole],
      )
      .join(', '),
  };
};

const ManageProfilesPage = (): JSX.Element => {
  const [lastRefreshTime, triggerDataRefresh] = useReducer(() => Date.now(), Date.now());
  const [profiles] = useEndpoint<UserProfile[]>(UsersApi.getProfiles, lastRefreshTime);
  const { filteredData, ...filterProps } = useFilters(profiles || [], filters);
  const [error, setError] = useState<string | null>(null);
  const { confirm } = useModal();
  const { current: biAddToQueueIconContext } = useRef({ size: '1.25rem', color: 'white' });

  const deleteProfile = async (profileId: number): Promise<void> => {
    if (
      !(await confirm(
        <div>
          <p>Are you sure you want to delete this profile?</p>
          <p>
            <strong> This is an irreversible action.</strong>
          </p>
        </div>,
      ))
    ) {
      return;
    }

    setError(null);

    const response = await UsersApi.deleteProfile(profileId).raw;
    if (!response.ok) {
      const unknownErrorMessage = 'An unexpected problem has occurred.';
      try {
        const body = response.value;
        const errorMessage = generateResponseErrorMessage(body, (detail) => detail.error, unknownErrorMessage);
        setError(errorMessage);
      } catch {
        setError(unknownErrorMessage);
      }
    }

    triggerDataRefresh();
  };

  if (!filterProps.selectedFilter) {
    filterProps.setSelectedFilter(getDefaultSelectedFilterInfo(filters[0]));
  }

  if (!profiles) {
    return <LoadingMessage />;
  }

  return (
    <Stack gap={5} className="manageProfilesPageContainer">
      <PageHeader
        heading="Manage profiles"
        crumbsType={RootPathType.AdminUsers}
        crumbs={[{ name: 'Manage user profiles', path: adminPages.AdminManageUserProfiles.path }]}
      />
      <LinkButton to={adminPages.AdminAddUserProfile.path}>
        <div className="d-flex align-items-center">
          Add Profile
          <div className="ms-3 d-flex align-items-center">
            <IconContext.Provider value={biAddToQueueIconContext}>
              <BiAddToQueue />
            </IconContext.Provider>
          </div>
        </div>
      </LinkButton>
      {error && (
        <Alert variant="danger" className="w-75" dismissible onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
      <div>
        <FiltersComponent {...filterProps} heading="All Profiles" />
        <Table
          data={filteredData}
          columns={getProfileTableColumns(deleteProfile)}
          paginated
          defaultSort={[{ id: 'name', desc: false }]}
          exportable
          exportFileName="User Profiles.csv"
          tableRowToExportRow={tableRowToExportRow}
          rowHeadingIndex={0}
        />
      </div>
    </Stack>
  );
};

export default ManageProfilesPage;
