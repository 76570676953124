import { UnsubmitPrimaryReason } from 'nrosh-common/Api/SubmissionsApi';
import { FormEvent, useState } from 'react';
import { Alert, Form, Stack } from 'react-bootstrap';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import { AccessibleFeedback } from '@/Components/Form/AccessibleFeedback';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import { useModal } from '@/Components/Modal/ModalProvider';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { SubmissionsApi } from '@/Helpers/Apis';
import { getValidityProps } from '@/Helpers/Forms';
import { surveyDisplayName } from '@/Helpers/SurveyHelper';
import useSubmissionDetail from '@/Hooks/useSubmissionDetail';
import { submissionPages, surveyPages } from '@/Pages/Home/SitePages';

const confirmationPrompt = 'Are you sure you want to unsubmit this submission?';

const mapUnsubmitReasonToString = (reason: UnsubmitPrimaryReason): string => {
  switch (reason) {
    case UnsubmitPrimaryReason.SignificantDataQualityIssues:
      return 'Significant data quality issues';
    case UnsubmitPrimaryReason.MinorDataQualityIssues:
      return 'Minor data quality issues';
    case UnsubmitPrimaryReason.ProviderRequest:
      return 'Provider request';
    case UnsubmitPrimaryReason.Other:
    default:
      return reason.toString();
  }
};

const UnsubmitPage = (): JSX.Element => {
  const { surveyId, timePeriodId, submissionId } = useParams();
  const [submission] = useSubmissionDetail(submissionId!);
  const navigate = useNavigate();

  const [primaryReason, setPrimaryReason] = useState<UnsubmitPrimaryReason | ''>('');
  const [furtherExplanation, setFurtherExplanation] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isNotifying, setIsNotifying] = useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(false);

  const { confirm } = useModal();

  if (!submission) {
    return <LoadingMessage />;
  }

  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    if (!event.currentTarget.checkValidity()) {
      setValidated(true);
      return;
    }

    if (!(await confirm(confirmationPrompt))) return;

    const response = await SubmissionsApi.unsubmit(
      submissionId!,
      {
        primaryReason: primaryReason as UnsubmitPrimaryReason, // field is required
        furtherExplanation,
      },
      isNotifying,
    ).raw;

    if (!response.ok) {
      const { message } = response.value;
      setError(message);
      return;
    }

    navigate(generatePath(submissionPages.SubmissionView.path, { surveyId, timePeriodId, submissionId }));
  };

  return (
    <Stack gap={5}>
      <PageHeader
        heading="Confirm unsubmit"
        crumbsType={RootPathType.AdminSurveys}
        crumbs={[
          {
            name: surveyDisplayName(submission.surveyName, submission.timePeriodName),
            path: surveyPages.SurveyView.path,
          },
          {
            name: submission.provider.name,
            path: submissionPages.SubmissionView.path,
          },
          {
            name: 'Confirm unsubmit',
            path: submissionPages.AdminUnsubmitPage.path,
          },
        ]}
      />
      <Form className="w-50" onSubmit={onSubmit} noValidate>
        <Form.Group className="mb-3" controlId="reason">
          <Form.Label>Reason</Form.Label>
          <Form.Select
            value={primaryReason}
            onChange={(e) => {
              setPrimaryReason(e.target.value as UnsubmitPrimaryReason);
              setValidated(false);
            }}
            required
            {...getValidityProps(validated && primaryReason === '', 'reason-feedback')}
          >
            <>
              <option value="" disabled>
                Select a reason
              </option>
              {Object.values(UnsubmitPrimaryReason).map((reason) => (
                <option value={reason} key={reason}>
                  {mapUnsubmitReasonToString(reason)}
                </option>
              ))}
            </>
          </Form.Select>
          <AccessibleFeedback displayFeedback={validated && primaryReason === ''} id="reason-feedback">
            Please select the primary reason for unsubmitting
          </AccessibleFeedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="furtherExplanation">
          <Form.Label>Further explanation</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={furtherExplanation}
            onChange={(e) => {
              setFurtherExplanation(e.target.value);
            }}
            required={primaryReason === UnsubmitPrimaryReason.Other}
            {...getValidityProps(
              validated && primaryReason === UnsubmitPrimaryReason.Other && furtherExplanation === '',
              'further-explanation-feedback',
            )}
          />
          <AccessibleFeedback
            displayFeedback={validated && primaryReason === UnsubmitPrimaryReason.Other && furtherExplanation === ''}
            id="further-explanation-feedback"
          >
            Please provider further explanation
          </AccessibleFeedback>
        </Form.Group>
        <Form.Check
          type="checkbox"
          label="Notify provider users"
          id="notify-toggle"
          className="mb-3"
          onChange={(e) => setIsNotifying(e.target.checked)}
          checked={isNotifying}
        />
        <PrimaryButton className="me-3" type="submit" value="confirm">
          Confirm
        </PrimaryButton>
      </Form>
      {error && (
        <Alert variant="danger" className="mt-3" dismissible onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
    </Stack>
  );
};

export default UnsubmitPage;
