/* global window, fetch */

// borrowing a JSON definition from https://stackoverflow.com/a/64117261,
// useful to help filter types that have dates in them and thus shouldn't be used in API directly

type JSONValue = string | number | boolean | null | JSONValue[] | { [key: string]: JSONValue | undefined };

interface JSONObject {
  [k: string]: JSONValue;
}

interface JSONArray extends Array<JSONValue> {
}

type QueryParameterTypes = { [key: string]: any } | null;

const defaultOptions: { credentials: 'include' } = {
  credentials: 'include',
};

export type APIResult = {
  successful: boolean;
  error?: string | null;
};

export type StandardErrorResponse<T = undefined> = {
  status: number;
  message: string | T;
};

//have to use string literal discriminators here due to typescript limitations
export type SuccessResponse<TSuccess> = { status: 'succeeded'; ok: true; value: TSuccess };
export type FailureResponse<TError> = { status: 'failed'; ok: false; value: StandardErrorResponse<TError> };

export type APIResponse<TSuccess, TError = string> = SuccessResponse<TSuccess> | FailureResponse<TError>;

export type UnhandledIntermediateFetchResult<T, U = string> = {
  justErrors: (errF: (e: StandardErrorResponse<U>) => Promise<void>) => Promise<T | undefined>;
  throwOnErrors: () => Promise<T>;
  withHandlers: <TOut = void>(
    onOk: (v: T) => TOut | Promise<TOut>,
    onFail: (e: StandardErrorResponse<U>) => TOut | Promise<TOut>,
  ) => Promise<TOut>;
  /* @deprecated This escape hatch is used for compatability but should not be used in new code and removed where possible */
  raw: Promise<APIResponse<T, U>>;
};

const buildApiWrapper = <T, U>(req: Promise<APIResponse<T, U>>): UnhandledIntermediateFetchResult<T, U> => ({
  justErrors: async (errF: (e: StandardErrorResponse<U>) => Promise<any>): Promise<T | undefined> => {
    const res = await req;
    if (!res.ok) {
      await errF(res.value);
      return undefined;
    }
    return res.value;
  },
  throwOnErrors: async () => {
    const res = await req;
    if (!res.ok) {
      throw { type: 'APIError', error: res.value };
    }
    return res.value;
  },
  withHandlers: async <TOut = void>(
    onOk: (v: T) => TOut | Promise<TOut>,
    onFail: (e: StandardErrorResponse<U>) => TOut | Promise<TOut>,
  ): Promise<TOut> => {
    const { ok, value } = await req;
    return ok ? onOk(value) : onFail(value);
  },
  raw: req,
});

export const APIHelpers = {
  none: () => Promise.resolve(null),
  json: async <T extends JSONValue>(response: Response): Promise<T> => (await response.json()) as T,
  standardError: async <T = string>(response: Response) => (await response.json()) as StandardErrorResponse<T>,
};

const handleAPIRequestInternal = async <TSuccess = null, TError = string>(
  request: Promise<Response>,
  successHandler: (response: Response) => Promise<TSuccess>,
  failHandler: (response: Response) => Promise<StandardErrorResponse<TError>>,
): Promise<APIResponse<TSuccess, TError>> => {
  try{
    const response = await request;
    if (response.ok) {
      return {
        status: 'succeeded',
        ok: true,
        value: await successHandler(response),
      };
    } else {
      return {
        status: 'failed',
        ok: false,
        value: await failHandler(response),
      };
    } 
  }
  catch (e){
    // TODO DCS-389: Connect this to remote logging in prod
    console.warn(e);
    return {
      status:'failed',
      ok:false,
      value: {status : NaN,message:'Something went wrong trying to make this request.'}
    }
  }
};

export const handleAPIRequest = <TSuccess = null, TError = undefined>(
  request: Promise<Response>,
  successHandler: (response: Response) => Promise<TSuccess>,
  failHandler: (response: Response) => Promise<StandardErrorResponse<TError>>,
): UnhandledIntermediateFetchResult<TSuccess, TError> =>
  buildApiWrapper(handleAPIRequestInternal(request, successHandler, failHandler));

const defaultOptionsJson = <T>(body: T) => ({
  ...defaultOptions,
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(body),
});

const redirectToLoginOn401 = async (query: Promise<Response>): Promise<Response> => {
  const response = await query;
  if (response.status === 401) {
    window.location.href = '/';
  }
  return response;
};

const fetchRaw = async (url: string, options: RequestInit | undefined, queryParameters: QueryParameterTypes = null) => {
  const urlObject = new URL(url);
  for (const q in queryParameters) {
    const parameterValue = queryParameters[q];
    if (Array.isArray(parameterValue)) {
      parameterValue.forEach((pv) => urlObject.searchParams.append(q, pv));
    } else if (parameterValue !== null) {
      urlObject.searchParams.append(q, parameterValue);
    }
  }
  return await redirectToLoginOn401(fetch(urlObject, options));
};

const getFileNameFromDisposition = (disposition: string | null) => {
  // Extract the file name from the content-disposition header
  const fileNamePart = disposition?.split(';').find((c) => c.includes('filename='));
  if (!fileNamePart) return null;
  const encodedFileName = fileNamePart.replace('filename=', '');
  const fileName = encodedFileName.toLowerCase().startsWith('utf-8\'\'')
    ? decodeURIComponent(encodedFileName.replace('utf-8\'\'', ''))
    : encodedFileName.replace(/['"]/g, '');
  return fileName.trim() || null;
};

export type NamedFile = {
  content: Blob;
  fileName: string;
};

class ApiClient {
  private readonly webApiBaseUrl: string;

  constructor(baseUrl: string) {
    this.webApiBaseUrl = baseUrl;
  }

  generatePath = (url: string) => `${this.webApiBaseUrl}/${url}`;

  get = async (url: string, queryParameters: QueryParameterTypes = null) => {
    return await fetchRaw(
      this.generatePath(url),
      defaultOptions,
      queryParameters,
    );
  };

  post = async <TBody>(url: string, body: TBody, queryParameters: QueryParameterTypes = null) => {
    return await fetchRaw(
      this.generatePath(url),
      {
        ...defaultOptionsJson(body),
        method: 'POST',
      },
      queryParameters,
    );
  };

  put = async <TBody>(url: string, body: TBody, queryParameters: QueryParameterTypes = null) => {
    return await fetchRaw(
      this.generatePath(url),
      {
        ...defaultOptionsJson(body),
        method: 'PUT',
      },
      queryParameters,
    );
  };

  patch = async <TBody>(url: string, body: TBody, queryParameters: QueryParameterTypes = null) => {
    return await fetchRaw(
      this.generatePath(url),
      {
        ...defaultOptionsJson(body),
        method: 'PATCH',
      },
      queryParameters,
    );
  };

  deleteAction = async (url: string, queryParameters: QueryParameterTypes = null) => {
    return await fetchRaw(
      this.generatePath(url),
      {
        ...defaultOptionsJson(null),
        method: 'DELETE',
      },
      queryParameters,
    );
  };

  getFile = async (url: string, queryParameters: QueryParameterTypes = null) => {
    const response = await fetchRaw(this.generatePath(url), defaultOptions, queryParameters);
    if (!response.ok) throw new Error(`Failed to get file: ${response.status}`);
    return await response.blob();
  };

  getFileWithName = async (
    url: string,
    fallbackFilename: string = 'UnknownFile',
    queryParameters: QueryParameterTypes = null,
  ) => {
    const response = await fetchRaw(this.generatePath(url), defaultOptions, queryParameters);
    if (!response.ok) throw new Error(`Failed to get file: ${response.status}`);
    const disposition = response.headers.get('Content-Disposition');
    const fileName = getFileNameFromDisposition(disposition);
    return {
      content: await response.blob(),
      fileName: fileName ?? fallbackFilename,
    };
  };

  putFormData = async (url: string, formData: FormData, queryParameters: QueryParameterTypes = null) => {
    return await fetchRaw(
      this.generatePath(url),
      {
        ...defaultOptions,
        method: 'PUT',
        body: formData,
      },
      queryParameters,
    );
  };

  patchFile = async (url: string, formData: FormData, queryParameters: QueryParameterTypes = null) => {
    return await fetchRaw(
      this.generatePath(url),
      {
        ...defaultOptions,
        method: 'PATCH',
        body: formData,
      },
      queryParameters,
    );
  };

  postFile = async (url: string, formData: FormData, queryParameters: QueryParameterTypes = null) => {
    return await fetchRaw(
      this.generatePath(url),
      {
        ...defaultOptions,
        method: 'POST',
        body: formData,
      },
      queryParameters,
    );
  };
}

export default ApiClient;
