import ApiClient, { APIHelpers, handleAPIRequest } from './ApiClient';
import { Tag } from './CommonTypes';

export type PublicDocument = {
  id: number;
  title: string;
  fileName: string;
  fileExtension: string;
  uploadDate: string;
  tags: Tag[];
};

export type DocumentsResponse = {
  documents: PublicDocument[];
};

export type UnauthenticatedPublicDocumentsApiSpec = {};
const UnauthenticatedPublicDocumentsApiRoot = 'UnauthenticatedPublicDocuments';
const UnauthenticatedPublicDocumentsApiPaths = {
  ALL_DOCUMENTS: UnauthenticatedPublicDocumentsApiRoot,
  DOWNLOAD: (documentId: string) => `${UnauthenticatedPublicDocumentsApiRoot}/${documentId}/Download`,
};

const UnauthenticatedPublicDocumentsApi = (apiClient: ApiClient) => ({
  getAll: () => {
    return handleAPIRequest(
      apiClient.get(UnauthenticatedPublicDocumentsApiPaths.ALL_DOCUMENTS),
      APIHelpers.json<DocumentsResponse>,
      APIHelpers.standardError,
    );
  },

  download: (documentId: string) => {
    return apiClient.getFile(UnauthenticatedPublicDocumentsApiPaths.DOWNLOAD(documentId));
  },
});

export default UnauthenticatedPublicDocumentsApi;
