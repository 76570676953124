import { ProviderRole } from 'nrosh-common/Api/Enums';
import { Provider, ProviderContact, ProviderWithStringDate, mapProviderResponse } from 'nrosh-common/Api/ProvidersApi';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import useEndpoint, { useEndpointConditionallyWithProcessing } from 'nrosh-common/Hooks/useEndpoint';
import { useContext } from 'react';
import { Stack } from 'react-bootstrap';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { MyProviderApi } from '@/Helpers/Apis';
import { providerDisplayName } from '@/Helpers/ProviderHelper';
import FeesInvoiceForm from '@/Pages/Providers/FeesInvoiceForm';
import ProviderContactsForm from '@/Pages/Providers/ProviderContactsForm';

const MyProviderDetailsPage = (): JSX.Element => {
  const [provider] = useEndpointConditionallyWithProcessing<ProviderWithStringDate, Provider>(
    MyProviderApi.getProvider,
    true,
    mapProviderResponse,
  );
  const [contacts] = useEndpoint<ProviderContact[]>(MyProviderApi.getProviderContacts);
  const auth = useContext(AuthContext);

  if (!provider || !contacts) {
    return <LoadingMessage />;
  }

  const canEditProviders = auth.hasRole(ProviderRole.EditProvider);

  return (
    <Stack gap={5}>
      <PageHeader
        heading={providerDisplayName(provider.name, provider.providerNumber)}
        crumbsType={RootPathType.MyProvider}
      />
      <Stack gap={3}>
        <h2>Registered Address</h2>
        <div>
          {provider.registeredAddress.addressLine1 && <p className="mb-2">{provider.registeredAddress.addressLine1}</p>}
          {provider.registeredAddress.addressLine2 && <p className="mb-2">{provider.registeredAddress.addressLine2}</p>}
          {provider.registeredAddress.addressLine3 && <p className="mb-2">{provider.registeredAddress.addressLine3}</p>}
          {provider.registeredAddress.addressLine4 && <p className="mb-2">{provider.registeredAddress.addressLine4}</p>}
          {provider.registeredAddress.addressLine5 && <p className="mb-2">{provider.registeredAddress.addressLine5}</p>}
          <p className="m-0 p-0">{provider.registeredAddress.postcode}</p>
        </div>
      </Stack>
      <p>
        If you need to update your registered name or address please email&nbsp;
        <a href="mailto:Team-DCS@softwire.com">Team-DCS@softwire.com</a>.
      </p>
      <Stack gap={3}>
        <h2>Fees Invoice Email</h2>
        <FeesInvoiceForm initialFeesInvoiceEmail={provider.feesInvoiceEmail} readonly={!canEditProviders} />
      </Stack>
      <Stack gap={3}>
        <h2>{canEditProviders ? 'Manage Contacts' : 'Contacts'}</h2>
        <ProviderContactsForm provider={provider} contacts={contacts} isAdmin={false} />
      </Stack>
    </Stack>
  );
};

export default MyProviderDetailsPage;
