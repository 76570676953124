import useRedirectWhenLoggedIn from '@/Hooks/useRedirectWhenLoggedIn';
import ForgotPasswordForm from '@/Pages/Home/PasswordChange/ForgotPasswordForm';

const ForgotPasswordPage = (): JSX.Element => {
  useRedirectWhenLoggedIn();

  return (
    <div className="d-flex flex-column">
      <h1 className="mx-auto pt-2 pb-3">Forgotten Password</h1>
      <ForgotPasswordForm />
    </div>
  );
};

export default ForgotPasswordPage;
