import { Stack } from 'react-bootstrap';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import LandingPage from '@/Components/LandingPage/LandingPage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { adminPages } from '@/Pages/Home/SitePages';

const ContentManagementLandingPage = (): JSX.Element => (
  <Stack gap={5}>
    <PageHeader heading="Content management" crumbsType={RootPathType.AdminContent} />
    <LandingPage
      description="Update the content of the news, FAQs and possibly other pages."
      pages={[
        { name: 'Manage news', path: adminPages.AdminNews.path },
        { name: 'Manage FAQs', path: adminPages.AdminFaqs.path },
        { name: 'Manage editable content', path: adminPages.AdminEditableContent.path },
        { name: 'Manage documents', path: adminPages.AdminPublicDocuments.path },
      ]}
    />
  </Stack>
);

export default ContentManagementLandingPage;
