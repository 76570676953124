import classNames from 'classnames';
import { APIResult, FailureResponse, UnhandledIntermediateFetchResult } from 'nrosh-common/Api/ApiClient';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { ChangeEvent, FormEvent, useContext, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import { AccessibleFeedback } from '@/Components/Form/AccessibleFeedback';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { AccountApi, placeHolderAPIErrorHandler } from '@/Helpers/Apis';
import { getValidityProps } from '@/Helpers/Forms';
import { passwordMinimumLength, passwordRegex } from '@/Helpers/Validation';
import { loginPages } from '@/Pages/Home/SitePages';
import '@/Pages/Home/PasswordChange/ChangePasswordForm.scss';

export enum ChangePasswordType {
  ActivateAccount = 'ActivateAccount',
  ResetPassword = 'ResetPassword',
  ChangePassword = 'ChangePassword',
}

type ResetPasswordProps = {
  token: string;
  username: string;
  isLoginLayout?: boolean;
  changePasswordType: ChangePasswordType.ActivateAccount | ChangePasswordType.ResetPassword;
};

type ChangePasswordProps = {
  token?: undefined;
  username?: undefined;
  isLoginLayout?: boolean;
  changePasswordType: ChangePasswordType.ChangePassword;
};

type ChangePasswordFormProps = ResetPasswordProps | ChangePasswordProps;

const ChangePasswordForm = (props: ChangePasswordFormProps): JSX.Element => {
  const { token, username, isLoginLayout = false, changePasswordType } = props;

  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [validated, setValidated] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setSubmitting] = useState(false);

  const isNewPasswordConfirmed = newPassword === confirmPassword;
  const isNewPasswordValid = passwordRegex.test(newPassword) && newPassword.length >= passwordMinimumLength;

  const isLoggedIn = !!auth.user;

  const onFieldChange = (setState: ReactStateSetter<string>) => (e: ChangeEvent<HTMLInputElement>) => {
    setState(e.target.value);
    setValidated(false);
  };

  const handleError = (response: FailureResponse<unknown>): void => {
    const unknownErrorMessage = 'An unexpected problem has occurred.';
    try {
      const body = response.value as {
        error?: string;
      };
      const errorMessage = body.error ?? unknownErrorMessage;
      setError(errorMessage);
    } catch {
      setError(unknownErrorMessage);
    }
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    setSubmitting(true);
    setError(null);
    event.preventDefault(); // Stops the page from reloading

    if ((isLoggedIn && !currentPassword) || !isNewPasswordValid || !isNewPasswordConfirmed) {
      setValidated(true);
      setSubmitting(false);
      return;
    }

    setValidated(false);

    const makeApiRequest = (
      type: ChangePasswordType,
    ): UnhandledIntermediateFetchResult<unknown, APIResult | string> => {
      switch (type) {
        case ChangePasswordType.ChangePassword:
          return AccountApi.changePassword({
            currentPassword,
            newPassword,
          });
        case ChangePasswordType.ResetPassword:
          return AccountApi.resetPassword({
            token: token ?? '',
            username: username ?? '',
            password: newPassword,
          });
        case ChangePasswordType.ActivateAccount:
          return AccountApi.activateAccount({
            token: token ?? '',
            username: username ?? '',
            password: newPassword,
          });
        default:
          throw Error('Bad switch');
      }
    };

    const response = await makeApiRequest(changePasswordType).raw;

    if (response.ok && isLoggedIn) {
      await AccountApi.logout().justErrors(placeHolderAPIErrorHandler);
      auth.setCurrentUser({ isLoggedIn: false, user: null });
      navigate(loginPages.PublicLogin.path, { state: { passwordChanged: true } });
      return;
    }
    if (response.ok && !isLoggedIn) {
      navigate(loginPages.PublicLogin.path, {
        state: {
          passwordChanged: changePasswordType === ChangePasswordType.ResetPassword,
          accountActivated: changePasswordType === ChangePasswordType.ActivateAccount,
        },
        replace: true,
      });
      return;
    }
    if (!response.ok) {
      handleError(response);
    }

    setSubmitting(false);
  };

  return (
    <div className={`changePasswordFormContainer ${classNames({ loginLayout: isLoginLayout })}`}>
      {error && (
        <Alert variant="danger" className={classNames({ 'text-center': isLoginLayout, 'w-75': !isLoginLayout })}>
          {error}
        </Alert>
      )}
      <Form
        onSubmit={onSubmit}
        noValidate
        autoComplete="off"
        className={classNames({ 'd-flex flex-column': isLoginLayout, 'w-25': !isLoginLayout })}
      >
        {isLoggedIn && (
          <Form.Group className="mb-3" controlId="currentPassword">
            <Form.Label>Current Password</Form.Label>
            <Form.Control
              type="password"
              value={currentPassword}
              onChange={onFieldChange(setCurrentPassword)}
              required
              {...getValidityProps(validated && !currentPassword, 'current-password-feedback')}
            />
            <AccessibleFeedback displayFeedback={validated && !currentPassword} id="current-password-feedback">
              Please provide your current password
            </AccessibleFeedback>
          </Form.Group>
        )}
        <Form.Group className="mb-3" controlId="newPassword">
          <Form.Label>New Password</Form.Label>
          <div id="passwordDescription" className="mb-2">
            <ul>
              <li>Must be at least {passwordMinimumLength} characters</li>
              <li>Must have at least one digit (0-9)</li>
              <li>Must contain at least one special character</li>
            </ul>
          </div>
          <Form.Control
            type="password"
            autoComplete="new-password"
            value={newPassword}
            onChange={onFieldChange(setNewPassword)}
            {...getValidityProps(
              validated && (!isNewPasswordValid || !isNewPasswordConfirmed),
              classNames(
                !isNewPasswordValid ? 'new-password-feedback' : undefined,
                !isNewPasswordConfirmed ? 'confirm-password-feedback' : undefined,
              ),
              'passwordDescription',
            )}
          />
          <AccessibleFeedback displayFeedback={validated && !isNewPasswordValid} id="new-password-feedback">
            Please provide a valid password
          </AccessibleFeedback>
        </Form.Group>
        <Form.Group className="mb-4" controlId="confirmPassword">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            value={confirmPassword}
            onChange={onFieldChange(setConfirmPassword)}
            {...getValidityProps(validated && !isNewPasswordConfirmed, 'confirm-password-feedback')}
          />
          <AccessibleFeedback displayFeedback={validated && !isNewPasswordConfirmed} id="confirm-password-feedback">
            The passwords do not match
          </AccessibleFeedback>
        </Form.Group>
        <div className={classNames({ 'ms-auto': isLoginLayout })}>
          {!isSubmitting ? (
            <PrimaryButton type="submit">Change Password</PrimaryButton>
          ) : (
            <LoadingButton message="Change Password" className="me-0" />
          )}
        </div>
      </Form>
    </div>
  );
};

export default ChangePasswordForm;
