import { Stack } from 'react-bootstrap';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import PageHeader from '@/Components/PageHeader/PageHeader';
import CloneSurveyForm from '@/Components/SurveyForm/CloneSurveyForm';
import '@/Styles/BootstrapOverrides/ListGroup.scss';
import { surveyPages } from '@/Pages/Home/SitePages';

const CloneExistingSurveyPage = (): JSX.Element => (
  <Stack gap={5}>
    <PageHeader
      heading="Clone existing survey"
      crumbsType={RootPathType.AdminSurveys}
      crumbs={[{ name: 'Clone existing survey', path: surveyPages.SurveyClone.path }]}
    />
    <CloneSurveyForm />
  </Stack>
);

export default CloneExistingSurveyPage;
