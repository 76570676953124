import { ProviderSummary } from 'nrosh-common/Api/ProvidersApi';
import { SearchableDropdown, SearchableDropdownOption } from '@/Components/SearchableDropdown/SearchableDropdown';

export type FilterProviderSelectionProps = {
  value: number | undefined;
  onChange: (e: number | undefined) => void;
  providers: ProviderSummary[] | null;
  includeRSH: boolean;
};

const FilterProviderSelection = (props: FilterProviderSelectionProps): JSX.Element => {
  const { value, providers, onChange, includeRSH } = props;

  let providerOptions: SearchableDropdownOption<number>[] = [];

  if (includeRSH) {
    providerOptions = [{ value: 0, label: 'DCS' }];
  }

  if (providers) {
    providerOptions = [
      ...providerOptions,
      ...providers.map((p) => ({ value: p.id, label: `${p.name} (${p.providerNumber})` })),
    ];
  }

  return (
    <SearchableDropdown
      options={providerOptions}
      currentSelection={value}
      placeholderText={`Select provider${includeRSH ? ' or DCS' : ''}`}
      onChange={onChange}
    />
  );
};

export default FilterProviderSelection;
