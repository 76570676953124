import { format } from 'date-fns';

// <input type="date" /> expects a string value in yyyy-mm-dd format]
export const formatDateStringForInput = (dateString: string): string => {
  if (!dateString) {
    return '';
  }

  return format(new Date(dateString), 'yyyy-MM-dd');
};

export const getStartOfDay = <T extends Date | null | undefined>(date: T): T => {
  if (!date) return date;
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate as T;
};

export const getEndOfDay = <T extends Date | null | undefined>(date: T): T => {
  if (!date) return date;
  const newDate = new Date(date);
  newDate.setHours(23, 59, 59, 999);
  return newDate as T;
};

export enum TimeResolution {
  Day,
  Minute,
  Second,
  HundredthSecond,
}

const formatStrings: Record<TimeResolution, string> = {
  [TimeResolution.Day]: 'dd/MM/yyyy',
  [TimeResolution.Minute]: 'dd/MM/yyyy HH:mm',
  [TimeResolution.Second]: 'dd/MM/yyyy HH:mm:ss',
  [TimeResolution.HundredthSecond]: 'dd/MM/yyyy HH:mm:ss.SS',
};

export const formatISODate = (date: Date | null, timeResolution: TimeResolution = TimeResolution.Day): string =>
  date ? format(date, formatStrings[timeResolution]) : '';

export const formatISODateString = (
  dateString: string | undefined | null,
  timeResolution: TimeResolution = TimeResolution.Day,
): string => {
  const date = dateString ? new Date(dateString) : null;
  return formatISODate(date, timeResolution);
};
