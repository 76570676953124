import saveAs from 'file-saver';
import { ProviderType, RshRole } from 'nrosh-common/Api/Enums';
import { ProviderSummary } from 'nrosh-common/Api/ProvidersApi';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { useContext } from 'react';
import { Stack } from 'react-bootstrap';
import { Link, generatePath } from 'react-router-dom';
import { Column } from 'react-table';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { useErrorReporting } from '@/Components/Errors/DCSErrorBoundary';
import ExportButton from '@/Components/ExportButton/ExportButton';
import FiltersComponent, { FilterType, getDefaultSelectedFilterInfo } from '@/Components/Filters/Filters';
import useFilters, { Filter } from '@/Components/Filters/useFilters';
import LinkButton from '@/Components/Links/LinkButton';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import Table from '@/Components/Table/Table';
import { ProvidersApi } from '@/Helpers/Apis';
import { caseInsensitiveSort } from '@/Helpers/TableHelper';
import { adminPages } from '@/Pages/Home/SitePages';
import { providerTypeNames } from '@/Pages/Providers/ProviderEnums';

const filters: Filter<ProviderSummary>[] = [
  {
    type: FilterType.Search,
    id: 'providerNumber',
    text: 'Provider Number',
    filter: (provider, filter) => provider.providerNumber.toLowerCase().includes(filter.toLowerCase().trim()),
    placeholder: 'Enter Provider number',
    ariaLabelledBy: 'providerNumber',
  },
  {
    type: FilterType.Search,
    id: 'name',
    text: 'Provider Name',
    filter: (provider, filter) => provider.name.toLowerCase().includes(filter.toLowerCase().trim()),
    placeholder: 'Enter Provider name',
    ariaLabelledBy: 'name',
  },
  {
    type: FilterType.Checkbox,
    id: 'type',
    text: 'Provider Classification',
    filter: (provider, values) => values.includes(provider.type || ''),
    options: [
      {
        value: ProviderType.Company,
        text: 'Company',
      },
    ],
  },
];

const providerTableColumns: Column<ProviderSummary>[] = [
  {
    Header: 'Provider Number',
    accessor: 'providerNumber',
    minWidth: 120,
  },
  {
    Header: 'Provider Name',
    accessor: 'name',
    sortType: caseInsensitiveSort,
    width: 220,
  },
  {
    Header: 'Provider Classification',
    accessor: 'type',
    Cell: ({ value }) => providerTypeNames[value],
    disableSortBy: true,
    width: 220,
  },
  {
    Header: 'Organisational Information',
    accessor: 'id',
    Cell: ({ value }) => (
      <Link to={generatePath(adminPages.AdminViewProvider.path, { providerId: value.toString() })}>
        View Provider details
      </Link>
    ),
    disableSortBy: true,
    minWidth: 140,
  },
];

const tableRowToExportRow = (
  providerSummary: ProviderSummary,
): { 'Provider Number': string; 'Provider Name': string; 'Provider Classification': string } => ({
  'Provider Number': providerSummary.providerNumber,
  'Provider Name': providerSummary.name,
  'Provider Classification': providerTypeNames[providerSummary.type],
});

const ProviderManagementPage = (): JSX.Element => {
  const [providers] = useEndpoint<ProviderSummary[]>(ProvidersApi.getProviders);
  const auth = useContext(AuthContext);
  const canEdit = auth.hasRole(RshRole.EditProviders);
  const [raiseError] = useErrorReporting();
  const { filteredData: filteredProviders, ...filterProps } = useFilters(providers || [], filters);

  const downloadContacts = async (): Promise<void> => {
    try {
      const users = await ProvidersApi.downloadProviderContacts();
      saveAs(URL.createObjectURL(users), 'Contacts.csv');
    } catch {
      raiseError();
    }
  };

  if (!filterProps.selectedFilter) {
    filterProps.setSelectedFilter(getDefaultSelectedFilterInfo(filters[0]));
  }

  if (!providers) {
    return <LoadingMessage />;
  }

  const pageTitle = `${canEdit ? 'Manage' : 'View'} providers`;

  return (
    <Stack gap={5}>
      <PageHeader
        heading={pageTitle}
        crumbsType={RootPathType.AdminProviders}
        crumbs={[{ name: pageTitle, path: adminPages.AdminManageProviders.path }]}
      />
      <div className="d-flex align-items-center gap-3 mb-3">
        <LinkButton to={adminPages.AdminAddProvider.path}>Add Provider</LinkButton>
        {auth.hasRole(RshRole.EditUsers) && <ExportButton label="Export Contacts" onDownload={downloadContacts} />}
      </div>
      <div>
        <FiltersComponent {...filterProps} heading="Search all Providers" />
        <Table
          data={filteredProviders}
          columns={providerTableColumns}
          defaultSort={[{ id: 'name', desc: false }]}
          paginated
          exportable
          tableRowToExportRow={tableRowToExportRow}
          exportFileName="Providers.csv"
          rowHeadingIndex={1}
        />
      </div>
    </Stack>
  );
};

export default ProviderManagementPage;
