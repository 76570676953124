import { DimensionalMember } from 'nrosh-common/Api/SubmissionsApi';
import { DataPoint } from 'nrosh-common/Api/SurveyPartsApi';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { orderDimensionMembersAndCreateCellId } from '@/Components/Spreadsheet/CellIdHelpers';
import { useValidationModel } from '@/Components/Validations/ValidationAccordionItem';
import { useValidationsAccordionContext } from '@/Components/Validations/Validations';

const dimensionMemberIds = (commaSeparatedMemberIds: string): number[] =>
  commaSeparatedMemberIds.split(',').map(Number);
const dimensionMember = (id: number, dimensionMembers: DimensionalMember[]): DimensionalMember =>
  dimensionMembers.find((dm) => dm.id === id)!;

const cellId = (
  dataPointId: string,
  commaSeparatedMemberIds: string,
  dimensionMembers: DimensionalMember[],
  dataPoints: DataPoint[],
): string => {
  const dataPoint = dataPoints.find((dp) => dp.id === dataPointId)!;
  const memberIds = dimensionMemberIds(commaSeparatedMemberIds);
  return orderDimensionMembersAndCreateCellId(dataPoint, memberIds, dimensionMembers);
};

const ValidationRule = (): JSX.Element => {
  const validation = useValidationModel();
  const { includeLinksToDataPoints, questionReferences, dimensionMembers, dataPoints } =
    useValidationsAccordionContext();

  const [selectedErrorDimensionState, setSelectedErrorDimensionState] = useState('');

  const formulaParts = validation.formula.slice(1).map((fp, position) => ({ ...fp, position }));

  const errorDimensionOptions = includeLinksToDataPoints
    ? validation.errorDimensions
        .map((ed) => ed.map((memberId) => dimensionMember(memberId, dimensionMembers)))
        .map((members) => ({ id: members.map((m) => m.id).join(','), value: members.map((m) => m.value).join(', ') }))
    : [];
  const selectedErrorDimension = errorDimensionOptions.map((option) => option.id).includes(selectedErrorDimensionState)
    ? selectedErrorDimensionState
    : '';

  const showDropdown = includeLinksToDataPoints && errorDimensionOptions.length > 0;
  const showLinks = includeLinksToDataPoints && (selectedErrorDimension || errorDimensionOptions.length === 0);
  const selectId = `${validation.validationId}-error-dimensions`;

  return (
    <div>
      {showDropdown && (
        <div>
          <label htmlFor={selectId}>
            Failing dimensions:
            <select
              className="ms-1"
              id={selectId}
              value={selectedErrorDimension}
              onChange={(e) => setSelectedErrorDimensionState(e.target.value)}
            >
              <option key="default-option" value="" disabled>
                Select...
              </option>
              {errorDimensionOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.value}
                </option>
              ))}
            </select>
          </label>
        </div>
      )}
      <span className="text-break">
        {formulaParts.map((fp) => {
          if (fp.dataPointId) {
            if (showLinks) {
              return (
                <Link
                  key={fp.position}
                  to={{
                    hash: `#${cellId(
                      fp.dataPointId,
                      selectedErrorDimension,
                      // When showLinks is true, both of these properties must be defined
                      dimensionMembers!,
                      dataPoints!,
                    )}`,
                  }}
                  state={{ lastClickedAt: new Date() }}
                >
                  {questionReferences[fp.dataPointId] || fp.displayValue}
                </Link>
              );
            }
            return questionReferences[fp.dataPointId] || fp.displayValue;
          }
          return <span key={fp.position}>{fp.displayValue}</span>;
        })}
      </span>
    </div>
  );
};

export default ValidationRule;
