import saveAs from 'file-saver';
import { ValidationStatus, ValidationType } from 'nrosh-common/Api/Enums';
import { DimensionalRegion } from 'nrosh-common/Api/SubmissionsApi';
import { DataDictionaryResponse } from 'nrosh-common/Api/SurveyPartsApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { Resizable } from 're-resizable';
import { useEffect, useState } from 'react';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import { useErrorReporting } from '@/Components/Errors/DCSErrorBoundary';
import LoadOnInitialRender from '@/Components/Loading/LoadOnInitialRender';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import { createCellId, parseCellId } from '@/Components/Spreadsheet/CellIdHelpers';
import Spreadsheet from '@/Components/Spreadsheet/Spreadsheet';
import { DataCellStyle, DataPoint, TabLayout } from '@/Components/Spreadsheet/SpreadsheetTypes';
import { SubmissionsApi } from '@/Helpers/Apis';
import SubmissionPartAlert from '@/Pages/Submissions/SubmissionPartAlert';
import { useSubmissionPartData } from '@/Pages/Submissions/SubmissionPartContext/SubmissionPartContext';
import SubmissionPartValidations, { active, all } from '@/Pages/Submissions/SubmissionPartValidations';
import ValidationHeadlines from '@/Pages/Submissions/ValidationHeadlines';
import '@/Pages/Submissions/SubmissionPart.scss';

const getSlicerValuesForDataPoint = (
  dataPoint: DataPoint,
  memberIds: number[],
  slicerRegions: DimensionalRegion[],
): Record<number, number> => {
  const [memberId1, memberId2] = memberIds;
  const slicerRegion1 = slicerRegions.find((sr) => sr.id === dataPoint.dimension1?.regionId);
  const slicerRegion2 = slicerRegions.find((sr) => sr.id === dataPoint.dimension2?.regionId);

  const slicerRegion1Update = memberId1 && slicerRegion1 ? { [slicerRegion1.id]: memberId1 } : {};
  const slicerRegion2Update = memberId2 && slicerRegion2 ? { [slicerRegion2.id]: memberId2 } : {};
  return { ...slicerRegion1Update, ...slicerRegion2Update };
};

const findDataPoint = (tab: TabLayout, dataPointId: string): DataPoint =>
  (tab.cellStyles.find((s) => 'dataPoint' in s && s.dataPoint.dataPointId === dataPointId)! as DataCellStyle).dataPoint;

const isEnum = (enumType: Record<never, string>, optionName: string): boolean =>
  Object.values(enumType).includes(optionName as typeof enumType);

const SubmissionPart = (): JSX.Element => {
  const { submissionPartId, surveyId, timePeriodId } = useParams();
  const location = useLocation();
  const {
    validations,
    surveyName,
    partName,
    timePeriodName,
    updateCommentInHub,
    deleteCommentInHub,
    isAdmin,
    layouts,
    dimensionRegions,
    setSelectedSlicerValues,
    setActiveCell,
  } = useSubmissionPartData();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [showValidations, setShowValidations] = useState(false);
  const [severityOption, setSeverityOption] = useState(all);
  const [statusOption, setStatusOption] = useState<ValidationStatus | string>(ValidationStatus.Fail);
  const [locationOption, setLocationOption] = useState(active);
  // Tech debt: This endpoint call may want to move to the submission part data context
  const [dataDictionary] = useEndpoint<DataDictionaryResponse>(SubmissionsApi.getDataDictionary, submissionPartId);
  const [lastScrollAt, setLastScrollAt] = useState(new Date());
  const [raiseError] = useErrorReporting();

  const slicerRegions = dimensionRegions.filter((dr) => dr.isSlicer);

  const hashLastUpdatedAt = (location.state as { lastClickedAt?: Date } | null)?.lastClickedAt;

  useEffect(() => {
    if (layouts && location.hash) {
      const result = parseCellId(location.hash);
      if (result.isValidCellId) {
        const activeTab = layouts.tabLayouts.find((l) =>
          l.cellStyles.some((s) => 'dataPoint' in s && s.dataPoint.dataPointId === result.dataPointId),
        );
        if (activeTab) {
          const activeTabIndex = layouts.tabLayouts.findIndex((t) => t.tabId === activeTab.tabId);
          if (result.dimensionMemberIds.length > 0) {
            const dataPoint = findDataPoint(activeTab, result.dataPointId);
            setSelectedSlicerValues((prevSelectedSlicerValues) => ({
              ...prevSelectedSlicerValues,
              ...getSlicerValuesForDataPoint(dataPoint, result.dimensionMemberIds, slicerRegions),
            }));
          }
          setSelectedTabIndex(activeTabIndex);
          setActiveCell(createCellId(result.dataPointId, ...result.dimensionMemberIds));
          // If the cell is not on the active tab, then we can't scroll to it yet.
          // Consequently we handle scrolling in a separate useEffect, so that it
          // will occur after the new active tab is rendered.
          setLastScrollAt(new Date());
        }
      }
    }
  }, [location.hash, hashLastUpdatedAt, layouts]);

  if (!layouts || !dataDictionary) {
    return <LoadingMessage />;
  }

  const { tabLayouts } = layouts;
  const selectedTabId = tabLayouts.length && tabLayouts[selectedTabIndex].tabId;

  const downloadValidations = async (): Promise<void> => {
    try {
      const fetchedValidations = await SubmissionsApi.downloadSubmissionPartValidationIssues(submissionPartId!, {
        ...(isEnum(ValidationType, severityOption) && {
          severityType: severityOption as ValidationType,
        }),
        ...(isEnum(ValidationStatus, statusOption) && {
          validationStatus: statusOption as ValidationStatus,
        }),
        ...(locationOption === active && { tabId: selectedTabId }),
      });
      saveAs(
        URL.createObjectURL(fetchedValidations),
        `${surveyName}_${partName}_${timePeriodName}-Validations.csv`.replaceAll(' ', '_'),
      );
    } catch {
      raiseError();
    }
  };
  if (tabLayouts.length === 0) {
    return <SubmissionPartAlert isAdmin={isAdmin} surveyId={surveyId!} timePeriodId={timePeriodId!} />;
  }

  return (
    <div className="d-flex flex-column flex-grow-1 height-0 gap-5">
      <ValidationHeadlines
        validations={validations}
        showValidations={showValidations}
        setShowValidations={setShowValidations}
      />
      <Container fluid className="flex-grow-1 height-0">
        <Row className="h-100">
          <Col className="scrollable h-100 p-0">
            <Tabs
              activeKey={selectedTabIndex}
              onSelect={(key) => setSelectedTabIndex(parseInt(key || '0', 10))}
              className="submissionPartTabs mb-3"
              mountOnEnter
              // If we remove the unmountOnExit flag then it greatly increases the speed at which users can navigate
              // between large survey tabs (after they have initially loaded). However, there is still a slight lag,
              // and RSH have decided that they would rather have a slow transition with a loading symbol, than a much
              // faster transition with no visible indication. See #553.
              unmountOnExit
            >
              {tabLayouts.map((tabLayout, index) => (
                <Tab key={tabLayout.tabId} eventKey={index} title={tabLayout.title} className="flex-grow-1 height-0">
                  <LoadOnInitialRender>
                    <Spreadsheet layout={tabLayout} lastScrollAt={lastScrollAt} isActive={index === selectedTabIndex} />
                  </LoadOnInitialRender>
                </Tab>
              ))}
            </Tabs>
          </Col>
          {showValidations && (
            <Resizable className="validationsColumnContainer pt-2" enable={{ left: true }}>
              <Col xs="auto" className="validationsColumn scrollable">
                <SubmissionPartValidations
                  validations={validations}
                  layout={tabLayouts.find((l) => l.tabId === selectedTabId)!}
                  dataDictionary={dataDictionary}
                  updateCommentInHub={updateCommentInHub}
                  deleteCommentInHub={deleteCommentInHub}
                  severityOption={severityOption}
                  setSeverityOption={setSeverityOption}
                  statusOption={statusOption}
                  setStatusOption={setStatusOption}
                  locationOption={locationOption}
                  setLocationOption={setLocationOption}
                  downloadValidations={downloadValidations}
                />
              </Col>
            </Resizable>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default SubmissionPart;
