import { Article, ArticleRequest } from 'nrosh-common/Api/NewsApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { Stack } from 'react-bootstrap';
import { generatePath, useParams } from 'react-router-dom';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { NewsApi } from '@/Helpers/Apis';
import NewsTabContainer from '@/Pages/Content/ManageNews/NewsTabContainer';
import { adminPages } from '@/Pages/Home/SitePages';

const EditNewsArticlePage = (): JSX.Element => {
  const { articleId } = useParams();
  const [article] = useEndpoint<Article>(NewsApi.getNewsArticle, articleId);

  const sendEditArticleRequest = async (
    articleRequest: ArticleRequest,
    unpublish?: boolean,
  ): Promise<{
    success: boolean;
    redirectPath?: string;
    errorMessage?: string;
  }> => {
    const response = await NewsApi.editNewsArticle(articleId!, articleRequest).raw;
    if (response.ok) {
      const redirectPath = unpublish ? generatePath(adminPages.AdminNews.path) : undefined;
      return { success: true, redirectPath };
    }
    const errorMessage = response.value.message;
    return { success: false, errorMessage };
  };

  if (!article) {
    return <LoadingMessage />;
  }

  return (
    <Stack gap={5}>
      <PageHeader
        heading="Edit news story"
        crumbsType={RootPathType.AdminContent}
        crumbs={[
          {
            name: 'Manage news',
            path: adminPages.AdminNews.path,
          },
          { name: 'Edit news story', path: adminPages.AdminNewsEdit.path },
        ]}
      />
      <NewsTabContainer article={article} sendArticleRequest={sendEditArticleRequest} />
    </Stack>
  );
};
export default EditNewsArticlePage;
