import classNames from 'classnames';
import { SubmissionStatus } from 'nrosh-common/Api/Enums';
import { SurveyStatus } from 'nrosh-common/Api/SubmissionsApi';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { RiErrorWarningFill } from 'react-icons/ri';
import { Link, generatePath } from 'react-router-dom';
import Badge, {
  BadgeColours,
  BadgeSizes,
  BadgeStyles,
  HardValidationBadge,
  SoftValidationBadge,
} from '@/Components/Badges/Badge';
import ArrowRight from '@/Components/Icons/ArrowRight';
import GreyCheckIcon from '@/Components/Icons/GreyCheckIcon';
import ProgressStepper from '@/Components/ProgressStepper/ProgressStepper';
import { surveyDisplayName } from '@/Helpers/SurveyHelper';
import { providerPages } from '@/Pages/Home/SitePages';
import '@/Pages/Submissions/SubmissionsPage.scss';
import { hasBeenSubmitted } from '@/Pages/Submissions/SubmissionHelpers';
import { SubmissionCard } from '@/Pages/Submissions/SubmissionsPage';

type Props = {
  submissionData: SubmissionCard[];
};

const getDeadlineClass = (daysRemaining: number, status: SubmissionStatus, type: string): string | undefined => {
  if (hasBeenSubmitted(status)) return undefined;
  if (daysRemaining <= 2) {
    return `redDeadline${type}`;
  }
  if (daysRemaining <= 5) {
    return `orangeDeadline${type}`;
  }
  return undefined;
};

const getDeadlineMessage = (daysRemaining: number, status: SubmissionStatus, date: string): JSX.Element | null => {
  if (hasBeenSubmitted(status)) return null;

  if (daysRemaining < 0) {
    return (
      <span className="d-flex align-items-center fw-bold redDeadlineText">
        <RiErrorWarningFill size="1.5rem" aria-hidden="true" /> <span className="ps-1">Survey overdue</span>
      </span>
    );
  }
  return (
    <span className={`${getDeadlineClass(daysRemaining, status, 'Text')} d-flex flex-column align-items-end`}>
      {daysRemaining === 0 ? (
        <span>
          <b>Submission due today</b>
        </span>
      ) : (
        <>
          <span>
            <b>{`${daysRemaining} ${daysRemaining === 1 ? 'day left' : 'days left'}`}</b> for
          </span>
          <span>{date} submission</span>
        </>
      )}
    </span>
  );
};

const getValidationLabel = (validations: number): string =>
  validations > 1 ? 'validation issues' : 'validation issue';

const getValidationMessage = (hard: number, soft: number): string =>
  hard > 1 || soft > 1 ? 'Validation issues' : 'Validation issue';

export const SubmissionCardComponent = ({
  id,
  surveyStatus,
  daysRemaining,
  status,
  surveyName,
  timePeriodName,
  hard,
  soft,
  submissionDeadline,
}: SubmissionCard): JSX.Element => (
  <Col key={id}>
    <Card
      className={classNames(surveyStatus !== SurveyStatus.Closed && getDeadlineClass(daysRemaining, status, 'Border'))}
    >
      <Card.Header
        bsPrefix="cardHeader"
        className={classNames(surveyStatus === SurveyStatus.InDevelopment && 'inDevelopmentSurveyCardBackground')}
      >
        <div className="headerContainer">
          <Link
            className="cardTitleLink"
            to={generatePath(providerPages.SubmissionsDetail.path, {
              submissionId: id.toString(),
            })}
          >
            {surveyDisplayName(surveyName, timePeriodName)}
            <ArrowRight />
          </Link>
        </div>
      </Card.Header>
      <Card.Body
        className={classNames(surveyStatus === SurveyStatus.InDevelopment && 'inDevelopmentSurveyCardStepper')}
      >
        <ProgressStepper submissionStatus={status} />
      </Card.Body>
      <Card.Footer
        bsPrefix="cardFooter"
        className={classNames(surveyStatus === SurveyStatus.InDevelopment && 'inDevelopmentSurveyCardBackground')}
      >
        <div className="footerContainer">
          <div className="validationsContainer">
            {hard > 0 && (
              <HardValidationBadge ariaLabel={`${hard} hard ${getValidationLabel(hard)} `}>{hard}</HardValidationBadge>
            )}
            {soft > 0 && (
              <SoftValidationBadge ariaLabel={`${soft} soft ${getValidationLabel(soft)} `}>{soft}</SoftValidationBadge>
            )}
            {soft > 0 || hard > 0 ? (
              <span className="validationText" aria-hidden="true">
                {getValidationMessage(hard, soft)}
              </span>
            ) : null}
          </div>
          {surveyStatus !== SurveyStatus.Closed && (
            <div className="d-flex align-items-center deadlineContainer">
              {getDeadlineMessage(daysRemaining, status, submissionDeadline)}
            </div>
          )}
          {surveyStatus === SurveyStatus.Closed && (
            <div className="deadlineContainer">
              <span className="d-flex align-items-center gap-2">
                <GreyCheckIcon ariaHidden />
                Survey closed
              </span>
            </div>
          )}
        </div>
      </Card.Footer>
    </Card>
  </Col>
);

const SubmissionsPageCard = ({ submissionData }: Props): JSX.Element => (
  <>
    <div className="d-flex gap-2 mb-3 keyContainer" aria-hidden="true">
      <span className="d-flex align-items-center">
        <Badge style={BadgeStyles.Circle} size={BadgeSizes.Small} colour={BadgeColours.Red} ariaLabel="" /> Hard issues
      </span>
      <span className="d-flex align-items-center">
        <Badge style={BadgeStyles.Square} size={BadgeSizes.Small} colour={BadgeColours.Blue} ariaLabel="" /> Soft issues
      </span>
    </div>
    <Row className="g-4" md="auto">
      {submissionData.map((submission) => {
        const CardComponent = (
          <SubmissionCardComponent
            id={submission.id}
            surveyName={submission.surveyName}
            timePeriodName={submission.timePeriodName}
            hard={submission.hard}
            soft={submission.soft}
            status={submission.status}
            daysRemaining={submission.daysRemaining}
            surveyStatus={submission.surveyStatus}
            submissionDeadline={submission.submissionDeadline}
            key={submission.id}
          />
        );

        if (submission.surveyStatus === SurveyStatus.InDevelopment) {
          return (
            <OverlayTrigger
              key={submission.id}
              placement="bottom"
              overlay={<Tooltip>This survey is in development</Tooltip>}
            >
              <div>{CardComponent}</div>
            </OverlayTrigger>
          );
        }
        return CardComponent;
      })}
    </Row>
  </>
);
export default SubmissionsPageCard;
