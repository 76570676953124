// TODO-343: Look at refactoring this
/* eslint-disable no-param-reassign */

import { APIResponse, SuccessResponse } from 'nrosh-common/Api/ApiClient';
import { ProviderRole, RshRole } from 'nrosh-common/Api/Enums';
import { CrossPartValidationIssue, CrossPartValidationIssuesResponse } from 'nrosh-common/Api/SubmissionsApi';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { useContext, useState } from 'react';
import { Alert, Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { SubmissionBreadcrumbs } from '@/Components/Breadcrumbs/SubmissionBreadcrumbs';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import { ValidationModel } from '@/Components/Validations/ValidationTypes';
import { SubmissionsApi, generateResponseErrorMessage } from '@/Helpers/Apis';
import { surveyDisplayName } from '@/Helpers/SurveyHelper';
import useSubmissionDetail from '@/Hooks/useSubmissionDetail';
import { providerPages, submissionPages } from '@/Pages/Home/SitePages';
import { mapCrossPartValidationToViewModel } from '@/Pages/Submissions/SubmissionHelpers';
import SubmissionValidationsView from '@/Pages/Submissions/SubmissionValidationsView';

const getValidationModels = (validations: CrossPartValidationIssue[]): ValidationModel[] =>
  validations.map(mapCrossPartValidationToViewModel);

const ReviewCrossPartValidationsPage = ({ isAdmin = false }: { isAdmin: boolean }): JSX.Element => {
  const { submissionId } = useParams();
  const [validationsResponse, setValidations] = useEndpoint<CrossPartValidationIssuesResponse>(
    SubmissionsApi.validateAndFetchCrossPartValidationIssues,
    submissionId,
  );
  const [data] = useSubmissionDetail(submissionId!);
  const [error, setError] = useState<string | null>(null);
  const auth = useContext(AuthContext);
  const userCanEdit = auth.hasOneOfRoles(RshRole.EditSurveyData, ProviderRole.EditSurveyData);

  if (!validationsResponse || !data) {
    return <LoadingMessage />;
  }

  const updateValidationWithId = (
    validationId: string,
    updateCallback: (v: CrossPartValidationIssue) => void,
  ): void => {
    const validationWithId = validationsResponse.validations.find((v) => v.validationId === validationId)!;
    const otherValidations = validationsResponse.validations.filter((v) => v.validationId !== validationId);

    updateCallback(validationWithId);

    setValidations((prevData) => ({
      ...prevData!,
      validations: [...otherValidations, validationWithId],
    }));
  };

  const handleUpdateResponse = async <T,>(
    response: APIResponse<T, unknown>,
    onSuccess: (response: SuccessResponse<T>) => void | Promise<void>,
  ): Promise<void> => {
    if (response.ok) {
      await onSuccess(response);
    } else {
      setError(generateResponseErrorMessage(response.value, () => null));
    }
  };
  const updateComment = async (validationId: string, comment: string): Promise<void> => {
    const response = await SubmissionsApi.editCrossPartValidationComment(submissionId!, validationId, comment).raw;

    await handleUpdateResponse(response, async (updateResponse) => {
      const body = updateResponse.value;
      updateValidationWithId(validationId, (v) => {
        v.comment = body.comment;
        v.commentUserName = body.userName;
        v.commentDateTime = body.dateTime;
      });
    });
  };

  const deleteComment = async (validationId: string): Promise<void> => {
    const response = await SubmissionsApi.deleteCrossPartValidationComment(submissionId!, validationId).raw;

    await handleUpdateResponse(response, async () => {
      updateValidationWithId(validationId, (v) => {
        v.comment = null;
        v.commentUserName = null;
        v.commentDateTime = null;
      });
    });
  };

  const toggleValidationApproval = async (validationId: string, isApproved: boolean): Promise<void> => {
    const endpoint = isApproved
      ? SubmissionsApi.approveCrossPartValidation
      : SubmissionsApi.unapproveCrossPartValidation;
    const response = await endpoint(submissionId!, validationId).raw;

    await handleUpdateResponse(response, async () => {
      updateValidationWithId(validationId, (v) => {
        v.isApproved = isApproved;
      });
    });
  };

  return (
    <Stack gap={5}>
      <SubmissionBreadcrumbs
        isRshUser={isAdmin}
        surveyDisplayName={surveyDisplayName(data.surveyName, data.timePeriodName)}
        providerName={data.provider.name}
        additionalRshCrumbs={[
          {
            name: 'Review cross part validations',
            path: submissionPages.AdminReviewCrossPartValidations.path,
          },
        ]}
        additionalProviderCrumbs={[
          {
            name: 'Review cross part validations',
            path: providerPages.ReviewCrossPartValidations.path,
          },
        ]}
      />
      {error && (
        <Alert variant="danger" dismissible onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
      <SubmissionValidationsView
        validations={getValidationModels(validationsResponse.validations)}
        submissionData={data}
        onFailuresAlertText="There are hard validation issues that need to be resolved before you can submit."
        exportButtonText="Export All Failing Validations"
        commentsAreReadonly={!userCanEdit}
        updateComment={updateComment}
        deleteComment={deleteComment}
        updateValidationApprovalStatus={toggleValidationApproval}
        isAdmin={isAdmin}
      />
    </Stack>
  );
};

export default ReviewCrossPartValidationsPage;
