import { Stack } from 'react-bootstrap';
import ChangePasswordForm, { ChangePasswordType } from '@/Pages/Home/PasswordChange/ChangePasswordForm';

const LoginAndSecurityPage = (): JSX.Element => (
  <Stack gap={5} className="mt-6">
    <Stack gap={3}>
      <h1>Login & security</h1>
      <h2>Change password</h2>
    </Stack>
    <p>Once your password has been successfully changed you will be logged out</p>
    <ChangePasswordForm changePasswordType={ChangePasswordType.ChangePassword} />
  </Stack>
);

export default LoginAndSecurityPage;
