import { EmailRecipientResponse, EmailRecipientsFilter } from 'nrosh-common/Api/EmailsApi';
import { ProviderSummary } from 'nrosh-common/Api/ProvidersApi';
import { SubmissionStatusDict } from 'nrosh-common/Api/SubmissionsApi';
import { Surveys } from 'nrosh-common/Api/SurveysApi';
import { UserProfile } from 'nrosh-common/Api/UsersApi';
import { ContactTypeTitleMapping } from '@/Components/EndOfSurveyChecks/ContactTypeTitleMapping';
import Table, { NroshColumn } from '@/Components/Table/Table';
import '@/Pages/Emails/EmailStyles.scss';
import { getSelectionTypeFromFilterOptions } from '@/Helpers/FilterHelpers';
import { surveyDisplayName } from '@/Helpers/SurveyHelper';
import { SelectionType } from '@/Pages/Emails/EmailUtilities';
import { EmailFilterSummaryItem } from '@/Pages/Emails/Manual/EmailFilterSummaryItem';
import { providerSizeNames, providerTypeNames, reportingStatusNames } from '@/Pages/Providers/ProviderEnums';

type EmailRecipientsViewProps = {
  emailRecipients: EmailRecipientResponse[] | null | undefined;
  recipientsFilter: EmailRecipientsFilter | null | undefined;
  surveyList: Surveys;
  profileList: UserProfile[];
  providerList: ProviderSummary[];
  isPreview?: boolean;
  hasEmailSent?: boolean;
};

const recipientRowToExportRow = (
  recipient: EmailRecipientResponse,
): { 'Sent Successfully': string; 'Name': string; 'Email Address': string; 'Provider': string } => ({
  'Name': recipient.name,
  'Email Address': recipient.address,
  'Provider': `${recipient.providerNumber} - ${recipient.providerName}`,
  'Sent Successfully': recipient.hasSent ? 'Yes' : 'No',
});

const providerRowToExportRow = (provider: ProviderSummary): { 'Provider Name': string; 'Provider Number': string } => ({
  'Provider Number': provider.providerNumber,
  'Provider Name': provider.name,
});

const excludedProvidersHeaders: NroshColumn<ProviderSummary>[] = [
  {
    Header: 'Provider Number',
    accessor: 'providerNumber',
  },
  {
    Header: 'Provider Name',
    accessor: 'name',
  },
];

const recipientsHeaders: NroshColumn<EmailRecipientResponse>[] = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Email Address',
    accessor: 'address',
  },
  {
    Header: 'Provider',
    accessor: 'providerNumber',
    Cell: ({ value, row }) => `${value} - ${row.original.providerName}`,
  },
  {
    Header: 'Sent Successfully',
    accessor: 'hasSent',
    align: 'center',
    Cell: ({ value }) => (value ? 'Yes' : 'No'),
  },
];

export const EmailRecipientsView = ({
  emailRecipients,
  recipientsFilter,
  surveyList,
  profileList,
  providerList,
  isPreview = false,
  hasEmailSent = false,
}: EmailRecipientsViewProps): JSX.Element => {
  if (!recipientsFilter) {
    return <p>No Recipients Selected</p>;
  }

  const selectedSurveyInstanceIds =
    recipientsFilter.surveyInstanceIds?.split(',')?.map((x) => {
      const splitSurveyInstanceId = x.split(':');
      return {
        surveyId: parseInt(splitSurveyInstanceId[0], 10),
        timePeriodId: parseInt(splitSurveyInstanceId[1], 10),
      };
    }) ?? [];
  const selectedSurveyInstanceNames = surveyList.surveys
    .map((s) =>
      s.instances
        .filter((si) =>
          selectedSurveyInstanceIds.some((id) => id.surveyId === s.surveyId && id.timePeriodId === si.timePeriodId),
        )
        .map((si) => surveyDisplayName(s.surveyName, si.timePeriodName)),
    )
    .flat();

  const submissionStatusIds = recipientsFilter?.submissionStatuses?.split(',');
  const selectedSubmissionStatusNames = Object.entries(SubmissionStatusDict)
    .filter(([submissionStatus]) => submissionStatusIds?.includes(submissionStatus) ?? false)
    .map(([, displayName]) => displayName);

  const providerSizes = recipientsFilter?.providerSizes?.split(',');
  const providerSizeDisplayNames = Object.entries(providerSizeNames)
    .filter(([providerSize]) => providerSizes?.includes(providerSize) ?? false)
    .map(([, displayName]) => displayName);

  const reportingStatuses = recipientsFilter?.reportingStatuses?.split(',');
  const reportingStatusDisplayNames = Object.entries(reportingStatusNames)
    .filter(([reportingStatus]) => reportingStatuses?.includes(reportingStatus) ?? false)
    .map(([, displayName]) => displayName);

  const providerTypes = recipientsFilter?.providerTypes?.split(',');
  const providerTypeDisplayNames = Object.entries(providerTypeNames)
    .filter(([providerType]) => providerTypes?.includes(providerType) ?? false)
    .map(([, displayName]) => displayName);

  const contactTypeIds = recipientsFilter?.providerContactsContactTypes?.split(',');
  const selectedOrganisationalContactNames = Object.entries(ContactTypeTitleMapping)
    .filter(([contactTypeTitleMapping]) => contactTypeIds?.includes(contactTypeTitleMapping) ?? false)
    .map(([, displayName]) => displayName);

  const userProfileIds = recipientsFilter?.userProfiles?.split(',');
  const selectedUserProfileNames = profileList
    .filter((up) => userProfileIds?.includes(up.id.toString()) ?? false)
    .map((up) => up.name);

  const excludedProviderIds = recipientsFilter?.excludedProviderIds?.split(',').map(parseInt) ?? [];
  const excludedProviders = providerList.filter((p) => excludedProviderIds.includes(p.id));

  return (
    <>
      {!isPreview && (
        <>
          <h3>Filter</h3>
          <ul>
            <EmailFilterSummaryItem
              selectionType={selectedSurveyInstanceNames.length > 0 ? SelectionType.Specific : SelectionType.All}
              singularDescription="Survey"
              pluralDescription="Surveys"
              listItems={selectedSurveyInstanceNames}
              itemKeyBase="si"
            />
            <EmailFilterSummaryItem
              selectionType={selectedSubmissionStatusNames.length > 0 ? SelectionType.Specific : SelectionType.All}
              singularDescription="Submission Status"
              pluralDescription="Submission Statuses"
              listItems={selectedSubmissionStatusNames}
              itemKeyBase="ss"
            />
            <EmailFilterSummaryItem
              selectionType={providerSizeDisplayNames.length > 0 ? SelectionType.Specific : SelectionType.All}
              singularDescription="Provider Size"
              pluralDescription="Provider Sizes"
              listItems={providerSizeDisplayNames}
              itemKeyBase="ps"
            />
            <EmailFilterSummaryItem
              selectionType={reportingStatusDisplayNames.length > 0 ? SelectionType.Specific : SelectionType.All}
              singularDescription="Reporting Status"
              pluralDescription="Reporting Statuses"
              listItems={reportingStatusDisplayNames}
              itemKeyBase="rs"
            />
            <EmailFilterSummaryItem
              selectionType={providerTypeDisplayNames.length > 0 ? SelectionType.Specific : SelectionType.All}
              singularDescription="Provider Type"
              pluralDescription="Provider Types"
              listItems={providerTypeDisplayNames}
              itemKeyBase="pt"
            />
            <EmailFilterSummaryItem
              selectionType={getSelectionTypeFromFilterOptions(recipientsFilter.providerContactsContactTypes)}
              singularDescription="Organisational Contact"
              pluralDescription="Organisational Contacts"
              listItems={selectedOrganisationalContactNames}
              itemKeyBase="oc"
            />
            <EmailFilterSummaryItem
              selectionType={getSelectionTypeFromFilterOptions(recipientsFilter.userProfiles)}
              singularDescription="User Profile"
              pluralDescription="User Profiles"
              listItems={selectedUserProfileNames}
              itemKeyBase="up"
            />
          </ul>
        </>
      )}
      {excludedProviders.length > 0 && !isPreview && (
        <>
          <h3>Excluded Providers</h3>
          <Table
            data={excludedProviders}
            columns={excludedProvidersHeaders}
            searchable
            paginated
            className="borderedContainer"
            exportable
            exportFileName="Excluded Providers.csv"
            tableRowToExportRow={providerRowToExportRow}
            rowHeadingIndex={1}
          />
        </>
      )}
      {emailRecipients && !isPreview && <h3>Detailed Recipients</h3>}
      {emailRecipients && !hasEmailSent && (
        <p className="fst-italic">
          Please note that until this email is sent the list of recipients is provisional and is subject to change.
        </p>
      )}
      {emailRecipients && <div className="mb-3">Total Recipients: {emailRecipients.length}</div>}
      {emailRecipients && (
        <Table
          data={emailRecipients}
          columns={recipientsHeaders}
          searchable
          paginated
          className={isPreview ? '' : 'borderedContainer'}
          hiddenColumns={isPreview ? ['hasSent'] : []}
          exportable
          exportFileName="Email Recipients.csv"
          tableRowToExportRow={recipientRowToExportRow}
          rowHeadingIndex={0}
        />
      )}
    </>
  );
};
