import { useState } from 'react';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import { LoadingButton } from '@/Components/Loading/LoadingButton';

type FileDownloadButtonProps = {
  onDownload: () => Promise<void>;
  buttonText?: string;
};

const FileDownloadButton = ({ onDownload, buttonText }: FileDownloadButtonProps): JSX.Element => {
  const [isDownloading, setDownloading] = useState(false);

  const onButtonClick = async (): Promise<void> => {
    setDownloading(true);
    await onDownload();
    setDownloading(false);
  };

  return !isDownloading ? (
    <PrimaryButton type="button" onClick={onButtonClick}>
      {buttonText || 'Download'}
    </PrimaryButton>
  ) : (
    <LoadingButton message="Downloading..." />
  );
};

export default FileDownloadButton;
