import '@/Pages/Submissions/SubmissionPart.scss';
import { ProviderRole, RshRole, SubmissionStatus } from 'nrosh-common/Api/Enums';
import { SubmissionDetail } from 'nrosh-common/Api/SubmissionsApi';
import AuthContext, { Auth } from 'nrosh-common/Contexts/AuthContext';
import { useContext, useEffect } from 'react';
import { Stack } from 'react-bootstrap';
import { generatePath, useParams } from 'react-router-dom';
import '@/Pages/Submissions/SubmissionPartPage.scss';
import { TertiaryLinkButton } from '@/Components/Buttons/TertiaryLinkButton';
import ChevronLeft from '@/Components/Icons/ChevronLeft';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import SubmissionPageHeader from '@/Components/PageHeader/SubmissionPageHeader';
import ToastWithIcon, { ToastType } from '@/Components/ToastWithIcon/ToastWithIcon';
import { SubmissionsApi, placeHolderAPIErrorHandler } from '@/Helpers/Apis';
import { surveyDisplayName } from '@/Helpers/SurveyHelper';
import useSubmissionDetail from '@/Hooks/useSubmissionDetail';
import { providerPages, submissionPages } from '@/Pages/Home/SitePages';
import { canBeSubmitted, submissionIsReadonly } from '@/Pages/Submissions/SubmissionHelpers';
import SubmissionPart from '@/Pages/Submissions/SubmissionPart';
import SubmissionPartContextProvider from '@/Pages/Submissions/SubmissionPartContext/SubmissionPartContext';

export type SubmissionsPartPageHeaderProps = {
  auth: Auth;
  isAdmin: boolean;
  submissionData: SubmissionDetail;
  submissionPartId: string;
};

export const SubmissionsPartPageHeader = ({
  auth,
  isAdmin,
  submissionData,
  submissionPartId,
}: SubmissionsPartPageHeaderProps): JSX.Element => {
  const surveyPart = submissionData.parts.find((part) => part.id === Number(submissionPartId));
  const readonly =
    submissionIsReadonly(submissionData.status, isAdmin, submissionData.surveyStatus) ||
    !auth.hasOneOfRoles(RshRole.EditSurveyData, ProviderRole.EditSurveyData);
  const breadcrumbName = `${readonly ? 'View' : 'Edit'} ${surveyPart?.name}`;

  return (
    <Stack gap={4} className="flex-grow-0">
      <Stack gap={3}>
        <SubmissionPageHeader
          heading={surveyPart?.name ?? ''}
          isRshUser={isAdmin}
          surveyDisplayName={surveyDisplayName(submissionData.surveyName, submissionData.timePeriodName)}
          providerName={submissionData.provider.name}
          additionalRshCrumbs={[
            {
              name: breadcrumbName,
              path: submissionPages.AdminSubmissionPartEdit.path,
            },
          ]}
          additionalProviderCrumbs={[
            {
              name: breadcrumbName,
              path: providerPages.ProviderSubmissionPartEdit.path,
            },
          ]}
        />
        <p className="p-0 m-0">All changes made to the survey are saved automatically.</p>
      </Stack>
      {surveyPart &&
        surveyPart.hardValidationIssues > 0 &&
        canBeSubmitted(submissionData.status, submissionData.surveyStatus) && (
          <ToastWithIcon
            text="There cannot be hard validation issues in any of the parts in order to submit this survey."
            type={ToastType.Info}
          />
        )}
    </Stack>
  );
};

const SubmissionPartPage = ({ isAdmin = false }: { isAdmin?: boolean }): JSX.Element => {
  const { surveyId, timePeriodId, submissionId, submissionPartId } = useParams();
  const [submissionData] = useSubmissionDetail(submissionId!);
  const auth = useContext(AuthContext);

  useEffect(() => {
    // We mark the submission as 'In Progress' the first time a provider user visits this page, regardless of whether
    // they make changes. This avoids having to check the status on every data point update. To some extent this
    // mirrors what we do when updating on bulk import/part template upload, in that we don't check whether any data
    // changes were actually made, just that the action happened.
    if (submissionData?.status === SubmissionStatus.NotStarted && auth.hasRole(ProviderRole.EditSurveyData)) {
      const startSubmission = async (): Promise<void> => {
        await SubmissionsApi.start(submissionId!).justErrors(placeHolderAPIErrorHandler);
      };
      startSubmission().catch(() => {});
    }
  }, [submissionData?.status, submissionId]);

  if (!submissionData) {
    return <LoadingMessage />;
  }

  const submissionPath = isAdmin
    ? generatePath(submissionPages.SubmissionView.path, {
        surveyId,
        timePeriodId,
        submissionId,
      })
    : generatePath(providerPages.SubmissionsDetail.path, { submissionId });

  return (
    <Stack gap={5} className="d-flex flex-column submissionPartPageContainer">
      <SubmissionsPartPageHeader
        auth={auth}
        isAdmin={isAdmin}
        submissionData={submissionData}
        submissionPartId={submissionPartId!}
      />
      <SubmissionPartContextProvider
        submissionPartId={submissionPartId!}
        submissionId={submissionId!}
        isAdmin={isAdmin}
      >
        <SubmissionPart />
      </SubmissionPartContextProvider>
      <TertiaryLinkButton to={submissionPath} icon={<ChevronLeft />} text="Return to survey overview" />
    </Stack>
  );
};

export default SubmissionPartPage;
