import { User } from 'nrosh-common/Api/UsersApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { Alert, Stack } from 'react-bootstrap';
import { generatePath, useParams } from 'react-router-dom';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { UsersApi } from '@/Helpers/Apis';
import { adminPages, providerPages } from '@/Pages/Home/SitePages';
import EditUserEmailForm from '@/Pages/Users/EditUserEmailForm';

type EditUserEmailPageProps = { isAdmin?: boolean };

export const EditUserEmailPage = ({ isAdmin }: EditUserEmailPageProps): JSX.Element => {
  const { userId } = useParams();
  const [user] = useEndpoint<User>(UsersApi.getUser, userId);
  const path = isAdmin ? adminPages.AdminEditUser.path : providerPages.EditUser.path;

  if (!user) {
    return <LoadingMessage />;
  }

  return (
    <Stack gap={5}>
      <PageHeader
        heading="Edit user email address"
        crumbsType={isAdmin ? RootPathType.AdminUsers : RootPathType.ProviderUsers}
        crumbs={
          isAdmin
            ? [
                {
                  name: 'Edit user',
                  path: adminPages.AdminEditUser.path,
                },
                {
                  name: 'Edit user email address',
                  path: adminPages.AdminEditUserEmail.path,
                },
              ]
            : [
                {
                  name: 'Edit user',
                  path: providerPages.EditUser.path,
                },
                {
                  name: 'Edit user email address',
                  path: providerPages.EditUserEmail.path,
                },
              ]
        }
      />
      <Alert variant="warning" className="w-75">
        When you update the user&#39;s email address a confirmation email will be sent to the new address. Please note
        that the user will not be able to log in to DCS again until they have clicked on the confirmation link in this
        email.
      </Alert>
      <EditUserEmailForm
        hasRestrictedDomain={!user.providerId}
        user={user}
        authoriseMessage="To update the user's email address, enter your password and click update"
        redirectPath={generatePath(path, {
          userId: user.id.toString(),
        })}
      />
    </Stack>
  );
};
