import { ProfitStatus, ProviderSize, ProviderType, RegistrationStatus, ReportingStatus } from 'nrosh-common/Api/Enums';

export const providerTypeOptions = {
  [ProviderType.Company]: 'Company',
};

export const providerTypeNames: Record<ProviderType, string> = {
  ...providerTypeOptions,
  [ProviderType.Other]: 'Other',
};

export const reportingStatusNames: Record<ReportingStatus, string> = {
  [ReportingStatus.Mainstream]: 'Mainstream',
  [ReportingStatus.MainstreamGroupOnly]: 'Mainstream Group Only',
  [ReportingStatus.Small]: 'Small',
  [ReportingStatus.NotSpecified]: 'Not Specified',
};

export const providerSizeNames: Record<ProviderSize, string> = {
  [ProviderSize.Large]: 'Large',
  [ProviderSize.Small]: 'Small',
  [ProviderSize.NotSpecified]: 'Not Specified',
};

export const registrationStatusOptions = {
  [RegistrationStatus.Registered]: 'Registered',
  [RegistrationStatus.NonRegistered]: 'Not Registered',
};

export const registrationStatusNames: Record<RegistrationStatus, string> = {
  ...registrationStatusOptions,
  [RegistrationStatus.NotSpecified]: 'Not Specified',
  [RegistrationStatus.Other]: 'Other',
};

export const profitStatusNames: Record<ProfitStatus, string> = {
  [ProfitStatus.Profit]: 'Profit',
  [ProfitStatus.NonProfit]: 'Non-profit',
  [ProfitStatus.LocalAuthority]: 'Local Authority',
  [ProfitStatus.NotSpecified]: 'Not Specified',
};
