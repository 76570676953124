import { SurveyStatus } from 'nrosh-common/Api/SubmissionsApi';
import { Badge } from 'react-bootstrap';

type SurveyStatusProps = {
  status: SurveyStatus;
};

const SurveyStatusBadge = ({ status }: SurveyStatusProps): JSX.Element => {
  const badgeText = {
    [SurveyStatus.Closed]: 'Survey is closed',
    [SurveyStatus.InDevelopment]: 'In development',
    [SurveyStatus.Open]: 'Survey is open',
  };

  const backgroundColours = {
    [SurveyStatus.Closed]: 'dcs-status-closed',
    [SurveyStatus.InDevelopment]: 'dcs-status-indevelopment',
    [SurveyStatus.Open]: 'dcs-status-open',
  };

  return (
    <Badge pill className="fw-normal fs-4 ms-3 py-2 px-3" bg={backgroundColours[status]} text="dark">
      {badgeText[status]}
    </Badge>
  );
};

export default SurveyStatusBadge;
