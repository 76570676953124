import classNames from 'classnames';
import { APIResponse, APIResult, FailureResponse } from 'nrosh-common/Api/ApiClient';
import { ProviderRole } from 'nrosh-common/Api/Enums';
import { ProviderUsers, User, UserStatus } from 'nrosh-common/Api/UsersApi';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { useContext, useReducer, useState } from 'react';
import { Alert, Form, Stack } from 'react-bootstrap';
import { Link, generatePath } from 'react-router-dom';
import { Column, Row } from 'react-table';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import FiltersComponent, { FilterType, getDefaultSelectedFilterInfo } from '@/Components/Filters/Filters';
import useFilters, { Filter } from '@/Components/Filters/useFilters';
import ArrowRight from '@/Components/Icons/ArrowRight';
import DeleteIcon from '@/Components/Icons/Delete';
import Edit from '@/Components/Icons/Edit';
import LinkButton from '@/Components/Links/LinkButton';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import { useModal } from '@/Components/Modal/ModalProvider';
import PageHeader from '@/Components/PageHeader/PageHeader';
import Table from '@/Components/Table/Table';
import { UsersApi, generateResponseErrorMessage } from '@/Helpers/Apis';
import { TimeResolution, formatISODateString } from '@/Helpers/DateHelpers';
import { providerDisplayName } from '@/Helpers/ProviderHelper';
import { providerPages } from '@/Pages/Home/SitePages';
import '@/Pages/Users/ProviderUserManagementPage.scss';
import { UserStatusNameMapping } from '@/Pages/Users/UserStatusNameMapping';
import { tableRowToExportRow } from '@/Pages/Users/UsersHelper';

enum BulkAction {
  Activate = 'Activate',
  Disable = 'Disable',
  Delete = 'Delete',
}

const getFilters = (profiles: string[]): Filter<User>[] => [
  {
    type: FilterType.Search,
    id: 'name',
    text: 'Name',
    filter: (user, filter) =>
      user.name.toLowerCase().includes(filter.toLowerCase().trim()) ||
      !!user.preferredFormOfAddress?.toLowerCase().includes(filter.toLowerCase().trim()),
    placeholder: 'Enter name',
    ariaLabelledBy: 'name',
  },
  {
    type: FilterType.Search,
    id: 'username',
    text: 'Username',
    filter: (user, filter) => user.userName.toLowerCase().includes(filter.toLowerCase().trim()),
    placeholder: 'Enter username',
    ariaLabelledBy: 'username',
  },
  {
    type: FilterType.Search,
    id: 'email',
    text: 'Email address',
    filter: (user, filter) => user.emailAddress.toLowerCase().includes(filter.toLowerCase().trim()),
    placeholder: 'Enter email',
    ariaLabelledBy: 'email',
  },
  {
    type: FilterType.Dropdown,
    id: 'type',
    text: 'Profile',
    placeholder: 'Select profile',
    filter: (user, value) => user.profile?.name === value,
    options: profiles.map((profile) => ({
      value: profile,
      text: profile,
    })),
  },
  {
    type: FilterType.Checkbox,
    id: 'status',
    text: 'Status',
    filter: (user, values) => values.includes(user.status),
    options: [
      {
        value: UserStatus.AwaitingActivation,
        text: 'Awaiting Activation',
      },
      {
        value: UserStatus.AwaitingReactivation,
        text: 'Awaiting Reactivation',
      },
      {
        value: UserStatus.Active,
        text: 'Active',
      },
      {
        value: UserStatus.Locked,
        text: 'Locked',
      },
      {
        value: UserStatus.Disabled,
        text: 'Disabled',
      },
    ],
  },
];

const getUserTableColumns = (
  currentUserId: number | undefined,
  selectedUsers: number[],
  setSelectedUsers: ReactStateSetter<number[]>,
  setAllUsersSelected: () => void,
  deleteUser: (id: number) => Promise<void>,
  canEditUsers: boolean,
  loggedInUserName: string | undefined,
): Column<User>[] => [
  ...(canEditUsers
    ? [
        {
          Header: ({ rows }) => {
            const rowsIncludesCurrentUser = rows.some((row) => row.original.id === currentUserId);

            return (
              <div className="d-flex align-items-center">
                <input
                  type="checkbox"
                  className="me-3"
                  aria-label="Select all"
                  checked={
                    rows.length > (rowsIncludesCurrentUser ? 1 : 0) &&
                    selectedUsers.length >= (rowsIncludesCurrentUser ? rows.length - 1 : rows.length)
                  }
                  disabled={rows.length === (rowsIncludesCurrentUser ? 1 : 0)}
                  onChange={(e) => {
                    const isChecked = e.target.checked;

                    if (!isChecked) {
                      setSelectedUsers([]);
                    } else {
                      setAllUsersSelected();
                    }
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              </div>
            );
          },
          accessor: 'id',
          width: 40,
          Cell: ({ value: id }) =>
            id !== currentUserId && (
              <div className="d-flex align-items-center">
                <input
                  type="checkbox"
                  className="me-3"
                  aria-label="Select all"
                  checked={selectedUsers.includes(id)}
                  onChange={(e) => {
                    const isChecked = e.target.checked;

                    if (!isChecked) {
                      setSelectedUsers((selected) => selected.filter((selectedId) => id !== selectedId));
                    } else {
                      setSelectedUsers((selected) => [...selected, id]);
                    }
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              </div>
            ),
          disableSortBy: true,
          disableResizing: true,
          getResizerProps: () => {},
        } as Column<User>,
      ]
    : []),
  {
    Header: 'Name',
    accessor: 'name',
    minWidth: 160,
    Cell: ({ row }) => (
      <>
        {row.original.name}
        {row.original.preferredFormOfAddress && ` (${row.original.preferredFormOfAddress})`}
      </>
    ),
  },
  {
    Header: 'Username',
    accessor: 'userName',
  },
  {
    Header: 'User Profile',
    accessor: 'profile',
    Cell: ({ value }) => value?.name,
  },
  {
    Header: 'Email Address',
    accessor: 'emailAddress',
    Cell: ({ value }) => (value.includes('@') ? <a href={`mailto:${value}`}>{value}</a> : value),
  },
  {
    Header: 'User Status',
    accessor: 'status',
    Cell: ({ value }) => UserStatusNameMapping[value],
  },
  {
    Header: 'Last Successful Login',
    accessor: 'lastSuccessfulLogin',
    Cell: ({ value }) => formatISODateString(value, TimeResolution.Second),
  },
  ...(canEditUsers
    ? [
        {
          Header: 'Edit',
          align: 'center' as const,
          width: 80,
          Cell: ({ row }: { row: Row<User> }) =>
            loggedInUserName !== row.original.userName && (
              <Link to={generatePath(providerPages.EditUser.path, { userId: row.original.id.toString() })}>
                <Edit />
              </Link>
            ),
          disableSortBy: true,
          disableResizing: true,
          getResizerProps: () => {},
        } as Column<User>,
        {
          Header: 'Delete',
          width: 90,
          Cell: ({ row }: { row: Row<User> }) =>
            row.original.id !== currentUserId && (
              <button
                className="deleteButton d-flex flex-column align-items-center p-0 w-100"
                type="button"
                onClick={() => deleteUser(row.original.id)}
                aria-label={`Delete ${row.original.name}`}
              >
                <DeleteIcon />
              </button>
            ),
          disableSortBy: true,
          disableResizing: true,
          getResizerProps: () => {},
        } as Column<User>,
      ]
    : []),
];

const ProviderUserManagementPage = (): JSX.Element => {
  const [lastRefreshTime, triggerDataRefresh] = useReducer(() => Date.now(), Date.now());
  const [provider] = useEndpoint<ProviderUsers>(UsersApi.getProviderUsers, lastRefreshTime);

  const profilesList = provider ? Array.from(new Set(provider.users.map((u) => u.profile?.name))).filter(Boolean) : [];

  const filters = provider ? getFilters(profilesList as string[]) : [];

  const { filteredData, ...filterProps } = useFilters(provider?.users || [], filters);

  const auth = useContext(AuthContext);

  const [selectedBulkAction, setSelectedBulkAction] = useState<BulkAction>();
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { confirm } = useModal();

  const handleError = async (response: FailureResponse<APIResult>): Promise<void> => {
    const unknownErrorMessage = 'An unexpected problem has occurred.';
    try {
      const body = response.value;
      const errorMessage = generateResponseErrorMessage(body, (err) => err.error, unknownErrorMessage);
      setError(errorMessage);
    } catch {
      setError(unknownErrorMessage);
    }
  };

  const triggerBulkAction = async (): Promise<void> => {
    if (!selectedBulkAction) {
      return;
    }

    if (
      selectedBulkAction === BulkAction.Delete &&
      !(await confirm(
        <div>
          <p>
            Are you sure you want to delete <strong>{selectedUsers.length}</strong> users?
          </p>
          <p>
            <strong> This is an irreversible action.</strong>
          </p>
        </div>,
      ))
    ) {
      return;
    }

    setError(null);
    setIsSubmitting(true);

    let response: APIResponse<APIResult, APIResult>;

    switch (selectedBulkAction) {
      case BulkAction.Activate:
        response = await UsersApi.enableUsers(selectedUsers).raw;
        break;
      case BulkAction.Disable:
        response = await UsersApi.disableUsers(selectedUsers).raw;
        break;
      case BulkAction.Delete:
        response = await UsersApi.deleteUsers(selectedUsers).raw;
        break;
      default:
        throw Error('Bad Enum');
    }

    if (!response.ok) {
      await handleError(response);
    } else {
      setSelectedUsers([]);
    }

    setIsSubmitting(false);
    triggerDataRefresh();
  };

  const deleteUser = async (userId: number): Promise<void> => {
    if (!(await confirm('Are you sure you want to delete this user? This is an irreversible action.'))) {
      return;
    }

    setError(null);
    const response = await UsersApi.deleteUser(userId).raw;
    if (!response.ok) {
      await handleError(response);
    }

    triggerDataRefresh();
  };

  if (!provider) {
    return <LoadingMessage />;
  }

  if (!filterProps.selectedFilter) {
    filterProps.setSelectedFilter(getDefaultSelectedFilterInfo(filters[0]));
  }

  const currentUser = provider.users.find((u) => u.userName === auth.user?.userName);
  const selectableUserIds = filteredData.map((d) => d.id).filter((id) => id !== currentUser?.id);

  if (selectedUsers.some((id) => !selectableUserIds.includes(id))) {
    setSelectedUsers((prevSelectedUsers) => prevSelectedUsers.filter((id) => selectableUserIds.includes(id)));
  }

  const canEditUsers = auth.hasRole(ProviderRole.EditUsers);

  const userTableColumns = getUserTableColumns(
    currentUser?.id,
    selectedUsers,
    setSelectedUsers,
    () => {
      setSelectedUsers(selectableUserIds);
    },
    deleteUser,
    canEditUsers || false,
    auth.user?.userName,
  );

  return (
    <Stack gap={5} className="providerUserManagementContainer">
      <PageHeader
        heading={`${canEditUsers ? 'User management' : 'Users'}`}
        subheading={`${providerDisplayName(provider.name, provider.providerNumber)}`}
        crumbsType={RootPathType.ProviderUsers}
      />
      {canEditUsers && (
        <LinkButton to={providerPages.AddUser.path} className="mb-3">
          Add User
        </LinkButton>
      )}
      {error && (
        <Alert variant="danger" className="w-75">
          {error}
        </Alert>
      )}
      <div>
        <FiltersComponent {...filterProps} heading="All Users" />
        {canEditUsers && (
          <div className="d-flex">
            <Form.Select
              as="select"
              aria-label="Bulk actions"
              className="bulkActionSelect me-2"
              value={selectedBulkAction ?? ''}
              onChange={(e) => setSelectedBulkAction(e.target.value as BulkAction)}
            >
              <option value="" hidden>
                Bulk actions
              </option>
              <option value={BulkAction.Activate}>Activate</option>
              <option value={BulkAction.Disable}>Disable</option>
              <option value={BulkAction.Delete} disabled={selectedUsers.length === provider.users.length}>
                Delete
              </option>
            </Form.Select>
            <PrimaryButton
              aria-label="Submit bulk action"
              disabled={
                isSubmitting ||
                selectedUsers.length === 0 ||
                (selectedBulkAction === BulkAction.Delete && selectedUsers.length === provider.users.length)
              }
              onClick={triggerBulkAction}
            >
              <ArrowRight />
            </PrimaryButton>
          </div>
        )}
      </div>
      <div className={classNames({ editUsersTable: canEditUsers })}>
        <Table
          data={filteredData}
          columns={userTableColumns}
          paginated
          defaultSort={[{ id: 'name', desc: false }]}
          message="No users match the search criteria."
          exportable
          exportFileName="Users.csv"
          tableRowToExportRow={tableRowToExportRow}
          rowHeadingIndex={1}
        />
      </div>
    </Stack>
  );
};

export default ProviderUserManagementPage;
