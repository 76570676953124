import { FaqRequest } from 'nrosh-common/Api/FaqsApi';
import { Stack } from 'react-bootstrap';
import { generatePath } from 'react-router-dom';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { FaqsApi } from '@/Helpers/Apis';
import FaqTabContainer from '@/Pages/Content/ManageFaqs/FaqTabContainer';
import { adminPages } from '@/Pages/Home/SitePages';

const CreateFaqPage = (): JSX.Element => {
  const sendFaqRequest = async (
    faqRequest: FaqRequest,
  ): Promise<{
    success: boolean;
    redirectPath?: string;
    errorMessage?: string;
  }> => {
    const response = await FaqsApi.createFaq(faqRequest).raw;

    if (response.ok) {
      const { id } = response.value;
      const redirectPath = generatePath(adminPages.AdminFaqsEdit.path, { faqId: id.toString() });
      return { success: true, redirectPath };
    }
    const errorMessage = response.value.message;
    return { success: false, errorMessage };
  };

  return (
    <Stack gap={5}>
      <PageHeader
        heading="Add FAQ"
        crumbsType={RootPathType.AdminContent}
        crumbs={[
          {
            name: 'Manage FAQs',
            path: adminPages.AdminFaqs.path,
          },
          { name: 'Add FAQ', path: adminPages.AdminFaqsCreate.path },
        ]}
      />
      <FaqTabContainer sendFaqRequest={sendFaqRequest} />
    </Stack>
  );
};
export default CreateFaqPage;
