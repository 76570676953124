import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { FormEvent, useState } from 'react';
import { Form } from 'react-bootstrap';
import { PrimaryButton, TertiaryButton } from '@/Components/Buttons/DCSButton';
import { AccessibleFeedback } from '@/Components/Form/AccessibleFeedback';
import { useValidationModel } from '@/Components/Validations/ValidationAccordionItem';
import { useValidationsAccordionContext } from '@/Components/Validations/Validations';
import { getValidityProps } from '@/Helpers/Forms';

type ValidationCommentFormProps = {
  comment: string | undefined;
  setComment: ReactStateSetter<string | undefined>;
  editingExistingComment: boolean;
  closeEditor: () => void;
};

const ValidationCommentForm = ({
  comment,
  setComment,
  editingExistingComment,
  closeEditor,
}: ValidationCommentFormProps): JSX.Element | null => {
  const { validationId, comment: commentOnServer } = useValidationModel();
  const { commentsAreReadonly, updateComment } = useValidationsAccordionContext();

  const [validated, setValidated] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  if (commentsAreReadonly) {
    return null;
  }

  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    const form = event.currentTarget;
    const updatedComment = comment?.trim();
    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      event.preventDefault();
      if (updatedComment != null && updatedComment !== '') {
        updateComment(validationId, updatedComment);
        setComment(updatedComment);
      }
      setIsDirty(false);
      setValidated(false);
      closeEditor();
    }
  };

  const cancelEditComment = (): void => {
    closeEditor();
    setComment(commentOnServer);
  };

  return (
    <Form noValidate onSubmit={onSubmit}>
      <div className="comment">
        <Form.Control
          as="textarea"
          aria-label="Validation Comment"
          value={comment ?? ''}
          required
          onChange={(e) => {
            e.preventDefault();
            setComment(e.target.value);
            setIsDirty(true);
          }}
          {...getValidityProps(validated && !comment, `invalid-comment-feedback-${validationId}`)}
        />
      </div>
      <AccessibleFeedback displayFeedback={validated && !comment} id={`invalid-comment-feedback-${validationId}`}>
        Please enter a comment
      </AccessibleFeedback>
      <div className="buttonContainer gap-1">
        <PrimaryButton
          size="sm"
          type="submit"
          className="validationButton"
          disabled={!isDirty || comment?.trim() === ''}
        >
          {editingExistingComment ? 'Save' : 'Add'}
        </PrimaryButton>
        {editingExistingComment && (
          <TertiaryButton size="sm" colour="primary" onClick={() => cancelEditComment()}>
            Cancel
          </TertiaryButton>
        )}
      </div>
    </Form>
  );
};

export default ValidationCommentForm;
