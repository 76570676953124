import {
  BulkImportWarnings,
  DuplicatedDataPointDetails,
  InvalidDataPointDetails,
  InvalidDimensionalMemberDetails,
} from 'nrosh-common/Api/SubmissionsApi';
import { joinWithSpecialLastJoin } from 'nrosh-common/Helpers/StringHelpers';
import { Alert } from 'react-bootstrap';

export const bulkImportHasErrors = (bulkImportResponse: BulkImportWarnings): number | undefined =>
  bulkImportResponse.unrecognisedDataPointIds?.length ||
  bulkImportResponse.ignoredCalculatedDataPointIds?.length ||
  bulkImportResponse.duplicatedDataPoints?.length ||
  bulkImportResponse.invalidDimensionalMembers?.length ||
  bulkImportResponse.updatesWithDimensionMissing?.length ||
  bulkImportResponse.nonDimensionalDataPointsWithDimensions?.length ||
  bulkImportResponse.dataPointsWithExtraDimensionsSpecified?.length;

type DataPointsWarningProps = {
  dataPointIds: string[];
};

type DuplicatedDataPointsWarningProps = {
  dataPoints: DuplicatedDataPointDetails[];
};

type InvalidDimensionWarningProps = {
  errors: InvalidDimensionalMemberDetails[];
};

type InvalidDataPointWarningProps = {
  dataPoints: InvalidDataPointDetails[];
};

const UnrecognisedDataPointsWarning = (props: DataPointsWarningProps): JSX.Element => {
  const { dataPointIds } = props;
  const uploadHasRowsWithoutDataPoint = dataPointIds.some((id) => !id);
  const unrecognisedDataPointIds = dataPointIds.filter(Boolean);

  const unrecognisedDataPointsWarningText =
    unrecognisedDataPointIds.length === 1
      ? `There is no data point with ID ${unrecognisedDataPointIds[0]} referenced by this survey. The value for this data point ID has been ignored.`
      : `There are no data points with IDs ${joinWithSpecialLastJoin(
          unrecognisedDataPointIds,
          ', ',
          ' or ',
        )} referenced by this survey. The values for these data points have been ignored.`;

  return (
    <>
      {uploadHasRowsWithoutDataPoint && (
        <li className="list-group-item">
          Some values uploaded do not have a data point ID, these values have been ignored
        </li>
      )}
      {unrecognisedDataPointIds.length > 0 && <li className="list-group-item">{unrecognisedDataPointsWarningText}</li>}
    </>
  );
};

const DuplicateDataPointsWarning = (props: DuplicatedDataPointsWarningProps): JSX.Element => {
  const { dataPoints } = props;

  return (
    <li className="list-group-item">
      Multiple values were specified for the data points with the following IDs:
      <ul className="mb-0">
        {dataPoints.map((dp) => (
          <li key={`${dp.dataPointId}_${dp.dimension1}_${dp.dimension2}`}>
            {dp.dataPointId}
            {dp.dimension1 || dp.dimension2 ? ` (${[dp.dimension1, dp.dimension2].filter(Boolean).join(', ')})` : ''}:
            specified {dp.numberOfValues} times - value &quot;{dp.usedValue}&quot; was used
          </li>
        ))}
      </ul>
    </li>
  );
};

const IgnoredDataPointsWarning = (props: DataPointsWarningProps): JSX.Element => {
  const { dataPointIds } = props;
  const warningText =
    dataPointIds.length === 1
      ? `The data point with ID ${dataPointIds[0]} is defined by a calculation. The value for this data point ID has been ignored.`
      : `The data points with IDs ${joinWithSpecialLastJoin(
          dataPointIds,
          ', ',
          ' and ',
        )} are defined by calculations. The values for these data points have been ignored.`;

  return <li className="list-group-item">{warningText}</li>;
};

const InvalidDimensionalMembers = (props: InvalidDimensionWarningProps): JSX.Element => {
  const { errors } = props;

  return (
    <li className="list-group-item">
      An invalid dimensional member has been used. The values for these data points with these dimension values have
      been ignored:
      <ul className="mb-0">
        {errors.map((error) => (
          <li key={`${error.dataPointIds.join('_')}_${error.value}`}>
            <div>Data Points: {error.dataPointIds.join(', ')}</div>
            <div>Dimension Value: {error.value}</div>
            <div>Number of affected values: {error.numberOfValues}</div>
          </li>
        ))}
      </ul>
    </li>
  );
};

const UpdatesWithDimensionsMissing = (props: InvalidDimensionWarningProps): JSX.Element => {
  const { errors } = props;

  return (
    <li className="list-group-item">
      Values which are missing dimensions have been ignored for these data points:
      <ul className="mb-0">
        {errors.map((error) => (
          <li key={`${error.dataPointIds.join('_')}_${error.dimensionId}`}>
            <div>Data Points: {error.dataPointIds.join(', ')}</div>
            <div>Missing dimension: {error.dimensionId}</div>
            <div>Number of affected values: {error.numberOfValues}</div>
          </li>
        ))}
      </ul>
    </li>
  );
};

const NonDimensionalWithDimensionsWarning = (props: DataPointsWarningProps): JSX.Element => {
  const { dataPointIds } = props;
  const warningText =
    dataPointIds.length === 1
      ? `Data point ${dataPointIds[0]} is non-dimensional but dimensions were provided. The value has been uploaded but the dimensions have been ignored.`
      : `Data points ${joinWithSpecialLastJoin(
          dataPointIds,
          ', ',
          ' and ',
        )} are non-dimensional but dimensions were provided. The values have been uploaded but their dimensions have been ignored.`;

  return <li className="list-group-item">{warningText}</li>;
};

const DataPointsWithExtraDimensionSpecified = (props: InvalidDataPointWarningProps): JSX.Element => {
  const { dataPoints } = props;

  return (
    <li className="list-group-item">
      Values with two dimensions specified have been ignored for these one-dimensional data points:
      <ul className="mb-0">
        {dataPoints.map((dataPoint) => (
          <li key={dataPoint.dataPointId}>
            <div>Data Point: {dataPoint.dataPointId}</div>
            <div>Number of affected values: {dataPoint.numberOfValues}</div>
          </li>
        ))}
      </ul>
    </li>
  );
};

type BulkUploadWarningsProps = {
  bulkImportResponse: BulkImportWarnings;
};

export const BulkUploadWarnings = ({ bulkImportResponse }: BulkUploadWarningsProps): JSX.Element => {
  const {
    unrecognisedDataPointIds,
    ignoredCalculatedDataPointIds,
    duplicatedDataPoints,
    invalidDimensionalMembers,
    updatesWithDimensionMissing,
    nonDimensionalDataPointsWithDimensions,
    dataPointsWithExtraDimensionsSpecified,
  } = bulkImportResponse;

  return (
    <>
      <Alert variant="success">
        The upload was successful, but some data could not be imported because it is incorrect. Please review the list
        below and, if necessary, make corrections and then upload the file again.
      </Alert>
      <ul className="list-group">
        {unrecognisedDataPointIds && unrecognisedDataPointIds.length > 0 && (
          <UnrecognisedDataPointsWarning dataPointIds={unrecognisedDataPointIds} />
        )}
        {ignoredCalculatedDataPointIds && ignoredCalculatedDataPointIds.length > 0 && (
          <IgnoredDataPointsWarning dataPointIds={ignoredCalculatedDataPointIds} />
        )}
        {duplicatedDataPoints && duplicatedDataPoints.length > 0 && (
          <DuplicateDataPointsWarning dataPoints={duplicatedDataPoints} />
        )}
        {invalidDimensionalMembers && invalidDimensionalMembers.length > 0 && (
          <InvalidDimensionalMembers errors={invalidDimensionalMembers} />
        )}

        {updatesWithDimensionMissing && updatesWithDimensionMissing.length > 0 && (
          <UpdatesWithDimensionsMissing errors={updatesWithDimensionMissing} />
        )}

        {nonDimensionalDataPointsWithDimensions && nonDimensionalDataPointsWithDimensions.length > 0 && (
          <NonDimensionalWithDimensionsWarning dataPointIds={nonDimensionalDataPointsWithDimensions} />
        )}

        {dataPointsWithExtraDimensionsSpecified && dataPointsWithExtraDimensionsSpecified.length > 0 && (
          <DataPointsWithExtraDimensionSpecified dataPoints={dataPointsWithExtraDimensionsSpecified} />
        )}
      </ul>
    </>
  );
};
