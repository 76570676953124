import { ProviderSummary } from 'nrosh-common/Api/ProvidersApi';
import { User, UserProfileType } from 'nrosh-common/Api/UsersApi';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import useEndpoint, { useEndpointConditionally } from 'nrosh-common/Hooks/useEndpoint';
import { useContext, useReducer } from 'react';
import { Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { LoadingSpinner } from '@/Components/Loading/LoadingSpinner';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { MyProviderApi, ProvidersApi, UsersApi } from '@/Helpers/Apis';
import { providerDisplayName } from '@/Helpers/ProviderHelper';
import { adminPages, providerPages } from '@/Pages/Home/SitePages';
import { EditUser } from '@/Pages/Users/EditUser';
import UserForm from '@/Pages/Users/UserForm';

type AddUserPageProps = {
  isAdmin: boolean;
};

export const AddUserPage = ({ isAdmin }: AddUserPageProps): JSX.Element => {
  const auth = useContext(AuthContext);
  const { providerId } = useParams();

  const [providerDetails] = useEndpointConditionally<ProviderSummary>(ProvidersApi.getProvider, isAdmin, providerId);
  const [myProviderDetails] = useEndpointConditionally<ProviderSummary>(
    MyProviderApi.getProvider,
    !isAdmin,
    providerId,
  );

  if ((isAdmin && !providerDetails) || (!isAdmin && !myProviderDetails)) {
    return <LoadingSpinner />;
  }

  return (
    <Stack gap={5}>
      <PageHeader
        heading="Add user"
        subheading={
          !isAdmin ? `${providerDisplayName(myProviderDetails!.name, myProviderDetails!.providerNumber)}` : ''
        }
        crumbsType={isAdmin ? RootPathType.AdminUsers : RootPathType.ProviderUsers}
        crumbs={
          isAdmin
            ? [
                {
                  name: 'Add user',
                  path: adminPages.AdminAddUser.path,
                },
              ]
            : [
                {
                  name: 'Add user',
                  path: providerPages.AddUser.path,
                },
              ]
        }
      />
      <UserForm
        providerId={isAdmin ? parseInt(providerId!, 10) : auth.user?.providerId}
        isAdmin={isAdmin}
        userProfileType={UserProfileType.ProviderProfile}
        providerDetails={providerDetails ?? myProviderDetails}
      />
    </Stack>
  );
};

export const AddDcsUserPage = ({ isAdmin }: AddUserPageProps): JSX.Element => (
  <Stack gap={5}>
    <PageHeader
      heading="Add DCS user"
      crumbsType={isAdmin ? RootPathType.AdminUsers : RootPathType.ProviderUsers}
      crumbs={
        isAdmin
          ? [
              {
                name: 'Add DCS user',
                path: adminPages.AdminAddDcsUser.path,
              },
            ]
          : [
              {
                name: 'Add user',
                path: providerPages.AddUser.path,
              },
            ]
      }
    />
    <UserForm isAdmin={isAdmin} userProfileType={UserProfileType.DcsProfile} />
  </Stack>
);

type EditUserPageProps = {
  isAdmin: boolean;
};

export const EditUserPage = ({ isAdmin }: EditUserPageProps): JSX.Element => {
  const { userId } = useParams();

  const [lastRefreshTime, triggerDataRefresh] = useReducer(() => Date.now(), Date.now());
  const [user] = useEndpoint<User>(UsersApi.getUser, userId, lastRefreshTime);

  const [providerDetails] = useEndpointConditionally<ProviderSummary>(
    ProvidersApi.getProvider,
    isAdmin && Boolean(user?.providerId),
    user?.providerId,
  );

  const [myProviderDetails] = useEndpointConditionally<ProviderSummary>(
    MyProviderApi.getProvider,
    !isAdmin,
    user?.providerId,
  );

  if (!user || (isAdmin && Boolean(user?.providerId) && !providerDetails) || (!isAdmin && !myProviderDetails)) {
    return <LoadingSpinner />;
  }

  return (
    <Stack gap={5}>
      <PageHeader
        heading="Edit user"
        subheading={
          !isAdmin ? `${providerDisplayName(myProviderDetails!.name, myProviderDetails!.providerNumber)}` : ''
        }
        crumbsType={isAdmin ? RootPathType.AdminUsers : RootPathType.ProviderUsers}
        crumbs={
          isAdmin
            ? [
                {
                  name: 'Edit user',
                  path: adminPages.AdminEditUser.path,
                },
              ]
            : [
                {
                  name: 'Edit user',
                  path: providerPages.EditUser.path,
                },
              ]
        }
      />
      <EditUser
        isAdmin={isAdmin}
        providerDetails={providerDetails ?? myProviderDetails}
        user={user}
        triggerDataRefresh={triggerDataRefresh}
      />
    </Stack>
  );
};
