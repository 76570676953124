import { ReactNode } from 'react';
import '@/Components/Badges/Badge.scss';

export enum BadgeStyles {
  Circle,
  Square,
  Rounded,
}

export enum BadgeSizes {
  Small,
  Large,
}

export enum BadgeColours {
  Red,
  Blue,
  Orange,
}

const badgeStyleClassNames = {
  [BadgeStyles.Circle]: 'badgeCircle',
  [BadgeStyles.Rounded]: 'badgeRounded',
  [BadgeStyles.Square]: 'badgeSquare',
};

const badgeSizeClassNames = {
  [BadgeSizes.Small]: 'badgeSmall',
  [BadgeSizes.Large]: 'badgeLarge',
};

const badgeColoursClassNames = {
  [BadgeColours.Red]: 'badgeRed',
  [BadgeColours.Blue]: 'badgeBlue',
  [BadgeColours.Orange]: 'badgeOrange',
};

type BadgeProps = {
  style: BadgeStyles;
  size: BadgeSizes;
  colour: BadgeColours;
  children?: ReactNode;
  ariaLabel: string;
};

type ValidationBadgeProps = {
  children?: ReactNode;
  ariaLabel: string;
};

const Badge = ({ style, size, colour, children, ariaLabel }: BadgeProps): JSX.Element => {
  const styleClass = badgeStyleClassNames[style];
  const sizeClass = badgeSizeClassNames[size];
  const colourClass = badgeColoursClassNames[colour];
  return (
    <span className={`nroshBadge ${styleClass} ${sizeClass} ${colourClass}`} role="img" aria-label={ariaLabel}>
      {children}
    </span>
  );
};

export const HardValidationBadge = ({ children, ariaLabel }: ValidationBadgeProps): JSX.Element => (
  <Badge style={BadgeStyles.Rounded} size={BadgeSizes.Large} colour={BadgeColours.Red} ariaLabel={ariaLabel}>
    {children}
  </Badge>
);

export const SoftValidationBadge = ({ children, ariaLabel }: ValidationBadgeProps): JSX.Element => (
  <Badge style={BadgeStyles.Square} size={BadgeSizes.Large} colour={BadgeColours.Blue} ariaLabel={ariaLabel}>
    {children}
  </Badge>
);

export default Badge;
