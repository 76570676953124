import { ProviderSummary } from 'nrosh-common/Api/ProvidersApi';
import { User } from 'nrosh-common/Api/UsersApi';
import useEndpoint, { useEndpointConditionally } from 'nrosh-common/Hooks/useEndpoint';
import { useReducer } from 'react';
import { Stack } from 'react-bootstrap';
import { generatePath, useParams } from 'react-router-dom';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { LoadingSpinner } from '@/Components/Loading/LoadingSpinner';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { ProvidersApi, UsersApi } from '@/Helpers/Apis';
import { adminPages } from '@/Pages/Home/SitePages';
import { EditUser } from '@/Pages/Users/EditUser';

export const AdminProviderEditUserPage = (): JSX.Element => {
  const { userId } = useParams();

  const [lastRefreshTime, triggerDataRefresh] = useReducer(() => Date.now(), Date.now());
  const [user] = useEndpoint<User>(UsersApi.getUser, userId, lastRefreshTime);

  const [providerDetails] = useEndpointConditionally<ProviderSummary>(
    ProvidersApi.getProvider,
    !!user,
    user?.providerId,
  );

  if (!user || !providerDetails) {
    return <LoadingSpinner />;
  }

  return (
    <Stack gap={5}>
      <PageHeader
        heading="Edit user"
        crumbsType={RootPathType.AdminProviders}
        crumbs={[
          {
            name: 'Organisational information',
            path: generatePath(adminPages.AdminViewProvider.path, { providerId: providerDetails.id.toString() }),
          },
          {
            name: providerDetails.name,
            path: generatePath(adminPages.AdminProviderUsers.path, { providerId: providerDetails.id.toString() }),
          },
          {
            name: 'Edit user',
            path: adminPages.AdminEditUser.path,
          },
        ]}
      />
      <EditUser
        isAdmin
        providerDetails={providerDetails}
        user={user}
        triggerDataRefresh={triggerDataRefresh}
        cancelPath
      />
    </Stack>
  );
};
