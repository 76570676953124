import { RshRole } from 'nrosh-common/Api/Enums';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import { useContext } from 'react';
import { Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import SubmissionPageHeader from '@/Components/PageHeader/SubmissionPageHeader';
import { SubmissionsApi } from '@/Helpers/Apis';
import { providerDisplayName } from '@/Helpers/ProviderHelper';
import { surveyDisplayName } from '@/Helpers/SurveyHelper';
import useSubmissionDetail from '@/Hooks/useSubmissionDetail';
import { providerPages, submissionPages } from '@/Pages/Home/SitePages';
import SupportingDocumentForm from '@/Pages/Submissions/Documents/SupportingDocumentForm';

const UploadSupportingDocumentPage = (): JSX.Element => {
  const { submissionId } = useParams();
  const [submission] = useSubmissionDetail(submissionId!);
  const auth = useContext(AuthContext);

  if (!submission) {
    return <LoadingMessage />;
  }

  const pageTitle = 'Upload supporting documents';
  const isAdmin = auth.hasRole(RshRole.User)!;
  const currentPath = isAdmin
    ? submissionPages.UploadSupportingDocument.path
    : providerPages.UploadSupportingDocument.path;

  return (
    <Stack gap={5}>
      <SubmissionPageHeader
        heading={`${pageTitle}`}
        subheading={providerDisplayName(submission.provider.name, submission.provider.providerNumber)}
        isRshUser={isAdmin}
        surveyDisplayName={surveyDisplayName(submission.surveyName, submission.timePeriodName)}
        providerName={submission.provider.name}
        additionalRshCrumbs={[{ name: pageTitle, path: currentPath }]}
        additionalProviderCrumbs={[{ name: pageTitle, path: currentPath }]}
      />
      <SupportingDocumentForm
        isEdit={false}
        submitAction={(formData) => SubmissionsApi.uploadSupportingDocument(submissionId!, formData).raw}
      />
    </Stack>
  );
};

export default UploadSupportingDocumentPage;
