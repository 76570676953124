import { Outlet } from 'react-router-dom';
import PrivacyNoticeFooter from '@/Components/Footer/PrivacyNoticeFooter';
import dcsLogo from '@/dcs-logo-white.png';
import '@/Pages/Home/LoginLayout.scss';

const LoginLayout = (): JSX.Element => (
  <div className="loginLayoutContainer d-flex flex-column align-items-center justify-content-center">
    <nav className="mb-4">
      <a href="/">
        <img src={dcsLogo as string} className="d-inline-block align-top nrosh-logo" alt="DCS" />
      </a>
    </nav>
    <main className="p-4 m-0">
      <Outlet />
    </main>
    <footer className="mt-4">
      <PrivacyNoticeFooter />
    </footer>
  </div>
);

export default LoginLayout;
