import { EmailTemplateType } from 'nrosh-common/Api/EmailsApi';
import { FormEvent, useMemo, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import LinkButton from '@/Components/Links/LinkButton';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { EmailsApi, placeHolderAPIErrorHandler } from '@/Helpers/Apis';
import { checkHtmlNonEmpty } from '@/Helpers/HtmlValidityHelper';
import useUnsavedChangesWarning from '@/Hooks/useUnsavedChangesWarning';
import { ComposeForm } from '@/Pages/Emails/ComposeFormComponents';
import {
  InvalidTemplatingFeedback,
  availableTemplateFieldGroups,
  getInvalidSubjectTemplateFields,
  getMissingTemplateFields,
  getUnrecognisedTemplateFields,
  getUsedTemplateFields,
  globalTemplateFieldGroups,
  requiredTemplateFields,
} from '@/Pages/Emails/EmailTemplates';
import { adminPages } from '@/Pages/Home/SitePages';

type UpdateEmailTemplateFormProps = {
  initialSubject: string;
  initialBody: string;
  templateType: EmailTemplateType;
};

export const UpdateEmailTemplateForm = ({
  initialSubject,
  initialBody,
  templateType,
}: UpdateEmailTemplateFormProps): JSX.Element => {
  const [validated, setValidated] = useState<boolean>(false);
  const [subject, setSubject] = useState<string>(initialSubject);
  const [body, setBody] = useState<string>(initialBody);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [setDirty, setPristine] = useUnsavedChangesWarning();

  const navigate = useNavigate();

  const templateFieldGroups = useRef(globalTemplateFieldGroups.concat(availableTemplateFieldGroups[templateType]));

  const subjectTemplateFields = useMemo(() => getUsedTemplateFields(subject), [subject]);

  const invalidSubjectTemplateFields = useMemo(
    () => getInvalidSubjectTemplateFields(subjectTemplateFields),
    [subjectTemplateFields],
  );

  const usedTemplateFields = subjectTemplateFields.concat(getUsedTemplateFields(body));

  const missingTemplateFields = getMissingTemplateFields(usedTemplateFields, templateType);

  const unrecognisedTemplateFields = getUnrecognisedTemplateFields(usedTemplateFields, templateType);

  const isSubjectValid = subject !== '';
  const isBodyValid = checkHtmlNonEmpty(body);

  const checkFormValid = (): boolean =>
    isSubjectValid &&
    isBodyValid &&
    missingTemplateFields.length === 0 &&
    unrecognisedTemplateFields.length === 0 &&
    invalidSubjectTemplateFields.length === 0;

  const updateSubject = (newSubject: string): void => {
    setDirty();
    setSubject(newSubject);
  };

  const updateBody = (newBody: string): void => {
    setDirty();
    setBody(newBody);
  };

  const updateTemplate = async (): Promise<void> => {
    setIsSaving(true);
    setPristine();
    await EmailsApi.patchEmailTemplate(templateType, {
      templateText: body,
      templateSubject: subject,
    }).justErrors(placeHolderAPIErrorHandler);
    setIsSaving(false);
    return navigate(adminPages.AdminAutomatedEmails.path);
  };

  const submitForm = (e: FormEvent<HTMLFormElement> | undefined = undefined): void => {
    e?.preventDefault();
    if (checkFormValid()) {
      setValidated(false);
      updateTemplate().catch(() => {});
    } else {
      e?.stopPropagation();
      setValidated(true);
    }
  };

  const areTemplateFieldsInvalid =
    missingTemplateFields.length !== 0 ||
    unrecognisedTemplateFields.length !== 0 ||
    invalidSubjectTemplateFields.length !== 0;

  const saveButtonText = 'Save and Return';
  return (
    <Form noValidate onSubmit={submitForm} className="w-75">
      <ComposeForm.Subject
        subject={subject}
        setSubject={updateSubject}
        isInvalid={validated && (!isSubjectValid || areTemplateFieldsInvalid)}
        hideInvalidMessage={isSubjectValid}
        templateFieldGroups={templateFieldGroups.current}
        requiredTemplateFields={requiredTemplateFields[templateType]}
        describedBy={validated && areTemplateFieldsInvalid ? 'invalidTemplatingFeedback' : undefined}
      />
      <ComposeForm.Message
        htmlBody={body}
        updateBody={updateBody}
        isInvalid={
          validated && (!isBodyValid || missingTemplateFields.length !== 0 || unrecognisedTemplateFields.length !== 0)
        }
        hideInvalidMessage={isBodyValid}
        templateFieldGroups={templateFieldGroups.current}
        requiredTemplateFields={requiredTemplateFields[templateType]}
        describedBy={validated && areTemplateFieldsInvalid ? 'invalidTemplatingFeedback' : undefined}
      />
      <InvalidTemplatingFeedback
        id="invalidTemplatingFeedback"
        validated={validated}
        missingTemplateFields={missingTemplateFields}
        invalidSubjectTemplateFieldsFeedback={invalidSubjectTemplateFields}
        unrecognisedTemplateFields={unrecognisedTemplateFields}
      />
      <div className="d-flex gap-2">
        <LinkButton to={adminPages.AdminAutomatedEmails.path} variant="outline-primary" disabled={isSaving}>
          Cancel
        </LinkButton>
        {!isSaving ? (
          <PrimaryButton className="me-3" type="submit">
            {saveButtonText}
          </PrimaryButton>
        ) : (
          <LoadingButton message={saveButtonText} />
        )}
      </div>
    </Form>
  );
};
