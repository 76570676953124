import ApiClient, { APIHelpers, APIResult, handleAPIRequest } from './ApiClient';
import { Role } from './Enums';

type LoginRequest = { userName: string; password: string };

export type User = {
  userName: string;
  displayName: string;
  roles: Role[];
  providerId?: number;
  providerNumber?: string;
  providerName?: string;
  surveys: number[];
};

export type CurrentUser = {
  isLoggedIn: boolean;
  user: User | null;
};

export type AccountsResponse = {
  successful: boolean;
  error?: string | null;
};

export type LoginResponse = AccountsResponse & {
  user: User | null;
};

type VerifyTokenRequest = {
  username: string;
  token: string;
};

type ForgotPasswordRequest = {
  username: string;
  email: string;
};

type ResetPasswordRequest = {
  username: string;
  token: string;
  password: string;
};

type ActivateAccountRequest = ResetPasswordRequest;

type ChangePasswordRequest = {
  currentPassword: string;
  newPassword: string;
};

export type PersonalDetails = {
  userId: string;
  userName: string;
  name: string;
  emailAddress: string;
  preferredFormOfAddress?: string;
};

export type UpdatePersonalDetailsRequest = {
  name: string;
  emailAddress: string;
  preferredFormOfAddress?: string;
};

const AccountApiRoot = 'Accounts';
const AccountApiPaths = {
  LOGIN: `${AccountApiRoot}/Login`,
  LOGOUT: `${AccountApiRoot}/Logout`,
  CURRENT_USER: `${AccountApiRoot}/CurrentUser`,
  CHANGE_PASSWORD: `${AccountApiRoot}/ChangePassword`,
  SEND_FORGOT_PASSWORD_EMAIL: `${AccountApiRoot}/SendForgotPasswordEmail`,
  RESET_PASSWORD: `${AccountApiRoot}/ResetPassword`,
  VERIFY_PASSWORD_RESET_TOKEN: `${AccountApiRoot}/VerifyPasswordResetToken`,
  ACTIVATE_ACCOUNT: `${AccountApiRoot}/ActivateAccount`,
  VERIFY_EMAIL_CONFIRMATION_TOKEN: `${AccountApiRoot}/VerifyEmailValidationToken`,
  PERSONAL_DETAILS: `${AccountApiRoot}/PersonalDetails`,
};

const accountApi = (apiClient: ApiClient) => ({
  login: (body: LoginRequest) => {
    return handleAPIRequest(
      apiClient.post(AccountApiPaths.LOGIN, body),
      APIHelpers.json<LoginResponse>,
      APIHelpers.standardError<LoginResponse>,
    );
  },
  logout: () => {
    return handleAPIRequest(apiClient.post(AccountApiPaths.LOGOUT, null), APIHelpers.none, APIHelpers.standardError);
  },
  getCurrentUser: () => {
    return handleAPIRequest(
      apiClient.get(AccountApiPaths.CURRENT_USER),
      APIHelpers.json<CurrentUser>,
      APIHelpers.standardError,
    );
  },
  changePassword: (request: ChangePasswordRequest) => {
    return handleAPIRequest(
      apiClient.post(AccountApiPaths.CHANGE_PASSWORD, request),
      APIHelpers.json<AccountsResponse>,
      APIHelpers.standardError<AccountsResponse>,
    );
  },
  sendForgotPasswordEmail: (request: ForgotPasswordRequest) => {
    return handleAPIRequest(
      apiClient.post(AccountApiPaths.SEND_FORGOT_PASSWORD_EMAIL, request),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },
  resetPassword: (request: ResetPasswordRequest) => {
    return handleAPIRequest(
      apiClient.post(AccountApiPaths.RESET_PASSWORD, request),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },
  verifyPasswordResetToken: (request: VerifyTokenRequest) => {
    return handleAPIRequest(
      apiClient.post(AccountApiPaths.VERIFY_PASSWORD_RESET_TOKEN, request),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },
  activateAccount: (request: ActivateAccountRequest) => {
    return handleAPIRequest(
      apiClient.post(AccountApiPaths.ACTIVATE_ACCOUNT, request),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },
  verifyEmailConfirmationToken: (request: VerifyTokenRequest) => {
    return handleAPIRequest(
      apiClient.post(AccountApiPaths.VERIFY_EMAIL_CONFIRMATION_TOKEN, request),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },
  getPersonalDetails: () => {
    return handleAPIRequest(
      apiClient.get(AccountApiPaths.PERSONAL_DETAILS),
      APIHelpers.json<PersonalDetails>,
      APIHelpers.standardError,
    );
  },
  updatePersonalDetails: (request: UpdatePersonalDetailsRequest) => {
    return handleAPIRequest(
      apiClient.patch(AccountApiPaths.PERSONAL_DETAILS, request),
      APIHelpers.json<APIResult>,
      APIHelpers.standardError<APIResult>,
    );
  },
});

export default accountApi;
