import { PageTitle } from 'nrosh-common/Api/Enums';
import { ContentChunk } from 'nrosh-common/Api/PublicPagesApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { Col, Container, Row } from 'react-bootstrap';
import { generatePath } from 'react-router-dom';
import ContentDisplay from '@/Components/ContentDisplay/ContentDisplay';
import LinkButton from '@/Components/Links/LinkButton';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import { PublicPagesApi } from '@/Helpers/Apis';
import AccessReports from '@/Pages/Home/HomePageImages/AccessReports';
import ManageProfiles from '@/Pages/Home/HomePageImages/ManageProfiles';
import SubmitSurvey from '@/Pages/Home/HomePageImages/SubmitSurvey';
import dataCollectionServiceIcon from '@/Pages/Home/HomePageImages/data-collection-service-icon.svg';
import { publicPages } from '@/Pages/Home/SitePages';
import '@/Pages/Home/HomePage.scss';

const HomePage = (): JSX.Element => {
  const [contentChunk] = useEndpoint<ContentChunk>(PublicPagesApi.getContentChunk, PageTitle.Home);

  if (!contentChunk) {
    return <LoadingMessage />;
  }

  return (
    <>
      <div className="header">
        <Container>
          <Row className="mx-6 py-4">
            <Col className="d-flex flex-column">
              <div className="pe-4">
                <ContentDisplay htmlContent={contentChunk.content} />
                <LinkButton to={generatePath(publicPages.PublicAbout.path)} className="pt-3">
                  Find out more about DCS
                </LinkButton>
              </div>
            </Col>
            <Col className="d-flex justify-content-center">
              <div className="d-flex align-items-end ps-4">
                <img src={dataCollectionServiceIcon as string} alt="" width="362" height="355" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="homePageContainer d-flex flex-column align-items-center m-auto">
        <Row className="d-flex justify-content-center pt-6 m-auto contentRow">
          <div className="d-flex flex-column align-items-center">
            <h1 className="fw-bold my-2">Features</h1>
            <p>By logging in you&#39;ll have access to the following tools</p>
          </div>
          <Col>
            <div className="d-flex flex-column align-items-center">
              <h2 className="my-4">Submit Surveys</h2>
              <SubmitSurvey />
            </div>
          </Col>
          <Col>
            <div className="d-flex flex-column align-items-center">
              <h2 className="my-4">Upload Documents</h2>
              <AccessReports />
            </div>
          </Col>
          <Col>
            <div className="d-flex flex-column align-items-center">
              <h2 className="my-4">Manage Profiles</h2>
              <ManageProfiles />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default HomePage;
