import { Alert } from 'react-bootstrap';
import { AiOutlineExclamationCircle } from 'react-icons/ai';

const SubmissionsPageAlert = (props: { message: string; className: string }): JSX.Element => {
  const { message, className } = props;
  return (
    <Alert className={className}>
      <AiOutlineExclamationCircle className="me-1" size="1.5rem" /> {message}
    </Alert>
  );
};

export default SubmissionsPageAlert;
