import { RshRole } from 'nrosh-common/Api/Enums';
import { Provider, ProviderWithStringDate, mapProviderResponse } from 'nrosh-common/Api/ProvidersApi';
import { Submission, SubmissionStatusDict, SurveyStatus } from 'nrosh-common/Api/SubmissionsApi';
import AuthContext, { Auth } from 'nrosh-common/Contexts/AuthContext';
import useEndpoint, { useEndpointConditionallyWithProcessing } from 'nrosh-common/Hooks/useEndpoint';
import { useContext, useState } from 'react';
import { Stack } from 'react-bootstrap';
import { Link, generatePath, useParams } from 'react-router-dom';
import { CellProps } from 'react-table';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import Table, { NroshColumn } from '@/Components/Table/Table';
import { ProvidersApi } from '@/Helpers/Apis';
import { formatISODateString } from '@/Helpers/DateHelpers';
import { surveyDisplayName } from '@/Helpers/SurveyHelper';
import { adminPages, submissionPages } from '@/Pages/Home/SitePages';
import '@/Pages/Providers/OrganisationalInformationPage.scss';

const getSubmissionTableColumns = (auth: Auth): NroshColumn<Submission>[] => [
  {
    Header: 'Survey',
    accessor: (row: Submission) => surveyDisplayName(row.surveyName, row.timePeriodName),
    width: 250,
    Cell: ({ row }: CellProps<Submission, string>) =>
      auth.hasRole(RshRole.AssignSurveys) ||
      (auth.hasRole(RshRole.ViewSurveyData) && auth.userCanAccessSurvey(row.original.surveyId)) ? (
        <Link
          to={generatePath(submissionPages.SubmissionView.path, {
            surveyId: row.original.surveyId.toString(),
            timePeriodId: row.original.timePeriodId.toString(),
            submissionId: row.original.id.toString(),
          })}
        >
          {surveyDisplayName(row.original.surveyName, row.original.timePeriodName)}
        </Link>
      ) : (
        surveyDisplayName(row.original.surveyName, row.original.timePeriodName)
      ),
  },
  {
    Header: 'Survey Status',
    accessor: 'surveyStatus',
    width: 100,
  },
  {
    Header: 'Provider Return Status',
    accessor: 'status',
    width: 100,
    Cell: ({ value }) => SubmissionStatusDict[value],
  },
  {
    Header: 'Deadline for Provider',
    accessor: 'deadline',
    align: 'center',
    width: 100,
    Cell: ({ value }) => formatISODateString(value),
  },
];

const tableRowToExportRow = (
  submission: Submission,
): {
  'Survey Name': string;
  'Survey Status': SurveyStatus;
  'Provider Return Status': string;
  'Deadline for Provider': string;
} => ({
  'Survey Name': surveyDisplayName(submission.surveyName, submission.timePeriodName),
  'Survey Status': submission.surveyStatus,
  'Provider Return Status': SubmissionStatusDict[submission.status],
  'Deadline for Provider': formatISODateString(submission.deadline),
});

const OrganisationalInformationPage = (): JSX.Element => {
  const { providerId } = useParams();
  const auth = useContext(AuthContext);

  const [provider] = useEndpointConditionallyWithProcessing<ProviderWithStringDate, Provider>(
    ProvidersApi.getProvider,
    true,
    mapProviderResponse,
    providerId,
  );
  const [submissions] = useEndpoint<Submission[]>(ProvidersApi.getProviderSubmissions, providerId);
  const [showClosedSurveys, setShowClosedSurveys] = useState(false);

  if (!provider || !submissions) {
    return <LoadingMessage />;
  }

  const filteredSubmissions = showClosedSurveys
    ? submissions
    : submissions.filter((submission) => submission.surveyStatus !== SurveyStatus.Closed);

  return (
    <Stack gap={5} className="organisationalInfoContainer">
      <PageHeader
        heading="Organisational information"
        subheading={provider.name}
        crumbsType={RootPathType.AdminProviders}
        crumbs={[
          {
            name: 'Organisational information',
            path: adminPages.AdminViewProvider.path,
          },
        ]}
      />
      <div className="providerLinks d-flex flex-column p-4">
        <Link to={generatePath(adminPages.AdminRegisteredDetails.path, { providerId })}>Registered Details</Link>
        <Link to={generatePath(adminPages.AdminProviderContacts.path, { providerId })}>Contacts</Link>
        {auth.hasRole(RshRole.ViewUsers) && (
          <Link to={generatePath(adminPages.AdminProviderUsers.path, { providerId })}>Users</Link>
        )}
      </div>

      <Stack gap={4}>
        <h2>Provider Detailed Survey Information</h2>
        <Stack gap={3}>
          <div className="d-flex align-items-center">
            <input
              type="checkbox"
              id="closedSurveysCheckbox"
              className="me-2"
              checked={showClosedSurveys}
              onChange={(e) => setShowClosedSurveys(e.target.checked)}
            />
            <label htmlFor="closedSurveysCheckbox">Show closed surveys</label>
          </div>
          <Table
            data={filteredSubmissions}
            columns={getSubmissionTableColumns(auth)}
            paginated
            exportable
            exportFileName={`${provider.name} - Surveys`}
            tableRowToExportRow={tableRowToExportRow}
            rowHeadingIndex={0}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default OrganisationalInformationPage;
