import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ContactDetails from '@/Components/Footer/ContactDetails';
import PrivacyNoticeFooter from '@/Components/Footer/PrivacyNoticeFooter';
import { publicPages } from '@/Pages/Home/SitePages';
import softwireLogo from '@/softwire-logo-blue.svg';
import '@/Components/Footer/footer.scss';

const Footer = (): JSX.Element => (
  <footer className="border-top footer pt-4">
    <Container>
      <Row className="justify-content-center mx-6">
        <Col className="d-flex justify-content-center">
          <ContactDetails />
        </Col>
        <Col className="d-flex justify-content-center">
          <ul className="nav flex-column">
            <li className="mb-2">
              <Link to={publicPages.PublicAbout.path}>About DCS</Link>
            </li>
            <li className="mb-2">
              <Link to={publicPages.PublicContactUs.path}>Contact Us</Link>
            </li>
            <li className="mb-2">
              <Link to={publicPages.PublicTermsAndConditions.path}>Terms & Conditions</Link>
            </li>
            <li className="mb-2">
              <Link to={publicPages.PublicAccessibilityStatement.path}>Accessibility Statement</Link>
            </li>
          </ul>
        </Col>
        <Col className="d-flex justify-content-center">
          <img src={softwireLogo as string} className="softwire-logo" alt="Softwire logo" />
        </Col>
      </Row>
      <Row>
        <Col className="mt-4">
          <PrivacyNoticeFooter />
        </Col>
      </Row>
    </Container>
  </footer>
);
export default Footer;
