import { Editor } from '@tiptap/react';

type EditorCommands = {
  toggleOrderedList: () => boolean;
  toggleUnderline: () => boolean;
  toggleBulletList: () => boolean;
  toggleHeading: (level: 1 | 2 | 3 | 4 | 5 | 6) => boolean;
  setTextAlign: (alignment: 'left' | 'center' | 'right') => boolean;
  readonly canToggleUnderline: boolean;
  toggleStrikethrough: () => boolean;
  redo: () => boolean;
  readonly canRedo: boolean;
  readonly canToggleStrikethrough: boolean;
  toggleItalic: () => boolean;
  readonly canUndo: boolean;
  toggleBold: () => boolean;
  undo: () => boolean;
  setColor: (color: string) => boolean;
  readonly canToggleBold: boolean;
  toggleBlockQuote: () => boolean;
  toggleHighlight: () => boolean;
  addHardBreak: () => boolean;
  insertContent: (content: string) => boolean;
  readonly canToggleItalic: boolean;
  table: {
    readonly canDeleteColumn: boolean;
    readonly canDeleteRow: boolean;
    insert: () => boolean;
    readonly canAddColumnAfter: boolean;
    readonly canAddRowBefore: boolean;
    delete: () => boolean;
    addRowBefore: () => boolean;
    deleteRow: () => boolean;
    readonly canAddColumnBefore: boolean;
    readonly canAddRowAfter: boolean;
    readonly canDelete: boolean;
    addColumnBefore: () => boolean;
    addColumnAfter: () => boolean;
    deleteColumn: () => boolean;
    addRowAfter: () => boolean;
  };
};

export const getEditorCommands = (editor: Editor): EditorCommands => ({
  undo: () => editor.chain().focus().undo().run(),
  get canUndo() {
    return editor.can().chain().focus().undo().run();
  },

  redo: () => editor.chain().focus().redo().run(),
  get canRedo() {
    return editor.can().chain().focus().redo().run();
  },

  setTextAlign: (alignment: 'left' | 'center' | 'right') => editor.chain().focus().setTextAlign(alignment).run(),

  setColor: (color: string) => editor.chain().focus().setColor(color).run(),

  toggleBulletList: () => editor.chain().focus().toggleBulletList().run(),

  toggleOrderedList: () => editor.chain().focus().toggleOrderedList().run(),

  toggleBlockQuote: () => editor.chain().focus().toggleBlockquote().run(),

  toggleBold: () => editor.chain().focus().toggleBold().run(),
  get canToggleBold() {
    return editor.can().chain().focus().toggleBold().run();
  },

  toggleItalic: () => editor.chain().focus().toggleItalic().run(),
  get canToggleItalic() {
    return editor.can().chain().focus().toggleItalic().run();
  },

  toggleUnderline: () => editor.chain().focus().toggleUnderline().run(),
  get canToggleUnderline() {
    return editor.can().chain().focus().toggleUnderline().run();
  },

  toggleStrikethrough: () => editor.chain().focus().toggleStrike().run(),
  get canToggleStrikethrough() {
    return editor.can().chain().focus().toggleStrike().run();
  },

  toggleHighlight: () => editor.chain().focus().toggleHighlight().run(),

  table: {
    insert: () => editor.chain().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run(),
    delete: () => editor.chain().focus().deleteTable().run(),
    get canDelete() {
      return editor.can().deleteTable();
    },
    addColumnBefore: () => editor.chain().focus().addColumnBefore().run(),
    get canAddColumnBefore() {
      return editor.can().addColumnBefore();
    },
    addColumnAfter: () => editor.chain().focus().addColumnAfter().run(),
    get canAddColumnAfter() {
      return editor.can().addColumnAfter();
    },
    addRowBefore: () => editor.chain().focus().addRowBefore().run(),
    get canAddRowBefore() {
      return editor.can().addRowBefore();
    },
    addRowAfter: () => editor.chain().focus().addRowAfter().run(),
    get canAddRowAfter() {
      return editor.can().addRowAfter();
    },
    deleteColumn: () => editor.chain().focus().deleteColumn().run(),
    get canDeleteColumn() {
      return editor.can().deleteColumn();
    },
    deleteRow: () => editor.chain().focus().deleteRow().run(),
    get canDeleteRow() {
      return editor.can().deleteRow();
    },
  },

  toggleHeading: (level: 1 | 2 | 3 | 4 | 5 | 6) => editor.chain().focus().toggleHeading({ level }).run(),

  addHardBreak: () => editor.chain().focus().setHardBreak().run(),
  insertContent: (content: string) => editor.chain().focus().insertContent(content).run(),
});
