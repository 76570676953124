import { EmailTemplateType } from 'nrosh-common/Api/EmailsApi';
import React, { Fragment } from 'react';
import { Dropdown } from 'react-bootstrap';
import '@/Components/Form/AccessibleFeedback.scss';
import { AccessibleFeedback } from '@/Components/Form/AccessibleFeedback';

export type TemplateFieldGroup = TemplateField[];

export enum TemplateField {
  ProviderNumber = 'PROVIDER_NUMBER',
  ProviderName = 'PROVIDER_NAME',
  UserUsername = 'USER_USERNAME',
  UserPreferredName = 'USER_PREFERREDNAME',
  SurveyName = 'SURVEY_NAME',
  SurveyDeadline = 'SURVEY_DEADLINE',
  LinkHomepage = 'LINK_HOMEPAGE',
  LinkDocuments = 'LINK_DOCUMENTS',
  LinkPrivacyNotice = 'LINK_PRIVACYNOTICE',
  ValidationCode = 'VALIDATION_CODE',
  PasswordResetLink = 'PASSWORD_RESETLINK',
  PasswordSetupLink = 'PASSWORD_SETUPLINK',
  NROSHLogo = 'IMAGE_NROSHLOGO',
  RSHLogo = 'IMAGE_RSHLOGO',
  ConfirmEmailLink = 'CONFIRM_EMAIL_LINK',
}

export const templateFieldTitles: Record<TemplateField, string> = {
  PROVIDER_NUMBER: 'Provider Number',
  PROVIDER_NAME: 'Provider Name',
  USER_USERNAME: 'Username',
  USER_PREFERREDNAME: 'Preferred Name',
  SURVEY_NAME: 'Survey Name',
  SURVEY_DEADLINE: 'Survey Deadline',
  LINK_HOMEPAGE: 'Homepage Link',
  LINK_DOCUMENTS: 'Documents Link',
  LINK_PRIVACYNOTICE: 'Privacy Notice Link',
  VALIDATION_CODE: 'Validation Code',
  PASSWORD_RESETLINK: 'Password Reset Link',
  PASSWORD_SETUPLINK: 'Password Setup Link',
  IMAGE_NROSHLOGO: 'NROSH+ Logo',
  IMAGE_RSHLOGO: 'RSH Logo',
  CONFIRM_EMAIL_LINK: 'Confirm Email Link',
};

const providerTemplateFieldGroup = [TemplateField.ProviderNumber, TemplateField.ProviderName];

const userTemplateFieldGroup = [TemplateField.UserUsername, TemplateField.UserPreferredName];

const surveyTemplateFieldGroup = [TemplateField.SurveyName, TemplateField.SurveyDeadline];

const linksTemplateFieldGroup = [
  TemplateField.LinkHomepage,
  TemplateField.LinkDocuments,
  TemplateField.LinkPrivacyNotice,
];

const imagesTemplateFieldGroup = [TemplateField.NROSHLogo, TemplateField.RSHLogo];

const validationTemplateFieldGroup = [TemplateField.ValidationCode];

const passwordResetTemplateFieldGroup = [TemplateField.PasswordResetLink];
const passwordSetupTemplateFieldGroup = [TemplateField.PasswordSetupLink];
const confirmEmailTemplateFieldGroup = [TemplateField.ConfirmEmailLink];

// TODO-507: reinstate image templating once Exchange attachments are enabled
// export const globalTemplateFieldGroups = [linksTemplateFieldGroup, imagesTemplateFieldGroup];

export const invalidSubjectTemplateFields = [
  ...linksTemplateFieldGroup,
  ...imagesTemplateFieldGroup,
  TemplateField.PasswordResetLink,
  TemplateField.PasswordSetupLink,
];

export const globalTemplateFieldGroups = [linksTemplateFieldGroup];

export const availableTemplateFieldGroups: Record<EmailTemplateType | 'Manual', TemplateFieldGroup[]> = {
  NewProviderUser: [userTemplateFieldGroup, providerTemplateFieldGroup, passwordSetupTemplateFieldGroup],
  NewDcsUser: [userTemplateFieldGroup, passwordSetupTemplateFieldGroup],
  ProviderUserPasswordReset: [userTemplateFieldGroup, providerTemplateFieldGroup, passwordResetTemplateFieldGroup],
  DcsUserPasswordReset: [userTemplateFieldGroup, passwordResetTemplateFieldGroup],
  ProviderUserUsernameReminder: [userTemplateFieldGroup, providerTemplateFieldGroup],
  DcsUserUsernameReminder: [userTemplateFieldGroup],
  ProviderUserAccountLock: [userTemplateFieldGroup, providerTemplateFieldGroup],
  DcsUserAccountLock: [userTemplateFieldGroup],
  ProviderUserAccountDisabled: [userTemplateFieldGroup, providerTemplateFieldGroup],
  DcsUserAccountDisabled: [userTemplateFieldGroup],
  ProviderUserAccountReactivated: [userTemplateFieldGroup, providerTemplateFieldGroup, passwordSetupTemplateFieldGroup],
  DcsUserAccountReactivated: [userTemplateFieldGroup, passwordSetupTemplateFieldGroup],
  ValidationAccepted: [providerTemplateFieldGroup, surveyTemplateFieldGroup, validationTemplateFieldGroup],
  SurveySubmitted: [providerTemplateFieldGroup, surveyTemplateFieldGroup],
  SurveyAssigned: [providerTemplateFieldGroup, surveyTemplateFieldGroup],
  SurveyDeadlineUpdated: [providerTemplateFieldGroup, surveyTemplateFieldGroup],
  SurveyUnsubmitted: [providerTemplateFieldGroup, surveyTemplateFieldGroup],
  SurveySignOff: [providerTemplateFieldGroup, surveyTemplateFieldGroup],
  SurveyReminderDefault: [providerTemplateFieldGroup, surveyTemplateFieldGroup],
  Footer: [],
  ConfirmEmail: [confirmEmailTemplateFieldGroup],
  Manual: [providerTemplateFieldGroup],
};

export const requiredTemplateFields: Record<EmailTemplateType | 'Manual', TemplateField[]> = {
  NewProviderUser: [TemplateField.PasswordSetupLink, TemplateField.UserUsername],
  NewDcsUser: [TemplateField.PasswordSetupLink, TemplateField.UserUsername],
  ProviderUserPasswordReset: [TemplateField.PasswordResetLink],
  DcsUserPasswordReset: [TemplateField.PasswordResetLink],
  ProviderUserUsernameReminder: [TemplateField.UserUsername],
  DcsUserUsernameReminder: [TemplateField.UserUsername],
  ProviderUserAccountLock: [],
  DcsUserAccountLock: [],
  ProviderUserAccountDisabled: [],
  DcsUserAccountDisabled: [],
  ProviderUserAccountReactivated: [TemplateField.PasswordSetupLink],
  DcsUserAccountReactivated: [TemplateField.PasswordSetupLink],
  ValidationAccepted: [TemplateField.ValidationCode, TemplateField.SurveyName],
  SurveySubmitted: [TemplateField.SurveyName],
  SurveyAssigned: [TemplateField.SurveyName],
  SurveyDeadlineUpdated: [TemplateField.SurveyName, TemplateField.SurveyDeadline],
  SurveyUnsubmitted: [TemplateField.SurveyName],
  SurveySignOff: [TemplateField.SurveyName],
  SurveyReminderDefault: [TemplateField.SurveyName, TemplateField.SurveyDeadline],
  Footer: [TemplateField.LinkPrivacyNotice],
  ConfirmEmail: [TemplateField.ConfirmEmailLink],
  Manual: [],
};

const templateFieldRegex = /(?:<<|&lt;&lt;)(.*?)(?:>>|&gt;&gt;)/g;

export const getUsedTemplateFields = (textToSearch: string): string[] => {
  const matches = textToSearch.matchAll(templateFieldRegex);

  return Array.from(matches, (match) => match[1]);
};

export const getMissingTemplateFields = (
  specifiedFields: string[],
  emailType: EmailTemplateType | 'Manual',
): TemplateField[] =>
  requiredTemplateFields[emailType].filter((f) => !specifiedFields.some((tf) => tf === (f as string)));

export const getUnrecognisedTemplateFields = (
  specifiedFields: string[],
  emailType: EmailTemplateType | 'Manual',
): string[] => [
  ...new Set(
    specifiedFields.filter((f) =>
      globalTemplateFieldGroups
        .concat(availableTemplateFieldGroups[emailType])
        .flat()
        .every((tf) => (tf as string) !== f),
    ),
  ),
];

export const getInvalidSubjectTemplateFields = (specifiedFields: string[]): TemplateField[] => [
  ...new Set(
    specifiedFields
      .filter((sf) => invalidSubjectTemplateFields.some((d) => sf === (d as string)))
      .map((sf) => sf as TemplateField),
  ),
];

type TemplateDropdownMenuItemsProps = {
  templateGroups: TemplateFieldGroup[];
  onInsert: (content: string) => void;
  requiredTemplateFieldsOptions: TemplateField[];
};

export const TemplatesDropdownMenuItems = ({
  templateGroups,
  onInsert,
  requiredTemplateFieldsOptions,
}: TemplateDropdownMenuItemsProps): JSX.Element => {
  const filteredTemplateGroups = templateGroups
    .map((tg) => tg.filter((tf) => requiredTemplateFieldsOptions.every((rtf) => rtf !== tf)))
    .filter((tg) => tg.length > 0);

  const filteredTemplateGroupsOptions = filteredTemplateGroups.map((tg, order) => ({ templateGroup: tg, order }));

  return (
    <>
      {requiredTemplateFieldsOptions.length > 0 && (
        <>
          {requiredTemplateFieldsOptions.map((t) => (
            <Dropdown.Item key={`template-required-${t}`} onClick={() => onInsert(`<<${t}>>`)}>
              {templateFieldTitles[t]}*
            </Dropdown.Item>
          ))}
          {filteredTemplateGroups.length > 0 && <Dropdown.Divider key="divider-required" />}
        </>
      )}
      {filteredTemplateGroupsOptions.map((option, i) => (
        <Fragment key={`template-group-${option.order}`}>
          {option.templateGroup.map((t) => (
            <Dropdown.Item key={`template-${option.order}-${t}`} onClick={() => onInsert(`<<${t}>>`)}>
              {templateFieldTitles[t]}
            </Dropdown.Item>
          ))}
          {i !== filteredTemplateGroups.length - 1 && <Dropdown.Divider key={`divider-${option.order}`} />}
        </Fragment>
      ))}
    </>
  );
};

type InvalidTemplatingFeedbackProps = {
  validated: boolean;
  missingTemplateFields: TemplateField[];
  unrecognisedTemplateFields: string[];
  invalidSubjectTemplateFieldsFeedback: TemplateField[];
  id: string;
};

export const InvalidTemplatingFeedback = ({
  validated,
  missingTemplateFields,
  unrecognisedTemplateFields,
  invalidSubjectTemplateFieldsFeedback,
  id,
}: InvalidTemplatingFeedbackProps): JSX.Element => {
  const isInvalid =
    validated &&
    (missingTemplateFields.length !== 0 ||
      unrecognisedTemplateFields.length !== 0 ||
      invalidSubjectTemplateFieldsFeedback.length !== 0);
  return (
    <div>
      {isInvalid && (
        <AccessibleFeedback id={id} displayFeedback={isInvalid}>
          {missingTemplateFields.length !== 0 && (
            <>
              The following required template fields must appear somewhere in your message:
              <ul>
                {missingTemplateFields.map((v) => (
                  <li key={`required-field-${v}`}>{templateFieldTitles[v]}</li>
                ))}
              </ul>
            </>
          )}
          {invalidSubjectTemplateFieldsFeedback.length !== 0 && (
            <>
              The following template fields cannot be used in the subject:
              <ul>
                {invalidSubjectTemplateFieldsFeedback.map((v) => (
                  <li key={`invalid-subject-field-${v}`}>{v}</li>
                ))}
              </ul>
            </>
          )}
          {unrecognisedTemplateFields.length !== 0 && (
            <>
              The following template fields are unrecognised:
              <ul>
                {unrecognisedTemplateFields.map((v) => (
                  <li key={`unrecognised-field-${v}`}>{v}</li>
                ))}
              </ul>
            </>
          )}
        </AccessibleFeedback>
      )}
    </div>
  );
};
