import { DimensionalMember } from 'nrosh-common/Api/SubmissionsApi';
import { DataPoint } from 'nrosh-common/Api/SurveyPartsApi';

const cellReferenceRegExp = /^#(cell-(DP_\w+)(:\d+:\d*)?)$/;

type ParseCellResult =
  | { isValidCellId: true; cellId: string; dataPointId: string; dimensionMemberIds: number[] }
  | { isValidCellId: false };

export const parseCellId = (cellIdHash: string): ParseCellResult => {
  const matches = cellReferenceRegExp.exec(cellIdHash);

  if (matches) {
    const [, cellId, dataPointId, dimensions] = matches;
    return {
      isValidCellId: true,
      cellId,
      dataPointId,
      dimensionMemberIds: dimensions
        ? dimensions
            .split(':')
            .filter((id) => id !== '')
            .map(Number)
        : [],
    };
  }

  return {
    isValidCellId: false,
  };
};

export const createCellId = (dataPointId: string, memberId1?: number | null, memberId2?: number | null): string => {
  const dimensionString = memberId1 ? `:${memberId1}:${memberId2 ?? ''}` : '';
  return `cell-${dataPointId}${dimensionString}`;
};

export const orderDimensionMembersAndCreateCellId = (
  dataPoint: DataPoint,
  memberIds: number[],
  dimensionMembers: DimensionalMember[],
): string => {
  if (memberIds.length === 0) return createCellId(dataPoint.id);
  // Use the data point's dimensions to work out the correct order of the dimension member IDs.
  const members = dimensionMembers.filter((dm) => memberIds.includes(dm.id));
  const member1 = members.find((m) => m.dimensionId === dataPoint.dimension1);
  const member2 = members.find((m) => m.dimensionId === dataPoint.dimension2); // Undefined if dimension not set

  return createCellId(dataPoint.id, member1?.id, member2?.id);
};
