import '@/Pages/Submissions/SubmissionDescription.scss';
import classNames from 'classnames';
import { ProviderRole, RshRole, SubmissionStatus } from 'nrosh-common/Api/Enums';
import { SurveyStatus } from 'nrosh-common/Api/SubmissionsApi';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import { Dispatch, FormEvent, SetStateAction, useContext, useState } from 'react';
import { Form } from 'react-bootstrap';
import { generatePath, useParams } from 'react-router-dom';
import { PrimaryButton, SecondaryButton, TertiaryButton } from '@/Components/Buttons/DCSButton';
import { useErrorReporting } from '@/Components/Errors/DCSErrorBoundary';
import { UtcDatePicker } from '@/Components/Form/FormDatePicker';
import Edit from '@/Components/Icons/Edit';
import { Warning } from '@/Components/Icons/Warning';
import LinkButton from '@/Components/Links/LinkButton';
import { useModal } from '@/Components/Modal/ModalProvider';
import TooltipOnHover from '@/Components/Tooltips/TooltipOnHover';
import { SubmissionsApi } from '@/Helpers/Apis';
import { formatISODateString } from '@/Helpers/DateHelpers';
import { providerPages, submissionPages } from '@/Pages/Home/SitePages';
import {
  canBeSignedOff,
  canBeSubmitted,
  canBeUnSignedOff,
  canBeUnsubmitted,
  hasBeenSubmitted,
  submissionIsReadonly,
} from '@/Pages/Submissions/SubmissionHelpers';

type EditSubmissionDeadlineFormProps = {
  deadline: Date;
  setDeadline: Dispatch<SetStateAction<Date>>;
  setIsEditingDeadline: Dispatch<SetStateAction<boolean>>;
};

const EditSubmissionDeadlineForm = ({
  deadline,
  setDeadline,
  setIsEditingDeadline,
}: EditSubmissionDeadlineFormProps): JSX.Element => {
  const { submissionId } = useParams();
  const [updatedDeadline, setUpdatedDeadline] = useState(deadline);
  const [raiseError, clearError] = useErrorReporting();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault(); // Stops the page from reloading

    const response = await SubmissionsApi.updateDeadlineOverride(submissionId!, updatedDeadline, false).raw;

    if (!response.ok) {
      const { message } = response.value;
      raiseError(message);
      return;
    }

    clearError();
    setDeadline(updatedDeadline);
    setIsEditingDeadline(false);
  };

  const onCancel = (): void => {
    setUpdatedDeadline(deadline);
    setIsEditingDeadline(false);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Label className="mb-0 d-block" htmlFor="datePicker">
        Submission deadline
      </Form.Label>
      <Form.Text>The deadline will be changed only for this Provider.</Form.Text>
      <div className="d-flex align-items-center">
        <UtcDatePicker
          inputId="datePicker"
          value={updatedDeadline}
          onChange={(newDate) => newDate && setUpdatedDeadline(newDate)}
        />
        <PrimaryButton className="ms-3" type="submit">
          Save
        </PrimaryButton>
        <SecondaryButton className="ms-2" onClick={onCancel}>
          Cancel
        </SecondaryButton>
      </div>
    </Form>
  );
};

type SubmitButtonProps = {
  isAdmin: boolean;
  tooltipText?: string;
  submissionId?: string;
  surveyId?: string;
  timePeriodId?: string;
  disabled: boolean;
};

const SubmitButton = ({
  isAdmin,
  tooltipText,
  submissionId,
  surveyId,
  timePeriodId,
  disabled,
}: SubmitButtonProps): JSX.Element => {
  const Button = (
    <LinkButton
      to={
        isAdmin
          ? generatePath(submissionPages.AdminValidationIssues.path, { submissionId, surveyId, timePeriodId })
          : generatePath(providerPages.ValidationIssues.path, { submissionId })
      }
      disabled={disabled}
    >
      Submit survey
    </LinkButton>
  );

  return tooltipText ? (
    <TooltipOnHover placement="left" text={tooltipText}>
      {Button}
    </TooltipOnHover>
  ) : (
    Button
  );
};

type SubmissionDescriptionButtonsProps = {
  surveyStatus: SurveyStatus;
  submissionStatus: SubmissionStatus;
  setSubmissionStatus: (status: SubmissionStatus) => void;
  isAdmin: boolean;
  hasHardValidationIssues: boolean;
};

const SubmissionDescriptionButtons = ({
  surveyStatus,
  submissionStatus,
  setSubmissionStatus,
  isAdmin,
  hasHardValidationIssues,
}: SubmissionDescriptionButtonsProps): JSX.Element => {
  const { submissionId, surveyId, timePeriodId } = useParams();
  const auth = useContext(AuthContext);
  const { confirm } = useModal();
  const [raiseError, clearError] = useErrorReporting();
  const showSubmitButton =
    (auth.hasRole(ProviderRole.SubmitSurvey) && canBeSubmitted(submissionStatus, surveyStatus)) ||
    (auth.hasRole(RshRole.SubmitSurvey) && !hasBeenSubmitted(submissionStatus) && surveyStatus !== SurveyStatus.Closed);

  const onUnSignOff = async (): Promise<void> => {
    const unSignOffConfirmationPrompt = 'Are you sure you want to un-sign-off this submission?';

    if (!(await confirm(unSignOffConfirmationPrompt))) return;

    const response = await SubmissionsApi.unSignOff(submissionId!).raw;

    if (!response.ok) {
      const { message } = response.value;
      raiseError(message);
      return;
    }

    clearError();
    setSubmissionStatus(SubmissionStatus.Submitted);
  };

  return (
    <div className="d-flex gap-3 align-self-center">
      {showSubmitButton && (
        <SubmitButton
          isAdmin={isAdmin}
          submissionId={submissionId}
          surveyId={surveyId}
          timePeriodId={timePeriodId}
          tooltipText={
            hasHardValidationIssues ? 'You must complete all parts of the survey before you can submit it.' : ''
          }
          disabled={hasHardValidationIssues}
        />
      )}
      {isAdmin && (
        <>
          {canBeSignedOff(submissionStatus, surveyStatus) && auth.hasRole(RshRole.SignOffSurvey) && (
            <LinkButton
              to={generatePath(submissionPages.AdminSignOffPage.path, { surveyId, timePeriodId, submissionId })}
            >
              Sign off survey
            </LinkButton>
          )}
          {canBeUnsubmitted(submissionStatus, surveyStatus) && auth.hasRole(RshRole.UnsubmitSurvey) && (
            <LinkButton
              to={generatePath(submissionPages.AdminUnsubmitPage.path, { surveyId, timePeriodId, submissionId })}
              variant="outline-primary"
            >
              Reject submission
            </LinkButton>
          )}
          {canBeUnSignedOff(submissionStatus, surveyStatus) && auth.hasRole(RshRole.UnsignOffSurvey) && (
            <SecondaryButton onClick={onUnSignOff}>Undo sign off</SecondaryButton>
          )}
        </>
      )}
    </div>
  );
};

export type SubmissionDescriptionProps = {
  initialDeadline: Date;
  submissionStatus: SubmissionStatus;
  mostRecentSubmission: string | null;
  signOffDate: string | null;
  isAdmin: boolean;
  surveyStatus: SurveyStatus;
  hasHardValidationIssues: boolean;
  setSubmissionStatus: (status: SubmissionStatus) => void;
};

const SubmissionDescription = ({
  initialDeadline,
  submissionStatus,
  mostRecentSubmission,
  signOffDate,
  isAdmin,
  surveyStatus,
  hasHardValidationIssues,
  setSubmissionStatus,
}: SubmissionDescriptionProps): JSX.Element => {
  const [deadline, setDeadline] = useState(initialDeadline);
  const statusString = submissionStatus.replace(/([A-Z])/g, ' $1').trim();
  const mostRecentSubmissionString = mostRecentSubmission
    ? formatISODateString(mostRecentSubmission)
    : 'Not yet submitted';
  const signOffDateString = signOffDate ? formatISODateString(signOffDate) : 'Pending';

  const [isEditingDeadline, setIsEditingDeadline] = useState<boolean>(false);

  const showOverdueWarning = deadline < new Date() && !isAdmin && canBeSubmitted(submissionStatus, surveyStatus);

  return (
    <div className="submissionDescription d-flex p-4 rounded-1 justify-content-between">
      <div className="d-flex flex-column gap-3">
        {isEditingDeadline ? (
          <EditSubmissionDeadlineForm
            deadline={deadline}
            setDeadline={setDeadline}
            setIsEditingDeadline={setIsEditingDeadline}
          />
        ) : (
          <div className="submissionDetail">
            <div
              className={classNames('submissionDetail', 'submissionDeadline', {
                overdue: showOverdueWarning,
              })}
            >
              Submission deadline:
              <span className="fw-semibold">{formatISODateString(deadline.toISOString())}</span>
              {showOverdueWarning && <Warning />}
            </div>
            {isAdmin && !submissionIsReadonly(submissionStatus, isAdmin, surveyStatus) && (
              <TertiaryButton onClick={() => setIsEditingDeadline(true)}>
                <Edit />
                Edit deadline
              </TertiaryButton>
            )}
          </div>
        )}
        <div className="submissionDetail">
          Survey status:<span className="fw-semibold">{statusString}</span>
        </div>
        <div className="submissionDetail">
          Most recent submission:<span className="fw-semibold">{mostRecentSubmissionString}</span>
        </div>
        {hasBeenSubmitted(submissionStatus) && (
          <div className="submissionDetail">
            Sign-off date:<span className="fw-semibold">{signOffDateString}</span>
          </div>
        )}
      </div>
      {!isEditingDeadline && (
        <SubmissionDescriptionButtons
          surveyStatus={surveyStatus}
          submissionStatus={submissionStatus}
          setSubmissionStatus={setSubmissionStatus}
          isAdmin={isAdmin}
          hasHardValidationIssues={hasHardValidationIssues}
        />
      )}
    </div>
  );
};

export default SubmissionDescription;
