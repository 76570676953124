import { AiOutlineEllipsis } from 'react-icons/ai';
import { BiChevronLeft, BiChevronRight, BiChevronsLeft, BiChevronsRight } from 'react-icons/bi';
import PageNumber from '@/Components/Pagination/PageNumber';
import '@/Components/Pagination/Pagination.scss';

export type PaginationProps = {
  pageOptions: number[];
  pageIndex: number;
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
  nextPage: () => void;
  canNextPage: boolean;
  previousPage: () => void;
  canPreviousPage: boolean;
};

const Pagination: (props: PaginationProps) => JSX.Element = ({
  pageOptions,
  pageIndex,
  gotoPage,
  nextPage,
  canNextPage,
  previousPage,
  canPreviousPage,
}) => {
  const pages: JSX.Element[] = [];
  const numberOfPages = pageOptions.length;
  const firstPage = 0;
  const lastPage = pageOptions.length - 1;
  const maxPagesWithoutEllipsis = 8;
  const pageIndexBeforeEllipsis = 3;
  const pagesBeforeEllipsis = 4;
  const pageIndexAfterEllipsis = pageOptions.length - 3;
  const pagesAroundActivePage = 2;

  if (numberOfPages < maxPagesWithoutEllipsis) {
    for (let i = 0; i < numberOfPages; i++) {
      pages.push(<PageNumber key={i} index={i} activeIndex={pageIndex} gotoPage={gotoPage} />);
    }
  } else if (pageIndex > pageIndexBeforeEllipsis && pageIndex < pageIndexAfterEllipsis) {
    pages.push(<PageNumber key={firstPage} index={firstPage} activeIndex={pageIndex} gotoPage={gotoPage} />);
    pages.push(<AiOutlineEllipsis key="paginationEllipsis1" className="paginationEllipsis" />);
    for (let i = pageIndex - pagesAroundActivePage; i <= pageIndex + pagesAroundActivePage; i++) {
      pages.push(<PageNumber key={i} index={i} activeIndex={pageIndex} gotoPage={gotoPage} />);
    }
    pages.push(<AiOutlineEllipsis key="paginationEllipsis2" className="paginationEllipsis" />);
    pages.push(<PageNumber key={lastPage} index={lastPage} activeIndex={pageIndex} gotoPage={gotoPage} />);
  } else if (pageIndex === pageIndexBeforeEllipsis) {
    for (let i = 0; i < pageIndex + pageIndexBeforeEllipsis; i++) {
      pages.push(<PageNumber key={i} index={i} activeIndex={pageIndex} gotoPage={gotoPage} />);
    }
    pages.push(<AiOutlineEllipsis key="paginationEllipsis" className="paginationEllipsis" />);
    pages.push(<PageNumber key={lastPage} index={lastPage} activeIndex={pageIndex} gotoPage={gotoPage} />);
  } else if (pageIndex === pageIndexAfterEllipsis) {
    pages.push(<PageNumber key={firstPage} index={firstPage} activeIndex={pageIndex} gotoPage={gotoPage} />);
    pages.push(<AiOutlineEllipsis key="paginationEllipsis" className="paginationEllipsis" />);
    for (let i = pageIndex - pagesAroundActivePage; i < numberOfPages; i++) {
      pages.push(<PageNumber key={i} index={i} activeIndex={pageIndex} gotoPage={gotoPage} />);
    }
  } else {
    for (let i = 0; i < pagesBeforeEllipsis; i++) {
      pages.push(<PageNumber key={i} index={i} activeIndex={pageIndex} gotoPage={gotoPage} />);
    }
    pages.push(<AiOutlineEllipsis key="paginationEllipsis" className="paginationEllipsis" />);
    for (let i = pageIndexAfterEllipsis; i < numberOfPages; i++) {
      pages.push(<PageNumber key={i} index={i} activeIndex={pageIndex} gotoPage={gotoPage} />);
    }
  }

  return (
    <div className="paginationContainer">
      <button type="button" aria-label="First page" onClick={() => gotoPage(firstPage)} disabled={!canPreviousPage}>
        <BiChevronsLeft />
      </button>
      <button
        className="previousButton"
        type="button"
        aria-label="Previous page"
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
      >
        <BiChevronLeft />
        Previous
      </button>
      {pages}
      <button
        className="nextButton"
        type="button"
        aria-label="Next page"
        onClick={() => nextPage()}
        disabled={!canNextPage}
      >
        Next
        <BiChevronRight />
      </button>
      <button type="button" aria-label="Last page" onClick={() => gotoPage(lastPage)} disabled={!canNextPage}>
        <BiChevronsRight />
      </button>
    </div>
  );
};

export default Pagination;
