import { ValidationType } from 'nrosh-common/Api/Enums';
import { Badge, Stack } from 'react-bootstrap';
import { HardValidationBadge, SoftValidationBadge } from '@/Components/Badges/Badge';
import { ValidationModel } from '@/Components/Validations/ValidationTypes';

const ValidationIssuesBadges = (props: { hardValidationIssues: number; softValidationIssues: number }): JSX.Element => {
  const { hardValidationIssues, softValidationIssues } = props;

  if (hardValidationIssues === 0 && softValidationIssues === 0) {
    return (
      <Badge pill className="fw-normal fs-4" bg="dcs-status-open" text="dark">
        No validation issues
      </Badge>
    );
  }

  return (
    <Stack gap={2} direction="horizontal">
      {hardValidationIssues > 0 && (
        <span className="d-flex">
          <HardValidationBadge ariaLabel={`${hardValidationIssues}`}>{hardValidationIssues}</HardValidationBadge>
          <span>Hard</span>
        </span>
      )}
      {softValidationIssues > 0 && (
        <span className="d-flex">
          <SoftValidationBadge ariaLabel={`${softValidationIssues}`}>{softValidationIssues}</SoftValidationBadge>
          <span>Soft</span>
        </span>
      )}
    </Stack>
  );
};

const ValidationIssues = (props: { validations: ValidationModel[] }): JSX.Element => {
  const { validations } = props;

  const numberOfHardIssues = validations.filter(
    (v) => v.type === ValidationType.Hard && !v.isPassing && !v.isApproved,
  ).length;
  const numberOfSoftIssues = validations.filter((v) => v.type === ValidationType.Soft && !v.isPassing).length;

  return (
    <Stack direction="horizontal" gap={2}>
      <span>Validation issues:</span>
      <ValidationIssuesBadges hardValidationIssues={numberOfHardIssues} softValidationIssues={numberOfSoftIssues} />
    </Stack>
  );
};

export default ValidationIssues;
