import { CellStyle } from 'nrosh-common/Api/Enums';
import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { ReactNode } from 'react';
import { styleIsData } from '@/Components/Spreadsheet/Cells/CellStyleHelpers';
import { DataCellStyle, DimensionIdentifier, DimensionValue } from '@/Components/Spreadsheet/SpreadsheetTypes';
import { formatDateStringForInput } from '@/Helpers/DateHelpers';

type CellContentProps = {
  children: ReactNode;
  height: number;
};

export type BaseCellProps = {
  style: CellStyle;
  height: number;
  width: number;
  rowSpan: number;
  columnSpan: number;
  isInFreezePane: boolean;
  left?: number;
  top?: number;
};

export type InputCellProps = BaseCellProps & {
  cellStyle: DataCellStyle;
  cellKey: string;
  isActive: boolean;
  setActiveCell: ReactStateSetter<string | null>;
  value: string;
  dimension1Id: number | null;
  dimension2Id: number | null;
  updateDataPoint: (
    dataPointId: string,
    value: string,
    dimension1Id: number | null,
    dimension2Id: number | null,
    sendToHub?: boolean,
  ) => void;
  readonly: boolean;
};

export const formattedValue = (style: CellStyle, rawValue: string): string => {
  switch (style) {
    case CellStyle.DataDate:
      return formatDateStringForInput(rawValue);
    default:
      return rawValue;
  }
};

const convertToNumber = (input: string, treatEmptyAsNaN: boolean): number =>
  treatEmptyAsNaN && !input ? NaN : Number(input);

const truncateAndFix = (rawValue: string, decimalPlaces: number): string => {
  // If rawValue is the empty string, or if it doesn't encode a valid a number, just return it
  if (!rawValue || Number.isNaN(Number(rawValue))) return rawValue;

  // Try to find the decimal point (we assume UK locale for writing decimal numbers)
  const decimalPlaceLocation = rawValue.lastIndexOf('.');

  if (decimalPlaceLocation === -1) {
    // If there is no decimal point, and we want an integer, return the value as-is
    if (decimalPlaces === 0) return rawValue;

    // If there is no decimal point, but we need some decimal places, add the decimal point and some trailing zeros
    return `${rawValue}.${'0'.repeat(decimalPlaces)}`;
  }

  // If the decimal point is the first character, we want to add a leading 0 after other processing is complete
  const prefix = decimalPlaceLocation === 0 ? '0' : '';

  // If we don't want any decimal places, keep only what is strictly left of the decimal point
  if (decimalPlaces === 0) {
    return prefix + rawValue.slice(0, decimalPlaceLocation);
  }

  // Otherwise, calculate how many digits are after the decimal point
  const numDigitsToRightOfDecimalPlace = rawValue.length - decimalPlaceLocation - 1;

  // Slice away any decimal places we don't need
  if (numDigitsToRightOfDecimalPlace >= decimalPlaces) {
    return prefix + rawValue.slice(0, decimalPlaceLocation + decimalPlaces + 1);
  }

  // At this stage, we have too few decimal places, so we add the necessary number of zeros
  return `${prefix}${rawValue}${'0'.repeat(decimalPlaces - numDigitsToRightOfDecimalPlace)}`;
};

export const truncateValue = (style: CellStyle, value: string): string => {
  switch (style) {
    case CellStyle.DataInteger:
    case CellStyle.TotalInteger:
    case CellStyle.PrePopulatedInteger:
      return truncateAndFix(value, 0);
    case CellStyle.DataDecimalOneDp:
    case CellStyle.TotalDecimalOneDp:
    case CellStyle.PrePopulatedDecimalOneDp:
      return truncateAndFix(value, 1);
    case CellStyle.DataCurrency:
    case CellStyle.TotalCurrency:
    case CellStyle.PrePopulatedCurrency:
    case CellStyle.DataDecimalTwoDp:
    case CellStyle.TotalDecimalTwoDp:
    case CellStyle.PrePopulatedDecimalTwoDp:
    case CellStyle.TotalPercent:
      return truncateAndFix(value, 2);
    default:
      return value;
  }
};

export const styledValue = (style: CellStyle, rawValue: string): string => {
  const numberValue = convertToNumber(rawValue, styleIsData(style));

  if (Number.isNaN(numberValue)) {
    return formattedValue(style, rawValue);
  }

  switch (style) {
    case CellStyle.DataDecimalOneDp:
    case CellStyle.TotalDecimalOneDp:
    case CellStyle.PrePopulatedDecimalOneDp:
    case CellStyle.DataDecimalTwoDp:
    case CellStyle.TotalDecimalTwoDp:
    case CellStyle.PrePopulatedDecimalTwoDp:
    case CellStyle.DataInteger:
    case CellStyle.TotalInteger:
    case CellStyle.PrePopulatedInteger:
      return truncateValue(style, rawValue);
    case CellStyle.DataCurrency:
    case CellStyle.TotalCurrency:
    case CellStyle.PrePopulatedCurrency:
      return `£${truncateValue(style, rawValue)}`;
    case CellStyle.TotalPercent:
      return `${truncateValue(style, (numberValue * 100).toString())}%`;
    default:
      return formattedValue(style, rawValue);
  }
};

export const inputType = (style: CellStyle): string => {
  switch (style) {
    case CellStyle.DataDate:
      return 'date';
    default:
      return 'text';
  }
};

// The width of each column is set in an empty row at the top of the spreadsheet.
// Each individual cell is then given 100% width via css to fill the available column width.
// Note, however, that the same approach doesn't work for cell heights because we do not have an empty column to control the row heights
// Tech debt to investigate the same approach for cell heights, see ticket #741
export const CellContent = ({ children, height }: CellContentProps): JSX.Element => (
  <div className="cell-content" style={{ height }}>
    {children}
  </div>
);

// The purpose of this function is to output an object which is guaranteed to only
// include the base cell props.
export const baseCellProps = (cellProps: BaseCellProps): BaseCellProps => ({
  style: cellProps.style,
  height: cellProps.height,
  width: cellProps.width,
  rowSpan: cellProps.rowSpan,
  columnSpan: cellProps.columnSpan,
  isInFreezePane: cellProps.isInFreezePane,
  left: cellProps.left,
  top: cellProps.top,
});

export const findDimensionMember = (
  id: DimensionIdentifier | null,
  selectedSlicerValues: Record<number, number>,
  dimensionValues: DimensionValue[],
): null | number => {
  if (id === null) {
    return null;
  }

  if (id.index != null) {
    const dimensionValue = dimensionValues.find((dv) => dv.regionId === id.regionId && dv.index === id.index);
    return dimensionValue?.dimensionMemberId ?? null;
  }

  // If the dimension identifier does not contain an index then it must be a slicer
  return selectedSlicerValues[id.regionId];
};
