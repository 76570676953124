import classNames from 'classnames';
import { UnhandledIntermediateFetchResult } from 'nrosh-common/Api/ApiClient';
import { ProfitStatus, ProviderSize, ProviderType, RegistrationStatus, ReportingStatus } from 'nrosh-common/Api/Enums';
import { Address, Provider } from 'nrosh-common/Api/ProvidersApi';
import { FormEvent, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { Link, generatePath, useNavigate } from 'react-router-dom';
import { AccessibleFeedback } from '@/Components/Form/AccessibleFeedback';
import { UtcDatePicker } from '@/Components/Form/FormDatePicker';
import SelectEnumValue from '@/Components/Form/SelectEnumValue';
import { ButtonWithLoadingEffect } from '@/Components/Loading/ButtonWithLoadingEffect';
import { ProvidersApi } from '@/Helpers/Apis';
import { getValidityProps } from '@/Helpers/Forms';
import { emailRegex, noWhitespaceRegex } from '@/Helpers/Validation';
import useUnsavedChangesWarning from '@/Hooks/useUnsavedChangesWarning';
import { adminPages } from '@/Pages/Home/SitePages';
import {
  profitStatusNames,
  providerSizeNames,
  providerTypeNames,
  providerTypeOptions,
  registrationStatusOptions,
  reportingStatusNames,
} from '@/Pages/Providers/ProviderEnums';

type ProviderFormProps = {
  initialProviderDetails: Provider;
  onSave: (provider: Provider) => UnhandledIntermediateFetchResult<unknown, string>;
};

const controlClassName = classNames('mb-3', 'w-50', 'providerFormControl');

const viewProviderPath = (id: number): string =>
  generatePath(adminPages.AdminViewProvider.path, { providerId: id.toString() });

const ProviderForm = ({ initialProviderDetails, onSave }: ProviderFormProps): JSX.Element => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<Provider>(initialProviderDetails);

  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [validated, setValidated] = useState(false);

  const [rpNumberInvalid, setRpNumberInvalid] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);

  const [setDirty, setPristine, isDirty] = useUnsavedChangesWarning();

  const isEdit = !!initialProviderDetails.id;
  const submitButtonMessage = isEdit ? 'Save' : 'Add Provider';
  const disableButtonMessage = formData.isDisabled ? 'Enable' : 'Disable';
  const cancelLink = isEdit ? viewProviderPath(initialProviderDetails.id) : adminPages.AdminProviders.path;

  const updateProvider = (update: Partial<Provider>): void => {
    setFormData((prevFormData) => ({ ...prevFormData, ...update }));
    setDirty();
  };

  const updateAddress = (update: Partial<Address>): void => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      registeredAddress: { ...prevFormData.registeredAddress, ...update },
    }));
    setDirty();
  };

  const setDisabled = async (isDisabled: boolean): Promise<void> => {
    setSubmitting(true);
    setError(null);

    const response = isDisabled
      ? ProvidersApi.disableProvider(initialProviderDetails.id)
      : ProvidersApi.enableProvider(initialProviderDetails.id);
    if ((await response.justErrors(async ({ message }) => setError(message))) !== null) {
      setFormData((prevFormData) => ({ ...prevFormData, isDisabled }));
    }

    setSubmitting(false);
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    setError(null);
    event.preventDefault(); // Stops the page from reloading
    event.stopPropagation();

    if (!event.currentTarget.checkValidity()) {
      setValidated(true);
      return;
    }
    setSubmitting(true);
    const response = await onSave(formData).raw;
    if (response.ok) {
      setPristine();
      const updatedProvider = response.value as Provider;
      navigate(viewProviderPath(updatedProvider.id));
      return;
    }

    const errorMessage = response.value.message;
    setError(errorMessage);

    setSubmitting(false);
  };

  return (
    <Form noValidate onSubmit={onSubmit}>
      {error && (
        <Alert variant="danger" className="w-75">
          {error}
        </Alert>
      )}
      <Form.Group className={controlClassName} controlId="name">
        <Form.Label>Name</Form.Label>
        <Form.Control
          value={formData.name}
          onChange={(e) => updateProvider({ name: e.target.value })}
          required
          maxLength={256}
          {...getValidityProps(validated && formData.name === '', 'name-feedback')}
        />
        <AccessibleFeedback id="name-feedback" displayFeedback={validated && formData.name === ''}>
          Please provide a name
        </AccessibleFeedback>
      </Form.Group>
      <Form.Group className={controlClassName} controlId="rpNumber">
        <Form.Label>Provider Number</Form.Label>
        <Form.Control
          value={formData.providerNumber}
          onChange={(e) => {
            setRpNumberInvalid(false);
            updateProvider({ providerNumber: e.target.value });
          }}
          required
          maxLength={50}
          pattern={noWhitespaceRegex.source}
          onInvalid={() => setRpNumberInvalid(true)}
          {...getValidityProps(validated && rpNumberInvalid, 'rp-number-feedback')}
        />
        <AccessibleFeedback id="rp-number-feedback" displayFeedback={validated && rpNumberInvalid}>
          Please enter an Provider Number with all spaces removed
        </AccessibleFeedback>
      </Form.Group>
      <SelectEnumValue
        className={controlClassName}
        controlId="providerType"
        label="Provider Type"
        value={formData.type}
        // Allows the form to display "Other" if that is the existing value, for backwards compatibility, but does not
        // allow it to be selected otherwise
        options={formData.type === ProviderType.Other ? providerTypeNames : providerTypeOptions}
        onChange={(e) => updateProvider({ type: e.target.value as ProviderType })}
      />
      <SelectEnumValue
        className={controlClassName}
        controlId="reportingStatus"
        label="Reporting Status"
        value={formData.reportingStatus}
        options={reportingStatusNames}
        onChange={(e) => updateProvider({ reportingStatus: e.target.value as ReportingStatus })}
      />
      <SelectEnumValue
        className={controlClassName}
        controlId="providerSize"
        label="Size"
        value={formData.size}
        options={providerSizeNames}
        onChange={(e) => updateProvider({ size: e.target.value as ProviderSize })}
      />
      <Form.Group className={controlClassName} controlId="address_line_1">
        <Form.Label>Address - Line 1</Form.Label>
        <Form.Control
          value={formData.registeredAddress.addressLine1}
          onChange={(e) => updateAddress({ addressLine1: e.target.value })}
          required
          maxLength={256}
          {...getValidityProps(validated && formData.registeredAddress.addressLine1 === '', 'address-feedback')}
        />
        <AccessibleFeedback
          id="address-feedback"
          displayFeedback={validated && formData.registeredAddress.addressLine1 === ''}
        >
          Please provide an address
        </AccessibleFeedback>
      </Form.Group>
      <Form.Group className={controlClassName} controlId="address_line_2">
        <Form.Label>Address - Line 2</Form.Label>
        <Form.Control
          value={formData.registeredAddress.addressLine2}
          onChange={(e) => updateAddress({ addressLine2: e.target.value })}
          maxLength={256}
        />
      </Form.Group>
      <Form.Group className={controlClassName} controlId="address_line_3">
        <Form.Label>Address - Line 3</Form.Label>
        <Form.Control
          value={formData.registeredAddress.addressLine3}
          onChange={(e) => updateAddress({ addressLine3: e.target.value })}
          maxLength={256}
        />
      </Form.Group>
      <Form.Group className={controlClassName} controlId="address_line_4">
        <Form.Label>Address - Line 4</Form.Label>
        <Form.Control
          value={formData.registeredAddress.addressLine4}
          onChange={(e) => updateAddress({ addressLine4: e.target.value })}
          maxLength={256}
        />
      </Form.Group>
      <Form.Group className={controlClassName} controlId="address_postcode">
        <Form.Label>Address - Postcode</Form.Label>
        <Form.Control
          value={formData.registeredAddress.postcode}
          onChange={(e) => updateAddress({ postcode: e.target.value })}
          maxLength={256}
        />
      </Form.Group>
      <Form.Group className={controlClassName} controlId="endOfFinancialYear">
        <Form.Label>End of Financial Year</Form.Label>
        <UtcDatePicker
          value={formData.endOfFinancialYear}
          onChange={(newDate) => updateProvider({ endOfFinancialYear: newDate })}
        />
      </Form.Group>
      <SelectEnumValue
        className={controlClassName}
        controlId="registrationStatus"
        label="Registration Status"
        value={formData.registrationStatus}
        options={registrationStatusOptions}
        onChange={(e) => updateProvider({ registrationStatus: e.target.value as RegistrationStatus })}
      />
      <Form.Group className={controlClassName} controlId="registrationDate">
        <Form.Label>Registration Date</Form.Label>
        <UtcDatePicker
          value={formData.registrationDate}
          onChange={(newDate) => updateProvider({ registrationDate: newDate })}
        />
      </Form.Group>
      <Form.Group className={controlClassName} controlId="deregistrationDate">
        <Form.Label>De-registration Date</Form.Label>
        <UtcDatePicker
          value={formData.deregistrationDate}
          onChange={(newDate) => updateProvider({ deregistrationDate: newDate })}
        />
      </Form.Group>
      <SelectEnumValue
        className={controlClassName}
        controlId="profitStatus"
        label="Profit Status"
        value={formData.profitStatus}
        options={profitStatusNames}
        onChange={(e) => updateProvider({ profitStatus: e.target.value as ProfitStatus })}
      />
      <Form.Group className={controlClassName} controlId="feesInvoiceEmail">
        <Form.Label>Fees Invoice Email</Form.Label>
        <Form.Control
          value={formData.feesInvoiceEmail ?? ''}
          onChange={(e) => {
            setEmailInvalid(false);
            updateProvider({ feesInvoiceEmail: e.target.value });
          }}
          minLength={6}
          maxLength={256}
          pattern={emailRegex.source}
          onInvalid={() => setEmailInvalid(true)}
          {...getValidityProps(validated && emailInvalid, 'fees-invoice-email-feedback')}
        />
        <AccessibleFeedback id="fees-invoice-email-feedback" displayFeedback={validated && emailInvalid}>
          Please provide a valid email address
        </AccessibleFeedback>
      </Form.Group>
      <div className="mb-3 d-flex align-items-center">
        <input
          type="checkbox"
          id="isTestProviderCheckbox"
          className="me-2"
          checked={formData.isTestProvider}
          onChange={(e) => updateProvider({ isTestProvider: e.target.checked })}
        />
        <label htmlFor="isTestProviderCheckbox">Is test provider</label>
      </div>
      <div className="mt-4 d-flex gap-3 align-items-center">
        <Link to={cancelLink}>Return to Provider</Link>
        <ButtonWithLoadingEffect
          isLoading={isSubmitting}
          loadingMessage={submitButtonMessage}
          buttonProps={{ type: 'submit', disabled: !isDirty }}
        >
          {submitButtonMessage}
        </ButtonWithLoadingEffect>
        {isEdit && (
          <ButtonWithLoadingEffect
            isLoading={isSubmitting}
            loadingMessage={disableButtonMessage}
            colour="outline-primary"
            buttonProps={{
              type: 'button',
              onClick: () => {
                setDisabled(!formData.isDisabled).catch(() => {});
              },
            }}
          >
            {disableButtonMessage}
          </ButtonWithLoadingEffect>
        )}
      </div>
    </Form>
  );
};

export default ProviderForm;
