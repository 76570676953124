import { SurveyReminderResponse } from 'nrosh-common/Api/EmailsApi';
import { RshRole } from 'nrosh-common/Api/Enums';
import { SurveyStatus } from 'nrosh-common/Api/SubmissionsApi';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { useContext } from 'react';
import { Stack } from 'react-bootstrap';
import { Link, generatePath } from 'react-router-dom';
import { CellProps } from 'react-table';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import '@/Pages/Emails/EmailStyles.scss';
import Edit from '@/Components/Icons/Edit';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import Table, { NroshColumn } from '@/Components/Table/Table';
import { EmailsApi } from '@/Helpers/Apis';
import { tidyReminderPattern } from '@/Pages/Emails/EmailUtilities';
import { adminPages } from '@/Pages/Home/SitePages';

const buildColumns = (templatesAreEditable: boolean): NroshColumn<SurveyReminderResponse>[] => [
  {
    Header: 'Survey',
    accessor: 'surveyInstanceName',
  },
  {
    Header: 'Days Before Deadline',
    accessor: 'reminderDays',
    Cell: ({ value }: CellProps<SurveyReminderResponse, string | null>) =>
      value === null ? 'Not Set' : tidyReminderPattern(value, true),
  },
  {
    Header: templatesAreEditable ? 'Edit' : 'View',
    align: 'center',
    width: 100,
    disableSortBy: true,
    Cell: ({ row: { original: rowValues } }: CellProps<SurveyReminderResponse>) => (
      <Link
        to={generatePath(adminPages.AdminEditSurveyReminderEmail.path, {
          surveyId: rowValues.surveyId.toString(),
          timePeriodId: rowValues.timePeriodId.toString(),
        })}
      >
        {templatesAreEditable ? <Edit /> : 'View'}
      </Link>
    ),
  },
];

const tableRowToExportRow = (
  templateResponse: SurveyReminderResponse,
): { 'Survey': string; 'Days Before Deadline': string; 'Body': string; 'Subject': string } => ({
  'Survey': templateResponse.surveyInstanceName,
  'Days Before Deadline':
    templateResponse.reminderDays === null ? 'Not Set' : tidyReminderPattern(templateResponse.reminderDays, true),
  'Subject': templateResponse.templateSubject,
  'Body': templateResponse.templateText,
});

export const ManageSurveyRemindersPage = (): JSX.Element => {
  const auth = useContext(AuthContext);
  const [reminders] = useEndpoint<SurveyReminderResponse[]>(EmailsApi.getSurveyReminders);

  if (!reminders) {
    return <LoadingMessage />;
  }

  const openSurveyReminders = reminders
    .filter((r) => r.surveyStatus !== SurveyStatus.Closed)
    .sort((s1, s2) => s1.surveyInstanceName.localeCompare(s2.surveyInstanceName));

  const columns = buildColumns(auth.hasRole(RshRole.DesignSurveys));

  return (
    <Stack gap={5}>
      <PageHeader
        heading="Manage survey reminders"
        crumbsType={RootPathType.AdminEmails}
        crumbs={[
          {
            name: 'Manage survey reminders',
            path: adminPages.AdminManageSurveyReminderEmails.path,
          },
        ]}
      />
      <Table
        data={openSurveyReminders}
        columns={columns}
        paginated
        searchable
        exportable
        exportFileName="Survey Reminders.csv"
        tableRowToExportRow={tableRowToExportRow}
        rowHeadingIndex={0}
      />
    </Stack>
  );
};
