import { ProviderRole, Role, RshRole } from 'nrosh-common/Api/Enums';
import { Auth } from 'nrosh-common/Contexts/AuthContext';
import { SidebarIconType } from '@/Components/Sidebar/SidebarIcon';
import { adminPages, providerPages } from '@/Pages/Home/SitePages';

export type SidebarPage = {
  name: string;
  path: string;
  icon: SidebarIconType;
  roles?: Role[];
  dropdownLinks?: { name: string; path: string; roles?: Role[] }[];
};

export const getAdminSidebarPages = (
  surveys: { name: string; path: string }[] | undefined,
  auth: Auth,
): SidebarPage[] => [
  {
    name: 'Providers',
    path: adminPages.AdminProviders.path,
    icon: SidebarIconType.PROVIDERS,
    dropdownLinks: [
      {
        name: auth.hasRole(RshRole.EditProviders) ? 'Manage Providers' : 'View Providers',
        path: adminPages.AdminManageProviders.path,
      },
      {
        name: auth.hasRole(RshRole.EditProviders) ? 'Manage Provider Groups' : 'View Provider Groups',
        path: adminPages.AdminProviderGroups.path,
      },
    ],
    roles: [RshRole.ViewProviders],
  },
  {
    name: auth.hasRole(RshRole.EditUsers) ? 'User Management' : 'Users',
    path: adminPages.AdminUsers.path,
    icon: SidebarIconType.USERS,
    dropdownLinks: [
      {
        name: 'Manage User Profiles',
        path: adminPages.AdminManageUserProfiles.path,
        roles: [RshRole.ManageUserRoles],
      },
    ],
    roles: [RshRole.ViewUsers],
  },
  {
    name: 'Regulatory Documents',
    path: adminPages.RegulatoryDocuments.path,
    icon: SidebarIconType.DOCUMENTS,
    dropdownLinks: [
      {
        name: 'Upload Regulatory Documents',
        path: adminPages.UploadRegulatoryDocument.path,
        roles: [RshRole.UploadRegDocs],
      },
    ],
    roles: [RshRole.ViewRegDocs],
  },
  {
    name: 'Content Management',
    path: adminPages.AdminContent.path,
    icon: SidebarIconType.CONTENT,
    dropdownLinks: [
      { name: 'Manage News', path: adminPages.AdminNews.path },
      { name: 'Manage FAQs', path: adminPages.AdminFaqs.path },
      { name: 'Manage Editable Content', path: adminPages.AdminEditableContent.path },
      { name: 'Manage Documents', path: adminPages.AdminPublicDocuments.path },
    ],
    roles: [RshRole.ManageContent],
  },
  {
    name: 'Emails',
    path: adminPages.AdminEmails.path,
    icon: SidebarIconType.EMAILS,
    dropdownLinks: [
      {
        name: 'Manual Emails',
        path: adminPages.AdminManualEmails.path,
        roles: [RshRole.ViewSentEmails],
      },
      {
        name: 'Manage Automated Emails',
        path: adminPages.AdminAutomatedEmails.path,
        roles: [RshRole.SendEmails],
      },
      {
        name: 'Manage Survey Reminders',
        path: adminPages.AdminManageSurveyReminderEmails.path,
        roles: [RshRole.ViewSurveys],
      },
      {
        name: 'Edit Email Footer',
        path: adminPages.AdminEmailFooter.path,
        roles: [RshRole.SendEmails],
      },
    ],
    roles: [RshRole.ViewSentEmails, RshRole.ViewSurveys],
  },
  {
    name: 'Audits',
    path: adminPages.AdminAudits.path,
    icon: SidebarIconType.AUDITS,
    dropdownLinks: [
      { name: 'User Audit', path: adminPages.AdminUserAudit.path, roles: [RshRole.ViewAudit] },
      { name: 'Detailed Audit Trail', path: adminPages.AdminDetailedAuditTrail.path, roles: [RshRole.ViewAudit] },
    ],
    roles: [RshRole.ViewAudit],
  },
];

export const getProviderSidebarPages = (
  surveys: { name: string; path: string }[] | undefined,
  auth: Auth,
): SidebarPage[] => [
  {
    name: 'My Surveys',
    path: providerPages.SubmissionsList.path,
    icon: SidebarIconType.PROVIDER_SURVEYS,
    dropdownLinks: surveys,
    roles: [ProviderRole.ViewSurveyData],
  },
  {
    name: 'My Provider',
    path: providerPages.ProviderDetails.path,
    icon: SidebarIconType.PROVIDERS,
    roles: [ProviderRole.ViewProvider],
  },
  {
    name: auth.hasRole(ProviderRole.EditUsers) ? 'User Management' : 'Users',
    path: providerPages.UserManagement.path,
    icon: SidebarIconType.USERS,
    roles: [ProviderRole.ViewUsers],
  },
  {
    name: 'Regulatory Documents',
    path: providerPages.RegulatoryDocuments.path,
    icon: SidebarIconType.DOCUMENTS,
    dropdownLinks: [
      {
        name: 'Upload Regulatory Document',
        path: providerPages.UploadRegulatoryDocument.path,
        roles: [ProviderRole.UploadRegDocs],
      },
    ],
    roles: [ProviderRole.ViewRegDocs],
  },
];
