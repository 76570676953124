import { useState } from 'react';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import ArrowRight from '@/Components/Icons/ArrowRight';
import GlobalFilter from '@/Components/Table/GlobalFilter';
import '@/Components/MultiSelect/MultiSelect.scss';

type SelectOptionsProps = {
  id: string;
  name: string;
  options: {
    text: string;
    value: string;
  }[];
  selectedValues: string[];
  updateSelectedValues: (values: string[]) => void;
};

const SelectOptions = (props: SelectOptionsProps): JSX.Element => {
  const { id, name, options, selectedValues, updateSelectedValues } = props;

  const [searchTerm, setSearchTerm] = useState('');

  const filteredOptions = options.filter((o) => o.text.toLowerCase().includes(searchTerm.toLowerCase().trim()));

  if (selectedValues.some((v) => !filteredOptions.some((o) => o.value === v))) {
    updateSelectedValues(selectedValues.filter((v) => filteredOptions.some((o) => o.value === v)));
  }

  return (
    <div className="selectOptionsContainer">
      <p className="title mb-2">{name}</p>
      <GlobalFilter filter={searchTerm} setFilter={setSearchTerm} />
      <div className="optionsList px-3 pt-3 pb-2">
        {filteredOptions.map((option) => (
          <div className="mb-2 d-flex align-items-center" key={option.value}>
            <input
              type="checkbox"
              className="me-1"
              id={`${id}-${option.value}`}
              name={`${id}_checkbox`}
              value={option.value}
              checked={selectedValues.includes(option.value)}
              onChange={(e) => {
                const isSelected = e.target.checked;

                const updatedValues = isSelected
                  ? [...selectedValues, option.value]
                  : selectedValues.filter((v) => v !== option.value);
                updateSelectedValues(updatedValues);
              }}
            />
            <label className="ms-2" htmlFor={`${id}-${option.value}`}>
              {option.text}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export type MultiSelectProps = {
  name: string;
  options: {
    text: string;
    value: string;
  }[];
  selectedValues: string[];
  updateSelectedValues: (options: string[]) => void;
};

const MultiSelect = (props: MultiSelectProps): JSX.Element => {
  const { name, options, selectedValues, updateSelectedValues } = props;

  const [valuesToAdd, setValuesToAdd] = useState<string[]>([]);
  const [valuesToRemove, setValuesToRemove] = useState<string[]>([]);

  const selectedOptions = options.filter((o) => selectedValues.includes(o.value));

  const addValues = (): void => {
    const updatedValues = [...selectedValues, ...valuesToAdd];
    updateSelectedValues(Array.from(new Set(updatedValues)));
    setValuesToAdd([]);
  };

  const removeValues = (): void => {
    const updatedValues = selectedValues.filter((v) => !valuesToRemove.includes(v));
    updateSelectedValues(updatedValues);
    setValuesToRemove([]);
  };

  return (
    <div className="multiSelectContainer d-flex align-items-center">
      <SelectOptions
        id={`${name.replaceAll(' ', '_')}-multiSelectAll`}
        name={`All ${name}s`}
        options={options}
        selectedValues={valuesToAdd}
        updateSelectedValues={setValuesToAdd}
      />
      <div className="addRemoveButtons m-4">
        <PrimaryButton
          className="d-flex justify-content-center mb-3 w-100"
          disabled={valuesToAdd.length === 0}
          onClick={addValues}
        >
          <p className="m-0 pe-3 me-auto">Add</p>
          <ArrowRight />
        </PrimaryButton>
        <PrimaryButton className="removeButton d-flex" disabled={valuesToRemove.length === 0} onClick={removeValues}>
          <ArrowRight />
          <p className="m-0 ps-3">Remove</p>
        </PrimaryButton>
      </div>
      <SelectOptions
        id={`${name.replaceAll(' ', '_')}-multiSelectSelected`}
        name={`Selected ${name}s`}
        options={selectedOptions}
        selectedValues={valuesToRemove}
        updateSelectedValues={setValuesToRemove}
      />
    </div>
  );
};

export default MultiSelect;
