import ExportButton from '@/Components/ExportButton/ExportButton';

type ExportAuditLogsProps = {
  numberOfResults: number;
  numberOfResultsReturned: number;
  onDownload: () => Promise<void>;
};

const ExportAuditLogs = (props: ExportAuditLogsProps): JSX.Element => {
  const { numberOfResults, numberOfResultsReturned, onDownload } = props;

  return (
    <div className="d-flex flex-row justify-content-between my-3 gap-3">
      <div className="align-self-end">
        {numberOfResults > numberOfResultsReturned
          ? `Showing most recent ${numberOfResultsReturned} of ${numberOfResults} total entries (to see up to 100,000 results, click 'Export Filtered List')`
          : `Showing ${numberOfResultsReturned} of ${numberOfResults} total entries`}
      </div>
      <ExportButton label="Export Filtered List" onDownload={onDownload} />
    </div>
  );
};

export default ExportAuditLogs;
