import classNames from 'classnames';
import { Col, Container, Row } from 'react-bootstrap';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import {
  Column,
  FilterValue,
  SortingRule,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table';
import '@/Components/Table/Types/react-table-config.d.ts';
import { TertiaryButton } from '@/Components/Buttons/DCSButton';
import Download from '@/Components/Icons/Download';
import Pagination from '@/Components/Pagination/Pagination';
import { combinedCellProps, combinedHeaderProps } from '@/Components/Table/CustomProps';
import GlobalFilter from '@/Components/Table/GlobalFilter';
import '@/Components/Table/Table.scss';
import { exportTableData } from '@/Components/Table/exportTable';

export enum CheckboxValues {
  Checked,
  Unchecked,
  Indeterminate,
}

export type Alignment = 'inherit' | 'left' | 'center' | 'right' | 'justify';

export type NroshColumn<T extends object> = Column<T> & {
  align?: Alignment;
};

const SortedIcon = ({ isDescending }: { isDescending: boolean }): JSX.Element =>
  isDescending ? <FaSortDown /> : <FaSortUp />;

type TableProps<T extends object> = {
  editable?: boolean;
  data: Array<T>;
  columns: Column<T>[];
  searchable?: boolean;
  paginated?: boolean;
  message?: string;
  hiddenColumns?: string[];
  placeholderSearchText?: string;
  defaultSort?: SortingRule<T>[];
  autoResetPage?: boolean;
  skipPageReset?: boolean;
  disableSkipPageReset?: () => void;
  className?: string;
  rowHeadingIndex?: number;
  exportable: boolean;
  tableRowToExportRow?: (dataRow: T) => {
    [key: string]: string | number | null | undefined;
  };
  exportFileName?: string;
  exportButtonText?: string;
};

const Table: <T extends object>(props: TableProps<T>) => JSX.Element = ({
  data,
  columns,
  searchable,
  paginated,
  message = 'No items to display.',
  hiddenColumns,
  placeholderSearchText,
  defaultSort = [],
  autoResetPage = true,
  skipPageReset,
  disableSkipPageReset,
  className,
  exportable = false,
  tableRowToExportRow,
  exportFileName,
  exportButtonText,
  rowHeadingIndex,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    gotoPage,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    state: { pageIndex, globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      autoResetPage: paginated ? autoResetPage && !skipPageReset : false,
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
      initialState: {
        hiddenColumns: hiddenColumns || [],
        sortBy: defaultSort,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useFlexLayout,
    useResizeColumns,
  );

  const onFilterChange = (f: FilterValue): void => {
    if (disableSkipPageReset !== undefined) {
      disableSkipPageReset();
    }
    setGlobalFilter(f);
  };

  const tableRows = paginated ? page : rows;

  type AriaSort = 'none' | 'ascending' | 'descending';

  const getAriaSort = (isSorted: boolean, isSortedDesc: boolean | undefined): AriaSort => {
    if (!isSorted) {
      return 'none';
    }
    return isSortedDesc ? 'descending' : 'ascending';
  };

  return (
    <>
      <div className={classNames('dataTable', className)}>
        {searchable && (
          <GlobalFilter
            filter={globalFilter ? String(globalFilter) : ''}
            setFilter={onFilterChange}
            placeholderText={placeholderSearchText}
          />
        )}
        <table {...getTableProps()}>
          <caption className="visually-hidden">Column headers with buttons are sortable</caption>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(combinedHeaderProps)}
                    className="d-flex align-items-center justify-content-between"
                    aria-sort={getAriaSort(column.isSorted, column.isSortedDesc)}
                  >
                    {!column.disableSortBy ? (
                      <button type="button" className="sortedHeader">
                        <span className="tableHeader">{column.render('Header')}</span>
                        <div>{column.isSorted ? <SortedIcon isDescending={!!column.isSortedDesc} /> : <FaSort />}</div>
                      </button>
                    ) : (
                      <span className="tableHeader">{column.render('Header')}</span>
                    )}
                    {column.canResize && (
                      <button
                        type="button"
                        {...column.getResizerProps()}
                        className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      />
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {tableRows.length > 0 ? (
              tableRows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, index) =>
                      index === rowHeadingIndex ? (
                        <th {...cell.getCellProps(combinedCellProps)} scope="row">
                          <span className="tableCell">{cell.render('Cell')}</span>
                        </th>
                      ) : (
                        <td {...cell.getCellProps(combinedCellProps)}>
                          <span className="tableCell">{cell.render('Cell')}</span>
                        </td>
                      ),
                    )}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="noTableItems">{message}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {(exportable || paginated) && (
        <Container fluid>
          <Row className={classNames('d-flex justify-content-center align-items-center', !paginated && 'mt-2')}>
            {exportable && <Col xs={2} />}
            <Col>
              {paginated && (
                <Pagination
                  pageOptions={pageOptions}
                  pageIndex={pageIndex}
                  gotoPage={gotoPage}
                  nextPage={nextPage}
                  canNextPage={canNextPage}
                  previousPage={previousPage}
                  canPreviousPage={canPreviousPage}
                />
              )}
            </Col>
            {exportable && tableRowToExportRow && (
              <div className="mt-3 p-0">
                <TertiaryButton
                  colour="primary"
                  icon={<Download />}
                  onClick={() => {
                    exportTableData(data, tableRowToExportRow, exportFileName);
                  }}
                  disabled={data.length === 0}
                >
                  {exportButtonText || 'Export'}
                </TertiaryButton>
              </div>
            )}
          </Row>
        </Container>
      )}
    </>
  );
};

export default Table;
