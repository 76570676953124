import { AccountsResponse } from 'nrosh-common/Api/AccountApi';
import { APIResponse } from 'nrosh-common/Api/ApiClient';
import { ProviderSummary } from 'nrosh-common/Api/ProvidersApi';
import { User, UserStatus } from 'nrosh-common/Api/UsersApi';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import { useContext, useState } from 'react';
import { Alert, Stack } from 'react-bootstrap';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { UsersApi, generateResponseErrorMessage } from '@/Helpers/Apis';
import UserForm from '@/Pages/Users/UserForm';

type EditUserProps = {
  isAdmin: boolean;
  providerDetails: ProviderSummary | null;
  user: User;
  triggerDataRefresh: () => void;
  cancelPath?: boolean;
};

export const EditUser = ({
  isAdmin,
  providerDetails,
  user,
  triggerDataRefresh,
  cancelPath,
}: EditUserProps): JSX.Element => {
  const auth = useContext(AuthContext);

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setSubmitting] = useState(false);

  const handleResponse = async (response: APIResponse<unknown, AccountsResponse>): Promise<void> => {
    if (response.ok) {
      setSuccess(true);
    } else {
      const unknownErrorMessage = 'An unexpected problem has occurred.';
      try {
        const body = response.value;
        const errorMessage = generateResponseErrorMessage(
          body,
          ({ error: localError }) => localError ?? null,
          unknownErrorMessage,
        );
        setError(errorMessage);
      } catch {
        setError(unknownErrorMessage);
      }
    }

    setSubmitting(false);
  };

  const sendResetPasswordEmail = async (): Promise<void> => {
    setSubmitting(true);
    setError(null);

    const response = await UsersApi.sendPasswordResetEmail(user.id).raw;
    await handleResponse(response);
  };

  const resendActivationEmail = async (): Promise<void> => {
    setSubmitting(true);
    setError(null);

    const response = await UsersApi.resendActivationEmail(user.id).raw;
    await handleResponse(response);
  };

  const isLoggedInUser = user.userName === auth.user?.userName;

  return (
    <>
      <UserForm
        user={user}
        isAdmin={isAdmin}
        providerId={auth.user?.providerId}
        triggerDataRefresh={() => {
          triggerDataRefresh();
          setSuccess(false);
          setError(null);
        }}
        userProfileType={user.profile?.type}
        providerDetails={providerDetails}
        cancelPath={cancelPath}
      />
      {!isLoggedInUser && user.status === UserStatus.Active && (
        <Stack gap={3}>
          <h1>Reset Password</h1>
          {success && (
            <Alert variant="success" className="w-75" dismissible onClose={() => setSuccess(false)}>
              An email has been sent to this user&#39;s email address
            </Alert>
          )}
          {error && (
            <Alert variant="danger" className="w-75">
              {error}
            </Alert>
          )}
          <p>Send a password reset email to the email registered with this user</p>
          {!isSubmitting ? (
            <PrimaryButton type="button" onClick={sendResetPasswordEmail}>
              Reset Password
            </PrimaryButton>
          ) : (
            <LoadingButton message="Reset Password" />
          )}
        </Stack>
      )}
      {!isLoggedInUser && user.status === UserStatus.AwaitingEmailConfirmation && (
        <Stack gap={3}>
          <h1>Resend Email Address Confirmation</h1>
          {success && (
            <Alert variant="success" className="w-75" dismissible onClose={() => setSuccess(false)}>
              An email address confirmation link has been sent to this user&#39;s email address.
            </Alert>
          )}
          {error && (
            <Alert variant="danger" className="w-75">
              {error}
            </Alert>
          )}
          <p>
            This account is waiting for the user to confirm their email address. Click the button below to resend an
            email address confirmation link to this user&#39;s email address.
          </p>
          {!isSubmitting ? (
            <PrimaryButton type="button" onClick={resendActivationEmail}>
              Re-send Email
            </PrimaryButton>
          ) : (
            <LoadingButton message="Re-send Email" />
          )}
        </Stack>
      )}
      {!isLoggedInUser &&
        (user.status === UserStatus.AwaitingActivation || user.status === UserStatus.AwaitingReactivation) && (
          <Stack gap={3}>
            <h1>Resend Activation Email</h1>
            {success && (
              <Alert variant="success" className="w-75" dismissible onClose={() => setSuccess(false)}>
                An account activation email has been sent to this user&#39;s email address
              </Alert>
            )}
            {error && (
              <Alert variant="danger" className="w-75">
                {error}
              </Alert>
            )}
            <p>
              This account is pending activation. Click the button below to resend an activation link to this user&#39;s
              email address.
            </p>
            {!isSubmitting ? (
              <PrimaryButton type="button" onClick={resendActivationEmail}>
                Re-send Email
              </PrimaryButton>
            ) : (
              <LoadingButton message="Re-send Email" />
            )}
          </Stack>
        )}
    </>
  );
};
