import { ReactNode } from 'react';
import { ButtonProps } from 'react-bootstrap';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import { LoadingButton } from '@/Components/Loading/LoadingButton';

type ButtonWithLoadingEffectCoreProps = {
  isLoading: boolean;
  loadingMessage: string;
  colour?: string;
  className?: string;
  children: ReactNode;
  buttonProps: ButtonProps;
};

export const ButtonWithLoadingEffect = ({
  isLoading,
  loadingMessage,
  colour,
  className,
  children,
  buttonProps,
}: ButtonWithLoadingEffectCoreProps): JSX.Element =>
  isLoading ? (
    <LoadingButton message={loadingMessage} colour={colour} className={className} />
  ) : (
    <PrimaryButton {...buttonProps} colour={colour ?? 'primary'} className={className}>
      {children}
    </PrimaryButton>
  );
