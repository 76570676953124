import { RshRole } from 'nrosh-common/Api/Enums';
import { ProviderUsers, User, UserStatus } from 'nrosh-common/Api/UsersApi';
import AuthContext, { Auth } from 'nrosh-common/Contexts/AuthContext';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { useContext, useState } from 'react';
import { Stack } from 'react-bootstrap';
import { Link, generatePath, useParams } from 'react-router-dom';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import Edit from '@/Components/Icons/Edit';
import LinkButton from '@/Components/Links/LinkButton';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import Table, { NroshColumn } from '@/Components/Table/Table';
import { UsersApi } from '@/Helpers/Apis';
import { TimeResolution, formatISODateString } from '@/Helpers/DateHelpers';
import { providerDisplayName } from '@/Helpers/ProviderHelper';
import { adminPages } from '@/Pages/Home/SitePages';
import '@/Pages/Users/AdminUserManagementPage.scss';
import { UserStatusNameMapping } from '@/Pages/Users/UserStatusNameMapping';
import { tableRowToExportRow } from '@/Pages/Users/UsersHelper';

const getUserTableColumns = (auth: Auth): NroshColumn<User>[] => {
  const editColumn: NroshColumn<User>[] = [
    {
      Header: 'Edit',
      accessor: 'id',
      align: 'center' as const,
      disableSortBy: true,
      disableResizing: true,
      width: 100,
      Cell: ({ value, row }) =>
        row.original.status !== UserStatus.Deleted && (
          <Link
            to={generatePath(adminPages.AdminProviderEditUser.path, { userId: value.toString() })}
            className="editUserLink d-flex align-items-center justify-content-center"
          >
            <Edit />
          </Link>
        ),
    },
  ];

  return [
    {
      Header: 'User',
      accessor: 'name',
      Cell: ({ row }) => (
        <div>
          {row.original.name}
          {row.original.preferredFormOfAddress && ` (${row.original.preferredFormOfAddress})`}
        </div>
      ),
    },
    {
      Header: 'Username',
      accessor: 'userName',
    },
    {
      Header: 'User Profile',
      accessor: 'profile',
      Cell: ({ value }) => value && value.name,
    },
    {
      Header: 'Email Address',
      accessor: 'emailAddress',
      Cell: ({ value }) => (value.includes('@') ? <a href={`mailto:${value}`}>{value}</a> : value),
    },
    {
      Header: 'User Status',
      accessor: 'status',
      Cell: ({ value }) => UserStatusNameMapping[value],
    },
    {
      Header: 'Last Successful Login',
      accessor: 'lastSuccessfulLogin',
      Cell: ({ value }) => formatISODateString(value, TimeResolution.Second),
    },
    ...(auth.hasRole(RshRole.EditUsers) ? editColumn : []),
  ];
};

export const AdminProviderUserManagementPage = (): JSX.Element => {
  const { providerId } = useParams();
  const auth = useContext(AuthContext);

  const [provider] = useEndpoint<ProviderUsers>(UsersApi.getUsersForProvider, providerId);
  const [showDeletedUsers, setShowDeletedUsers] = useState(false);

  if (!provider) {
    return <LoadingMessage />;
  }

  const filteredUsers = showDeletedUsers
    ? provider.users
    : provider.users.filter((u) => u.status !== UserStatus.Deleted);

  const pageHeading = `${auth.hasRole(RshRole.EditUsers) ? 'User management' : 'Users'}`;

  return (
    <Stack gap={5} className="adminProviderUserManagementContainer">
      <PageHeader
        heading={pageHeading}
        subheading={`${providerDisplayName(provider.name, provider.providerNumber)}`}
        crumbsType={RootPathType.AdminProviders}
        crumbs={[
          {
            name: 'Organisational information',
            path: generatePath(adminPages.AdminViewProvider.path, { providerId }),
          },
          {
            name: provider.name,
            path: adminPages.AdminProviderUsers.path,
          },
        ]}
      />
      {auth.hasRole(RshRole.EditUsers) && (
        <LinkButton to={generatePath(adminPages.AdminProviderAddUser.path, { providerId: provider.id?.toString() })}>
          Add User
        </LinkButton>
      )}
      <Stack gap={4}>
        <h2>All Users</h2>
        <Stack gap={3}>
          <label htmlFor="showDeletedUsers" className="d-flex align-items-center">
            <input
              type="checkbox"
              id="showDeletedUsers"
              className="me-2"
              checked={showDeletedUsers}
              onChange={(e) => setShowDeletedUsers(e.target.checked)}
            />
            Show deleted users
          </label>
          <Table
            data={filteredUsers}
            columns={getUserTableColumns(auth)}
            searchable
            paginated
            defaultSort={[{ id: 'name', desc: false }]}
            exportable
            tableRowToExportRow={tableRowToExportRow}
            exportFileName={`${provider.name} Users.csv`}
            rowHeadingIndex={0}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
