export const groupBy = <T, TKey extends string | number>(
  sourceArray: T[],
  getKey: (source: T) => TKey,
): Record<TKey, T[]> => {
  const output = {} as Record<TKey, T[]>;
  for (const item of sourceArray) {
    const key = getKey(item);
    if (output[key]) {
      output[key].push(item);
    } else {
      output[key] = [item];
    }
  }
  return output;
};

export const distinctBy = <T, TKey extends string | number>(sourceArray: T[], getKey: (source: T) => TKey) => {
  return sourceArray
    .map((value) => getKey(value))
    .map((keyValue, index, keyValues) => (keyValues.indexOf(keyValue) === index ? index : -1))
    .filter((index) => index !== -1)
    .map((index) => sourceArray[index]);
};
