import { useCallback, useState } from 'react';
import { usePrompt } from '@/ThirdPartyCode/usePrompt';

const useUnsavedChangesWarning = (
  message = 'Are you sure you want to discard changes?',
): readonly [() => void, () => void, boolean] => {
  const [isDirty, setIsDirty] = useState(false);
  const setDirty = useCallback(() => setIsDirty(true), []);
  const setPristine = useCallback(() => setIsDirty(false), []);

  usePrompt(message, isDirty);

  return [setDirty, setPristine, isDirty] as const;
};

export default useUnsavedChangesWarning;
