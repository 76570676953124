import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { Col, InputGroup } from 'react-bootstrap';
import { RiCalendar2Line, RiTimeLine } from 'react-icons/ri';
import DateSelector from '@/Components/DateTime/DateSelector';
import TimeSelector from '@/Components/DateTime/TimeSelector';

type DateTimePickerProps = {
  dateTime: Date | null;
  setDateTime: ReactStateSetter<Date | null>;
  allowPastDate: boolean;
  dateDisabled: boolean;
  timeDisabled: boolean;
  showTime?: boolean;
  ariaLabel?: string;
};

const DateTimePicker = ({
  dateTime,
  setDateTime,
  allowPastDate,
  dateDisabled,
  timeDisabled,
  showTime = true,
  ariaLabel,
}: DateTimePickerProps): JSX.Element => (
  <Col sm="auto">
    <InputGroup>
      <InputGroup.Text>
        <RiCalendar2Line />
      </InputGroup.Text>
      <DateSelector
        dateTime={dateTime}
        setDateTime={setDateTime}
        disabled={dateDisabled}
        allowPast={allowPastDate}
        ariaLabel={ariaLabel}
      />
      {showTime && (
        <>
          <InputGroup.Text>
            <RiTimeLine />
          </InputGroup.Text>
          <TimeSelector dateTime={dateTime} setDateTime={setDateTime} disabled={timeDisabled} allowPast />
        </>
      )}
    </InputGroup>
  </Col>
);

export default DateTimePicker;
