import { ChevronDown24Regular } from '@fluentui/react-icons';

type DropdownArrowProps = {
  direction: 'up' | 'down';
};

const DropdownArrow = ({ direction }: DropdownArrowProps): JSX.Element => {
  const transform = direction === 'up' ? 'rotate(180deg)' : '';
  return <ChevronDown24Regular style={{ transform }} />;
};

export default DropdownArrow;
