import ApiClient, { APIHelpers, handleAPIRequest } from './ApiClient';
import { Tag } from './CommonTypes';

export type Article = {
  id: string;
  name: string;
  content: string;
  publishDateTime: string;
  tags: Tag[];
};

export type ArticleRequest = {
  title: string;
  content: string;
  publishDate?: Date | null;
  tagIds: number[];
};

export type NewArticleResponse = {
  articleId: number;
};
const NewsApiRoot = 'News';
const NewsApiPaths = {
  NEWS_ARTICLES: `${NewsApiRoot}/newsArticles`,
  NEWS_ARTICLE: (articleId: string) => `${NewsApiRoot}/newsArticles/${articleId}`,
  CREATE_NEWS_ARTICLE: `${NewsApiRoot}/newsArticles/create`,
};

const newsApi = (apiClient: ApiClient) => ({
  getNewsArticles: () => {
    return handleAPIRequest(
      apiClient.get(NewsApiPaths.NEWS_ARTICLES),
      APIHelpers.json<Article[]>,
      APIHelpers.standardError,
    );
  },

  getNewsArticle: (articleId: string) => {
    return handleAPIRequest(
      apiClient.get(NewsApiPaths.NEWS_ARTICLE(articleId)),
      APIHelpers.json<Article>,
      APIHelpers.standardError,
    );
  },

  editNewsArticle: (articleId: string, editArticleRequest: ArticleRequest) => {
    return handleAPIRequest(
      apiClient.patch(NewsApiPaths.NEWS_ARTICLE(articleId), editArticleRequest),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  deleteNewsArticle: (articleId: string) => {
    return handleAPIRequest(
      apiClient.deleteAction(NewsApiPaths.NEWS_ARTICLE(articleId)),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  createNewsArticle: (createArticleRequest: ArticleRequest) => {
    return handleAPIRequest(
      apiClient.post(NewsApiPaths.CREATE_NEWS_ARTICLE, createArticleRequest),
      APIHelpers.json<NewArticleResponse>,
      APIHelpers.standardError,
    );
  },
});

export default newsApi;
