import { Faq, FaqRequest } from 'nrosh-common/Api/FaqsApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { Stack } from 'react-bootstrap';
import { generatePath, useParams } from 'react-router-dom';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { FaqsApi } from '@/Helpers/Apis';
import FaqTabContainer from '@/Pages/Content/ManageFaqs/FaqTabContainer';
import { adminPages } from '@/Pages/Home/SitePages';

const EditFaqPage = (): JSX.Element => {
  const { faqId } = useParams();
  const [faq] = useEndpoint<Faq>(FaqsApi.getFaq, faqId);

  const sendEditFaqRequest = async (
    faqRequest: FaqRequest,
    unpublish?: boolean,
  ): Promise<{
    success: boolean;
    redirectPath?: string;
    errorMessage?: string;
  }> => {
    const response = await FaqsApi.editFaq(faqId!, faqRequest).raw;
    if (response.ok) {
      const redirectPath = unpublish ? generatePath(adminPages.AdminFaqs.path) : undefined;
      return { success: true, redirectPath };
    }
    const errorMessage = response.value.message;
    return { success: false, errorMessage };
  };

  if (!faq) {
    return <LoadingMessage />;
  }

  return (
    <Stack gap={5}>
      <PageHeader
        heading="Edit FAQ"
        crumbsType={RootPathType.AdminContent}
        crumbs={[
          {
            name: 'Manage FAQs',
            path: adminPages.AdminFaqs.path,
          },
          { name: 'Edit FAQ', path: adminPages.AdminFaqsCreate.path },
        ]}
      />
      <FaqTabContainer faq={faq} sendFaqRequest={sendEditFaqRequest} />
    </Stack>
  );
};

export default EditFaqPage;
