import { SurveyChecks, SurveyDeclaration } from 'nrosh-common/Api/SurveysApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import '@/Pages/Submissions/Confirmation/ConfirmDeclarationsPage.scss';
import { useState } from 'react';
import { Alert, Stack } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { LoadingSpinner } from '@/Components/Loading/LoadingSpinner';
import SubmissionPageHeader from '@/Components/PageHeader/SubmissionPageHeader';
import { SubmissionsApi, generateResponseErrorMessage } from '@/Helpers/Apis';
import { surveyDisplayName } from '@/Helpers/SurveyHelper';
import useSubmissionDetail from '@/Hooks/useSubmissionDetail';
import { providerPages, submissionPages } from '@/Pages/Home/SitePages';
import {
  ConfirmSubmissionPage,
  useNextAndPreviousConfirmPath,
} from '@/Pages/Submissions/Confirmation/ConfirmSubmissionRouteHelpers';
import SubmissionBackButton from '@/Pages/Submissions/Confirmation/SubmissionBackButton';
import { SubmissionConfirmation } from '@/Pages/Submissions/Confirmation/SubmissionConfirmation';
import SubmissionConfirmationCheckbox from '@/Pages/Submissions/Confirmation/SubmissionConfirmationCheckbox';

type ConfirmDeclarationsFormProps = {
  declarations: SurveyDeclaration[] | undefined;
  isAdmin: boolean;
};

const buildFalseArrayOfLength = (length: number): boolean[] => new Array<boolean>(length).fill(false);

const ConfirmDeclarationsForm = ({ declarations, isAdmin }: ConfirmDeclarationsFormProps): JSX.Element => {
  const [customDeclarationsChecked, setCustomDeclarationsChecked] = useState(
    buildFalseArrayOfLength(declarations?.length ?? 0),
  );
  const [finalDeclarationChecked, setFinalDeclarationChecked] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { submissionId } = useParams();
  const submissionConfirmationState = location.state as SubmissionConfirmation;

  const { previousPage, nextPage } = useNextAndPreviousConfirmPath(ConfirmSubmissionPage.Declarations, isAdmin);

  const updateStateAtIndex = (index: number, value: boolean): void =>
    setCustomDeclarationsChecked((arr) => {
      const clonedArray = [...arr];
      clonedArray[index] = value;
      return clonedArray;
    });

  const shouldAllowSubmission = customDeclarationsChecked.every(Boolean) && finalDeclarationChecked;

  const onSubmit = async (): Promise<void> => {
    setError(null);
    setSubmitting(true);
    const response = await SubmissionsApi.submit(submissionId!).raw;

    if (response.ok) {
      navigate(nextPage);
    } else {
      const unknownErrorMessage = 'Error submitting survey';

      try {
        const body = response.value;
        const errorMessage = generateResponseErrorMessage(body, (d) => d.error, unknownErrorMessage);
        setError(errorMessage);
      } catch {
        setError(unknownErrorMessage);
      }

      setSubmitting(false);
    }
  };

  return (
    <>
      <p>
        Please complete the disclosure statement below. If this submission is successful, you will no longer be able to
        change any data.
      </p>
      {!!declarations?.length && (
        <div className="mb-3">
          <p>Before you submit your data please confirm that you have:</p>
          {declarations.map((declaration, index) => (
            <SubmissionConfirmationCheckbox
              key={declaration.declaration}
              checked={customDeclarationsChecked[index]}
              id={declaration.declaration}
              label={declaration.declaration}
              onChange={(e) => updateStateAtIndex(index, e.target.checked)}
            />
          ))}
        </div>
      )}
      <div className="mb-4">
        <SubmissionConfirmationCheckbox
          checked={finalDeclarationChecked}
          id="final-declaration"
          label="I certify that:"
          onChange={(e) => setFinalDeclarationChecked(e.target.checked)}
        />
        <ul className="ms-6 finalDeclarationBulletPoints">
          <li>To the best of my knowledge, the information provided in this return is accurate;</li>
        </ul>
      </div>

      {shouldAllowSubmission ? (
        <>
          <p>
            To submit your data, please click on the Submit option below. If this submission is successful, you will no
            longer be able to import or edit data in this survey.
          </p>
          <p>
            Should you need to make changes after you have submitted the return, please contact the DCS enquiries team
            on&nbsp;
            <a href="mailto:Team-DCS@softwire.com">Team-DCS@softwire.com</a>
          </p>
          {error && (
            <Alert variant="danger" className="w-75">
              {error}
            </Alert>
          )}
          <div className="d-flex gap-2">
            <SubmissionBackButton
              previousPage={previousPage}
              submissionConfirmationState={{
                detailsCorrect: submissionConfirmationState.detailsCorrect,
                updateRequestSent: submissionConfirmationState.updateRequestSent,
                feesInvoiceEmailConfirmed: submissionConfirmationState.feesInvoiceEmailConfirmed,
                organisationalDetailsConfirmed: submissionConfirmationState.organisationalDetailsConfirmed,
              }}
            />
            {isSubmitting ? (
              <LoadingButton message="Submit" />
            ) : (
              <PrimaryButton onClick={() => onSubmit()}>Submit</PrimaryButton>
            )}
          </div>
        </>
      ) : (
        <SubmissionBackButton
          previousPage={previousPage}
          submissionConfirmationState={{
            detailsCorrect: submissionConfirmationState.detailsCorrect,
            updateRequestSent: submissionConfirmationState.updateRequestSent,
            feesInvoiceEmailConfirmed: submissionConfirmationState.feesInvoiceEmailConfirmed,
            organisationalDetailsConfirmed: submissionConfirmationState.organisationalDetailsConfirmed,
          }}
        />
      )}
    </>
  );
};

const ConfirmDeclarationsPage = ({ isAdmin = false }: { isAdmin?: boolean }): JSX.Element => {
  const { submissionId } = useParams();
  const [data] = useSubmissionDetail(submissionId!, isAdmin);
  const [checks] = useEndpoint<SurveyChecks>(SubmissionsApi.getEndOfSurveyChecks, submissionId);

  if (!checks || (isAdmin && !data)) {
    return <LoadingSpinner />;
  }

  const declarations = checks.surveyDeclarations?.sort((d) => d.orderIndex);

  return (
    <Stack gap={5}>
      <SubmissionPageHeader
        heading="Declarations"
        isRshUser={isAdmin}
        surveyDisplayName={surveyDisplayName(checks.surveyName, checks.timePeriodName)}
        providerName={data?.provider.name ?? ''}
        additionalRshCrumbs={[
          {
            name: 'Confirm declarations',
            path: submissionPages.AdminConfirmDeclarations.path,
          },
        ]}
        additionalProviderCrumbs={[
          {
            name: 'Confirm declarations',
            path: providerPages.ConfirmDeclarations.path,
          },
        ]}
      />
      <ConfirmDeclarationsForm declarations={declarations} isAdmin={isAdmin} />
    </Stack>
  );
};

export default ConfirmDeclarationsPage;
