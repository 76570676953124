import { RefObject, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const useFocusOnPathChange = (): RefObject<HTMLHeadingElement> => {
  const location = useLocation();
  const focusRef = useRef<HTMLHeadingElement>(null);
  useEffect(() => {
    focusRef.current?.focus();
  }, [location.pathname]);
  return focusRef;
};

export default useFocusOnPathChange;
