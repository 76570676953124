import { saveAs } from 'file-saver';
import { NamedFile } from 'nrosh-common/Api/ApiClient';
import { useState } from 'react';
import { TertiaryButton } from '@/Components/Buttons/DCSButton';
import { useErrorReporting } from '@/Components/Errors/DCSErrorBoundary';
import Download from '@/Components/Icons/Download';
import { LoadingButton } from '@/Components/Loading/LoadingButton';

type ExportSupportingDocumentsButtonProps = {
  numFiles: number;
  buttonText: string;
  endpointCall: () => Promise<NamedFile>;
};

export const ExportDocumentsButton = ({
  numFiles,
  buttonText,
  endpointCall,
}: ExportSupportingDocumentsButtonProps): JSX.Element => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [raiseError] = useErrorReporting();

  const triggerAllDocumentDownload = async (): Promise<void> => {
    setIsDownloading(true);
    try {
      const { content, fileName } = await endpointCall();
      saveAs(URL.createObjectURL(content), fileName);
    } catch {
      raiseError();
    } finally {
      setIsDownloading(false);
    }
  };

  return isDownloading ? (
    <LoadingButton message={buttonText} />
  ) : (
    <TertiaryButton disabled={numFiles === 0} onClick={() => triggerAllDocumentDownload()} icon={<Download />}>
      {buttonText}
    </TertiaryButton>
  );
};
