import classNames from 'classnames';

type ValidityProps = {
  'isInvalid': boolean;
  'aria-invalid': boolean;
  'aria-describedby': string;
};

type ValidatedPropertyArray<T> = T & {
  validated: boolean;
};

export const getValidityProps = (
  isInvalid: boolean,
  feedbackId: string | undefined = undefined,
  additionalDescribedBy: string | undefined = undefined,
): ValidityProps => ({
  'isInvalid': isInvalid,
  'aria-invalid': isInvalid,
  'aria-describedby': classNames(isInvalid ? feedbackId : undefined, additionalDescribedBy),
});

export const addValidatedPropertyToArray = <T>(array: T[], validated: boolean): ValidatedPropertyArray<T>[] =>
  array.map((x) => ({ ...x, validated }));
