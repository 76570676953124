import ApiClient, { APIHelpers, handleAPIRequest } from './ApiClient';
import { Tag } from './CommonTypes';

export type UnauthenticatedNewsArticle = {
  id: string;
  name: string;
  content: string;
  publishDateTime: string;
  tags: Tag[];
};

export type GetUnauthenticatedNewsArticleResponse = {
  id: string;
  name: string;
  content: string;
  publishDateTime: string;
  relatedArticles: RelatedArticle[];
};

export type RelatedArticle = {
  id: string;
  name: string;
};

const UnauthenticatedNewsApiRoot = 'UnauthenticatedNews';
const UnauthenticatedNewsApiPaths = {
  NEWS_ARTICLE: (articleId: string) => `${UnauthenticatedNewsApiRoot}/newsArticles/${articleId}`,
  PUBLISHED_NEWS_ARTICLES: `${UnauthenticatedNewsApiRoot}/newsArticles`,
  RELATED_NEWS_ARTICLES: (articleId: string) => `${UnauthenticatedNewsApiRoot}/${articleId}/RelatedArticles`,
};

const unauthenticatedNewsApi = (apiClient: ApiClient) => ({
  getNewsArticle: (articleId: string) => {
    return handleAPIRequest(
      apiClient.get(UnauthenticatedNewsApiPaths.NEWS_ARTICLE(articleId)),
      APIHelpers.json<GetUnauthenticatedNewsArticleResponse>,
      APIHelpers.standardError,
    );
  },

  getPublishedNewsArticles: () => {
    return handleAPIRequest(
      apiClient.get(UnauthenticatedNewsApiPaths.PUBLISHED_NEWS_ARTICLES),
      APIHelpers.json<UnauthenticatedNewsArticle[]>,
      APIHelpers.standardError,
    );
  },
  //TODO-376: Doesn't exist on backend double check and delete?
  getRelatedNewsArticles: (articleId: string) => {
    return handleAPIRequest(
      apiClient.get(UnauthenticatedNewsApiPaths.RELATED_NEWS_ARTICLES(articleId)),
      APIHelpers.json<never>,
      APIHelpers.standardError,
    );
  },
});

export default unauthenticatedNewsApi;
