import classNames from 'classnames';
import '@/Components/Loading/LoadingButton.css';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';

export type LoadingButtonProps = {
  message: string;
  colour?: string;
  className?: string;
};

export const LoadingButton = (props: LoadingButtonProps): JSX.Element => {
  const { message, colour = 'primary', className = '' } = props;

  return (
    <PrimaryButton colour={colour} className={classNames('addSaveButton', className)} type="button" disabled>
      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
      <span className="sr-only loading-button-message">{message}</span>
    </PrimaryButton>
  );
};
