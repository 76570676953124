import { ListType } from 'nrosh-common/Api/SubmissionsApi';
import { ChangeEvent } from 'react';
import { InputCellProps, baseCellProps } from '@/Components/Spreadsheet/Cells/CellHelpers';
import { DataPointCellWithOverlay } from '@/Components/Spreadsheet/Cells/DataPointCell';

export type ListInputCellProps = InputCellProps & {
  listOptions: ListType | undefined;
};

const ListInputCell = (props: ListInputCellProps): JSX.Element => {
  const {
    cellStyle,
    cellKey,
    isActive,
    setActiveCell,
    value,
    dimension1Id,
    dimension2Id,
    updateDataPoint,
    readonly,
    listOptions,
  } = props;
  const { dataPoint } = cellStyle;
  const inputId = `data-input-${cellKey}`;

  const onListInputChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    const selectedValue = event.target.value;
    updateDataPoint(dataPoint.dataPointId, selectedValue, dimension1Id, dimension2Id);
  };

  const getMatchingValueFromOptions = (input: string): string => {
    if (!listOptions) {
      return input;
    }
    return listOptions.options.find((lo) => lo.toLowerCase() === input.toLowerCase()) ?? input;
  };

  return (
    <DataPointCellWithOverlay
      {...baseCellProps(props)}
      dataPointId={dataPoint.dataPointId}
      cellKey={cellKey}
      active={isActive}
      setActiveCell={setActiveCell}
      inputId={inputId}
      helpText={cellStyle.helpText}
      overlayPosition="top"
    >
      <select
        id={inputId}
        name={inputId}
        onChange={onListInputChange}
        aria-describedby={cellStyle.helpText ? `help-text-${dataPoint.dataPointId}` : undefined}
        value={getMatchingValueFromOptions(value)}
        disabled={readonly}
      >
        <option aria-label="blank option" value="" hidden={!cellStyle.allowNull} />
        {listOptions?.options.map((lo) => (
          <option key={inputId + lo} value={lo}>
            {lo}
          </option>
        ))}
      </select>
    </DataPointCellWithOverlay>
  );
};

export default ListInputCell;
