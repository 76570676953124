import { FormEvent, useState } from 'react';
import { Form } from 'react-bootstrap';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import { AccessibleFeedback } from '@/Components/Form/AccessibleFeedback';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { MyProviderApi, placeHolderAPIErrorHandler } from '@/Helpers/Apis';
import { getValidityProps } from '@/Helpers/Forms';
import { emailRegex } from '@/Helpers/Validation';

type FeesInvoiceFormProps = {
  initialFeesInvoiceEmail: string | null;
  readonly: boolean;
};

const FeesInvoiceForm = ({ initialFeesInvoiceEmail, readonly }: FeesInvoiceFormProps): JSX.Element => {
  const [feesInvoiceEmail, setFeesInvoiceEmail] = useState<string | null>(initialFeesInvoiceEmail);

  // We don't use useUnsavedChangesWarning here because it doesn't really matter if we lose the information in the form
  // and also because it is used by the contacts form on the same page and we don't want to have multiple competing prompts
  // on navigation.
  const [isDirty, setIsDirty] = useState(false);

  const [validated, setValidated] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault(); // Stops the page from reloading
    event.stopPropagation();

    if (!event.currentTarget.checkValidity()) {
      setValidated(true);
      return;
    }
    setSubmitting(true);
    await MyProviderApi.editProvider({ feesInvoiceEmail }).justErrors(placeHolderAPIErrorHandler);
    setIsDirty(false);

    setSubmitting(false);
  };

  return (
    <Form className="mb-4" onSubmit={onSubmit} noValidate>
      <Form.Group className="w-25 mb-4" controlId="feesInvoiceEmail">
        <Form.Label>Email Address</Form.Label>
        <Form.Control
          value={feesInvoiceEmail ?? ''}
          disabled={readonly}
          onChange={(e) => {
            setIsDirty(true);
            setEmailInvalid(false);
            setFeesInvoiceEmail(e.target.value);
          }}
          minLength={6}
          maxLength={256}
          pattern={emailRegex.source}
          onInvalid={() => setEmailInvalid(true)}
          {...getValidityProps(validated && emailInvalid, 'fees-invoice-email-feedback')}
        />
        <AccessibleFeedback displayFeedback={emailInvalid} id="fees-invoice-email-feedback">
          Please provide a valid email address
        </AccessibleFeedback>
      </Form.Group>
      {!readonly &&
        (!isSubmitting ? (
          <PrimaryButton type="submit" disabled={!isDirty}>
            Save
          </PrimaryButton>
        ) : (
          <LoadingButton message="Save" />
        ))}
    </Form>
  );
};

export default FeesInvoiceForm;
