import { Faq } from 'nrosh-common/Api/FaqsApi';
import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { FormEvent, ReactNode, useMemo, useRef, useState } from 'react';
import { Alert, Form, Stack } from 'react-bootstrap';
import { IconContext } from 'react-icons';
import { BiAddToQueue } from 'react-icons/bi';
import { Link, generatePath } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import Delete from '@/Components/Icons/Delete';
import Edit from '@/Components/Icons/Edit';
import LinkButton from '@/Components/Links/LinkButton';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import { LoadingSpinner } from '@/Components/Loading/LoadingSpinner';
import { useModal } from '@/Components/Modal/ModalProvider';
import PageHeader from '@/Components/PageHeader/PageHeader';
import EditableCell from '@/Components/Table/EditableCell';
import { useTablePageResetOverride } from '@/Components/Table/PageSkipUtilities';
import Table, { NroshColumn } from '@/Components/Table/Table';
import { FaqsApi, placeHolderAPIErrorHandler } from '@/Helpers/Apis';
import { formatISODateString } from '@/Helpers/DateHelpers';
import { addValidatedPropertyToArray } from '@/Helpers/Forms';
import useUnsavedChangesWarning from '@/Hooks/useUnsavedChangesWarning';
import { PublishStatus, checkItemCanBeDeleted, getPublishStatus } from '@/Pages/Content/ContentHelpers';
import { adminPages } from '@/Pages/Home/SitePages';
import '@/Pages/Content/Content.scss';

const buildHeaders = (
  setFaqs: ReactStateSetter<Faq[] | null>,
  setDirty: () => void,
  setDeleteInProgress: ReactStateSetter<Set<string>>,
  deleteInProgress: Set<string>,
  setFaqLastUpdatedAt: ReactStateSetter<Date | null>,
  disableReset: () => void,
  triggerUserConfirmation: (msg: ReactNode) => Promise<boolean>,
): NroshColumn<FaqTableRow>[] => {
  const updateData = (rowIndex: number, columnId: string, value: string): void => {
    disableReset();
    setFaqs((prevData) => {
      const newData = [...prevData!];
      newData[rowIndex] = {
        ...prevData![rowIndex],
        [columnId]: value !== '' ? value : null,
        updatedPriority: true,
      };
      return newData;
    });
  };

  const deleteFaq = async (faqId: string, publishDateTime: string): Promise<void> => {
    if (!(await checkItemCanBeDeleted(publishDateTime, 'FAQ', triggerUserConfirmation))) {
      return;
    }
    setDeleteInProgress((prevDeleteInProgress) => new Set(prevDeleteInProgress).add(faqId));
    await FaqsApi.deleteFaq(faqId).justErrors(placeHolderAPIErrorHandler);
    setDeleteInProgress((prevDeleteInProgress) => {
      const newSet = new Set(prevDeleteInProgress);
      newSet.delete(faqId);
      return newSet;
    });
    setFaqLastUpdatedAt(new Date());
  };

  return [
    {
      Header: 'Priority',
      accessor: 'priority',
      Cell: ({ value, row, column }: CellProps<FaqTableRow, number>) => (
        <EditableCell
          value={value !== null ? value : ''}
          row={row}
          column={column}
          updateData={updateData}
          setDirty={setDirty}
          ariaLabel="Priority number"
          invalidValueFeedback="Please enter a number"
          inputType="number"
          required={false}
          validated={row.original.validated}
          feedbackId={`priority-feedback-${row.id}`}
        />
      ),
      disableSortBy: true,
    },
    {
      Header: 'Questions',
      accessor: 'title',
      disableSortBy: true,
      width: 400,
    },
    {
      Header: 'Tags',
      disableSortBy: true,
      Cell: ({ row }: CellProps<FaqTableRow>) => row.original.tags.map((t) => t.name).join(', '),
    },
    {
      Header: 'Publish Date',
      accessor: 'publishDateTime',
      align: 'center',
      Cell: ({ value }: CellProps<FaqTableRow>) => formatISODateString(value as string),
      disableSortBy: true,
    },
    {
      Header: 'Status',
      accessor: (row: FaqTableRow) => getPublishStatus(row.publishDateTime),
      Cell: ({ row }: CellProps<FaqTableRow>) => getPublishStatus(row.original.publishDateTime),
      disableSortBy: true,
    },
    {
      Header: 'Edit',
      accessor: 'id',
      Cell: ({ value }) => (
        <Link
          className="d-flex align-items-center editLink justify-content-center"
          to={generatePath(adminPages.AdminFaqsEdit.path, { faqId: value })}
        >
          <Edit />
        </Link>
      ),
      width: 100,
      disableResizing: true,
      disableSortBy: true,
    },
    {
      Header: 'Delete',
      align: 'center',
      Cell: ({ row }: CellProps<FaqTableRow>) =>
        deleteInProgress.has(row.original.id) ? (
          <div className="spinner-container">
            <LoadingSpinner smallSpinner />
          </div>
        ) : (
          <button
            aria-label="Delete"
            className="iconButton"
            type="button"
            onClick={async () => {
              await deleteFaq(row.original.id, row.original.publishDateTime);
            }}
          >
            <Delete />
          </button>
        ),
      width: 100,
      disableResizing: true,
      disableSortBy: true,
    },
  ];
};

type FaqTableRow = Faq & { validated: boolean };

const tableRowToExportRow = (
  faq: Faq,
): {
  'Priority': number;
  'Question': string;
  'Tags': string;
  'Publish Date': string;
  'Status': PublishStatus;
  'Content': string;
} => ({
  'Priority': faq.priority,
  'Question': faq.title,
  'Tags': faq.tags.map((t) => t.name).join(', '),
  'Publish Date': formatISODateString(faq.publishDateTime),
  'Status': getPublishStatus(faq.publishDateTime),
  'Content': faq.content,
});

const ManageFaqsPage = (): JSX.Element => {
  const [faqLastUpdatedAt, setFaqLastUpdatedAt] = useState<Date | null>(null);
  const [faqs, setFaqs] = useEndpoint<Faq[]>(FaqsApi.getFaqs, faqLastUpdatedAt);
  const [deleteInProgress, setDeleteInProgress] = useState<Set<string>>(new Set());
  const [setDirty, setPristine, isDirty] = useUnsavedChangesWarning();
  const [validated, setValidated] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [skipPageResetRef, , disableReset] = useTablePageResetOverride();
  const { confirm } = useModal();
  // It's important to call useMemo here - otherwise, whenever the columns are recalculated all of the cells will
  // be unmounted and recreated. This means that the editable cell component will lose focus on each keystroke.
  const headers: Column<FaqTableRow>[] = useMemo(
    () =>
      buildHeaders(
        setFaqs,
        setDirty,
        setDeleteInProgress,
        deleteInProgress,
        setFaqLastUpdatedAt,
        disableReset,
        confirm,
      ),
    [setFaqs, setDirty, setDeleteInProgress, deleteInProgress, setFaqLastUpdatedAt, disableReset, confirm],
  );

  const tableData = useMemo(() => faqs && addValidatedPropertyToArray(faqs, validated), [faqs, validated]);

  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    const form = event.currentTarget;

    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      event.preventDefault();
      setSaving(true);

      const faqsPriorityRequest = faqs!
        .filter((faq) => faq.updatedPriority === true)
        .map((faq) => ({ id: faq.id, priority: faq.priority }));
      const response = await FaqsApi.editFaqsPriority({
        faqsPriorityList: faqsPriorityRequest,
      }).raw;
      if (response.ok) {
        setFaqLastUpdatedAt(new Date());
        setPristine();
        setValidated(false);
        setError(null);
      } else {
        const errorMessage = response.value.message;
        setError(errorMessage);
      }

      setSaving(false);
    }
  };

  const { current: iconContextValue } = useRef({ size: '1.25rem', color: 'white' });

  if (!faqs || !tableData) {
    return <LoadingMessage />;
  }

  return (
    <Stack gap={5}>
      <PageHeader
        heading="Manage FAQs"
        crumbsType={RootPathType.AdminContent}
        crumbs={[
          {
            name: 'Manage FAQs',
            path: adminPages.AdminFaqs.path,
          },
        ]}
      />
      <LinkButton to={adminPages.AdminFaqsCreate.path} className="mb-3">
        <div className="d-flex align-items-center">
          Add New FAQ
          <div className="ms-3 d-flex align-items-center">
            <IconContext.Provider value={iconContextValue}>
              <BiAddToQueue />
            </IconContext.Provider>
          </div>
        </div>
      </LinkButton>
      <Form noValidate onSubmit={onSubmit}>
        {error && (
          <Alert variant="danger" dismissible onClose={() => setError(null)}>
            {error}
          </Alert>
        )}
        <Table
          data={tableData}
          columns={headers}
          paginated
          searchable
          exportable
          exportFileName="FAQs.csv"
          tableRowToExportRow={tableRowToExportRow}
          rowHeadingIndex={1}
          skipPageReset={skipPageResetRef.current}
          disableSkipPageReset={disableReset}
        />
        {!isSaving ? (
          <PrimaryButton type="submit" disabled={!isDirty}>
            Save
          </PrimaryButton>
        ) : (
          <LoadingButton message="Saving..." />
        )}
      </Form>
    </Stack>
  );
};

export default ManageFaqsPage;
