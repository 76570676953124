import { DimensionalMember, SurveyStatus } from 'nrosh-common/Api/SubmissionsApi';
import { DataPoint } from 'nrosh-common/Api/SurveyPartsApi';
import { nameof } from 'nrosh-common/Helpers/StringHelpers';
import { ReactNode, useContext, useMemo, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { SecondaryButton } from '@/Components/Buttons/DCSButton';
import ExportButton from '@/Components/ExportButton/ExportButton';
import ValidationAccordionItem, { ValidationDisplayModel } from '@/Components/Validations/ValidationAccordionItem';
import { ValidationModel } from '@/Components/Validations/ValidationTypes';
import { createContext } from '@/Helpers/Context';
import '@/Styles/BootstrapOverrides/Accordion.scss';
import '@/Components/Validations/ValidationsStyles.scss';

type BaseValidationAccordionContext = {
  includeLinksToParts: boolean;
  questionReferences: { [id: string]: string };
  surveyStatus: SurveyStatus;
  toggleActiveEventKey: (key: string) => void;
  isAdmin: boolean;
  updateComment?: (validationId: string, comment: string) => void;
  deleteComment?: (validationId: string) => void;
  updateValidationApprovalStatus?: (validationId: string, isApproved: boolean) => void;
};

type IncludeLinksToDataPointsContext = {
  includeLinksToDataPoints: true;
  dimensionMembers: DimensionalMember[];
  dataPoints: DataPoint[];
};

type OmitLinksToDataPointsContext = {
  includeLinksToDataPoints: false;
  dimensionMembers?: undefined;
  dataPoints?: undefined;
};

type ReadonlyCommentsContext = {
  commentsAreReadonly: true;
  updateComment?: undefined;
  deleteComment?: undefined;
};

type EditableCommentsContext = {
  commentsAreReadonly: false;
  updateComment: (validationId: string, comment: string) => void;
  deleteComment: (validationId: string) => void;
};

type ValidationsAccordionContextType = BaseValidationAccordionContext &
  (IncludeLinksToDataPointsContext | OmitLinksToDataPointsContext) &
  (ReadonlyCommentsContext | EditableCommentsContext);

const ValidationsAccordionContext = createContext<ValidationsAccordionContextType>();

type ValidationsAccordionContextProviderProps = ValidationsAccordionContextType & {
  children: ReactNode;
};

const ValidationsAccordionContextProvider = ({
  includeLinksToParts,
  includeLinksToDataPoints,
  dimensionMembers,
  dataPoints,
  questionReferences,
  commentsAreReadonly,
  updateComment,
  deleteComment,
  updateValidationApprovalStatus,
  isAdmin,
  surveyStatus,
  toggleActiveEventKey,
  children,
}: ValidationsAccordionContextProviderProps): JSX.Element => {
  const value = useMemo(
    () =>
      ({
        includeLinksToParts,
        includeLinksToDataPoints,
        dimensionMembers,
        dataPoints,
        questionReferences,
        commentsAreReadonly,
        updateComment,
        deleteComment,
        updateValidationApprovalStatus,
        surveyStatus,
        isAdmin,
        toggleActiveEventKey,
      }) as ValidationsAccordionContextType,
    [
      includeLinksToParts,
      dimensionMembers,
      dataPoints,
      questionReferences,
      commentsAreReadonly,
      updateComment,
      deleteComment,
      updateValidationApprovalStatus,
      surveyStatus,
      isAdmin,
      toggleActiveEventKey,
    ],
  );

  return <ValidationsAccordionContext.Provider value={value}>{children}</ValidationsAccordionContext.Provider>;
};

export const useValidationsAccordionContext = (): ValidationsAccordionContextType => {
  const context = useContext(ValidationsAccordionContext);
  if (!context) {
    throw new Error(
      `${nameof({ useValidationsAccordionContext })} must be used within ${nameof({
        ValidationsAccordionContextProvider,
      })}`,
    );
  }

  return context;
};

// Tech-debt: There's a lot of duplication here with the ValidationsAccordionContextType
type ValidationsBaseProps = {
  validations: ValidationModel[];
  dataPoints: DataPoint[];
  includeLinksToParts?: boolean;
  updateValidationApprovalStatus?: (validationId: string, isApproved: boolean) => void;
  isAdmin?: boolean;
  surveyStatus: SurveyStatus;
  commentsAreReadonly?: boolean;
  updateComment?: (validationId: string, comment: string) => void;
  deleteComment?: (validationId: string) => void;
  downloadValidations: () => Promise<void>;
};

type IncludeLinksToDataPointsProps = {
  includeLinksToDataPoints: true;
  dimensionMembers?: DimensionalMember[];
};

type OmitLinksToDataPointsProps = {
  includeLinksToDataPoints?: false;
  dimensionMembers?: undefined;
};

type ValidationsProps = ValidationsBaseProps & (IncludeLinksToDataPointsProps | OmitLinksToDataPointsProps);

const AutoValidationRegex = /(?<=AV\d_)(DP_.+)/;

const Validations = ({
  validations,
  dataPoints,
  includeLinksToParts = false,
  includeLinksToDataPoints = false,
  dimensionMembers,
  commentsAreReadonly = false,
  updateComment,
  deleteComment,
  updateValidationApprovalStatus,
  isAdmin = false,
  surveyStatus,
  downloadValidations,
}: ValidationsProps): JSX.Element => {
  const [activeEventKeys, setActiveEventKeys] = useState<string[]>([]);

  const toggleActiveEventKey = (eventKey: string): void =>
    setActiveEventKeys((selectedKeys) =>
      selectedKeys.includes(eventKey) ? selectedKeys.filter((k) => k !== eventKey) : [...selectedKeys, eventKey],
    );

  const questionReferences = Object.assign({}, ...dataPoints.map((dp) => ({ [dp.id]: dp.questionReference }))) as {
    [id: string]: string;
  };

  const mapValidationModelToDisplayModel = (v: ValidationModel): ValidationDisplayModel => {
    const matches = v.validationId.match(AutoValidationRegex);

    return {
      ...v,
      displayName:
        matches && matches[0]
          ? v.validationId.replace(AutoValidationRegex, questionReferences[matches[0]])
          : v.validationId,
    } as ValidationDisplayModel;
  };

  const processedValidations = useMemo(
    () =>
      validations
        .map(mapValidationModelToDisplayModel)
        .sort((v1, v2) => v1.type.localeCompare(v2.type) || v1.displayName.localeCompare(v2.displayName)),
    [validations],
  );

  const contextProps = {
    includeLinksToParts,
    includeLinksToDataPoints,
    dimensionMembers,
    dataPoints,
    questionReferences,
    commentsAreReadonly,
    updateComment,
    deleteComment,
    updateValidationApprovalStatus,
    surveyStatus,
    isAdmin,
    toggleActiveEventKey,
  } as ValidationsAccordionContextType;

  return (
    <>
      <div className="d-flex gap-3 my-3">
        <SecondaryButton onClick={() => setActiveEventKeys(validations.map((v) => v.validationId))}>
          Expand all
        </SecondaryButton>
        <SecondaryButton onClick={() => setActiveEventKeys([])}>Collapse all</SecondaryButton>
        <ExportButton className="ms-auto" label="Download validations" onDownload={downloadValidations} />
      </div>
      <ValidationsAccordionContextProvider {...contextProps}>
        <Accordion alwaysOpen activeKey={activeEventKeys}>
          {processedValidations.map((v) => (
            <ValidationAccordionItem key={v.validationId} validation={v} />
          ))}
        </Accordion>
      </ValidationsAccordionContextProvider>
    </>
  );
};

export default Validations;
