import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { ChangeEvent, FormEvent, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import { AccessibleFeedback } from '@/Components/Form/AccessibleFeedback';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { AccountApi } from '@/Helpers/Apis';
import { getValidityProps } from '@/Helpers/Forms';

const onFieldChange = (setState: ReactStateSetter<string>) => (e: ChangeEvent<HTMLInputElement>) => {
  setState(e.target.value);
  e.target.setCustomValidity('');
};

const ForgotPasswordForm = (): JSX.Element => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [validated, setValidated] = useState<boolean>(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    setError(null);
    setSuccess(false);
    event.preventDefault(); // Stops the page from reloading

    if (username === '' || email === '') {
      setValidated(true);
      return;
    }

    setSubmitting(true);

    const response = await AccountApi.sendForgotPasswordEmail({ username, email }).raw;
    if (response.ok) {
      setSuccess(true);
    } else if (response.value.status === 429) {
      setError(
        "You have made too many forgot password requests. Please check your email inbox for a reset password email. This email should usually arrive within a few minutes. If an email hasn't arrived after an hour please try again.",
      );
    } else {
      setError('Something has gone wrong, please try again in a few minutes');
    }

    setSubmitting(false);
  };

  return (
    <>
      {success && (
        <Alert variant="warning" className="text-center">
          If we have an account with matching details, you will receive an email with a password reset link. If you do
          not see this within a few minutes, please check your junk or spam folders.
        </Alert>
      )}
      {error && <Alert variant="danger">{error}</Alert>}
      <p className="text-center">
        Please enter both your username and email address as detailed in our records and click submit.
      </p>
      <p className="text-center">
        If you have forgotten your username please speak to another registered user at your organisation who can confirm
        your details. Alternatively, if you cannot do this, you should contact the DCS Enquiries Team via{' '}
        <a href="mailto:Team-DCS@softwire.com">Team-DCS@softwire.com</a>
      </p>
      <Form noValidate onSubmit={onSubmit} className="d-flex flex-column">
        <Form.Group className="mb-3" controlId="userName">
          <Form.Label>Username</Form.Label>
          <Form.Control
            value={username}
            onChange={onFieldChange(setUsername)}
            {...getValidityProps(validated && username === '', 'username-feedback')}
          />
          <AccessibleFeedback id="username-feedback" displayFeedback={validated && username === ''}>
            You must provide a username
          </AccessibleFeedback>
        </Form.Group>
        <Form.Group className="mb-4" controlId="email">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            value={email}
            onChange={onFieldChange(setEmail)}
            {...getValidityProps(validated && email === '', 'email-feedback')}
          />
          <AccessibleFeedback id="email-feedback" displayFeedback={validated && email === ''}>
            You must provide an email address
          </AccessibleFeedback>
        </Form.Group>
        <div className="ms-auto">
          {!isSubmitting ? (
            <PrimaryButton type="submit">Submit</PrimaryButton>
          ) : (
            <LoadingButton message="Submit" className="me-0" />
          )}
        </div>
      </Form>
    </>
  );
};

export default ForgotPasswordForm;
