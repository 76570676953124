import {
  CellStyleBaseResponse,
  CellStyleResponse,
  DataPointDimensions,
  DimensionalData,
  DimensionalMember,
  DimensionalRegion,
  LayoutsResponse,
  ListType,
  SubmissionPartValidation,
  TabLayoutResponse,
} from 'nrosh-common/Api/SubmissionsApi';
import { groupBy } from 'nrosh-common/Helpers/ArrayHelpers';
import { mapObject } from 'nrosh-common/Helpers/ObjectHelpers';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { useMemo } from 'react';
import {
  CellId,
  CellStyle,
  ContentCellStyle,
  DataCellStyle,
  DimensionContentCellStyle,
  DimensionIdentifier,
  DimensionInputCellStyle,
  SlicerCellStyle,
  TabLayout,
} from '@/Components/Spreadsheet/SpreadsheetTypes';
import { SubmissionsApi } from '@/Helpers/Apis';

export type SubmissionPartLayout = {
  dimensionMembers: DimensionalMember[];
  dimensionRegions: DimensionalRegion[];
  submissionValidations: SubmissionPartValidation[];
  listTypes: ListType[];
  tabLayouts: TabLayout[];
};

type RegionData = {
  isRowRegion: boolean;
  minRowOrColumn: number;
  isSlicer: boolean;
  dimensionId: string;
  cells: CellStyleResponse[];
};

const processRegions = (tabLayout: TabLayoutResponse): Record<number, RegionData> => {
  const dimensionCells = tabLayout.cellStyles.filter((c) => c.dimensionalRegionId);
  const regionCells = groupBy(dimensionCells, (cell) => cell.dimensionalRegionId!);
  return mapObject(regionCells, (cells) => {
    const isRowRegion = cells.every((c) => c.row === cells[0].row);
    const minRowOrColumn = isRowRegion ? Math.min(...cells.map((c) => c.column)) : Math.min(...cells.map((c) => c.row));
    const isSlicer = cells.length === 1;
    const dimensionId = cells[0].dimensionId!;
    return {
      dimensionId,
      isRowRegion,
      minRowOrColumn,
      isSlicer,
      cells,
    };
  });
};

// The purpose of this function is to remove any "extra" properties that may be present in
// the API response (with null values). This means that we can be confident that the mapped
// styles only contain the properties that their types imply.
const cellStyleBaseResponse = (cell: CellStyleResponse): CellStyleBaseResponse => ({
  row: cell.row,
  column: cell.column,
  rowSpan: cell.rowSpan,
  columnSpan: cell.columnSpan,
  style: cell.style,
  dimensionalRegionId: cell.dimensionalRegionId,
});

const getIndex = (cell: CellStyleResponse, region: RegionData): number =>
  (region.isRowRegion ? cell.column : cell.row) - region.minRowOrColumn;

const getDimensionIdentifier = (
  dimensionId: string,
  cell: CellStyleResponse,
  regions: Record<number, RegionData>,
): DimensionIdentifier => {
  const region = Object.values(regions).find((r) => r.dimensionId === dimensionId)!;
  const dimensionCell = region.cells.find((c) => region.isSlicer || c.row === cell.row || c.column === cell.column)!;
  const index = region.isSlicer ? null : getIndex(dimensionCell, region);
  return { regionId: dimensionCell.dimensionalRegionId!, index };
};

const getCellId = (tabId: number, cell: CellStyleBaseResponse): CellId => `${tabId}-${cell.column}:${cell.row}`;

const mapCellStyle = (
  cell: CellStyleResponse,
  layout: TabLayoutResponse,
  dataPointDimensions: DataPointDimensions[],
  regions: Record<number, RegionData>,
): CellStyle => {
  const cellId = getCellId(layout.tabId, cell);
  const cellBase = cellStyleBaseResponse(cell);

  if (cell.dimensionalRegionId) {
    const region = regions[cell.dimensionalRegionId];
    if (region.isSlicer) {
      return {
        ...cellBase,
        cellId,
        dimensionId: cell.dimensionId,
        regionId: cell.dimensionalRegionId,
      } as SlicerCellStyle;
    }
    if (cell.content) {
      return {
        ...cellBase,
        cellId,
        dimensionId: cell.dimensionId,
        regionId: cell.dimensionalRegionId,
        index: getIndex(cell, region),
        content: cell.content,
      } as DimensionContentCellStyle;
    }
    return {
      ...cellBase,
      cellId,
      dimensionId: cell.dimensionId,
      regionId: cell.dimensionalRegionId,
      index: getIndex(cell, region),
      content: null,
    } as DimensionInputCellStyle;
  }

  const { dataPointId } = cell;
  if (!dataPointId) {
    return {
      ...cellBase,
      cellId,
      content: cell.content,
    } as ContentCellStyle;
  }

  const dataPoint = dataPointDimensions.find((dp) => dp.dataPointId === dataPointId);
  const dimension1 = dataPoint?.dimension1Id ? getDimensionIdentifier(dataPoint.dimension1Id, cell, regions) : null;
  const dimension2 = dataPoint?.dimension2Id ? getDimensionIdentifier(dataPoint.dimension2Id, cell, regions) : null;

  return {
    ...cellBase,
    cellId,
    dataPoint: {
      dataPointId,
      dimension1,
      dimension2,
    },
    listTypeName: cell.listTypeName,
    helpText: cell.helpText,
    allowNull: cell.allowNull,
    questionReference: cell.questionReference,
  } as DataCellStyle;
};

const processLayout = (layout: TabLayoutResponse, dataPointDimensions: DataPointDimensions[]): TabLayout => {
  const regions = processRegions(layout);
  return {
    ...layout,
    cellStyles: layout.cellStyles.map((cellStyle) => mapCellStyle(cellStyle, layout, dataPointDimensions, regions)),
  };
};

const useSubmissionPartLayout = (submissionPartId: string): SubmissionPartLayout | null => {
  const [dimensionalData] = useEndpoint<DimensionalData>(SubmissionsApi.getDimensionalData, submissionPartId);
  const [submissionValidations] = useEndpoint<SubmissionPartValidation[]>(
    SubmissionsApi.getUnderlyingSurveyPartValidations,
    submissionPartId,
  );
  const [layoutResponse] = useEndpoint<LayoutsResponse>(SubmissionsApi.getLayout, submissionPartId);

  const listTypes = layoutResponse?.listTypes;
  const tabLayouts = useMemo(() => {
    if (!dimensionalData || !layoutResponse) {
      return null;
    }
    return layoutResponse.tabLayouts.map((layout) => processLayout(layout, dimensionalData.dataPointsDimensions));
  }, [dimensionalData, layoutResponse]);

  // Adding a memo here so that the return object is fixed (once the endpoint calls have returned)
  const submissionPartLayout = useMemo(() => {
    if (!dimensionalData || !submissionValidations || !listTypes || !tabLayouts) {
      return null;
    }
    return {
      dimensionMembers: dimensionalData.dimensionalMembers,
      dimensionRegions: dimensionalData.dimensionalRegions,
      submissionValidations,
      listTypes,
      tabLayouts,
    };
  }, [dimensionalData, submissionValidations, listTypes, tabLayouts]);

  return submissionPartLayout;
};

export default useSubmissionPartLayout;
