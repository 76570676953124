import { EmailRecipientsFilter } from 'nrosh-common/Api/EmailsApi';
import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { useMemo, useState } from 'react';
import { DropdownButton, Form, InputGroup } from 'react-bootstrap';
import { RiCalendar2Line, RiTimeLine } from 'react-icons/ri';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import ContentDisplay from '@/Components/ContentDisplay/ContentDisplay';
import ContentEditor from '@/Components/ContentEditor/ContentEditor';
import DateSelector from '@/Components/DateTime/DateSelector';
import TimeSelector from '@/Components/DateTime/TimeSelector';
import { FileItem, MultipleFileUploadManager } from '@/Components/FileUpload/MultipleFileUploadManager';
import { AccessibleFeedback } from '@/Components/Form/AccessibleFeedback';
import { AccessibleFieldSet } from '@/Components/Form/AccessibleFieldSet';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { getValidityProps } from '@/Helpers/Forms';
import {
  TemplateField,
  TemplateFieldGroup,
  TemplatesDropdownMenuItems,
  invalidSubjectTemplateFields,
} from '@/Pages/Emails/EmailTemplates';
import '@/Pages/Emails/ComposeFormComponents.scss';
import '@/Components/Form/AccessibleFeedback.scss';

type ComposeFormBaseProps = {
  noLabel?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  disabled?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  describedBy?: string;
};

type SendTimeProps = ComposeFormBaseProps & {
  sendLater: boolean;
  setSendLater: ReactStateSetter<boolean>;
  sendDateTime: Date | null;
  setSendDateTime: ReactStateSetter<Date | null>;
  isInvalid: boolean;
};

type RecipientsProps = ComposeFormBaseProps & {
  onButtonPressed: () => Promise<void>;
  recipientsFilter: EmailRecipientsFilter | null | undefined;
  emailRecipientMessage: string;
  isInvalid: boolean;
};

type SubjectProps = ComposeFormBaseProps & {
  subject: string;
  setSubject: (e: string) => void;
  isInvalid: boolean;
  hideInvalidMessage?: boolean;
  templateFieldGroups?: TemplateFieldGroup[];
  requiredTemplateFields?: TemplateField[];
};

type AttachmentProps = ComposeFormBaseProps & {
  attachments: FileItem[];
  setAttachments: ReactStateSetter<FileItem[]>;
  setDirty?: () => void;
};

type MessageProps = ComposeFormBaseProps & {
  htmlBody: string;
  updateBody: (b: string) => void;
  isInvalid: boolean;
  hideInvalidMessage?: boolean;
  templateFieldGroups?: TemplateFieldGroup[];
  requiredTemplateFields?: TemplateField[];
};

export const ComposeForm = {
  SendTime: ({
    sendLater,
    setSendLater,
    sendDateTime,
    setSendDateTime,
    isInvalid,
    noLabel = false,
    describedBy = '',
  }: SendTimeProps): JSX.Element => (
    <AccessibleFieldSet legend={!noLabel ? 'Send Time' : undefined} legendClassName="mb-2">
      <Form.Group
        className="mb-3"
        controlId="sendTime"
        aria-describedby={`${isInvalid ? 'sendTimeFeedback' : ''} ${describedBy}`}
      >
        <Form.Check
          inline
          type="radio"
          label="Send immediately"
          id="sendImmediate"
          name="sendType"
          checked={!sendLater}
          onChange={(e) => setSendLater(!e.target.checked)}
        />
        <Form.Check
          inline
          type="radio"
          label="Send at a specific time"
          id="sendLater"
          name="sendType"
          checked={sendLater}
          onChange={(e) => setSendLater(e.target.checked)}
        />
        {sendLater && (
          <InputGroup hasValidation>
            <InputGroup.Text>
              <RiCalendar2Line />
            </InputGroup.Text>
            <DateSelector dateTime={sendDateTime} setDateTime={setSendDateTime} isInvalid={isInvalid} />
            <InputGroup.Text>
              <RiTimeLine />
            </InputGroup.Text>
            <TimeSelector
              dateTime={sendDateTime}
              setDateTime={setSendDateTime}
              isInvalid={isInvalid}
              disabled={!sendDateTime}
            />
            <AccessibleFeedback id="sendTimeFeedback" displayFeedback={isInvalid}>
              Please select a date after {new Date(Date.now()).toLocaleString()}
            </AccessibleFeedback>
          </InputGroup>
        )}
      </Form.Group>
    </AccessibleFieldSet>
  ),
  Recipients: ({
    onButtonPressed,
    recipientsFilter,
    emailRecipientMessage,
    isInvalid,
    noLabel = false,
    describedBy = '',
  }: RecipientsProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const buttonText = recipientsFilter !== null ? 'Edit Recipients' : 'Choose Recipients';

    return (
      <Form.Group className="mb-3" controlId="recipients">
        {!noLabel && <Form.Label>Recipients</Form.Label>}
        <InputGroup hasValidation>
          <Form.Control
            disabled
            aria-label="Recipients"
            value={emailRecipientMessage}
            {...getValidityProps(isInvalid, 'recipientsFeedback', describedBy)}
          />
          {!isLoading ? (
            <PrimaryButton
              onClick={async () => {
                setIsLoading(true);
                await onButtonPressed();
                setIsLoading(false);
              }}
            >
              {buttonText}
            </PrimaryButton>
          ) : (
            <LoadingButton message={buttonText} />
          )}
          <AccessibleFeedback id="recipientsFeedback" displayFeedback={isInvalid}>
            An email must have at least one recipient
          </AccessibleFeedback>
        </InputGroup>
      </Form.Group>
    );
  },
  Subject: ({
    subject,
    setSubject,
    isInvalid,
    hideInvalidMessage = false,
    noLabel = false,
    templateFieldGroups,
    requiredTemplateFields,
    disabled = false,
    describedBy = '',
  }: SubjectProps) => {
    const availableRequiredTemplateFields = useMemo(
      () => requiredTemplateFields?.filter((tf) => !invalidSubjectTemplateFields.some((x) => x === tf)),
      [requiredTemplateFields],
    );

    const availableTemplateFieldGroups = useMemo(
      () => templateFieldGroups?.map((tfg) => tfg.filter((tf) => !invalidSubjectTemplateFields.some((x) => x === tf))),
      [templateFieldGroups],
    );

    return (
      <Form.Group className="mb-3" controlId="subject">
        {!noLabel && <Form.Label>Subject</Form.Label>}
        <InputGroup hasValidation>
          <Form.Control
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            aria-label="Subject"
            disabled={disabled}
            {...getValidityProps(isInvalid, 'subjectFeedback', describedBy)}
          />
          {availableTemplateFieldGroups && availableTemplateFieldGroups.length !== 0 && (
            <DropdownButton title="Template Fields" variant="primary" disabled={disabled}>
              <TemplatesDropdownMenuItems
                templateGroups={availableTemplateFieldGroups}
                onInsert={(content) => setSubject(subject + content)}
                requiredTemplateFieldsOptions={availableRequiredTemplateFields ?? []}
              />
            </DropdownButton>
          )}
          <AccessibleFeedback id="subjectFeedback" displayFeedback={isInvalid && !hideInvalidMessage}>
            An email must have a subject
          </AccessibleFeedback>
        </InputGroup>
      </Form.Group>
    );
  },
  Attachments: ({ attachments, setAttachments, setDirty, noLabel = false }: AttachmentProps) => (
    <Form.Group className="mb-3" controlId="attachments">
      {!noLabel && <Form.Label>Attachments</Form.Label>}
      <MultipleFileUploadManager files={attachments} setFiles={setAttachments} setDirty={setDirty} />
    </Form.Group>
  ),
  Message: ({
    htmlBody,
    updateBody,
    isInvalid,
    noLabel = false,
    hideInvalidMessage = false,
    templateFieldGroups,
    requiredTemplateFields,
    disabled = false,
    describedBy = '',
  }: MessageProps) => (
    <Form.Group
      className="mb-3"
      controlId="message"
      aria-invalid={isInvalid}
      aria-describedby={`${isInvalid ? 'messageFeedback ' : ''}${describedBy}`}
    >
      {!noLabel && <Form.Label>Message</Form.Label>}
      {disabled ? (
        <div className="px-3 py-2 contentDisplayContainer" aria-label="Message">
          <ContentDisplay htmlContent={htmlBody} />
        </div>
      ) : (
        <ContentEditor
          content={htmlBody}
          ariaLabel="Message"
          setContent={updateBody}
          setDirty={() => {}}
          isInvalid={isInvalid}
          templateFieldGroups={templateFieldGroups}
          requiredTemplateFields={requiredTemplateFields}
        />
      )}
      <AccessibleFeedback id="messageFeedback" displayFeedback={isInvalid && !hideInvalidMessage}>
        An email must have a body
      </AccessibleFeedback>
    </Form.Group>
  ),
};
