import saveAs from 'file-saver';
import { RshRole } from 'nrosh-common/Api/Enums';
import { ProviderUsers, User, UserStatus, UsersResponse } from 'nrosh-common/Api/UsersApi';
import AuthContext, { Auth } from 'nrosh-common/Contexts/AuthContext';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { useContext, useState } from 'react';
import { Form, Stack } from 'react-bootstrap';
import { Link, generatePath } from 'react-router-dom';
import Accordion from '@/Components/Accordion/Accordion';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { useErrorReporting } from '@/Components/Errors/DCSErrorBoundary';
import ExportButton from '@/Components/ExportButton/ExportButton';
import Edit from '@/Components/Icons/Edit';
import LinkButton from '@/Components/Links/LinkButton';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import Table, { NroshColumn } from '@/Components/Table/Table';
import { UsersApi } from '@/Helpers/Apis';
import { TimeResolution, formatISODateString } from '@/Helpers/DateHelpers';
import { caseInsensitiveSort } from '@/Helpers/TableHelper';
import { adminPages } from '@/Pages/Home/SitePages';
import '@/Pages/Users/AdminUserManagementPage.scss';
import { UserStatusNameMapping } from '@/Pages/Users/UserStatusNameMapping';

const getUserTableColumns = (auth: Auth): NroshColumn<User>[] => {
  const editColumn: NroshColumn<User>[] = [
    {
      Header: 'Edit',
      accessor: 'id',
      align: 'center' as const,
      disableSortBy: true,
      disableResizing: true,
      width: 100,
      Cell: ({ value, row }) =>
        row.original.status !== UserStatus.Deleted &&
        auth.user?.userName !== row.original.userName && (
          <Link
            to={generatePath(adminPages.AdminEditUser.path, { userId: value.toString() })}
            className="editUserLink d-flex align-items-center justify-content-center"
          >
            <Edit />
          </Link>
        ),
    },
  ];

  return [
    {
      Header: 'User',
      accessor: 'name',
      sortType: caseInsensitiveSort,
      Cell: ({ row }) => (
        <div>
          {row.original.name}
          {row.original.preferredFormOfAddress && ` (${row.original.preferredFormOfAddress})`}
        </div>
      ),
    },
    {
      Header: 'Username',
      accessor: 'userName',
      sortType: caseInsensitiveSort,
    },
    {
      Header: 'User Profile',
      accessor: 'profile',
      Cell: ({ value }) => value && value.name,
    },
    {
      Header: 'Email Address',
      accessor: 'emailAddress',
      sortType: caseInsensitiveSort,
      Cell: ({ value }) => (value.includes('@') ? <a href={`mailto:${value}`}>{value}</a> : value),
    },
    {
      Header: 'User Status',
      accessor: 'status',
      Cell: ({ value }) => UserStatusNameMapping[value],
    },
    {
      Header: 'Last Successful Login',
      accessor: 'lastSuccessfulLogin',
      Cell: ({ value }) => formatISODateString(value, TimeResolution.Second),
    },
    ...(auth.hasRole(RshRole.EditUsers) ? editColumn : []),
  ];
};

export const AdminUserManagementPage = (): JSX.Element => {
  const auth = useContext(AuthContext);

  const [users] = useEndpoint<UsersResponse>(UsersApi.getUsers);

  const [searchTerm, setSearchTerm] = useState('');
  const [showDeletedUsers, setShowDeletedUsers] = useState(false);
  const [raiseError] = useErrorReporting();

  const downloadUsers = async (): Promise<void> => {
    try {
      const downloadedUsers = await UsersApi.downloadUsers();
      saveAs(URL.createObjectURL(downloadedUsers), 'Users.csv');
    } catch {
      raiseError();
    }
  };

  if (!users) {
    return <LoadingMessage />;
  }

  const flattenedUsers = [
    {
      id: null,
      name: 'DCS Users',
      providerNumber: '',
      users: users.rshUsers,
    },
    ...users.providers,
  ];

  const normalisedSearchTerm = searchTerm.toLowerCase().trim();

  const filteredProviders = flattenedUsers
    .map((p) => ({
      ...p,
      users: p.users.filter(
        (u) =>
          (u.name.toLowerCase().includes(normalisedSearchTerm) ||
            u.userName.toLowerCase().includes(normalisedSearchTerm) ||
            u.preferredFormOfAddress?.toLowerCase().includes(normalisedSearchTerm) ||
            u.emailAddress.toLowerCase().includes(normalisedSearchTerm) ||
            u.profile?.name.toLowerCase().includes(normalisedSearchTerm) ||
            p.name.toLowerCase().includes(normalisedSearchTerm) ||
            p.providerNumber.toLowerCase().includes(normalisedSearchTerm)) &&
          (showDeletedUsers || u.status !== UserStatus.Deleted),
      ),
    }))
    .filter(
      (p) =>
        p.users.length > 0 ||
        p.name.toLowerCase().includes(normalisedSearchTerm) ||
        p.providerNumber.toLowerCase().includes(normalisedSearchTerm),
    );

  const heading = (provider: ProviderUsers): JSX.Element => (
    <div>
      {provider.name}
      {provider.providerNumber && ` - ${provider.providerNumber}`}
    </div>
  );

  const body = (provider: ProviderUsers): JSX.Element => (
    <>
      {auth.hasRole(RshRole.EditUsers) && provider.providerNumber && (
        <LinkButton
          className="ms-3 mt-2 mb-1"
          to={generatePath(adminPages.AdminAddUser.path, { providerId: provider.id?.toString() })}
        >
          Add User
        </LinkButton>
      )}
      <Table
        data={provider.users}
        columns={getUserTableColumns(auth)}
        defaultSort={[{ id: 'name', desc: false }]}
        message="No users for provider."
        // The table is within an accordion, all users can be downloaded using a separate button, and an
        // alternative means of downloading the users for a given provider is available through the per-provider
        // user pages (/admin/providers/:providerId/users)
        exportable={false}
        rowHeadingIndex={0}
      />
    </>
  );

  return (
    <Stack gap={5} className="adminUserManagementContainer">
      <PageHeader
        heading={auth.hasRole(RshRole.EditUsers) ? 'User management' : 'Users'}
        crumbsType={RootPathType.AdminUsers}
      />
      {auth.hasRole(RshRole.EditUsers) && (
        <div>
          <LinkButton to={adminPages.AdminAddDcsUser.path} className="me-3">
            Add DCS User
          </LinkButton>
          <ExportButton label="Export Users" onDownload={downloadUsers} />
        </div>
      )}
      <Stack gap={3}>
        <div className="tableDescription">All Users</div>
        <Form.Control
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="searchInput"
          placeholder="Search user"
        />
        <label htmlFor="showDeletedUsersCheckbox" className="d-flex align-items-center">
          <input
            type="checkbox"
            id="showDeletedUsersCheckbox"
            className="me-2"
            checked={showDeletedUsers}
            onChange={(e) => setShowDeletedUsers(e.target.checked)}
          />
          Show deleted users
        </label>
        <Accordion
          data={filteredProviders}
          id={(provider: ProviderUsers) => provider.id?.toString() || 'RSH-Users'}
          heading={heading}
          body={body}
          paginated
          message="No users matching the search criteria."
        />
      </Stack>
    </Stack>
  );
};
