import { User } from 'nrosh-common/Api/UsersApi';
import { FormEvent, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { AccessibleFeedback } from '@/Components/Form/AccessibleFeedback';
import { UsersApi } from '@/Helpers/Apis';
import { getValidityProps } from '@/Helpers/Forms';
import { UserEmailFormControl } from '@/Pages/Users/UserEmailFormControl';
import { SubmitButton, handleError } from '@/Pages/Users/UsersHelper';

type EditUserEmailProps = {
  hasRestrictedDomain: boolean;
  user: User;
  authoriseMessage: string;
  redirectPath: string;
};

enum EditEmailField {
  Email,
  Password,
}

const EditUserEmailForm = ({
  hasRestrictedDomain,
  user,
  authoriseMessage,
  redirectPath,
}: EditUserEmailProps): JSX.Element => {
  const [fieldValues, setFieldValues] = useState<Record<EditEmailField, string>>({
    [EditEmailField.Email]: user.emailAddress,
    [EditEmailField.Password]: '',
  });
  const [fieldValidities, setFieldValidities] = useState<Record<EditEmailField, boolean>>({
    [EditEmailField.Email]: true,
    [EditEmailField.Password]: true,
  });
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [validated, setValidated] = useState(false);

  const navigate = useNavigate();

  const onFieldInvalid = (field: EditEmailField): void => {
    setFieldValidities((oldFieldValidity) => ({ ...oldFieldValidity, [field]: false }));
  };

  const onFieldChange = (field: EditEmailField, newValue: string): void => {
    setFieldValues((oldFieldValues) => ({ ...oldFieldValues, [field]: newValue }));
    setFieldValidities((oldFieldValidities) => ({ ...oldFieldValidities, [field]: true }));
    setValidated(false);
    setError(null);
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    setError(null);
    event.preventDefault(); // Stops the page from reloading

    if (!event.currentTarget.checkValidity()) {
      setValidated(true);
      return;
    }

    setSubmitting(true);

    const response = await UsersApi.updateUserEmail(user.id, {
      emailAddress: fieldValues[EditEmailField.Email],
      password: fieldValues[EditEmailField.Password],
    }).raw;

    if (response.ok) {
      navigate(redirectPath);
      return;
    }
    await handleError(response, setError);

    setSubmitting(false);
  };

  return (
    <Form onSubmit={onSubmit} autoComplete="off" noValidate className="d-grid gap-3">
      {error && (
        <Alert variant="danger" className="w-75">
          {error}
        </Alert>
      )}
      <UserEmailFormControl
        email={fieldValues[EditEmailField.Email]}
        setEmail={(newEmail) => onFieldChange(EditEmailField.Email, newEmail)}
        hasRestrictedDomain={hasRestrictedDomain}
        validated={validated}
        isValid={fieldValidities[EditEmailField.Email]}
        onInvalid={() => onFieldInvalid(EditEmailField.Email)}
      />
      <h2>Authorise Change</h2>
      <div>
        <p>{authoriseMessage}</p>
        <Form.Group className="mb-3 w-25" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={fieldValues[EditEmailField.Password]}
            onChange={(e) => onFieldChange(EditEmailField.Password, e.target.value)}
            onInvalid={() => onFieldInvalid(EditEmailField.Password)}
            required
            {...getValidityProps(validated && !fieldValidities[EditEmailField.Password], 'password-feedback')}
          />
          <AccessibleFeedback
            displayFeedback={validated && !fieldValidities[EditEmailField.Password]}
            id="password-feedback"
          >
            Please provide your password
          </AccessibleFeedback>
        </Form.Group>
      </div>
      <div className="mt-4 d-flex align-items-center">
        <Link className="me-3" to={redirectPath}>
          Cancel
        </Link>
        <SubmitButton isSubmitting={isSubmitting} text="Update" />
      </div>
    </Form>
  );
};

export default EditUserEmailForm;
