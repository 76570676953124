import { HTMLContent } from '@tiptap/react';
import { Faq, FaqRequest } from 'nrosh-common/Api/FaqsApi';
import { useState } from 'react';
import { ListGroup, Tab } from 'react-bootstrap';
import { generatePath, useLocation } from 'react-router-dom';
import ContentDisplay from '@/Components/ContentDisplay/ContentDisplay';
import ContentForm from '@/Pages/Content/ContentForm';
import { adminPages } from '@/Pages/Home/SitePages';

type Props = {
  faq?: Faq;
  sendFaqRequest: (
    request: FaqRequest,
    unpublish?: boolean,
  ) => Promise<{ success: boolean; redirectPath?: string; errorMessage?: string }>;
};

const FaqTabContainer: (props: Props) => JSX.Element = ({ faq, sendFaqRequest }) => {
  const [content, setContent] = useState<HTMLContent>(faq ? faq.content : '');
  const [faqQuestion, setFaqQuestion] = useState(faq ? faq.title : '');
  const priorityNumber = faq ? faq.priority : null;
  const contentEditorNavLink = '#contentEditor';
  const previewNavLink = '#preview';
  const redirectPath = generatePath(adminPages.AdminFaqs.path);
  const location = useLocation();

  const publishDateTime = faq && faq.publishDateTime ? new Date(faq.publishDateTime) : null;

  return (
    <Tab.Container defaultActiveKey={location.hash || contentEditorNavLink}>
      <ListGroup horizontal className="w-50">
        <ListGroup.Item bsPrefix="listGroupItem submissions" action href={contentEditorNavLink} as="button">
          Edit
        </ListGroup.Item>
        <ListGroup.Item bsPrefix="listGroupItem submissions" action href={previewNavLink} as="button">
          Preview
        </ListGroup.Item>
      </ListGroup>
      <Tab.Content>
        <Tab.Pane eventKey={contentEditorNavLink}>
          <ContentForm
            content={content}
            setContent={setContent}
            publishDateTime={publishDateTime}
            sendApiRequest={sendFaqRequest}
            title={faqQuestion}
            setTitle={setFaqQuestion}
            publishOrCancelPath={redirectPath}
            titleLabel="FAQ Title"
            contentLabel="FAQ Content"
            titlePlaceholderText="Enter FAQ question "
            contentPlaceHolderText="Start writing answer..."
            contentType="FAQ"
            hasPriority
            priorityNumber={priorityNumber}
            defaultSelectedTagIds={faq?.tags.map((t) => t.id) ?? []}
          />
        </Tab.Pane>
        <Tab.Pane eventKey={previewNavLink}>
          <h2>{faqQuestion}</h2>
          <ContentDisplay htmlContent={content} />
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  );
};

export default FaqTabContainer;
