import { SelectionType } from '@/Pages/Emails/EmailUtilities';

type EmailFilterItemSummaryProps = {
  selectionType: SelectionType;
  singularDescription: string;
  pluralDescription: string;
  listItems: string[];
  itemKeyBase: string;
};
export const EmailFilterSummaryItem = ({
  selectionType,
  singularDescription,
  pluralDescription,
  listItems,
  itemKeyBase,
}: EmailFilterItemSummaryProps): JSX.Element | null => {
  if (selectionType === SelectionType.All) return <li>All {pluralDescription}</li>;
  if (selectionType === SelectionType.None) return null;

  const description = listItems.length === 1 ? singularDescription : pluralDescription;

  return (
    <li>
      {listItems.length} {description}
      <ul>
        {listItems.map((li) => (
          <li key={`${itemKeyBase}-${li}`}>{li}</li>
        ))}
      </ul>
    </li>
  );
};
