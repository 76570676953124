import { GetRegulatoryDocumentResponse } from 'nrosh-common/Api/RegulatoryDocumentsApi';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import { distinctBy } from 'nrosh-common/Helpers/ArrayHelpers';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { useContext, useMemo, useReducer } from 'react';
import { Stack } from 'react-bootstrap';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { RegulatoryDocumentsApi } from '@/Helpers/Apis';
import { providerDisplayName } from '@/Helpers/ProviderHelper';
import { compareTagNamesAlphabetically } from '@/Helpers/TagHelper';
import RegulatoryDocumentsTable from '@/Pages/RegulatoryDocuments/RegulatoryDocumentsTable';

const RegulatoryDocumentsPage = ({ isAdmin = false }: { isAdmin?: boolean }): JSX.Element => {
  const auth = useContext(AuthContext);
  const documentsEndpoint = isAdmin ? RegulatoryDocumentsApi.getList : RegulatoryDocumentsApi.getListForProvider;
  const breadcrumbsRootPathType = isAdmin
    ? RootPathType.AdminRegulatoryDocuments
    : RootPathType.ProviderRegulatoryDocuments;

  const [lastRefreshTime, triggerDataRefresh] = useReducer(() => Date.now(), Date.now());
  const [documentsResponse] = useEndpoint<GetRegulatoryDocumentResponse>(documentsEndpoint, lastRefreshTime);

  const tagOptions = useMemo(() => {
    const allTags =
      documentsResponse?.documents
        .map((d) => [d.primaryTag, d.secondaryTag])
        .flat()
        .filter((t) => t) ?? [];
    return distinctBy(allTags, (t) => t.id)
      .sort(compareTagNamesAlphabetically)
      .map((t) => ({ value: t.id, label: t.name }));
  }, [documentsResponse]);

  if (!documentsResponse || !auth.user) {
    return <LoadingMessage />;
  }

  return (
    <Stack gap={5}>
      <PageHeader
        heading="Manage regulatory documents"
        subheading={`${!isAdmin ? `${providerDisplayName(auth.user.providerName!, auth.user.providerNumber!)}` : ''}`}
        crumbsType={breadcrumbsRootPathType}
      />
      <RegulatoryDocumentsTable
        apiDocuments={documentsResponse.documents}
        tagOptions={tagOptions}
        triggerDataRefresh={triggerDataRefresh}
      />
    </Stack>
  );
};

export default RegulatoryDocumentsPage;
