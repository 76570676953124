import { APIResponse, APIResult } from 'nrosh-common/Api/ApiClient';
import { ProviderSummary } from 'nrosh-common/Api/ProvidersApi';
import { User, UserProfile, UserProfileType, UserStatus } from 'nrosh-common/Api/UsersApi';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { FormEvent, useContext, useState } from 'react';
import { Alert, Form, InputGroup } from 'react-bootstrap';
import { Link, generatePath, useNavigate } from 'react-router-dom';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import { AccessibleFeedback } from '@/Components/Form/AccessibleFeedback';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import { useModal } from '@/Components/Modal/ModalProvider';
import { UsersApi } from '@/Helpers/Apis';
import { getValidityProps } from '@/Helpers/Forms';
import { alphanumericOrUnderscoreRegex } from '@/Helpers/Validation';
import { adminPages, providerPages } from '@/Pages/Home/SitePages';
import '@/Pages/Users/UserForm.scss';
import { UserEmailFormControl } from '@/Pages/Users/UserEmailFormControl';
import { SubmitButton, handleError } from '@/Pages/Users/UsersHelper';

enum UserStatusAction {
  Enable = 'Enable',
  Disable = 'Disable',
  Delete = 'Delete',
}

enum UserFormField {
  Username,
  Profile,
  Name,
  PreferredName,
  Email,
}

type UserFormProps = {
  user?: User;
  providerId?: number;
  isAdmin?: boolean;
  triggerDataRefresh?: () => void;
  userProfileType?: UserProfileType;
  providerDetails?: ProviderSummary | null;
  cancelPath?: boolean;
};

const UserForm = ({
  user,
  providerId,
  isAdmin = false,
  triggerDataRefresh,
  userProfileType,
  providerDetails,
  cancelPath = false,
}: UserFormProps): JSX.Element => {
  const navigate = useNavigate();

  const isDcsProfile = userProfileType === UserProfileType.DcsProfile;
  const isProviderProfile = userProfileType === UserProfileType.ProviderProfile;

  const [profiles] = useEndpoint<UserProfile[]>(isAdmin ? UsersApi.getProfiles : UsersApi.getProviderProfiles);
  const [userStatus, setUserStatus] = useState(user?.status);

  const [fieldValues, setFieldValues] = useState<Record<UserFormField, string>>({
    [UserFormField.Username]: user?.userName ?? '',
    [UserFormField.Profile]: user?.profile?.id.toString() ?? '',
    [UserFormField.Name]: user?.name ?? '',
    [UserFormField.PreferredName]: user?.preferredFormOfAddress ?? '',
    [UserFormField.Email]: user?.emailAddress ?? '',
  });
  const [fieldValidities, setFieldValidities] = useState<Record<UserFormField, boolean>>({
    [UserFormField.Username]: true,
    [UserFormField.Profile]: true,
    [UserFormField.Name]: true,
    [UserFormField.PreferredName]: true,
    [UserFormField.Email]: true,
  });

  const [validated, setValidated] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setSubmitting] = useState(false);

  const auth = useContext(AuthContext);
  const { confirm } = useModal();
  const onFieldInvalid = (field: UserFormField): void => {
    setFieldValidities((oldFieldValidity) => ({ ...oldFieldValidity, [field]: false }));
  };

  const onFieldChange = (field: UserFormField, newValue: string): void => {
    setFieldValues((oldFieldValues) => ({ ...oldFieldValues, [field]: newValue }));
    setFieldValidities((oldFieldValidities) => ({ ...oldFieldValidities, [field]: true }));
    setError(null);
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    setError(null);
    event.preventDefault(); // Stops the page from reloading

    if (!event.currentTarget.checkValidity()) {
      setValidated(true);
      return;
    }

    setSubmitting(true);

    const updatedProviderId = isAdmin && !isProviderProfile ? undefined : providerId;

    const prefixedUsername = isDcsProfile
      ? `DCS-${fieldValues[UserFormField.Username]}`
      : `${providerDetails?.providerNumber}-${fieldValues[UserFormField.Username]}`;

    const request = user
      ? UsersApi.updateUser(user.id, {
          name: fieldValues[UserFormField.Name],
          profileId: Number(fieldValues[UserFormField.Profile]),
          preferredFormOfAddress: fieldValues[UserFormField.PreferredName],
          providerId: updatedProviderId,
        })
      : UsersApi.addUser({
          userName: prefixedUsername,
          emailAddress: fieldValues[UserFormField.Email],
          name: fieldValues[UserFormField.Name],
          profileId: Number(fieldValues[UserFormField.Profile]),
          preferredFormOfAddress: fieldValues[UserFormField.PreferredName],
          providerId: updatedProviderId,
        });
    // Submit form
    const response = await request.raw;

    const path = isDcsProfile
      ? adminPages.AdminUsers.path
      : generatePath(adminPages.AdminProviderUsers.path, { providerId: providerId?.toString() });

    if (response.ok) {
      if (!user) {
        navigate(isAdmin ? path : providerPages.UserManagement.path);
      }
      triggerDataRefresh?.();
    } else {
      await handleError(response, setError);
    }
    setSubmitting(false);
  };

  const changeUserStatus = async (action: UserStatusAction): Promise<void> => {
    if (
      !user ||
      (action === UserStatusAction.Delete &&
        !(await confirm(
          <div>
            <p>Are you sure you want to delete this user?</p>
            <p>
              <strong> This is an irreversible action.</strong>
            </p>
          </div>,
        )))
    ) {
      return;
    }

    setSubmitting(true);

    const makeApiRequest = (userStatusAction: UserStatusAction): Promise<APIResponse<APIResult, APIResult>> => {
      switch (userStatusAction) {
        case UserStatusAction.Enable:
          return UsersApi.enableUser(user.id).raw;
        case UserStatusAction.Disable:
          return UsersApi.disableUser(user.id).raw;
        case UserStatusAction.Delete:
          return UsersApi.deleteUser(user.id).raw;
        default:
          throw Error('Bad option');
      }
    };

    const response = await makeApiRequest(action);

    if (response.ok) {
      if (action === UserStatusAction.Delete) {
        navigate(isAdmin ? adminPages.AdminUsers.path : providerPages.UserManagement.path);
      }
      setUserStatus(action === UserStatusAction.Enable ? UserStatus.AwaitingReactivation : UserStatus.Disabled);
      triggerDataRefresh?.();
    } else {
      await handleError(response, setError);
    }
    setSubmitting(false);
  };

  if (!profiles) {
    return <LoadingMessage />;
  }

  const isCurrentUser = !!user && user.userName === auth.user?.userName;

  let redirectPath = providerPages.UserManagement.path;

  if (isAdmin && cancelPath) {
    redirectPath = generatePath(adminPages.AdminProviderUsers.path, { providerId: providerDetails?.id.toString() });
  }

  if (isAdmin && !cancelPath) {
    redirectPath = adminPages.AdminUsers.path;
  }

  let editLinkPath;

  if (user && isAdmin) {
    editLinkPath = generatePath(adminPages.AdminEditUserEmail.path, { userId: user.id.toString() });
  }

  if (user && !isAdmin) {
    editLinkPath = generatePath(providerPages.EditUserEmail.path, { userId: user.id.toString() });
  }

  return (
    <Form noValidate onSubmit={onSubmit} autoComplete="off" className="userFormContainer">
      {error && (
        <Alert variant="danger" className="w-75">
          {error}
        </Alert>
      )}
      <h2 className="mb-3">Account Details</h2>
      {isAdmin && isProviderProfile && (
        <Form.Group className="mb-3 w-25 userFormGroup" controlId="provider">
          <Form.Label>Provider</Form.Label>
          <Form.Control disabled value={`${providerDetails?.name} - ${providerDetails?.providerNumber}`} />
        </Form.Group>
      )}
      <Form.Group className="mb-3 w-25 userFormGroup" controlId="userName">
        <Form.Label>Username</Form.Label>
        {!user ? (
          <>
            <InputGroup hasValidation>
              <InputGroup.Text>{isDcsProfile ? 'DCS' : providerDetails?.providerNumber}</InputGroup.Text>
              <Form.Control
                value={fieldValues[UserFormField.Username]}
                onChange={(e) => onFieldChange(UserFormField.Username, e.target.value)}
                required
                maxLength={256}
                pattern={alphanumericOrUnderscoreRegex.source}
                onInvalid={() => onFieldInvalid(UserFormField.Username)}
                {...getValidityProps(validated && !fieldValidities[UserFormField.Username], 'usernameFeedback')}
              />
            </InputGroup>
            <AccessibleFeedback
              displayFeedback={validated && !fieldValidities[UserFormField.Username]}
              id="usernameFeedback"
            >
              Please provide a username containing only alphanumeric characters and underscores
            </AccessibleFeedback>
          </>
        ) : (
          <Form.Control value={fieldValues[UserFormField.Username]} disabled />
        )}
      </Form.Group>
      {userProfileType && (
        <Form.Group className="mb-3 w-25 userFormGroup" controlId="userProfile">
          <Form.Label>User Profile</Form.Label>
          <Form.Select
            required
            as="select"
            value={fieldValues[UserFormField.Profile]}
            disabled={isCurrentUser}
            onChange={(e) => onFieldChange(UserFormField.Profile, e.target.value)}
            {...getValidityProps(validated && fieldValues[UserFormField.Profile] === '', 'profileFeedback')}
          >
            <option value="" hidden>
              Select user profile
            </option>
            {profiles
              .filter((p) => p.type === userProfileType)
              .map((p) => (
                <option key={p.id} value={p.id}>
                  {p.name}
                </option>
              ))}
          </Form.Select>
          <AccessibleFeedback
            displayFeedback={validated && fieldValues[UserFormField.Profile] === ''}
            id="profileFeedback"
          >
            Please select a profile
          </AccessibleFeedback>
        </Form.Group>
      )}
      <Form.Group className="mb-3 w-25 userFormGroup" controlId="name">
        <Form.Label>Name</Form.Label>
        <Form.Control
          value={fieldValues[UserFormField.Name]}
          onChange={(e) => onFieldChange(UserFormField.Name, e.target.value)}
          required
          maxLength={256}
          onInvalid={() => onFieldInvalid(UserFormField.Name)}
          {...getValidityProps(validated && !fieldValidities[UserFormField.Name], 'nameFeedback')}
        />
        <AccessibleFeedback displayFeedback={validated && !fieldValidities[UserFormField.Name]} id="nameFeedback">
          Please provide a name
        </AccessibleFeedback>
      </Form.Group>
      <Form.Group className="mb-3 w-25 userFormGroup" controlId="preferredAddress">
        <Form.Label>Preferred Name</Form.Label>
        <Form.Control
          value={fieldValues[UserFormField.PreferredName]}
          onChange={(e) => onFieldChange(UserFormField.PreferredName, e.target.value)}
          maxLength={256}
        />
      </Form.Group>
      <UserEmailFormControl
        email={fieldValues[UserFormField.Email]}
        setEmail={(newEmail) => onFieldChange(UserFormField.Email, newEmail)}
        hasRestrictedDomain={isDcsProfile}
        editLinkPath={editLinkPath}
        isReadonly={!!user}
        validated={validated}
        isValid={fieldValidities[UserFormField.Email]}
        onInvalid={() => onFieldInvalid(UserFormField.Email)}
      />
      <div className="mt-4 d-flex align-items-center">
        <Link className="me-3" to={redirectPath}>
          Cancel
        </Link>
        <SubmitButton isSubmitting={isSubmitting} text={user ? 'Save' : 'Confirm'} />
        {user && !isCurrentUser && (
          <>
            {(userStatus === UserStatus.Disabled || userStatus === UserStatus.Locked) && (
              <PrimaryButton
                className="ms-3"
                colour="outline-primary"
                type="button"
                onClick={() => changeUserStatus(UserStatusAction.Enable)}
                disabled={isSubmitting}
              >
                {userStatus === UserStatus.Disabled ? 'Re-enable' : 'Unlock'}
              </PrimaryButton>
            )}
            {userStatus !== UserStatus.Disabled && (
              <PrimaryButton
                className="ms-3"
                colour="outline-primary"
                type="button"
                onClick={() => changeUserStatus(UserStatusAction.Disable)}
                disabled={isSubmitting}
              >
                Disable
              </PrimaryButton>
            )}
            <PrimaryButton
              className="ms-3"
              colour="outline-primary"
              type="button"
              onClick={() => changeUserStatus(UserStatusAction.Delete)}
              disabled={isSubmitting}
            >
              Delete
            </PrimaryButton>
          </>
        )}
      </div>
    </Form>
  );
};

export default UserForm;
