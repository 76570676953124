import { Provider } from 'nrosh-common/Api/ProvidersApi';
import { SurveyChecks } from 'nrosh-common/Api/SurveysApi';
import { FormEvent, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import { AccessibleFeedback } from '@/Components/Form/AccessibleFeedback';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { MyProviderApi, ProvidersApi } from '@/Helpers/Apis';
import { getValidityProps } from '@/Helpers/Forms';
import { emailRegex } from '@/Helpers/Validation';
import {
  ConfirmSubmissionPage,
  useNextAndPreviousConfirmPath,
} from '@/Pages/Submissions/Confirmation/ConfirmSubmissionRouteHelpers';
import SubmissionBackButton from '@/Pages/Submissions/Confirmation/SubmissionBackButton';
import { SubmissionConfirmation } from '@/Pages/Submissions/Confirmation/SubmissionConfirmation';
import SubmissionConfirmationCheckbox from '@/Pages/Submissions/Confirmation/SubmissionConfirmationCheckbox';

type ConfirmFeesInvoiceFormProps = {
  provider: Provider;
  submissionConfirmationState: SubmissionConfirmation;
  checks: SurveyChecks;
  isAdmin: boolean;
};

const ConfirmFeesInvoiceForm = ({
  provider,
  submissionConfirmationState,
  checks,
  isAdmin,
}: ConfirmFeesInvoiceFormProps): JSX.Element => {
  const [isSubmitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [emailConfirmed, setEmailConfirmed] = useState(submissionConfirmationState.feesInvoiceEmailConfirmed ?? false);
  const [feesInvoiceEmail, setFeesInvoiceEmail] = useState(provider.feesInvoiceEmail);

  const { previousPage, nextPage } = useNextAndPreviousConfirmPath(
    ConfirmSubmissionPage.FeesInvoiceEmail,
    isAdmin,
    checks,
  );

  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    if (!event.currentTarget.checkValidity()) {
      setValidated(true);
      return;
    }

    setValidated(false);
    setSubmitting(true);
    const request = isAdmin
      ? ProvidersApi.editProviderFeesInvoiceEmail(provider.id, { feesInvoiceEmail })
      : MyProviderApi.editProvider({ feesInvoiceEmail });
    const response = await request.raw;

    if (response.ok) {
      navigate(nextPage, {
        state: {
          detailsCorrect: submissionConfirmationState.detailsCorrect,
          updateRequestSent: submissionConfirmationState.updateRequestSent,
          feesInvoiceEmailConfirmed: true,
          organisationalDetailsConfiremd: submissionConfirmationState.organisationalDetailsConfirmed,
        },
      });
    } else {
      setSubmitting(false);
    }
  };

  return (
    <Form onSubmit={onSubmit} noValidate>
      <Form.Group controlId="fees-invoice-email" className="mb-2">
        <Form.Label>Email Address</Form.Label>
        <Form.Control
          value={feesInvoiceEmail ?? ''}
          onChange={(e) => {
            setEmailInvalid(false);
            setFeesInvoiceEmail(e.target.value);
          }}
          required
          minLength={6}
          maxLength={256}
          pattern={emailRegex.source}
          onInvalid={() => setEmailInvalid(true)}
          {...getValidityProps(validated && emailInvalid, 'fees-invoice-email-feedback')}
        />
        <AccessibleFeedback displayFeedback={emailInvalid} id="fees-invoice-email-feedback">
          Please provide a valid email address
        </AccessibleFeedback>
      </Form.Group>
      <SubmissionConfirmationCheckbox
        validated={validated}
        checked={emailConfirmed}
        id="confirm-email"
        label="I confirm this email address is correct"
        onChange={(e) => setEmailConfirmed(e.target.checked)}
      />
      <div className="mt-3 d-flex gap-2">
        <SubmissionBackButton previousPage={previousPage} submissionConfirmationState={submissionConfirmationState} />
        {!isSubmitting ? (
          <PrimaryButton type="submit" disabled={!emailConfirmed}>
            Confirm
          </PrimaryButton>
        ) : (
          <LoadingButton message="Confirm" />
        )}
      </div>
    </Form>
  );
};
export default ConfirmFeesInvoiceForm;
