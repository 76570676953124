import { User } from 'nrosh-common/Api/UsersApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { Alert, Stack } from 'react-bootstrap';
import { generatePath, useParams } from 'react-router-dom';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import { UsersApi } from '@/Helpers/Apis';
import { userPages } from '@/Pages/Home/SitePages';
import EditUserEmailForm from '@/Pages/Users/EditUserEmailForm';

export const EditPersonalEmailPage = (): JSX.Element => {
  const { userId } = useParams();
  const [user] = useEndpoint<User>(UsersApi.getUser, userId);

  if (!user) {
    return <LoadingMessage />;
  }

  return (
    <Stack gap={5} className="mt-6">
      <h1>Edit email address</h1>
      <Alert variant="warning" className="w-75">
        When you update your email address a confirmation email will be sent to the new address. Please note that you
        will not be able to log in to DCS again until you have clicked on the confirmation link in this email.
      </Alert>
      <EditUserEmailForm
        hasRestrictedDomain={!user.providerId}
        user={user}
        authoriseMessage="To update your email address, enter your password and click update"
        redirectPath={generatePath(userPages.UserPersonalDetails.path, { userId: user.id.toString() })}
      />
    </Stack>
  );
};
