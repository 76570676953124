import { SubmissionValidationIssues } from 'nrosh-common/Api/SubmissionsApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { FormEvent, useState } from 'react';
import { Alert, Form, Stack } from 'react-bootstrap';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import { useModal } from '@/Components/Modal/ModalProvider';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { SubmissionsApi } from '@/Helpers/Apis';
import { surveyDisplayName } from '@/Helpers/SurveyHelper';
import useSubmissionDetail from '@/Hooks/useSubmissionDetail';
import { submissionPages, surveyPages } from '@/Pages/Home/SitePages';
import { mapSubmissionValidationsToViewModels } from '@/Pages/Submissions/SubmissionHelpers';
import SubmissionValidationsView from '@/Pages/Submissions/SubmissionValidationsView';

const confirmationPrompt = (
  <div>
    <p>Are you sure you want to sign-off this submission?</p>
    <p>
      <strong> Once signed-off, it will no longer be editable.</strong>
    </p>
  </div>
);

const SignOffPage = (): JSX.Element => {
  const { surveyId, timePeriodId, submissionId } = useParams();
  const [submission] = useSubmissionDetail(submissionId!);
  const [validations] = useEndpoint<SubmissionValidationIssues>(
    SubmissionsApi.validateSubmissionAndGetValidationIssues,
    submissionId,
  );
  const navigate = useNavigate();

  const [reason, setReason] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isNotifying, setIsNotifying] = useState<boolean>(false);
  const { confirm } = useModal();

  if (!submission || !validations) {
    return <LoadingMessage />;
  }

  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    if (!(await confirm(confirmationPrompt))) return;

    const response = await SubmissionsApi.signOff(submissionId!, reason ?? null, isNotifying).raw;

    if (!response.ok) {
      const { message } = response.value;
      setError(message);
      return;
    }

    navigate(generatePath(submissionPages.SubmissionView.path, { surveyId, timePeriodId, submissionId }));
  };

  const failingValidationIssues = mapSubmissionValidationsToViewModels(validations).filter(
    (v) => !v.isPassing && !v.isApproved,
  );

  return (
    <Stack gap={5}>
      <PageHeader
        heading="Confirm sign-off"
        crumbsType={RootPathType.AdminSurveys}
        crumbs={[
          {
            name: surveyDisplayName(submission.surveyName, submission.timePeriodName),
            path: surveyPages.SurveyView.path,
          },
          {
            name: submission.provider.name,
            path: submissionPages.SubmissionView.path,
          },
          {
            name: 'Confirm Sign-off',
            path: submissionPages.AdminSignOffPage.path,
          },
        ]}
      />
      <Form className="w-50" onSubmit={onSubmit}>
        <Form.Group className="mb-3" controlId="reason">
          <Form.Label>Reason (optional)</Form.Label>
          <Form.Control value={reason} onChange={(e) => setReason(e.target.value)} />
        </Form.Group>
        <Form.Check
          type="checkbox"
          label="Notify provider users"
          id="notify-toggle"
          className="mb-3"
          onChange={(e) => setIsNotifying(e.target.checked)}
          checked={isNotifying}
        />
        <PrimaryButton className="me-3" type="submit" value="confirm">
          Confirm
        </PrimaryButton>
      </Form>
      {error && (
        <Alert variant="danger" className="mt-3" dismissible onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
      <SubmissionValidationsView
        validations={failingValidationIssues}
        submissionData={submission}
        exportButtonText="Export"
        commentsAreReadonly
        isAdmin
      />
    </Stack>
  );
};

export default SignOffPage;
