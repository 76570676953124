import { DimensionalMember, DimensionalRegion, SubmissionPartValidation } from 'nrosh-common/Api/SubmissionsApi';
import { nameof } from 'nrosh-common/Helpers/StringHelpers';
import { ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import { createCellId } from '@/Components/Spreadsheet/CellIdHelpers';
import { DimensionValue, Layouts, SpreadsheetValues } from '@/Components/Spreadsheet/SpreadsheetTypes';
import { createContext } from '@/Helpers/Context';
import { debounceOverFirstArg } from '@/Helpers/Debounce';
import { EnginePendingDeletionMessage } from '@/Pages/Submissions/EnginePendingDeletionMessage';
import { ImportInProgressMessage } from '@/Pages/Submissions/ImportInProgressMessage';
import { mergeValidationsWithCurrentStatus } from '@/Pages/Submissions/SubmissionHelpers';
import { useActiveCellContext } from '@/Pages/Submissions/SubmissionPartContext/ActiveCellContext';
import useFactHub, { DimensionalMemberUpdate, FactUpdate } from '@/Pages/Submissions/SubmissionPartContext/useFactHub';
import useSubmissionPartLayout from '@/Pages/Submissions/SubmissionPartContext/useSubmissionPartLayout';
import useSubmissionPartState from '@/Pages/Submissions/SubmissionPartContext/useSubmissionPartState';

export type SubmissionPartLiveData = {
  data: SpreadsheetValues;
  dimensionMembers: DimensionalMember[];
  dimensionRegions: DimensionalRegion[];
  dimensionValues: DimensionValue[];
  validations: SubmissionPartValidation[];
  layouts: Layouts;
  updateDataPoint: (
    dataPointId: string,
    value: string,
    dimension1MemberId: number | null,
    dimension2MemberId: number | null,
    sendToHub?: boolean,
  ) => void;
  updateCommentInHub: (validationId: string, comment: string) => void;
  deleteCommentInHub: (validationId: string) => void;
  updateDimensionValue: (dimensionMemberId: number | null, regionId: number, index: number) => void;
  updateValidationIssue: (validationId: string, isApproved: boolean) => void;
};

const SubmissionPartLiveDataContext = createContext<SubmissionPartLiveData>();

export type SubmissionPartLiveDataContextProviderProps = {
  submissionPartId: string;
  children: ReactNode;
};

export const SubmissionPartLiveDataContextProvider = ({
  submissionPartId,
  children,
}: SubmissionPartLiveDataContextProviderProps): JSX.Element => {
  const submissionPartLayout = useSubmissionPartLayout(submissionPartId);
  const submissionPartState = useSubmissionPartState(submissionPartId);
  const [importInProgress, setImportInProgress] = useState<boolean>(false);
  const [enginePendingDeletion, setEnginePendingDeletion] = useState<boolean>(false);
  const { activeCell } = useActiveCellContext();

  const {
    data,
    dimensionValues,
    validationIssues,
    validationStatuses,
    validationComments,
    setInitialData,
    updateFact,
    updateDimensionalMember,
    swapDimensionMemberInDataPoints,
    updateValidation,
    updateValidationApproval,
    updateComment,
    deleteComment,
  } = submissionPartState;

  // Use memo here so that the layouts are fixed unless the submissionPartLayout changes
  const layouts = useMemo(
    () =>
      submissionPartLayout && {
        tabLayouts: submissionPartLayout.tabLayouts,
        listTypes: submissionPartLayout.listTypes,
      },
    [submissionPartLayout],
  );

  // Because updating the dimensional members requires knowledge of the layout, we need to plumb it
  // together in this component, rather than in the useSubmissionPartState hook.
  const updateDimensionValueInState = useCallback(
    (dimensionMemberUpdate: DimensionalMemberUpdate) => {
      const { index, dimensionalMemberId: dimensionMemberId, dimensionalRegionId: regionId } = dimensionMemberUpdate;
      if (dimensionValues && submissionPartLayout) {
        const existingValue = dimensionValues.find((dv) => dv.regionId === regionId && dv.index === index);
        updateDimensionalMember(dimensionMemberUpdate);
        if (existingValue && existingValue.dimensionMemberId !== dimensionMemberId) {
          const region = submissionPartLayout.dimensionRegions.find((dr) => dr.id === regionId);
          if (region) {
            const dataPointsToUpdate = region.dataPoints;
            swapDimensionMemberInDataPoints(existingValue.dimensionMemberId, dimensionMemberId, dataPointsToUpdate);
          }
        }
      }
    },
    [submissionPartLayout, dimensionValues, updateDimensionalMember, swapDimensionMemberInDataPoints],
  );

  const onFactUpdated = useCallback(
    (factUpdate: FactUpdate) => {
      if (
        activeCell !==
        createCellId(factUpdate.dataPointId, factUpdate.dimension1MemberId, factUpdate.dimension2MemberId)
      ) {
        updateFact(factUpdate);
      }
    },
    [updateFact, activeCell],
  );

  const factHub = useFactHub(
    parseInt(submissionPartId, 10),
    setInitialData,
    onFactUpdated,
    updateDimensionValueInState,
    updateValidation,
    updateValidationApproval,
    updateComment,
    deleteComment,
    importInProgress,
    setImportInProgress,
    enginePendingDeletion,
    setEnginePendingDeletion,
  );

  const debouncedUpdateFactInHub = useMemo(
    () =>
      debounceOverFirstArg(
        (key: string, dataPointId: string, value: string, dimension1MemberId?: number, dimension2MemberId?: number) => {
          factHub?.updateFact(dataPointId, value, dimension1MemberId, dimension2MemberId).catch(() => {});
        },
      ),
    [factHub],
  );

  const updateDataPoint = useCallback(
    (
      dataPointId: string,
      value: string,
      dimension1MemberId: number | null,
      dimension2MemberId: number | null,
      sendToHub: boolean = true,
    ) => {
      updateFact({ dataPointId, newValue: value, dimension1MemberId, dimension2MemberId });
      if (sendToHub) {
        const key = `${dataPointId}:${dimension1MemberId || ''}:${dimension2MemberId || ''}`;
        debouncedUpdateFactInHub(key, dataPointId, value, dimension1MemberId, dimension2MemberId);
      }
    },
    [updateFact, debouncedUpdateFactInHub],
  );

  const updateDimensionValue = useCallback(
    (dimensionMemberId: number | null, regionId: number, index: number) => {
      if (factHub) {
        updateDimensionValueInState({ dimensionalMemberId: dimensionMemberId, dimensionalRegionId: regionId, index });
        factHub.setUsedDimension(regionId, index, dimensionMemberId).catch(() => {});
      }
    },
    [updateDimensionValueInState, factHub],
  );

  if (enginePendingDeletion) {
    return <EnginePendingDeletionMessage />;
  }

  if (importInProgress) {
    return <ImportInProgressMessage />;
  }

  if (
    !submissionPartLayout ||
    !data ||
    !validationStatuses ||
    !factHub ||
    !validationIssues ||
    !dimensionValues ||
    !layouts
  ) {
    return <LoadingMessage />;
  }

  const { dimensionMembers, dimensionRegions } = submissionPartLayout;

  const updateValidationIssue = async (validationId: string, isApproved: boolean): Promise<void> => {
    updateValidationApproval({ validationId, isApproved });
    await factHub.updateValidationApproval(validationId, isApproved);
  };

  const validations: SubmissionPartValidation[] = mergeValidationsWithCurrentStatus(
    submissionPartLayout.submissionValidations,
    validationStatuses,
    validationIssues,
    validationComments,
  );

  // TODO-343: Look at memoising this
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    data,
    dimensionMembers,
    dimensionRegions,
    validations,
    layouts,
    dimensionValues,
    updateDataPoint,
    updateCommentInHub: factHub.updateComment,
    deleteCommentInHub: factHub.deleteComment,
    updateDimensionValue,
    updateValidationIssue,
  };

  return <SubmissionPartLiveDataContext.Provider value={value}>{children}</SubmissionPartLiveDataContext.Provider>;
};

export const useSubmissionPartLiveData = (): SubmissionPartLiveData => {
  const context = useContext(SubmissionPartLiveDataContext);
  if (!context) {
    throw new Error(
      `${nameof({ useSubmissionPartLiveData })} must be used within ${nameof({
        SubmissionPartLiveDataContextProvider,
      })}`,
    );
  }

  return context;
};
