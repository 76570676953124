export const mapObject = <TKey extends string | number, TSource, TOut>(
  sourceObject: Record<TKey, TSource>,
  mapper: (item: TSource) => TOut,
): Record<TKey, TOut> => {
  const output = {} as Record<TKey, TOut>;
  for (const key of Object.keys(sourceObject) as TKey[]) {
    output[key] = mapper(sourceObject[key]);
  }
  return output;
};
