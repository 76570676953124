import { saveAs } from 'file-saver';
import { SurveyInstancePart } from 'nrosh-common/Api/SurveysApi';
import { useState } from 'react';
import { Badge, Dropdown } from 'react-bootstrap';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { TertiaryButton } from '@/Components/Buttons/DCSButton';
import { TertiaryLinkButton } from '@/Components/Buttons/TertiaryLinkButton';
import DropdownButton from '@/Components/Dropdown/DropdownButton';
import DropdownMenu from '@/Components/Dropdown/DropdownMenu';
import IconToggle from '@/Components/Dropdown/IconToggle';
import FileDownloadButton from '@/Components/FileDownload/FileDownloadButton';
import Delete from '@/Components/Icons/Delete';
import DocumentCheckmark from '@/Components/Icons/DocumentCheckmark';
import Download from '@/Components/Icons/Download';
import DropdownArrow from '@/Components/Icons/DropdownArrow';
import Edit from '@/Components/Icons/Edit';
import MoreVertical from '@/Components/Icons/MoreVertical';
import View from '@/Components/Icons/View';
import '@/Components/SurveyForm/SurveyPartCard.scss';
import LinkButton from '@/Components/Links/LinkButton';
import TooltippedInfoIcon from '@/Components/Tooltips/TooltippedInfoIcon';
import { SurveyPartsApi } from '@/Helpers/Apis';
import { surveyPages } from '@/Pages/Home/SitePages';
import { SurveyPartForm } from '@/Pages/Surveys/ConfigureSurveyPage';

type UploadCardHeaderProps = {
  title: string;
  uploaded: boolean;
};

type SurveyFileCardContentProps = {
  dataDictionaryUploaded: boolean;
  dataDictAndSurveyFileUploaded: boolean;
  isSurveyClosed: boolean;
};

type SurveyButtonProps = {
  dataDictionaryUploaded: boolean;
  surveyFileUploaded: boolean;
  surveyPartId: number;
  timePeriodId: number;
};

type DataDictionaryButtonProps = {
  dataDictionaryUploaded: boolean;
  surveyPartId: number;
  surveyId: number;
  timePeriodId: number;
  isSurveyClosed: boolean;
};

type DeleteAction = {
  isDeletable: boolean;
  delete?: () => Promise<void>;
  cannotDeleteReason?: string;
};

type SurveyPartCardProps = {
  partData: SurveyInstancePart;
  isSurveyClosed: boolean;
  deleteAction: DeleteAction;
  surveyPartNames: string[];
  addOrEditPart: (newPartName: string, currentSurveyPartName?: string) => Promise<boolean>;
  isOnlyInstanceOfPart: boolean;
  sharedPartInfoMessage: string;
};

type ManageCardType = {
  surveyPartId: number;
  timePeriodId: number;
  isSurveyClosed: boolean;
};

type DataDictionaryManageCardProps = ManageCardType & { surveyId: number; dataDictionaryUploaded: boolean };

export type SurveyFileManageCardProps = ManageCardType & {
  dataDictAndSurveyFileUploaded: boolean;
  dataDictionaryUploaded: boolean;
  surveyFileUploaded: boolean;
};

const UploadCardHeader = ({ title, uploaded }: UploadCardHeaderProps): JSX.Element => (
  <div className="d-flex justify-content-between w-100 mb-3">
    <h3>{title}</h3>
    {uploaded ? <span className="uploadedText">Uploaded</span> : <span className="notUploadedText">Not uploaded</span>}
  </div>
);

const SurveyFileCardContent = ({
  dataDictionaryUploaded,
  dataDictAndSurveyFileUploaded,
  isSurveyClosed,
}: SurveyFileCardContentProps): JSX.Element => {
  if (isSurveyClosed) {
    return <p>Click the three dot menu in the corner to view version history.</p>;
  }

  if (dataDictAndSurveyFileUploaded) {
    return (
      <p>
        You can use the <span className="highlightText m-0 p-0 d-inline">Excel add-in</span> to upload new versions of
        the import file. Click the three dot menu in the corner to view version history.
      </p>
    );
  }

  if (dataDictionaryUploaded) {
    return (
      <ol className="uploadFileList">
        <li>Export the survey file (Excel file).</li>
        <li>Open the file in Excel and make necessary changes.</li>
        <li>
          Use the <span className="highlightText m-0 p-0 d-inline">Excel add-in</span> to upload the import file.
        </li>
      </ol>
    );
  }

  return <p>You must first upload a data dictionary before you can upload the survey file.</p>;
};

const SurveyFileButton = ({
  dataDictionaryUploaded,
  surveyFileUploaded,
  surveyPartId,
  timePeriodId,
}: SurveyButtonProps): JSX.Element | null => {
  const triggerTemplateDownload = async (targetSurveyPartId: number, targetTimePeriodId: number): Promise<void> => {
    // eslint-disable-next-line no-useless-catch
    try {
      const templateFile = await SurveyPartsApi.getSurveyPartImportTemplate(targetSurveyPartId, targetTimePeriodId);
      saveAs(URL.createObjectURL(templateFile.content), templateFile.fileName);
    } catch (e) {
      // TODO-146: Add error handling instead of just throwing error, and re-enable lint rule above
      throw e;
    }
  };

  if (!dataDictionaryUploaded) {
    return null;
  }

  return surveyFileUploaded ? (
    <TertiaryButton
      colour="primary"
      icon={<Download />}
      onClick={() => triggerTemplateDownload(surveyPartId, timePeriodId)}
    >
      Download survey file
    </TertiaryButton>
  ) : (
    <FileDownloadButton
      onDownload={() => triggerTemplateDownload(surveyPartId, timePeriodId)}
      buttonText="Download survey file template"
    />
  );
};

const DataDictionaryButton = ({
  dataDictionaryUploaded,
  surveyPartId,
  surveyId,
  timePeriodId,
  isSurveyClosed,
}: DataDictionaryButtonProps): JSX.Element => {
  const surveyIdString = surveyId?.toString();
  const timePeriodIdString = timePeriodId?.toString();
  const surveyPartIdString = surveyPartId?.toString();

  return dataDictionaryUploaded ? (
    <TertiaryLinkButton
      icon={isSurveyClosed ? <DocumentCheckmark /> : <Edit />}
      to={generatePath(surveyPages.SurveyPartEdit.path, {
        surveyPartId: surveyPartIdString,
        surveyId: surveyIdString,
        timePeriodId: timePeriodIdString,
      })}
      text={isSurveyClosed ? 'View data dictionary' : 'Edit'}
    />
  ) : (
    <LinkButton
      to={generatePath(surveyPages.SurveyPartEdit.path, {
        surveyPartId: surveyPartIdString,
        surveyId: surveyIdString,
        timePeriodId: timePeriodIdString,
      })}
    >
      Upload data dictionary
    </LinkButton>
  );
};

export const DataDictionaryManageCard = ({
  dataDictionaryUploaded,
  surveyPartId,
  surveyId,
  timePeriodId,
  isSurveyClosed,
}: DataDictionaryManageCardProps): JSX.Element => (
  <div className="uploadCard d-flex flex-column align-items-start p-4 gap-3">
    <div className="flex-grow-1">
      <UploadCardHeader title="Data dictionary" uploaded={dataDictionaryUploaded} />
      <p className="m-0">
        The data dictionary is where the metadata about the data points to be collected is specified.
      </p>
    </div>
    <DataDictionaryButton
      dataDictionaryUploaded={dataDictionaryUploaded}
      surveyPartId={surveyPartId}
      surveyId={surveyId}
      timePeriodId={timePeriodId}
      isSurveyClosed={isSurveyClosed}
    />
  </div>
);

export const SurveyFileManageCard = ({
  surveyFileUploaded,
  dataDictAndSurveyFileUploaded,
  dataDictionaryUploaded,
  surveyPartId,
  timePeriodId,
  isSurveyClosed,
}: SurveyFileManageCardProps): JSX.Element => (
  <div className="uploadCard d-flex flex-column align-items-start p-4 gap-3">
    <div className="flex-grow-1">
      <UploadCardHeader title="Survey file" uploaded={surveyFileUploaded} />
      <SurveyFileCardContent
        dataDictAndSurveyFileUploaded={dataDictAndSurveyFileUploaded}
        dataDictionaryUploaded={dataDictionaryUploaded}
        isSurveyClosed={isSurveyClosed}
      />
    </div>
    <SurveyFileButton
      dataDictionaryUploaded={dataDictionaryUploaded}
      surveyPartId={surveyPartId}
      timePeriodId={timePeriodId}
      surveyFileUploaded={surveyFileUploaded}
    />
  </div>
);

const SurveyPartCard = ({
  partData,
  deleteAction,
  isSurveyClosed,
  surveyPartNames,
  addOrEditPart,
  isOnlyInstanceOfPart,
  sharedPartInfoMessage,
}: SurveyPartCardProps): JSX.Element => {
  const { surveyId, timePeriodId } = useParams();
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const timePeriodIdNum = parseInt(timePeriodId!, 10);
  const surveyIdNum = parseInt(surveyId!, 10);
  const { surveyPartId } = partData;
  const navigate = useNavigate();

  const dataDictionaryUploaded = partData.hasDataDictionary;
  const surveyFileUploaded = partData.hasUploadedImportTemplate;
  const dataDictAndSurveyFileUploaded = dataDictionaryUploaded && surveyFileUploaded;

  return (
    <div className="partCard d-flex flex-column align-items-start p-4 mt-3 gap-3">
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="d-flex mr-auto align-items-center gap-3">
          {isEditing ? (
            <SurveyPartForm
              currentSurveyPartName={partData.name}
              surveyPartNames={surveyPartNames}
              addOrEditPart={addOrEditPart}
              setIsEditing={setIsEditing}
              isOnlyInstanceOfPart={isOnlyInstanceOfPart}
              sharedPartInfoMessage={sharedPartInfoMessage}
            />
          ) : (
            <>
              <h2>{partData.name}</h2>
              {!isOnlyInstanceOfPart && <TooltippedInfoIcon message={sharedPartInfoMessage} />}
            </>
          )}
          <Badge
            pill
            className="fw-normal fs-4"
            bg={dataDictAndSurveyFileUploaded ? 'dcs-status-open' : 'dcs-status-indevelopment'}
            text="dark"
          >
            {dataDictAndSurveyFileUploaded ? 'Configured' : 'Not configured'}
          </Badge>
        </div>
        <div className="d-flex">
          <Dropdown>
            <IconToggle icon={<MoreVertical />} />
            <DropdownMenu>
              <DropdownButton icon={<Edit />} text="Edit part name" onClick={() => setIsEditing(true)} />
              <DropdownButton
                icon={<View />}
                text="View version history"
                onClick={() =>
                  navigate(
                    generatePath(surveyPages.SurveyPartImportTemplateHistory.path, {
                      surveyId,
                      timePeriodId,
                      surveyPartId: surveyPartId.toString(),
                    }),
                  )
                }
              />
              <DropdownButton
                icon={<Delete />}
                text="Delete survey part"
                disabled={!deleteAction.isDeletable}
                onClick={deleteAction?.delete}
                tooltipText={deleteAction?.cannotDeleteReason}
              />
            </DropdownMenu>
          </Dropdown>
          <button
            className="iconButton"
            type="button"
            aria-label={collapsed ? 'expand' : 'collapse'}
            onClick={() => setCollapsed(!collapsed)}
          >
            <DropdownArrow direction={collapsed ? 'down' : 'up'} />
          </button>
        </div>
      </div>
      {!collapsed && (
        <div className="uploadCardWrapper align-item-start w-100 gap-4">
          <DataDictionaryManageCard
            dataDictionaryUploaded={dataDictionaryUploaded}
            surveyPartId={surveyPartId}
            surveyId={surveyIdNum}
            timePeriodId={timePeriodIdNum}
            isSurveyClosed={isSurveyClosed}
          />
          <SurveyFileManageCard
            surveyFileUploaded={surveyFileUploaded}
            dataDictAndSurveyFileUploaded={dataDictAndSurveyFileUploaded}
            dataDictionaryUploaded={dataDictionaryUploaded}
            surveyPartId={surveyPartId}
            timePeriodId={timePeriodIdNum}
            isSurveyClosed={isSurveyClosed}
          />
        </div>
      )}
    </div>
  );
};

export default SurveyPartCard;
