import { PageTitle } from 'nrosh-common/Api/Enums';
import { ReactNode } from 'react';
import { formatISODateString } from '@/Helpers/DateHelpers';

export enum PublishStatus {
  Published = 'Published',
  Draft = 'Draft',
  Scheduled = 'Scheduled',
}

export const PageTitleDict: { [key in PageTitle]: string } = {
  [PageTitle.Home]: 'Home',
  [PageTitle.About]: 'About',
  [PageTitle.PrivacyNotice]: 'Privacy notice',
  [PageTitle.AccessibilityStatement]: 'Accessibility statement',
  [PageTitle.TermsAndConditions]: 'Terms and conditions ',
  [PageTitle.ContactUs]: 'Contact us',
};

export const getPublishStatus = (publishDateTime: string): PublishStatus => {
  if (publishDateTime) {
    return new Date(publishDateTime).getTime() <= Date.now() ? PublishStatus.Published : PublishStatus.Scheduled;
  }
  return PublishStatus.Draft;
};

const publishedWarning = (date: string, contentType: string): JSX.Element => (
  <div>
    <p>
      This {contentType} was published on <strong>{date}</strong>, are you sure you wish to continue?
    </p>
    <p>
      <strong> All information will be permanently lost. </strong>
    </p>
  </div>
);

const scheduledWarning = (date: string, contentType: string): JSX.Element => (
  <div>
    <p>
      This {contentType} is scheduled to be published on <strong>{date}</strong>, are you sure you wish to continue?
    </p>
    <p>
      <strong> All information will be permanently lost. </strong>
    </p>
  </div>
);

const draftWarning = (contentType: string): JSX.Element => (
  <div>
    <p>Are you sure you wish to delete this {contentType}?</p>
    <p>
      <strong> All information will be permanently lost. </strong>
    </p>
  </div>
);

export const checkItemCanBeDeleted = async (
  publishDateTime: string,
  contentType: string,
  triggerUserConfirmation: (msg: ReactNode) => Promise<boolean>,
): Promise<boolean> => {
  const formattedDate = formatISODateString(publishDateTime);
  const publishStatus = getPublishStatus(publishDateTime);

  if (
    publishStatus === PublishStatus.Published &&
    !(await triggerUserConfirmation(publishedWarning(formattedDate, contentType)))
  ) {
    return false;
  }
  if (publishStatus === PublishStatus.Draft && !(await triggerUserConfirmation(draftWarning(contentType)))) {
    return false;
  }
  return !(
    publishStatus === PublishStatus.Scheduled &&
    !(await triggerUserConfirmation(scheduledWarning(formattedDate, contentType)))
  );
};
