import { ChangeEvent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { AccessibleFeedback } from '@/Components/Form/AccessibleFeedback';
import { getValidityProps } from '@/Helpers/Forms';
import { containsANonWhitespaceCharacterRegex } from '@/Helpers/Validation';

export type EditableCellProps = {
  value: string | number;
  row: { index: number };
  column: { id: string };
  updateData: (index: number, id: string, value: string) => void;
  setDirty?: () => void;
  ariaLabel: string;
  invalidValueFeedback?: string;
  inputType?: string;
  required?: boolean;
  readOnly?: boolean;
  feedbackId?: string;
  validated?: boolean;
};

const EditableCell = ({
  value,
  row: { index },
  column: { id },
  updateData,
  setDirty,
  ariaLabel,
  invalidValueFeedback,
  inputType = 'text',
  required = true,
  readOnly = false,
  feedbackId = '',
  validated = false,
}: EditableCellProps): JSX.Element => {
  const [newValue, setNewValue] = useState(value);
  const [invalid, setInvalid] = useState<boolean>(false);

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setNewValue(e.target.value);
    updateData(index, id, e.target.value);
    if (setDirty) {
      setDirty();
    }
  };

  return (
    <Form.Group>
      <Form.Control
        type={inputType}
        value={newValue === null ? '' : newValue}
        onChange={onChange}
        onInput={() => setInvalid(false)}
        aria-label={ariaLabel}
        required={required}
        pattern={containsANonWhitespaceCharacterRegex.source}
        readOnly={readOnly}
        {...getValidityProps(validated && invalid, feedbackId)}
        onInvalid={() => setInvalid(true)}
      />
      <AccessibleFeedback id={feedbackId} displayFeedback={validated && invalid}>
        {invalidValueFeedback}
      </AccessibleFeedback>
    </Form.Group>
  );
};

export default EditableCell;
