import { Breadcrumbs, Crumb, RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { providerPages, submissionPages, surveyPages } from '@/Pages/Home/SitePages';

type Props = {
  isRshUser: boolean;
  surveyDisplayName: string;
  providerName: string;
  additionalRshCrumbs: Crumb[];
  additionalProviderCrumbs: Crumb[];
};

export const SubmissionBreadcrumbs = ({
  isRshUser,
  surveyDisplayName,
  providerName,
  additionalRshCrumbs,
  additionalProviderCrumbs,
}: Props): JSX.Element => {
  const crumbs = isRshUser
    ? [
        {
          name: surveyDisplayName,
          path: surveyPages.SurveyView.path,
        },
        {
          name: providerName,
          path: submissionPages.SubmissionView.path,
        },
        ...additionalRshCrumbs,
      ]
    : [
        {
          name: surveyDisplayName,
          path: providerPages.SubmissionsDetail.path,
        },
        ...additionalProviderCrumbs,
      ];
  const type = isRshUser ? RootPathType.AdminSurveys : RootPathType.ProviderSurveys;
  return <Breadcrumbs crumbs={crumbs} type={type} />;
};
