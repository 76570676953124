import { useEffect, useState } from 'react';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';

// This component is a wrapper around components which are very slow to initially render.
// It will display a loading spinner until the render completes.
const LoadOnInitialRender = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [initialRender, setInitialRender] = useState(true);

  useEffect(() => {
    // We need a timeout to ensure that the initial render is displayed to the user
    // before the second render is attempted.
    setTimeout(() => setInitialRender(false), 10);
  }, []);

  return initialRender ? <LoadingMessage /> : children;
};

export default LoadOnInitialRender;
