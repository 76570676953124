import { SurveyStatus } from 'nrosh-common/Api/SubmissionsApi';
import React from 'react';
import SurveyStatusBadge from '@/Components/Badges/SurveyStatusBadge';

type PageHeadingWithSurveyStatusBadgeProps = {
  title: string;
  status: SurveyStatus;
};

const PageHeadingWithSurveyStatusBadge = ({ title, status }: PageHeadingWithSurveyStatusBadgeProps): JSX.Element => (
  <div className="d-inline-flex align-items-center">
    <h1>
      <span>{title}</span>
    </h1>
    <SurveyStatusBadge status={status} />
  </div>
);

export default PageHeadingWithSurveyStatusBadge;
