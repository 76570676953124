import { RegisteredDetails } from 'nrosh-common/Api/ProvidersApi';
import '@/Pages/Providers/RegisteredDetailsTable.scss';
import { formatISODate } from '@/Helpers/DateHelpers';
import {
  profitStatusNames,
  providerSizeNames,
  providerTypeNames,
  registrationStatusNames,
  reportingStatusNames,
} from '@/Pages/Providers/ProviderEnums';

type RegisteredDetailsTableProps =
  | {
      showFullDetails: boolean;
      showEditableFields: true;
      registeredDetails: RegisteredDetails & { feesInvoiceEmail: string | null };
    }
  | {
      showFullDetails: boolean;
      showEditableFields: false;
      registeredDetails: RegisteredDetails;
    };

export const RegisteredDetailsTable = ({
  registeredDetails,
  showFullDetails,
  showEditableFields,
}: RegisteredDetailsTableProps): JSX.Element => (
  <table className="registeredDetailsTable">
    <tbody>
      <tr>
        <th>Provider number</th>
        <td>{registeredDetails.providerNumber}</td>
      </tr>
      <tr>
        <th>Provider name</th>
        <td>{registeredDetails.providerName}</td>
      </tr>
    </tbody>
    {showFullDetails && (
      <tbody>
        <tr>
          <th>Provider type</th>
          <td>{providerTypeNames[registeredDetails.providerType]}</td>
        </tr>
        <tr>
          <th>Provider reporting status</th>
          <td>{reportingStatusNames[registeredDetails.reportingStatus]}</td>
        </tr>
        <tr>
          <th>Provider size</th>
          <td>{providerSizeNames[registeredDetails.providerSize]}</td>
        </tr>
      </tbody>
    )}
    <tbody>
      <tr>
        <th>Registered address</th>
        <td>
          <div>
            {registeredDetails.registeredAddress.addressLine1 && (
              <p className="mb-2">{registeredDetails.registeredAddress.addressLine1}</p>
            )}
            {registeredDetails.registeredAddress.addressLine2 && (
              <p className="mb-2">{registeredDetails.registeredAddress.addressLine2}</p>
            )}
            {registeredDetails.registeredAddress.addressLine3 && (
              <p className="mb-2">{registeredDetails.registeredAddress.addressLine3}</p>
            )}
            {registeredDetails.registeredAddress.addressLine4 && (
              <p className="mb-2">{registeredDetails.registeredAddress.addressLine4}</p>
            )}
            {registeredDetails.registeredAddress.addressLine5 && (
              <p className="mb-2">{registeredDetails.registeredAddress.addressLine5}</p>
            )}
            <p className="mb-0">{registeredDetails.registeredAddress.postcode}</p>
          </div>
        </td>
      </tr>
    </tbody>
    {showFullDetails && (
      <tbody>
        <tr>
          <th>End of financial year</th>
          <td>{formatISODate(registeredDetails.endOfFinancialYear)}</td>
        </tr>
        <tr>
          <th>Registration status</th>
          <td>{registrationStatusNames[registeredDetails.registrationStatus]}</td>
        </tr>
        <tr>
          <th>Registration date</th>
          <td>{formatISODate(registeredDetails.registrationDate)}</td>
        </tr>
        <tr>
          <th>Deregistration date</th>
          <td>{formatISODate(registeredDetails.deregistrationDate)}</td>
        </tr>
        <tr>
          <th>Profit status</th>
          <td>{profitStatusNames[registeredDetails.profitStatus]}</td>
        </tr>
        <tr>
          <th>Included in data collection</th>
          <td>{registeredDetails.isDisabled ? 'No' : 'Yes'}</td>
        </tr>
      </tbody>
    )}
    {showEditableFields && (
      <tbody>
        <tr>
          <th>Is test provider</th>
          <td>{registeredDetails.isTestProvider ? 'Yes' : 'No'}</td>
        </tr>
        <tr>
          <th>Fees invoice email</th>
          <td>{registeredDetails.feesInvoiceEmail}</td>
        </tr>
      </tbody>
    )}
  </table>
);
