import { SurveyDeclarationResponse as SurveyDeclaration } from 'nrosh-common/Api/SurveysApi';
import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import React from 'react';
import { Form } from 'react-bootstrap';
import { AccessibleFeedback } from '@/Components/Form/AccessibleFeedback';
import Delete from '@/Components/Icons/Delete';
import { getValidityProps } from '@/Helpers/Forms';
import { containsANonWhitespaceCharacterRegex } from '@/Helpers/Validation';

type Props = {
  surveyDeclarations: SurveyDeclaration[];
  setSurveyDeclarations: ReactStateSetter<SurveyDeclaration[]>;
  setDirty: () => void;
  surveyDeclaration: SurveyDeclaration;
  index: number;
  readOnly: boolean;
  validated: boolean;
  deleteHandler: (declaration: SurveyDeclaration) => void;
};

const SurveyDeclarationInput = (props: Props): JSX.Element => {
  const {
    surveyDeclarations,
    setSurveyDeclarations,
    setDirty,
    surveyDeclaration: sd,
    index: i,
    readOnly,
    validated,
    deleteHandler,
  } = props;

  const isInvalid = !containsANonWhitespaceCharacterRegex.test(sd.declaration) || sd.declaration === '';

  return (
    <div className="input-group">
      <span className="input-group-text">{i + 1}</span>
      <Form.Control
        name="text"
        className="form-control"
        aria-label="Survey Declaration"
        placeholder="Before you submit your data please confirm that you have:"
        value={sd.declaration}
        readOnly={readOnly}
        onChange={(e) => {
          e.preventDefault();
          const index = surveyDeclarations.findIndex((x) => x === sd);
          if (index > -1) {
            const newState = [...surveyDeclarations];
            newState[index] = {
              ...newState[index],
              declaration: e.target.value,
            };
            setSurveyDeclarations(newState);
          }
          setDirty();
        }}
        {...getValidityProps(validated && isInvalid, `declaration-feedback-${i + 1}`)}
      />
      <button
        className="iconButton"
        type="button"
        aria-label="Delete"
        onClick={() => {
          deleteHandler(sd);
        }}
        disabled={readOnly}
      >
        <Delete />
      </button>
      <AccessibleFeedback id={`declaration-feedback-${i + 1}`} displayFeedback={validated && isInvalid}>
        Please enter a survey declaration
      </AccessibleFeedback>
    </div>
  );
};

export default SurveyDeclarationInput;
