import ApiClient, { APIHelpers, handleAPIRequest } from './ApiClient';
import { Tag } from './CommonTypes';

export type PublicDocument = {
  id: number;
  title: string;
  fileName: string;
  fileSizeBytes: number;
  fileExtension: string;
  uploadDate: string;
  priority?: number;
  updatedPriority?: boolean;
  tags: Tag[];
};

export type GetPublicDocumentResponse = {
  documents: PublicDocument[];
};

export type PublicDocumentMetadata = {
  title: string;
  tags: Tag[];
  fileName: string;
  fileSizeBytes: number;
};

export type DocumentPriority = {
  id: number;
  priority: number;
};

type DocumentPriorityRequest = {
  documentsPriorityList: DocumentPriority[];
};

const PublicDocumentsApiRoot = 'PublicDocuments';
const PublicDocumentsApiPaths = {
  ALL_DOCUMENTS: PublicDocumentsApiRoot,
  METADATA: (documentId: string) => `${PublicDocumentsApiRoot}/${documentId}`,
  DOWNLOAD: (documentId: string) => `${PublicDocumentsApiRoot}/${documentId}/Download`,
};

const publicDocumentsApi = (apiClient: ApiClient) => ({
  getAll: () => {
    return handleAPIRequest(
      apiClient.get(PublicDocumentsApiPaths.ALL_DOCUMENTS),
      APIHelpers.json<GetPublicDocumentResponse>,
      APIHelpers.standardError,
    );
  },

  editDocumentsPriority: (documentPriorityRequest: DocumentPriorityRequest) => {
    return handleAPIRequest(
      apiClient.patch(PublicDocumentsApiPaths.ALL_DOCUMENTS, documentPriorityRequest),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  upload: (formData: FormData) => {
    return handleAPIRequest(
      apiClient.postFile(PublicDocumentsApiPaths.ALL_DOCUMENTS, formData),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  getMetadata: (documentId: string) => {
    return handleAPIRequest(
      apiClient.get(PublicDocumentsApiPaths.METADATA(documentId)),
      APIHelpers.json<PublicDocumentMetadata>,
      APIHelpers.standardError,
    );
  },

  update: (documentId: string, formData: FormData) => {
    return handleAPIRequest(
      apiClient.patchFile(PublicDocumentsApiPaths.METADATA(documentId), formData),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  delete: (documentId: string) => {
    return handleAPIRequest(
      apiClient.deleteAction(PublicDocumentsApiPaths.METADATA(documentId)),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  download: (documentId: string) => {
    return apiClient.getFile(PublicDocumentsApiPaths.DOWNLOAD(documentId));
  },
});

export default publicDocumentsApi;
