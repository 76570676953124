import { ProfitStatus, ProviderSize, ProviderType, RegistrationStatus, ReportingStatus } from 'nrosh-common/Api/Enums';
import { Stack } from 'react-bootstrap';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { ProvidersApi } from '@/Helpers/Apis';
import { adminPages } from '@/Pages/Home/SitePages';
import ProviderForm from '@/Pages/Providers/ProviderForm';
import '@/Pages/Providers/RegisteredDetailsPage.scss';

const initialValues = {
  id: 0,
  name: '',
  providerNumber: '',
  type: ProviderType.Company,

  reportingStatus: ReportingStatus.Mainstream,
  size: ProviderSize.Small,

  registeredAddress: {
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    addressLine4: '',
    postcode: '',
  },

  endOfFinancialYear: null,
  registrationStatus: RegistrationStatus.Registered,
  registrationDate: null,
  deregistrationDate: null,
  profitStatus: ProfitStatus.LocalAuthority,
  feesInvoiceEmail: null,

  isDisabled: false,
  isTestProvider: true,
  isSyncedWithDynamics: false,
};

const AddProviderPage = (): JSX.Element => (
  <Stack gap={5} className="registeredDetailsContainer">
    <PageHeader
      heading="Add provider"
      crumbsType={RootPathType.AdminProviders}
      crumbs={[
        {
          name: 'Add provider',
          path: adminPages.AdminAddProvider.path,
        },
      ]}
    />
    <ProviderForm initialProviderDetails={initialValues} onSave={ProvidersApi.addProvider} />
  </Stack>
);

export default AddProviderPage;
