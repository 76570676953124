import { SurveyStatus } from 'nrosh-common/Api/SubmissionsApi';
import { DataDictionaryErrorResponse, DataDictionaryResponse } from 'nrosh-common/Api/SurveyPartsApi';
import { SurveyInstance, SurveyInstanceWithStringDate, mapSurveyInstanceResponse } from 'nrosh-common/Api/SurveysApi';
import useEndpoint, { useEndpointConditionallyWithProcessing } from 'nrosh-common/Hooks/useEndpoint';
import { useMemo, useState } from 'react';
import { Stack } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import { generatePath, useParams } from 'react-router-dom';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { TertiaryLinkButton } from '@/Components/Buttons/TertiaryLinkButton';
import {
  DownloadDataDictionaryButton,
  UploadDataDictionaryButton,
} from '@/Components/DataDictionaryUpload/DataDictionaryButtons';
import { NavigableAlertMessageList } from '@/Components/DataDictionaryUploadErrorMessages/NavigableAlertMessageList';
import ChevronLeft from '@/Components/Icons/ChevronLeft';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import ToastWithIcon, { ToastType } from '@/Components/ToastWithIcon/ToastWithIcon';
import { SurveyPartsApi, SurveysApi } from '@/Helpers/Apis';
import '@/Pages/Surveys/DataDictionaryPage.scss';
import '@/Components/Icons/Icons.scss';
import { surveyPages } from '@/Pages/Home/SitePages';
import DataDictionaryTable from '@/Pages/Surveys/DataDictionaryTable';
import warningMessage from '@/Pages/Surveys/DataDictionaryWarningMessages';

const DataDictionaryPage = (): JSX.Element => {
  const { surveyPartId, timePeriodId, surveyId } = useParams();
  const [dataLastUploadedAt, setDataLastUploadedAt] = useState<Date | null>(null);
  const [data] = useEndpoint<DataDictionaryResponse>(
    SurveyPartsApi.getDataDictionary,
    surveyPartId,
    dataLastUploadedAt,
  );
  const [surveyInstance] = useEndpointConditionallyWithProcessing<SurveyInstanceWithStringDate, SurveyInstance>(
    SurveysApi.getSurveyInstance,
    true,
    mapSurveyInstanceResponse,
    surveyId,
    timePeriodId,
    dataLastUploadedAt,
  );

  const [dataDictionaryErrors, setDataDictionaryErrors] = useState<DataDictionaryErrorResponse | undefined>();
  const dataDictionaryErrorMessages = useMemo(
    () => dataDictionaryErrors?.dataDictionaryErrors.map(warningMessage) || [],
    [dataDictionaryErrors],
  );
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState('Changes to data dictionary saved successfully');
  const [show, setShow] = useState<{ [name: string]: boolean }>({ isMissing: true });

  if (!data || !surveyInstance) {
    return <LoadingMessage />;
  }

  const isDataDictionaryUploaded = data.dataPoints.length > 0;
  const isSurveyClosed = surveyInstance.surveyStatus === SurveyStatus.Closed;

  return (
    <Stack gap={5}>
      <Stack gap={4}>
        <PageHeader
          heading="Data dictionary"
          crumbsType={RootPathType.AdminSurveys}
          crumbs={[
            {
              name: data.surveyName,
              path: surveyPages.SurveyView.path,
            },
            {
              name: 'Configure survey',
              path: surveyPages.SurveyConfigure.path,
            },
            {
              name: 'Data dictionary',
              path: surveyPages.SurveyPartEdit.path,
            },
          ]}
        />
        {isDataDictionaryUploaded && showToast && (
          <div className="mb-4">
            <ToastWithIcon text={toastText} type={ToastType.Success} />
          </div>
        )}
        <NavigableAlertMessageList messages={dataDictionaryErrorMessages} />
        {show.isMissing && dataDictionaryErrors?.isMissingDataPointIds && (
          <Alert variant="warning" dismissible onClose={() => setShow((prev) => ({ ...prev, isMissing: false }))}>
            Some rows in the upload file do not contain a data point ID. These rows were ignored by the upload.
          </Alert>
        )}
        {!isDataDictionaryUploaded ? (
          <Stack gap={5}>
            <p>
              No data dictionary has been added for this survey yet. Follow the steps below to upload a data dictionary.
            </p>
            <div>
              <div className="mb-4">
                <h2>Step 1</h2>
                <p>
                  Download the data dictionary template - it will download as an Excel file. Open the file and add a row
                  for each column.
                </p>
              </div>
              <DownloadDataDictionaryButton surveyPartId={surveyPartId!} />
            </div>
            <div>
              <div className="mb-4">
                <h2>Step 2</h2>
                <p>Save the changes in your file then click the button below to upload it.</p>
              </div>
              <UploadDataDictionaryButton
                surveyPartId={surveyPartId!}
                setDataLastUploadedAt={setDataLastUploadedAt}
                setDataDictionaryErrors={setDataDictionaryErrors}
                setShow={setShow}
                setShowToast={setShowToast}
                setToastText={setToastText}
              />
            </div>
          </Stack>
        ) : (
          <DataDictionaryTable
            initialData={data.dataPoints}
            surveyPartId={surveyPartId}
            setDataLastUploadedAt={setDataLastUploadedAt}
            setDataDictionaryErrors={setDataDictionaryErrors}
            setShow={setShow}
            setShowToast={setShowToast}
            setToastText={setToastText}
            isSurveyClosed={isSurveyClosed}
          />
        )}
      </Stack>
      <TertiaryLinkButton
        to={generatePath(surveyPages.SurveyConfigure.path, { surveyId, timePeriodId })}
        icon={<ChevronLeft />}
        text="Return to configure survey"
      />
    </Stack>
  );
};
export default DataDictionaryPage;
