import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { Form } from 'react-bootstrap';
import '@/Components/Filters/Filters.scss';

export type FilterSearchBoxProps = {
  id?: string;
  searchString: string;
  setSearchString: ReactStateSetter<string>;
  placeholderText?: string;
};

export const FilterSearchBox = ({
  searchString,
  setSearchString,
  id,
  placeholderText,
}: FilterSearchBoxProps): JSX.Element => (
  <Form.Control
    id={id}
    type="input"
    value={searchString}
    onChange={(e) => setSearchString(e.target.value)}
    placeholder={placeholderText}
  />
);
