import { format } from 'date-fns';
import {
  EmailFileObjectType,
  EmailRecipientsFilter,
  ScheduledEmailRequest,
  ScheduledEmailResponse,
  ScheduledEmailStatus,
} from 'nrosh-common/Api/EmailsApi';
import { FormEvent, useMemo, useRef, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { generatePath, useNavigate } from 'react-router-dom';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import { FileItem } from '@/Components/FileUpload/MultipleFileUploadManager';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { EmailsApi, placeHolderAPIErrorHandler } from '@/Helpers/Apis';
import { TimeResolution, formatISODateString } from '@/Helpers/DateHelpers';
import { checkHtmlNonEmpty } from '@/Helpers/HtmlValidityHelper';
import { ComposeForm } from '@/Pages/Emails/ComposeFormComponents';
import {
  InvalidTemplatingFeedback,
  availableTemplateFieldGroups,
  getInvalidSubjectTemplateFields,
  getMissingTemplateFields,
  getUnrecognisedTemplateFields,
  getUsedTemplateFields,
  globalTemplateFieldGroups,
} from '@/Pages/Emails/EmailTemplates';
import { getEmailRecipientMessage } from '@/Pages/Emails/EmailUtilities';
import { adminPages } from '@/Pages/Home/SitePages';
import '@/Pages/Emails/EmailStyles.scss';

type ComposeEmailFormProps = {
  emailId: string;
  emailData: ScheduledEmailResponse;
  setDirty: () => void;
  setPristine: () => void;
  initialSubject: string;
  initialHtmlBody: string;
  initialUploadedFiles: FileItem[];
  initialSendLater: boolean;
  initialSendDateTime: Date;
  recipientsFilter: EmailRecipientsFilter | null | undefined;
};

export const ComposeEmailForm = ({
  emailId,
  emailData,
  setDirty,
  setPristine,
  initialSubject,
  initialHtmlBody,
  initialUploadedFiles,
  initialSendLater,
  initialSendDateTime,
  recipientsFilter,
}: ComposeEmailFormProps): JSX.Element => {
  const [subject, setSubject] = useState(initialSubject);
  const [htmlBody, setHtmlBody] = useState(initialHtmlBody);
  const [attachedFileItems] = useState<FileItem[]>(initialUploadedFiles);
  const [sendLater, setSendLater] = useState<boolean>(initialSendLater);
  const [sendDateTime, setSendDateTime] = useState<Date | null>(initialSendDateTime);

  const [isSending, setIsSending] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(false);
  const [isSendTimeValid, setIsSendTimeValid] = useState<boolean>(false);

  const templateFieldGroups = useRef(globalTemplateFieldGroups.concat(availableTemplateFieldGroups.Manual));

  const subjectTemplateFields = useMemo(() => getUsedTemplateFields(subject), [subject]);

  const invalidSubjectTemplateFields = useMemo(
    () => getInvalidSubjectTemplateFields(subjectTemplateFields),
    [subjectTemplateFields],
  );

  const usedTemplateFields = subjectTemplateFields.concat(getUsedTemplateFields(htmlBody));

  const missingTemplateFields = getMissingTemplateFields(usedTemplateFields, 'Manual');

  const unrecognisedTemplateFields = getUnrecognisedTemplateFields(usedTemplateFields, 'Manual');

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const navigate = useNavigate();

  const emailRecipientMessage = getEmailRecipientMessage(emailData.recipientsFilter);

  const isSubjectValid = subject !== '';
  const isBodyValid = checkHtmlNonEmpty(htmlBody);
  const areRecipientsValid = recipientsFilter !== null && recipientsFilter !== undefined;

  const navigateToRecipients = (): void => navigate(generatePath(adminPages.AdminEmailSetRecipients.path, { emailId }));

  const updateSubject = (newSubject: string): void => {
    setDirty();
    setSubject(newSubject);
  };

  const updateBody = (body: string): void => {
    setDirty();
    setHtmlBody(body);
  };

  const checkFormValid = (): boolean => {
    const newIsSendTimeValid = !sendLater || (sendDateTime !== null && sendDateTime > new Date(Date.now()));
    setIsSendTimeValid(newIsSendTimeValid);

    return (
      isSubjectValid &&
      newIsSendTimeValid &&
      areRecipientsValid &&
      isBodyValid &&
      missingTemplateFields.length === 0 &&
      unrecognisedTemplateFields.length === 0 &&
      invalidSubjectTemplateFields.length === 0
    );
  };

  const updateEmail = async (newStatus?: ScheduledEmailStatus): Promise<void> => {
    setPristine();

    if (newStatus === ScheduledEmailStatus.Queued) {
      setIsSending(true);
    } else if (newStatus === ScheduledEmailStatus.Draft) {
      setIsSaving(true);
    }

    const emailPatchRequest: ScheduledEmailRequest = {
      subject,
      htmlBody,
      sendTime: sendLater ? sendDateTime : new Date(Date.now()),
      status: newStatus ?? null,
    };

    const filesToUpload = attachedFileItems.filter((fi) => fi.fileObject !== null).map((fi) => fi.fileObject!);

    await EmailsApi.updateScheduledAttachments(
      emailId,
      attachedFileItems.filter((fi) => fi.fileObject === null).map((fi) => fi.fileName),
      filesToUpload.map((f) => ({
        type: EmailFileObjectType.Attachment,
        fileName: f.name,
        content: f,
      })),
    ).justErrors(placeHolderAPIErrorHandler);

    const response = await EmailsApi.patchScheduledEmail(emailId, emailPatchRequest).raw;
    if (response.ok) {
      setErrorMessage(undefined);
      setIsSending(false);
      setIsSaving(false);
      navigate(generatePath(adminPages.AdminManualEmails.path));
      return;
    }

    setErrorMessage(response.value.message);
    setIsSending(false);
    setIsSaving(false);
  };

  const submitForm = (e: FormEvent<HTMLFormElement> | undefined = undefined): void => {
    e?.preventDefault();
    if (checkFormValid()) {
      setValidated(false);
      updateEmail(ScheduledEmailStatus.Queued).catch(() => {});
    } else {
      e?.stopPropagation();
      setValidated(true);
    }
  };

  const sendButtonText = sendLater ? 'Schedule Send and Return' : 'Send and Return';

  const saveButtonText = 'Save as Draft and Return';

  return (
    <Form noValidate onSubmit={submitForm} className="w-75">
      <ComposeForm.SendTime
        sendDateTime={sendDateTime}
        sendLater={sendLater}
        setSendDateTime={setSendDateTime}
        setSendLater={setSendLater}
        isInvalid={validated && !isSendTimeValid}
      />
      <ComposeForm.Recipients
        onButtonPressed={async () => {
          await updateEmail();
          navigateToRecipients();
        }}
        recipientsFilter={emailData.recipientsFilter}
        emailRecipientMessage={emailRecipientMessage}
        isInvalid={validated && !areRecipientsValid}
      />
      <ComposeForm.Subject
        subject={subject}
        setSubject={updateSubject}
        isInvalid={
          validated &&
          (!isSubjectValid ||
            missingTemplateFields.length !== 0 ||
            unrecognisedTemplateFields.length !== 0 ||
            invalidSubjectTemplateFields.length !== 0)
        }
        hideInvalidMessage={isSubjectValid}
        templateFieldGroups={templateFieldGroups.current}
        describedBy={
          validated &&
          (missingTemplateFields.length !== 0 ||
            unrecognisedTemplateFields.length !== 0 ||
            invalidSubjectTemplateFields.length !== 0)
            ? 'invalidTemplatingFeedback'
            : undefined
        }
      />
      {/*
      TODO-507: Restore this code when Exchange attachments are enabled
      <ComposeForm.Attachments
        attachments={attached}
        setAttachments={setAttachedFileItems}
        setDirty={setDirty}
      />
      */}
      <ComposeForm.Message
        htmlBody={htmlBody}
        updateBody={updateBody}
        isInvalid={
          validated && (!isBodyValid || missingTemplateFields.length !== 0 || unrecognisedTemplateFields.length !== 0)
        }
        templateFieldGroups={templateFieldGroups.current}
        hideInvalidMessage={isBodyValid}
        describedBy={
          validated &&
          (missingTemplateFields.length !== 0 ||
            unrecognisedTemplateFields.length !== 0 ||
            invalidSubjectTemplateFields.length !== 0)
            ? 'invalidTemplatingFeedback'
            : undefined
        }
      />
      <InvalidTemplatingFeedback
        id="invalidTemplatingFeedback"
        validated={validated}
        missingTemplateFields={missingTemplateFields}
        invalidSubjectTemplateFieldsFeedback={invalidSubjectTemplateFields}
        unrecognisedTemplateFields={unrecognisedTemplateFields}
      />
      {emailData?.lastEditedByName && (
        <p className="lastEditedBy">
          Last edited by {emailData.lastEditedByName}{' '}
          {emailData.lastEditedAt &&
            `on  ${formatISODateString(emailData.lastEditedAt, TimeResolution.Day)} at ${format(
              new Date(emailData.lastEditedAt),
              'HH:mm',
            )}`}
        </p>
      )}
      {!isSending ? (
        <PrimaryButton className="me-3" disabled={isSaving || isSending} type="submit">
          {sendButtonText}
        </PrimaryButton>
      ) : (
        <LoadingButton message={sendButtonText} />
      )}
      {!isSaving ? (
        <PrimaryButton
          colour="outline-primary"
          className="me-3"
          disabled={isSaving || isSending}
          onClick={() => updateEmail(ScheduledEmailStatus.Draft)}
        >
          {saveButtonText}
        </PrimaryButton>
      ) : (
        <LoadingButton message={saveButtonText} colour="outline-primary" />
      )}
      {errorMessage && (
        <Alert variant="danger" className="mt-3">
          {errorMessage}
        </Alert>
      )}
    </Form>
  );
};
