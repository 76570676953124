import { EditablePage } from 'nrosh-common/Api/EditableContentApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { EditableContentApi } from '@/Helpers/Apis';
import { PageTitleDict } from '@/Pages/Content/ContentHelpers';
import EditContent from '@/Pages/Content/ManageEditableContent/EditContent';
import { adminPages } from '@/Pages/Home/SitePages';

const EditableContentPage = (): JSX.Element => {
  const { pageId } = useParams();
  const [editablePage] = useEndpoint<EditablePage>(EditableContentApi.getEditablePage, pageId);

  if (!editablePage) {
    return <LoadingMessage />;
  }

  return (
    <Stack gap={5}>
      <PageHeader
        heading={`Edit '${PageTitleDict[editablePage.title]}' page`}
        crumbsType={RootPathType.AdminContent}
        crumbs={[
          {
            name: 'Manage editable content',
            path: adminPages.AdminEditableContent.path,
          },
          {
            name: `Edit '${PageTitleDict[editablePage.title]}' page`,
            path: adminPages.AdminEditPage.path,
          },
        ]}
      />
      <EditContent editablePage={editablePage} />
    </Stack>
  );
};

export default EditableContentPage;
