import { Accordion as BootstrapAccordion } from 'react-bootstrap';
import Pagination from '@/Components/Pagination/Pagination';
import usePagination from '@/Components/Pagination/usePagination';
import '@/Components/Accordion/Accordion.scss';

type Props<T extends object> = {
  data: Array<T>;
  id: (item: T) => string;
  heading: (item: T) => JSX.Element;
  body: (item: T) => JSX.Element;
  paginated?: boolean;
  message?: string;
};

const Accordion = <T extends object>({
  data,
  id,
  heading,
  body,
  paginated,
  message = 'No items to display.',
}: Props<T>): JSX.Element => {
  const { page, pageIndex, canPreviousPage, canNextPage, pageOptions, nextPage, previousPage, gotoPage } =
    usePagination(data);

  return (
    <div className="dataAccordion">
      <div>
        {(paginated ? page : data).map((item) => (
          <BootstrapAccordion key={id(item)}>
            <BootstrapAccordion.Item eventKey={id(item)}>
              <BootstrapAccordion.Header>{heading(item)}</BootstrapAccordion.Header>
              <BootstrapAccordion.Body>{body(item)}</BootstrapAccordion.Body>
            </BootstrapAccordion.Item>
          </BootstrapAccordion>
        ))}
      </div>
      <div className="noItemsMessage">{(paginated ? page : data).length === 0 ? message : null}</div>
      {paginated && (
        <Pagination
          pageOptions={pageOptions}
          pageIndex={pageIndex}
          gotoPage={gotoPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
        />
      )}
    </div>
  );
};

export default Accordion;
