import classNames from 'classnames';
import { ProviderRole, RshRole } from 'nrosh-common/Api/Enums';
import { Submission, SurveyStatus } from 'nrosh-common/Api/SubmissionsApi';
import { Surveys } from 'nrosh-common/Api/SurveysApi';
import AuthContext, { Auth } from 'nrosh-common/Contexts/AuthContext';
import { useEndpointConditionally } from 'nrosh-common/Hooks/useEndpoint';
import { useContext, useState } from 'react';
import { Nav } from 'react-bootstrap';
import { Outlet, generatePath } from 'react-router-dom';
import '@/Pages/Home/Sidebar.scss';
import DropdownLinks from '@/Components/Sidebar/DropdownLinks';
import SidebarIcon from '@/Components/Sidebar/SidebarIcon';
import SidebarLink from '@/Components/Sidebar/SidebarLink';
import { SidebarPage, getAdminSidebarPages, getProviderSidebarPages } from '@/Components/Sidebar/SidebarPages';
import { SubmissionsApi, SurveysApi } from '@/Helpers/Apis';
import { surveyDisplayName } from '@/Helpers/SurveyHelper';
import { providerPages, surveyPages } from '@/Pages/Home/SitePages';

export type DropdownLink = {
  name: string;
  path: string;
};

type Props = {
  pages: SidebarPage[];
};

const filterAllowedSidebarPages = (pages: SidebarPage[], auth: Auth): SidebarPage[] =>
  pages
    .filter((p) => !p.roles || auth.hasOneOfRoles(...p.roles))
    .map((page) => ({
      ...page,
      dropdownLinks: page.dropdownLinks?.filter((l) => !l.roles || auth.hasOneOfRoles(...l.roles)),
    }));

const useAdminPages = (): SidebarPage[] => {
  const auth = useContext(AuthContext);
  const [adminData] = useEndpointConditionally<Surveys>(SurveysApi.getSurveys, auth.hasRole(RshRole.ViewSurveys));

  const adminSurveys: DropdownLink[] | undefined = adminData
    ? adminData.surveys.flatMap((s) =>
        s.instances
          .filter((si) => si.surveyStatus === SurveyStatus.Open)
          .map((si) => ({
            name: surveyDisplayName(s.surveyName, si.timePeriodName),
            path: generatePath(surveyPages.SurveyView.path, {
              surveyId: s.surveyId.toString(),
              timePeriodId: si.timePeriodId.toString(),
            }),
          })),
      )
    : undefined;
  const adminSidebar = getAdminSidebarPages(adminSurveys, auth);
  return filterAllowedSidebarPages(adminSidebar, auth);
};

const useProviderPages = (): SidebarPage[] => {
  const auth = useContext(AuthContext);
  const [providerData] = useEndpointConditionally<Array<Submission>>(
    SubmissionsApi.getSubmissions,
    auth.hasRole(ProviderRole.ViewSurveyData),
  );

  const providerSurveys: DropdownLink[] | undefined = providerData
    ? providerData
        .filter((s) => s.surveyStatus === SurveyStatus.Open)
        .map((data) => ({
          name: surveyDisplayName(data.surveyName, data.timePeriodName),
          path: generatePath(providerPages.SubmissionsDetail.path, { submissionId: data.id.toString() }),
        }))
    : undefined;
  const providerSidebar = getProviderSidebarPages(providerSurveys, auth);
  return filterAllowedSidebarPages(providerSidebar, auth);
};

const LayoutWithSidebar = (props: Props): JSX.Element => {
  const { pages } = props;
  const [activeExpanded, setActiveExpanded] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | undefined>();

  return (
    <>
      {pages.length > 0 && (
        <Nav defaultActiveKey="/home" as="nav" className="flex-column sidebar">
          {pages?.map((page, index) => (
            <div key={page.name}>
              <div className="sidebarContent">
                <div className="sidebarIcons">
                  <div className={classNames({ activeLink: index === activeIndex })}>
                    <SidebarIcon icon={page.icon} />
                  </div>
                </div>
                <SidebarLink
                  page={page}
                  index={index}
                  activeExpanded={activeExpanded}
                  setActiveExpanded={setActiveExpanded}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
                />
              </div>
              <div className="hideDropdownLinks">
                {page.dropdownLinks && page.dropdownLinks.length > 0 && activeExpanded && activeIndex === index && (
                  <DropdownLinks pages={page.dropdownLinks} />
                )}
              </div>
            </div>
          ))}
        </Nav>
      )}
      <main className={classNames('mainWithSidebar', 'fillScreen')}>
        <Outlet />
      </main>
    </>
  );
};

export const LayoutWithAdminSidebar = (): JSX.Element => {
  const pages = useAdminPages();
  return <LayoutWithSidebar pages={pages} />;
};

export const LayoutWithProviderSidebar = (): JSX.Element => {
  const pages = useProviderPages();
  return <LayoutWithSidebar pages={pages} />;
};
