import { TertiaryButton } from '@/Components/Buttons/DCSButton';
import Delete from '@/Components/Icons/Delete';

type DeleteButtonProps = {
  text: string;
  onClick: () => void;
  disabled?: boolean;
};

export const DeleteButton = ({ text, onClick, disabled = false }: DeleteButtonProps): JSX.Element => (
  <TertiaryButton aria-disabled={disabled} onClick={onClick} icon={<Delete />} colour="danger" disabled={disabled}>
    {text}
  </TertiaryButton>
);
