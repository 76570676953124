import ApiClient, { APIHelpers, handleAPIRequest } from './ApiClient';
import {
  FeesInvoiceRequest,
  ProviderContact,
  ProviderWithStringDate,
  RegisteredDetailsWithStringDate,
} from './ProvidersApi';

const MyProviderApiRoot = 'MyProvider';
const MyProviderApiPaths = {
  PROVIDER: MyProviderApiRoot,
  REGISTERED_DETAILS: `${MyProviderApiRoot}/RegisteredDetails`,
  PROVIDER_CONTACTS: `${MyProviderApiRoot}/Contacts`,
  CONFIRM_ORGANISATIONAL_CONTACTS: (submissionId: number) => `${MyProviderApiRoot}/Submission/${submissionId}/Contacts`,
  EDIT_PROVIDER: `${MyProviderApiRoot}/Details`,
};

const myProviderApi = (apiClient: ApiClient) => ({
  getProvider: () => {
    return handleAPIRequest(
      apiClient.get(MyProviderApiPaths.PROVIDER),
      APIHelpers.json<ProviderWithStringDate>,
      APIHelpers.standardError,
    );
  },

  getRegisteredDetails: () => {
    return handleAPIRequest(
      apiClient.get(MyProviderApiPaths.REGISTERED_DETAILS),
      APIHelpers.json<RegisteredDetailsWithStringDate>,
      APIHelpers.standardError,
    );
  },

  getProviderContacts: () => {
    return handleAPIRequest(
      apiClient.get(MyProviderApiPaths.PROVIDER_CONTACTS),
      APIHelpers.json<ProviderContact[]>,
      APIHelpers.standardError,
    );
  },

  updateProviderContacts: (contacts: ProviderContact[]) => {
    return handleAPIRequest(
      apiClient.patch(MyProviderApiPaths.PROVIDER_CONTACTS, contacts),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  confirmOrganisationalContacts: (submissionId: number, contacts: ProviderContact[]) => {
    return handleAPIRequest(
      apiClient.patch(MyProviderApiPaths.CONFIRM_ORGANISATIONAL_CONTACTS(submissionId), contacts),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  editProvider: (provider: FeesInvoiceRequest) => {
    return handleAPIRequest(
      apiClient.patch(MyProviderApiPaths.EDIT_PROVIDER, provider),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },
});

export default myProviderApi;
