const {
  VITE_WEB_API_PROTOCOL: webApiProtocol,
  VITE_WEB_API_HOST: webApiHost,
  VITE_WEB_API_PORT: webApiPort,
  VITE_ALLOW_ADMIN_DOMAINS: adminDomains,
} = (import.meta.env || process.env || {}) as Record<string, string | undefined>;

const webApiBaseUrl: string = `${webApiProtocol}://${webApiHost}:${webApiPort}`;
const allowAdminDomains: string[] = adminDomains?.split(',') || [];
export { webApiBaseUrl, allowAdminDomains, webApiPort };
