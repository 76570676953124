import { SurveyStatus } from 'nrosh-common/Api/SubmissionsApi';
import { SurveyInstanceWithStringDate } from 'nrosh-common/Api/SurveysApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import PageHeadingWithSurveyStatusBadge from '@/Components/Badges/PageHeadingWithSurveyStatusBadge';
import { Breadcrumbs, RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import { SurveysApi } from '@/Helpers/Apis';
import { surveyDisplayName } from '@/Helpers/SurveyHelper';
import { surveyPages } from '@/Pages/Home/SitePages';
import SurveyCrossPartValidationsTable from '@/Pages/Surveys/SurveyCrossPartValidationsTable';

const SurveyCrossPartValidationsPage = (): JSX.Element => {
  const { surveyId, timePeriodId } = useParams();
  const [surveyData] = useEndpoint<SurveyInstanceWithStringDate>(SurveysApi.getSurveyInstance, surveyId, timePeriodId);

  if (!surveyData) {
    return <LoadingMessage />;
  }

  const isSurveyClosed = surveyData.surveyStatus === SurveyStatus.Closed;
  const pageTitle = `${isSurveyClosed ? 'View' : 'Edit'} cross-part validations`;

  return (
    <Stack gap={5}>
      <Stack gap={5}>
        <Breadcrumbs
          crumbs={[
            {
              name: surveyDisplayName(surveyData.name, surveyData.timePeriodName),
              path: surveyPages.SurveyView.path,
            },
            {
              name: 'Configure survey',
              path: surveyPages.SurveyConfigure.path,
            },
            {
              name: pageTitle,
              path: surveyPages.SurveyCrossPartValidations.path,
            },
          ]}
          type={RootPathType.AdminSurveys}
        />
        <PageHeadingWithSurveyStatusBadge title={pageTitle} status={surveyData.surveyStatus} />
      </Stack>
      <SurveyCrossPartValidationsTable
        initialData={surveyData.crossPartValidations}
        isSurveyClosed={isSurveyClosed}
        surveyInstanceName={`${surveyData.name} - ${surveyData.timePeriodName}`}
      />
    </Stack>
  );
};

export default SurveyCrossPartValidationsPage;
