import { SurveyStatus } from 'nrosh-common/Api/SubmissionsApi';
import {
  SurveyInstance,
  SurveyInstancePart,
  SurveyInstanceWithStringDate,
  mapSurveyInstanceResponse,
} from 'nrosh-common/Api/SurveysApi';
import { useEndpointConditionallyWithProcessing } from 'nrosh-common/Hooks/useEndpoint';
import { Dispatch, FormEvent, ReactNode, SetStateAction, useState } from 'react';
import { Form, OverlayTrigger, Stack, Tooltip } from 'react-bootstrap';
import { generatePath, useParams } from 'react-router-dom';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { SecondaryButton, TertiaryButton } from '@/Components/Buttons/DCSButton';
import { TertiaryLinkButton } from '@/Components/Buttons/TertiaryLinkButton';
import { useErrorReporting } from '@/Components/Errors/DCSErrorBoundary';
import { AccessibleFeedback } from '@/Components/Form/AccessibleFeedback';
import FormLineBreak from '@/Components/Form/FromLineBreak';
import AddCircle from '@/Components/Icons/AddCircle';
import ChevronLeft from '@/Components/Icons/ChevronLeft';
import LinkButton from '@/Components/Links/LinkButton';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import { useModal } from '@/Components/Modal/ModalProvider';
import PageHeader from '@/Components/PageHeader/PageHeader';
import SurveyPartCard from '@/Components/SurveyForm/SurveyPartCard';
import ToastWithIcon, { ToastType } from '@/Components/ToastWithIcon/ToastWithIcon';
import TooltippedInfoIcon from '@/Components/Tooltips/TooltippedInfoIcon';
import { SurveyPartsApi, SurveysApi } from '@/Helpers/Apis';
import { getValidityProps } from '@/Helpers/Forms';
import { surveyDisplayName } from '@/Helpers/SurveyHelper';
import { containsANonWhitespaceCharacterRegex } from '@/Helpers/Validation';
import { surveyPages } from '@/Pages/Home/SitePages';
import '@/Components/SurveyForm/SurveyPartForm.scss';

export type SurveyPartFormProps = {
  currentSurveyPartName?: string;
  surveyPartNames: string[];
  addOrEditPart: (newPartName: string, currentSurveyPartName?: string) => Promise<boolean>;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  isOnlyInstanceOfPart?: boolean;
  sharedPartInfoMessage?: string;
};

type AddNewPartFormProps = {
  surveyPartNames: string[];
  addOrEditPart: (newPartName: string, currentSurveyPartName?: string) => Promise<boolean>;
};

const deleteWarningMessage = (part: SurveyInstancePart): ReactNode => {
  if (part.hasDataDictionary && part.isOnlyInstanceOfPart) {
    return (
      <div>
        <p>A data dictionary and/or import file has already been uploaded for {part.name}.</p>
        <p>
          If you continue,<strong> all data associated with this survey part will be permanently lost</strong>,
          including the data dictionary and import file.
        </p>
        <p>
          If this survey has already been assigned to providers,
          <strong> any data that has been entered by those providers will be permanently lost</strong> as well.
        </p>
        <p>Do you wish to continue?</p>
      </div>
    );
  }

  if (part.hasUploadedImportTemplate) {
    return (
      <div>
        <p>An import file has already been uploaded for {part.name}.</p>
        <p>
          If you continue,<strong> all data associated with this survey part will be permanently lost</strong>,
          including the import file.
        </p>
        <p>
          If this survey has already been assigned to providers,
          <strong> any data that has been entered by those providers will be permanently lost</strong> as well.
        </p>
        <p>Do you wish to continue?</p>
      </div>
    );
  }

  return (
    <div>
      <p>Are you sure you want to delete {part.name}?</p>
      <p>
        <strong> This action cannot be undone.</strong>
      </p>
    </div>
  );
};

export const SurveyPartForm = ({
  currentSurveyPartName = '',
  surveyPartNames,
  addOrEditPart,
  setIsEditing,
  isOnlyInstanceOfPart = true,
  sharedPartInfoMessage = '',
}: SurveyPartFormProps): JSX.Element => {
  const [name, setName] = useState<string>(currentSurveyPartName);
  const [displayValidation, setDisplayValidation] = useState<boolean>(false);

  const newPartNameAlreadyExists = surveyPartNames.some((part) => part.trim() === name.trim());
  const isInvalidNewPartName = newPartNameAlreadyExists;

  const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault(); // Stops the page from reloading

    if (isInvalidNewPartName) {
      setDisplayValidation(true);
      return;
    }

    if (await addOrEditPart(name, currentSurveyPartName)) {
      setName('');
      setDisplayValidation(false);
      setIsEditing(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setName(e.target.value);
    setDisplayValidation(false);
  };

  return (
    <div>
      <Form
        noValidate
        validated={displayValidation && !isInvalidNewPartName}
        onSubmit={handleSubmit}
        className="surveyPartForm d-flex align-items-end gap-3 m-0"
      >
        <Form.Group controlId="new-part-form" className="w-50 me-1 p-0">
          <Form.Label>Part name</Form.Label>
          {!isOnlyInstanceOfPart && sharedPartInfoMessage && <TooltippedInfoIcon message={sharedPartInfoMessage} />}
          <Form.Control
            type="text"
            value={name}
            onChange={handleChange}
            {...getValidityProps(displayValidation && isInvalidNewPartName, 'new-part-name-feedback')}
            isValid={displayValidation && !isInvalidNewPartName}
          />
        </Form.Group>
        <Stack gap={3} direction="horizontal" className="w-40 align-items-end">
          <SecondaryButton type="submit" disabled={!containsANonWhitespaceCharacterRegex.test(name)}>
            {currentSurveyPartName ? 'Save changes' : 'Confirm'}
          </SecondaryButton>
          <SecondaryButton
            type="reset"
            onClick={() => {
              setName('');
              setDisplayValidation(false);
              setIsEditing(false);
            }}
          >
            Cancel
          </SecondaryButton>
        </Stack>
      </Form>
      <AccessibleFeedback id="new-part-name-feedback" displayFeedback={displayValidation && isInvalidNewPartName}>
        {newPartNameAlreadyExists && 'Please provide a unique part name.'}
      </AccessibleFeedback>
    </div>
  );
};

export const AddNewPartForm = ({ surveyPartNames, addOrEditPart }: AddNewPartFormProps): JSX.Element => {
  const [isAddingNewPart, setIsAddingNewPart] = useState<boolean>(false);

  return (
    <>
      {isAddingNewPart && (
        <div className="partCard d-flex flex-column align-items-start p-4 mt-3">
          <SurveyPartForm
            surveyPartNames={surveyPartNames}
            addOrEditPart={addOrEditPart}
            setIsEditing={setIsAddingNewPart}
          />
        </div>
      )}
      <div className="mt-3">
        <TertiaryButton icon={<AddCircle />} onClick={() => setIsAddingNewPart(true)} disabled={isAddingNewPart}>
          Add another part
        </TertiaryButton>
      </div>
    </>
  );
};

const ConfigureSurveyPage = (): JSX.Element => {
  const { surveyId, timePeriodId } = useParams();
  const [dataLastUploadedAt, setDataLastUploadedAt] = useState<Date | null>(null);
  const [raiseError, clearError] = useErrorReporting();
  const { confirm } = useModal();
  const [surveyInstance] = useEndpointConditionallyWithProcessing<SurveyInstanceWithStringDate, SurveyInstance>(
    SurveysApi.getSurveyInstance,
    true,
    mapSurveyInstanceResponse,
    surveyId,
    timePeriodId,
    dataLastUploadedAt,
  );

  if (!surveyInstance) {
    return <LoadingMessage />;
  }

  const numberOfSurveyParts = surveyInstance.parts.length;
  const isSurveyClosed = surveyInstance.surveyStatus === SurveyStatus.Closed;
  const surveyPartNames = surveyInstance.parts.map(({ name }) => name);
  const sharedPartInfoMessage =
    'This part is included in other instances of this survey, where the parts have the same name. As a result, changes made to the data dictionary or name of this part will affect all instances of the part.';

  const addOrEditPart = async (newPartName: string, currentSurveyPartName?: string): Promise<boolean> => {
    const request = {
      name: newPartName,
    };

    const surveyPart = surveyInstance.parts.find((p) => p.name === currentSurveyPartName);
    const response = await (surveyPart
      ? SurveyPartsApi.editSurveyPart(surveyPart.surveyPartId.toString(), timePeriodId!, request)
      : SurveysApi.addNewPartToSurveyInstance(surveyId!, timePeriodId!, request)
    ).raw;

    if (response.ok) {
      setDataLastUploadedAt(new Date());
      clearError();
    } else {
      const errorMessage = response.value.message;
      raiseError(errorMessage);
    }
    return response.ok;
  };

  const deletePart = async (partId?: number): Promise<void> => {
    const partData = surveyInstance.parts.find((p) => p.surveyPartId === partId);
    if (!partData) {
      raiseError('That survey part could not be found.');
      return;
    }
    clearError();

    const shouldDelete = await confirm(deleteWarningMessage(partData), {
      title: `Delete ${partData.name}`,
      swapConfirmAndCancelStyles: true,
      confirmButtonText: 'Yes, delete',
    });
    if (!shouldDelete) return;

    const response = await SurveyPartsApi.deletePartFromSurveyInstance(partId!.toString(), timePeriodId!).raw;
    if (response.ok) {
      setDataLastUploadedAt(new Date());
      clearError();
    } else {
      const errorMessage = response.value.message;
      raiseError(errorMessage);
    }
  };

  let cannotDeleteReason = '';

  if (!surveyInstance.isDeletable) {
    cannotDeleteReason = surveyInstance.isAssigned
      ? 'This survey part is currently assigned to a provider and cannot be deleted.'
      : 'This survey part cannot be deleted as it was recently unassigned from one or more providers. Note that it can take up to 24 hours for the system to clean up submission data.';
  }

  const validationsLinkButton = (
    <LinkButton
      to={generatePath(surveyPages.SurveyCrossPartValidations.path, { surveyId, timePeriodId })}
      disabled={surveyInstance.parts.length <= 1}
      variant="outline-primary"
    >
      {isSurveyClosed ? 'View' : 'Edit'} cross-part validations
    </LinkButton>
  );

  return (
    <Stack gap={5}>
      <PageHeader
        heading="Configure survey"
        crumbsType={RootPathType.AdminSurveys}
        crumbs={[
          {
            name: surveyDisplayName(surveyInstance?.name, surveyInstance.timePeriodName),
            path: surveyPages.SurveyView.path,
          },
          {
            name: 'Configure survey',
            path: surveyPages.SurveyConfigure.path,
          },
        ]}
      />
      <Stack gap={5}>
        {isSurveyClosed && (
          <ToastWithIcon text="This survey is closed so survey parts cannot be edited." type={ToastType.Info} />
        )}
        <Stack gap={3}>
          <h2>Survey parts</h2>
          {numberOfSurveyParts === 0 && (
            <div>
              No parts have been added to this survey yet. Type in the parts name and click &#34;Add survey part&#34;
              below to get started.
            </div>
          )}
          {surveyInstance.parts.map((part) => (
            <SurveyPartCard
              partData={part}
              deleteAction={{
                isDeletable: surveyInstance.isDeletable,
                delete: () => deletePart(part.surveyPartId),
                cannotDeleteReason,
              }}
              key={`survey-part-card-${part.surveyPartId}`}
              isSurveyClosed={isSurveyClosed}
              surveyPartNames={surveyPartNames}
              addOrEditPart={addOrEditPart}
              isOnlyInstanceOfPart={part.isOnlyInstanceOfPart}
              sharedPartInfoMessage={sharedPartInfoMessage}
            />
          ))}
          {!isSurveyClosed && <AddNewPartForm surveyPartNames={surveyPartNames} addOrEditPart={addOrEditPart} />}
        </Stack>
        <FormLineBreak />
        <Stack gap={4}>
          <h2>Optional actions</h2>
          <div className="mb-2">
            {surveyInstance.parts.length <= 1 ? (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip>
                    Cannot {isSurveyClosed ? 'view' : 'edit'} cross-part validations without at least 2 parts
                  </Tooltip>
                }
              >
                {/* This span is required for tooltip to work with custom components. Refer to
                https://github.com/react-bootstrap/react-bootstrap/issues/2208#issuecomment-301737531 */}
                <span> {validationsLinkButton} </span>
              </OverlayTrigger>
            ) : (
              <div>{validationsLinkButton}</div>
            )}
          </div>
        </Stack>
        <TertiaryLinkButton
          to={generatePath(surveyPages.SurveyView.path, { surveyId, timePeriodId })}
          icon={<ChevronLeft />}
          text="Return to survey overview"
        />
      </Stack>
    </Stack>
  );
};

export default ConfigureSurveyPage;
