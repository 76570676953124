import { UnauthenticatedNewsArticle } from 'nrosh-common/Api/UnauthenticatedNewsApi';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Link, generatePath } from 'react-router-dom';
import { CellProps } from 'react-table';
import { FilterDropdown } from '@/Components/Filters/FilterDropdown';
import FilterHeading from '@/Components/Filters/FilterHeading';
import { FilterSearchBox } from '@/Components/Filters/FilterSearchBox';
import ArrowRight from '@/Components/Icons/ArrowRight';
import { SearchableDropdown, SearchableDropdownOption } from '@/Components/SearchableDropdown/SearchableDropdown';
import Table, { NroshColumn } from '@/Components/Table/Table';
import { formatISODateString } from '@/Helpers/DateHelpers';
import { publicPages } from '@/Pages/Home/SitePages';

const columns: NroshColumn<UnauthenticatedNewsArticle>[] = [
  {
    Header: 'Title',
    accessor: 'name',
    Cell: ({ value, row }: CellProps<UnauthenticatedNewsArticle>) => (
      <Link to={generatePath(publicPages.PublicArticle.path, { articleId: row.original.id })}>
        {value}
        <span className="ps-1 linkIcon">
          <ArrowRight />
        </span>
      </Link>
    ),
  },
  {
    Header: 'Tags',
    accessor: (row) => row.tags.map((t) => t.name).join(', '),
  },
  {
    Header: 'Date Published',
    accessor: 'publishDateTime',
    Cell: ({ value }) => formatISODateString(value),
    width: 200,
    align: 'center',
    disableResizing: true,
  },
];

type NewsTableProps = {
  articles: UnauthenticatedNewsArticle[];
  tagOptions: SearchableDropdownOption<number>[];
};

const applyFilters = (
  articles: UnauthenticatedNewsArticle[],
  articleSearchString: string,
  tagIds: number[],
): UnauthenticatedNewsArticle[] => {
  const normalisedSearchString = articleSearchString.trim().toLowerCase();
  let filteredArticles = articles.filter((d) => d.name.toLowerCase().includes(normalisedSearchString));
  filteredArticles =
    tagIds.length === 0
      ? filteredArticles
      : filteredArticles.filter((a) => tagIds.some((id) => a.tags.some((t) => t.id === id)));
  return filteredArticles;
};

const NewsTable = ({ articles, tagOptions }: NewsTableProps): JSX.Element => {
  const [articleSearchString, setArticleSearchString] = useState<string>('');
  const [tagIds, setTagIds] = useState<number[]>([]);
  const [filteredArticles, setFilteredArticles] = useState(articles);

  useEffect(() => {
    setFilteredArticles(applyFilters(articles, articleSearchString, tagIds));
  }, [articles, tagIds, articleSearchString]);

  return (
    <div>
      <FilterDropdown resultCount={filteredArticles.length} heading="Filter News">
        <div className="row mb-2">
          <Form.Group className="col">
            <Form.Label className="fw-normal mb-0">
              <FilterHeading heading="Search for Article Title" />
            </Form.Label>
            <FilterSearchBox
              id="newsSearchBox"
              searchString={articleSearchString}
              setSearchString={setArticleSearchString}
              placeholderText="Search for article title"
            />
          </Form.Group>
          <div className="col">
            <FilterHeading heading="Filter by Tag" />
            <SearchableDropdown
              options={tagOptions}
              currentSelection={tagIds}
              placeholderText="Choose Tags"
              onChange={(v) => setTagIds(v)}
              allowMultiSelect
            />
          </div>
        </div>
      </FilterDropdown>
      <Table
        data={filteredArticles}
        columns={columns}
        paginated
        // This is a public page, so we don't allow export
        exportable={false}
        rowHeadingIndex={0}
      />
    </div>
  );
};

export default NewsTable;
