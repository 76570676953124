import { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import { DateTimeSelectorProps } from '@/Components/DateTime/DateTimeSelectorProps';

const TimeSelector = ({
  dateTime,
  setDateTime,
  disabled = false,
  allowPast = false,
  isInvalid = false,
}: DateTimeSelectorProps): JSX.Element => {
  const formatTimeString = (date: Date | null): string => {
    if (!date) {
      return '';
    }
    return date.toLocaleTimeString('en-GB');
  };

  const handleTimeChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (setDateTime) {
      // valueAsNumber is a valid IDL attribute that TypeScript is not recognising
      // see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/time
      const timeInMilliseconds = event.target.valueAsNumber;
      setDateTime((prevDateTime) => {
        const newDate = new Date(prevDateTime!.getTime());
        if (timeInMilliseconds) {
          newDate.setHours(0, 0, 0, 0);
          newDate.setTime(newDate.getTime() + timeInMilliseconds);
        } else {
          newDate.setHours(0, 0, 0, 0);
        }
        return newDate;
      });
    }
  };

  return (
    <div className="formDatePickerContainer">
      <Form.Control
        className="dateTimePicker"
        type="time"
        onChange={handleTimeChange}
        value={formatTimeString(dateTime)}
        min={!allowPast ? formatTimeString(new Date(Date.now())) : undefined}
        disabled={disabled}
        isInvalid={isInvalid}
      />
    </div>
  );
};

export default TimeSelector;
