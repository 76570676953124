import classNames from 'classnames';
import { ReactNode } from 'react';
import { ButtonVariant } from 'react-bootstrap/esm/types';
import { Link, To } from 'react-router-dom';
import '@/Components/Links/LinkButton.scss';

type LinkButtonProps = {
  to: To;
  state?: unknown;
  variant?: ButtonVariant;
  className?: string;
  disabled?: boolean;
  children: ReactNode;
};

const LinkButton = ({
  to,
  state,
  variant = 'primary',
  className,
  disabled = false,
  children,
}: LinkButtonProps): JSX.Element => (
  <div className={classNames('d-inline-block', 'align-middle', 'linkButton', className)}>
    <Link
      to={to}
      state={state}
      className={classNames('btn', `btn-${variant}`, disabled && 'disabled', 'd-flex', 'align-items-center')}
      role="button"
      aria-disabled={disabled}
    >
      {children}
    </Link>
  </div>
);

export default LinkButton;
