import '@/Components/Form/AccessibleFeedback.scss';
import { ReactNode } from 'react';

type AccessibleFeedbackProps = {
  children: ReactNode;
  displayFeedback: boolean;
  id: string;
};

export const AccessibleFeedback = ({ children, displayFeedback, id }: AccessibleFeedbackProps): JSX.Element => (
  // We render an empty element here at all times - this is to ensure that changes within the element are announced
  // properly (an aria-live block doesn't seem to announce itself when it is first rendered) when the validation status
  // changes
  <div className="invalidFeedback mt-1 w-100 d-block" id={id} aria-live="polite" role="alert">
    {displayFeedback && children}
  </div>
);
