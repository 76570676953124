import { ProviderRole, RshRole, ValidationStatus, ValidationType } from 'nrosh-common/Api/Enums';
import { SubmissionPartValidation } from 'nrosh-common/Api/SubmissionsApi';
import { DataDictionaryResponse } from 'nrosh-common/Api/SurveyPartsApi';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { useContext } from 'react';
import { Form, Stack } from 'react-bootstrap';
import { DataCellStyle, TabLayout } from '@/Components/Spreadsheet/SpreadsheetTypes';
import Validations from '@/Components/Validations/Validations';
import { useSubmissionPartData } from '@/Pages/Submissions/SubmissionPartContext/SubmissionPartContext';

const Filter = ({
  inputId,
  label,
  options,
  setValue,
  value,
}: {
  inputId: string;
  label: string;
  options: { value: string; description: string }[];
  value: string;
  setValue: ReactStateSetter<string>;
}): JSX.Element => (
  <div className="d-flex align-items-center gap-1">
    <label htmlFor={inputId}>{label}</label>
    <Form.Select id={inputId} size="sm" value={value} onChange={(e) => setValue(e.target.value)}>
      {options.map((opt) => (
        <option key={opt.value} value={opt.value}>
          {opt.description}
        </option>
      ))}
    </Form.Select>
  </div>
);
export const all = 'All';
export const active = 'Active';

const severityOptions = [
  { value: all, description: all },
  { value: ValidationType.Hard, description: ValidationType.Hard },
  { value: ValidationType.Soft, description: ValidationType.Soft },
];

const filterSeverity = (option: string, validation: SubmissionPartValidation): boolean => {
  switch (option) {
    case ValidationType.Hard as string:
      return validation.type === ValidationType.Hard;
    case ValidationType.Soft as string:
      return validation.type === ValidationType.Soft;
    case all:
      return true;
    default:
      throw new Error('Invalid option');
  }
};

const statusOptions = [
  { value: all, description: all },
  { value: ValidationStatus.Pass, description: 'Passing' },
  { value: ValidationStatus.Fail, description: 'Failing' },
];

const filterStatus = (option: string, validation: SubmissionPartValidation): boolean => {
  switch (option) {
    case ValidationStatus.Pass as string:
      return validation.isPassing || validation.isApproved;
    case ValidationStatus.Fail as string:
      return !validation.isPassing && !validation.isApproved;
    case all:
    default:
      return true;
  }
};

const locationOptions = [
  { value: all, description: 'All Tabs' },
  { value: active, description: 'Active Tab' },
];

const filterLocation = (option: string, validation: SubmissionPartValidation, dataPointIdsOnTab: string[]): boolean => {
  switch (option) {
    case active:
      return validation.formula.some((dp) => dp.dataPointId && dataPointIdsOnTab.includes(dp.dataPointId));
    case all:
    default:
      return true;
  }
};

const SubmissionPartValidations = (props: {
  validations: SubmissionPartValidation[];
  updateCommentInHub: (validationId: string, comment: string) => void;
  deleteCommentInHub: (validationId: string) => void;
  layout: TabLayout;
  dataDictionary: DataDictionaryResponse;
  severityOption: string;
  setSeverityOption: ReactStateSetter<string>;
  statusOption: string;
  setStatusOption: ReactStateSetter<ValidationStatus | string>;
  locationOption: string;
  setLocationOption: ReactStateSetter<string>;
  downloadValidations: () => Promise<void>;
}): JSX.Element => {
  const auth = useContext(AuthContext);
  const {
    validations,
    layout,
    dataDictionary,
    updateCommentInHub,
    deleteCommentInHub,
    severityOption,
    setSeverityOption,
    statusOption,
    setStatusOption,
    locationOption,
    setLocationOption,
    downloadValidations,
  } = props;

  const dataPointIdsOnTab = layout.cellStyles
    .filter((cs) => 'dataPoint' in cs && cs.dataPoint.dataPointId)
    .map((cs) => (cs as DataCellStyle).dataPoint.dataPointId);

  const displayedValidations = validations
    .filter((v) => filterSeverity(severityOption, v))
    .filter((v) => filterStatus(statusOption, v))
    .filter((v) => filterLocation(locationOption, v, dataPointIdsOnTab));

  const { updateValidationIssue, isAdmin, dimensionMembers, surveyStatus, readonly } = useSubmissionPartData();

  const commentsAreReadonly = !auth.hasOneOfRoles(RshRole.EditSurveyData, ProviderRole.EditSurveyData) || readonly;

  return (
    <div className="w-100">
      <Stack direction="horizontal" gap={3} className="my-2">
        <span className="fw-bold">Filters</span>
        <Filter
          inputId="select-severity"
          label="Type:"
          options={severityOptions}
          value={severityOption}
          setValue={setSeverityOption}
        />
        <Filter
          inputId="select-status"
          label="Status:"
          options={statusOptions}
          value={statusOption}
          setValue={setStatusOption}
        />
        <Filter
          inputId="select-location"
          label="Location:"
          options={locationOptions}
          value={locationOption}
          setValue={setLocationOption}
        />
      </Stack>
      <Validations
        validations={displayedValidations}
        dataPoints={dataDictionary.dataPoints}
        commentsAreReadonly={commentsAreReadonly}
        updateComment={updateCommentInHub}
        deleteComment={deleteCommentInHub}
        updateValidationApprovalStatus={updateValidationIssue}
        isAdmin={isAdmin}
        includeLinksToDataPoints
        dimensionMembers={dimensionMembers}
        surveyStatus={surveyStatus}
        downloadValidations={downloadValidations}
      />
    </div>
  );
};

export default SubmissionPartValidations;
