import '@/Pages/Home/App.scss';
import { ThemeProvider, createTheme } from '@mui/material';
import { CurrentUser } from 'nrosh-common/Api/AccountApi';
import { ProviderRole, RshRole, UserRole } from 'nrosh-common/Api/Enums';
import AuthContext, { Auth } from 'nrosh-common/Contexts/AuthContext';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { useMemo, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { DCSErrorBoundary } from '@/Components/Errors/DCSErrorBoundary';
import DCSModalProvider from '@/Components/Modal/ModalProvider';
import { AccountApi } from '@/Helpers/Apis';
import LayoutWithNavbar from '@/Pages/Home/LayoutWithNavbar';
import LayoutWithNoSidebar from '@/Pages/Home/LayoutWithNoSidebar';
import { LayoutWithAdminSidebar, LayoutWithProviderSidebar } from '@/Pages/Home/LayoutWithSidebar';
import LoginLayout from '@/Pages/Home/LoginLayout';
import AccessiblePage from '@/Pages/Home/Navigation/AccessiblePage';
import ScreenReaderMessage from '@/Pages/Home/Navigation/ScreenReaderMessage';
import { ScreenReaderMessageContextProvider } from '@/Pages/Home/Navigation/ScreenReaderMessagesContext';
import ScrollToTop from '@/Pages/Home/Navigation/ScrollToTop';
import StealFocus from '@/Pages/Home/Navigation/StealFocus';
import RequireRole from '@/Pages/Home/RequireRole';
import {
  Page,
  adminPages,
  loginPages,
  providerPages,
  publicPages,
  submissionPages,
  surveyPages,
  userPages,
} from '@/Pages/Home/SitePages';

export const navBarPages = [
  { name: 'Home', path: publicPages.PublicHome.path },
  { name: 'My DCS', path: providerPages.SubmissionsList.path, requiredRole: ProviderRole.User },
  { name: 'Admin', path: adminPages.AdminProviders.path, requiredRole: RshRole.User },
  { name: 'Surveys', path: surveyPages.Surveys.path, requiredRole: RshRole.User },
  { name: 'Documents', path: publicPages.PublicDocuments.path },
  {
    name: 'Help & Contact',
    path: '',
    dropdownLinks: [
      { name: 'Contact us', path: publicPages.PublicContactUs.path },
      { name: 'FAQs', path: publicPages.PublicFaqs.path },
    ],
  },
  { name: 'News', path: publicPages.PublicNews.path },
];

export const navBarUserPages = [
  { name: 'Personal details', path: userPages.UserPersonalDetails.path },
  { name: 'Login & security', path: userPages.UserLoginSecurity.path },
];

const renderPage = (page: Page): JSX.Element => (
  <AccessiblePage key={page.key} title={page.title}>
    {page.render()}
  </AccessiblePage>
);

const renderPageRoute = (page: Page): JSX.Element => (
  <Route
    key={page.key}
    path={page.path}
    element={
      page.roles ? <RequireRole requireOneOfRoles={page.roles}>{renderPage(page)}</RequireRole> : renderPage(page)
    }
  />
);

const renderRoutes = (pages: { [id: string]: Page }): JSX.Element[] => Object.values(pages).map(renderPageRoute);

const renderLoginRoutes = (): JSX.Element => (
  <>
    {Object.entries(loginPages).map(([id, page]) => (
      <Route key={page.key} element={<LoginLayout />}>
        {renderRoutes({ [id]: page })}
      </Route>
    ))}
  </>
);

const { palette } = createTheme();
export const muiTheme = createTheme({
  palette: {
    nrosh: palette.augmentColor({
      color: {
        main: '#1EB6C8', // $primary-colour in _nrosh-colours.scss
      },
    }),
  },
});

const Site = (props: { initialUser: CurrentUser }): JSX.Element => {
  const { initialUser } = props;
  const [user, setUser] = useState<CurrentUser>(initialUser);
  const auth = useMemo(() => new Auth(user, setUser), [user, setUser]);

  return (
    <AuthContext.Provider value={auth}>
      <ThemeProvider theme={muiTheme}>
        <DCSModalProvider>
          <DCSErrorBoundary>
            <ScreenReaderMessageContextProvider>
              <BrowserRouter>
                <StealFocus />
                <ScreenReaderMessage />
                <ScrollToTop>
                  <Routes>
                    {renderLoginRoutes()}
                    <Route path="/" element={<LayoutWithNavbar />}>
                      <Route path="/" element={<LayoutWithNoSidebar />}>
                        {renderRoutes(publicPages)}
                      </Route>
                      <Route
                        path="/surveys"
                        element={
                          <RequireRole requireOneOfRoles={[RshRole.User]}>
                            <LayoutWithNoSidebar />
                          </RequireRole>
                        }
                      >
                        {renderRoutes({ ...surveyPages, ...submissionPages })}
                      </Route>
                      <Route
                        path="/admin"
                        element={
                          <RequireRole requireOneOfRoles={[RshRole.User]}>
                            <LayoutWithAdminSidebar />
                          </RequireRole>
                        }
                      >
                        {renderRoutes(adminPages)}
                      </Route>
                      <Route
                        path="/my-dcs"
                        element={
                          <RequireRole requireOneOfRoles={[ProviderRole.User]}>
                            <LayoutWithProviderSidebar />
                          </RequireRole>
                        }
                      >
                        {renderRoutes(providerPages)}
                      </Route>
                      <Route
                        path="/user"
                        element={
                          <RequireRole requireOneOfRoles={[UserRole.LoggedIn]}>
                            {auth.hasRole(RshRole.User) ? <LayoutWithAdminSidebar /> : <LayoutWithProviderSidebar />}
                          </RequireRole>
                        }
                      >
                        {renderRoutes(userPages)}
                      </Route>
                    </Route>
                  </Routes>
                </ScrollToTop>
              </BrowserRouter>
            </ScreenReaderMessageContextProvider>
          </DCSErrorBoundary>
        </DCSModalProvider>
      </ThemeProvider>
    </AuthContext.Provider>
  );
};

const App = (): JSX.Element | null => {
  const [data] = useEndpoint<CurrentUser>(AccountApi.getCurrentUser);
  return data && <Site initialUser={data} />;
};

export default App;
