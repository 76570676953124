import React, { ReactElement, cloneElement, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/types';

type TooltipOnHoverProps = {
  placement: Placement;
  text: string;
  children: ReactElement;
};

const TooltipOnHover = ({ text, children, placement = 'top' }: TooltipOnHoverProps): JSX.Element => {
  const [show, setShow] = useState(false);
  // Placeholder until upgrade to react 18, note we can't use crypto here since it doesn't exist in the version of node storybook is using.
  const [tooltipId] = useState(`tooltip-${Math.random() * 1000000}`);
  const tooltip = <Tooltip id={tooltipId}>{text}</Tooltip>;
  const labelledChildren = cloneElement(children, { 'aria-describedby': show ? tooltipId : undefined });

  return (
    <OverlayTrigger placement={placement} overlay={tooltip} show={show} onToggle={(nextShow) => setShow(nextShow)}>
      {/* Wrapping in a span here is needed so that the tooltip shows even when the child is a disabled button. */}
      <span className="d-inline-flex">{labelledChildren}</span>
    </OverlayTrigger>
  );
};

export default TooltipOnHover;
