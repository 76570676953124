import { SurveyStatus } from 'nrosh-common/Api/SubmissionsApi';
import { Dispatch, SetStateAction } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useModal } from '@/Components/Modal/ModalProvider';
import { SurveysApi } from '@/Helpers/Apis';
import { surveyPages } from '@/Pages/Home/SitePages';

type UseSurveyViewButtonsProps = {
  surveyId: string;
  timePeriodId: string;
  setError: Dispatch<SetStateAction<string | null>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setSurveyStatus: Dispatch<SetStateAction<SurveyStatus | undefined>>;
};

type ManageSurveyStates = {
  openSurvey: () => Promise<void>;
  closeSurvey: () => Promise<void>;
  reopenSurvey: () => Promise<void>;
  deleteSurvey: () => Promise<void>;
  markSurveyAsInDevelopment: () => Promise<void>;
};

const useManageSurveyState = ({
  surveyId,
  timePeriodId,
  setError,
  setIsLoading,
  setSurveyStatus,
}: UseSurveyViewButtonsProps): ManageSurveyStates => {
  const modal = useModal();
  const navigate = useNavigate();
  const confirmAndChangeSurveyStatus = async (newStatus: SurveyStatus, confirmationMessage: string): Promise<void> => {
    if (!(await modal.confirm(confirmationMessage, { title: 'Action Confirmation' }))) {
      return;
    }

    setError(null);
    setIsLoading(true);

    let response;
    switch (newStatus) {
      case SurveyStatus.Open:
        response = await SurveysApi.openSurvey(surveyId, timePeriodId).raw;
        break;
      case SurveyStatus.Closed:
        response = await SurveysApi.closeSurvey(surveyId, timePeriodId).raw;
        break;
      case SurveyStatus.InDevelopment:
        response = await SurveysApi.markSurveyAsInDevelopment(surveyId, timePeriodId).raw;
        break;
      default:
        throw Error('Bad Switch');
    }

    if (response.ok) {
      setSurveyStatus(newStatus);
    } else {
      const error = response.value.message;
      setError(error);
    }
    setIsLoading(false);
  };

  const openSurvey = async (): Promise<void> =>
    confirmAndChangeSurveyStatus(
      SurveyStatus.Open,
      'Are you sure you want to open this survey? This will make it available for assignment to providers, and it will appear on the survey dashboard.',
    );

  const markSurveyAsInDevelopment = async (): Promise<void> =>
    confirmAndChangeSurveyStatus(
      SurveyStatus.InDevelopment,
      'Are you sure you want to mark this survey as in development? This will prevent assignment of this survey to providers, and it will no longer appear on the survey dashboard.',
    );

  const closeSurvey = async (): Promise<void> =>
    confirmAndChangeSurveyStatus(
      SurveyStatus.Closed,
      'Are you sure you want to close this survey? Once the survey is closed, providers will no longer be able to edit or submit data, and DCS users will no longer be able to edit this instance of the survey.',
    );

  const reopenSurvey = async (): Promise<void> =>
    confirmAndChangeSurveyStatus(
      SurveyStatus.Open,
      'Are you sure you want to reopen this survey? If the survey is reopened, providers will be able to edit and submit submissions, and DCS users will be able to edit this instance of the survey.',
    );

  const deleteSurvey = async (): Promise<void> => {
    const confirmationMessage =
      'Deleting this survey will permanently erase any data associated with it. Are you sure you want to delete this survey? This action is not reversible.';
    if (
      await modal.confirm(confirmationMessage, {
        title: 'Delete Survey',
        swapConfirmAndCancelStyles: true,
        confirmButtonText: 'Yes, delete',
      })
    ) {
      const response = await SurveysApi.deleteSurveyInstance(surveyId, timePeriodId).raw;

      if (response.ok) {
        navigate(generatePath(surveyPages.Surveys.path));
        return;
      }

      setError(response.value.message);
    }
  };

  return {
    openSurvey,
    closeSurvey,
    reopenSurvey,
    deleteSurvey,
    markSurveyAsInDevelopment,
  };
};

export default useManageSurveyState;
