import { PrimaryButton } from '@/Components/Buttons/DCSButton';

type AuditLogsSearchButtonsProps = {
  clearFilters: () => Promise<void>;
};

const AuditLogsSearchButtons = (props: AuditLogsSearchButtonsProps): JSX.Element => {
  const { clearFilters } = props;
  return (
    <div className="d-flex flex-row mt-3 gap-3">
      <button className="clearFiltersButton align-self-center" type="button" onClick={() => clearFilters()}>
        Clear filters
      </button>
      <PrimaryButton className="addSaveButton" type="submit">
        Search
      </PrimaryButton>
    </div>
  );
};

export default AuditLogsSearchButtons;
