import classNames from 'classnames';
import { CellStyle } from 'nrosh-common/Api/Enums';
import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { ReactNode } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BaseCellProps, CellContent } from '@/Components/Spreadsheet/Cells/CellHelpers';
import { freezePaneClassName, styleClassName } from '@/Components/Spreadsheet/Cells/CellStyleHelpers';

type DataPointCellProps = BaseCellProps & {
  cellKey: string;
  active: boolean;
  setActiveCell: ReactStateSetter<string | null>;
};

export const DataPointCell = (props: DataPointCellProps & { children: ReactNode }): JSX.Element => {
  const { style, height, rowSpan, columnSpan, left, top, cellKey, active, setActiveCell, children } = props;

  const className = classNames(styleClassName(style), freezePaneClassName(props), {
    active,
  });

  const setActive = (): void => setActiveCell(cellKey);
  const setInactive = (): void => setActiveCell(null);

  return (
    <td
      className={className}
      id={cellKey}
      onFocus={setActive}
      onBlur={setInactive}
      rowSpan={rowSpan}
      colSpan={columnSpan}
      style={{
        left,
        top,
      }}
      aria-hidden={style === CellStyle.Hidden}
    >
      <CellContent height={height}>{children}</CellContent>
    </td>
  );
};

export const DataPointCellWithOverlay = (
  props: DataPointCellProps & {
    dataPointId: string;
    inputId: string;
    helpText: string | null;
    overlayPosition: 'top' | 'right';
    children: JSX.Element;
  },
): JSX.Element => {
  const { cellKey, dataPointId, inputId, helpText, overlayPosition, children } = props;

  return (
    <DataPointCell {...props}>
      <label htmlFor={inputId}>Update data item {dataPointId}:</label>
      {helpText ? (
        <OverlayTrigger
          placement={overlayPosition}
          trigger="focus"
          overlay={<Tooltip id={`help-text-${cellKey}`}>{helpText}</Tooltip>}
        >
          {children}
        </OverlayTrigger>
      ) : (
        children
      )}
    </DataPointCell>
  );
};
