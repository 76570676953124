import { ScheduledEmailResponse, ScheduledEmailStatus } from 'nrosh-common/Api/EmailsApi';
import { ProviderSummary } from 'nrosh-common/Api/ProvidersApi';
import { SubmissionStatusDict, SurveyStatus } from 'nrosh-common/Api/SubmissionsApi';
import { Surveys } from 'nrosh-common/Api/SurveysApi';
import { UserProfile } from 'nrosh-common/Api/UsersApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { Stack } from 'react-bootstrap';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { ContactTypeTitleMapping } from '@/Components/EndOfSurveyChecks/ContactTypeTitleMapping';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { EmailsApi, ProvidersApi, SurveysApi, UsersApi } from '@/Helpers/Apis';
import { generateInitialFilterFromNameRecord, getSelectionTypeFromFilterOptions } from '@/Helpers/FilterHelpers';
import { surveyDisplayName } from '@/Helpers/SurveyHelper';
import useUnsavedChangesWarning from '@/Hooks/useUnsavedChangesWarning';
import { SelectionType } from '@/Pages/Emails/EmailUtilities';
import { EmailRecipientsForm } from '@/Pages/Emails/Manual/EmailRecipientsForm';
import { adminPages } from '@/Pages/Home/SitePages';
import '@/Pages/Emails/Manual/EmailRecipientsPage.scss';
import '@/Pages/Emails/EmailStyles.scss';
import { providerSizeNames, providerTypeNames, reportingStatusNames } from '@/Pages/Providers/ProviderEnums';

export const EmailRecipientsPage = (): JSX.Element => {
  const { emailId } = useParams();

  const [emailData] = useEndpoint<ScheduledEmailResponse>(EmailsApi.getScheduledEmail, emailId);
  const [setDirty, setPristine] = useUnsavedChangesWarning();
  const [surveyList] = useEndpoint<Surveys>(SurveysApi.getSurveys);
  const [providerList] = useEndpoint<ProviderSummary[]>(ProvidersApi.getProviders);
  const [profilesList] = useEndpoint<UserProfile[]>(UsersApi.getProviderProfiles);

  const navigate = useNavigate();

  if (!emailData || !surveyList || !providerList || !profilesList) {
    return <LoadingMessage />;
  }

  if (emailData.status !== ScheduledEmailStatus.Draft) {
    navigate(generatePath(adminPages.AdminViewOrEditEmail.path, { emailId: emailId! }));
    return <LoadingMessage />;
  }

  // Survey Instances
  const surveyInstanceIds =
    emailData.recipientsFilter?.surveyInstanceIds?.split(',')?.map((x) => {
      const splitSurveyInstanceId = x.split(':');
      return {
        surveyId: parseInt(splitSurveyInstanceId[0], 10),
        timePeriodId: parseInt(splitSurveyInstanceId[1], 10),
      };
    }) ?? [];

  const surveyInstanceSelectionPattern = surveyList.surveys
    .map((survey) =>
      survey.instances
        .filter((instance) => instance.surveyStatus === SurveyStatus.Open)
        .map((instance) => ({
          surveyId: survey.surveyId,
          timePeriodId: instance.timePeriodId,
          displayName: surveyDisplayName(survey.surveyName, instance.timePeriodName),
          isSelected: surveyInstanceIds.some(
            (instanceIds) =>
              instanceIds.surveyId === survey.surveyId && instanceIds.timePeriodId === instance.timePeriodId,
          ),
        })),
    )
    .flat()
    .sort((a, b) => a.displayName.localeCompare(b.displayName));

  const surveyInstanceSelectionType = surveyInstanceSelectionPattern.every((s) => !s.isSelected)
    ? SelectionType.All
    : SelectionType.Specific;

  // Submission Statuses
  const submissionStatusSelectionPattern = generateInitialFilterFromNameRecord(
    SubmissionStatusDict,
    (ss) => emailData.recipientsFilter?.submissionStatuses?.split(',').includes(ss) ?? false,
  );
  const submissionStatusSelectionType = getSelectionTypeFromFilterOptions(
    emailData.recipientsFilter?.submissionStatuses,
  );

  // Provider Sizes
  const providerSizeSelectionPattern = generateInitialFilterFromNameRecord(
    providerSizeNames,
    (ps) => emailData.recipientsFilter?.providerSizes?.split(',').includes(ps) ?? false,
  );
  const providerSizeSelectionType = getSelectionTypeFromFilterOptions(emailData.recipientsFilter?.providerSizes);

  // Reporting Statuses
  const reportingStatusSelectionPattern = generateInitialFilterFromNameRecord(
    reportingStatusNames,
    (rs) => emailData.recipientsFilter?.reportingStatuses?.split(',').includes(rs) ?? false,
  );
  const reportingStatusSelectionType = getSelectionTypeFromFilterOptions(emailData.recipientsFilter?.reportingStatuses);

  // Provider Types
  const providerTypeSelectionPattern = generateInitialFilterFromNameRecord(
    providerTypeNames,
    (pt) => emailData.recipientsFilter?.providerTypes?.split(',').includes(pt) ?? false,
  );
  const providerTypeSelectionType = getSelectionTypeFromFilterOptions(emailData.recipientsFilter?.providerTypes);

  // Excluded Providers
  const excludedProviders = providerList.map((pl) => ({
    ...pl,
    isSelected: emailData.recipientsFilter?.excludedProviderIds?.split(',').includes(pl.id.toString()) ?? false,
  }));

  // Organisational Contacts
  const contactTypes = emailData.recipientsFilter?.providerContactsContactTypes?.split(',') ?? [];
  const organisationalContactsSelectionType =
    emailData.recipientsFilter === null || emailData.recipientsFilter === undefined
      ? SelectionType.None
      : getSelectionTypeFromFilterOptions(emailData.recipientsFilter.providerContactsContactTypes);

  const organisationalContactsSelectionPattern = generateInitialFilterFromNameRecord(ContactTypeTitleMapping, (ct) =>
    organisationalContactsSelectionType === SelectionType.None ? false : contactTypes.includes(ct),
  ).sort((a, b) => a.displayName.localeCompare(b.displayName));

  // User Profiles
  const userProfiles = emailData.recipientsFilter?.userProfiles?.split(',') ?? [];
  const userProfilesSelectionType = getSelectionTypeFromFilterOptions(emailData.recipientsFilter?.userProfiles);

  const userProfilesSelectionPattern = profilesList.map((up) => ({
    value: up.id,
    displayName: up.name,
    isSelected: userProfilesSelectionType === SelectionType.None ? false : userProfiles.includes(up.id.toString()),
  }));

  const initialState = {
    surveyInstances: {
      selectionType: surveyInstanceSelectionType,
      pattern: surveyInstanceSelectionPattern,
    },
    submissionStatuses: {
      selectionType: submissionStatusSelectionType,
      pattern: submissionStatusSelectionPattern,
    },
    providerSizes: {
      selectionType: providerSizeSelectionType,
      pattern: providerSizeSelectionPattern,
    },
    providerTypes: {
      selectionType: providerTypeSelectionType,
      pattern: providerTypeSelectionPattern,
    },
    reportingStatuses: {
      selectionType: reportingStatusSelectionType,
      pattern: reportingStatusSelectionPattern,
    },
    organisationalContacts: {
      selectionType: organisationalContactsSelectionType,
      pattern: organisationalContactsSelectionPattern,
    },
    userProfiles: {
      selectionType: userProfilesSelectionType,
      pattern: userProfilesSelectionPattern,
    },
    excludedProviders,
  };

  return (
    <Stack gap={5}>
      <PageHeader
        heading="Set recipients"
        crumbsType={RootPathType.AdminEmails}
        crumbs={[
          {
            name: 'Manual emails',
            path: adminPages.AdminManualEmails.path,
          },
          {
            name: emailData.subject ? emailData.subject : 'Compose email',
            path: adminPages.AdminViewOrEditEmail.path,
          },
          { name: 'Recipients', path: adminPages.AdminEmailSetRecipients.path },
        ]}
      />
      <EmailRecipientsForm
        emailId={emailId!}
        setDirty={setDirty}
        setPristine={setPristine}
        initialState={initialState}
        surveyList={surveyList}
        profileList={profilesList}
        providerList={providerList}
      />
    </Stack>
  );
};
