import { APIResponse } from 'nrosh-common/Api/ApiClient';
import { Tag } from 'nrosh-common/Api/CommonTypes';
import { RshRole } from 'nrosh-common/Api/Enums';
import { SubmissionSupportingDocumentMetadata } from 'nrosh-common/Api/SubmissionsApi';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { FormEvent, useContext, useReducer, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import FileUploadArea from '@/Components/FileUpload/FileUploadArea';
import { FileItem, MultipleFileUploadManager } from '@/Components/FileUpload/MultipleFileUploadManager';
import { AccessibleFeedback } from '@/Components/Form/AccessibleFeedback';
import LinkButton from '@/Components/Links/LinkButton';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import TagCheckboxes from '@/Components/Tags/TagCheckboxes';
import { TagsApi } from '@/Helpers/Apis';
import { getValidityProps } from '@/Helpers/Forms';
import useUnsavedChangesWarning from '@/Hooks/useUnsavedChangesWarning';
import { providerPages, submissionPages } from '@/Pages/Home/SitePages';
import '@/Pages/Submissions/Documents/SupportingDocumentForm.scss';

type SupportingDocumentFormProps = {
  document?: SubmissionSupportingDocumentMetadata;
  isEdit: boolean;
  submitAction: (form: FormData) => Promise<APIResponse<unknown>>;
};

const SupportingDocumentForm = ({ document, isEdit, submitAction }: SupportingDocumentFormProps): JSX.Element => {
  const { surveyId, timePeriodId, submissionId } = useParams();
  const [supportingDocumentTags] = useEndpoint<Tag[]>(TagsApi.getSupportingDocumentTags);

  const isNewDocument = document === undefined;

  const [error, setError] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [setDirty, setPristine, isDirty] = useUnsavedChangesWarning();
  const [validated, setValidated] = useState<boolean>(false);
  const [filesHaveBeenUploaded, setFilesHaveBeenUploaded] = useState(false);
  const [showSuccessfulUploadMessage, setShowSuccessfulUploadMessage] = useState(false);
  const [submitButtonMessage] = useState(isEdit ? 'Save' : 'Upload');

  const [description, setDescription] = useState<string | null>(document?.description ?? null);
  const [files, setFiles] = useState<FileItem[]>(
    isNewDocument ? [] : [{ fileName: document.fileName, fileObject: null }],
  );
  const [fileWasChanged, markFileAsChanged] = useReducer(() => true, false);
  const [selectedTagIds, setSelectedTagIds] = useState<number[]>(document?.tags.map((t) => t.id) ?? []);

  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  if (!supportingDocumentTags) {
    return <LoadingMessage />;
  }

  const submissionPagePath = auth.hasRole(RshRole.User)
    ? generatePath(submissionPages.SubmissionView.path, { surveyId, timePeriodId, submissionId })
    : generatePath(providerPages.SubmissionsDetail.path, { submissionId });

  const onSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (!e.currentTarget.checkValidity() || files.length === 0) {
      setValidated(true);
      return;
    }

    setError(null);
    setPristine();
    setIsSaving(true);

    const formData = new FormData();

    const filesToUpload = files.filter((fi) => fi.fileObject !== null).map((fi) => fi.fileObject!);
    filesToUpload.forEach((file) => formData.append(file.name, file, file.name));

    formData.append('fileWasChanged', fileWasChanged.toString());
    selectedTagIds.forEach((id) => formData.append('tagIds', id.toString()));
    if (description) formData.append('description', description);

    const response = await submitAction(formData);

    if (response.ok) {
      if (!isEdit) {
        setFilesHaveBeenUploaded(true);
        setShowSuccessfulUploadMessage(true);
      } else {
        navigate(submissionPagePath);
        return;
      }
    } else {
      setError(response.value.message);
    }

    setIsSaving(false);
  };

  const initialFileData = document && { name: document.fileName, sizeBytes: document.fileSizeBytes };

  return (
    <Form noValidate className="w-25 supportingDocumentForm" onSubmit={onSubmit}>
      <Form.Group className="mb-3" controlId="upload">
        <Form.Label>Select {isNewDocument ? 'files' : 'file'}</Form.Label>
        {isNewDocument ? (
          <MultipleFileUploadManager
            aria-label="files"
            files={files}
            setFiles={setFiles}
            setDirty={setDirty}
            {...getValidityProps(validated && files.length === 0, 'file-feedback')}
          />
        ) : (
          <FileUploadArea
            aria-label="file"
            value={files[0]?.fileObject}
            initialFileData={initialFileData}
            allowZeroSizeFiles={false}
            onChange={(file) => {
              setFiles(file ? [{ fileName: file.name, fileObject: file }] : []);
              markFileAsChanged();
              setDirty();
            }}
            {...getValidityProps(validated && files.length === 0, 'file-feedback')}
          />
        )}
        <AccessibleFeedback displayFeedback={validated && files.length === 0} id="file-feedback">
          Please select {isNewDocument ? 'at least one' : 'a'} file
        </AccessibleFeedback>
      </Form.Group>
      <Form.Group controlId="description" className="mb-3">
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="description"
          aria-label="description"
          value={description ?? undefined}
          onChange={(e) => {
            setDescription(e.target.value);
            setDirty();
          }}
        />
      </Form.Group>
      <div className="mb-3 d-flex flex-column gap-2">
        <span>Tags</span>
        <TagCheckboxes
          allTags={supportingDocumentTags}
          selectedTagIds={selectedTagIds}
          setSelectedTagIds={setSelectedTagIds}
          onChange={setDirty}
        />
      </div>
      {!filesHaveBeenUploaded ? (
        <div className="d-flex gap-2 mb-3">
          <LinkButton to={submissionPagePath} variant="outline-primary" disabled={isSaving}>
            Cancel
          </LinkButton>
          {isSaving ? (
            <LoadingButton message={submitButtonMessage} />
          ) : (
            <PrimaryButton type="submit" disabled={!isDirty}>
              {submitButtonMessage}
            </PrimaryButton>
          )}
        </div>
      ) : (
        <div className="d-flex gap-2 mb-3">
          <LinkButton to={submissionPagePath} variant="outline-primary">
            Return to submission page
          </LinkButton>
        </div>
      )}
      {error && (
        <Alert variant="danger" className="mt-3" dismissible onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
      {showSuccessfulUploadMessage && (
        <Alert variant="success" dismissible onClose={() => setShowSuccessfulUploadMessage(false)}>
          Document successfully uploaded
        </Alert>
      )}
    </Form>
  );
};

export default SupportingDocumentForm;
