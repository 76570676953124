import ApiClient, { APIHelpers, handleAPIRequest } from './ApiClient';

export type UserAuditLogFilters = {
  fromDate?: string;
  toDate?: string;
  providerId?: number;
  changedByUserId?: number;
  userId?: number;
  changeType?: string;
};

export type UserAuditLogsRequest = UserAuditLogFilters & {
  filterByRshUsers?: boolean;
};

export type DetailedAuditTrailFilters = {
  fromDate?: string;
  toDate?: string;
  providerId?: number;
  changedByUserId?: number;
  changeType?: string;
  surveyId?: number;
  timePeriodId?: number;
  surveyPartId?: number;
};

export type UserAuditLogsResponse = {
  numberOfResults: number;
  numberOfResultsReturned: number;
  userAuditLogs: UserAuditLog[];
};

export type UserAuditLog = {
  auditLogId: number;
  dateOfChange: string;
  userName: string;
  providerNumber: string;
  providerName: string;
  changeType: AccountChangeType;
  changedByUserName: string;
};

export enum AccountChangeType {
  AccountCreated = 'AccountCreated',
  AccountDisabled = 'AccountDisabled',
  AccountDeletedManual = 'AccountDeletedManual',
  AccountDeletedInactivity = 'AccountDeletedInactivity',
  AccountLockedInactivity = 'AccountLockedInactivity',
  AccountLockedFailedLogin = 'AccountLockedFailedLogin',
  AccountUnlocked = 'AccountUnlocked',
}

export type DetailedAuditTrailResponse = {
  numberOfResults: number;
  numberOfResultsReturned: number;
  detailedAuditTrail: DetailedAuditTrailLog[];
};

export type DetailedAuditTrailLog = {
  auditLogId: number;
  providerNumber: string;
  providerName: string;
  surveyName: string;
  timePeriodName: string;
  surveyPartName: string;
  dateOfChange: string;
  changedByUserName: string;
  changeType: DetailedAuditChangeType;
  field: string;
  oldValue: string;
  newValue: string;
  dimension1: string;
  dimension2: string;
  reason: string;
};

export enum DetailedAuditChangeType {
  SurveyAssigned = 'SurveyAssigned',
  SurveyUnassigned = 'SurveyUnassigned',
  SurveyStatus = 'SurveyStatus',
  DeadlineChange = 'DeadlineChange',
  ValidationTolerance = 'ValidationTolerance',
  ValueChange = 'ValueChange',
  DimensionAdded = 'DimensionAdded',
  DimensionRemoved = 'DimensionRemoved',
  SurveyClosed = 'SurveyClosed',
  SurveyReopened = 'SurveyReopened',
  FileUploaded = 'FileUploaded',
  SurveyDeleted = 'SurveyDeleted',
  SurveyMarkedAsInDevelopment = 'SurveyMarkedAsInDevelopment',
  SurveyOpened = 'SurveyOpened',
}

const AuditLogsApiRoot = 'AuditLogs';
const AuditLogsApiPaths = {
  USER_AUDIT_LOGS: `${AuditLogsApiRoot}/user`,
  DOWNLOAD_USER_AUDIT_LOGS: `${AuditLogsApiRoot}/user/download`,
  DETAILED_AUDIT_TRAIL_LOGS: `${AuditLogsApiRoot}/detailedAuditTrail`,
  DOWNLOAD_DETAILED_AUDIT_TRAIL: `${AuditLogsApiRoot}/detailedAuditTrail/download`,
  DOWNLOAD_DETAILED_AUDIT_TRAIL_ASSOCIATED_FILE: (auditLogId: number) =>
    `${AuditLogsApiRoot}/detailedAuditTrail/${auditLogId}/downloadFile`,
};

const auditLogsApi = (apiClient: ApiClient) => ({
  getUserAuditLogs: (filters?: UserAuditLogsRequest) => {
    return handleAPIRequest(
      apiClient.get(AuditLogsApiPaths.USER_AUDIT_LOGS, filters),
      APIHelpers.json<UserAuditLogsResponse>,
      APIHelpers.standardError,
    );
  },

  downloadUserAuditLogs: (filters?: UserAuditLogsRequest) => {
    return apiClient.getFile(AuditLogsApiPaths.DOWNLOAD_USER_AUDIT_LOGS, filters);
  },

  getDetailedAuditTrailLogs: (filters?: DetailedAuditTrailFilters) => {
    return handleAPIRequest(
      apiClient.get(AuditLogsApiPaths.DETAILED_AUDIT_TRAIL_LOGS, filters),
      APIHelpers.json<DetailedAuditTrailResponse>,
      APIHelpers.standardError,
    );
  },

  downloadDetailedAuditTrail: (filters?: DetailedAuditTrailFilters) => {
    return apiClient.getFile(AuditLogsApiPaths.DOWNLOAD_DETAILED_AUDIT_TRAIL, filters);
  },

  downloadDetailedAuditTrailLogFile: (auditLogId: number) => {
    return apiClient.getFile(AuditLogsApiPaths.DOWNLOAD_DETAILED_AUDIT_TRAIL_ASSOCIATED_FILE(auditLogId));
  },
});

export default auditLogsApi;
