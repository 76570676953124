import classNames from 'classnames';
import { Outlet } from 'react-router-dom';

const LayoutWithNoSidebar = (): JSX.Element => (
  <main className={classNames('fillScreen', 'mainWithNoSidebar')}>
    <Outlet />
  </main>
);

export default LayoutWithNoSidebar;
