import { SurveyInstance } from 'nrosh-common/Api/SurveysApi';
import { generatePath } from 'react-router-dom';
import { TertiaryLinkButton } from '@/Components/Buttons/TertiaryLinkButton';
import Edit from '@/Components/Icons/Edit';
import { formatISODate } from '@/Helpers/DateHelpers';
import { surveyPages } from '@/Pages/Home/SitePages';

type SurveyInformationProps = {
  survey: SurveyInstance;
};

export const SurveyInformation = ({ survey }: SurveyInformationProps): JSX.Element => {
  const surveyId = survey.surveyId?.toString();
  const timePeriodId = survey.timePeriodId?.toString();

  return (
    <div className="surveyViewSection">
      <div className="d-flex flex-row">
        <h2 className="align-self-center">Survey information</h2>
        <div className="ms-2">
          <TertiaryLinkButton
            icon={<Edit />}
            text="Edit"
            to={generatePath(surveyPages.SurveyEdit.path, { surveyId, timePeriodId })}
          />
        </div>
      </div>
      <div className="pt-3">
        {survey.description && <p>Description: {survey.description}</p>}
        <p>Submission deadline: {formatISODate(survey.defaultSubmissionDeadline)}</p>
        <p>Frequency: {survey.frequency}</p>
        <p>Time period: {survey.timePeriodName}</p>
      </div>
    </div>
  );
};
