import { ProviderContact } from 'nrosh-common/Api/ProvidersApi';
import { ContactType } from 'nrosh-common/Api/SurveysApi';
import { nameof } from 'nrosh-common/Helpers/StringHelpers';
import { Dispatch, ReactNode, useContext, useReducer } from 'react';
import { Location, useLocation } from 'react-router-dom';
import { ContactTypeTitleMapping } from '@/Components/EndOfSurveyChecks/ContactTypeTitleMapping';
import { createContext } from '@/Helpers/Context';
import {
  OrganisationalContactForm,
  OrganisationalContactFormBooleanField,
  OrganisationalContactFormStringField,
} from '@/Pages/Submissions/Confirmation/ConfirmOrganisationalContactsTypes';
import { SubmissionConfirmation } from '@/Pages/Submissions/Confirmation/SubmissionConfirmation';

type OrganisationalContactsFormState = { [type in ContactType]: OrganisationalContactForm };

type StringFieldUpdateAction = {
  type: 'update-string-field';
  payload: {
    contactType: ContactType;
    field: OrganisationalContactFormStringField;
    value: string;
  };
};

type BooleanFieldUpdateAction = {
  type: 'update-boolean-field';
  payload: {
    contactType: ContactType;
    field: OrganisationalContactFormBooleanField;
    value: boolean;
  };
};

type ConfirmAllAction = {
  type: 'confirm-all';
  payload: {
    value: boolean;
  };
};

type OrganisationalContactsReducerAction = StringFieldUpdateAction | BooleanFieldUpdateAction | ConfirmAllAction;

const organisationalContactsReducer = (
  state: OrganisationalContactsFormState,
  action: OrganisationalContactsReducerAction,
): OrganisationalContactsFormState => {
  switch (action.type) {
    case 'update-string-field':
    case 'update-boolean-field': {
      const { payload } = action;

      if (!(payload.contactType in state)) {
        throw new Error('Tried to update data for invalid contact');
      }

      return { ...state, [payload.contactType]: { ...state[payload.contactType], [payload.field]: payload.value } };
    }
    case 'confirm-all': {
      const stateClone = { ...state };
      (Object.keys(stateClone) as ContactType[]).forEach((contactType) => {
        stateClone[contactType].confirmed = action.payload.value;
      });
      return stateClone;
    }
    default:
      throw new Error('Unexpected action type');
  }
};

const buildDefaultFormData = (
  location: Location,
  contactType: ContactType,
  contact?: ProviderContact,
): OrganisationalContactForm => {
  const { organisationalDetailsConfirmed } = location.state as SubmissionConfirmation;
  return {
    formTitle: ContactTypeTitleMapping[contactType],
    name: contact?.name ?? '',
    jobTitle: contact?.jobTitle ?? '',
    email: contact?.emailAddress ?? '',
    phone: contact?.phoneNumber ?? '',
    address1: contact?.address.addressLine1 ?? '',
    address2: contact?.address.addressLine2 ?? '',
    address3: contact?.address.addressLine3 ?? '',
    address4: contact?.address.addressLine4 ?? '',
    postcode: contact?.address.postcode ?? '',
    confirmed: organisationalDetailsConfirmed ?? false,
  };
};

const buildDefaultOrganisationalContactsFormState = (
  location: Location,
  contactTypes: ContactType[],
  existingContacts: ProviderContact[],
): OrganisationalContactsFormState =>
  contactTypes.reduce((acc, curr) => {
    acc[curr] = buildDefaultFormData(
      location,
      curr,
      existingContacts.find((c) => c.type === curr),
    );
    return acc;
  }, {} as OrganisationalContactsFormState);

export const useOrganisationalContactsReducer = (
  contactTypes: ContactType[],
  existingContacts: ProviderContact[],
): [OrganisationalContactsFormState, Dispatch<OrganisationalContactsReducerAction>] => {
  const location = useLocation();
  return useReducer(
    organisationalContactsReducer,
    buildDefaultOrganisationalContactsFormState(location, contactTypes, existingContacts),
  );
};

const OrganisationalContactsDispatchContext = createContext<Dispatch<OrganisationalContactsReducerAction>>();

type OrganisationalContactsDispatchContextProviderProps = {
  dispatch: Dispatch<OrganisationalContactsReducerAction>;
  children: ReactNode;
};

export const OrganisationalContactsDispatchContextProvider = ({
  dispatch,
  children,
}: OrganisationalContactsDispatchContextProviderProps): JSX.Element => (
  <OrganisationalContactsDispatchContext.Provider value={dispatch}>
    {children}
  </OrganisationalContactsDispatchContext.Provider>
);

export const useOrganisationalContactsDispatch = (): Dispatch<OrganisationalContactsReducerAction> => {
  const context = useContext(OrganisationalContactsDispatchContext);
  if (!context) {
    throw new Error(
      `${nameof({ useOrganisationalContactsDispatch })} must be used within ${nameof({
        OrganisationalContactsDispatchContextProvider,
      })}`,
    );
  }

  return context;
};
