import { nameof } from 'nrosh-common/Helpers/StringHelpers';
import { ReactNode, useContext } from 'react';
import { Accordion, Table } from 'react-bootstrap';
import ValidationAccordionButton from '@/Components/Validations/ValidationAccordionButton';
import { ValidationApprovalToggleRow } from '@/Components/Validations/ValidationApprovalToggleRow';
import ValidationComment from '@/Components/Validations/ValidationComment';
import ValidationRule from '@/Components/Validations/ValidationRule';
import { ValidationModel } from '@/Components/Validations/ValidationTypes';
import { createContext } from '@/Helpers/Context';
import '@/Styles/BootstrapOverrides/Accordion.scss';
import '@/Components/Validations/ValidationsStyles.scss';

export type ValidationDisplayModel = ValidationModel & { displayName: string };

const ValidationModelContext = createContext<ValidationDisplayModel>();

type ValidationModelContextProviderProps = {
  validation: ValidationDisplayModel;
  children: ReactNode;
};

const ValidationModelContextProvider = ({ validation, children }: ValidationModelContextProviderProps): JSX.Element => (
  <ValidationModelContext.Provider value={validation}>{children}</ValidationModelContext.Provider>
);

export const useValidationModel = (): ValidationDisplayModel => {
  const context = useContext(ValidationModelContext);
  if (!context) {
    throw new Error(
      `${nameof({ useValidationModel })} must be used within ${nameof({
        ValidationModelContextProvider,
      })}`,
    );
  }

  return context;
};

type ValidationAccordionItemProps = {
  validation: ValidationDisplayModel;
};

const ValidationAccordionItem = ({ validation }: ValidationAccordionItemProps): JSX.Element => (
  <ValidationModelContextProvider validation={validation}>
    <Accordion.Item key={validation.validationId} eventKey={validation.validationId}>
      <ValidationAccordionButton />
      <Accordion.Body className="p-0 w-100">
        <Table className="m-0 w-100 validationTable" bordered>
          <colgroup>
            <col className="w-25" />
            <col className="w-75" />
          </colgroup>
          <tbody>
            <tr>
              <th id="descriptionLabel">Description</th>
              <td aria-labelledby="descriptionLabel">
                <span className="text-break">{validation.description}</span>
              </td>
            </tr>
            <tr>
              <th id="validationLabel">Validation Rule</th>
              <td aria-labelledby="validationLabel">
                <ValidationRule />
              </td>
            </tr>
            <tr>
              <th id="commentLabel">Comments</th>
              <td aria-labelledby="commentLabel">
                <ValidationComment commentOnServer={validation.comment} />
              </td>
            </tr>
            <ValidationApprovalToggleRow />
          </tbody>
        </Table>
      </Accordion.Body>
    </Accordion.Item>
  </ValidationModelContextProvider>
);

export default ValidationAccordionItem;
