import { FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { PrimaryButton, SecondaryButton } from '@/Components/Buttons/DCSButton';
import { createContext } from '@/Helpers/Context';

const ModalContext = createContext<ModalControllerInternal>(undefined);

/**
 * Hook to access the 'global' modal functions.
 * This hook additionally ensures that modals are closed if the host component is unmounted (see DCS-162)
 */
export const useModal: () => ModalController = () => {
  const modalContext = useContext(ModalContext);
  useEffect(() => () => modalContext?.abort(), []);

  if (modalContext === undefined) {
    throw new Error('useModal used without context');
  }
  return modalContext;
};

export type ConfirmModalProps = {
  title?: string;
  closeOnClickAway?: boolean;
  swapConfirmAndCancelStyles?: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
};

interface ModalController {
  confirm: (body: ReactNode, props?: ConfirmModalProps) => Promise<boolean>;
  alert: (
    body: ReactNode,
    permanentDismissKey: string | null,
    title?: string,
    closeOnClickAway?: boolean,
  ) => Promise<void>;
}

interface ModalControllerInternal extends ModalController {
  /**
   * Internal only modal method for unmounting when the host component dismounts.
   */
  abort: () => void;
}

interface ModalConfig {
  body: ReactNode;
  title: string | ReactNode;
  onHide: () => void;
  closeOnClickAway?: boolean;
  footer: ReactNode;
}

const SuppressModalCheckbox: FC<{ storageKey: string }> = ({ storageKey }) => {
  const [toggleState, setToggleState] = useState(localStorage.getItem(storageKey) !== null);

  useEffect(() => {
    if (toggleState) {
      localStorage.setItem(storageKey, 'active');
    } else {
      localStorage.removeItem(storageKey);
    }
  }, [toggleState]);

  return (
    <Form.Group controlId="modal-suppress">
      <Form.Check inline onChange={(e) => setToggleState(!!e)} />
      <Form.Label>Don&#39;t show this again</Form.Label>
    </Form.Group>
  );
};

const DCSModalProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [visible, setVisible] = useState(false);
  const [modalData, setModalData] = useState<ModalConfig | undefined>(undefined);
  const showModal = (): void => setVisible(true);
  const hideModal = (): void => setVisible(false);

  const modalController: ModalControllerInternal = useMemo(
    () => ({
      abort: () => {
        setModalData(modalData);
        setVisible(false);
      },
      confirm: (body, props) => {
        const title = props?.title ?? 'Action Confirmation';
        const closeOnClickAway = props?.closeOnClickAway ?? true;
        const swapConfirmAndCancelStyles = props?.swapConfirmAndCancelStyles ?? false;
        const confirmButtonText = props?.confirmButtonText ?? 'Confirm';
        const cancelButtonText = props?.cancelButtonText ?? 'Cancel';

        return new Promise((resolve) => {
          const confirm = (): void => {
            resolve(true);
            hideModal();
          };
          const cancel = (): void => {
            resolve(false);
            hideModal();
          };

          const cancelButton = swapConfirmAndCancelStyles ? (
            <PrimaryButton onClick={cancel}>{cancelButtonText}</PrimaryButton>
          ) : (
            <SecondaryButton onClick={cancel}>{cancelButtonText}</SecondaryButton>
          );
          const confirmButton = swapConfirmAndCancelStyles ? (
            <SecondaryButton onClick={confirm}>{confirmButtonText}</SecondaryButton>
          ) : (
            <PrimaryButton onClick={confirm}>{confirmButtonText}</PrimaryButton>
          );

          const footer: ReactNode = (
            <>
              {cancelButton}
              {confirmButton}
            </>
          );

          const config: ModalConfig = {
            title,
            body,
            onHide: cancel,
            closeOnClickAway,
            footer,
          };
          showModal();
          setModalData(config);
        });
      },
      alert: (body, suppressionKey, title = 'Notification', closeOnClickAway = true) => {
        const modalSuppressionPrefix = 'modalDisabled-';
        const storageKey = modalSuppressionPrefix + suppressionKey;

        if (suppressionKey !== null && localStorage.getItem(storageKey)) {
          return Promise.resolve();
        }

        return new Promise((resolve) => {
          const dismiss = (): void => {
            resolve();
            hideModal();
          };
          const footer: ReactNode = (
            <>
              {suppressionKey && <SuppressModalCheckbox storageKey={storageKey} />}
              <div className="flex-grow-1" />
              <PrimaryButton onClick={dismiss} colour="primary">
                Confirm
              </PrimaryButton>
            </>
          );
          const config: ModalConfig = {
            title,
            body,
            onHide: dismiss,
            closeOnClickAway,
            footer,
          };
          showModal();
          setModalData(config);
        });
      },
    }),
    [modalData],
  );

  // TODO: DCS-145 Modal border overrides should probably be centralised
  return (
    <ModalContext.Provider value={modalController}>
      {modalData && (
        <Modal
          show={visible}
          contentClassName="rounded-0 border-0 p-4"
          backdrop={modalData.closeOnClickAway ? true : 'static'}
          centered
          onHide={() => {
            setVisible(false);
            modalData?.onHide();
          }}
        >
          {modalData.title && (
            <Modal.Header className="border-bottom-0 mb-4 p-0">
              <Modal.Title as="h2">{modalData.title}</Modal.Title>
            </Modal.Header>
          )}
          <Modal.Body className="p-0 mb-5">{modalData.body}</Modal.Body>
          <Modal.Footer className="border-top-0 p-0">{modalData.footer}</Modal.Footer>
        </Modal>
      )}
      {children}
    </ModalContext.Provider>
  );
};

export default DCSModalProvider;
