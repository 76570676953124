import Select, { createFilter } from 'react-select';
import '@/Components/SearchableDropdown/SearchableDropdown.scss';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';

export type SearchableDropdownOption<T extends number | string> = {
  value: T;
  label: string;
};

export type SearchableDropdownProps<T extends string | number> = BaseProps<T> &
  (MultiSelectProps<T> | SingleSelectProps<T>);

type BaseProps<T extends string | number> = {
  options: SearchableDropdownOption<T>[];
  placeholderText: string;
  ariaLabel?: string;
  isInvalid?: boolean;
  required?: boolean;
};

type MultiSelectProps<T extends string | number> = {
  allowMultiSelect: true;
  currentSelection: T[];
  onChange: (values: T[]) => void;
};

type SingleSelectProps<T extends string | number> = {
  allowMultiSelect?: false;
  currentSelection: T | undefined;
  onChange: (value: T | undefined) => void;
};

const getBaseProps = <T extends string | number>(
  options: SearchableDropdownOption<T>[],
  required: boolean,
  placeholderText: string,
  ariaLabel: string,
  isInvalid: boolean,
): {
  'options': SearchableDropdownOption<T>[];
  'required': boolean;
  'isSearchable': true;
  'isClearable': true;
  'placeholder': string;
  'aria-label': string;
  'aria-invalid': boolean;
  'className': string | undefined;
  'filterOption': (option: FilterOptionOption<SearchableDropdownOption<T>>, rawInput: string) => boolean;
} => ({
  options,
  required,
  'isSearchable': true,
  'isClearable': true,
  'placeholder': placeholderText,
  'aria-label': ariaLabel,
  'aria-invalid': isInvalid,
  'className': isInvalid ? 'invalidReactSelect' : undefined,
  'filterOption': createFilter<SearchableDropdownOption<T>>({
    matchFrom: 'any',
    stringify: (option) => option.label,
  }),
});

const SingleSelectDropdown = <T extends string | number>({
  options,
  currentSelection,
  placeholderText,
  ariaLabel = placeholderText,
  onChange,
  isInvalid = false,
  required = false,
}: BaseProps<T> & SingleSelectProps<T>): JSX.Element => {
  const currentOption = options.filter((o) => o.value === currentSelection).at(0) ?? null;
  return (
    <Select
      value={currentOption}
      closeMenuOnSelect
      onChange={(newValue) => onChange(newValue?.value ?? undefined)}
      {...getBaseProps(options, required, placeholderText, ariaLabel, isInvalid)}
    />
  );
};

const MultiSelectDropdown = <T extends string | number>({
  options,
  currentSelection,
  placeholderText,
  ariaLabel = placeholderText,
  onChange,
  isInvalid = false,
  required = false,
}: BaseProps<T> & MultiSelectProps<T>): JSX.Element => {
  const currentOption = options.filter((o) => currentSelection.some((v) => o.value === v));
  return (
    <Select
      isMulti
      value={currentOption}
      onChange={(newValue) => onChange(newValue.map((o) => o.value))}
      {...getBaseProps(options, required, placeholderText, ariaLabel, isInvalid)}
    />
  );
};

export const SearchableDropdown = <T extends string | number>(props: SearchableDropdownProps<T>): JSX.Element => {
  const { allowMultiSelect } = props;
  if (allowMultiSelect) return <MultiSelectDropdown {...props} />;
  return <SingleSelectDropdown {...props} />;
};
