import { Checkbox } from '@mui/material';
import { ChangeEvent } from 'react';
import { AccessibleFeedback } from '@/Components/Form/AccessibleFeedback';
import '@/Pages/Submissions/Confirmation/SubmissionConfirmationCheckbox.scss';

type SubmissionConfirmationCheckboxProps = {
  checked: boolean;
  id: string;
  label: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  validated?: boolean;
};

const SubmissionConfirmationCheckbox = ({
  checked,
  id,
  label,
  onChange,
  required = false,
  validated = false,
}: SubmissionConfirmationCheckboxProps): JSX.Element => (
  <>
    <div
      className="d-flex align-items-center"
      aria-invalid={validated && required && !checked}
      aria-describedby={validated && required && !checked ? `${id}-feedback` : undefined}
    >
      <Checkbox
        color="nrosh"
        inputProps={{ id }}
        checked={checked}
        disableRipple
        onChange={onChange}
        required={required}
      />
      <label htmlFor={id} className={validated && required && !checked ? 'invalidFeedbackLabel' : undefined}>
        {label}
      </label>
    </div>
    {required && (
      <AccessibleFeedback displayFeedback={validated && !checked} id={`${id}-feedback`}>
        You must tick this box
      </AccessibleFeedback>
    )}
  </>
);

export default SubmissionConfirmationCheckbox;
