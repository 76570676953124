import { RshRole } from 'nrosh-common/Api/Enums';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import { useContext } from 'react';
import { Stack } from 'react-bootstrap';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { providerDisplayName } from '@/Helpers/ProviderHelper';
import { adminPages, providerPages } from '@/Pages/Home/SitePages';
import RegulatoryDocumentForm from '@/Pages/RegulatoryDocuments/RegulatoryDocumentForm';

const UploadRegulatoryDocumentPage = (): JSX.Element => {
  const auth = useContext(AuthContext);

  const isAdmin = auth.hasRole(RshRole.User)!;
  const currentPath = isAdmin ? adminPages.UploadRegulatoryDocument.path : providerPages.UploadRegulatoryDocument.path;
  const breadcrumbsRootPathType = isAdmin
    ? RootPathType.AdminRegulatoryDocuments
    : RootPathType.ProviderRegulatoryDocuments;

  if (!auth.user) {
    return <LoadingMessage />;
  }

  return (
    <Stack gap={5}>
      <PageHeader
        heading="Upload regulatory documents"
        subheading={`${!isAdmin ? `${providerDisplayName(auth.user.providerName!, auth.user.providerNumber!)}` : ''}`}
        crumbsType={breadcrumbsRootPathType}
        crumbs={[
          {
            name: 'Upload regulatory documents',
            path: currentPath,
          },
        ]}
      />
      <RegulatoryDocumentForm />
    </Stack>
  );
};

export default UploadRegulatoryDocumentPage;
