import { APIResult, FailureResponse } from 'nrosh-common/Api/ApiClient';
import { ProviderRole, Role, RshRole } from 'nrosh-common/Api/Enums';
import { Surveys } from 'nrosh-common/Api/SurveysApi';
import { UserProfile, UserProfileType } from 'nrosh-common/Api/UsersApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { FormEvent, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import { AccessibleFeedback } from '@/Components/Form/AccessibleFeedback';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import { useModal } from '@/Components/Modal/ModalProvider';
import MultiSelect from '@/Components/MultiSelect/MultiSelect';
import { SurveysApi, UsersApi, generateResponseErrorMessage } from '@/Helpers/Apis';
import { getValidityProps } from '@/Helpers/Forms';
import useUnsavedChangesWarning from '@/Hooks/useUnsavedChangesWarning';
import { adminPages } from '@/Pages/Home/SitePages';
import { ProviderRoleDescriptionMapping, RshRoleDescriptionMapping } from '@/Pages/Users/RoleDescriptionMapping';
import '@/Pages/Users/UserProfileForm.scss';

const listOfRshRoles = Object.values(RshRole)
  .map((r) => r as RshRole)
  .filter((r) => r !== RshRole.User);

const listOfProviderRoles = Object.values(ProviderRole)
  .map((r) => r as ProviderRole)
  .filter((r) => r !== ProviderRole.User);

const getDefaultRshRoles = (profile?: UserProfile): { [key in RshRole]?: boolean } => {
  if (!profile || profile.type === UserProfileType.ProviderProfile) {
    return {};
  }

  return Object.fromEntries(profile.roles.map((role) => [role, true]));
};

const getDefaultProviderRoles = (profile?: UserProfile): { [key in ProviderRole]?: boolean } => {
  if (!profile || profile.type === UserProfileType.DcsProfile) {
    return {};
  }

  return Object.fromEntries(profile.roles.map((role) => [role, true]));
};

const roleDependencies: { [key in Role]?: Role[] } = {
  [RshRole.ViewSurveys]: [
    RshRole.DesignSurveys,
    RshRole.AssignSurveys,
    RshRole.OpenCloseSurveys,
    RshRole.DeleteSurveys,
    RshRole.ViewSurveyData,
  ],
  [RshRole.ViewRegDocs]: [RshRole.EditRegDocs, RshRole.UploadRegDocs],
  [RshRole.ViewSurveyData]: [
    RshRole.EditSurveyData,
    RshRole.SubmitSurvey,
    RshRole.UnsubmitSurvey,
    RshRole.SignOffSurvey,
    RshRole.UnsignOffSurvey,
    RshRole.ApproveValidations,
  ],
  [RshRole.ViewProviders]: [RshRole.EditProviders],
  [RshRole.ViewSentEmails]: [RshRole.SendEmails],
  [RshRole.ViewUsers]: [RshRole.EditUsers],
  [RshRole.EditUsers]: [RshRole.ManageUserRoles],

  [ProviderRole.ViewRegDocs]: [ProviderRole.EditRegDocs, ProviderRole.UploadRegDocs],
  [ProviderRole.ViewSurveyData]: [ProviderRole.EditSurveyData, ProviderRole.SubmitSurvey],
  [ProviderRole.ViewProvider]: [ProviderRole.EditProvider],
  [ProviderRole.ViewUsers]: [ProviderRole.EditUsers],
};

const getRolesImpliedBy = <T extends RshRole | ProviderRole>(selectedRoles: { [key in T]?: boolean }, role: T): T[] => {
  const allSelectedRoles = Object.entries(selectedRoles)
    .filter(([, isSelected]) => isSelected)
    .map(([userRole]) => userRole);

  return (roleDependencies[role] ?? []).filter(
    (dependentRole) =>
      allSelectedRoles.includes(dependentRole) || getRolesImpliedBy(selectedRoles, dependentRole as T).length > 0,
  ) as T[];
};

const isRoleImplied = <T extends RshRole | ProviderRole>(selectedRoles: { [key in T]?: boolean }, role: T): boolean =>
  getRolesImpliedBy(selectedRoles, role).length > 0;

type UserProfileFormProps = {
  profile?: UserProfile;
};

const UserProfileForm = (props: UserProfileFormProps): JSX.Element => {
  const navigate = useNavigate();
  const { confirm } = useModal();
  const { profile } = props;

  const [surveys] = useEndpoint<Surveys>(SurveysApi.getSurveys);
  const [profileName, setProfileName] = useState(profile?.name ?? '');
  const [profileType, setProfileType] = useState(profile?.type ?? UserProfileType.ProviderProfile);
  const [selectedRshRoles, setSelectedRshRoles] = useState(getDefaultRshRoles(profile));
  const [selectedProviderRoles, setSelectedProviderRoles] = useState(getDefaultProviderRoles(profile));
  const [selectedSurveys, setSelectedSurveys] = useState<number[]>(profile?.surveys ?? []);
  const [setDirty, setPristine, isDirty] = useUnsavedChangesWarning();
  const [validated, setValidated] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setSubmitting] = useState(false);

  const handleError = async (response: FailureResponse<APIResult>): Promise<void> => {
    const unknownErrorMessage = 'An unexpected problem has occurred.';
    try {
      const body = response.value;
      const errorMessage = generateResponseErrorMessage(body, (err) => err.error, unknownErrorMessage);
      setError(errorMessage);
    } catch {
      setError(unknownErrorMessage);
    }
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    setError(null);
    event.preventDefault(); // Stops the page from reloading

    if (!event.currentTarget.checkValidity()) {
      setValidated(true);
      return;
    }

    setSubmitting(true);
    setPristine();

    const selectedRoles =
      profileType === UserProfileType.DcsProfile
        ? listOfRshRoles.filter((role) => selectedRshRoles[role] || isRoleImplied(selectedRshRoles, role))
        : listOfProviderRoles.filter(
            (role) => selectedProviderRoles[role] || isRoleImplied(selectedProviderRoles, role),
          );

    const request = {
      name: profileName,
      type: profileType as UserProfileType,
      roles: selectedRoles,
      surveys: selectedSurveys,
    };

    const response = await (profile ? UsersApi.updateProfile(profile.id, request) : UsersApi.createProfile(request))
      .raw;

    if (response.ok) {
      navigate(adminPages.AdminManageUserProfiles.path);
      return;
    }
    setDirty();
    await handleError(response);
    setSubmitting(false);
  };

  const deleteProfile = async (): Promise<void> => {
    if (
      !profile ||
      !(await confirm(
        <div>
          <p>Are you sure you want to delete this profile?</p>
          <p>
            <strong> This is an irreversible action.</strong>
          </p>
        </div>,
      ))
    ) {
      return;
    }

    setError(null);

    const response = await UsersApi.deleteProfile(profile.id).raw;
    if (response.ok) {
      navigate(adminPages.AdminManageUserProfiles.path);
      return;
    }
    await handleError(response);
  };

  if (!surveys) {
    return <LoadingMessage />;
  }

  return (
    <div className="userProfileFormContainer">
      <Form noValidate onSubmit={onSubmit}>
        {error && (
          <Alert variant="danger" className="w-75">
            {error}
          </Alert>
        )}
        <Form.Group className="mb-3 w-25" controlId="profileName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            value={profileName}
            onChange={(event) => {
              setDirty();
              setProfileName(event.target.value);
            }}
            required
            {...getValidityProps(validated && profileName === '', 'profile-name-feedback')}
          />
          <AccessibleFeedback displayFeedback={validated && profileName === ''} id="profile-name-feedback">
            Please provide a profile name
          </AccessibleFeedback>
        </Form.Group>
        <Form.Group className="mb-3 w-25" controlId="profileType">
          <Form.Label>Profile type</Form.Label>
          <Form.Select
            required
            as="select"
            value={profileType}
            disabled={!!profile}
            onChange={(event) => {
              setDirty();
              setProfileType(event.target.value as UserProfileType);
              event.target.setCustomValidity('');
            }}
          >
            <option value="" hidden>
              Select profile type
            </option>
            <option value={UserProfileType.DcsProfile}>DCS</option>
            <option value={UserProfileType.ProviderProfile}>Provider</option>
          </Form.Select>
        </Form.Group>
        <div>Select roles</div>
        {profileType === UserProfileType.DcsProfile && (
          <div className="mb-3 w-25">
            {listOfRshRoles.map((role) => (
              <div key={role} className="mt-3 d-flex flex-column">
                <div className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    id={`rshRoles-${role}`}
                    className="me-3"
                    disabled={isRoleImplied(selectedRshRoles, role)}
                    checked={selectedRshRoles[role] || isRoleImplied(selectedRshRoles, role)}
                    onChange={(e) => {
                      setDirty();
                      setSelectedRshRoles((prev) => ({ ...prev, [role]: e.target.checked }));
                    }}
                  />
                  <label htmlFor={`rshRoles-${role}`}>{RshRoleDescriptionMapping[role]}</label>
                </div>
                {isRoleImplied(selectedRshRoles, role) && (
                  <div className="mt-2 autoSelected">
                    Auto selected as these roles have been selected:
                    <ul className="mb-0">
                      {getRolesImpliedBy(selectedRshRoles, role).map((userRole) => (
                        <li key={userRole}>{RshRoleDescriptionMapping[role]}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
        {profileType === UserProfileType.ProviderProfile && (
          <div className="mb-3">
            {listOfProviderRoles.map((role) => (
              <div key={role} className="mt-3 d-flex flex-column">
                <div className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    id={`providerRoles-${role}`}
                    className="me-3"
                    disabled={isRoleImplied(selectedProviderRoles, role)}
                    checked={selectedProviderRoles[role] || isRoleImplied(selectedProviderRoles, role)}
                    onChange={(e) => {
                      setDirty();
                      setSelectedProviderRoles((prev) => ({ ...prev, [role]: e.target.checked }));
                    }}
                  />
                  <label htmlFor={`providerRoles-${role}`}>{ProviderRoleDescriptionMapping[role]}</label>
                </div>
                {isRoleImplied(selectedProviderRoles, role) && (
                  <div className="mt-2 autoSelected">
                    Auto selected as these roles have been selected:
                    <ul className="mb-0">
                      {getRolesImpliedBy(selectedProviderRoles, role).map((userRole) => (
                        <li key={userRole}>{ProviderRoleDescriptionMapping[role]}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
        <div className="selectSurveysText">Select Surveys</div>
        <p>
          Select surveys this profile will be restricted to. If no surveys are selected then this profile will not be
          restricted to specific surveys
        </p>
        <MultiSelect
          name="Survey"
          options={surveys.surveys.map((s) => ({
            value: s.surveyId.toString(),
            text: s.surveyName,
          }))}
          selectedValues={selectedSurveys.map((id) => id.toString())}
          updateSelectedValues={(options) => {
            setDirty();
            setSelectedSurveys(options.map(Number));
          }}
        />
        <div className="mt-4 d-flex gap-3 align-items-center">
          <Link to={adminPages.AdminManageUserProfiles.path}>Cancel</Link>
          {!isSubmitting ? (
            <PrimaryButton type="submit" disabled={!isDirty}>
              {profile ? 'Save' : 'Create'}
            </PrimaryButton>
          ) : (
            <LoadingButton className="me-0" message={profile ? 'Save' : 'Create'} />
          )}
          {profile && (
            <PrimaryButton
              colour="outline-primary"
              type="button"
              onClick={deleteProfile}
              disabled={isSubmitting || profile.assignedUsers !== 0}
            >
              Delete
            </PrimaryButton>
          )}
        </div>
      </Form>
    </div>
  );
};

export default UserProfileForm;
