import { HTMLContent } from '@tiptap/react';
import { format } from 'date-fns';
import { Tag } from 'nrosh-common/Api/CommonTypes';
import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { ChangeEvent, SyntheticEvent, useState } from 'react';
import { Alert, Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import ContentEditor from '@/Components/ContentEditor/ContentEditor';
import { AccessibleFeedback } from '@/Components/Form/AccessibleFeedback';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import { useModal } from '@/Components/Modal/ModalProvider';
import TagCheckboxes from '@/Components/Tags/TagCheckboxes';
import { TagsApi } from '@/Helpers/Apis';
import { getValidityProps } from '@/Helpers/Forms';
import { checkHtmlNonEmpty } from '@/Helpers/HtmlValidityHelper';
import useUnsavedChangesWarning from '@/Hooks/useUnsavedChangesWarning';
import '@/Pages/Content/ContentForm.scss';
import ContentDateTimePicker from '@/Pages/Content/ContentDateTimePicker';

type Props = {
  publishDateTime?: Date | null;
  title: string;
  setTitle: ReactStateSetter<string>;
  content: HTMLContent;
  setContent: ReactStateSetter<HTMLContent>;
  sendApiRequest: (
    request: {
      title: string;
      content: string;
      publishDate?: Date | null;
      tagIds: number[];
    },
    unpublish?: boolean,
  ) => Promise<{ success: boolean; redirectPath?: string; errorMessage?: string }>;
  publishOrCancelPath: string;
  titleLabel: string;
  contentLabel: string;
  titlePlaceholderText: string;
  contentPlaceHolderText: string;
  contentType: string;
  hasPriority?: boolean;
  priorityNumber?: number | null;
  defaultSelectedTagIds: number[];
};

const ContentForm: (props: Props) => JSX.Element = ({
  publishDateTime = null,
  content,
  setContent,
  title,
  setTitle,
  sendApiRequest,
  publishOrCancelPath,
  titleLabel,
  contentLabel,
  titlePlaceholderText,
  contentPlaceHolderText,
  contentType,
  hasPriority,
  priorityNumber = null,
  defaultSelectedTagIds,
}) => {
  const [allTags] = useEndpoint<Tag[]>(TagsApi.getContentTags);

  const navigate = useNavigate();
  const { confirm } = useModal();
  const [setDirty, setPristine, isDirty] = useUnsavedChangesWarning();

  const [isSaving, setIsSaving] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [dateTime, setDateTime] = useState<Date | null>(publishDateTime);
  const [publishAtSpecificDateTime, setPublishAtSpecificDateTime] = useState(Boolean(publishDateTime));
  const [isPublished] = useState(dateTime ? dateTime.getTime() <= Date.now() && !isDirty : false);
  const [validated, setValidated] = useState(false);
  const [priority, setPriority] = useState(priorityNumber);
  const [selectedTagIds, setSelectedTagIds] = useState<number[]>(defaultSelectedTagIds);

  const [priorityInvalid, setPriorityInvalid] = useState(false);

  const isInvalidArticle = !content || !checkHtmlNonEmpty(content) || !title;

  if (!allTags) {
    return <LoadingMessage />;
  }

  const confirmUnpublishing = async (): Promise<boolean> =>
    confirm(`This ${contentType} has been published are you sure you want to unpublish?`);

  const saveDraft = async (): Promise<void> => {
    const unpublish = isPublished;

    if (unpublish) {
      if (!(await confirmUnpublishing())) {
        return;
      }
    }

    const draftRequest = {
      title,
      content,
      priority,
      tagIds: selectedTagIds,
    };
    setIsSaving(true);
    const { success, redirectPath, errorMessage } = await sendApiRequest(draftRequest, unpublish);
    if (success) {
      setPristine();
      setError(null);
    } else {
      setError(errorMessage || null);
    }
    if (redirectPath) {
      navigate(redirectPath);
    }
    setIsSaving(false);
    setValidated(false);
  };

  const publish = async (): Promise<void> => {
    const publishRequest = {
      title,
      content,
      publishDate: dateTime || new Date(),
      priority,
      tagIds: selectedTagIds,
    };
    // The component needs to rerender before updating the new state.
    // setPristine is being called before the api to ensure the state has been updated before navigate is called.
    setPristine();
    setIsPublishing(true);
    await sendApiRequest(publishRequest);
    setIsPublishing(false);
    return navigate(publishOrCancelPath);
  };

  const onSubmit = async (event: SyntheticEvent<HTMLFormElement, SubmitEvent>): Promise<void> => {
    const form = event.currentTarget;
    const isPublish = (event.nativeEvent.submitter as HTMLInputElement).name === 'publish';

    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      event.preventDefault();
      if (isPublish) {
        await publish();
      } else {
        await saveDraft();
      }
    }
  };

  return (
    <>
      {error && (
        <Alert variant="danger" dismissible onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
      <Form noValidate onSubmit={onSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} md="4" controlId="articleName">
            <Form.Label className="headingLabel">{titleLabel}</Form.Label>
            <Form.Control
              type="text"
              name="text"
              placeholder={titlePlaceholderText}
              onChange={(e) => {
                setTitle(e.target.value);
                setDirty();
              }}
              value={title}
            />
          </Form.Group>
          {hasPriority && (
            <Col className="mb-3 b headingLabel" sm="auto">
              <Form.Group controlId="priority">
                <Form.Label>Priority</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter priority number"
                  value={priority || priority === 0 ? priority : ''}
                  onInput={(e: ChangeEvent<HTMLInputElement>) => {
                    setPriorityInvalid(false);
                    setValidated(false);
                    setPriority(e.target.valueAsNumber);
                    setDirty();
                  }}
                  onInvalid={() => setPriorityInvalid(true)}
                  {...getValidityProps(validated && priorityInvalid, 'priorityFeedback')}
                />
                <AccessibleFeedback displayFeedback={validated && priorityInvalid} id="priorityFeedback">
                  Please provide a number
                </AccessibleFeedback>
              </Form.Group>
            </Col>
          )}
        </Row>

        <Form.Group className="mb-3">
          <Form.Label className="mb-2 b headingLabel">{contentLabel}</Form.Label>
          <ContentEditor
            content={content}
            setContent={setContent}
            setDirty={setDirty}
            placeholderText={contentPlaceHolderText}
            ariaLabel="Content"
          />
        </Form.Group>
        <div className="mb-3 d-flex flex-column gap-2">
          <span>Tags</span>
          <TagCheckboxes
            allTags={allTags}
            selectedTagIds={selectedTagIds}
            setSelectedTagIds={setSelectedTagIds}
            onChange={setDirty}
          />
        </div>
        {!isPublished ? (
          <ContentDateTimePicker
            publishAtSpecificDateTime={publishAtSpecificDateTime}
            setPublishAtSpecificDateTime={setPublishAtSpecificDateTime}
            setDateTime={setDateTime}
            dateTime={dateTime}
            setDirty={setDirty}
          />
        ) : (
          <div className="pb-4 fst-italic">
            Published on {format(dateTime!, 'dd/MM/yy')} at {format(dateTime!, 'HH:mm')}
          </div>
        )}
        <Link className="cancelLink" to={publishOrCancelPath}>
          Cancel
        </Link>
        {!isSaving ? (
          <PrimaryButton
            className="me-3"
            colour="outline-primary"
            type="submit"
            name="draft"
            disabled={!isPublished && (isInvalidArticle || !isDirty || publishAtSpecificDateTime || isPublishing)}
          >
            {isPublished ? 'Unpublish' : 'Save as Draft'}
          </PrimaryButton>
        ) : (
          <LoadingButton message="Saving..." colour="outline-primary" />
        )}
        {!isPublishing ? (
          <PrimaryButton
            type="submit"
            name="publish"
            disabled={isInvalidArticle || (isPublished && !isDirty) || isSaving}
          >
            {isPublished ? 'Update' : 'Publish'}
          </PrimaryButton>
        ) : (
          <LoadingButton message="Saving..." />
        )}
      </Form>
    </>
  );
};

export default ContentForm;
