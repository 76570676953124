import classNames from 'classnames';
import '@/Components/Form/AccessibleFieldSet.scss';
import { ReactNode } from 'react';

type AccessibleFieldSetProps = {
  legend?: ReactNode;
  legendClassName?: string;
  children: JSX.Element;
};

export const AccessibleFieldSet = ({
  legend,
  children,
  legendClassName = 'mb-0',
}: AccessibleFieldSetProps): JSX.Element =>
  legend ? (
    <fieldset>
      <legend className={classNames(legendClassName, 'legendFont')}>{legend}</legend>
      {children}
    </fieldset>
  ) : (
    children
  );
