import ApiClient, { APIHelpers, handleAPIRequest } from './ApiClient';
import { Tag } from './CommonTypes';
import { CellStyle, SubmissionStatus, ValidationStatus, ValidationType } from './Enums';
import { SurveyChecks } from './SurveysApi';
import { DataDictionaryResponse } from './SurveyPartsApi';

export type UpdateDefaultDeadlineResponse = {
  defaultDeadline: string;
};

export type SubmissionTab = {
  id: number;
  title: string;
};

export type SubmissionDetailTableRow = {
  id: number;
  name: string;
  hardValidationIssues: number;
  softValidationIssues: number;
  isTemplateUploaded: boolean;
};

export enum SurveyStatus {
  Open = 'Open',
  Closed = 'Closed',
  InDevelopment = 'InDevelopment',
}

export type SubmissionSupportingDocument = {
  id: number;
  fileName: string;
  description: string;
  uploadDate: string;
  tags: Tag[];
};
// We need this type because JSON dates are just strings, and so this is the response given by the API.
export type SubmissionDetailWithStringDate = Omit<SubmissionDetail, 'deadline'> & {
  deadline: string;
};

export type SubmissionDetailProvider = {
  id: number;
  name: string;
  providerNumber: string;
};

export type SubmissionDetail = {
  surveyName: string;
  provider: SubmissionDetailProvider;
  status: SubmissionStatus;
  lastSubmissionDate: string | null;
  signOffDate: string | null;
  surveyStatus: SurveyStatus;
  timePeriodName: string;
  deadline: Date;
  parts: Array<SubmissionDetailTableRow>;
  supportingDocuments: Array<SubmissionSupportingDocument>;
  description?: string;
};

export const mapSubmissionDetailResponse = (response: SubmissionDetailWithStringDate): SubmissionDetail => {
  return {
    ...response,
    deadline: new Date(response.deadline),
  };
};

export type Submission = {
  id: number;
  surveyId: number;
  surveyName: string;
  providerName: string;
  providerNumber: string;
  timePeriodId: number;
  timePeriodName: string;
  surveyYear: number;
  status: SubmissionStatus;
  surveyStatus: SurveyStatus;
  hardValidationIssues: number;
  softValidationIssues: number;
  deadline: string;
};

export const SubmissionStatusDict: { [key in SubmissionStatus]: string } = {
  [SubmissionStatus.NotStarted]: 'Not Started',
  [SubmissionStatus.InProgress]: 'In Progress',
  [SubmissionStatus.Submitted]: 'Submitted',
  [SubmissionStatus.Unsubmitted]: 'Unsubmitted',
  [SubmissionStatus.SignedOff]: 'Signed Off',
};

export type BulkImportWarnings = {
  unrecognisedDataPointIds?: string[];
  ignoredCalculatedDataPointIds?: string[];
  duplicatedDataPoints?: DuplicatedDataPointDetails[];
  invalidDimensionalMembers?: InvalidDimensionalMemberDetails[];
  updatesWithDimensionMissing?: InvalidDimensionalMemberDetails[];
  nonDimensionalDataPointsWithDimensions?: string[];
  dataPointsWithExtraDimensionsSpecified?: InvalidDataPointDetails[];
};

export type BulkImportResponse = {
  success: boolean;
  error: string | null;
  bulkImportWarnings: BulkImportWarnings | null;
};

export type DuplicatedDataPointDetails = {
  dataPointId: string;
  dimension1?: string;
  dimension2?: string;
  usedValue: string;
  numberOfValues: number;
};

export type InvalidDimensionalMemberDetails = {
  dataPointIds: string[];
  dimensionId: string;
  value: string;
  numberOfValues: number;
};

export type InvalidDataPointDetails = {
  dataPointId: string;
  numberOfValues: number;
};

export type DimensionalData = {
  dimensionalMembers: DimensionalMember[];
  dataPointsDimensions: DataPointDimensions[];
  dimensionalRegions: DimensionalRegion[];
};

export type DataPointDimensions = {
  dataPointId: string;
  dimension1Id?: string;
  dimension2Id?: string;
};

export type DimensionalMember = {
  id: number;
  dimensionId: string;
  value: string;
  isArchived: boolean;
};

export type SubmissionPartValidationComment = {
  submissionPartId: number;
  validationId: string;
  comment: string;
  dateTime: string;
  userName: string;
};

export type DimensionalRegion = {
  id: number;
  surveyTabId: number;
  isSlicer: boolean;
  dimensionId: string;
  dataPoints: string[];
};

export type FormulaPart = {
  displayValue: string;
  dataPointId: string | null;
  helpText: string | null;
};

export type SurveyPartValidation = {
  category: string;
  shortText: string;
  description: string;
  formula: FormulaPart[];
  helpText: string;
  type: ValidationType;
  validationId: string;
  submissionPartId: string;
};

export type ValidationIssues = {
  validationId: string;
  isValidationApproved: boolean;
};

export type ValidationUpdate = {
  validationRef: string;
  currentStatus: boolean;
  formulaDisplayParts: FormulaPart[];
  errorDimensions: number[][];
};

export type CrossPartValidationIssue = {
  validationId: string;
  formula: FormulaPart[];
  description: string;
  type: ValidationType;
  shortText: string;
  isPassing: boolean;
  isApproved: boolean;
  comment: string | null;
  commentUserName: string | null;
  commentDateTime: string | null;
};

export type SubmissionPartValidation = SurveyPartValidation & {
  isPassing: boolean;
  comment?: string;
  userName?: string;
  dateTime?: string;
  isApproved: boolean;
  errorDimensions: number[][];
};

export type SubmissionValidationIssues = {
  submissionValidations: SubmissionPartValidation[];
  validationStatuses: ValidationUpdate[];
  validationComments: SubmissionPartValidationComment[];
  validationIssues: ValidationIssues[];
  crossPartValidations: CrossPartValidationIssue[];
};

export type CellStyleBaseResponse = {
  row: number;
  column: number;
  rowSpan: number;
  columnSpan: number;
  style: CellStyle;
  dimensionalRegionId: number | null;
};

type DataCellStyleResponse = CellStyleBaseResponse & {
  dataPointId: string;
  content: null;
  helpText: string | null;
  questionReference: string;
  dimensionId: null;
  listTypeName: string | null;
  allowNull: boolean;
};

type ContentCellStyleResponse = CellStyleBaseResponse & {
  content: string;
  dataPointId: null;
  helpText: null;
  questionReference: null;
  dimensionId: string | null;
  listTypeName: null;
  allowNull: null;
};

export type CellStyleResponse = DataCellStyleResponse | ContentCellStyleResponse;

export type ListType = {
  name: string;
  options: string[];
};

export type LayoutsResponse = {
  tabLayouts: TabLayoutResponse[];
  listTypes: ListType[];
};

export type FreezePaneSpanResponse = {
  rows: number;
  columns: number;
};

export type TabLayoutResponse = {
  tabId: number;
  title: string;
  cellStyles: CellStyleResponse[];
  rowHeights: number[];
  columnWidths: number[];
  numberOfRows: number;
  numberOfColumns: number;
  freezePaneSpan: FreezePaneSpanResponse;
};

export type SubmissionContact = {
  id: number;
  name: string;
  email: string;
  phone: string;
};

export type GetSubmissionContactResponse = {
  submissionContact: SubmissionContact | null;
};

export type SaveSubmissionContactRequest = {
  id: number | null;
  name: string;
  email: string;
  phone: string;
};

export type CrossPartValidationIssuesResponse = {
  surveyStatus: SurveyStatus;
  validations: CrossPartValidationIssue[];
};

export type EditCrossPartValidationCommentResponse = {
  comment: string;
  userName: string;
  dateTime: string;
};

export type SubmissionSupportingDocumentMetadata = {
  fileName: string;
  description: string | null;
  fileSizeBytes: number;
  tags: Tag[];
};

// These values should match those in UnsubmitRequest.cs
export enum UnsubmitPrimaryReason {
  SignificantDataQualityIssues = 'SignificantDataQualityIssues',
  MinorDataQualityIssues = 'MinorDataQualityIssues',
  ProviderRequest = 'ProviderRequest',
  Other = 'Other',
}

type UnsubmitRequest = {
  primaryReason: UnsubmitPrimaryReason;
  furtherExplanation: string | null;
};

export type SubmitResponse = {
  successful: boolean;
  error: string | null;
};

export type UploadImportTemplateResponse = {
  success: boolean;
  error: string | null;
};

export type SubmissionPartValidationFilters = {
  severityType?: ValidationType;
  validationStatus?: ValidationStatus;
  tabId?: number;
};

const SubmissionsApiRoot = 'Submissions';
export const SubmissionsApiPaths = {
  SUBMISSIONS: SubmissionsApiRoot,
  SUBMISSION: (submissionId: string) => `${SubmissionsApiRoot}/${submissionId}`,

  UPLOAD_SUBMISSION_BULK_IMPORT_FILE: (submissionId: string) => `${SubmissionsApiRoot}/${submissionId}/BulkImport`,
  START: (submissionId: string) => `${SubmissionsApiRoot}/${submissionId}/Start`,
  SUBMIT: (submissionId: string) => `${SubmissionsApiRoot}/${submissionId}/Submit`,
  UNSUBMIT: (submissionId: string) => `${SubmissionsApiRoot}/${submissionId}/Unsubmit`,
  SIGN_OFF: (submissionId: string) => `${SubmissionsApiRoot}/${submissionId}/SignOff`,
  UN_SIGN_OFF: (submissionId: string) => `${SubmissionsApiRoot}/${submissionId}/UnSignOff`,
  END_OF_SURVEY_CHECKS: (submissionId: string) => `${SubmissionsApiRoot}/${submissionId}/EndOfSurveyChecks`,
  CONTACT: (submissionId: string) => `${SubmissionsApiRoot}/${submissionId}/Contact`,
  UPDATE_DEADLINE_OVERRIDE: (submissionId: string) => `${SubmissionsApiRoot}/${submissionId}/UpdateDeadlineOverride`,
  RESET_DEADLINE_OVERRIDE: (submissionId: string) => `${SubmissionsApiRoot}/${submissionId}/ResetDeadlineOverride`,

  VALIDATE_AND_FETCH_CROSS_PART_VALIDATION_ISSUES: (submissionId: string) =>
    `${SubmissionsApiRoot}/${submissionId}/CrossPartValidations/Validate`,
  CROSS_PART_VALIDATION_COMMENT: (submissionId: string, validationId: string) =>
    `${SubmissionsApiRoot}/${submissionId}/CrossPartValidations/${validationId}/Comment`,
  APPROVE_CROSS_PART_VALIDATION: (submissionId: string, validationId: string) =>
    `${SubmissionsApiRoot}/${submissionId}/CrossPartValidations/${validationId}/Approve`,
  UNAPPROVE_CROSS_PART_VALIDATION: (submissionId: string, validationId: string) =>
    `${SubmissionsApiRoot}/${submissionId}/CrossPartValidations/${validationId}/Unapprove`,

  UPLOAD_SUPPORTING_DOCUMENT: (submissionId: string) => `${SubmissionsApiRoot}/${submissionId}/SupportingDocuments`,
  SUPPORTING_DOCUMENT: (submissionId: string, documentId: string) =>
    `${SubmissionsApiRoot}/${submissionId}/SupportingDocuments/${documentId}`,
  DOWNLOAD_SUPPORTING_DOCUMENT: (submissionId: string, documentId: string) =>
    `${SubmissionsApiRoot}/${submissionId}/SupportingDocuments/${documentId}/Download`,
  DOWNLOAD_ALL_SUPPORTING_DOCUMENTS: (submissionId: string) =>
    `${SubmissionsApiRoot}/${submissionId}/SupportingDocuments/DownloadAll`,

  LAYOUT: (partId: string) => `SubmissionParts/${partId}/Layout`,
  DIMENSIONAL_DATA: (partId: string) => `SubmissionParts/${partId}/DimensionalData`,
  DATA_DICTIONARY: (partId: string) => `SubmissionParts/${partId}/DataDictionary`,
  UNDERLYING_SURVEY_PART_VALIDATIONS: (partId: string) => `SubmissionParts/${partId}/Validations`,
  SUBMISSION_PART_VALIDATION_ISSUES: (partId: string) => `SubmissionParts/${partId}/Validations/Issues`,
  DOWNLOAD_SUBMISSION_PART_VALIDATION_ISSUES: (partId: string) =>
    `SubmissionParts/${partId}/Validations/Issues/Download`,
  VALIDATE_SUBMISSION_AND_GET_VALIDATION_ISSUES: (submissionId: string) =>
    `${SubmissionsApiRoot}/${submissionId}/Validations/Validate`,
  DOWNLOAD_SUBMISSION_VALIDATION_ISSUES: (submissionId: string) =>
    `${SubmissionsApiRoot}/${submissionId}/Validations/Issues/Download`,
  SUBMISSION_PART_IMPORT_FILE: (submissionPartId: string) => `SubmissionParts/${submissionPartId}/Template`,
};

const submissionsApi = (apiClient: ApiClient) => ({
  getSubmissions: () => {
    return handleAPIRequest(
      apiClient.get(SubmissionsApiPaths.SUBMISSIONS),
      APIHelpers.json<Submission[]>,
      APIHelpers.standardError,
    );
  },

  getSubmission: (submissionId: string) => {
    return handleAPIRequest(
      apiClient.get(SubmissionsApiPaths.SUBMISSION(submissionId)),
      APIHelpers.json<SubmissionDetailWithStringDate>,
      APIHelpers.standardError,
    );
  },

  uploadSubmissionPartImportFile: (
    formData: FormData,
    submissionPartId: string,
    overwriteWithNulls: boolean = false,
  ) => {
    return handleAPIRequest(
      apiClient.patchFile(SubmissionsApiPaths.SUBMISSION_PART_IMPORT_FILE(submissionPartId), formData, {
        overwriteWithNulls: overwriteWithNulls,
      }),
      APIHelpers.none,
      APIHelpers.standardError<UploadImportTemplateResponse>,
    );
  },

  uploadSubmissionBulkImportFile: (formData: FormData, submissionId: string) => {
    return handleAPIRequest(
      apiClient.patchFile(SubmissionsApiPaths.UPLOAD_SUBMISSION_BULK_IMPORT_FILE(submissionId), formData),
      APIHelpers.json<BulkImportResponse>,
      APIHelpers.standardError<BulkImportResponse>,
    );
  },

  start: (submissionId: string) => {
    return handleAPIRequest(
      apiClient.patch(SubmissionsApiPaths.START(submissionId), null),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  submit: (submissionId: string) => {
    return handleAPIRequest(
      apiClient.patch(SubmissionsApiPaths.SUBMIT(submissionId), null),
      APIHelpers.json<SubmitResponse>,
      APIHelpers.standardError<SubmitResponse>,
    );
  },

  unsubmit: (submissionId: string, body: UnsubmitRequest, notify: boolean) => {
    return handleAPIRequest(
      apiClient.patch(SubmissionsApiPaths.UNSUBMIT(submissionId), body, {
        notify: notify,
      }),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  signOff: (submissionId: string, reason: string | null, notify: boolean) => {
    return handleAPIRequest(
      apiClient.patch(SubmissionsApiPaths.SIGN_OFF(submissionId), reason, { notify: notify }),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  unSignOff: (submissionId: string) => {
    return handleAPIRequest(
      apiClient.patch(SubmissionsApiPaths.UN_SIGN_OFF(submissionId), null),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  getEndOfSurveyChecks: (submissionId: string) => {
    return handleAPIRequest(
      apiClient.get(SubmissionsApiPaths.END_OF_SURVEY_CHECKS(submissionId)),
      APIHelpers.json<SurveyChecks>,
      APIHelpers.standardError,
    );
  },

  getContact: (submissionId: string) => {
    return handleAPIRequest(
      apiClient.get(SubmissionsApiPaths.CONTACT(submissionId)),
      APIHelpers.json<GetSubmissionContactResponse>,
      APIHelpers.standardError,
    );
  },

  saveContact: (submissionId: string, body: SaveSubmissionContactRequest) => {
    return handleAPIRequest(
      apiClient.put(SubmissionsApiPaths.CONTACT(submissionId), body),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  updateDeadlineOverride: (submissionId: string, deadline: Date, notify: boolean) => {
    return handleAPIRequest(
      apiClient.patch(SubmissionsApiPaths.UPDATE_DEADLINE_OVERRIDE(submissionId), deadline, { notify: notify }),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  resetDeadlineOverride: (submissionId: string, notify: boolean) => {
    return handleAPIRequest(
      apiClient.patch(SubmissionsApiPaths.RESET_DEADLINE_OVERRIDE(submissionId), null, { notify: notify }),
      APIHelpers.json<UpdateDefaultDeadlineResponse>,
      APIHelpers.standardError,
    );
  },

  validateAndFetchCrossPartValidationIssues: (submissionId: string) => {
    return handleAPIRequest(
      apiClient.get(SubmissionsApiPaths.VALIDATE_AND_FETCH_CROSS_PART_VALIDATION_ISSUES(submissionId)),
      APIHelpers.json<CrossPartValidationIssuesResponse>,
      APIHelpers.standardError,
    );
  },

  editCrossPartValidationComment: (submissionId: string, validationId: string, comment: string) => {
    return handleAPIRequest(
      apiClient.put(SubmissionsApiPaths.CROSS_PART_VALIDATION_COMMENT(submissionId, validationId), comment),
      APIHelpers.json<EditCrossPartValidationCommentResponse>,
      APIHelpers.standardError,
    );
  },

  deleteCrossPartValidationComment: (submissionId: string, validationId: string) => {
    return handleAPIRequest(
      apiClient.deleteAction(SubmissionsApiPaths.CROSS_PART_VALIDATION_COMMENT(submissionId, validationId)),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  approveCrossPartValidation: (submissionId: string, validationId: string) => {
    return handleAPIRequest(
      apiClient.patch(SubmissionsApiPaths.APPROVE_CROSS_PART_VALIDATION(submissionId, validationId), null),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  unapproveCrossPartValidation: (submissionId: string, validationId: string) => {
    return handleAPIRequest(
      apiClient.patch(SubmissionsApiPaths.UNAPPROVE_CROSS_PART_VALIDATION(submissionId, validationId), null),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  uploadSupportingDocument: (submissionId: string, formData: FormData) => {
    return handleAPIRequest(
      apiClient.postFile(SubmissionsApiPaths.UPLOAD_SUPPORTING_DOCUMENT(submissionId), formData),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  getSupportingDocumentMetadata: (submissionId: string, documentId: string) => {
    return handleAPIRequest(
      apiClient.get(SubmissionsApiPaths.SUPPORTING_DOCUMENT(submissionId, documentId)),
      APIHelpers.json<SubmissionSupportingDocumentMetadata>,
      APIHelpers.standardError,
    );
  },

  updateSupportingDocument: (submissionId: string, documentId: string, formData: FormData) => {
    return handleAPIRequest(
      apiClient.patchFile(SubmissionsApiPaths.SUPPORTING_DOCUMENT(submissionId, documentId), formData),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  deleteSupportingDocument: (submissionId: string, documentId: string) => {
    return handleAPIRequest(
      apiClient.deleteAction(SubmissionsApiPaths.SUPPORTING_DOCUMENT(submissionId, documentId)),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  downloadSupportingDocument: (submissionId: string, documentId: string) => {
    return apiClient.getFile(SubmissionsApiPaths.DOWNLOAD_SUPPORTING_DOCUMENT(submissionId, documentId));
  },

  downloadAllSupportingDocuments: (submissionId: string) => {
    return apiClient.getFileWithName(SubmissionsApiPaths.DOWNLOAD_ALL_SUPPORTING_DOCUMENTS(submissionId));
  },

  getLayout: (partId: string) => {
    return handleAPIRequest(
      apiClient.get(SubmissionsApiPaths.LAYOUT(partId)),
      APIHelpers.json<LayoutsResponse>,
      APIHelpers.standardError,
    );
  },

  getDimensionalData: (partId: string) => {
    return handleAPIRequest(
      apiClient.get(SubmissionsApiPaths.DIMENSIONAL_DATA(partId)),
      APIHelpers.json<DimensionalData>,
      APIHelpers.standardError,
    );
  },

  getDataDictionary: (partId: string) => {
    return handleAPIRequest(
      apiClient.get(SubmissionsApiPaths.DATA_DICTIONARY(partId)),
      APIHelpers.json<DataDictionaryResponse>,
      APIHelpers.standardError,
    );
  },

  getUnderlyingSurveyPartValidations: (partId: string) => {
    return handleAPIRequest(
      apiClient.get(SubmissionsApiPaths.UNDERLYING_SURVEY_PART_VALIDATIONS(partId)),
      APIHelpers.json<SubmissionPartValidation[]>,
      APIHelpers.standardError,
    );
  },

  getSubmissionPartValidationIssues: (partId: string) => {
    return handleAPIRequest(
      apiClient.get(SubmissionsApiPaths.SUBMISSION_PART_VALIDATION_ISSUES(partId)),
      APIHelpers.json<ValidationIssues[]>,
      APIHelpers.standardError,
    );
  },

  downloadSubmissionPartValidationIssues: (partId: string, filters?: SubmissionPartValidationFilters) => {
    return apiClient.getFile(SubmissionsApiPaths.DOWNLOAD_SUBMISSION_PART_VALIDATION_ISSUES(partId), filters);
  },

  validateSubmissionAndGetValidationIssues: (submissionId: string) => {
    return handleAPIRequest(
      apiClient.get(SubmissionsApiPaths.VALIDATE_SUBMISSION_AND_GET_VALIDATION_ISSUES(submissionId)),
      APIHelpers.json<SubmissionValidationIssues>,
      APIHelpers.standardError,
    );
  },

  downloadSubmissionValidationIssues: (submissionId: string, includePassingValidations: boolean = false) => {
    return apiClient.getFile(SubmissionsApiPaths.DOWNLOAD_SUBMISSION_VALIDATION_ISSUES(submissionId), {
      includePassingValidations,
    });
  },

  getSubmissionPartImportFile: (submissionPartId: string) => {
    return apiClient.getFileWithName(
      SubmissionsApiPaths.SUBMISSION_PART_IMPORT_FILE(submissionPartId),
      `Import_File_${submissionPartId}.xlsm`,
    );
  },
});

export default submissionsApi;
