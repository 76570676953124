import { useState } from 'react';
import { SelectionType, emptySelectionStringIndicator } from '@/Pages/Emails/EmailUtilities';

export type FilterEntry<T> = {
  value: T;
  displayName: string;
  isSelected: boolean;
};

type InitialFilter<T> = FilterEntry<T> & {
  isSelected: boolean | ((value: T) => boolean);
};

export const useFilterPattern = <T>(
  initialState: FilterEntry<T>[],
  setDirty?: () => void,
): [FilterEntry<T>[], (value: T, isSelected: boolean) => void] => {
  const [pattern, setPattern] = useState<FilterEntry<T>[]>(initialState);
  const setEntryState = (value: T, isSelected: boolean): void => {
    if (setDirty !== undefined) {
      setDirty();
    }

    setPattern((prevFilterState) => prevFilterState.map((t) => (t.value === value ? { ...t, isSelected } : t)));
  };
  return [pattern, setEntryState];
};

export const getSelectedValues = <T>(filterEntries: FilterEntry<T>[]): T[] =>
  filterEntries.filter((f) => f.isSelected).map((f) => f.value);

export const generateInitialFilterFromNameRecord = <T extends string | number | symbol>(
  nameRecord: Record<T, string>,
  isSelected: boolean | ((value: T) => boolean),
): InitialFilter<T>[] =>
  Object.entries(nameRecord).map(([v, n]) => ({
    value: v as T,
    displayName: n as string,
    isSelected: typeof isSelected === 'boolean' ? isSelected : isSelected(v as T),
  }));

export const getSelectionTypeFromFilterOptions = (filterOptions: string | null | undefined): SelectionType => {
  if (filterOptions === null || filterOptions === undefined) {
    return SelectionType.All;
  }

  if (filterOptions === emptySelectionStringIndicator) {
    return SelectionType.None;
  }

  return SelectionType.Specific;
};
