import { RshRole } from 'nrosh-common/Api/Enums';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import { useContext } from 'react';
import { Stack } from 'react-bootstrap';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import LandingPage from '@/Components/LandingPage/LandingPage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { adminPages } from '@/Pages/Home/SitePages';

const ProvidersLandingPage = (): JSX.Element => {
  const auth = useContext(AuthContext);
  const canEdit = auth.hasRole(RshRole.EditProviders);
  return (
    <Stack gap={5}>
      <PageHeader heading="Providers" crumbsType={RootPathType.AdminProviders} />
      <LandingPage
        description={`${canEdit ? 'Manage' : 'View'} providers and their groups`}
        pages={[
          {
            name: `${canEdit ? 'Manage' : 'View'} providers`,
            path: adminPages.AdminManageProviders.path,
            roles: [RshRole.ViewProviders],
          },
          {
            name: 'View provider groups',
            path: adminPages.AdminProviderGroups.path,
            roles: [RshRole.ViewProviders],
          },
        ]}
      />
    </Stack>
  );
};

export default ProvidersLandingPage;
