import { ReactNode } from 'react';
import { Link, To } from 'react-router-dom';
import { TertiaryButton } from '@/Components/Buttons/DCSButton';

export type TertiaryLinkButtonProps = {
  icon: ReactNode;
  text: string;
  to: To;
  disabled?: boolean;
};

export const TertiaryLinkButton = ({ to, icon, text, disabled }: TertiaryLinkButtonProps): JSX.Element => (
  <Link to={to} className="text-decoration-none">
    <TertiaryButton colour="primary" icon={icon} disabled={disabled}>
      {text}
    </TertiaryButton>
  </Link>
);
