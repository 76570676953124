import React, { ReactNode } from 'react';
import { Dropdown } from 'react-bootstrap';

type DropdownMenuProps = {
  children?: ReactNode;
};

const DropdownMenu = ({ children }: DropdownMenuProps): JSX.Element => (
  <Dropdown.Menu align="end" className="rounded shadow-sm bg-white py-0">
    {children}
  </Dropdown.Menu>
);

export default DropdownMenu;
