import ApiClient, { APIHelpers, handleAPIRequest } from './ApiClient';
import { Tag } from './CommonTypes';

export type PublicFaq = {
  id: string;
  title: string;
  content: string;
  priority: number;
  tags: Tag[];
};

const UnauthenticatedFaqsApiRoot = 'UnauthenticatedFaqs';
const UnauthenticatedFaqsApiPaths = {
  PUBLISHED_FAQS: `${UnauthenticatedFaqsApiRoot}/Faqs`,
};

const UnauthenticatedFaqsApi = (apiClient: ApiClient) => ({
  getPublishedFaqs: () => {
    return handleAPIRequest(
      apiClient.get(UnauthenticatedFaqsApiPaths.PUBLISHED_FAQS),
      APIHelpers.json<PublicFaq[]>,
      APIHelpers.standardError,
    );
  },
});

export default UnauthenticatedFaqsApi;
