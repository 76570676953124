import { APIResult, FailureResponse } from 'nrosh-common/Api/ApiClient';
import { User } from 'nrosh-common/Api/UsersApi';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { generateResponseErrorMessage } from '@/Helpers/Apis';
import { TimeResolution, formatISODateString } from '@/Helpers/DateHelpers';
import { UserStatusNameMapping } from '@/Pages/Users/UserStatusNameMapping';

type UserTableRow = {
  'Name': string;
  'Preferred Name': string | undefined;
  'Username': string;
  'User Profile': string | undefined;
  'Email Address': string;
  'User Status': string;
  'Last Successful Login': string;
};

export const tableRowToExportRow = (user: User): UserTableRow => ({
  'Name': user.name,
  'Preferred Name': user.preferredFormOfAddress,
  'Username': user.userName,
  'User Profile': user.profile?.name,
  'Email Address': user.emailAddress,
  'User Status': UserStatusNameMapping[user.status],
  'Last Successful Login': formatISODateString(user.lastSuccessfulLogin, TimeResolution.Second),
});

export const SubmitButton = ({ isSubmitting, text }: { isSubmitting: boolean; text: string }): JSX.Element =>
  !isSubmitting ? (
    <PrimaryButton type="submit">{text}</PrimaryButton>
  ) : (
    <LoadingButton message={text} className="me-0" />
  );

export const handleError = async (
  response: FailureResponse<APIResult>,
  setError: (error: string) => void,
): Promise<void> => {
  const unknownErrorMessage = 'An unexpected problem has occurred.';
  try {
    const body = response.value;
    const errorMessage = generateResponseErrorMessage(body, (err) => err.error, unknownErrorMessage);
    setError(errorMessage);
  } catch {
    setError(unknownErrorMessage);
  }
};
