import { RshRole } from 'nrosh-common/Api/Enums';
import { Provider, ProviderWithStringDate, mapProviderResponse } from 'nrosh-common/Api/ProvidersApi';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import { useEndpointConditionallyWithProcessing } from 'nrosh-common/Hooks/useEndpoint';
import { useContext } from 'react';
import { Stack } from 'react-bootstrap';
import { generatePath, useParams } from 'react-router-dom';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { ProvidersApi } from '@/Helpers/Apis';
import { providerDisplayName } from '@/Helpers/ProviderHelper';
import { adminPages } from '@/Pages/Home/SitePages';
import ProviderForm from '@/Pages/Providers/ProviderForm';
import '@/Pages/Providers/RegisteredDetailsPage.scss';
import ViewRegisteredDetails from '@/Pages/Providers/ViewRegisteredDetails';

const RegisteredDetailsPage = (): JSX.Element => {
  const { providerId } = useParams();
  const auth = useContext(AuthContext);
  const [provider] = useEndpointConditionallyWithProcessing<ProviderWithStringDate, Provider>(
    ProvidersApi.getProvider,
    true,
    mapProviderResponse,
    providerId,
  );

  if (!provider) {
    return <LoadingMessage />;
  }

  const providerIdNumber = Number(providerId);
  const canEditFullDetails = !provider.isSyncedWithDynamics && auth.hasRole(RshRole.EditProviders);

  return (
    <Stack gap={5} className="registeredDetailsContainer">
      <PageHeader
        heading="Registered details"
        subheading={`${providerDisplayName(provider.name, provider.providerNumber)}`}
        crumbsType={RootPathType.AdminProviders}
        crumbs={[
          {
            name: 'Organisational information',
            path: generatePath(adminPages.AdminViewProvider.path, { providerId }),
          },
          {
            name: 'Registered details',
            path: adminPages.AdminRegisteredDetails.path,
          },
        ]}
      />
      {canEditFullDetails ? (
        <ProviderForm
          initialProviderDetails={provider}
          onSave={(providerDetails) => ProvidersApi.editProvider(providerIdNumber, providerDetails)}
        />
      ) : (
        <ViewRegisteredDetails provider={provider} />
      )}
    </Stack>
  );
};

export default RegisteredDetailsPage;
