import { useValidationModel } from '@/Components/Validations/ValidationAccordionItem';

const ValidationCommentDetail = (): JSX.Element => {
  const validation = useValidationModel();

  return (
    <>
      <div className="commentDetails">
        {validation.comment && (
          <>
            {validation.commentUserName ?? 'Unknown User'} {' on '}
            {validation.commentDateTime &&
              new Date(validation.commentDateTime).toLocaleDateString('en-gb', {
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}
          </>
        )}
      </div>
      <div className="comment">{validation.comment}</div>
    </>
  );
};

export default ValidationCommentDetail;
