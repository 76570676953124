import { ProviderRole, RshRole } from 'nrosh-common/Api/Enums';
import { SurveyStatus } from 'nrosh-common/Api/SubmissionsApi';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import { nameof } from 'nrosh-common/Helpers/StringHelpers';
import { ReactNode, useContext, useMemo } from 'react';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import { createContext } from '@/Helpers/Context';
import useSubmissionDetail from '@/Hooks/useSubmissionDetail';
import { submissionIsReadonly } from '@/Pages/Submissions/SubmissionHelpers';

export type SubmissionPartStaticData = {
  submissionPartId: string;
  submissionId: string;
  surveyName: string;
  timePeriodName: string;
  partName: string;
  readonly: boolean;
  isAdmin: boolean;
  surveyStatus: SurveyStatus;
};

const SubmissionPartStaticDataContext = createContext<SubmissionPartStaticData>();

export type SubmissionPartStaticDataContextProviderProps = {
  submissionPartId: string;
  submissionId: string;
  isAdmin: boolean;
  children: ReactNode;
};

export const SubmissionPartStaticDataProvider = ({
  submissionPartId,
  submissionId,
  isAdmin,
  children,
}: SubmissionPartStaticDataContextProviderProps): JSX.Element => {
  const [submission] = useSubmissionDetail(submissionId);
  const auth = useContext(AuthContext);

  // Memo the value to avoid causing unnecessary re-renders of consuming components
  const value = useMemo(
    () =>
      submission && {
        submissionPartId,
        submissionId,
        surveyName: submission.surveyName,
        timePeriodName: submission.timePeriodName,
        partName: submission.parts.find((p) => p.id === Number(submissionPartId))?.name ?? '',
        readonly:
          submissionIsReadonly(submission.status, isAdmin, submission.surveyStatus) ||
          !auth.hasOneOfRoles(RshRole.EditSurveyData, ProviderRole.EditSurveyData),
        isAdmin,
        surveyStatus: submission.surveyStatus,
      },
    [submissionPartId, submissionId, isAdmin, submission, auth],
  );

  if (!submission) {
    return <LoadingMessage />;
  }

  return <SubmissionPartStaticDataContext.Provider value={value!}>{children}</SubmissionPartStaticDataContext.Provider>;
};

export const useSubmissionPartStaticData = (): SubmissionPartStaticData => {
  const context = useContext(SubmissionPartStaticDataContext);
  if (!context) {
    throw new Error(
      `${nameof({ useSubmissionPartStaticData })} must be used within ${nameof({
        SubmissionPartStaticDataProvider,
      })}`,
    );
  }

  return context;
};
