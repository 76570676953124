import saveAs from 'file-saver';
import { ImportTemplateVersionHistory } from 'nrosh-common/Api/SurveyPartsApi';
import { SurveyInstanceWithStringDate } from 'nrosh-common/Api/SurveysApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { useMemo } from 'react';
import { Stack } from 'react-bootstrap';
import { generatePath, useParams } from 'react-router-dom';
import { CellProps } from 'react-table';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { useErrorReporting } from '@/Components/Errors/DCSErrorBoundary';
import FileDownloadIconButton from '@/Components/FileDownload/FileDownloadIconButton';
import LinkButton from '@/Components/Links/LinkButton';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import Table, { NroshColumn } from '@/Components/Table/Table';
import { SurveyPartsApi, SurveysApi } from '@/Helpers/Apis';
import { TimeResolution, formatISODateString } from '@/Helpers/DateHelpers';
import { surveyDisplayName } from '@/Helpers/SurveyHelper';
import { surveyPages } from '@/Pages/Home/SitePages';

const buildHeaders = (
  triggerTemplateDownload: (surveyPartId: number, timePeriodId: number, version: number) => Promise<void>,
): NroshColumn<ImportTemplateVersionHistory>[] => [
  {
    Header: 'Version',
    accessor: 'version',
  },
  {
    Header: 'Upload date',
    accessor: 'uploadDate',
    Cell: ({ value }) => formatISODateString(value, TimeResolution.Second),
  },
  {
    Header: 'Download import file',
    align: 'center',
    Cell: ({ row }: CellProps<ImportTemplateVersionHistory>) => (
      <FileDownloadIconButton
        onDownload={() =>
          triggerTemplateDownload(row.original.surveyPartId, row.original.timePeriodId, row.original.version)
        }
      />
    ),
    disableSortBy: true,
  },
];

const SurveyPartTemplateVersionHistoryPage = (): JSX.Element => {
  const { surveyId, surveyPartId, timePeriodId } = useParams();
  const [surveyData] = useEndpoint<SurveyInstanceWithStringDate>(SurveysApi.getSurveyInstance, surveyId, timePeriodId);
  const [surveyPartTemplateVersions] = useEndpoint<ImportTemplateVersionHistory[]>(
    SurveyPartsApi.getSurveyPartTemplateVersionHistory,
    surveyPartId,
    timePeriodId,
  );
  const [raiseError] = useErrorReporting();

  const triggerTemplateDownload = async (
    templateSurveyPartId: number,
    templateTimePeriodId: number,
    version: number,
  ): Promise<void> => {
    // TODO-198: Replace this with a more best-practices compliant version (use <a href="..." download/> instead of file-saver)
    try {
      const templateFile = await SurveyPartsApi.getSurveyPartImportTemplateVersion(
        templateSurveyPartId.toString(),
        templateTimePeriodId.toString(),
        version.toString(),
      );
      saveAs(URL.createObjectURL(templateFile.content), templateFile.fileName);
    } catch {
      raiseError();
    }
  };

  // It's important to call useMemo here - otherwise, whenever the export button is clicked all of the cells will
  // be unmounted and recreated, and an unmounted component warning appears in the console.
  const headers = useMemo(() => buildHeaders(triggerTemplateDownload), []);

  if (!surveyData || !surveyPartTemplateVersions) {
    return <LoadingMessage />;
  }

  const surveyPartName = surveyData.parts.find((part) => part.surveyPartId === Number(surveyPartId))?.name;
  const pageTitle = `${surveyPartName} version history`;

  return (
    <Stack gap={5}>
      <PageHeader
        heading={pageTitle}
        crumbsType={RootPathType.AdminSurveys}
        crumbs={[
          {
            name: surveyDisplayName(surveyData.name, surveyData.timePeriodName),
            path: surveyPages.SurveyView.path,
          },
          {
            name: 'Configure survey',
            path: surveyPages.SurveyConfigure.path,
          },
          {
            name: pageTitle,
            path: surveyPages.SurveyPartImportTemplateHistory.path,
          },
        ]}
      />
      <Table
        data={surveyPartTemplateVersions}
        columns={headers}
        // There's no useful information to be exported here
        exportable={false}
        rowHeadingIndex={0}
      />
      <div>
        <LinkButton
          to={generatePath(surveyPages.SurveyConfigure.path, { surveyId, timePeriodId })}
          variant="outline-primary"
        >
          Return to Survey
        </LinkButton>
      </div>
    </Stack>
  );
};

export default SurveyPartTemplateVersionHistoryPage;
