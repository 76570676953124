import { saveAs } from 'file-saver';
import { ProviderRole, RshRole } from 'nrosh-common/Api/Enums';
import { SubmissionSupportingDocument } from 'nrosh-common/Api/SubmissionsApi';
import AuthContext, { Auth } from 'nrosh-common/Contexts/AuthContext';
import { useContext, useMemo, useReducer } from 'react';
import { Stack } from 'react-bootstrap';
import { Link, generatePath, useParams } from 'react-router-dom';
import { CellProps } from 'react-table';
import { TertiaryLinkButton } from '@/Components/Buttons/TertiaryLinkButton';
import { DCSErrorContextOutlet, useErrorReporting } from '@/Components/Errors/DCSErrorBoundary';
import { ExportDocumentsButton } from '@/Components/FileDownload/ExportDocumentsButton';
import FileDownloadIconButton from '@/Components/FileDownload/FileDownloadIconButton';
import Delete from '@/Components/Icons/Delete';
import DocumentAdd from '@/Components/Icons/DocumentAdd';
import Edit from '@/Components/Icons/Edit';
import { LoadingSpinner } from '@/Components/Loading/LoadingSpinner';
import { useModal } from '@/Components/Modal/ModalProvider';
import Table, { NroshColumn } from '@/Components/Table/Table';
import { SubmissionsApi } from '@/Helpers/Apis';
import { formatISODateString } from '@/Helpers/DateHelpers';
import useSubmissionDetail from '@/Hooks/useSubmissionDetail';
import { providerPages, submissionPages } from '@/Pages/Home/SitePages';

const deleteWarning = (
  <div>
    <p>Are you sure you want to delete this document?</p>
    <p>
      <strong> This will be permanent.</strong>
    </p>
  </div>
);

const buildHeaders = (
  submissionId: string,
  auth: Auth,
  readonly: boolean,
  onDelete: (documentId: number) => Promise<void>,
  editDocumentPathBuilder: (documentId: string) => string,
  triggerDocumentDownload: (submissionId: string, documentId: string, fileName: string) => Promise<void>,
): NroshColumn<SubmissionSupportingDocument>[] => [
  {
    Header: 'File Name',
    accessor: 'fileName',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Date of Upload',
    align: 'center',
    accessor: 'uploadDate',
    Cell: ({ row }: CellProps<SubmissionSupportingDocument>) => formatISODateString(row.original.uploadDate),
  },
  {
    Header: 'Tags',
    accessor: (row) => row.tags.map((t) => t.name).join(', '),
  },
  ...(!auth.hasOneOfRoles(ProviderRole.EditSurveyData, RshRole.EditSurveyData) || readonly
    ? []
    : [
        {
          Header: 'Edit',
          align: 'center' as const,
          width: 100,
          disableSortBy: true,
          disableResizing: true,
          Cell: ({ row }: CellProps<SubmissionSupportingDocument>) => (
            <Link to={editDocumentPathBuilder(row.original.id.toString())}>
              <Edit />
            </Link>
          ),
        },
      ]),
  {
    Header: 'Export',
    align: 'center' as const,
    width: 100,
    disableSortBy: true,
    disableResizing: true,
    Cell: ({ row }: CellProps<SubmissionSupportingDocument>) => (
      <FileDownloadIconButton
        onDownload={() => triggerDocumentDownload(submissionId, row.original.id.toString(), row.original.fileName)}
      />
    ),
  },
  ...(!auth.hasOneOfRoles(ProviderRole.EditSurveyData, RshRole.EditSurveyData) || readonly
    ? []
    : [
        {
          Header: 'Delete',
          align: 'center' as const,
          width: 100,
          disableSortBy: true,
          disableResizing: true,
          Cell: ({ row }: CellProps<SubmissionSupportingDocument>) => (
            <button className="iconButton" type="button" aria-label="Delete" onClick={() => onDelete(row.original.id)}>
              <Delete />
            </button>
          ),
        },
      ]),
];

type SupportingDocumentsSectionProps = {
  documents: SubmissionSupportingDocument[];
  readonly: boolean;
  isRshUser: boolean;
};

type ExportRowType = {
  'File Name': string;
  'Description': string;
  'Date of Upload': string;
  'Tags': string;
};

const tableRowToExportRow = (document: SubmissionSupportingDocument): ExportRowType => ({
  'File Name': document.fileName,
  'Description': document.description,
  'Date of Upload': formatISODateString(document.uploadDate),
  'Tags': document.tags.map((t) => t.name).join(', '),
});

const SupportingDocumentsSectionDescription = ({ readonly }: { readonly: boolean }): JSX.Element => {
  const description = readonly
    ? 'No supporting documents were uploaded for this survey.'
    : 'If you need to provide a longer explanation for any soft validation issues, please upload supporting documents.';

  return <p className="p-0 m-0">{description}</p>;
};

const SupportingDocumentsSection = ({
  documents,
  readonly,
  isRshUser,
}: SupportingDocumentsSectionProps): JSX.Element => {
  const { surveyId, timePeriodId, submissionId } = useParams();
  const auth = useContext(AuthContext);
  const { confirm } = useModal();
  const [raiseError, clearError] = useErrorReporting();
  const [tableData, deleteRow] = useReducer(
    (currentDocuments: SubmissionSupportingDocument[], id: number) => currentDocuments.filter((d) => d.id !== id),
    documents,
  );

  const [submission] = useSubmissionDetail(submissionId!);

  const triggerDocumentDownload = async (
    submissionIdToDownload: string,
    documentId: string,
    fileName: string,
  ): Promise<void> => {
    // TODO-198: Replace this with a more best-practices compliant version (use <a href="..." download/> instead of file-saver)
    try {
      const document = await SubmissionsApi.downloadSupportingDocument(
        submissionIdToDownload.toString(),
        documentId.toString(),
      );
      saveAs(URL.createObjectURL(document), fileName);
    } catch {
      raiseError();
    }
  };

  const onDelete = async (documentId: number): Promise<void> => {
    if (!(await confirm(deleteWarning))) {
      return;
    }

    const response = await SubmissionsApi.deleteSupportingDocument(submissionId!, documentId.toString()).raw;

    if (response.ok) {
      clearError();
      deleteRow(documentId);
    } else {
      raiseError(response.value.message);
    }
  };

  const editDocumentPathBuilder = (documentId: string): string =>
    isRshUser
      ? generatePath(submissionPages.EditSupportingDocument.path, { surveyId, timePeriodId, submissionId, documentId })
      : generatePath(providerPages.EditSupportingDocument.path, { submissionId, documentId });

  // It's important to call useMemo here - otherwise, whenever the export button is clicked all of the cells will
  // be unmounted and recreated, and an unmounted component warning appears in the console.
  const headers = useMemo(
    () => buildHeaders(submissionId!, auth, readonly, onDelete, editDocumentPathBuilder, triggerDocumentDownload),
    [readonly],
  );

  const uploadDocumentPath = isRshUser
    ? generatePath(submissionPages.UploadSupportingDocument.path, { surveyId, timePeriodId, submissionId })
    : generatePath(providerPages.UploadSupportingDocument.path, { submissionId });

  if (!submission) {
    return <LoadingSpinner />;
  }

  const exportFileName = isRshUser
    ? `${submission.provider.name} - Supporting Documents Metadata.csv`
    : 'Supporting Documents Metadata.csv';

  return (
    <Stack gap={3}>
      <h2>Supporting documents</h2>
      <DCSErrorContextOutlet />

      {tableData.length === 0 ? (
        <SupportingDocumentsSectionDescription readonly={readonly} />
      ) : (
        <Table
          data={tableData}
          columns={headers}
          paginated
          exportable
          exportFileName={exportFileName}
          tableRowToExportRow={tableRowToExportRow}
          rowHeadingIndex={0}
        />
      )}
      <Stack direction="horizontal" gap={2}>
        {auth.hasOneOfRoles(ProviderRole.EditSurveyData, RshRole.EditSurveyData) && !readonly && (
          <TertiaryLinkButton
            icon={<DocumentAdd />}
            to={uploadDocumentPath}
            text="Upload new document"
            disabled={readonly}
          />
        )}
        {tableData.length !== 0 && (
          <ExportDocumentsButton
            numFiles={tableData.length}
            buttonText="Download all documents"
            endpointCall={() => SubmissionsApi.downloadAllSupportingDocuments(submissionId!.toString())}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default SupportingDocumentsSection;
