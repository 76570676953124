import { RshRole, SubmissionStatus } from 'nrosh-common/Api/Enums';
import { SurveyStatus } from 'nrosh-common/Api/SubmissionsApi';
import { Surveys } from 'nrosh-common/Api/SurveysApi';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { useContext } from 'react';
import { Stack } from 'react-bootstrap';
import { Link, generatePath } from 'react-router-dom';
import { CellProps } from 'react-table';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import FiltersComponent, { FilterType, getDefaultSelectedFilterInfo } from '@/Components/Filters/Filters';
import useFilters, { Filter } from '@/Components/Filters/useFilters';
import LinkButton from '@/Components/Links/LinkButton';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import Table, { NroshColumn } from '@/Components/Table/Table';
import { SurveysApi } from '@/Helpers/Apis';
import { formatISODateString } from '@/Helpers/DateHelpers';
import { surveyDisplayName } from '@/Helpers/SurveyHelper';
import { surveyPages } from '@/Pages/Home/SitePages';

type SurveyTableRow = {
  surveyId: number;
  surveyName: string;
  timePeriodId: number;
  timePeriodName: string;
  statusCounts: Record<SubmissionStatus, number | undefined>;
  surveyStatus: SurveyStatus;
  defaultDeadline: string;
};

type ExportSurveyTableRow = {
  'Survey': string;
  'Not Started': number;
  'Started': number;
  'Unsubmitted': number;
  'Submitted': number;
  'Signed-off': number;
  '% Submitted': string;
  'Total Assigned': number;
  'Default Deadline': string;
};

const getTotalAssigned = (statusCounts: Record<SubmissionStatus, number | undefined>): number =>
  Object.values(statusCounts).reduce((acc: number, value) => acc + (value ?? 0), 0) ?? 0;

const getSurveyCompletionPercentage = (statusCounts: Record<SubmissionStatus, number | undefined>): string => {
  const totalAssigned = getTotalAssigned(statusCounts);

  return totalAssigned > 0
    ? `${Math.floor(
        (((statusCounts[SubmissionStatus.Submitted] ?? 0) + (statusCounts[SubmissionStatus.SignedOff] ?? 0)) * 100) /
          totalAssigned,
      )}%`
    : '-';
};

const filters: Filter<SurveyTableRow>[] = [
  {
    type: FilterType.Checkbox,
    id: 'status',
    text: 'Survey Status',
    filter: (survey, values) => values.includes(survey.surveyStatus || ''),
    options: [
      {
        value: SurveyStatus.Open,
        text: 'Open',
        isUnselectedByDefault: false,
      },
      {
        value: SurveyStatus.InDevelopment,
        text: 'In Development',
        isUnselectedByDefault: true,
      },
      {
        value: SurveyStatus.Closed,
        text: 'Closed',
        isUnselectedByDefault: true,
      },
    ],
  },
];

const tableRowToExportRow = (tableRow: SurveyTableRow): ExportSurveyTableRow => ({
  'Survey': `${tableRow.surveyName} - ${tableRow.timePeriodName}`,
  'Not Started': tableRow.statusCounts[SubmissionStatus.NotStarted] || 0,
  'Started': tableRow.statusCounts[SubmissionStatus.InProgress] || 0,
  'Unsubmitted': tableRow.statusCounts[SubmissionStatus.Unsubmitted] || 0,
  'Submitted': tableRow.statusCounts[SubmissionStatus.Submitted] || 0,
  'Signed-off': tableRow.statusCounts[SubmissionStatus.SignedOff] || 0,
  '% Submitted': getSurveyCompletionPercentage(tableRow.statusCounts),
  'Total Assigned': getTotalAssigned(tableRow.statusCounts),
  'Default Deadline': formatISODateString(tableRow.defaultDeadline),
});

const SurveyDashboardPage = (): JSX.Element => {
  const auth = useContext(AuthContext);
  const [data] = useEndpoint<Surveys>(SurveysApi.getSurveys);

  const { filteredData: filteredSurveys, ...filterProps } = useFilters(
    data?.surveys.flatMap((s) =>
      s.instances.map((si) => ({
        surveyId: s.surveyId,
        surveyName: s.surveyName,
        timePeriodId: si.timePeriodId,
        timePeriodName: si.timePeriodName,
        statusCounts: si.statusCounts,
        surveyStatus: si.surveyStatus,
        defaultDeadline: si.defaultDeadline,
      })),
    ) || [],
    filters,
  );

  if (!auth.hasRole(RshRole.ViewSurveys)) {
    return <h1>Welcome to DCS</h1>;
  }

  if (!filterProps.selectedFilter) {
    filterProps.setSelectedFilter(getDefaultSelectedFilterInfo(filters[0]));
  }
  const surveyTableLink = (value: string, rowValues: SurveyTableRow): JSX.Element => (
    <Link
      style={{ wordBreak: 'break-word' }}
      to={generatePath(surveyPages.SurveyView.path, {
        surveyId: rowValues.surveyId.toString(),
        timePeriodId: rowValues.timePeriodId.toString(),
      })}
    >
      {value}
    </Link>
  );

  const headers: NroshColumn<SurveyTableRow>[] = [
    {
      Header: 'Survey',
      accessor: (row: SurveyTableRow) => surveyDisplayName(row.surveyName, row.timePeriodName),
      Cell: ({ value, row: { original: rowValues } }: CellProps<SurveyTableRow, string>) =>
        surveyTableLink(value, rowValues),
      minWidth: 200,
    },
    {
      Header: 'Not Started',
      accessor: (row: SurveyTableRow) => row.statusCounts[SubmissionStatus.NotStarted] || '-',
      minWidth: 125,
      align: 'center',
    },
    {
      Header: 'Started',
      accessor: (row: SurveyTableRow) => row.statusCounts[SubmissionStatus.InProgress] || '-',
      minWidth: 110,
      align: 'center',
    },
    {
      Header: 'Unsubmitted',
      accessor: (row: SurveyTableRow) => row.statusCounts[SubmissionStatus.Unsubmitted] || '-',
      minWidth: 150,
      align: 'center',
    },
    {
      Header: 'Submitted',
      accessor: (row: SurveyTableRow) => row.statusCounts[SubmissionStatus.Submitted] || '-',
      minWidth: 130,
      align: 'center',
    },
    {
      Header: 'Signed-off',
      accessor: (row: SurveyTableRow) => row.statusCounts[SubmissionStatus.SignedOff] || '-',
      minWidth: 110,
      align: 'center',
    },
    {
      Header: '% Submitted',
      accessor: (row: SurveyTableRow) => getSurveyCompletionPercentage(row.statusCounts),
      minWidth: 180,
      align: 'center',
    },
    {
      Header: 'Total Assigned',
      accessor: (row: SurveyTableRow) => getTotalAssigned(row.statusCounts),
      minWidth: 160,
      align: 'center',
    },
    {
      Header: 'Default Deadline',
      accessor: 'defaultDeadline',
      Cell: ({ value }) => formatISODateString(value),
      minWidth: 180,
      align: 'center',
    },
  ];

  if (!data) {
    return <LoadingMessage />;
  }

  return (
    <Stack gap={5}>
      <PageHeader heading="All surveys" crumbsType={RootPathType.AdminSurveys} />
      <Stack gap={5}>
        <p className="m-0 p-0">
          This page shows the overall status of data entry, validation, submission and sign-off for each of the surveys.
          Survey level information and reports are available from the dashboard areas below.
        </p>
        <div>
          <FiltersComponent {...filterProps} heading="Filter Surveys" />
          <Table
            data={filteredSurveys}
            columns={headers}
            paginated
            exportable
            exportFileName="Survey Summary.csv"
            tableRowToExportRow={tableRowToExportRow}
            rowHeadingIndex={0}
          />
        </div>
        {auth.hasRole(RshRole.DesignSurveys) && (
          <div className="d-flex gap-4">
            <LinkButton to={surveyPages.SurveyCreate.path}>Create new survey</LinkButton>
            <LinkButton variant="outline-primary" to={surveyPages.SurveyClone.path}>
              Clone existing survey
            </LinkButton>
          </div>
        )}
      </Stack>
    </Stack>
  );
};

export default SurveyDashboardPage;
