type Props = {
  heading: string;
};

const FilterHeading = (props: Props): JSX.Element => {
  const { heading } = props;
  return <p className="mb-2">{heading}</p>;
};

export default FilterHeading;
