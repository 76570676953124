import saveAs from 'file-saver';
import { ProviderSummary } from 'nrosh-common/Api/ProvidersApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import React, { useCallback, useMemo } from 'react';
import { Form, Stack } from 'react-bootstrap';
import { Column } from 'react-table';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { useErrorReporting } from '@/Components/Errors/DCSErrorBoundary';
import ExportButton from '@/Components/ExportButton/ExportButton';
import { AccessibleFieldSet } from '@/Components/Form/AccessibleFieldSet';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import Table from '@/Components/Table/Table';
import { ProvidersApi } from '@/Helpers/Apis';
import { generateInitialFilterFromNameRecord, getSelectedValues, useFilterPattern } from '@/Helpers/FilterHelpers';
import { caseInsensitiveSort } from '@/Helpers/TableHelper';
import { adminPages } from '@/Pages/Home/SitePages';
import { providerSizeNames, providerTypeNames, reportingStatusNames } from '@/Pages/Providers/ProviderEnums';

const columns: Column<ProviderSummary>[] = [
  {
    Header: 'Provider Number',
    accessor: 'providerNumber',
    minWidth: 120,
  },
  {
    Header: 'Provider Name',
    accessor: 'name',
    sortType: caseInsensitiveSort,
    width: 220,
  },
  {
    Header: 'Provider Size',
    accessor: 'size',
    Cell: ({ value }) => providerSizeNames[value],
    width: 220,
  },
  {
    Header: 'Provider Reporting Status',
    accessor: 'reportingStatus',
    Cell: ({ value }) => reportingStatusNames[value],
    width: 220,
  },
  {
    Header: 'Provider Type',
    accessor: 'type',
    Cell: ({ value }) => providerTypeNames[value],
    width: 220,
  },
];

const tableRowToExportRow = (
  provider: ProviderSummary,
): {
  'Provider Number': string;
  'Provider Name': string;
  'Provider Size': string;
  'Provider Reporting Status': string;
  'Provider Type': string;
} => ({
  'Provider Number': provider.providerNumber,
  'Provider Name': provider.name,
  'Provider Size': providerSizeNames[provider.size],
  'Provider Reporting Status': reportingStatusNames[provider.reportingStatus],
  'Provider Type': providerTypeNames[provider.type],
});

export const ViewProviderGroupPage = (): JSX.Element => {
  const [providers] = useEndpoint<ProviderSummary[]>(ProvidersApi.getProviders);
  const [raiseError] = useErrorReporting();

  const [providerSizeSelectionPattern, setProviderSizeSelected] = useFilterPattern(
    generateInitialFilterFromNameRecord(providerSizeNames, true),
  );

  const [reportingStatusSelectionPattern, setReportingStatusSelected] = useFilterPattern(
    generateInitialFilterFromNameRecord(reportingStatusNames, true),
  );

  const [providerTypeSelectionPattern, setProviderTypeSelected] = useFilterPattern(
    generateInitialFilterFromNameRecord(providerTypeNames, true),
  );

  const filteredProviders = useMemo(() => {
    const selectedSizes = getSelectedValues(providerSizeSelectionPattern);
    const selectedTypes = getSelectedValues(providerTypeSelectionPattern);
    const selectedReportingStatuses = getSelectedValues(reportingStatusSelectionPattern);

    return providers?.filter(
      (p) =>
        selectedSizes.includes(p.size) &&
        selectedTypes.includes(p.type) &&
        selectedReportingStatuses.includes(p.reportingStatus),
    );
  }, [providers, providerSizeSelectionPattern, providerTypeSelectionPattern, reportingStatusSelectionPattern]);

  const onDownload = useCallback(async () => {
    try {
      const response = await ProvidersApi.downloadProviderGroup(
        getSelectedValues(providerSizeSelectionPattern),
        getSelectedValues(reportingStatusSelectionPattern),
        getSelectedValues(providerTypeSelectionPattern),
      );
      saveAs(response.content, response.fileName);
    } catch {
      raiseError();
    }
  }, [providerSizeSelectionPattern, reportingStatusSelectionPattern, providerTypeSelectionPattern]);

  if (!providers || !filteredProviders) {
    return <LoadingMessage />;
  }

  return (
    <Stack gap={5}>
      <PageHeader
        heading="View provider groups"
        crumbsType={RootPathType.AdminProviders}
        crumbs={[{ name: 'View provider groups', path: adminPages.AdminProviderGroups.path }]}
      />
      <Form onSubmit={() => {}} className="mb-3 recipientsForm">
        <AccessibleFieldSet legend="Provider Size" legendClassName="mb-2">
          <Form.Group className="mb-3" controlId="provider-size">
            {providerSizeSelectionPattern.map((ps) => (
              <Form.Check
                key={`provider-size-${ps.value}`}
                inline
                label={ps.displayName}
                name="provider-size"
                type="checkbox"
                id={`provider-size-${ps.value}`}
                checked={ps.isSelected}
                onChange={(e) => setProviderSizeSelected(ps.value, e.target.checked)}
              />
            ))}
          </Form.Group>
        </AccessibleFieldSet>

        <AccessibleFieldSet legend="Reporting Status" legendClassName="mb-2">
          <Form.Group className="mb-3" controlId="reporting-status">
            {reportingStatusSelectionPattern.map((rs) => (
              <Form.Check
                key={`reporting-status-${rs.value}`}
                inline
                label={rs.displayName}
                name="reporting-status"
                type="checkbox"
                id={`reporting-status-${rs.value}`}
                checked={rs.isSelected}
                onChange={(e) => setReportingStatusSelected(rs.value, e.target.checked)}
              />
            ))}
          </Form.Group>
        </AccessibleFieldSet>

        <AccessibleFieldSet legend="Provider Type" legendClassName="mb-2">
          <Form.Group className="mb-3" controlId="provider-type">
            {providerTypeSelectionPattern.map((pt) => (
              <Form.Check
                key={`provider-type-${pt.value}`}
                inline
                label={pt.displayName}
                name="provider-type"
                type="checkbox"
                id={`provider-type-${pt.value}`}
                checked={pt.isSelected}
                onChange={(e) => setProviderTypeSelected(pt.value, e.target.checked)}
              />
            ))}
          </Form.Group>
        </AccessibleFieldSet>
      </Form>
      <ExportButton label={`Export ${filteredProviders.length} Providers for Assignment`} onDownload={onDownload} />
      <Table
        data={filteredProviders}
        columns={columns}
        defaultSort={[{ id: 'name', desc: false }]}
        paginated
        exportable
        tableRowToExportRow={tableRowToExportRow}
        exportFileName="Provider Group.csv"
        rowHeadingIndex={1}
      />
    </Stack>
  );
};
