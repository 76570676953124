import { EmailTemplateType } from 'nrosh-common/Api/EmailsApi';

export const emailTemplateTypeNames: Record<EmailTemplateType, string> = {
  NewProviderUser: 'Set Up New Provider User',
  NewDcsUser: 'Set Up New DCS User',
  ProviderUserPasswordReset: 'Provider User: Password Reset',
  DcsUserPasswordReset: 'DCS User: Password Reset',
  ProviderUserUsernameReminder: 'Provider User: Username Reminder',
  DcsUserUsernameReminder: 'Dcs User: Username Reminder',
  ProviderUserAccountLock: 'Provider User: Account Locked',
  DcsUserAccountLock: 'DCS User: Account Locked',
  ProviderUserAccountDisabled: 'Provider User: Account Disabled',
  DcsUserAccountDisabled: 'DCS User: Account Disabled',
  ProviderUserAccountReactivated: 'Provider User: Account Reactivated',
  DcsUserAccountReactivated: 'DCS User: Account Reactivated',
  ValidationAccepted: 'Failing Validation Accepted',
  SurveySubmitted: 'Survey Submitted',
  SurveyAssigned: 'Survey Assignment',
  SurveyDeadlineUpdated: 'Survey Deadline Update',
  SurveyUnsubmitted: 'Survey Unsubmitted',
  SurveySignOff: 'Survey Signed-off',
  SurveyReminderDefault: 'Default Survey Reminder',
  Footer: 'Email Footer',
  ConfirmEmail: 'Confirm Email',
};

export const emailTemplateTypeOptionality: Record<EmailTemplateType, boolean> = {
  NewProviderUser: false,
  NewDcsUser: false,
  ProviderUserPasswordReset: false,
  DcsUserPasswordReset: false,
  ProviderUserUsernameReminder: false,
  DcsUserUsernameReminder: false,
  ProviderUserAccountLock: false,
  DcsUserAccountLock: false,
  ProviderUserAccountDisabled: false,
  DcsUserAccountDisabled: false,
  ProviderUserAccountReactivated: false,
  DcsUserAccountReactivated: false,
  ValidationAccepted: false,
  SurveySubmitted: false,
  SurveyAssigned: true,
  SurveyDeadlineUpdated: true,
  SurveyUnsubmitted: true,
  SurveySignOff: true,
  SurveyReminderDefault: false,
  Footer: false,
  ConfirmEmail: false,
};

const sendToTargetUserString = 'Target User';
const sendToSurveyEditorsString = 'Survey Editors';

export const emailTemplateTypeRecipients: Record<EmailTemplateType, string> = {
  NewProviderUser: sendToTargetUserString,
  NewDcsUser: sendToTargetUserString,
  ProviderUserPasswordReset: sendToTargetUserString,
  DcsUserPasswordReset: sendToTargetUserString,
  ProviderUserUsernameReminder: sendToTargetUserString,
  DcsUserUsernameReminder: sendToTargetUserString,
  ProviderUserAccountLock: sendToTargetUserString,
  DcsUserAccountLock: sendToTargetUserString,
  ProviderUserAccountDisabled: sendToTargetUserString,
  DcsUserAccountDisabled: sendToTargetUserString,
  ProviderUserAccountReactivated: sendToTargetUserString,
  DcsUserAccountReactivated: sendToTargetUserString,
  ValidationAccepted: sendToSurveyEditorsString,
  SurveySubmitted: sendToSurveyEditorsString,
  SurveyAssigned: sendToSurveyEditorsString,
  SurveyDeadlineUpdated: sendToSurveyEditorsString,
  SurveyUnsubmitted: sendToSurveyEditorsString,
  SurveySignOff: sendToSurveyEditorsString,
  SurveyReminderDefault: sendToSurveyEditorsString,
  ConfirmEmail: sendToTargetUserString,
  Footer: '',
};
