import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { BaseCellProps, baseCellProps, styledValue } from '@/Components/Spreadsheet/Cells/CellHelpers';
import { textClassNameForNumericValue } from '@/Components/Spreadsheet/Cells/CellStyleHelpers';
import { DataPointCell } from '@/Components/Spreadsheet/Cells/DataPointCell';
import { DataCellStyle } from '@/Components/Spreadsheet/SpreadsheetTypes';

export type TotalCellProps = BaseCellProps & {
  cellStyle: DataCellStyle;
  cellKey: string;
  isActive: boolean;
  setActiveCell: ReactStateSetter<string | null>;
  value: string;
};

const TotalCell = (props: TotalCellProps): JSX.Element => {
  const { cellStyle, cellKey, isActive, setActiveCell, value } = props;
  const { style } = cellStyle;

  return (
    <DataPointCell {...baseCellProps(props)} cellKey={cellKey} active={isActive} setActiveCell={setActiveCell}>
      <span className={textClassNameForNumericValue(style, value)}>{styledValue(style, value)}</span>
    </DataPointCell>
  );
};

export default TotalCell;
