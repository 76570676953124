import '@/Components/Form/FormDatePicker.scss';
import { format } from 'date-fns';
import { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';

export type DatePickerProps = {
  value: Date | null;
  onChange: (newDate: Date | null) => void;
  inputId?: string;
  required?: boolean;
  disabled?: boolean;
  min?: Date | null;
  isInvalid?: boolean;
  ariaLabel?: string;
};

type FormDatePickerProps = DatePickerProps & {
  // The output of this function will be passed as a value to the  <input type="date" />.
  // It should be either the empty string or a string in the "yyyy-MM-dd" format.
  mapDateToString: (date: Date | null) => string;
  // The string argument passed to this function is the value of the <input type="date" />.
  // It should be either the empty string or a string in the "yyyy-MM-dd" format.
  mapStringToDate: (dateString: string) => Date | null;
  useCalendarIcon?: boolean;
};

const FormDatePicker = ({
  value,
  onChange,
  inputId,
  required = false,
  disabled = false,
  isInvalid = false,
  min = null,
  useCalendarIcon,
  ariaLabel,
  mapDateToString,
  mapStringToDate,
}: FormDatePickerProps): JSX.Element => {
  const valueString = mapDateToString(value);
  const minDateString = mapDateToString(min);
  const onValueChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const dateString = e.target.value;
    onChange(mapStringToDate(dateString));
  };
  return (
    <div className="formDatePickerContainer">
      <Form.Control
        className={useCalendarIcon ? 'formDatePickerCalendarIcon' : undefined}
        value={valueString}
        onChange={onValueChange}
        type="date"
        id={inputId}
        name={inputId}
        required={required}
        disabled={disabled}
        min={minDateString}
        isInvalid={isInvalid}
        aria-label={ariaLabel}
      />
    </div>
  );
};

const mapUtcDateToString = (date: Date | null): string => (date ? date.toISOString().substring(0, 10) : '');
const mapStringToUtcMidnight = (dateString: string): Date | null => (dateString ? new Date(dateString) : null);

// Use this component when the date being selected represents the concept of a day.
// For example, survey deadline dates, or end of financial year dates.
export const UtcDatePicker = (props: DatePickerProps): JSX.Element => {
  const { disabled } = props;
  return (
    <FormDatePicker
      {...props}
      useCalendarIcon={!disabled}
      mapDateToString={mapUtcDateToString}
      mapStringToDate={mapStringToUtcMidnight}
    />
  );
};

const mapLocalDateToString = (date: Date | null): string => (date ? format(date, 'yyyy-MM-dd') : '');
const mapStringToLocalMidnight = (dateString: string): Date | null =>
  dateString ? new Date(`${dateString}T00:00:00`) : null;

// Use this component when the date being selected represents a point in time (i.e. local midnight on the given day).
// For example, when selecting date range filters, or setting a publish time for site content.
export const LocalDatePicker = (props: DatePickerProps): JSX.Element => (
  <FormDatePicker {...props} mapDateToString={mapLocalDateToString} mapStringToDate={mapStringToLocalMidnight} />
);
