import classNames from 'classnames';
import { CellStyle } from 'nrosh-common/Api/Enums';
import { BaseCellProps } from '@/Components/Spreadsheet/Cells/CellHelpers';

const warningStyles = [
  CellStyle.TextSmallWarning,
  CellStyle.TextSmallBoldWarning,
  CellStyle.TextSmallWarningRight,
  CellStyle.TextSmallBoldWarningRight,
];

const rightAlignedStyles = [
  CellStyle.PurpleSmallRight,
  CellStyle.YellowSmallRight,
  CellStyle.TextSmallRight,
  CellStyle.TextSmallBoldRight,
  CellStyle.TextSmallWarningRight,
  CellStyle.TextSmallBoldWarningRight,
  CellStyle.DataCurrency,
  CellStyle.DataDate,
  CellStyle.DataDecimalOneDp,
  CellStyle.DataDecimalTwoDp,
  CellStyle.DataInteger,
  CellStyle.PrePopulatedCurrency,
  CellStyle.PrePopulatedDecimalOneDp,
  CellStyle.PrePopulatedDecimalTwoDp,
  CellStyle.PrePopulatedInteger,
  CellStyle.TextQuestionNumber,
];
const centreAlignedStyles = [
  CellStyle.TableHeaderCentre,
  CellStyle.CalculatedYellowHeader,
  CellStyle.TextQuestionNumberCentre,
];

const topAlignedStyles = [CellStyle.DataText, CellStyle.PrePopulatedText];

const borderedStyles = [
  CellStyle.GreyBorder,
  CellStyle.PurpleHeaderSmallBorder,
  CellStyle.YellowHeaderSmallBorder,
  CellStyle.TableHeaderCentre,
  CellStyle.TableHeaderLeft,
];
const topAndBottomBorderStyles = [CellStyle.TableHeaderLine];
const bottomBorderStyles = [CellStyle.PurpleLine, CellStyle.YellowLine];
const purpleBorderStyles = [CellStyle.PurpleLine];
const yellowBorderStyles = [CellStyle.YellowLine];

const largeTextStyles = [
  CellStyle.PurpleHeaderLarge,
  CellStyle.YellowHeaderLarge,
  CellStyle.TextLarge,
  CellStyle.TextLargeBold,
];
const mediumTextStyles = [
  CellStyle.PurpleHeaderMedium,
  CellStyle.YellowHeaderMedium,
  CellStyle.CalculatedYellowHeader,
  CellStyle.TextMedium,
  CellStyle.TextMediumBold,
];

const boldTextStyles = [
  CellStyle.TextLargeBold,
  CellStyle.TextMediumBold,
  CellStyle.TextSmallBold,
  CellStyle.TextSmallBoldRight,
  CellStyle.TextSmallBoldWarning,
  CellStyle.TextSmallBoldWarningRight,
  CellStyle.TextQuestionNumber,
  CellStyle.TextQuestionNumberCentre,
  CellStyle.PurpleLine,
  CellStyle.YellowLine,
];

export const styleIsData = (style: CellStyle): boolean => style.startsWith('Data');
const styleIsTotal = (style: CellStyle): boolean => style.startsWith('Total');
const styleIsPrePopulated = (style: CellStyle): boolean => style.startsWith('PrePopulated');
const styleIsPrePopulatedText = (style: CellStyle): boolean => style === CellStyle.PrePopulatedText;

export const styleClassName = (style: CellStyle): string => {
  const styles = [];

  if (styleIsData(style)) styles.push('data-input');
  if (styleIsTotal(style)) styles.push('total');
  if (styleIsPrePopulated(style)) styles.push('pre-populated');
  if (styleIsPrePopulatedText(style)) styles.push('text');
  if (style.startsWith('Grey')) styles.push('grey');
  if (style.startsWith('Purple') && style !== CellStyle.PurpleLine) styles.push('purple');
  if (style.startsWith('Yellow') && style !== CellStyle.YellowLine) styles.push('yellow');
  if (style === CellStyle.CalculatedYellowHeader) styles.push('yellow');
  if (style.startsWith('Table')) styles.push('table-header');

  if (warningStyles.includes(style)) styles.push('warning-text');

  if (rightAlignedStyles.includes(style)) styles.push('right-align');
  if (centreAlignedStyles.includes(style)) styles.push('centre-align');

  if (topAlignedStyles.includes(style)) styles.push('top-align');

  if (borderedStyles.includes(style)) styles.push('bordered');
  if (topAndBottomBorderStyles.includes(style)) styles.push('top-and-bottom-border');
  if (bottomBorderStyles.includes(style)) styles.push('bottom-border');

  if (purpleBorderStyles.includes(style)) styles.push('purple-border');
  if (yellowBorderStyles.includes(style)) styles.push('yellow-border');

  if (largeTextStyles.includes(style)) styles.push('large-text');
  else if (mediumTextStyles.includes(style)) styles.push('medium-text');
  else styles.push('normal-text');

  if (boldTextStyles.includes(style)) styles.push('bold');

  if (style === CellStyle.Hidden) styles.push('hidden-text');

  return classNames(styles);
};

export const styleIsCalculation = (style: CellStyle): boolean =>
  styleIsTotal(style) || styleIsPrePopulated(style) || style === CellStyle.CalculatedYellowHeader;

export const styleIsNumericDataInput = (style: CellStyle): boolean =>
  [CellStyle.DataCurrency, CellStyle.DataDecimalOneDp, CellStyle.DataDecimalTwoDp, CellStyle.DataInteger].includes(
    style,
  );

export const textClassNameForNumericValue = (style: CellStyle, value: string): string =>
  (styleIsNumericDataInput(style) || styleIsCalculation(style)) && Number(value) < 0 ? 'warning-text' : '';

export const freezePaneClassName = (props: BaseCellProps): string =>
  classNames({
    'freeze-pane-row': props.isInFreezePane && props.top !== undefined,
    'freeze-pane-column': props.isInFreezePane && props.left !== undefined,
  });
