import saveAs from 'file-saver';
import { PublicDocument } from 'nrosh-common/Api/UnauthenticatedPublicDocumentsApi';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { CellProps } from 'react-table';
import { FilterDropdown } from '@/Components/Filters/FilterDropdown';
import FilterHeading from '@/Components/Filters/FilterHeading';
import { FilterSearchBox } from '@/Components/Filters/FilterSearchBox';
import ArrowRight from '@/Components/Icons/ArrowRight';
import { SearchableDropdown, SearchableDropdownOption } from '@/Components/SearchableDropdown/SearchableDropdown';
import Table, { NroshColumn } from '@/Components/Table/Table';
import { UnauthenticatedPublicDocumentsApi } from '@/Helpers/Apis';
import { formatISODateString } from '@/Helpers/DateHelpers';

const triggerDocumentDownload = async (documentId: string, fileName: string): Promise<void> => {
  // TODO-198: Replace this with a more best-practices compliant version (use <a href="..." download/> instead of file-saver)
  const document = await UnauthenticatedPublicDocumentsApi.download(documentId.toString());
  saveAs(URL.createObjectURL(document), fileName);
};

const headers: NroshColumn<PublicDocument>[] = [
  {
    Header: 'Document Title',
    accessor: 'title',
    Cell: ({ value, row }) => (
      <button
        type="button"
        className="d-flex align-items-center"
        onClick={() => triggerDocumentDownload(row.original.id.toString(), row.original.fileName)}
      >
        {value}
        <span className="ps-1 linkIcon">
          <ArrowRight />
        </span>
      </button>
    ),
    minWidth: 250,
  },
  {
    Header: 'File type',
    accessor: 'fileExtension',
  },
  {
    Header: 'Date published',
    align: 'center',
    width: 100,
    accessor: 'uploadDate',
    Cell: ({ row }: CellProps<PublicDocument>) => formatISODateString(row.original.uploadDate),
  },
  {
    Header: 'Tags',
    accessor: (row) => (row.tags ? row.tags.map((t) => t.name).join(', ') : ''),
  },
];

type PublicDocumentsTableProps = {
  publicDocuments: PublicDocument[];
  tagOptions: SearchableDropdownOption<number>[];
};

const applyFilters = (
  documents: PublicDocument[],
  documentSearchString: string,
  tagIds: number[],
): PublicDocument[] => {
  const normalisedSearchString = documentSearchString.trim().toLowerCase();
  let filteredDocuments = documents.filter((d) => d.title.toLowerCase().includes(normalisedSearchString));
  filteredDocuments =
    tagIds.length === 0
      ? filteredDocuments
      : filteredDocuments.filter((d) => tagIds.some((id) => d.tags.some((t) => t.id === id)));
  return filteredDocuments;
};

const PublicDocumentsTable = ({ publicDocuments, tagOptions }: PublicDocumentsTableProps): JSX.Element => {
  const [documentSearchString, setDocumentSearchString] = useState<string>('');
  const [tagIds, setTagIds] = useState<number[]>([]);
  const [filteredDocuments, setFilteredDocuments] = useState(publicDocuments);

  useEffect(() => {
    setFilteredDocuments(applyFilters(publicDocuments, documentSearchString, tagIds));
  }, [publicDocuments, tagIds, documentSearchString]);

  return (
    <div>
      <FilterDropdown resultCount={filteredDocuments.length} heading="Filter Documents">
        <div className="row mb-2">
          <Form.Group className="col">
            <Form.Label className="fw-normal mb-0">
              <FilterHeading heading="Search for Document Title" />
            </Form.Label>
            <FilterSearchBox
              id="documentSearchBox"
              searchString={documentSearchString}
              setSearchString={setDocumentSearchString}
              placeholderText="Search for document title"
            />
          </Form.Group>
          <div className="col">
            <FilterHeading heading="Filter by Tag" />
            <SearchableDropdown
              options={tagOptions}
              currentSelection={tagIds}
              placeholderText="Choose Tags"
              onChange={(v) => setTagIds(v)}
              allowMultiSelect
            />
          </div>
        </div>
      </FilterDropdown>
      <Table
        data={filteredDocuments}
        columns={headers}
        // This is a public page, so we don't allow export
        exportable={false}
        rowHeadingIndex={0}
      />
    </div>
  );
};

export default PublicDocumentsTable;
