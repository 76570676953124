import { Nav } from 'react-bootstrap';

type Props = {
  logout: () => Promise<void>;
  className?: string;
};

const SignOutLink = ({ logout, className }: Props): JSX.Element => (
  <Nav.Link className={className} eventKey="logout" onClick={logout}>
    Sign Out
  </Nav.Link>
);

export default SignOutLink;
