import classNames from 'classnames';
import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ChevronDown from '@/Components/Icons/ChevronDown';
import ChevronUp from '@/Components/Icons/ChevronUp';
import { SidebarIconType } from '@/Components/Sidebar/SidebarIcon';
import { convertStringToSentenceCase } from '@/Helpers/StringHelpers';
import { DropdownLink } from '@/Pages/Home/LayoutWithSidebar';

const SidebarLink = (props: {
  page: {
    name: string;
    path: string;
    icon: SidebarIconType;
    dropdownLinks?: DropdownLink[];
  };
  index: number;
  activeExpanded: boolean;
  setActiveExpanded: ReactStateSetter<boolean>;
  activeIndex: number | undefined;
  setActiveIndex: ReactStateSetter<number | undefined>;
}): JSX.Element => {
  const { page, index, activeExpanded, setActiveExpanded, activeIndex, setActiveIndex } = props;
  const isActiveAndExpanded = activeExpanded && index === activeIndex;

  const handleClick = (): void => {
    setActiveExpanded(!isActiveAndExpanded);
    setActiveIndex(index);
  };

  return (
    <div className="sidebarLinkContainer">
      {page.dropdownLinks && page.dropdownLinks.length > 0 ? (
        <div className={classNames('sidebarLink', { activeLink: index === activeIndex })}>
          <Nav.Link as={Link} to={page.path} onClick={handleClick}>
            {convertStringToSentenceCase(page.name)}
          </Nav.Link>
          <button
            className="blankButton"
            type="button"
            aria-label={`${isActiveAndExpanded ? 'Hide' : 'Expand'} ${page.name}`}
            onClick={handleClick}
          >
            {isActiveAndExpanded ? <ChevronUp /> : <ChevronDown />}
          </button>
        </div>
      ) : (
        <div className={classNames({ activeLink: index === activeIndex })}>
          <Nav.Link onClick={handleClick} as={Link} to={page.path}>
            {convertStringToSentenceCase(page.name)}
          </Nav.Link>
        </div>
      )}
    </div>
  );
};

export default SidebarLink;
