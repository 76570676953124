import { CellStyle } from 'nrosh-common/Api/Enums';
import { ChangeEvent, FocusEvent, useState } from 'react';
import {
  InputCellProps,
  baseCellProps,
  formattedValue,
  inputType,
  styledValue,
  truncateValue,
} from '@/Components/Spreadsheet/Cells/CellHelpers';
import { styleIsNumericDataInput, textClassNameForNumericValue } from '@/Components/Spreadsheet/Cells/CellStyleHelpers';
import { DataPointCellWithOverlay } from '@/Components/Spreadsheet/Cells/DataPointCell';

const isInputInvalid = (style: CellStyle, value: string): boolean =>
  styleIsNumericDataInput(style) && Number.isNaN(Number(value));

const DataInputCell = (props: InputCellProps): JSX.Element => {
  const { cellStyle, cellKey, isActive, setActiveCell, value, dimension1Id, dimension2Id, updateDataPoint, readonly } =
    props;
  const { style, dataPoint } = cellStyle;

  const inputId = `data-input-${cellKey}`;
  const updatedValue = isActive ? formattedValue(style, value) : styledValue(style, value);

  const [lastGoodValue, setLastGoodValue] = useState<string>(updatedValue);

  const onInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const newValue = event.target.value;
    const sendToHub = !isInputInvalid(style, newValue);
    updateDataPoint(dataPoint.dataPointId, newValue, dimension1Id, dimension2Id, sendToHub);
    if (sendToHub) {
      setLastGoodValue(newValue);
    }
  };

  const onBlur = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    if (isInputInvalid(style, event.target.value)) {
      alert(
        `The value ${event.target.value} is not valid for this numeric cell. Please provide a number in the correct format.`,
      );
      updateDataPoint(dataPoint.dataPointId, truncateValue(style, lastGoodValue), dimension1Id, dimension2Id, false);
    } else {
      updateDataPoint(
        dataPoint.dataPointId,
        truncateValue(style, event.target.value),
        dimension1Id,
        dimension2Id,
        false,
      );
    }
  };

  const inputElement =
    style === CellStyle.DataText ? (
      <textarea
        id={inputId}
        key={inputId}
        className={textClassNameForNumericValue(style, value)}
        aria-describedby={cellStyle.helpText ? `help-text-${dataPoint.dataPointId}` : undefined}
        value={updatedValue}
        onChange={onInputChange}
        onBlur={onBlur}
        disabled={readonly}
      />
    ) : (
      <input
        id={inputId}
        key={inputId}
        className={textClassNameForNumericValue(style, value)}
        aria-describedby={cellStyle.helpText ? `help-text-${dataPoint.dataPointId}` : undefined}
        value={updatedValue}
        onBlur={onBlur}
        onChange={onInputChange}
        type={inputType(style)}
        disabled={readonly}
      />
    );

  return (
    <DataPointCellWithOverlay
      {...baseCellProps(props)}
      dataPointId={dataPoint.dataPointId}
      cellKey={cellKey}
      active={isActive}
      setActiveCell={setActiveCell}
      inputId={inputId}
      helpText={cellStyle.helpText}
      overlayPosition="right"
    >
      {inputElement}
    </DataPointCellWithOverlay>
  );
};

export default DataInputCell;
