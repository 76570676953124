import { UsersResponse } from 'nrosh-common/Api/UsersApi';
import { AccessibleFeedback } from '@/Components/Form/AccessibleFeedback';
import '@/Components/SearchableDropdown/SearchableDropdown.scss';
import { SearchableDropdown } from '@/Components/SearchableDropdown/SearchableDropdown';

export type FilterUserSelectionProps = {
  value: number | undefined;
  onChange: (e: number | undefined) => void;
  providerId?: number;
  users: UsersResponse;
  required: boolean;
  validated?: boolean;
  includeRshUsers?: boolean;
  includeProviderUsers?: boolean;
};

const FilterUserSelection = (props: FilterUserSelectionProps): JSX.Element => {
  const {
    value,
    onChange,
    providerId,
    users,
    required,
    validated = false,
    includeRshUsers,
    includeProviderUsers,
  } = props;

  const providerUsers = includeProviderUsers ? users.providers.map((p) => p.users).flat() : [];

  const userOptions = [
    ...(includeRshUsers ? users.rshUsers : []),
    ...(providerId === undefined ? providerUsers : providerUsers.filter((u) => u.providerId === providerId)),
  ].map((u) => ({ value: u.id, label: u.name ? `${u.name} (${u.userName})` : u.userName }));

  return (
    <>
      <SearchableDropdown
        options={userOptions}
        currentSelection={value}
        placeholderText="Select user"
        onChange={onChange}
        isInvalid={required && validated && value === undefined}
        required={required}
      />
      {required && (
        <AccessibleFeedback displayFeedback={validated && value === undefined} id="invalid-user-select-feedback">
          Please select a user
        </AccessibleFeedback>
      )}
    </>
  );
};

export default FilterUserSelection;
