import { Survey } from 'nrosh-common/Api/SurveysApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { FormEvent, useState } from 'react';
import { Alert, Form, Stack } from 'react-bootstrap';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import FileUploadArea from '@/Components/FileUpload/FileUploadArea';
import LinkButton from '@/Components/Links/LinkButton';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { SurveysApi } from '@/Helpers/Apis';
import { surveyDisplayName } from '@/Helpers/SurveyHelper';
import useUnsavedChangesWarning from '@/Hooks/useUnsavedChangesWarning';
import { surveyPages } from '@/Pages/Home/SitePages';
import '@/Pages/Surveys/AssignSurveyUploadPage.scss';

const acceptedFileTypes = {
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.ms-excel.sheet.macroEnabled.12': ['.xlsm'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'text/csv': ['.csv'],
};

const AssignSurveyUploadPage = (): JSX.Element => {
  const { surveyId, timePeriodId } = useParams();
  const navigate = useNavigate();
  const [surveyData] = useEndpoint<Survey>(SurveysApi.getSurvey, surveyId, timePeriodId);

  const [file, setFile] = useState<File | null>(null);
  const [isSaving, setSaving] = useState(false);
  const [success, setSuccess] = useState<string | null>(null);
  const [warning, setWarning] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [setDirty, setPristine, isDirty] = useUnsavedChangesWarning();
  const [isNotifying, setIsNotifying] = useState<boolean>(false);

  if (!surveyData) {
    return <LoadingMessage />;
  }

  const timePeriodName = surveyData.instances.find((i) => i.timePeriodId === Number(timePeriodId))?.timePeriodName;
  const assignProvidersPagePath = generatePath(surveyPages.SurveyAssign.path, { surveyId, timePeriodId });

  const onSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setSuccess(null);
    setWarning(null);
    setError(null);
    setPristine();
    setSaving(true);

    const formData = new FormData();
    formData.append('file', file!);

    const response = await SurveysApi.assignSurveyUpload(surveyId!, timePeriodId!, formData, isNotifying).raw;

    if (response.status === 'succeeded') {
      const { invalidProviderCodes, isInvalidFile, totalAssigned } = response.value;

      if (invalidProviderCodes && invalidProviderCodes.length > 0) {
        setSuccess(`Successfully assigned ${totalAssigned} providers.`);
        setWarning(`Providers could not be found for the following codes: ${invalidProviderCodes.join(', ')}`);
      } else if (isInvalidFile) {
        setError('Could not read file. Please ensure that the file is correctly formatted and try again');
        setDirty();
      } else {
        navigate(assignProvidersPagePath);
        return;
      }
    } else {
      setDirty();
      // TODO-377: Look into using actual error data
      setError('Something has gone wrong');
    }

    setSaving(false);
  };

  return (
    <Stack gap={5} className="assignSurveyUploadPageContainer">
      <PageHeader
        heading="Bulk assign"
        subheading={surveyDisplayName(surveyData.surveyName, timePeriodName ?? '')}
        crumbsType={RootPathType.AdminSurveys}
        crumbs={[
          {
            name: surveyDisplayName(surveyData.surveyName, timePeriodName ?? ''),
            path: surveyPages.SurveyView.path,
          },
          {
            name: 'Assign survey',
            path: surveyPages.SurveyAssign.path,
          },
          {
            name: 'Bulk assign',
            path: surveyPages.SurveyAssignUpload.path,
          },
        ]}
      />
      <Form className="w-25 d-grid gap-4" onSubmit={onSubmit}>
        {success && (
          <Alert variant="success" dismissible onClose={() => setSuccess(null)}>
            {success}
          </Alert>
        )}
        {error && <Alert variant="danger">{error}</Alert>}
        {warning && (
          <Alert variant="warning" dismissible onClose={() => setWarning(null)}>
            {warning}
          </Alert>
        )}
        <div>
          <p>Select file</p>
          <FileUploadArea
            className="mb-3"
            aria-label="file"
            value={file}
            onChange={(selectedFile) => {
              setFile(selectedFile);
              setDirty();
            }}
            accept={acceptedFileTypes}
          />
        </div>
        <Stack gap={3}>
          <Form.Check
            type="checkbox"
            label="Notify provider users"
            id="notify-toggle"
            onChange={(e) => setIsNotifying(e.target.checked)}
            checked={isNotifying}
          />
          <div className="d-flex gap-2">
            <LinkButton to={assignProvidersPagePath} variant="outline-primary" disabled={isSaving}>
              Cancel
            </LinkButton>
            {isSaving ? (
              <LoadingButton message="Upload" />
            ) : (
              <PrimaryButton type="submit" value="upload" disabled={!isDirty}>
                Upload
              </PrimaryButton>
            )}
          </div>
        </Stack>
      </Form>
    </Stack>
  );
};

export default AssignSurveyUploadPage;
