import { allowAdminDomains } from '@/Config';

export const containsANonWhitespaceCharacterRegex = /.*\S.*/;
export const alphanumericRegex = /[a-zA-Z0-9]*/;
export const alphanumericOrSpaceRegex = /[a-zA-Z0-9\s]*/;
export const alphanumericOrUnderscoreRegex = /[a-zA-Z0-9_]*/;

// Contains a '@'
export const emailRegex = /(?=.*@).*/;
// Contains allowed domains per-environment, see .env files for list of domains
export const emailDomainRegex = new RegExp(
  allowAdminDomains.map((domain: string) => `(?=.*${domain.replaceAll('.', '\\.')}$).*`).join('|'),
);
// Contains a non-alphanumeric character and a digit
export const passwordRegex = /(?=.*[^a-zA-Z0-9])(?=.*[0-9]).*/;
export const passwordMinimumLength = 10;

export const htmlTagsRegex = /<[^>]+>/g;

export const noWhitespaceRegex = /^\S*$/;

const forbiddenPhrases = [
  'na',
  'n/a',
  'n.a.',
  'n.a',
  'n a',
  'not applicable',
  'notapplicable',
  'none',
  'as above',
  'asabove',
  'above',
  'see above',
  'seeabove',
  'no chief executive',
  'no ceo',
  'no finance director',
  'no audit committee chair',
  'no chair',
  'parent',
  'group',
  'no regulatory contact',
  'no primary regulatory contact',
  'no fees contact',
  'no fees invoice contact',
];

export const textIsForbidden = (text: string): boolean => forbiddenPhrases.includes(text.trim().toLowerCase());
