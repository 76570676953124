import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';

type Props = {
  setOverwrite: ReactStateSetter<boolean>;
};

export const UploadOptions = (props: Props): JSX.Element => {
  const { setOverwrite } = props;

  const onOptionChange = (option: string): void => {
    setOverwrite(option === 'overwrite');
  };

  return (
    <div className="radioContainer">
      <div className="radioOption">
        <input
          type="radio"
          value="keep"
          name="nullBehaviour"
          onChange={(e) => onOptionChange(e.target.value)}
          defaultChecked
        />{' '}
        Ignore blank cells
      </div>
      <div className="radioOption">
        <input type="radio" value="overwrite" name="nullBehaviour" onChange={(e) => onOptionChange(e.target.value)} />{' '}
        Overwrite existing values with blank cells
      </div>
    </div>
  );
};
