import { LoadingSpinner } from '@/Components/Loading/LoadingSpinner';

export const ImportInProgressMessage = (): JSX.Element => (
  <div className="text-center m-6">
    <LoadingSpinner />
    <div>
      <h2>An import is currently in progress for this submission part</h2>
      <p>You will be reconnected once the import has completed</p>
    </div>
  </div>
);
