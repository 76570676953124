import { nameof } from 'nrosh-common/Helpers/StringHelpers';
import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { ReactNode, useContext, useMemo, useState } from 'react';
import { createContext } from '@/Helpers/Context';

type ScreenReaderMessageContextType = {
  message: string;
  setMessage: ReactStateSetter<string>;
};

const ScreenReaderMessageContext = createContext<ScreenReaderMessageContextType>();

export const ScreenReaderMessageContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [message, setMessage] = useState('');
  const context = useMemo(() => ({ message, setMessage }), [message, setMessage]);
  return <ScreenReaderMessageContext.Provider value={context}>{children}</ScreenReaderMessageContext.Provider>;
};

export const useScreenReaderMessageContext = (): ScreenReaderMessageContextType => {
  const context = useContext(ScreenReaderMessageContext);
  if (!context) {
    throw new Error(
      `${nameof({ useScreenReaderMessageContext })} must be used within ${nameof({
        ScreenReaderMessageContextProvider,
      })}`,
    );
  }

  return context;
};
