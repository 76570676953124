import classNames from 'classnames';
import { ProviderRole, ValidationType } from 'nrosh-common/Api/Enums';
import AuthContext, { Auth } from 'nrosh-common/Contexts/AuthContext';
import { useContext } from 'react';
import { Accordion } from 'react-bootstrap';
import { NavigateFunction, generatePath, useNavigate, useParams } from 'react-router-dom';
import { HardValidationBadge, SoftValidationBadge } from '@/Components/Badges/Badge';
import { Check, CheckNrosh } from '@/Components/Icons/CheckIcon';
import Cross from '@/Components/Icons/CrossIcon';
import { useValidationModel } from '@/Components/Validations/ValidationAccordionItem';
import { useValidationsAccordionContext } from '@/Components/Validations/Validations';
import { providerPages, submissionPages } from '@/Pages/Home/SitePages';

const ValidationIcon = (props: { isApproved: boolean; isPassing: boolean }): JSX.Element => {
  const { isApproved, isPassing } = props;
  if (isPassing) {
    return <Check size="1.5rem" ariaLabel="Passing" />;
  }
  if (isApproved) {
    return <CheckNrosh size="1.5rem" ariaLabel="Approved" />;
  }
  return <Cross size="1.5rem" ariaLabel="Failing" />;
};

type SubmissionPartButtonProps = {
  navigate: NavigateFunction;
  auth: Auth;
  submissionPartId: string;
  submissionId: string | undefined;
  surveyId: string | undefined;
  timePeriodId: string | undefined;
};

const SubmissionPartButton = (props: SubmissionPartButtonProps): JSX.Element | null => {
  const { navigate, auth, submissionPartId, submissionId, surveyId, timePeriodId } = props;

  const handleClick = (): void => {
    navigate(
      auth.hasRole(ProviderRole.User)
        ? generatePath(providerPages.ProviderSubmissionPartEdit.path, {
            submissionId,
            submissionPartId,
          })
        : generatePath(submissionPages.AdminSubmissionPartEdit.path, {
            submissionId,
            surveyId,
            timePeriodId,
            submissionPartId,
          }),
    );
  };

  return (
    <div className="submissionPartButton" tabIndex={0} role="button" onClick={handleClick} onKeyDown={handleClick}>
      Go to part
    </div>
  );
};

const ValidationAccordionButton = (): JSX.Element => {
  const { submissionId, surveyId, timePeriodId } = useParams();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const validation = useValidationModel();
  const { includeLinksToParts, toggleActiveEventKey } = useValidationsAccordionContext();

  const hasLinkToPart = includeLinksToParts && !validation.isCrossPartValidation;

  return (
    <Accordion.Button
      className={classNames('py-1', 'px-2', 'gap-2', hasLinkToPart ? 'withPartsButton' : 'withoutPartsButton')}
      onClick={() => toggleActiveEventKey(validation.validationId)}
    >
      <div className="validationIconWrapper">
        <ValidationIcon isApproved={validation.isApproved} isPassing={validation.isPassing} />
      </div>
      <div className="validationId">
        {validation.type === ValidationType.Hard ? (
          <HardValidationBadge ariaLabel={`Hard validation rule ID: ${validation.displayName}`}>
            <span>HARD:</span>
            <span className="fw-normal ms-2 validationDisplayName">{validation.displayName}</span>
          </HardValidationBadge>
        ) : (
          <SoftValidationBadge ariaLabel={`Soft validation rule ID: ${validation.displayName}`}>
            <span>SOFT:</span>
            <span className="fw-normal ms-2 validationDisplayName">{validation.displayName}</span>
          </SoftValidationBadge>
        )}
      </div>
      <span className="validationShortText">{validation.shortText}</span>
      {hasLinkToPart && (
        <SubmissionPartButton
          navigate={navigate}
          auth={auth}
          submissionPartId={validation.submissionPartId}
          submissionId={submissionId}
          surveyId={surveyId}
          timePeriodId={timePeriodId}
        />
      )}
    </Accordion.Button>
  );
};

export default ValidationAccordionButton;
