import '@/Pages/Submissions/SubmissionsDetailPartCard.scss';
import { saveAs } from 'file-saver';
import { SubmissionDetailTableRow } from 'nrosh-common/Api/SubmissionsApi';
import { useState } from 'react';
import { Badge, Stack } from 'react-bootstrap';
import { generatePath } from 'react-router-dom';
import { HardValidationBadge, SoftValidationBadge } from '@/Components/Badges/Badge';
import { SecondaryButton } from '@/Components/Buttons/DCSButton';
import { useErrorReporting } from '@/Components/Errors/DCSErrorBoundary';
import DropdownArrow from '@/Components/Icons/DropdownArrow';
import LinkButton from '@/Components/Links/LinkButton';
import { SubmissionsApi } from '@/Helpers/Apis';
import { providerPages, submissionPages } from '@/Pages/Home/SitePages';

export type SubmissionsDetailPartCardProps = {
  partData: SubmissionDetailTableRow;
  isAdmin: boolean;
  readonly: boolean;
  submissionId: string | undefined;
  surveyId: string | undefined;
  timePeriodId: string | undefined;
};

// TODO DCS-314: Implement the 'Not Started' badge
const SubmissionDetailPartCardValidationIssuesBadge = (props: {
  hardValidationIssues: number;
  softValidationIssues: number;
}): JSX.Element => {
  const { hardValidationIssues, softValidationIssues } = props;

  if (hardValidationIssues === 0 && softValidationIssues === 0) {
    return (
      <Badge pill className="fw-normal fs-4" bg="dcs-status-open" text="dark">
        No validation issues
      </Badge>
    );
  }

  const getValidationLabel = (validations: number): string =>
    validations > 1 ? 'validation issues' : 'validation issue';

  return (
    <Stack direction="horizontal" gap={3}>
      {hardValidationIssues > 0 && (
        <span className="d-flex">
          <HardValidationBadge ariaLabel={`${hardValidationIssues}`}>{hardValidationIssues}</HardValidationBadge>
          <span>Hard {getValidationLabel(hardValidationIssues)}</span>
        </span>
      )}
      {softValidationIssues > 0 && (
        <span className="d-flex">
          <SoftValidationBadge ariaLabel={`${softValidationIssues}`}>{softValidationIssues}</SoftValidationBadge>
          <span>Soft {getValidationLabel(softValidationIssues)}</span>
        </span>
      )}
    </Stack>
  );
};

const SubmissionsDetailPartCard = ({
  partData,
  isAdmin,
  readonly,
  submissionId,
  surveyId,
  timePeriodId,
}: SubmissionsDetailPartCardProps): JSX.Element => {
  const { id, isTemplateUploaded } = partData;
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [raiseError] = useErrorReporting();

  const submissionPartPath = isAdmin
    ? submissionPages.AdminSubmissionPartEdit.path
    : providerPages.ProviderSubmissionPartEdit.path;
  const partImportOptionsPath = isAdmin
    ? submissionPages.AdminPartImportOptions.path
    : providerPages.ProviderPartImportOptions.path;

  const editPathParams = (): {
    surveyId?: string | undefined;
    submissionId: string | undefined;
    submissionPartId: string;
    timePeriodId?: string | undefined;
  } =>
    isAdmin
      ? {
          surveyId,
          submissionId,
          submissionPartId: id.toString(),
          timePeriodId,
        }
      : {
          submissionId,
          submissionPartId: id.toString(),
        };

  const triggerImportFileDownload = async (submissionPartId: string): Promise<void> => {
    // TODO-198: Replace this with a more best-practices compliant version (use <a href="..." download/> instead of file-saver)
    try {
      const submissionPartFile = await SubmissionsApi.getSubmissionPartImportFile(submissionPartId);
      saveAs(URL.createObjectURL(submissionPartFile.content), submissionPartFile.fileName);
    } catch {
      raiseError();
    }
  };

  return (
    <Stack gap={4} className="partCard p-4">
      <div className="d-flex justify-content-between align-items-center">
        <Stack direction="horizontal" gap={4}>
          <h2>{partData.name}</h2>
          <SubmissionDetailPartCardValidationIssuesBadge
            hardValidationIssues={partData.hardValidationIssues}
            softValidationIssues={partData.softValidationIssues}
          />
        </Stack>
        <button
          className="iconButton"
          type="button"
          aria-label={collapsed ? 'expand' : 'collapse'}
          onClick={() => setCollapsed(!collapsed)}
        >
          <DropdownArrow direction={collapsed ? 'down' : 'up'} />
        </button>
      </div>
      {!collapsed && (
        <div>
          {readonly ? (
            <Stack gap={3}>
              <p className="p-0 m-0">
                You cannot edit responses anymore because the survey has been submitted or closed.
              </p>
              <Stack direction="horizontal" gap={3}>
                <LinkButton to={generatePath(submissionPartPath, editPathParams())}>View responses</LinkButton>
                {isTemplateUploaded && (
                  <SecondaryButton type="button" onClick={() => triggerImportFileDownload(id.toString())}>
                    Download survey file
                  </SecondaryButton>
                )}
              </Stack>
            </Stack>
          ) : (
            <Stack gap={4}>
              <Stack gap={3}>
                {/* TODO DCS-314: Implement message and button text for 'Not started' survey parts. */}
                <p className="p-0 m-0">
                  {partData.hardValidationIssues + partData.softValidationIssues > 0
                    ? "To see and fix the validation issues click 'Edit survey part' below."
                    : "To see and edit answers using the DCS website click 'Edit survey part' below."}
                </p>
                <LinkButton to={generatePath(submissionPartPath, editPathParams())}>Edit survey part</LinkButton>
              </Stack>
              <Stack gap={3}>
                <p className="p-0 m-0">
                  To fill out the survey using Excel, first click &#39;Download survey part&#39;, then &#39;Upload
                  survey answers&#39; to import your answers.
                </p>
                {isTemplateUploaded && (
                  <Stack direction="horizontal" gap={3}>
                    <SecondaryButton type="button" onClick={() => triggerImportFileDownload(id.toString())}>
                      Download survey part
                    </SecondaryButton>
                    <LinkButton
                      to={generatePath(partImportOptionsPath, editPathParams())}
                      variant="outline-primary"
                      aria-label="Import"
                    >
                      Upload survey answers
                    </LinkButton>
                  </Stack>
                )}
              </Stack>
            </Stack>
          )}
        </div>
      )}
    </Stack>
  );
};

export default SubmissionsDetailPartCard;
