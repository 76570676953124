import { ArticleRequest } from 'nrosh-common/Api/NewsApi';
import { Stack } from 'react-bootstrap';
import { generatePath } from 'react-router-dom';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { NewsApi } from '@/Helpers/Apis';
import NewsTabContainer from '@/Pages/Content/ManageNews/NewsTabContainer';
import { adminPages } from '@/Pages/Home/SitePages';

const CreateNewsArticlePage = (): JSX.Element => {
  const sendCreateArticleRequest = async (
    articleRequest: ArticleRequest,
  ): Promise<{
    success: boolean;
    redirectPath?: string;
    errorMessage?: string;
  }> => {
    const response = await NewsApi.createNewsArticle(articleRequest).raw;

    if (response.ok) {
      const { articleId } = response.value;
      const redirectPath = generatePath(adminPages.AdminNewsEdit.path, { articleId: articleId.toString() });
      return { success: true, redirectPath };
    }
    const errorMessage = response.value.message;
    return { success: false, errorMessage };
  };

  return (
    <Stack gap={5}>
      <PageHeader
        heading="Add news story"
        crumbsType={RootPathType.AdminContent}
        crumbs={[
          {
            name: 'Manage news',
            path: adminPages.AdminNews.path,
          },
          { name: 'Add news story', path: adminPages.AdminNewsCreate.path },
        ]}
      />
      <NewsTabContainer sendArticleRequest={sendCreateArticleRequest} />
    </Stack>
  );
};
export default CreateNewsArticlePage;
