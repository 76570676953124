import { Form, InputGroup } from 'react-bootstrap';
import { AccessibleFeedback } from '@/Components/Form/AccessibleFeedback';
import LinkButton from '@/Components/Links/LinkButton';
import { getValidityProps } from '@/Helpers/Forms';
import { emailDomainRegex, emailRegex } from '@/Helpers/Validation';
import '@/Pages/Users/UserEmailFormControl.scss';

type UserEmailFormControlProps = {
  email: string;
  setEmail: (email: string) => void;
  hasRestrictedDomain?: boolean;
  isReadonly?: boolean;
  editLinkPath?: string | undefined;
  validated: boolean;
  isValid: boolean;
  onInvalid?: () => void;
};

export const UserEmailFormControl = ({
  email,
  setEmail,
  hasRestrictedDomain = false,
  isReadonly = false,
  editLinkPath,
  validated,
  isValid,
  onInvalid,
}: UserEmailFormControlProps): JSX.Element => (
  <Form.Group className="mb-3 w-25 userFormGroup" controlId="email">
    <Form.Label>Email</Form.Label>
    <InputGroup>
      <Form.Control
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
        minLength={6}
        maxLength={256}
        pattern={hasRestrictedDomain ? emailDomainRegex.source : emailRegex.source}
        disabled={isReadonly}
        onInvalid={() => onInvalid && onInvalid()}
        {...getValidityProps(validated && !isValid, 'emailFeedback')}
      />
      {isReadonly && editLinkPath && (
        <LinkButton className="emailFormEditButton" to={editLinkPath}>
          Edit
        </LinkButton>
      )}
    </InputGroup>
    <AccessibleFeedback displayFeedback={validated && !isValid} id="emailFeedback">
      {hasRestrictedDomain
        ? 'Please provide an email address from an DCS-approved domain'
        : 'Please provide a valid email address'}
    </AccessibleFeedback>
  </Form.Group>
);
