import { Checkbox, FormControlLabel } from '@mui/material';
import { ProviderSummary } from 'nrosh-common/Api/ProvidersApi';
import { SubmissionStatusDict } from 'nrosh-common/Api/SubmissionsApi';
import { SubmissionBySurveyId } from 'nrosh-common/Api/SurveysApi';
import { ReactStateSetter } from 'nrosh-common/Helpers/TypeHelpers';
import { ChangeEvent } from 'react';
import { Column } from 'react-table';
import '@/Pages/Surveys/AssignSurveyTable.css';
import { useTablePageResetOverride } from '@/Components/Table/PageSkipUtilities';
import Table, { CheckboxValues } from '@/Components/Table/Table';
import { caseInsensitiveSort } from '@/Helpers/TableHelper';
import { ProviderWithSubmissionStatus } from '@/Pages/Surveys/AssignSurveyPage';

type Props = {
  submissions: SubmissionBySurveyId[];
  providersList: ProviderSummary[];
  timePeriod: string;
  tableData: ProviderWithSubmissionStatus[];
  setTableData: ReactStateSetter<ProviderWithSubmissionStatus[]>;
  setDirty: () => void;
  isSurveyClosed: boolean;
};

const calculateAllChecked = (providerData: ProviderWithSubmissionStatus[]): CheckboxValues => {
  if (providerData.length === 0) {
    return CheckboxValues.Unchecked;
  }
  if (providerData.every((provider) => provider.surveyAssigned)) {
    return CheckboxValues.Checked;
  }
  if (providerData.some((provider) => provider.surveyAssigned)) {
    return CheckboxValues.Indeterminate;
  }
  return CheckboxValues.Unchecked;
};

const AssignSurveyTable: (props: Props) => JSX.Element = ({
  providersList,
  tableData,
  setTableData,
  setDirty,
  isSurveyClosed,
}) => {
  const [skipPageResetRef, , disableReset] = useTablePageResetOverride();

  const assignChangeHandler = (event: ChangeEvent<HTMLInputElement>, providerNumber: string): void => {
    disableReset();

    const isAssign = event.target.checked;

    setTableData((currentTableData) =>
      currentTableData.map((provider) =>
        provider.providerNumber === providerNumber ? { ...provider, surveyAssigned: isAssign } : provider,
      ),
    );
    setDirty();
  };

  const assignAllChangeHandler = (
    event: ChangeEvent<HTMLInputElement>,
    filteredProviders: ProviderWithSubmissionStatus[],
  ): void => {
    disableReset();

    const isBulkAssign = event.target.checked;

    setTableData((currentTableData) =>
      currentTableData.map((provider) =>
        filteredProviders.some((row) => row.providerNumber === provider.providerNumber)
          ? { ...provider, surveyAssigned: isBulkAssign }
          : provider,
      ),
    );
    setDirty();
  };

  const surveyAssignedColumnHeader = (
    allChecked: CheckboxValues,
    onClick: (e: ChangeEvent<HTMLInputElement>) => void,
  ): JSX.Element => (
    <div className="rpNumberContainer">
      <FormControlLabel
        label=""
        style={{ padding: 0, margin: 0 }}
        control={
          <Checkbox
            title="Toggle all"
            checked={allChecked === CheckboxValues.Checked}
            indeterminate={allChecked === CheckboxValues.Indeterminate}
            disableRipple
            color="nrosh"
            onChange={onClick}
            disabled={isSurveyClosed}
          />
        }
      />
      <div className="rpNumber">Provider Number</div>
    </div>
  );

  const surveyAssignedColumnCell = (value: boolean, values: Record<string, string>): JSX.Element => (
    <div className="rpNumberContainer">
      <Checkbox
        inputProps={{ 'aria-label': `Assign/unassign survey to provider: ${values.name}` }}
        checked={value}
        disableRipple
        color="nrosh"
        onChange={(event: ChangeEvent<HTMLInputElement>) => assignChangeHandler(event, values.providerNumber)}
        disabled={isSurveyClosed}
      />
      <div className="rpNumber">{values.providerNumber}</div>
    </div>
  );

  const headers: Column<ProviderWithSubmissionStatus>[] = [
    {
      accessor: 'surveyAssigned',
      Header: ({ rows }) => {
        const filteredProviders = rows.map((row) => row.values as ProviderWithSubmissionStatus);
        const onClick = (e: ChangeEvent<HTMLInputElement>): void => {
          // Make sure that the checkbox does not change state because of the click itself,
          // only when the state of the filtered providers has changed.
          e.preventDefault();
          assignAllChangeHandler(e, filteredProviders);
        };
        const allChecked = calculateAllChecked(filteredProviders);
        return surveyAssignedColumnHeader(allChecked, onClick);
      },
      Cell: ({ value, row }) => surveyAssignedColumnCell(value, row.values),
    },
    {
      Header: 'Provider Number',
      accessor: 'providerNumber',
    },
    {
      Header: 'Provider Name',
      accessor: 'name',
      sortType: caseInsensitiveSort,
    },
    {
      Header: 'Survey Status',
      accessor: (row) => row.status && SubmissionStatusDict[row.status],
    },
  ];

  return (
    <div>
      <p className="mb-2">
        Assigned providers {tableData.filter((provider) => provider.surveyAssigned).length}/{providersList.length}
      </p>
      <Table
        data={tableData}
        columns={headers}
        searchable
        paginated
        hiddenColumns={['providerNumber']}
        // An alternative export button is provided in the parent component
        exportable={false}
        rowHeadingIndex={1}
        skipPageReset={skipPageResetRef.current}
        disableSkipPageReset={disableReset}
        placeholderSearchText="Type to search providers"
      />
    </div>
  );
};

export default AssignSurveyTable;
