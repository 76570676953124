import classNames from 'classnames';
import { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { Accept, useDropzone } from 'react-dropzone';
import { NoFileDisplay, buildErrorMessage, defaultAcceptedFileTypes } from '@/Components/FileUpload/FileUploadHelpers';

type MultiFileUploadAreaProps = {
  className?: string;
  onChange: (newFiles: File[]) => void;
  disabled?: boolean;
  accept?: Accept;
  isInvalid?: boolean;
};

export const MultiFileUploadArea = ({
  className,
  onChange,
  disabled = false,
  accept = defaultAcceptedFileTypes,
  isInvalid = false,
}: MultiFileUploadAreaProps): JSX.Element => {
  const [error, setError] = useState<string | null>(null);

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: (acceptedFiles: File[]) => {
      onChange(acceptedFiles);
    },
    onDropRejected: (fileRejections) => setError(buildErrorMessage(fileRejections, accept)),
    disabled,
    multiple: true,
    accept,
  });

  const containerClass = classNames(
    'fileUploadArea',
    disabled && 'fileUploadDisabled',
    isInvalid && 'fileUploadInvalid',
    className,
  );

  return (
    <>
      <div className={containerClass} {...getRootProps()}>
        <input {...getInputProps()} aria-label="Upload Files" />
        <NoFileDisplay disabled={disabled} displayText="Upload Files" />
      </div>
      {error && (
        <Alert variant="danger" className="mt-3" dismissible onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
    </>
  );
};

export default MultiFileUploadArea;
