import { EmailRecipientsFilter } from 'nrosh-common/Api/EmailsApi';

// Should match the value in the API (class EmailScheduler)
export const emptySelectionStringIndicator = '<<NONE>>';

export enum SelectionType {
  All,
  Specific,
  None,
}

// Take the comma-separated list of numbers, parse it to an array, sort it in numerically descending order, then join to a string (with optional added spaces)
export const tidyReminderPattern = (pattern: string, addSpace: boolean = false): string => {
  if (pattern === '') return '';

  return [...new Set(pattern.split(',').map((p) => parseInt(p, 10)))].sort((a, b) => b - a).join(addSpace ? ', ' : ',');
};

export const mapSelectionToRequestString = (selectionType: SelectionType, selectionString: string): null | string => {
  if (selectionType === SelectionType.All) {
    return null;
  }

  if (selectionType === SelectionType.None || selectionString === '') {
    return emptySelectionStringIndicator;
  }

  return selectionString;
};

export const getSummaryStringComponent = (
  filter: string | null | undefined,
  singularDescription: string,
  pluralDescription: string,
): string => {
  if (filter === null || filter === undefined) {
    return `All ${pluralDescription}`;
  }

  if (filter === emptySelectionStringIndicator) {
    return '';
  }

  const numberOfSelections = filter.split(',').length;
  return `${numberOfSelections} ${numberOfSelections === 1 ? singularDescription : pluralDescription}`;
};

export const getEmailRecipientMessage = (recipientsFilter: EmailRecipientsFilter | null | undefined): string => {
  if (!recipientsFilter) return 'No Recipients Selected';

  const numberOfSurveyInstanceFilters = recipientsFilter.surveyInstanceIds?.split(',').length ?? 0;
  const numberOfSubmissionStatusFilters = recipientsFilter.submissionStatuses?.split(',').length ?? 0;
  const numberOfExcludedProviders = recipientsFilter.excludedProviderIds?.split(',').length ?? 0;
  const numberOfProviderSizes = recipientsFilter.providerSizes?.split(',').length ?? 0;
  const numberOfReportingStatuses = recipientsFilter.reportingStatuses?.split(',').length ?? 0;
  const numberOfProviderTypes = recipientsFilter.providerTypes?.split(',').length ?? 0;

  const surveyInstanceString =
    numberOfSurveyInstanceFilters === 0
      ? 'All surveys'
      : `${numberOfSurveyInstanceFilters} survey${numberOfSurveyInstanceFilters > 1 ? 's' : ''} selected`;

  const submissionStatusString =
    numberOfSubmissionStatusFilters === 0
      ? ', All submission statuses'
      : `, ${numberOfSubmissionStatusFilters} submission status${
          numberOfSubmissionStatusFilters > 1 ? 'es' : ''
        } selected`;

  const providerSizeString =
    numberOfProviderSizes === 0
      ? ', All provider sizes'
      : `, ${numberOfProviderSizes} provider size${numberOfProviderSizes > 1 ? 's' : ''} selected`;

  const reportingStatusString =
    numberOfReportingStatuses === 0
      ? ', All reporting statuses'
      : `, ${numberOfReportingStatuses} reporting status${numberOfReportingStatuses > 1 ? 'es' : ''} selected`;

  const providerTypeString =
    numberOfProviderTypes === 0
      ? ', All provider types'
      : `, ${numberOfProviderTypes} provider type${numberOfProviderTypes > 1 ? 's' : ''} selected`;

  const excludedProvidersString =
    numberOfExcludedProviders > 0
      ? `, ${numberOfExcludedProviders} provider${numberOfExcludedProviders > 1 ? 's' : ''} excluded`
      : '';

  const userProfilesString = getSummaryStringComponent(recipientsFilter.userProfiles, 'user profile', 'user profiles');

  const organisationalContactsString = getSummaryStringComponent(
    recipientsFilter.providerContactsContactTypes,
    'organisational contact',
    'organisational contacts',
  );

  const providerString = `${surveyInstanceString}${submissionStatusString}${providerSizeString}${reportingStatusString}${providerTypeString}${excludedProvidersString}`;
  let recipientString: string;
  if (userProfilesString !== '' && organisationalContactsString !== '') {
    recipientString = `${userProfilesString}, ${organisationalContactsString}`;
  } else if (userProfilesString !== '') {
    recipientString = userProfilesString;
  } else {
    recipientString = organisationalContactsString;
  }

  return `${providerString}; ${recipientString}`;
};
