import classNames from 'classnames';
import { useState } from 'react';
import { TertiaryButton } from '@/Components/Buttons/DCSButton';
import '@/Components/ExportButton/ExportButton.scss';
import Download from '@/Components/Icons/Download';

type Props = {
  label: string;
  onDownload: () => Promise<void>;
  className?: string;
};

const ExportButton = (props: Props): JSX.Element => {
  const { label, onDownload, className } = props;
  const [isDownloading, setDownloading] = useState(false);

  const onButtonClick = async (): Promise<void> => {
    setDownloading(true);
    await onDownload();
    setDownloading(false);
  };

  return (
    <TertiaryButton
      type="button"
      className={classNames(className, 'exportButton')}
      onClick={onButtonClick}
      disabled={isDownloading}
      icon={
        !isDownloading ? (
          <Download />
        ) : (
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
        )
      }
    >
      {label}
    </TertiaryButton>
  );
};

export default ExportButton;
