import classNames from 'classnames';
import { PropsWithChildren, useReducer, useRef } from 'react';
import { IconContext } from 'react-icons';
import { VscListSelection } from 'react-icons/vsc';

type FilterDropdownProps = {
  resultCount?: number;
  heading?: string;
  numberOfAppliedFilters?: number;
};

export const FilterDropdown = ({
  resultCount,
  children,
  heading,
  numberOfAppliedFilters,
}: PropsWithChildren<FilterDropdownProps>): JSX.Element => {
  const [expanded, toggleExpanded] = useReducer((prev) => !prev, false);
  const { current: vscListSelectionIconContext } = useRef({ size: '1.25rem' });

  return (
    <div className={classNames('filtersComponentContainer mb-4', { expanded })}>
      <div className="filtersTopSection d-flex">
        {heading && <h2 className="align-self-center">{heading}</h2>}
        <div className="filtersInfo d-flex ms-auto">
          {resultCount !== undefined && (
            <p className="align-self-center">
              <strong>{resultCount}</strong> {resultCount === 1 ? 'result' : 'results'}
            </p>
          )}
          <button type="button" className="filtersToggleButton d-flex" onClick={toggleExpanded}>
            <IconContext.Provider value={vscListSelectionIconContext}>
              <VscListSelection />
            </IconContext.Provider>
            Filters
            {!!numberOfAppliedFilters && ` (${numberOfAppliedFilters})`}
          </button>
        </div>
      </div>
      {expanded && <div className="filters w-100 px-4 pt-3 pb-4">{children}</div>}
    </div>
  );
};
