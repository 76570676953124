import { GetPublicDocumentResponse } from 'nrosh-common/Api/PublicDocumentsApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { FormEvent, useState } from 'react';
import { Alert, Form, Stack } from 'react-bootstrap';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { PublicDocumentsApi } from '@/Helpers/Apis';
import useUnsavedChangesWarning from '@/Hooks/useUnsavedChangesWarning';
import ManagePublicDocumentsTable from '@/Pages/Content/PublicDocuments/ManagePublicDocumentsTable';
import { adminPages } from '@/Pages/Home/SitePages';

const ManagePublicDocumentsPage = (): JSX.Element => {
  const [documentsLastUpdatedAt, setDocumentsLastUpdatedAt] = useState<Date | null>(null);
  const [documentsResponse, setDocumentsResponse] = useEndpoint<GetPublicDocumentResponse>(
    PublicDocumentsApi.getAll,
    documentsLastUpdatedAt,
  );
  const [setDirty, setPristine, isDirty] = useUnsavedChangesWarning();
  const [isSaving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);

  if (!documentsResponse) {
    return <LoadingMessage />;
  }

  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setSaving(true);

    const documentsPriorityRequest = documentsResponse.documents
      .filter((document) => document.updatedPriority === true)
      .map((document) => ({ id: document.id, priority: document.priority! }));
    const response = await PublicDocumentsApi.editDocumentsPriority({
      documentsPriorityList: documentsPriorityRequest,
    }).raw;
    if (response.ok) {
      setDocumentsLastUpdatedAt(new Date());
      setPristine();
      setError(null);
    } else {
      const errorMessage = response.value.message;
      setError(errorMessage);
    }

    setSaving(false);
  };

  return (
    <Stack gap={5}>
      <PageHeader
        heading="Manage documents"
        crumbsType={RootPathType.AdminContent}
        crumbs={[
          {
            name: 'Manage documents',
            path: adminPages.AdminPublicDocuments.path,
          },
        ]}
      />
      <Form noValidate onSubmit={onSubmit}>
        {error && (
          <Alert variant="danger" dismissible onClose={() => setError(null)}>
            {error}
          </Alert>
        )}
        <ManagePublicDocumentsTable
          documentsResponse={documentsResponse}
          setDirty={setDirty}
          setDocumentsResponse={setDocumentsResponse}
          setDocumentsLastUpdatedAt={setDocumentsLastUpdatedAt}
        />
        {!isSaving ? (
          <PrimaryButton type="submit" disabled={!isDirty}>
            Save
          </PrimaryButton>
        ) : (
          <LoadingButton message="Saving..." />
        )}
      </Form>
    </Stack>
  );
};

export default ManagePublicDocumentsPage;
