import { EmailTemplateType, SurveyReminderResponse } from 'nrosh-common/Api/EmailsApi';
import { RshRole } from 'nrosh-common/Api/Enums';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import { FormEvent, useContext, useMemo, useState } from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { generatePath, useNavigate } from 'react-router-dom';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import { AccessibleFeedback } from '@/Components/Form/AccessibleFeedback';
import LinkButton from '@/Components/Links/LinkButton';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { EmailsApi, placeHolderAPIErrorHandler } from '@/Helpers/Apis';
import { getValidityProps } from '@/Helpers/Forms';
import { checkHtmlNonEmpty } from '@/Helpers/HtmlValidityHelper';
import useUnsavedChangesWarning from '@/Hooks/useUnsavedChangesWarning';
import { ComposeForm } from '@/Pages/Emails/ComposeFormComponents';
import {
  InvalidTemplatingFeedback,
  availableTemplateFieldGroups,
  getInvalidSubjectTemplateFields,
  getMissingTemplateFields,
  getUnrecognisedTemplateFields,
  getUsedTemplateFields,
  globalTemplateFieldGroups,
  requiredTemplateFields,
} from '@/Pages/Emails/EmailTemplates';
import { tidyReminderPattern } from '@/Pages/Emails/EmailUtilities';
import { adminPages, surveyPages } from '@/Pages/Home/SitePages';

type EditSurveyReminderFormProps = {
  reminder: SurveyReminderResponse;
  isSurveyFlow: boolean;
};

const checkReminderDaysValid = (pattern: string): boolean =>
  pattern === '' || !pattern.split(',').some((p) => Number.isNaN(parseInt(p, 10)));

const templateFieldGroups = globalTemplateFieldGroups.concat(
  availableTemplateFieldGroups[EmailTemplateType.SurveyReminderDefault],
);

export const EditSurveyReminderForm = ({ reminder, isSurveyFlow }: EditSurveyReminderFormProps): JSX.Element => {
  const auth = useContext(AuthContext);

  const [validated, setValidated] = useState<boolean>(false);
  const [subject, setSubject] = useState<string>(reminder.templateSubject);
  const [body, setBody] = useState<string>(reminder.templateText);
  const [reminderDays, setReminderDays] = useState<string>(
    reminder.reminderDays === null ? '' : tidyReminderPattern(reminder.reminderDays),
  );
  const [isEnabled, setIsEnabled] = useState<boolean>(reminder.reminderDays !== null);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const navigate = useNavigate();

  const [setDirty, setPristine] = useUnsavedChangesWarning();

  const subjectTemplateFields = useMemo(() => getUsedTemplateFields(subject), [subject]);

  const invalidSubjectTemplateFields = useMemo(
    () => getInvalidSubjectTemplateFields(subjectTemplateFields),
    [subjectTemplateFields],
  );

  const usedTemplateFields = subjectTemplateFields.concat(getUsedTemplateFields(body));

  const missingTemplateFields = getMissingTemplateFields(usedTemplateFields, EmailTemplateType.SurveyReminderDefault);

  const unrecognisedTemplateFields = getUnrecognisedTemplateFields(
    usedTemplateFields,
    EmailTemplateType.SurveyReminderDefault,
  );

  const isSubjectValid = subject !== '';
  const isBodyValid = checkHtmlNonEmpty(body);
  const areReminderDaysValid = checkReminderDaysValid(reminderDays);

  const checkFormValid = (): boolean =>
    isSubjectValid &&
    isBodyValid &&
    areReminderDaysValid &&
    missingTemplateFields.length === 0 &&
    unrecognisedTemplateFields.length === 0 &&
    invalidSubjectTemplateFields.length === 0;

  const updateSubject = (newSubject: string): void => {
    setDirty();
    setSubject(newSubject);
  };

  const updateBody = (newBody: string): void => {
    setDirty();
    setBody(newBody);
  };

  const updateReminderDays = (newReminderDays: string): void => {
    setDirty();
    setReminderDays(newReminderDays);
  };

  const redirectPath = isSurveyFlow
    ? generatePath(surveyPages.SurveyView.path, {
        surveyId: reminder.surveyId.toString(),
        timePeriodId: reminder.timePeriodId.toString(),
      })
    : adminPages.AdminManageSurveyReminderEmails.path;

  const updateTemplate = async (): Promise<void> => {
    setIsSaving(true);
    setPristine();

    const tidiedReminderDays = tidyReminderPattern(reminderDays);
    if (tidiedReminderDays === '' && isEnabled) {
      setIsEnabled(false);
    }

    setReminderDays(tidiedReminderDays);

    await EmailsApi.patchSurveyReminder(reminder.surveyId.toString(), reminder.timePeriodId.toString(), {
      templateText: body,
      templateSubject: subject,
      reminderDays: tidiedReminderDays === '' || !isEnabled ? null : tidiedReminderDays,
    }).justErrors(placeHolderAPIErrorHandler);

    setIsSaving(false);
    return navigate(redirectPath);
  };

  const submitForm = (e: FormEvent<HTMLFormElement> | undefined = undefined): void => {
    e?.preventDefault();
    if (checkFormValid()) {
      setValidated(false);
      updateTemplate().catch(() => {});
    } else {
      e?.stopPropagation();
      setValidated(true);
    }
  };

  const saveButtonText = 'Save and Return';

  const readonly = !auth.hasRole(RshRole.DesignSurveys);

  return (
    <Form noValidate onSubmit={submitForm} className="w-75">
      <Form.Group className="mb-3" controlId="enable">
        <Form.Check
          key="enable"
          inline
          label="Enabled"
          name="enable"
          type="checkbox"
          id="enable"
          checked={isEnabled}
          onChange={(e) => setIsEnabled(e.target.checked)}
          disabled={readonly}
        />
      </Form.Group>
      <Form.Label htmlFor="reminder-days">Days before deadline</Form.Label>
      <Form.Group className="mb-3" controlId="reminder-days">
        <OverlayTrigger
          placement="right"
          trigger="focus"
          overlay={
            <Tooltip id="help-text-reminder-days">
              Provide a comma-separated list of the days before the deadline on which providers should receive reminder
              emails.
            </Tooltip>
          }
        >
          <Form.Control
            value={reminderDays}
            onChange={(e) => updateReminderDays(e.target.value)}
            disabled={readonly || !isEnabled}
            {...getValidityProps(
              validated && !areReminderDaysValid,
              'reminder-days-feedback',
              document.activeElement?.id === 'reminder-days' ? 'help-text-reminder-days' : undefined,
            )}
          />
        </OverlayTrigger>
        <AccessibleFeedback id="reminder-days-feedback" displayFeedback={validated && !areReminderDaysValid}>
          Please specify a valid comma-separated list of positive numbers
        </AccessibleFeedback>
      </Form.Group>
      <ComposeForm.Subject
        subject={subject}
        setSubject={updateSubject}
        isInvalid={
          validated &&
          (!isSubjectValid ||
            missingTemplateFields.length !== 0 ||
            unrecognisedTemplateFields.length !== 0 ||
            invalidSubjectTemplateFields.length !== 0)
        }
        hideInvalidMessage={isSubjectValid}
        templateFieldGroups={templateFieldGroups}
        requiredTemplateFields={requiredTemplateFields[EmailTemplateType.SurveyReminderDefault]}
        disabled={readonly}
        describedBy={
          validated &&
          (missingTemplateFields.length !== 0 ||
            unrecognisedTemplateFields.length !== 0 ||
            invalidSubjectTemplateFields.length !== 0)
            ? 'invalidTemplatingFeedback'
            : undefined
        }
      />
      <ComposeForm.Message
        htmlBody={body}
        updateBody={updateBody}
        isInvalid={
          validated && (!isBodyValid || missingTemplateFields.length !== 0 || unrecognisedTemplateFields.length !== 0)
        }
        hideInvalidMessage={isBodyValid}
        templateFieldGroups={templateFieldGroups}
        requiredTemplateFields={requiredTemplateFields[EmailTemplateType.SurveyReminderDefault]}
        disabled={readonly}
      />
      <InvalidTemplatingFeedback
        id="invalidTemplatingFeedback"
        validated={validated}
        missingTemplateFields={missingTemplateFields}
        invalidSubjectTemplateFieldsFeedback={invalidSubjectTemplateFields}
        unrecognisedTemplateFields={unrecognisedTemplateFields}
      />
      <div className="d-flex gap-2">
        <LinkButton to={redirectPath} variant="outline-primary" disabled={isSaving}>
          Cancel
        </LinkButton>
        {!isSaving ? (
          <PrimaryButton className="me-3" type="submit" disabled={readonly}>
            {saveButtonText}
          </PrimaryButton>
        ) : (
          <LoadingButton message={saveButtonText} />
        )}
      </div>
    </Form>
  );
};
