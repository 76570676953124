import { useState } from 'react';
import {
  CheckboxFilterInfo,
  DropdownFilterInfo,
  FilterType,
  FiltersComponentProps,
  SearchFilterInfo,
  SelectedCheckboxFilterInfo,
  SelectedDropdownFilterInfo,
  SelectedFilterInfo,
  SelectedSearchFilterInfo,
} from '@/Components/Filters/Filters';

type SearchFilter<T> = SearchFilterInfo & {
  filter: (item: T, filter: string) => boolean;
};

type CheckboxFilter<T> = CheckboxFilterInfo & {
  filter: (item: T, values: string[]) => boolean;
};

type DropdownFilter<T> = DropdownFilterInfo & {
  filter: (item: T, option: string) => boolean;
};

export type Filter<T> = SearchFilter<T> | CheckboxFilter<T> | DropdownFilter<T>;

type FiltersData<T> = Omit<FiltersComponentProps, 'heading'> & {
  filteredData: T[];
};

const getFilteredData = <T>(data: T[], filters: Filter<T>[], selectedFilter: SelectedFilterInfo): T[] => {
  const filter = filters.find((f) => f.id === selectedFilter.id)!;
  const { option } = selectedFilter as SelectedDropdownFilterInfo;

  switch (filter.type) {
    case FilterType.Search:
      return data.filter((d) => filter.filter(d, (selectedFilter as SelectedSearchFilterInfo).searchTerm));
    case FilterType.Checkbox:
      return data.filter((d) =>
        filter.filter(
          d,
          Object.entries((selectedFilter as SelectedCheckboxFilterInfo).options)
            .filter(([, selected]) => selected)
            .map(([value]) => value),
        ),
      );
    case FilterType.Dropdown:
      return option ? data.filter((d) => filter.filter(d, option)) : data;
    default:
      return data;
  }
};

const useFilters = <T>(data: T[], filters: Filter<T>[]): FiltersData<T> => {
  const [selectedFilter, setSelectedFilter] = useState<SelectedFilterInfo | null>(null);

  const filteredData = selectedFilter ? getFilteredData(data, filters, selectedFilter) : data;

  return {
    filteredData,
    count: filteredData.length,
    filters,
    selectedFilter,
    setSelectedFilter,
  };
};

export default useFilters;
