import { To } from 'react-router-dom';
import LinkButton from '@/Components/Links/LinkButton';
import { SubmissionConfirmation } from '@/Pages/Submissions/Confirmation/SubmissionConfirmation';

type SubmissionBackButtonProps = {
  previousPage: To;
  submissionConfirmationState?: SubmissionConfirmation;
  label?: string;
};

const SubmissionBackButton = ({
  previousPage,
  submissionConfirmationState,
  label,
}: SubmissionBackButtonProps): JSX.Element => (
  <LinkButton to={previousPage} state={submissionConfirmationState} variant="outline-primary">
    {label ?? 'Back'}
  </LinkButton>
);

export default SubmissionBackButton;
