import { Tag } from 'nrosh-common/Api/CommonTypes';
import { PublicFaq } from 'nrosh-common/Api/UnauthenticatedFaqsApi';
import { distinctBy } from 'nrosh-common/Helpers/ArrayHelpers';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { Stack } from 'react-bootstrap';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { TagsApi, UnauthenticatedFaqsApi } from '@/Helpers/Apis';
import { compareTagNamesAlphabetically } from '@/Helpers/TagHelper';
import FaqsAccordion from '@/Pages/Content/Faqs/FaqsAccordion';
import '@/Pages/Content/Faqs/FaqsPage.scss';

const FaqsPage = (): JSX.Element => {
  const [faqs] = useEndpoint<PublicFaq[]>(UnauthenticatedFaqsApi.getPublishedFaqs);
  const [tags] = useEndpoint<Tag[]>(TagsApi.getContentTags);

  if (!faqs || !tags) {
    return <LoadingMessage />;
  }

  const tagOptions = distinctBy(faqs.map((d) => d.tags).flat(), (t) => t.id)
    .sort(compareTagNamesAlphabetically)
    .map((t) => ({
      value: t.id,
      label: t.name,
    }));

  return (
    <Stack gap={5}>
      <PageHeader heading="Frequently asked questions" crumbsType={RootPathType.PublicFaqs} />
      <FaqsAccordion faqs={faqs} tagOptions={tagOptions} />
    </Stack>
  );
};

export default FaqsPage;
