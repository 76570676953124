import AuthContext from 'nrosh-common/Contexts/AuthContext';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { splashPage } from '@/Pages/Home/LoginForm';

const useRedirectWhenLoggedIn = (): void => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (auth.user) {
      navigate(splashPage(auth.user), { replace: true });
    }
  }, [auth.user]);
};

export default useRedirectWhenLoggedIn;
