import { Context, createContext as reactCreateContext } from 'react';

declare module 'react' {
  /**
   * @deprecated use custom helper.
   * Avoids needing to specify a default that should never surface (if using a custom useContext hooks that throws
   * if called outside the provider). Otherwise, the need to specify a default can force consumers to give confusing
   * defaults or use incorrect null/undefined typings.
   */
  function createContext<T>(defaultValue: T): Context<T>;
}

export function createContext<T>(defaultValue: T): Context<T>;
export function createContext<T>(defaultValue?: undefined): Context<T | undefined>;
export function createContext<T>(defaultValue?: T): unknown {
  if (defaultValue !== undefined) {
    return reactCreateContext<T>(defaultValue);
  }
  return reactCreateContext<T | undefined>(undefined);
}
