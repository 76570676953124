import {
  RegisteredDetails,
  RegisteredDetailsWithStringDate,
  mapRegisteredDetailsResponse,
} from 'nrosh-common/Api/ProvidersApi';
import { SurveyChecks } from 'nrosh-common/Api/SurveysApi';
import useEndpoint, { useEndpointConditionallyWithProcessing } from 'nrosh-common/Hooks/useEndpoint';
import { useState } from 'react';
import { Stack } from 'react-bootstrap';
import { generatePath, useLocation, useParams } from 'react-router-dom';
import LinkButton from '@/Components/Links/LinkButton';
import { LoadingSpinner } from '@/Components/Loading/LoadingSpinner';
import SubmissionPageHeader from '@/Components/PageHeader/SubmissionPageHeader';
import { MyProviderApi, ProvidersApi, SubmissionsApi } from '@/Helpers/Apis';
import { surveyDisplayName } from '@/Helpers/SurveyHelper';
import { providerPages, submissionPages } from '@/Pages/Home/SitePages';
import '@/Pages/Providers/RegisteredDetailsPage.scss';
import { RegisteredDetailsTable } from '@/Pages/Providers/RegisteredDetailsTable';
import {
  ConfirmSubmissionPage,
  useNextAndPreviousConfirmPath,
} from '@/Pages/Submissions/Confirmation/ConfirmSubmissionRouteHelpers';
import SubmissionBackButton from '@/Pages/Submissions/Confirmation/SubmissionBackButton';
import { SubmissionConfirmation } from '@/Pages/Submissions/Confirmation/SubmissionConfirmation';
import SubmissionConfirmationCheckbox from '@/Pages/Submissions/Confirmation/SubmissionConfirmationCheckbox';

const ContinueButton = ({
  nextPagePath,
  disabled,
  submissionConfirmationState,
}: {
  nextPagePath: string;
  disabled: boolean;
  submissionConfirmationState: SubmissionConfirmation;
}): JSX.Element => {
  const { submissionId } = useParams();

  return (
    <LinkButton
      to={generatePath(nextPagePath, { submissionId })}
      state={submissionConfirmationState}
      disabled={disabled}
    >
      Continue
    </LinkButton>
  );
};

const ConfirmRegisteredDetailsPage = ({ isAdmin = false }: { isAdmin?: boolean }): JSX.Element => {
  const location = useLocation();
  const locationState = location.state as SubmissionConfirmation;
  const [detailsCorrect, setDetailsCorrect] = useState(locationState.detailsCorrect ?? false);
  const [updateRequestSent, setUpdateRequestSent] = useState(locationState.updateRequestSent ?? false);
  const { submissionId } = useParams();

  const [registeredDetails] = useEndpointConditionallyWithProcessing<
    RegisteredDetailsWithStringDate,
    RegisteredDetails
  >(
    isAdmin ? ProvidersApi.getRegisteredDetails : MyProviderApi.getRegisteredDetails,
    true,
    mapRegisteredDetailsResponse,
    isAdmin ? submissionId : undefined,
  );
  const [checks] = useEndpoint<SurveyChecks>(SubmissionsApi.getEndOfSurveyChecks, submissionId);
  const { previousPage, nextPage } = useNextAndPreviousConfirmPath(
    ConfirmSubmissionPage.RegisteredDetails,
    isAdmin,
    checks,
  );

  if (!checks || !registeredDetails) {
    return <LoadingSpinner />;
  }

  const submissionConfirmationState: SubmissionConfirmation = {
    detailsCorrect,
    updateRequestSent,
    feesInvoiceEmailConfirmed: locationState.feesInvoiceEmailConfirmed,
    organisationalDetailsConfirmed: locationState.organisationalDetailsConfirmed,
  };

  return (
    <Stack gap={5}>
      <SubmissionPageHeader
        heading="Registered details"
        isRshUser={isAdmin}
        surveyDisplayName={surveyDisplayName(checks.surveyName, checks.timePeriodName)}
        providerName={registeredDetails.providerName}
        additionalRshCrumbs={[
          {
            name: 'Registered details',
            path: submissionPages.AdminConfirmRegisteredDetails.path,
          },
        ]}
        additionalProviderCrumbs={[
          {
            name: 'Registered details',
            path: providerPages.ConfirmRegisteredDetails.path,
          },
        ]}
      />
      <div>
        <p>Please check the following details we hold for your organisation.</p>
        <p>
          Please note that these details can only be updated by contacting us directly and not through the DCS system.
          If you need to update your registered name or address please email&nbsp;
          <a href="mailto:Team-DCS@softwire.com">Team-DCS@softwire.com</a>.
        </p>
        <RegisteredDetailsTable
          registeredDetails={registeredDetails}
          showEditableFields={false}
          showFullDetails={isAdmin}
        />
        <SubmissionConfirmationCheckbox
          checked={detailsCorrect}
          id="detailsCorrect"
          label="I confirm these details are correct"
          onChange={({ target: { checked } }) => {
            setDetailsCorrect(checked);
            if (checked) setUpdateRequestSent(false);
          }}
        />
        <SubmissionConfirmationCheckbox
          checked={updateRequestSent}
          id="updateRequestSent"
          label="I confirm I have sent an email to enquiries to update these details"
          onChange={({ target: { checked } }) => {
            setUpdateRequestSent(checked);
            if (checked) setDetailsCorrect(false);
          }}
        />
        <div className="mt-3 d-flex gap-2">
          <SubmissionBackButton previousPage={previousPage} submissionConfirmationState={submissionConfirmationState} />
          <ContinueButton
            nextPagePath={nextPage}
            disabled={!detailsCorrect && !updateRequestSent}
            submissionConfirmationState={submissionConfirmationState}
          />
        </div>
      </div>
    </Stack>
  );
};

export default ConfirmRegisteredDetailsPage;
