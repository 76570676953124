import { useMemo, useState } from 'react';
import { IconContext } from 'react-icons';
import { AiOutlineDownload } from 'react-icons/ai';
import '@/Components/FileDownload/FileDownloadIconButton.scss';
import { LoadingSpinner } from '@/Components/Loading/LoadingSpinner';

type FileDownloadIconButtonProps = {
  onDownload: () => Promise<void>;
  disabled?: boolean;
};

const FileDownloadIconButton = ({ onDownload, disabled = false }: FileDownloadIconButtonProps): JSX.Element => {
  const [isDownloading, setDownloading] = useState(false);

  const onButtonClick = async (): Promise<void> => {
    setDownloading(true);
    await onDownload();
    setDownloading(false);
  };

  const value = useMemo(
    () =>
      disabled
        ? { size: '1.5rem', className: 'file-download-button-disabled', color: 'grey' }
        : { size: '1.5rem', className: 'file-download-button', color: 'black' },
    [disabled],
  );

  return (
    <button
      className="blankButton"
      aria-label="Download"
      type="button"
      onClick={onButtonClick}
      disabled={disabled || isDownloading}
    >
      {!isDownloading ? (
        <IconContext.Provider value={value}>
          <AiOutlineDownload />
        </IconContext.Provider>
      ) : (
        <div className="spinner-container">
          <LoadingSpinner smallSpinner />
        </div>
      )}
    </button>
  );
};

export default FileDownloadIconButton;
