import classNames from 'classnames';
import { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { DropdownLink } from '@/Pages/Home/LayoutWithSidebar';

const DropdownLinks = (props: { pages: DropdownLink[] }): JSX.Element => {
  const { pages } = props;
  const [activeIndex, setActiveIndex] = useState<number | undefined>();

  return (
    <div className="dropdownLinksContainer">
      <ul>
        {pages.map((page, index) => (
          <li
            key={page.name}
            className={classNames({
              activeLink: index === activeIndex,
            })}
          >
            <Nav.Link key={page.path} as={Link} to={page.path} onClick={() => setActiveIndex(index)}>
              {page.name}
            </Nav.Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DropdownLinks;
