import { ScheduledEmailResponse, ScheduledEmailStatus } from 'nrosh-common/Api/EmailsApi';
import { RshRole } from 'nrosh-common/Api/Enums';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import { EmailsApi } from '@/Helpers/Apis';
import { ComposeEmailPage } from '@/Pages/Emails/Manual/ComposeEmailPage';
import { ViewEmailPage } from '@/Pages/Emails/Manual/ViewEmailPage';

export const ViewOrEditEmailPage = (): JSX.Element => {
  const { emailId } = useParams();
  const [emailData] = useEndpoint<ScheduledEmailResponse>(EmailsApi.getScheduledEmail, emailId);
  const auth = useContext(AuthContext);

  if (!emailData) {
    return <LoadingMessage />;
  }

  if (emailData.status === ScheduledEmailStatus.Draft && auth.hasRole(RshRole.SendEmails)) {
    return <ComposeEmailPage emailId={emailId!} emailData={emailData} />;
  }
  return <ViewEmailPage emailId={emailId!} emailData={emailData} />;
};
