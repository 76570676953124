import { IconProps } from '@/Components/Icons/Icons';

const GreyCheckIcon = ({ ariaHidden = false }: IconProps): JSX.Element => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden={ariaHidden}
  >
    <rect x="0.0546875" width="24" height="24" rx="5" fill="#545454" />
    <path
      d="M16.2992 7.3102C16.5099 7.10904 16.7903 6.99782 17.0813 7.00003C17.3722 7.00224 17.6509 7.1177 17.8586 7.32204C18.0662 7.52638 18.1866 7.80362 18.1943 8.09527C18.202 8.38691 18.0964 8.67015 17.8998 8.8852L11.9311 16.3702C11.8285 16.4811 11.7046 16.57 11.5669 16.6318C11.4292 16.6935 11.2805 16.7268 11.1297 16.7296C10.9789 16.7324 10.829 16.7046 10.6891 16.6481C10.5492 16.5915 10.4222 16.5072 10.3155 16.4002L6.35733 12.4312C6.2471 12.3282 6.15869 12.204 6.09737 12.066C6.03605 11.928 6.00308 11.779 6.00042 11.628C5.99776 11.4769 6.02547 11.3269 6.0819 11.1868C6.13833 11.0467 6.22231 10.9195 6.32885 10.8126C6.43539 10.7058 6.56229 10.6216 6.70199 10.565C6.84169 10.5084 6.99133 10.4807 7.14197 10.4833C7.29261 10.486 7.44118 10.519 7.5788 10.5805C7.71642 10.642 7.84029 10.7307 7.943 10.8412L11.0754 13.9807L16.2707 7.3432C16.2801 7.33166 16.2901 7.32064 16.3007 7.3102H16.2992Z"
      fill="white"
    />
  </svg>
);

export default GreyCheckIcon;
