const ManageProfiles = (): JSX.Element => {
  const fill = '#F83F78';
  return (
    <svg id="manage-profiles-icon" xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="60 60 185 185">
      <path
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5px"
        d="m152.13,123.81c-4.4-5.96-11.55-8.58-18.01-7.24l9.81,13.29-10.7,7.9-9.81-13.29c-3.18,5.77-2.78,13.38,1.62,19.34,4.56,6.18,12.09,8.77,18.73,7.07l29.75,40.29c2.01,2.73,5.86,3.31,8.58,1.3s3.3-5.85,1.29-8.58l-29.83-40.39c3.39-5.81,3.06-13.61-1.42-19.68Z"
      />
      <g>
        <path
          fill={fill}
          d="m192.58,92.11c-35.54-21.38-81.68-9.9-103.06,25.64-21.38,35.54-9.9,81.68,25.64,103.06,35.54,21.38,81.68,9.9,103.06-25.64,21.38-35.54,9.9-81.68-25.64-103.06Zm-70.53,117.23c-29.6-17.81-39.16-56.24-21.35-85.84,17.81-29.6,56.24-39.16,85.84-21.35,29.6,17.81,39.16,56.24,21.35,85.84-17.81,29.6-56.24,39.16-85.84,21.35Z"
        />
        <g>
          <rect
            fill={fill}
            x="97.15"
            y="215.16"
            width="27.52"
            height="18.73"
            transform="translate(89.68 474.05) rotate(-148.85)"
          />
          <rect
            fill={fill}
            x="64.28"
            y="177.15"
            width="27.52"
            height="18.73"
            transform="translate(-63.54 330.86) rotate(-112.85)"
          />
          <rect
            fill={fill}
            x="60.03"
            y="127.08"
            width="27.52"
            height="18.73"
            transform="translate(-75.87 177.26) rotate(-76.85)"
          />
          <rect
            fill={fill}
            x="86.02"
            y="84.07"
            width="27.52"
            height="18.73"
            transform="translate(-36.81 88.03) rotate(-40.85)"
          />
          <rect
            fill={fill}
            x="132.33"
            y="64.56"
            width="27.52"
            height="18.73"
            transform="translate(-5.73 12.62) rotate(-4.85)"
          />
          <rect
            fill={fill}
            x="181.26"
            y="75.99"
            width="27.52"
            height="18.73"
            transform="translate(72.27 -88.57) rotate(31.15)"
          />
          <rect
            fill={fill}
            x="214.13"
            y="114"
            width="27.52"
            height="18.73"
            transform="translate(253.09 -134.54) rotate(67.15)"
          />
          <rect
            fill={fill}
            x="218.38"
            y="164.07"
            width="27.52"
            height="18.73"
            transform="translate(453.84 -13.15) rotate(103.15)"
          />
          <rect
            fill={fill}
            x="192.39"
            y="207.08"
            width="27.52"
            height="18.73"
            transform="translate(503.65 245.34) rotate(139.15)"
          />
          <rect
            fill={fill}
            x="146.08"
            y="226.59"
            width="27.52"
            height="18.73"
            transform="translate(339.05 457.56) rotate(175.15)"
          />
        </g>
      </g>
    </svg>
  );
};
export default ManageProfiles;
