import { Provider, ProviderWithStringDate, mapProviderResponse } from 'nrosh-common/Api/ProvidersApi';
import { SurveyChecks } from 'nrosh-common/Api/SurveysApi';
import useEndpoint, { useEndpointConditionallyWithProcessing } from 'nrosh-common/Hooks/useEndpoint';
import { Stack } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import { LoadingSpinner } from '@/Components/Loading/LoadingSpinner';
import SubmissionPageHeader from '@/Components/PageHeader/SubmissionPageHeader';
import { MyProviderApi, ProvidersApi, SubmissionsApi } from '@/Helpers/Apis';
import { surveyDisplayName } from '@/Helpers/SurveyHelper';
import useSubmissionDetail from '@/Hooks/useSubmissionDetail';
import { providerPages, submissionPages } from '@/Pages/Home/SitePages';
import ConfirmFeesInvoiceForm from '@/Pages/Submissions/Confirmation/ConfirmFeesInvoiceForm';
import { SubmissionConfirmation } from '@/Pages/Submissions/Confirmation/SubmissionConfirmation';

const ConfirmFeesInvoicePage = ({ isAdmin = false }: { isAdmin?: boolean }): JSX.Element => {
  const location = useLocation();
  const submissionConfirmationState = location.state as SubmissionConfirmation;
  const { submissionId } = useParams();
  const [data] = useSubmissionDetail(submissionId!, isAdmin);
  const [checks] = useEndpoint<SurveyChecks>(SubmissionsApi.getEndOfSurveyChecks, submissionId);
  const [provider] = useEndpointConditionallyWithProcessing<ProviderWithStringDate, Provider>(
    isAdmin ? ProvidersApi.getProviderFromSubmission : MyProviderApi.getProvider,
    true,
    mapProviderResponse,
    submissionId,
  );

  if (!checks || !provider || (isAdmin && !data)) {
    return <LoadingSpinner />;
  }

  return (
    <Stack gap={5}>
      <SubmissionPageHeader
        heading="Fees invoice email"
        isRshUser={isAdmin}
        surveyDisplayName={surveyDisplayName(checks.surveyName, checks.timePeriodName)}
        providerName={data?.provider.name ?? ''}
        additionalRshCrumbs={[
          {
            name: 'Fees invoice email',
            path: submissionPages.AdminConfirmFeesInvoiceEmail.path,
          },
        ]}
        additionalProviderCrumbs={[{ name: 'Fees invoice email', path: providerPages.ConfirmFeesInvoiceEmail.path }]}
      />
      <div>
        <p>Please check the fees invoice email we hold for your organisation and update if necessary.</p>
      </div>
      <ConfirmFeesInvoiceForm
        provider={provider}
        submissionConfirmationState={submissionConfirmationState}
        checks={checks}
        isAdmin={isAdmin}
      />
    </Stack>
  );
};

export default ConfirmFeesInvoicePage;
