import { SurveyInstance, SurveyInstanceWithStringDate, mapSurveyInstanceResponse } from 'nrosh-common/Api/SurveysApi';
import { useEndpointConditionallyWithProcessing } from 'nrosh-common/Hooks/useEndpoint';
import { useState } from 'react';
import { Stack } from 'react-bootstrap';
import { generatePath, useParams } from 'react-router-dom';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import SurveyForm from '@/Components/SurveyForm/SurveyForm';
import { SurveysApi } from '@/Helpers/Apis';
import { surveyDisplayName } from '@/Helpers/SurveyHelper';
import { surveyPages } from '@/Pages/Home/SitePages';

const EditSurveyPage = (): JSX.Element => {
  const { surveyId, timePeriodId } = useParams();
  const [dataLastUploadedAt, setDataLastUploadedAt] = useState<Date | null>(null);
  const [surveyData] = useEndpointConditionallyWithProcessing<SurveyInstanceWithStringDate, SurveyInstance>(
    SurveysApi.getSurveyInstance,
    true,
    mapSurveyInstanceResponse,
    surveyId,
    timePeriodId,
    dataLastUploadedAt,
  );

  const onSave = async (
    updatedSurveyData: SurveyInstance,
    notify: boolean,
  ): Promise<{ success: boolean; errorMessage?: string }> => {
    const editRequest = {
      name: updatedSurveyData.name.trim(),
      parts: updatedSurveyData.parts.map((part) => ({ id: part.surveyPartId, name: part.name.trim() })),
      // We should have passed validation before this is called, so ok to assume deadline is set
      defaultSubmissionDeadline: updatedSurveyData.defaultSubmissionDeadline!,
      description: updatedSurveyData.description ? updatedSurveyData.description.trim() : null,
    };
    const response = await SurveysApi.editSurveyInstance(editRequest, surveyId!, timePeriodId!, notify).raw;
    if (response.ok) {
      setDataLastUploadedAt(new Date());
      return { success: true };
    }
    const errorMessage = response.value.message;
    return { success: false, errorMessage };
  };

  if (!surveyData) {
    return <LoadingMessage />;
  }

  const surveyPath = generatePath(surveyPages.SurveyView.path, { surveyId, timePeriodId });

  return (
    <Stack gap={5}>
      <PageHeader
        heading="Edit survey information"
        crumbsType={RootPathType.AdminSurveys}
        crumbs={[
          {
            name: surveyDisplayName(surveyData.name, surveyData.timePeriodName),
            path: surveyPages.SurveyView.path,
          },
          { name: 'Edit survey information', path: surveyPages.SurveyEdit.path },
        ]}
      />
      <SurveyForm
        initialValues={surveyData}
        onSave={(si, notify) => onSave(si, notify)}
        pathOnCancel={surveyPath}
        isCreate={false}
      />
    </Stack>
  );
};

export default EditSurveyPage;
