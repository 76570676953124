import classNames from 'classnames';
import { CellStyle } from 'nrosh-common/Api/Enums';
import { ReactNode, memo } from 'react';
import { BaseCellProps, CellContent } from '@/Components/Spreadsheet/Cells/CellHelpers';
import { freezePaneClassName, styleClassName } from '@/Components/Spreadsheet/Cells/CellStyleHelpers';

const TextCell = (props: BaseCellProps & { children?: ReactNode }): JSX.Element => {
  const { style, height, rowSpan, columnSpan, left, top, children } = props;

  return (
    <td
      className={classNames(styleClassName(style), freezePaneClassName(props))}
      rowSpan={rowSpan ?? 1}
      colSpan={columnSpan ?? 1}
      style={{ left, top }}
      aria-hidden={style === CellStyle.Hidden}
    >
      <CellContent height={height}>{children}</CellContent>
    </td>
  );
};

export const TextContentCell = memo((props: BaseCellProps & { content: string }) => (
  <TextCell {...props}>
    <span>{props.content}</span>
  </TextCell>
));

export default TextCell;
