import ApiClient, { APIHelpers, handleAPIRequest } from './ApiClient';
import { SubmissionStatus, ValidationType } from './Enums';
import { SurveyStatus } from './SubmissionsApi';

export type DataItem = {
  id: string;
  section: string;
  type: string;
  description: string;
};

export type SubmissionSummaryResponse = {
  submissionId: number;
  providerId: number;
  providerNumber: string;
  submissionStatus: SubmissionStatus;
};

export type SurveyTimePeriod = {
  timePeriodId: number;
  timePeriodName: string;
  surveyStatus: SurveyStatus;
  statusCounts: Record<SubmissionStatus, number | undefined>;
  defaultDeadline: string;
};

export type Survey = {
  surveyId: number;
  surveyName: string;
  frequency: SurveyFrequency;
  instances: SurveyTimePeriod[];
};

export type Surveys = {
  surveys: Survey[];
};

export type SurveyInstancePart = {
  surveyPartId: number;
  timePeriodId: number;
  name: string;
  hasDataDictionary: boolean;
  hasUploadedImportTemplate: boolean;
  isOnlyInstanceOfPart: boolean;
};

export type SurveyCrossPartValidation = {
  validationId: string;
  validationFormula: string;
  description: string;
  type: ValidationType;
  shortText: string;
};

// We need this type because JSON dates are just strings, and so this is the response given by the API.
export type SurveyInstanceWithStringDate = Omit<SurveyInstance, 'defaultSubmissionDeadline'> & {
  defaultSubmissionDeadline: string;
};

export type SurveyInstance = {
  surveyId?: number;
  timePeriodId: number | null;
  name: string;
  timePeriodName: string;
  frequency: SurveyFrequency | null;
  parts: SurveyInstancePart[];
  // Tech-debt: We're only allowing null values here for convenience - doing so allows us to
  // use this type for the state within the survey form, otherwise we would need another,
  // very similar, type.
  defaultSubmissionDeadline: Date | null;
  surveyStatus: SurveyStatus;
  crossPartValidations: SurveyCrossPartValidation[];
  description?: string | null;
  isSurveyInstanceConfigured: boolean;
  isAssigned: boolean;
  isDeletable: boolean;
};

export const mapSurveyInstanceResponse = (response: SurveyInstanceWithStringDate): SurveyInstance => {
  return {
    ...response,
    defaultSubmissionDeadline: new Date(response.defaultSubmissionDeadline),
  };
};

export type SurveyInstanceId = {
  surveyId: number;
  timePeriodId: number;
};

export type CreateSurveyRequest = {
  name: string;
  frequency: string;
  initialTimePeriodId: number;
  partNames: string[];
  defaultSubmissionDeadline: Date;
};

export type EditSurveyInstanceRequest = {
  name: string;
  parts: { id?: number; name: string }[];
  defaultSubmissionDeadline: Date;
  description?: string | null;
};

export type AddNewSurveyInstancePartRequest = {
  name: string;
};

export type SubmissionBySurveyId = {
  submissionId: number;
  providerName: string;
  providerNumber: string;
  isTestProvider: boolean;
  timePeriodName: string;
  timePeriodId?: number;
  status: SubmissionStatus;
  surveyName: string;
};

export type TimePeriod = {
  id: number;
  name: string;
  timePeriodType: string;
  startDate: Date;
  endDate: Date;
};

export type TimePeriodWithStringDates = {
  id: number;
  name: string;
  timePeriodType: string;
  startDate: string;
  endDate: string;
};

export type NewSurveyInstanceRequest = {
  timePeriodId: number;
  copyFromTimePeriodId: number;
  defaultSubmissionDeadline: Date;
};

export enum SurveyFrequency {
  Yearly = 'Yearly',
  Quarterly = 'Quarterly',
  Monthly = 'Monthly',
}

export enum ContactType {
  ChiefExecutive = 'ChiefExecutive',
  Chair = 'Chair',
  FinanceDirector = 'FinanceDirector',
  AuditCommitteeChair = 'AuditCommitteeChair',
  RegulatoryContact = 'RegulatoryContact',
  FinancialReturnsSubmissionPerson = 'FinancialReturnsSubmissionPerson',
  FinancialSurveyDataEntryContact = 'FinancialSurveyDataEntryContact',
  FeesInvoiceContact = 'FeesInvoiceContact',
}

export type SurveyChecks = {
  surveyId: number;
  surveyName: string;
  surveyStatus: SurveyStatus;
  timePeriodId: number | null;
  timePeriodName: string;
  confirmRegisteredDetails: boolean;
  confirmFeesInvoiceEmail: boolean;
  organisationalDetailsToConfirm: ContactType[];
  surveyDeclarations: SurveyDeclarationResponse[] | undefined;
};

export type SurveyDeclaration = {
  orderIndex: number;
  declaration: string;
};

export type SurveyDeclarationResponse = {
  id: number;
  orderIndex: number;
  declaration: string;
};

export type EditSurveyChecksRequest = {
  confirmRegisteredDetails: boolean;
  confirmFeesInvoiceEmail: boolean;
  organisationalDetailsToConfirm: ContactType[];
  surveyDeclarations: string[];
};

type SaveCrossPartValidationRequest = {
  validationId: string;
  validationFormula: string;
  description: string;
  type: ValidationType;
  shortText: string;
};

type SaveCrossPartValidationsRequest = {
  validations: SaveCrossPartValidationRequest[];
};

export type BulkAssignProviderResponse = {
  invalidProviderCodes?: string[];
  isInvalidFile: boolean;
  totalAssigned: number;
};

const SurveysApiRoot = 'Surveys';
export const SurveysApiPaths = {
  SURVEYS: SurveysApiRoot,
  TIME_PERIODS: 'TimePeriods',
  CREATE_SURVEY: `${SurveysApiRoot}/Create`,
  CREATE_SURVEY_INSTANCE: (surveyId: string) => `${SurveysApiRoot}/${surveyId}/CreateInstance`,
  SURVEY: (surveyId: string) => `${SurveysApiRoot}/${surveyId}`,
  SURVEY_SUBMISSIONS: (surveyId: string) => `${SurveysApiRoot}/${surveyId}/Submissions`,
  SURVEY_INSTANCE: (surveyId: string, timePeriodId: string) =>
    `${SurveysApiRoot}/${surveyId}/TimePeriods/${timePeriodId}`,
  ASSIGN_SURVEY: (surveyId: string, timePeriodId: string) =>
    `${SurveysApiRoot}/${surveyId}/TimePeriods/${timePeriodId}/Assign`,
  ASSIGN_SURVEY_UPLOAD: (surveyId: string, timePeriodId: string) =>
    `${SurveysApiRoot}/${surveyId}/TimePeriods/${timePeriodId}/Assign/Upload`,
  DOWNLOAD_ASSIGNED_PROVIDERS: (surveyId: string, timePeriodId: string) =>
    `${SurveysApiRoot}/${surveyId}/TimePeriods/${timePeriodId}/Assign/Download`,
  END_OF_SURVEY_CHECKS: (surveyId: string, timePeriodId: string) =>
    `${SurveysApiRoot}/${surveyId}/TimePeriods/${timePeriodId}/Checks`,
  SAVE_CROSS_PART_VALIDATIONS: (surveyId: string, timePeriodId: string) =>
    `${SurveysApiRoot}/${surveyId}/TimePeriods/${timePeriodId}/CrossPartValidations`,
  CLOSE_SURVEY: (surveyId: string, timePeriodId: string) =>
    `${SurveysApiRoot}/${surveyId}/TimePeriods/${timePeriodId}/Close`,
  MARK_SURVEY_AS_IN_DEVELOPMENT: (surveyId: string, timePeriodId: string) =>
    `${SurveysApiRoot}/${surveyId}/TimePeriods/${timePeriodId}/MarkAsInDevelopment`,
  OPEN_SURVEY: (surveyId: string, timePeriodId: string) =>
    `${SurveysApiRoot}/${surveyId}/TimePeriods/${timePeriodId}/Open`,
  ADD_NEW_PART_TO_SURVEY_INSTANCE: (surveyId: string, timePeriodId: string) =>
    `${SurveysApiRoot}/${surveyId}/TimePeriods/${timePeriodId}/Parts`,
};

const surveysApi = (apiClient: ApiClient) => ({
  getSurveys: () => {
    return handleAPIRequest(apiClient.get(SurveysApiPaths.SURVEYS), APIHelpers.json<Surveys>, APIHelpers.standardError);
  },

  getTimePeriods: () => {
    return handleAPIRequest(
      apiClient.get(SurveysApiPaths.TIME_PERIODS),
      APIHelpers.json<TimePeriodWithStringDates[]>,
      APIHelpers.standardError,
    );
  },

  createSurvey: (survey: CreateSurveyRequest) => {
    return handleAPIRequest(
      apiClient.post(SurveysApiPaths.CREATE_SURVEY, survey),
      APIHelpers.json<SurveyInstanceId>,
      APIHelpers.standardError,
    );
  },

  createSurveyInstance: (surveyId: string, request: NewSurveyInstanceRequest) => {
    return handleAPIRequest(
      apiClient.post(SurveysApiPaths.CREATE_SURVEY_INSTANCE(surveyId), request),
      APIHelpers.json<SurveyInstanceId>,
      APIHelpers.standardError,
    );
  },

  getSurvey: (surveyId: string) => {
    return handleAPIRequest(
      apiClient.get(SurveysApiPaths.SURVEY(surveyId)),
      APIHelpers.json<Survey>,
      APIHelpers.standardError,
    );
  },

  getSubmissionsBySurveyId: (surveyId: string) => {
    return handleAPIRequest(
      apiClient.get(SurveysApiPaths.SURVEY_SUBMISSIONS(surveyId)),
      APIHelpers.json<SubmissionBySurveyId[]>,
      APIHelpers.standardError,
    );
  },

  getSurveyInstance: (surveyId: string, timePeriodId: string) => {
    return handleAPIRequest(
      apiClient.get(SurveysApiPaths.SURVEY_INSTANCE(surveyId, timePeriodId)),
      APIHelpers.json<SurveyInstanceWithStringDate>,
      APIHelpers.standardError,
    );
  },

  editSurveyInstance: (survey: EditSurveyInstanceRequest, surveyId: string, timePeriodId: string, notify: boolean) => {
    return handleAPIRequest(
      apiClient.patch(SurveysApiPaths.SURVEY_INSTANCE(surveyId, timePeriodId), survey, { notify: notify }),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  deleteSurveyInstance: (surveyId: string, timePeriodId: string) => {
    return handleAPIRequest(
      apiClient.deleteAction(SurveysApiPaths.SURVEY_INSTANCE(surveyId, timePeriodId)),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  assignSurvey: (surveyId: string, timePeriodId: string, providerIds: number[], notify: boolean) => {
    return handleAPIRequest(
      apiClient.patch(SurveysApiPaths.ASSIGN_SURVEY(surveyId, timePeriodId), providerIds, { notify: notify }),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  assignSurveyUpload: (surveyId: string, timePeriodId: string, formData: FormData, notify: boolean) => {
    return handleAPIRequest(
      apiClient.patchFile(SurveysApiPaths.ASSIGN_SURVEY_UPLOAD(surveyId, timePeriodId), formData, { notify: notify }),
      APIHelpers.json<BulkAssignProviderResponse>,
      APIHelpers.standardError,
    );
  },

  downloadAssignedProviders: (surveyId: string, timePeriodId: string) => {
    return apiClient.getFile(SurveysApiPaths.DOWNLOAD_ASSIGNED_PROVIDERS(surveyId, timePeriodId));
  },

  getEndOfSurveyChecks: (surveyId: string, timePeriodId: string) => {
    return handleAPIRequest(
      apiClient.get(SurveysApiPaths.END_OF_SURVEY_CHECKS(surveyId, timePeriodId)),
      APIHelpers.json<SurveyChecks>,
      APIHelpers.standardError,
    );
  },

  editEndOfSurveyChecks: (checks: EditSurveyChecksRequest, surveyId: string, timePeriodId: string) => {
    return handleAPIRequest(
      apiClient.patch(SurveysApiPaths.END_OF_SURVEY_CHECKS(surveyId, timePeriodId), checks),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  saveCrossPartValidations: (surveyId: string, timePeriodId: string, body: SaveCrossPartValidationsRequest) => {
    return handleAPIRequest(
      apiClient.put(SurveysApiPaths.SAVE_CROSS_PART_VALIDATIONS(surveyId, timePeriodId), body),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  closeSurvey: (surveyId: string, timePeriodId: string) => {
    return handleAPIRequest(
      apiClient.patch(SurveysApiPaths.CLOSE_SURVEY(surveyId, timePeriodId), null),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  markSurveyAsInDevelopment: (surveyId: string, timePeriodId: string) => {
    return handleAPIRequest(
      apiClient.patch(SurveysApiPaths.MARK_SURVEY_AS_IN_DEVELOPMENT(surveyId, timePeriodId), null),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  openSurvey: (surveyId: string, timePeriodId: string) => {
    return handleAPIRequest(
      apiClient.patch(SurveysApiPaths.OPEN_SURVEY(surveyId, timePeriodId), null),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },

  addNewPartToSurveyInstance: (surveyId: string, timePeriodId: string, request: AddNewSurveyInstancePartRequest) => {
    return handleAPIRequest(
      apiClient.post(SurveysApiPaths.ADD_NEW_PART_TO_SURVEY_INSTANCE(surveyId, timePeriodId), request),
      APIHelpers.none,
      APIHelpers.standardError,
    );
  },
});

export default surveysApi;
