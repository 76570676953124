import {
  ActiveCellContextProvider,
  ActiveCellData,
  useActiveCellContext,
} from '@/Pages/Submissions/SubmissionPartContext/ActiveCellContext';
import {
  SlicerValuesContextProvider,
  SlicerValuesData,
  useSlicerValuesContext,
} from '@/Pages/Submissions/SubmissionPartContext/SlicerValuesContext';
import {
  SubmissionPartLiveData,
  SubmissionPartLiveDataContextProvider,
  SubmissionPartLiveDataContextProviderProps,
  useSubmissionPartLiveData,
} from '@/Pages/Submissions/SubmissionPartContext/SubmissionPartLiveDataContext';
import {
  SubmissionPartStaticData,
  SubmissionPartStaticDataContextProviderProps,
  SubmissionPartStaticDataProvider,
  useSubmissionPartStaticData,
} from '@/Pages/Submissions/SubmissionPartContext/SubmissionPartStaticDataContext';

type SubmissionPartContextProviderProps = SubmissionPartStaticDataContextProviderProps &
  SubmissionPartLiveDataContextProviderProps;

const SubmissionPartContextProvider = ({
  submissionPartId,
  submissionId,
  isAdmin,
  children,
}: SubmissionPartContextProviderProps): JSX.Element => (
  // Tech-debt: This is a bit of a mess, with many nested context providers. This may need a rethink on a structural level
  <ActiveCellContextProvider>
    <SubmissionPartStaticDataProvider submissionPartId={submissionPartId} submissionId={submissionId} isAdmin={isAdmin}>
      <SubmissionPartLiveDataContextProvider submissionPartId={submissionPartId}>
        <SlicerValuesContextProvider>{children}</SlicerValuesContextProvider>
      </SubmissionPartLiveDataContextProvider>
    </SubmissionPartStaticDataProvider>
  </ActiveCellContextProvider>
);

type SubmissionPartData = SubmissionPartStaticData & SubmissionPartLiveData & SlicerValuesData & ActiveCellData;
export const useSubmissionPartData = (): SubmissionPartData => {
  const staticData = useSubmissionPartStaticData();
  const liveData = useSubmissionPartLiveData();
  const slicerValuesData = useSlicerValuesContext();
  const activeCellData = useActiveCellContext();

  return { ...staticData, ...liveData, ...slicerValuesData, ...activeCellData };
};

export default SubmissionPartContextProvider;
