import Color from '@tiptap/extension-color';
import Highlight from '@tiptap/extension-highlight';
import Link from '@tiptap/extension-link';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import StarterKit from '@tiptap/starter-kit';

export const contentEditorExtensions = [
  StarterKit,
  Underline,
  Highlight,
  Link.configure({
    openOnClick: false,
  }),
  TableRow,
  TableHeader,
  TableCell,
  Table.configure({ resizable: true, HTMLAttributes: { class: 'contentTable' } }),
  TextAlign.configure({ types: ['heading', 'paragraph'] }),
  Color.configure({ types: ['textStyle'] }),
  TextStyle,
];
