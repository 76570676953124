import { saveAs } from 'file-saver';
import { ValidationType } from 'nrosh-common/Api/Enums';
import { SubmissionDetail, SubmissionDetailTableRow, SurveyStatus } from 'nrosh-common/Api/SubmissionsApi';
import { DataDictionaryResponse, DataPoint } from 'nrosh-common/Api/SurveyPartsApi';
import { useEffect, useState } from 'react';
import { Alert, Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import PageHeadingWithSurveyStatusBadge from '@/Components/Badges/PageHeadingWithSurveyStatusBadge';
import { DCSErrorContextOutlet, useErrorReporting } from '@/Components/Errors/DCSErrorBoundary';
import ExportButton from '@/Components/ExportButton/ExportButton';
import LinkButton from '@/Components/Links/LinkButton';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import ValidationIssues from '@/Components/Validations/ValidationIssues';
import { ValidationModel } from '@/Components/Validations/ValidationTypes';
import Validations from '@/Components/Validations/Validations';
import { SubmissionsApi } from '@/Helpers/Apis';
import { providerDisplayName } from '@/Helpers/ProviderHelper';
import { surveyDisplayName } from '@/Helpers/SurveyHelper';
import {
  ConfirmSubmissionPage,
  useNextAndPreviousConfirmPath,
} from '@/Pages/Submissions/Confirmation/ConfirmSubmissionRouteHelpers';
import { submissionIsReadonly } from '@/Pages/Submissions/SubmissionHelpers';

const getDataPointsForPart = async (part: SubmissionDetailTableRow): Promise<DataPoint[]> => {
  const response = await SubmissionsApi.getDataDictionary(part.id.toString()).raw;
  const dataDictionary = response.value as DataDictionaryResponse;
  return dataDictionary.dataPoints;
};

const getAllDataPointsForSubmission = async (parts: SubmissionDetailTableRow[]): Promise<DataPoint[]> =>
  (await Promise.all(parts.map(getDataPointsForPart))).flat();

type ValidationsAlertText = {
  hasHardValidationIssues: boolean;
  hasSoftValidationIssues: boolean;
  onFailuresAlertText: string;
};

const ValidationsAlert = ({
  hasHardValidationIssues,
  hasSoftValidationIssues,
  onFailuresAlertText,
}: ValidationsAlertText): JSX.Element => {
  if (hasHardValidationIssues) {
    return <Alert variant="danger">{onFailuresAlertText}</Alert>;
  }
  if (hasSoftValidationIssues) {
    return (
      <Alert variant="warning">
        You have outstanding soft validation issues. Please ensure you have commented on these or have uploaded a
        supporting document before continuing to declarations.
      </Alert>
    );
  }
  return (
    <Alert variant="success">
      All hard validation issues have been resolved or approved. You can now continue to declarations to complete the
      submission of your return.
    </Alert>
  );
};

type SubmissionValidationsViewProps = {
  validations: ValidationModel[];
  submissionData: SubmissionDetail;
  onFailuresAlertText?: string;
  exportButtonText: string;
  canSubmit?: boolean;
  isAdmin?: boolean;
  commentsAreReadonly?: boolean;
  updateComment?: (validationId: string, comment: string) => void;
  deleteComment?: (validationId: string) => void;
  updateValidationApprovalStatus?: (validationId: string, isApproved: boolean) => void;
};

const SubmissionValidationsView = ({
  validations,
  onFailuresAlertText,
  exportButtonText,
  updateComment,
  deleteComment,
  updateValidationApprovalStatus,
  submissionData,
  commentsAreReadonly = false,
  canSubmit = false,
  isAdmin = false,
}: SubmissionValidationsViewProps): JSX.Element => {
  const { submissionId } = useParams();
  const [dataPoints, setDataPoints] = useState<DataPoint[] | null>(null);
  const [raiseError] = useErrorReporting();
  const { previousPage: backToSurveyPath, nextPage } = useNextAndPreviousConfirmPath(
    ConfirmSubmissionPage.ValidationIssues,
    isAdmin,
  );

  useEffect(() => {
    const fetchAndSetDataPoints = async (): Promise<void> => {
      setDataPoints(await getAllDataPointsForSubmission(submissionData.parts));
    };
    fetchAndSetDataPoints().catch(() => {});
  }, [submissionData]);

  if (!dataPoints) {
    return <LoadingMessage />;
  }

  const readonly = submissionIsReadonly(submissionData.status, isAdmin, submissionData.surveyStatus);

  const downloadValidations = async (): Promise<void> => {
    try {
      const validationsBlob = await SubmissionsApi.downloadSubmissionValidationIssues(submissionId!, false);
      saveAs(
        URL.createObjectURL(validationsBlob),
        `${submissionData.surveyName}_${submissionData.timePeriodName}-Validations.csv`.replaceAll(' ', '_'),
      );
    } catch {
      raiseError();
    }
  };

  const hasValidationIssues = (validationType: ValidationType): boolean =>
    Boolean(validations.find((v) => v.type === validationType));

  const hasHardValidationIssues = hasValidationIssues(ValidationType.Hard);
  const hasSoftValidationIssues = hasValidationIssues(ValidationType.Soft);

  const isSurveyClosed = submissionData.surveyStatus === SurveyStatus.Closed;

  return (
    <>
      <PageHeadingWithSurveyStatusBadge
        title={`${surveyDisplayName(submissionData.surveyName, submissionData.timePeriodName)} - ${providerDisplayName(
          submissionData.provider.name,
          submissionData.provider.providerNumber,
        )}`}
        status={submissionData.surveyStatus}
      />
      {canSubmit && onFailuresAlertText && !isSurveyClosed && (
        <ValidationsAlert
          hasHardValidationIssues={hasHardValidationIssues}
          hasSoftValidationIssues={hasSoftValidationIssues}
          onFailuresAlertText={onFailuresAlertText}
        />
      )}
      <DCSErrorContextOutlet />
      <Stack direction="horizontal" gap={3} className="mb-3 align-items-center">
        <ValidationIssues validations={validations} />
        <LinkButton to={backToSurveyPath} className="ms-auto" variant="outline-primary">
          Return to Survey
        </LinkButton>
        <ExportButton label={exportButtonText} onDownload={downloadValidations} />
        {canSubmit && !isSurveyClosed && (
          <LinkButton to={nextPage} disabled={hasHardValidationIssues}>
            Continue to Declarations
          </LinkButton>
        )}
      </Stack>
      <Validations
        validations={validations}
        dataPoints={dataPoints}
        includeLinksToParts
        commentsAreReadonly={commentsAreReadonly || readonly}
        updateComment={updateComment}
        deleteComment={deleteComment}
        updateValidationApprovalStatus={updateValidationApprovalStatus}
        isAdmin={isAdmin}
        surveyStatus={submissionData.surveyStatus}
        downloadValidations={downloadValidations}
      />
    </>
  );
};

export default SubmissionValidationsView;
