import { GetUnauthenticatedNewsArticleResponse } from 'nrosh-common/Api/UnauthenticatedNewsApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import { Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import ContentDisplay from '@/Components/ContentDisplay/ContentDisplay';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { UnauthenticatedNewsApi } from '@/Helpers/Apis';
import { formatISODateString } from '@/Helpers/DateHelpers';
import RelatedArticles from '@/Pages/Content/News/RelatedArticles';
import { publicPages } from '@/Pages/Home/SitePages';
import '@/Pages/Content/News/ArticlePage.scss';

const ArticlePage = (): JSX.Element => {
  const { articleId } = useParams();
  const [newsArticle] = useEndpoint<GetUnauthenticatedNewsArticleResponse>(
    UnauthenticatedNewsApi.getNewsArticle,
    articleId,
  );

  if (!newsArticle) {
    return <LoadingMessage />;
  }

  return (
    <Stack gap={5} className="articleContainer">
      <Stack gap={3} className="articleHeader">
        <PageHeader
          heading={newsArticle.name}
          crumbsType={RootPathType.PublicNews}
          crumbs={[{ name: `${newsArticle.name}`, path: publicPages.PublicNews.path }]}
        />
        <div className="publishDate">{`Published on ${formatISODateString(newsArticle.publishDateTime)}`}</div>
      </Stack>
      <div className="d-flex">
        <div className="w-75 pt-4 pe-6">
          <ContentDisplay htmlContent={newsArticle.content} />
        </div>

        <div className="d-flex justify-content-center w-25 ps-6">
          <RelatedArticles articles={newsArticle.relatedArticles} />
        </div>
      </div>
    </Stack>
  );
};
export default ArticlePage;
