import { PageTitle } from 'nrosh-common/Api/Enums';
import { ContentChunk } from 'nrosh-common/Api/PublicPagesApi';
import useEndpoint from 'nrosh-common/Hooks/useEndpoint';
import ContentDisplay from '@/Components/ContentDisplay/ContentDisplay';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import { PublicPagesApi } from '@/Helpers/Apis';

const PrivacyNoticePage = (): JSX.Element => {
  const [contentChunk] = useEndpoint<ContentChunk>(PublicPagesApi.getContentChunk, PageTitle.PrivacyNotice);

  if (!contentChunk) {
    return <LoadingMessage />;
  }
  return <ContentDisplay htmlContent={contentChunk.content} />;
};

export default PrivacyNoticePage;
