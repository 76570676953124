import { RshRole } from 'nrosh-common/Api/Enums';
import { Provider, ProviderContact, ProviderWithStringDate, mapProviderResponse } from 'nrosh-common/Api/ProvidersApi';
import AuthContext from 'nrosh-common/Contexts/AuthContext';
import useEndpoint, { useEndpointConditionallyWithProcessing } from 'nrosh-common/Hooks/useEndpoint';
import { useContext } from 'react';
import { Stack } from 'react-bootstrap';
import { generatePath, useParams } from 'react-router-dom';
import { RootPathType } from '@/Components/Breadcrumbs/Breadcrumbs';
import { LoadingMessage } from '@/Components/Loading/LoadingMessage';
import PageHeader from '@/Components/PageHeader/PageHeader';
import { ProvidersApi } from '@/Helpers/Apis';
import { providerDisplayName } from '@/Helpers/ProviderHelper';
import { adminPages } from '@/Pages/Home/SitePages';
import ProviderContactsForm from '@/Pages/Providers/ProviderContactsForm';

const ProviderContactsPage = (): JSX.Element => {
  const { providerId } = useParams();

  const [provider] = useEndpointConditionallyWithProcessing<ProviderWithStringDate, Provider>(
    ProvidersApi.getProvider,
    true,
    mapProviderResponse,
    providerId,
  );
  const [contacts] = useEndpoint<ProviderContact[]>(ProvidersApi.getProviderContacts, providerId);
  const auth = useContext(AuthContext);

  if (!provider || !contacts) {
    return <LoadingMessage />;
  }

  const providerName = providerDisplayName(provider.name, provider.providerNumber);

  const pageTitle = auth.hasRole(RshRole.EditProviders) ? 'Manage contacts' : 'Contacts';

  return (
    <Stack gap={5}>
      <PageHeader
        heading={pageTitle}
        subheading={providerName}
        crumbsType={RootPathType.AdminProviders}
        crumbs={[
          {
            name: 'Organisational information',
            path: generatePath(adminPages.AdminViewProvider.path, { providerId: provider.id.toString() }),
          },
          {
            name: pageTitle,
            path: adminPages.AdminRegisteredDetails.path,
          },
        ]}
      />
      <ProviderContactsForm provider={provider} contacts={contacts} isAdmin />
    </Stack>
  );
};

export default ProviderContactsPage;
