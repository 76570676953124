import { EmailTemplateType } from 'nrosh-common/Api/EmailsApi';
import { FormEvent, useState } from 'react';
import { Form } from 'react-bootstrap';
import { PrimaryButton } from '@/Components/Buttons/DCSButton';
import LinkButton from '@/Components/Links/LinkButton';
import { LoadingButton } from '@/Components/Loading/LoadingButton';
import { EmailsApi, placeHolderAPIErrorHandler } from '@/Helpers/Apis';
import useUnsavedChangesWarning from '@/Hooks/useUnsavedChangesWarning';
import { ComposeForm } from '@/Pages/Emails/ComposeFormComponents';
import {
  availableTemplateFieldGroups,
  globalTemplateFieldGroups,
  requiredTemplateFields,
} from '@/Pages/Emails/EmailTemplates';
import { adminPages } from '@/Pages/Home/SitePages';

type EditFooterFormProps = {
  initialBody: string;
};

export const EditFooterForm = ({ initialBody }: EditFooterFormProps): JSX.Element => {
  const [body, setBody] = useState<string>(initialBody);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [setDirty, setPristine] = useUnsavedChangesWarning();

  const updateBody = (newBody: string): void => {
    setDirty();
    setBody(newBody);
  };

  const updateTemplate = async (): Promise<void> => {
    setIsSaving(true);
    setPristine();
    await EmailsApi.patchEmailTemplate(EmailTemplateType.Footer, {
      templateText: body,
      templateSubject: null,
    }).justErrors(placeHolderAPIErrorHandler);
    setIsSaving(false);
  };

  const submitForm = (e: FormEvent<HTMLFormElement> | undefined = undefined): void => {
    e?.preventDefault();
    updateTemplate().catch(() => {});
  };
  const saveButtonText = 'Save';
  return (
    <Form noValidate onSubmit={submitForm} className="w-75">
      <ComposeForm.Message
        htmlBody={body}
        updateBody={updateBody}
        isInvalid={false}
        noLabel
        templateFieldGroups={globalTemplateFieldGroups.concat(availableTemplateFieldGroups[EmailTemplateType.Footer])}
        requiredTemplateFields={requiredTemplateFields[EmailTemplateType.Footer]}
      />
      <LinkButton className="me-2" to={adminPages.AdminEmails.path} variant="outline-primary" disabled={isSaving}>
        Cancel
      </LinkButton>
      {!isSaving ? (
        <PrimaryButton className="me-3" type="submit">
          {saveButtonText}
        </PrimaryButton>
      ) : (
        <LoadingButton message={saveButtonText} />
      )}
    </Form>
  );
};
