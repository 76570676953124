import { SurveyChecks } from 'nrosh-common/Api/SurveysApi';
import { generatePath, useLocation, useParams } from 'react-router-dom';
import { providerPages, submissionPages } from '@/Pages/Home/SitePages';
import { SubmissionConfirmation } from '@/Pages/Submissions/Confirmation/SubmissionConfirmation';

export enum ConfirmSubmissionPage {
  ValidationIssues = 1,
  SubmissionContact = 2,
  RegisteredDetails = 3,
  FeesInvoiceEmail = 4,
  OrganisationalContacts = 5,
  Declarations = 6,
}

type CheckPathsResult = { order: number; path: string; required: boolean };

const useCheckPaths = (isAdmin: boolean, checks?: SurveyChecks | null): CheckPathsResult[] => {
  const { surveyId, timePeriodId, submissionId } = useParams();
  const location = useLocation();
  const submissionConfirmationState = location.state as SubmissionConfirmation | null;

  const submissionDetailsPath = isAdmin
    ? generatePath(submissionPages.SubmissionView.path, { surveyId, timePeriodId, submissionId })
    : generatePath(providerPages.SubmissionsDetail.path, { submissionId });

  return [
    {
      order: 0,
      path: submissionDetailsPath,
      required: true,
    },
    {
      order: ConfirmSubmissionPage.ValidationIssues,
      path: isAdmin
        ? generatePath(submissionPages.AdminValidationIssues.path, { surveyId, timePeriodId, submissionId })
        : generatePath(providerPages.ValidationIssues.path, { submissionId }),
      required: true,
    },
    {
      order: ConfirmSubmissionPage.SubmissionContact,
      path: isAdmin
        ? generatePath(submissionPages.AdminConfirmSubmissionContact.path, { surveyId, timePeriodId, submissionId })
        : generatePath(providerPages.ConfirmSubmissionContact.path, { submissionId }),
      required: true,
    },
    {
      order: ConfirmSubmissionPage.RegisteredDetails,
      path: isAdmin
        ? generatePath(submissionPages.AdminConfirmRegisteredDetails.path, { surveyId, timePeriodId, submissionId })
        : generatePath(providerPages.ConfirmRegisteredDetails.path, { submissionId }),
      required: checks
        ? checks.confirmRegisteredDetails
        : !!(submissionConfirmationState?.detailsCorrect || submissionConfirmationState?.updateRequestSent),
    },
    {
      order: ConfirmSubmissionPage.FeesInvoiceEmail,
      path: isAdmin
        ? generatePath(submissionPages.AdminConfirmFeesInvoiceEmail.path, { surveyId, timePeriodId, submissionId })
        : generatePath(providerPages.ConfirmFeesInvoiceEmail.path, { submissionId }),
      required: checks ? checks.confirmFeesInvoiceEmail : !!submissionConfirmationState?.feesInvoiceEmailConfirmed,
    },
    {
      order: ConfirmSubmissionPage.OrganisationalContacts,
      path: isAdmin
        ? generatePath(submissionPages.AdminConfirmOrganisationalContacts.path, {
            surveyId,
            timePeriodId,
            submissionId,
          })
        : generatePath(providerPages.ConfirmOrganisationalContacts.path, { submissionId }),
      required: checks
        ? checks.organisationalDetailsToConfirm.length > 0
        : !!submissionConfirmationState?.organisationalDetailsConfirmed,
    },
    {
      order: ConfirmSubmissionPage.Declarations,
      path: isAdmin
        ? generatePath(submissionPages.AdminConfirmDeclarations.path, { surveyId, timePeriodId, submissionId })
        : generatePath(providerPages.ConfirmDeclarations.path, { submissionId }),
      required: true,
    },
    {
      order: 7,
      path: submissionDetailsPath,
      required: true,
    },
  ];
};

export const useNextAndPreviousConfirmPath = (
  currentPage: ConfirmSubmissionPage,
  isAdmin: boolean,
  checks?: SurveyChecks | null,
): { nextPage: string; previousPage: string } => {
  const currentPageNumber: number = currentPage;
  const paths = useCheckPaths(isAdmin, checks);
  const previousPage = [...paths].filter((p) => p.required && p.order < currentPageNumber).pop()!.path;
  const nextPage = [...paths].filter((p) => p.required && p.order > currentPageNumber)[0].path;

  return {
    previousPage,
    nextPage,
  };
};
