import AuthContext from 'nrosh-common/Contexts/AuthContext';
import { useCallback, useContext } from 'react';
import { Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { Link, Outlet, matchPath, useNavigate } from 'react-router-dom';
import '@/Pages/Home/Navbar.scss';
import Footer from '@/Components/Footer/Footer';
import LoginLink from '@/Components/Navbar/LoginLink';
import SignOutLink from '@/Components/Navbar/SignOutLink';
import UserActions from '@/Components/Navbar/UserActions';
import UserDetails from '@/Components/Navbar/UserDetails';
import { AccountApi, placeHolderAPIErrorHandler } from '@/Helpers/Apis';
import { navBarPages, navBarUserPages } from '@/Pages/Home/App';
import { publicPages } from '@/Pages/Home/SitePages';
import dcsLogo from '@/dcs-logo-white.png';

const LayoutWithNavbar = (): JSX.Element => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const linksToShow = navBarPages.filter((p) => !p.requiredRole || auth.hasRole(p.requiredRole));
  const logout = useCallback(async () => {
    await AccountApi.logout().justErrors(placeHolderAPIErrorHandler);
    auth.setCurrentUser({ isLoggedIn: false, user: null });
    return navigate(publicPages.PublicHome.path);
  }, [auth, navigate]);
  const responsiveNavbar = 'responsive-navbar-nav';

  const getBasePath = (path: string): string => path.split('/')[1];
  const isActivePath = (path: string): boolean =>
    matchPath(`/${getBasePath(path)}/*`, window.location.pathname) !== null;

  return (
    <>
      <Navbar collapseOnSelect expand="md" sticky="top" variant="dark" bg="primary">
        <Navbar.Brand href="/">
          <img src={dcsLogo as string} className="d-inline-block align-top nrosh-logo" alt="DCS" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={responsiveNavbar} />
        <Navbar.Collapse id={responsiveNavbar}>
          <Nav>
            {auth.user && (
              <UserDetails userPages={navBarUserPages} displayName={auth.user.displayName} className="d-md-none" />
            )}
            {linksToShow.map((page) =>
              page.dropdownLinks ? (
                <NavDropdown
                  key={page.path}
                  title={page.name}
                  active={page.dropdownLinks.some((element) => isActivePath(element.path))}
                >
                  {page.dropdownLinks.map((dropdownPage) => (
                    <Nav.Link
                      id="dropdownLink"
                      eventKey={dropdownPage.path}
                      key={dropdownPage.path}
                      as={Link}
                      to={dropdownPage.path}
                    >
                      {dropdownPage.name}
                    </Nav.Link>
                  ))}
                </NavDropdown>
              ) : (
                <Nav.Link
                  eventKey={page.path}
                  key={page.path}
                  as={Link}
                  to={page.path}
                  active={isActivePath(page.path)}
                >
                  {page.name}
                </Nav.Link>
              ),
            )}
            {auth.user ? <SignOutLink logout={logout} className="d-md-none" /> : <LoginLink className="d-md-none" />}
          </Nav>
        </Navbar.Collapse>
        <UserActions userPages={navBarUserPages} logout={logout} />
      </Navbar>
      <Outlet />
      <Footer />
    </>
  );
};

export default LayoutWithNavbar;
